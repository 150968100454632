import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

interface IProps {
    className?: string;
}

const LanguageSelector: React.FC<IProps> = ({ className }) => {
    const { i18n } = useTranslation();

    const changeLanguage = (language: 'en' | 'de' | 'fr' | 'it') => {
        i18n.changeLanguage(language);
    };

    return (
        <div className={clsx('util__inline', className)}>
            <span className="inline-s" onClick={() => changeLanguage('de')}>
                DE
            </span>
            <span className="inline-s">|</span>
            <span className="inline-s" onClick={() => changeLanguage('fr')}>
                FR
            </span>
            <span className="inline-s">|</span>
            <span className="inline-s" onClick={() => changeLanguage('it')}>
                IT
            </span>
            <span className="inline-s">|</span>
            <span onClick={() => changeLanguage('en')}>EN</span>
            
        </div>
    );
};

export default LanguageSelector;
