import React, { useState, useEffect, useCallback } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';

import { predictRooms, sortingCrud } from '../../../store/action-creators';

import { getFloorParams, getFloorState } from '../../../modules/property';
import {
    getRoomsFromFloor,
    levelToFloor,
    reorderRoomsOnDragEnd
} from '../../../modules/rooms';

import { intOrElse } from '../../../utils';

import ExpSectionAlt from '../../atoms/ExpSectionAlt/ExpSectionAlt';
import ObjectFloor from '../ObjectFloor';

interface IProps {
    object: IDpd;
    rooms: IRoom[];
    viewedRoomId?: number;
    urlFloorNumber?: number;
    urlRoomId?: number;
    onTemplateSelect: (payload: Partial<IRoom>) => void;
    onRoomView: (room: IRoom) => void;
    roomsLoading?: boolean;
    isHouse?: boolean;
}

const RoomTab: React.FC<IProps> = (props) => {
    const {
        object,
        rooms,
        viewedRoomId,
        urlRoomId,
        urlFloorNumber,
        isHouse,
        roomsLoading,
        onTemplateSelect,
        onRoomView
    } = props;

    const dispatch = useDispatch();
    const [floorState, setFloorState] = useState<IHash<boolean>>({});

    const params = getFloorParams(object);
    useEffect(() => {
        const state = getFloorState(
            params.count,
            params.baseLevel,
            urlFloorNumber
        );

        setFloorState(state);
    }, [urlFloorNumber, params.count, params.baseLevel]);

    const openRoom =
        urlRoomId != null &&
        !roomsLoading &&
        rooms.find((room) => urlRoomId === room.rom_id);
    useEffect(() => {
        if (!openRoom) return;

        const roomFloor = intOrElse(openRoom.rom_level, 1);
        setFloorState((state) => ({
            ...state,
            [roomFloor]: true
        }));

        onRoomView(openRoom);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openRoom]);

    const onRoomDragEnd = useCallback(
        (result: DropResult) => {
            const newRooms = reorderRoomsOnDragEnd(rooms, result, !isHouse);
            if (!newRooms) return;

            dispatch(predictRooms(newRooms));
            dispatch(
                sortingCrud({
                    srt_dpd_id: object.dpd_id,
                    context: 'srt_dpd_id',
                    srt_order: newRooms.map(({ rom_id }) => ({ pk: rom_id }))
                })
            );
        },
        [dispatch, object.dpd_id, rooms, isHouse]
    );

    const toggleFloor = useCallback((floor: number) => {
        setFloorState((state) => ({
            ...state,
            [floor]: !state[floor]
        }));
    }, []);

    if (isHouse) {
        return (
            <>
                {Object.keys(floorState).map((floorKey) => {
                    const floor = parseInt(floorKey);
                    const [sortedRooms] = getRoomsFromFloor(rooms, floor);

                    return (
                        <ExpSectionAlt
                            key={floor}
                            className="stack-l"
                            titleClass="sidebar-title"
                            open={floorState[floorKey]}
                            onClick={() => toggleFloor(floor)}
                            title={levelToFloor(floor)}
                            count={sortedRooms.length}
                        >
                            <ObjectFloor
                                dpdId={object.dpd_id}
                                rooms={sortedRooms}
                                currentFloor={floor}
                                viewedRoomId={viewedRoomId}
                                onRoomDragEnd={onRoomDragEnd}
                                onAddCustomSelect={() =>
                                    onTemplateSelect({
                                        rom_type: 'custom',
                                        rom_level: floor
                                    })
                                }
                            />
                        </ExpSectionAlt>
                    );
                })}
            </>
        );
    }

    return (
        <ObjectFloor
            dpdId={object.dpd_id}
            rooms={rooms}
            currentFloor={1}
            viewedRoomId={viewedRoomId}
            onRoomDragEnd={onRoomDragEnd}
            onAddCustomSelect={() =>
                onTemplateSelect({ rom_type: 'custom', rom_level: 1 })
            }
        />
    );
};

export default RoomTab;
