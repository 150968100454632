import { schema } from 'normalizr';

import { justArray } from '../../utils';
import { keyToUploads, mediaToUpload } from '../../modules/upload';

const uploadSchema = new schema.Entity('uploads', undefined);

const accountAssociations = {
    uploads: [uploadSchema]
};
const accountOptions = {
    processStrategy: function (account: any) {
        return keyToUploads(account);
    }
};
const accountSchema = new schema.Entity(
    'accounts',
    accountAssociations,
    accountOptions
);

const tenantAssociations = {
    uploads: [uploadSchema]
};
const tenantOptions = {
    idAttribute: 'id',
    processStrategy: function (tenant: any) {
        return keyToUploads(tenant, { oldKey: 'profile_pic' });
    }
};
const tenantSchema = new schema.Entity(
    'tenants',
    tenantAssociations,
    tenantOptions
);

const elementAssociations = {
    uploads: [uploadSchema]
};
const elementOptions = {
    idAttribute: 'ele_id',
    processStrategy: function (element: any) {
        return keyToUploads(element);
    }
};
const elementSchema = new schema.Entity(
    'elements',
    elementAssociations,
    elementOptions
);

const roomAssociations = {
    uploads: [uploadSchema]
};
const roomOptions = {
    idAttribute: 'rom_id',
    processStrategy: function (room: any) {
        return keyToUploads(room);
    }
};
const roomSchema = new schema.Entity('rooms', roomAssociations, roomOptions);

// Protocol related stuff
const protocolAssociations = {
    uploads: [uploadSchema]
};
const protocolOptions = {
    idAttribute: 'prt_id',
    processStrategy: function (protocol: any) {
        return keyToUploads(protocol);
    }
};
const protocolSchema = new schema.Entity(
    'protocols',
    protocolAssociations,
    protocolOptions
);

const signatureAssociations = {
    uploads: [uploadSchema]
};
const signatureOptions = {
    idAttribute: 'id',
    processStrategy: function (signature: any) {
        return keyToUploads(signature, { many: false });
    }
};
const signatureSchema = new schema.Entity(
    'signatures',
    signatureAssociations,
    signatureOptions
);

const attendeeAssociations = {
    uploads: [uploadSchema],
    signatures: [signatureSchema]
};
const attendeeOptions = {
    idAttribute: 'att_id',
    processStrategy: function (attendee: any) {
        return keyToUploads(attendee, { oldKey: 'att_profile_pic' });
    }
};
const attendeeSchema = new schema.Entity(
    'attendees',
    attendeeAssociations,
    attendeeOptions
);

const protocolElementAssociations = {
    uploads: [uploadSchema]
};
const protocolElementOptional = {
    idAttribute: 'pele_id',
    processStrategy: function (element: any) {
        return keyToUploads(element);
    }
};
const protocolElementSchema = new schema.Entity(
    'protocol_elements',
    protocolElementAssociations,
    protocolElementOptional
);

const protocolRoomAssociations = {
    prom_pele_id: [protocolElementSchema],
    uploads: [uploadSchema]
};
const protocolRoomOptions = {
    idAttribute: 'prom_id',
    processStrategy: function (room: any) {
        return keyToUploads(room);
    }
};
const protocolRoomSchema = new schema.Entity(
    'protocol_rooms',
    protocolRoomAssociations,
    protocolRoomOptions
);

const protocolItemAssociations = {
    uploads: [uploadSchema]
};
const protocolItemOptions = {
    idAttribute: 'pitm_id',
    processStrategy: function (item: any) {
        return keyToUploads(item);
    }
};
const protocolItemSchema = new schema.Entity(
    'protocol_items',
    protocolItemAssociations,
    protocolItemOptions
);

const issueAssociations = {
    iss_pele_id: protocolElementSchema,
    iss_prom_id: protocolRoomSchema,
    iss_pitm_id: protocolItemSchema
};
const issueOptions = {
    idAttribute: 'iss_id',
    processStrategy: function (issue: any) {
        return keyToUploads(issue);
    }
};
const issueSchema = new schema.Entity(
    'issues',
    { ...issueAssociations, uploads: [uploadSchema] },
    issueOptions
);

// NOTE: mild inconsistency
const issueDenormSchema = new schema.Entity(
    'issues',
    issueAssociations,
    issueOptions
);

// Only normalize media
const tempPropertyAssociations = {
    dpd_dpd_id: [
        {
            uploads: [uploadSchema]
        }
    ],
    uploads: [uploadSchema]
};

const tempPropertyOptions = {
    idAttribute: 'dpd_id',
    processStrategy: function (property: any) {
        return {
            ...keyToUploads(property),
            dpd_dpd_id: justArray(property.dpd_dpd_id).map((object) =>
                keyToUploads(object)
            )
        };
    }
};
const propertySchema = new schema.Entity(
    'properties',
    tempPropertyAssociations,
    tempPropertyOptions
);

const sharedIssueAssociations = {
    piss_ele_id: {
        uploads: [uploadSchema]
    },
    uploads: [uploadSchema]
};
const sharedIssueOptions = {
    idAttribute: 'piss_id',
    processStrategy: function (sharedIssue: any) {
        const { piss_ele_id } = sharedIssue;
        return {
            ...keyToUploads(sharedIssue),
            piss_ele_id: piss_ele_id ? keyToUploads(piss_ele_id) : undefined
        };
    }
};
const sharedIssueSchema = new schema.Entity(
    'issues',
    sharedIssueAssociations,
    sharedIssueOptions
);

const upload = new schema.Entity(
    'uploads',
    {},
    {
        idAttribute: 'pk',
        processStrategy: function (media: any) {
            return mediaToUpload(media);
        }
    }
);
const checkoutUploadsSchema = { uploads: [upload] };

export {
    uploadSchema,
    tenantSchema,
    elementSchema,
    roomSchema,
    protocolSchema,
    attendeeSchema,
    protocolElementSchema,
    protocolRoomSchema,
    protocolItemSchema,
    issueSchema,
    issueDenormSchema,
    accountSchema,
    signatureSchema,
    propertySchema,
    sharedIssueSchema,
    checkoutUploadsSchema
};
