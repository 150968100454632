import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { Checkbox, CheckboxProps } from '@rmwc/checkbox';
import './std-checkbox.scss';

interface IProps extends IClass, CheckboxProps {
    children?: ReactNode;
    slim?: boolean;
    width?: string;
    wrap?: boolean;
    onClick?: ICallback;
    onTextClick?: ICallback;
}

const StdCheckbox: React.FC<IProps> = (props) => {
    const {
        className,
        children,
        label,
        slim,
        width,
        wrap,
        onClick,
        onTextClick,
        ...otherProps
    } = props;

    const containerClass = clsx(
        'std-checkbox__container',
        wrap && 'std-checkbox__container--wrap',
        className
    );

    const elementClass = clsx(
        'std-checkbox__element',
        slim && 'mdc-checkbox--slim',
        children && 'inline-m'
    );

    return (
        <div style={{ width }} className={containerClass}>
            <Checkbox
                className={elementClass}
                onClick={onClick}
                {...otherProps}
            />
            <span
                className="std-checkbox__text"
                onClick={onTextClick || onClick}
            >
                {children}
            </span>
        </div>
    );
};

export default StdCheckbox;
