import { takeEvery } from 'redux-saga/effects';
import qs from 'query-string';
import { GET_USAGE_LOGS_REQUEST } from '../../actions';
import {
    getUsageLogs,
    getUsageLogsSuccess,
    getUsageLogsFail
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeEvery(
    GET_USAGE_LOGS_REQUEST,
    simpleRequest<RT<typeof getUsageLogs>>({
        success: getUsageLogsSuccess,
        failure: getUsageLogsFail,
        getUrl: (action) =>
            `user/me/usage_logs/?${qs.stringify({
                summary: action.isSummary,
                date: action.date
            })}`,
        isV2Api: true
    })
);
