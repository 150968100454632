import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

import { validate, validatePassword } from '../../../utils/validation';

import useInput from '../../../hooks/useInput';
import useValidation from '../../../hooks/useValidation';

import StdModal from '../../atoms/StdModal';
import StdTextField from '../../atoms/StdTextField';
import StdButton from '../../atoms/StdButton';
import StdIcon from '../../atoms/StdIcon';

import './set-password-modal.scss';

// TODO: reduce password input repetition
// Perhaps use use separate password input component?
interface IState {
    form: {
        new_password: string;
    };
    errors: IErrorHash;
}

const initState: IState = {
    form: {
        new_password: ''
    },
    errors: {}
};

interface IProps extends IModalBase {
    handleSetPassword: (password: string) => void;
}

const SetPasswordModal: React.FC<IProps> = ({
    open,
    onClose,
    handleSetPassword
}) => {
    const { t, i18n } = useTranslation();

    const search = useLocation().search;
    const lang: string | null = new URLSearchParams(search).get('lang');

    const [hidePassword, setHidePassword] = useState(true);

    const [input, setInput] = useState(initState);
    const handleInput = useInput(setInput);

    const validateFields = useValidation(input, setInput, (hash) => {
        const { new_password } = input.form;
        const [passValid, passMessage] = validatePassword(new_password);
        return [validate({ new_password }, passValid, hash, passMessage)].every(
            Boolean
        );
    });

    const handleLocalisePage = () => {
        if(lang) {
            i18n.changeLanguage(lang);
        }
    };

    const handleSubmit = () => {
        if (validateFields()) {
            handleSetPassword(input.form.new_password);
        }
    };

    const cleanup = () => {
        setInput(initState);
    };

    useEffect(() => {
        handleLocalisePage();
    }, []);

    const { form, errors } = input;
    return (
        <StdModal open={open} onClose={onClose} onExited={cleanup}>
            <div className="set-password-modal__header">
                {t('invite.set_password_header')}
            </div>
            <div className="set-password-modal__content">
                <div className="stack-xl">
                    <StdTextField
                        label={t('new_password')}
                        name="new_password"
                        type={hidePassword ? 'password' : 'text'}
                        value={form.new_password}
                        error={errors['new_password']}
                        width="100%"
                        onChange={handleInput}
                        placeholder={t('invite.password_placeholder')}
                        trailingIcon={{
                            icon: (
                                <StdIcon
                                    prefix="far"
                                    name={hidePassword ? 'eye-slash' : 'eye'}
                                />
                            ),
                            onClick: () => setHidePassword((s) => !s)
                        }}
                        helpText={{
                            persistent: true,
                            children: t(errors['new_password']),
                            validationMsg: true
                        }}
                        autoComplete="new-password"
                    />
                </div>
                <div className="set-password-modal__actions">
                    <StdButton type="primary" onClick={handleSubmit}>
                        {t('invite.set_password')}
                    </StdButton>
                </div>
            </div>
        </StdModal>
    );
};

export default SetPasswordModal;
