import type { IDpdActions } from '../action-creators/types';

import * as actions from '../actions/dpd';
import { paginateStore, onPaginateStart } from '../../modules/pagination';
import { STORE_PAGE_INIT } from '../../configs/costants';

import { IDpdFetchType } from '../action-creators/dpd';

function fetchTypeToStore(type: IDpdFetchType) {
    const isDashboard = type === 'all' || type === 'dashboard';
    const isIssue = type === 'all' || type === 'issue_select';
    const isProtocol = type === 'all' || type === 'protocol_select';

    return {
        isDashboard,
        isIssue,
        isProtocol
    };
}

function handlePropertyRequest(
    state: DpdReducer,
    action: AnyAction,
    fetchType: IDpdFetchType
) {
    const { isDashboard, isIssue, isProtocol } = fetchTypeToStore(fetchType);

    const dashboardState = isDashboard
        ? onPaginateStart(state.dashboard, action.paginated)
        : {};
    const issueState = isIssue
        ? onPaginateStart(state.issueModal, action.paginated)
        : {};
    const protocolState = isProtocol
        ? onPaginateStart(state.select, action.paginated)
        : {};

    return {
        ...state,
        dashboard: {
            ...state.dashboard,
            ...dashboardState
        },
        issueModal: {
            ...state.issueModal,
            ...issueState
        },
        select: {
            ...state.select,
            ...protocolState
        }
    };
}

function handlePropertySuccess(
    state: DpdReducer,
    action: AnyAction,
    fetchType: IDpdFetchType
) {
    const { isDashboard, isIssue, isProtocol } = fetchTypeToStore(fetchType);

    const { response } = action.payload;

    const dashboardState = isDashboard
        ? paginateStore(state.dashboard, response)
        : {};
    const issueState = isIssue ? paginateStore(state.issueModal, response) : {};
    const protocolState = isProtocol
        ? paginateStore(state.select, response)
        : {};

    return {
        ...state,
        dashboard: {
            ...state.dashboard,
            ...dashboardState
        },
        issueModal: {
            ...state.issueModal,
            ...issueState
        },
        select: {
            ...state.select,
            ...protocolState
        }
    };
}

const fetchInitState = {
    response: {
        results: [],
        count: 0,
        next: null,
        previous: null
    },
    paginate: STORE_PAGE_INIT,
    error: null
};

function handleObjectsRequest(state: DpdReducer, action: AnyAction) {
    const buildingId = action.params.building_id;
    const buildingState = state.buildings[buildingId];

    const paginateStartState =
        buildingState == null ? fetchInitState : buildingState;
    return {
        ...state,
        buildings: {
            ...state.buildings,
            [buildingId]: onPaginateStart(paginateStartState, action.paginated)
        }
    };
}

function handleObjectsSuccess(state: DpdReducer, action: AnyAction) {
    const buildingId = action.originator.params.building_id;
    const buildingState = state.buildings[buildingId];

    if (!buildingState) return state;

    const { response } = action.payload;

    return {
        ...state,
        buildings: {
            ...state.buildings,
            [buildingId]: paginateStore(buildingState, response)
        }
    };
}

interface DpdReducer {
    dashboard: IPaginatedResult<IDpd>;
    issueModal: IPaginatedResult<IDpd>;
    select: IPaginatedResult<IDpd>;
    buildings: IHash<IPaginatedResult<IDpd>>;
}

const initState: DpdReducer = {
    dashboard: fetchInitState,
    issueModal: fetchInitState,
    select: fetchInitState,
    buildings: {}
};

function dpdsReducer(state = initState, action: IDpdActions): DpdReducer {
    switch (action.type) {
        case actions.GET_DPD_REQUEST: {
            const { fetchType } = action;
            if (fetchType === 'building_objects') {
                return handleObjectsRequest(state, action);
            }

            return handlePropertyRequest(state, action, fetchType);
        }
        case actions.GET_DPD_SUCCESS: {
            const fetchDpdAction = action as any as IAsyncResponseAction;
            const fetchType = fetchDpdAction.originator.fetchType;

            if (fetchType === 'building_objects') {
                return handleObjectsSuccess(state, action);
            }

            return handlePropertySuccess(state, action, fetchType);
        }
        case actions.PREDICT_OBJECTS: {
            const buildingId = action.id;
            const buildingState = state.buildings[buildingId];

            if (buildingState == null) return state;

            return {
                ...state,
                buildings: {
                    ...state.buildings,
                    [buildingId]: {
                        ...buildingState,
                        response: {
                            ...buildingState.response,
                            results: action.objects
                        }
                    }
                }
            };
        }
    }
    return state;
}

export default dpdsReducer;
