import React from 'react';
import CustomIcon from '../CustomIcon';
import ModalHeader from '../ModalHeader';
import StdButton from '../StdButton';

interface IProps {
    title: string;
    submitLabel: string;
    onClose: ICallback;
    onSubmit: ICallback;
    width?: string;
    disabled?: boolean;
    className?: string;
}

const EditModalHeader: React.FC<IProps> = ({
    title,
    submitLabel,
    onClose,
    onSubmit,
    width,
    disabled,
    className = ''
}) => {
    return (
        <ModalHeader
            leftNode={<CustomIcon name="cross" onClick={onClose} ripple />}
            centerNode={title}
            rightNode={
                <StdButton
                    type="primary"
                    className={className}
                    onClick={onSubmit}
                    disabled={disabled}
                >
                    {submitLabel}
                </StdButton>
            }
            width={width}
        />
    );
};

export default EditModalHeader;
