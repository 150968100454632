import { takeEvery } from 'redux-saga/effects';

import { DELETE_ROOM_REQUEST } from '../../actions';
import {
    deleteRoomSuccess,
    deleteRoomFail,
    IDeleteRoom
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeEvery(
    DELETE_ROOM_REQUEST,
    simpleRequest<IDeleteRoom>({
        success: deleteRoomSuccess,
        failure: deleteRoomFail,
        getUrl: (action) => `room/${action.roomId}/room_delete/`,
        method: 'DELETE'
    })
);
