import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import StdTab from '../../atoms/StdTab';
import Loading from '../../atoms/Loading';

interface IProps {
    className?: string;
    selectedTab?: IObjectTab;
    tabs?: {
        roomTab?: ReactNode;
        tenantTab?: ReactNode;
        reportTab?: ReactNode;
    };
    isRoomLoading?: boolean;
    isTenantLoading?: boolean;
    onTabChange?: (tab: IObjectTab) => void;
}

const ObjectBar: React.FC<IProps> = ({
    className,
    selectedTab = 'rooms',
    tabs,
    onTabChange,
    isRoomLoading,
    isTenantLoading
}) => {
    const { t } = useTranslation();
    const { roomTab, tenantTab, reportTab } = tabs || {};

    const roomsActive = selectedTab === 'rooms';
    const tenantsActive = selectedTab === 'tenants';
    const reportActive = selectedTab === 'reporting';
    return (
        <>
            <div className={clsx('object__tabs', className)}>
                <StdTab
                    iconPrefix={roomsActive ? 'fas' : 'fal'}
                    icon="th-large"
                    inactive={!roomsActive}
                    onClick={() => onTabChange?.('rooms')}
                >
                    <span>{t('rooms').toUpperCase()}</span>
                </StdTab>
                <StdTab
                    iconPrefix={tenantsActive ? 'fas' : 'fal'}
                    icon="users"
                    inactive={!tenantsActive}
                    onClick={() => onTabChange?.('tenants')}
                >
                    <span>{t('tenants').toUpperCase()}</span>
                </StdTab>
                <StdTab
                    iconPrefix={reportActive ? 'fas' : 'fal'}
                    icon="file-alt"
                    inactive={!reportActive}
                    onClick={() => onTabChange?.('reporting')}
                >
                    <span>{t('reporting').toUpperCase()}</span>
                </StdTab>
            </div>
            <div className="object__barcontent">
                <div
                    className={clsx(isRoomLoading && 'loading__tab')}
                    hidden={!roomsActive}
                >
                    {roomTab}
                    {isRoomLoading && (
                        <Loading className="loading__item" size="large" />
                    )}
                </div>
                <div
                    className={clsx(isTenantLoading && 'loading__tab')}
                    hidden={!tenantsActive}
                >
                    {tenantTab}
                    {isTenantLoading && (
                        <Loading className="loading__item" size="large" />
                    )}
                </div>
                <div hidden={!reportActive}>{reportTab}</div>
            </div>
        </>
    );
};

export default ObjectBar;
