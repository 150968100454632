import React, {
    useState,
    useCallback,
    useEffect,
    useLayoutEffect
} from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getDpds, postDpd, deleteMedia } from '../../store/action-creators';

import { dpdToData } from '../../utils/componentInterfaces';

import { getPageLoading, getPaginationParams } from '../../modules/pagination';

import { ONE_DPD } from '../../configs/routes';
import { settingsManager } from '../../modules/localSettings';
import { justArray } from '../../utils';
import { detectScrollEnd } from '../../modules/scroll';

import useDelayedPending from '../../hooks/useDelayedPending';
import useMatchMedia from '../../hooks/useMatchMedia';
import useScrollToContent from '../../hooks/useScrollToContent';
import usePending from '../../hooks/usePending';

import ExpSection from '../../components/atoms/ExpSection/ExpSection';
import DpdCard from '../inventory/DpdCard';

import Loading from '../atoms/Loading';
import TutorialCard from '../inventory/TutorialCard';
import StdButton from '../atoms/StdButton';
import i18n from '../../i18next';

const fetchLoadingConfig = [
    { action: getDpds, fetchType: 'dashboard' },
    { action: getDpds, fetchType: 'all' }
];

interface DpdProps {
    search: string;
    dpdsRes: IPaginatedResult<IDpd>;
    onModalOpen?: ICallback;
    onAdd?: ICallback;
}

export const DpdSection: React.FC<DpdProps> = ({
    search,
    dpdsRes,
    onAdd,
    onModalOpen
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const lang = i18n.language as ILang;

    const [dpdOpen, setDpdOpen] = useState(true);
    const [sectionRef, scrollToContent] = useScrollToContent({
        onlyMobile: true,
        block: 'center'
    });

    const uploadStore = useSelector((state) => state.uploads.uploads);

    const { hasNext, hasPrevious } = dpdsRes.paginate;
    const dpdCount = dpdsRes.response.count;

    const dpds = justArray(dpdsRes.response.results);

    const isPhone = useMatchMedia('mobile');

    const dpdLoading = useDelayedPending([
        postDpd,
        deleteMedia,
        ...fetchLoadingConfig
    ]);
    const dpdLoadingInst = usePending(fetchLoadingConfig);

    const getMoreDpds = useCallback(
        (direction?: IPaginateDirection) => {
            const params = getPaginationParams(dpdsRes.paginate, direction);

            dispatch(
                getDpds('dashboard', { ...params, search, lang }, direction)
            );
        },
        [dispatch, dpdsRes.paginate, search]
    );

    const handleScroll = useCallback(
        (element: HTMLElement) => {
            if (hasNext && !dpdLoadingInst && detectScrollEnd(element)) {
                getMoreDpds('forwards');
            }
        },
        [dpdLoadingInst, hasNext, getMoreDpds]
    );

    useLayoutEffect(() => {
        if (!dpdOpen) return;

        const onScroll = (event: Event) => {
            const target = event.target as HTMLElement;
            const element =
                event.target === document ? document.documentElement : target;

            handleScroll(element);
        };

        window.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, [dpdOpen, handleScroll]);

    useEffect(() => {
        setDpdOpen(settingsManager.get('property_section_open'));
    }, []);

    const onSectionToggle = () => {
        setDpdOpen((state) => {
            settingsManager.set('property_section_open', !state);
            return !state;
        });
    };

    const dpdsNode = Boolean(dpds.length) ? (
        dpds.map((dpd, index) => {
            const { dpd_id } = dpd;
            const isLast = index === dpds.length - 1;
            return (
                <DpdCard
                    key={dpd_id}
                    className={clsx(!isLast && 'stack-l')}
                    dpdInfo={dpdToData(dpd, uploadStore)}
                    link={ONE_DPD(dpd.dpd_id)}
                />
            );
        })
    ) : (
        <TutorialCard
            key="property-tutorial-card"
            onAction={onModalOpen}
            property
        />
    );

    const verticalSpinner = (
        <Loading
            key="loading-spinner"
            className="loading__dashborad"
            size="xlarge"
        />
    );

    return (
        <>
            <div ref={sectionRef} />
            <ExpSection
                open={dpdOpen}
                className="dashboard__expandable"
                title={
                    <span attr-intercom="dpd">
                        {t('properties').toUpperCase()}
                    </span>
                }
                actionTitle={t('property')}
                count={dpdCount || dpds.length}
                onClick={onSectionToggle}
                onAction={onAdd}
                onAnimationComplete={() => {
                    if (dpdOpen && sectionRef.current) {
                        scrollToContent();
                    }
                }}
                wide={isPhone}
            >
                {!dpdLoading && hasPrevious && (
                    <div className="dashboard__load-more">
                        <StdButton onClick={() => getMoreDpds('backwards')}>
                            {t('load_previous')}
                        </StdButton>
                    </div>
                )}
                {dpdLoading && getPageLoading(dpdsRes.paginate) === 'start'
                    ? verticalSpinner
                    : null}
                {dpdLoading && getPageLoading(dpdsRes.paginate) === 'middle'
                    ? verticalSpinner
                    : dpdsNode}
                {dpdLoading && getPageLoading(dpdsRes.paginate) === 'end'
                    ? verticalSpinner
                    : null}
            </ExpSection>
        </>
    );
};

export default DpdSection;
