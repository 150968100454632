import React from 'react';

import './modal-header.scss';

interface IProps {
    leftNode?: ReactNode;
    centerNode?: ReactNode;
    rightNode?: ReactNode;
    width?: string;
    className?: string;
}

const ModalHeader: React.FC<IProps> = ({
    leftNode,
    centerNode,
    rightNode,
    width,
    className
}) => {
    return (
        <div
            style={{ width }}
            className={`modal-header__container ${className}`}
        >
            <span>{leftNode}</span>
            <span className="modal-header__title">{centerNode}</span>
            <span>{rightNode}</span>
        </div>
    );
};

export default ModalHeader;
