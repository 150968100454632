import React, { Fragment } from 'react';

import { getPageLoading } from '../../../modules/pagination';

import Loading from '../../atoms/Loading';

interface IProps {
    loading?: boolean;
    pagination: IStoredPagination;
    children: React.ReactNode;
}

const PaginatedList: React.FC<IProps> = ({ loading, pagination, children }) => {
    const verticalSpinner = (
        <Loading
            key="loading-spinner"
            className="loading__dashborad"
            size="xlarge"
        />
    );
    return (
        <Fragment>
            {loading && getPageLoading(pagination) === 'start'
                ? verticalSpinner
                : null}
            {loading && getPageLoading(pagination) === 'middle'
                ? verticalSpinner
                : children}
            {loading && getPageLoading(pagination) === 'end'
                ? verticalSpinner
                : null}
        </Fragment>
    );
};

export default PaginatedList;
