export const GET_DPD_REQUEST = 'GET_DPD_REQUEST';
export const GET_DPD_SUCCESS = 'GET_DPD_SUCCESS';
export const GET_DPD_FAIL = 'GET_DPD_FAIL';

export const GET_ONE_DPD_REQUEST = 'GET_ONE_DPD_REQUEST';
export const GET_ONE_DPD_SUCCESS = 'GET_ONE_DPD_SUCCESS';
export const GET_ONE_DPD_FAIL = 'GET_ONE_DPD_FAIL';

export const POST_DPD_REQUEST = 'POST_DPD_REQUEST';
export const POST_DPD_SUCCESS = 'POST_DPD_SUCCESS';
export const POST_DPD_FAIL = 'POST_DPD_FAIL';

export const PATCH_DPD_REQUEST = 'PATCH_DPD_REQUEST';
export const PATCH_DPD_SUCCESS = 'PATCH_DPD_SUCCESS';
export const PATCH_DPD_FAIL = 'PATCH_DPD_FAIL';

export const DELETE_DPD_REQUEST = 'DELETE_DPD_REQUEST';
export const DELETE_DPD_SUCCESS = 'DELETE_DPD_SUCCESS';
export const DELETE_DPD_FAIL = 'DELETE_DPD_FAIL';

export const GET_LOCATION_CH_REQUEST = 'GET_LOCATION_CH_REQUEST';
export const GET_LOCATION_CH_SUCCESS = 'GET_LOCATION_CH_SUCCESS';
export const GET_LOCATION_CH_FAIL = 'GET_LOCATION_CH_FAIL';

export const DPD_ATTR_CRUD_REQUEST = 'DPD_ATTR_CRUD_REQUEST';
export const DPD_ATTR_CRUD_SUCCESS = 'DPD_ATTR_CRUD_SUCCESS';
export const DPD_ATTR_CRUD_FAIL = 'DPD_ATTR_CRUD_FAIL';

export const COPY_DPD_REQUEST = 'COPY_DPD_REQUEST';
export const COPY_DPD_SUCCESS = 'COPY_DPD_SUCCESS';
export const COPY_DPD_FAIL = 'COPY_DPD_FAIL';

export const PREDICT_OBJECTS = 'PREDICT_OBJECTS';
