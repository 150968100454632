import { takeEvery } from 'redux-saga/effects';

import { RENAME_MEDIA_REQUEST } from '../../actions';
import {
    renameMedia,
    renameMediaSuccess,
    renameMediaFail
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeEvery(
    RENAME_MEDIA_REQUEST,
    simpleRequest<RT<typeof renameMedia>>({
        success: renameMediaSuccess,
        failure: renameMediaFail,
        getUrl: () => `media/media_rename/`,
        getBody: (action) => ({ media_id: action.id, new_key: action.name }),
        method: 'POST'
    })
);
