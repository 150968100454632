import { takeEvery, call } from 'redux-saga/effects';
import queryString from 'query-string';

import { GET_ISSUES_REQUEST } from '../../actions';
import {
    getIssuesSuccess,
    getIssuesFail,
    getIssues
} from '../../action-creators';

import { simpleRequest } from '../modules/request';
import { fetchFailError } from '../../../modules/errorHandlers';
import { normalizePropertyIssues } from '../modules/normalization';

export default takeEvery(
    GET_ISSUES_REQUEST,
    simpleRequest<RT<typeof getIssues>>({
        success: getIssuesSuccess,
        failure: getIssuesFail,
        getUrl: (action) =>
            `dpd/${action.dpdId}/issues/?${queryString.stringify(
                action.params || {}
            )}`,
        onFail: function* (error: INetworkError) {
            yield call(fetchFailError, 'fetch_issues_error', error);
        },
        onSuccess: function (data) {
            const { uploads } = normalizePropertyIssues(data);
            const allIds = data.map((object: { iss_id: number; }) => object.iss_id).filter((object: object) => object)
            let byIds = {};
            data.forEach((object: { iss_id: any; }) => {
            byIds = {
                    ...byIds,
                    [object.iss_id] : object
                }
            })
            return {store: {allIds, byIds}, uploads};
        }
    })
);
