import React, { useState, useEffect, useCallback, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AnyAction } from 'redux';
import { isEmpty } from 'validator';
import { useDispatch, useSelector } from 'react-redux';

import {
    isHouseType,
    OBJECT_FLOOR_LIMIT,
    DPD_UPLOAD_CONFIG,
    DPD_NUMBER_OF_FLOORS_REGEX,
    MEDIA_ATTACHMENT_TYPE,
    DPD_TYPE_HOUSE
} from '../../../configs/costants';

import {
    objectHasTemplate,
    propertyToIcon,
    propertyToForm
} from '../../../modules/property';
import {
    DPD_TYPE,
    DPD_ATTR_TYPE,
} from '../../../configs/basicData';
import { INT_TYPE_REGEX } from '../../../configs/costants';
import { validate } from '../../../utils/validation';
import { dispatchAll } from '../../../store/storeModule';
import {
    patchDpd,
    postDpd,
    showSnackbar,
    dpdAttrCrud
} from '../../../store/action-creators';
import {
    propertyAddressToForm
} from '../../../modules/property';
import { getAttrHashKeys } from '../../../utils/attributes';

import useValidation from '../../../hooks/useValidation';
import useInput from '../../../hooks/useInput';
import useAttributeList from '../../../hooks/useAttributeList';
import useAddressEdit from '../../../hooks/useAddressEdit';
import useGeoAdmin from '../../../hooks/useGeoAdmin';
import useReactScrollTo from '../../../hooks/useReactScrollTo';
import useUploader from '../../../hooks/useUploader';
import useScrollToContent from '../../../hooks/useScrollToContent';
import { MenuItem } from '@rmwc/menu';
import StdDivider from '../../atoms/StdDivider';
import StdIcon from '../../atoms/StdIcon';
import StdModal from '../../atoms/StdModal';
import TextField from '../../atoms/StdTextField';
import StdGallery from '../../molecules/StdGallery';

import EditAttributeList from '../EditAttributeList';
import AttachmentGallery from '../../molecules/AttachmentGallery';
import AddressEditForm from '../AddressEditForm';
import StdCheckbox from '../../atoms/StdCheckbox';
import ObjectEditModal from '../../atoms/ObjectEditModal/ObjectEditModal';
import StdButton from '../../atoms/StdButton';
import { SUBDPD_TYPE_GENERAL } from '../../../configs/costants';
import moment from 'moment';
import StdSelect from '../../atoms/StdSelect';

interface IProps {
    open: boolean;
    dpd?: IDpd;
    egid?: string;
    toAttach?: boolean;
    toAttr?: boolean;
    isPropertyGallery?: string;
    onClose: ICallback;
    onSubmit: ICallback;
}

interface IInput {
    type: string;
    floor: string;
    egid: string;
    ewid: string;
    include_basement: boolean;
    use_template: boolean;
}

interface IForm {
    form: IInput;
    errors: IErrorHash;
}

const initForm: IForm = {
    form: {
        type: '',
        floor: '',
        ewid: '',
        egid: '',
        include_basement: false,
        use_template: false
    },
    errors: {}
};

const EditDpdModal: React.FC<IProps> = ({
    dpd,
    open,
    toAttach,
    toAttr,
    onSubmit,
    onClose
}) => {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [input, setInput] = useState(initForm);
    const [step, setStep] = useState('location');

    const [typeRef, scrollToType] = useScrollToContent();
    const [buildingRef, scrollToBuilding] = useScrollToContent();
    const [addressRef, scrollToAddress] = useScrollToContent();

    useEffect(() => {
        if(toAttach || toAttr) {
            setStep('detail');
        }
    }, [toAttach, toAttr])

    const {
        dirty,
        setConfirmOpen,
        addressCleanup,
        validateAddress,
        getAddressForm,
        ...addressEditProps
    } = useAddressEdit();

    const lookupHash = useSelector((state) => state.basicData.response);

    const galleryUploader = useUploader();
    const attachUploader = useUploader();

    const attrListProps = useAttributeList();
    const { attrState, resetAttrs, validateAttrs } = attrListProps;

    const attrData = lookupHash?.[DPD_ATTR_TYPE];

    const dpdTypesHash =
        useSelector((state) => state.basicData.response?.[DPD_TYPE]) || {};

    const attrRef = useReactScrollTo(toAttr);
    const attachRef = useReactScrollTo(toAttach);
    // NOTE: Sort of "forward" referential equaility for address of a dpd
    const addressForm = useMemo(() => dpd && propertyAddressToForm(dpd), [dpd]);

    const handleEGID = useCallback((egid?: string, ewid?: string) => {
        setInput((s) => ({

            ...s,
            form: {
                ...s.form,
                egid: egid || '',
                ewid: ewid || ''
            }
        }));
    }, []);

    const getGeoData = useGeoAdmin(handleEGID);

    // Make sure number fields stay number
    const handleInput = useInput(setInput, (name, value, newState) => {
        if (name === 'egid' && !INT_TYPE_REGEX.test(value)) return input;
        if (name === 'floor' && !DPD_NUMBER_OF_FLOORS_REGEX.test(value))
            return input;
        return newState;
    });

    const cleanup = useCallback(() => {
        setInput(initForm);
        addressCleanup();
    }, [addressCleanup]);

    const resetInput = useCallback(() => {
        if (dpd && open) {
            const { dpd_attributes, uploads = [] } = dpd;
            const dpdAttrs = Array.isArray(dpd_attributes)
                ? dpd_attributes
                : [];

            const hashForm: IHash<string> = {};
            const attrIds = dpdAttrs.map((dpdAttr) => {
                const { dpa_id, dpa_type, dpa_value } = dpdAttr;
                const idLiteral = { id: dpa_id };

                const { typeKey, valueKey } = getAttrHashKeys(idLiteral);

                hashForm[typeKey] = dpa_type || '';
                hashForm[valueKey] = dpa_value || '';

                return idLiteral;
            });

            resetAttrs(attrIds, hashForm);
            setInput((state) => ({
                ...state,
                form: {
                    ...state.form,
                    ...propertyToForm(dpd)
                }
            }));

            galleryUploader.setPartialUploads(
                uploads,
                (upload) => upload.type !== MEDIA_ATTACHMENT_TYPE
            );
            attachUploader.setPartialUploads(
                uploads,
                (upload) => upload.type === MEDIA_ATTACHMENT_TYPE
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dpd, open, resetAttrs]);

    useEffect(() => {
        resetInput();
    }, [resetInput]);

    const mediaIds = galleryUploader.ids.concat(attachUploader.ids);
    const makeRequest = () => {
        const { type, floor, egid, include_basement, use_template } =
            input.form;
        const { zip, street, street_number, city, country, address_extra } =
            getAddressForm();
        const { region, commune, lat, lng } = address_extra;
        const parsedEGID = parseInt(egid);

        const { attrIds, hashForm, dirtyAttr } = attrState;

        const floorLevel = parseInt(floor);
        const dpdLiteral = {
            dpd_zip: zip,
            dpd_street: street,
            dpd_floor: !isNaN(floorLevel) ? floorLevel : undefined,
            dpd_street_number: street_number,
            dpd_country: country,
            dpd_region: region,
            dpd_city: city,
            dpd_commune: commune,
            dpd_type: type, 
            dpd_title: `${street} ${street_number}`,
            dpd_description: type === DPD_TYPE_HOUSE ? "" : `${street} ${street_number}, ${zip} ${city}`, 
            dpd_geo_lat: lat?.toString(),
            dpd_geo_lng: lng?.toString(),
            dpd_egid: Number.isInteger(parsedEGID) ? parsedEGID : null,
            dpd_basement_included: include_basement,
            media_med_id: mediaIds,
            template_type: !dpd && use_template ? type : undefined
        };

        const actions: AnyAction[] = [];
        const context = attrIds.map((attrId) => {
            const { typeKey, valueKey } = getAttrHashKeys(attrId);
            return {
                dpa_type: hashForm[typeKey],
                dpa_value: hashForm[valueKey]
            };
        });

        const modAction = dpd
            ? patchDpd(dpd.dpd_id, dpdLiteral)
            : postDpd({ ...dpdLiteral, context: 'create_object_dpd' });
        actions.push(modAction);

        if (dirtyAttr && dpd && dpd.dpd_id != null)
            actions.push(
                dpdAttrCrud({ dpa_dpd_id: dpd.dpd_id, dpa_state: context })
            );

        dispatchAll(dispatch, actions, { disableLoading: true })
            .then((_) =>
                Promise.all([
                    galleryUploader.submitChanges(),
                    attachUploader.submitChanges()
                ])
            )
            .then(onSubmit)
            .catch(({ error }) =>
                dispatch(
                    showSnackbar({
                        message: dpd
                            ? 'edit_dpd_fail_message'
                            : 'add_dpd_fail_message',
                        error
                    })
                )
            );
        onClose();
        setStep('location');
    };
    const isGeneral = input.form.type === SUBDPD_TYPE_GENERAL;
    const typesHash =
        useSelector((state) => state.basicData.response?.[DPD_TYPE]) || {};
    const generalTitle = typesHash[SUBDPD_TYPE_GENERAL] || '';

    const validateFields = useValidation(input, setInput, (hash) => {
        const { type, floor } = input.form;

        const parsedFloor = parseInt(floor);

        const attrsRes = validateAttrs();
        const addressValid = validateAddress();
        const isTypeValid =  step === "detail" ? validate({ type }, !isEmpty(type), hash) : true;

        const isBuildingValid = step === "detail" ? [
            !isHouseType(type) ||
            (validate({ floor }, Number.isInteger(parsedFloor), hash) &&
                validate({ floor }, parsedFloor > 0, hash) &&
                validate(
                    { floor },
                    parsedFloor <= OBJECT_FLOOR_LIMIT,
                    hash
                ))
        ].every(Boolean) : true;

        const isValid = [
            isTypeValid,
            isBuildingValid,
            addressValid,
            ...attrsRes
        ].every(Boolean);

        if (!isTypeValid) {
            scrollToType();
        } else if (!isBuildingValid) {
            scrollToBuilding();
        } else if (!addressValid) {
            scrollToAddress();
        }
        return isValid;
    });

    const handleSubmit = () => {
        if (!validateFields()) return;
        makeRequest();
    };

    const onModalClose = () => {
        galleryUploader.cancelChanges();
        onClose();
        setStep('location');
    };

    const { form, errors } = input;

    const hasTemplates = objectHasTemplate(form.type);
    const buildingForm = isHouseType(form.type) ? (
        <>
            <div className="modal__section modal__section--slim">
                <TextField
                    className="inline-m"
                    label="EGID"
                    name="egid"
                    value={form.egid}
                    onChange={handleInput}
                    width="45%"
                />
                <TextField
                    label={t('number_of_floors')}
                    name="floor"
                    value={form.floor}
                    error={errors['floor']}
                    onChange={handleInput}
                    width="45%"
                    required
                />
            </div>
            <div className="modal__section modal__section--slim">
            <StdCheckbox
                checked={form.include_basement}
                onClick={() =>
                    {
                        setInput((s) => ({
                        ...s,
                        form: {
                            ...s.form,
                            include_basement: !s.form.include_basement
                        }
                    }))}}
                width="100%"
                slim
            >
                {t('include_basement')}
            </StdCheckbox>
            {hasTemplates && (
                <StdCheckbox
                    checked={form.use_template}
                    onClick={() =>{
                        setInput((s) => ({
                            ...s,
                            form: {
                                ...s.form,
                                use_template: !s.form.use_template
                            }
                        }))}
                    }
                    width="100%"
                    slim
                >
                    {t('use_template')}
                </StdCheckbox>
            )}
            </div>
        </>
    ) : null;

    const modalTitle = dpd ? t('edit_property') : t('add_property');
    return (
        <StdModal open={open} onClose={onModalClose} onExited={cleanup} small>
            <ObjectEditModal title={modalTitle} onClose={onModalClose} />
            <div className="modal__section modal__section__breadcrump">
                <div className={`modal__section__breadcrump__wrapper`} onClick={() => {
                    setStep('location')
                }}>
                    <img
                        src="/static/images/location.svg"
                        alt="steps"
                        className={`${step === 'location' && 'modal__section__breadcrump__activeImg'
                            }`}
                    />
                    <div
                        className={`modal__section__breadcrump__title ${step === 'location' &&
                            'modal__section__breadcrump__title__active'
                            }`}
                    >
                        {t('place')}
                    </div>
                    <StdIcon name="chevron-right" />
                </div>
                <div
                    className={`modal__section__breadcrump__wrapper modal__section__space`}
                >
                    <img
                        src={`${Boolean(dpd)
                            ? '/static/images/checkmark.svg'
                            : '/static/images/building.svg'
                            }`}
                        alt="location"
                        className={`${step === 'detail' && 'modal__section__breadcrump__activeImg'
                            }`}
                    />
                    <div
                        className={`modal__section__breadcrump__title ${step === 'detail' && 'modal__section__breadcrump__title__active'
                            }`}
                        onClick={() => {
                            if(!validateFields()) return;
                            setStep('detail')
                        }}
                    >
                        {t('property_details')}
                    </div>
                    <StdIcon name="chevron-right" />
                </div>
            </div>
            {step === 'location' ? (
                <div>
                    <div>
                        <AddressEditForm
                            {...addressEditProps}
                            dirty={dirty}
                            address={addressForm}
                            onDirtyConfirm={makeRequest}
                            setConfirmOpen={setConfirmOpen}
                            onGeoLocateSuccess={getGeoData}
                        />
                        <StdDivider />
                        <div ref={addressRef} />
                    </div>
                    <div className='modal__section--slim modal__section--egidWrapper'>
                    <div className="modal__heading stack-xl">
                            {t('egid').toUpperCase()}
                        </div>
                        <TextField
                            className="inline-m"
                            label="EGID"
                            name="egid"
                            value={form.egid}
                            onChange={handleInput}
                            width="100%"
                        />
                        <p className='modal__section--egid'>{t("egid-generated-automaticaly")}</p>
                    </div>
                    <div className="modal__section--slim modal__section--addProperty">
                        <div>
                            <StdButton onClick={onModalClose}>
                                {t('cancel')}
                            </StdButton>
                        </div>
                        <div>
                            <StdButton
                                type="primary"
                                onClick={() => {
                                    if(!validateFields()) return;
                                    setStep('detail');
                                }}
                            >
                                {t('dpd-next')}
                            </StdButton>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <div className="modal__section modal__section--slim  modal__section--egidWrapper">
                    <StdSelect
                        label={t('property_type')}
                        name="type"
                        value={form.type}
                        error={errors['type']}
                        onChange={handleInput}
                        width="1005"
                        disabled={Boolean(dpd)}
                    >
                        {Object.keys(typesHash).map((key) => {
                            return (
                                <MenuItem key={key} data-value={key}>
                                    <StdIcon
                                        className="inline-m"
                                        name={propertyToIcon(key)}
                                    />
                                    <span>{typesHash[key]}</span>
                                </MenuItem>
                            );
                        })}
                    </StdSelect>
                    </div>
                    <div className="modal__section modal__section--slim">
                        <div className="modal__heading">
                            {t('property_photos').toUpperCase()}
                        </div>
                    </div>
                    <div className="modal__section modal__section--imgUploader">
                        <StdGallery
                            {...galleryUploader}
                            noTitle
                            uploadConfig={DPD_UPLOAD_CONFIG}
                            icon="photo-video"
                            isPropertyGallery
                        />
                    </div>
                    <StdDivider/>
                    {buildingForm}
                    {dpd ?
                      <>
                        <div className={`modal__section modal__section--options modal__section--addProperty ${attrState.attrIds.length
                            ? 'modal__section--addAttributes'
                            : ''
                            }`}
                            ref={attrRef}
                        >
                            <div className="modal__heading">
                                {t('description').toUpperCase()}
                            </div>
                            <div
                                className={` ${attrState.attrIds.length
                                    ? 'modal__section--EditAttributesBtn'
                                    : ''
                                    }`}
                            >
                                <EditAttributeList
                                    {...attrListProps}
                                    {...attrState}
                                    attrData={attrData}
                                />
                            </div>
                        </div>
                        <StdDivider/>
                        <div
                            className="modal__section modal__section---options "
                            ref={attachRef}
                        >
                            <AttachmentGallery
                                {...attachUploader}
                                className={`modal__section--addProperty ${attachUploader.ids.length
                                    ? 'modal__section--addAttributes'
                                    : ' '
                                    }`}
                                showImg
                            />
                        </div>
                    <StdDivider className='stack-m'/>
                     </>
                    : null}
                    <div className="modal__section--slim modal__section--addProperty">
                        <div>
                            <StdButton onClick={onModalClose}>
                                {t('cancel')}
                            </StdButton>
                        </div>
                        <div>
                            <StdButton type="primary" onClick={handleSubmit}>
                                {t('save')}
                            </StdButton>
                        </div>
                    </div>
                </div>
            )}
        </StdModal>
    );
};

export default memo(
    EditDpdModal,
    (prevProps, nextProps) => prevProps.open === nextProps.open
);
