import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { PROTOCOL_ROUTE } from '../../../configs/routes';
import { justArray } from '../../../utils';
import { nextInoviceDate } from '../../../utils/formatter';
import { parsePrice } from '../../../modules/plans';
import ExpandableList from '../../atoms/ExpandableList';
import StdDivider from '../../atoms/StdDivider';
import InvoiceItem from '../InvoiceItem';
import './invoice.scss';
import HistoryUsage from './HistoryUsage';

interface IProps {
    invoice: IInvoice;
}

const Invoice: React.FC<IProps> = ({ invoice }) => {
    const { t } = useTranslation();

    const { usage } = invoice;

    const { total } = usage;

    const miopUsage = usage.MIOP;
    const issueUsage = usage.ISSUE;
    const storageUsage = usage.DOCUMENT;
    const managerUsage = usage.USER;
    const planUsage = usage.plan;

    const currency = planUsage?.currency || '';

    const protocolLogs = justArray(miopUsage?.data);
    const issueLogs = justArray(issueUsage?.data);
    const storageLogs = justArray(storageUsage?.data);
    const managerLogs = justArray(managerUsage?.data);

    const protocolTitle = t('profile.protocol_count', {
        count: miopUsage?.count
    });
    const issueTitle = t('profile.issue_count', { count: issueUsage?.count });
    const managerTitle = t('profile.manager_count', {
        count: managerUsage?.count
    });

    const getPackageTitle = () => {
        let title = '';
        if(planUsage?.identifier.includes('ENTERPRISE')) {
            title = `${planUsage?.name}`
        } else {
            title = t(`plan.backend.name.${planUsage?.identifier}`)
        }
        return title;
    }

    const packageTitle = getPackageTitle();

    return (
        <div className="inovice__container">
            <div className="inovice__total stack-l">
                {total} {currency}
            </div>
            <HistoryUsage />
            <div className="inovice__next-invoice-note stack-m">
                {t('profile.inovice_note', { date: nextInoviceDate() })}
            </div>
            <ExpandableList
                title={
                    <div className="inovice__subtotal-container inline-m">
                        <span>{protocolTitle}</span>
                        <span className="currency">{parsePrice(miopUsage.subtotal, currency)}</span>
                    </div>
                }
                isEmpty={!protocolLogs.length}
                first
                small
            >
                {protocolLogs.map((log) => {
                    const { metadata, price, included } = log;
                    const { id, title, date } = metadata;

                    return (
                        <Fragment key={id}>
                            <StdDivider />
                            <InvoiceItem
                                title={title}
                                link={PROTOCOL_ROUTE(id)}
                                date={date}
                                price={
                                    included
                                        ? t('invoice.usage.included')
                                        : parsePrice(price, currency)
                                }
                            />
                        </Fragment>
                    );
                })}
            </ExpandableList>
            <ExpandableList
                title={
                    <div className="inovice__subtotal-container inline-m">
                        <span>{issueTitle}</span>
                        <span className="currency">{parsePrice(issueUsage.subtotal, currency)}</span>
                    </div>
                }
                isEmpty={!issueLogs.length}
                small
            >
                {issueLogs.map((log) => {
                    const { metadata, included, price } = log;
                    const { id, title, date } = metadata;

                    return (
                        <Fragment key={id}>
                            <StdDivider />
                            <InvoiceItem
                                title={title}
                                date={date}
                                price={
                                    included
                                        ? t('invoice.usage.included')
                                        : parsePrice(price, currency)
                                }
                            />
                        </Fragment>
                    );
                })}
            </ExpandableList>
            <ExpandableList
                title={
                    <div className="inovice__subtotal-container inline-m">
                        <span>{managerTitle}</span>
                        <span className="currency">
                            {parsePrice(managerUsage?.subtotal, currency)}
                        </span>
                    </div>
                }
                isEmpty={!managerLogs.length}
                small
            >
                {managerLogs.map((log) => {
                    const { metadata, included, price } = log;
                    const { id, email, date } = metadata;

                    return (
                        <Fragment key={id}>
                            <StdDivider />
                            <InvoiceItem
                                title={email}
                                date={date}
                                price={
                                    included
                                        ? t('invoice.usage.included')
                                        : parsePrice(price, currency)
                                }
                            />
                        </Fragment>
                    );
                })}
            </ExpandableList>
            <ExpandableList
                title={
                    <div className="inovice__subtotal-container inline-m">
                        <span>
                            {Math.ceil(storageUsage.count)} x 1
                            {storageUsage.unit}
                        </span>
                        <span className="currency">
                            {parsePrice(storageUsage.subtotal, currency)}
                        </span>
                    </div>
                }
                isEmpty={!storageLogs.length}
                small
                last={!planUsage}
            >
                {storageLogs.map((log) => {
                    const { metadata, included, price } = log;
                    const { id, title, date } = metadata;

                    return (
                        <Fragment key={id}>
                            <StdDivider />
                            <InvoiceItem
                                title={title}
                                date={date}
                                price={
                                    included
                                        ? t('invoice.usage.included')
                                        : parsePrice(price, currency)
                                }
                            />
                        </Fragment>
                    );
                })}
            </ExpandableList>
            {planUsage && (
                <ExpandableList
                    title={
                        <div className="inovice__subtotal-container inline-m">
                            <span>
                                {packageTitle} 
                            </span>
                            <span className="currency">
                                {parseFloat(planUsage.base_price)}
                                {currency}
                            </span>
                        </div>
                    }
                    isEmpty
                    small
                    last
                />
            )}
        </div>
    );
};

export default Invoice;
