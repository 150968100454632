import { takeEvery } from 'redux-saga/effects';

import { PATCH_OFFLINE_MEDIA_REQUEST } from '../../actions';
import {
    patchOfflineMedia,
    patchOfflineMediaSuccess,
    patchOfflineMediaFail
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeEvery(
    PATCH_OFFLINE_MEDIA_REQUEST,
    simpleRequest<ReturnType<typeof patchOfflineMedia>>({
        success: patchOfflineMediaSuccess,
        failure: patchOfflineMediaFail,
        getUrl: (action) => `media/${action.medId}/`,
        getBody: (action) => action.payload,
        method: 'PATCH',
        enableOffline: true
    })
);
