import React from 'react';
import clsx from 'clsx';
import { matchSimple } from '../../../utils';

import './std-bullet.scss';

export interface IProps extends IClass {
    type?: 'primary' | 'warn' | 'danger';
}

const StdBullet: React.FC<IProps> = ({ className, type }) => {
    const typeClass = matchSimple()
        .on(type === 'warn', 'std-bullet--warn')
        .on(type === 'danger', 'std-bullet--danger')
        .end();

    return <span className={clsx('std-bullet', typeClass, className)}></span>;
};

export default StdBullet;
