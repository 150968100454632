import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { hasWebcam } from 'detectrtc';
import { MenuItem, MenuSurfaceAnchor } from '@rmwc/menu';

import useMatchMedia from '../../../hooks/useMatchMedia';
import useRTCDetect from '../../../hooks/useRTCDetect';

import StdDropdown from '../StdDropdown';
import StdButton from '../StdButton';
import Camera from '../../misc/Camera';

import './filepicker.scss';
import StdIcon from '../StdIcon';

interface IProps extends IClass {
    title: string;
    accept?: string;
    enableWebcam?: boolean;
    capture?: boolean | string;
    disabled?: boolean;
    backgroundColor?: string;
    onFileUpload?: (files: File[]) => void;
}

const StdFilepicker: React.FC<IProps> = ({
    className,
    backgroundColor,
    title,
    accept = 'image/*',
    enableWebcam,
    capture,
    disabled,
    onFileUpload
}) => {
    const { t } = useTranslation();
    const [cameraOpen, setCameraOpen] = useState(false);
    const [optionsOpen, setOptionsOpen] = useState(false);
    const isDesktop = useMatchMedia('desktop');

    const haveChecksLoaded = useRTCDetect();
    const isDisabled = !haveChecksLoaded || disabled;

    const hasWebcamOption =
        enableWebcam && isDesktop && haveChecksLoaded && hasWebcam;

    const onOptionSelect = useCallback((event: ISelectEvent) => {
        const { value } = event.detail.item.dataset;
        if (value === 'use_camera') setCameraOpen(true);
    }, []);

    const onFileSelected = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const { files } = event.target;
            if (!files) return;

            onFileUpload?.(Array.from(files));
        },
        [onFileUpload]
    );

    const onMediaCreated = useCallback(
        (file: File) => {
            onFileUpload?.([file]);
        },
        [onFileUpload]
    );

    const inputNode = (
        <input
            className="absolute-input"
            type="file"
            accept={accept}
            onChange={onFileSelected}
            capture={capture}
            multiple
        />
    );

    const webcamUploadNote = (
        <div className="file-picker__anchor">
            <MenuSurfaceAnchor>
                <StdDropdown
                    open={optionsOpen}
                    onClose={() => setOptionsOpen(false)}
                    onSelect={onOptionSelect}
                >
                    <MenuItem data-value="use_camera">
                        {t('filepicker.use_webcam')}
                    </MenuItem>
                    <MenuItem className="file-picker__trigger">
                        <span>{t('filepicker.browse_files')}</span>
                        {inputNode}
                    </MenuItem>
                </StdDropdown>

                <button
                    className="file-picker__add"
                    onClick={() => setOptionsOpen(true)}
                    disabled={isDisabled}
                >
                    <StdIcon className="file-picker__add-icon" name="plus" />
                    <p className="file-picker__add-text">{title}</p>
                </button>
            </MenuSurfaceAnchor>
            <Camera
                open={cameraOpen}
                onClose={() => setCameraOpen(false)}
                onFileCreated={onMediaCreated}
            />
        </div>
    );

    const defaultUploadNode = (
        <button
            className={`file-picker__add file-picker__bg-${backgroundColor}`}
            onClick={() => setOptionsOpen(true)}
            disabled={isDisabled}
        >
            <StdIcon className="file-picker__add-icon" name="plus" />
            <p className="file-picker__add-text">{title}</p>
            {haveChecksLoaded && inputNode}
        </button>
    );
    return hasWebcamOption ? webcamUploadNote : defaultUploadNode;
};

export default StdFilepicker;
