export const parseUserAgent = (userAgentString?: string) => {
    const browserRegex =
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i;
    const osRegex =
        /(windows\snt\s\d+\.\d+|mac\sos\sx\s\d+(?:[._]\d+)?|os\sx\s\d+(?:[._]\d+)?|linux\s(?:i[346]|x(?:86_64)?))(?:\s|\;|$)/i;
    const deviceRegex =
        /(iphone|ipod|ipad|android|blackberry|playbook|windows phone|windows|iemobile|mac|macintosh|linux|ubuntu)/i;
    const mobileRegex =
        /(iphone|ipod|ipad|android|blackberry|playbook|windows phone|iemobile)/i;
    const tabletRegex = /(ipad|android|playbook)/i;

    if (userAgentString) {
        const browserMatch = userAgentString.match(browserRegex);
        const osMatch = userAgentString.match(osRegex);
        const deviceMatch = userAgentString.match(deviceRegex);
        const mobileMatch = userAgentString.match(mobileRegex);
        const tabletMatch = userAgentString.match(tabletRegex);

        const parsedData = {
            browser: browserMatch
                ? {
                      name: browserMatch[1].toLowerCase(),
                      version: parseInt(browserMatch[2])
                  }
                : null,
            os: osMatch ? { name: osMatch[1].toLowerCase() } : null,
            device: deviceMatch
                ? {
                      name: deviceMatch[1].toLowerCase(),
                      type: mobileMatch || tabletMatch ? 'mobile' : 'desktop'
                  }
                : null
        };

        if (
            parsedData.device &&
            parsedData.device.name === 'linux' &&
            parsedData.device &&
            parsedData.device.type === 'mobile'
        ) {
            parsedData.device.name = 'android';
        }

        return parsedData;
    } else {
        return '';
    }
};
