import { takeLatest, call } from 'redux-saga/effects';

import { GET_PROTOCOL_ITEMS_REQUEST } from '../../actions';
import {
    protoGetItems,
    protoGetItemsSuccess,
    protoGetItemsFail
} from '../../action-creators';
import { simpleRequest, ISuccessCallback } from '../modules/request';

import { fetchFailError } from '../../../modules/errorHandlers';
import { protocolItemSchema } from '../../reducers/schema';
import { justArray } from '../../../utils';
import { normalize } from 'normalizr';

type IGetProtocolItemsAction = RT<typeof protoGetItems>;

const onSuccess: ISuccessCallback<IGetProtocolItemsAction> = function (data) {
    const { entities, result } = normalize(justArray(data), [
        protocolItemSchema
    ]);
    const { protocol_items = {}, uploads = {} } = entities;

    return {
        store: {
            allIds: result,
            byIds: protocol_items
        },
        uploads
    };
};

export default takeLatest(
    GET_PROTOCOL_ITEMS_REQUEST,
    simpleRequest<IGetProtocolItemsAction>({
        success: protoGetItemsSuccess,
        failure: protoGetItemsFail,
        getUrl: (action) => `protocol/${action.protoId}/protocol_items/`,
        onFail: function* (error: INetworkError) {
            yield call(fetchFailError, 'fetch_items_error', error);
        },
        onSuccess
    })
);
