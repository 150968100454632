import { takeLatest } from 'redux-saga/effects';

import { DPD_ATTR_CRUD_REQUEST } from '../../actions';
import {
    dpdAttrCrud,
    dpdAttrCrudSuccess,
    dpdAttrCrudFail
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeLatest(
    DPD_ATTR_CRUD_REQUEST,
    simpleRequest<ReturnType<typeof dpdAttrCrud>>({
        success: dpdAttrCrudSuccess,
        failure: dpdAttrCrudFail,
        getBody: (action) => action.payload,
        getUrl: (_) => `dpd_attribute/dpd_attribute_crud/`,
        method: 'POST'
    })
);
