import React from 'react';
import clsx from 'clsx';

import { detectScrollEnd } from '../../../modules/scroll';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18next';

import { Select, SelectProps, FormattedOption } from '@rmwc/select';
import StdToolTip from '../StdToolTip';
import useWindowSize from '../../../hooks/useWindowSize';
const MAX_VALUE_LENGTH = 68;
export interface IProps extends IClass, SelectProps {
    value: string;
    name: string;
    label?: string;
    error?: string;
    width?: string;
    native?: boolean;
    children?: React.ReactNode;
    options?: string[] | FormattedOption[] | { [value: string]: string };
    dense?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    onScrollEnd?: ICallback;
}

const StdSelect: React.FC<IProps> = (props) => {
    const {
        value,
        label,
        name,
        children,
        native,
        error,
        options,
        dense,
        className,
        width = 'auto',
        onChange,
        onScrollEnd,
        ...other
    } = props;
    const windowWidth = useWindowSize().width || 0;

    const { t } = useTranslation();
    // console.log('Value in SelectComp-->', {
    //     recievedValue: t(value.toLowerCase()),
    //     lang: i18n.language,
    //     label: label
    // });
    const renderSelect = () => (
        <Select
            {...other}
            className={clsx(
                className,
                'form_select_overflow',
                dense && 'mdc-select--dense'
            )}
            value={value}
            label={label}
            onChange={(e: any) => {
                const {
                    detail: { value = '' }
                } = e as CustomEvent<{ index: number; value?: string }>;
                const data = { value, name };
                onChange?.({ target: data, currentTarget: data } as any);
            }}
            rootProps={{
                style: { width },
                onScroll: (e: React.UIEvent<HTMLDivElement>) => {
                    const element = e.target as HTMLDivElement;
                    if (detectScrollEnd(element, 0)) {
                        onScrollEnd?.();
                    }
                }
            }}
            style={{ width }}
            invalid={Boolean(error)}
            enhanced={!native && { focusOnOpen: false }}
            options={options}
            outlined
        >
            {children}
        </Select>
    );
    // TODO: think of better way to show a tooltip, this wont work in responsive view.
    if (value?.length > MAX_VALUE_LENGTH && windowWidth > 1028) {
        return (
            <div className={'form_select'}>
                <StdToolTip content={value}>{renderSelect()}</StdToolTip>
            </div>
        );
    }
    return renderSelect();
};

export default StdSelect;
