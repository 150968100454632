import { useEffect } from 'react';
import useScrollToContent, { IScrollToOptions } from './useScrollToContent';

const useReactScrollTo = (toggle?: boolean, options?: IScrollToOptions) => {
    const [ref, scrollToNode] = useScrollToContent(options);

    useEffect(() => {
        toggle && scrollToNode();
    }, [toggle, scrollToNode]);

    return ref;
};

export default useReactScrollTo;
