import { takeEvery } from 'redux-saga/effects';
import queryString from 'query-string';

import { GENERATE_PDF_REQUEST } from '../../actions';
import {
    generatePdf,
    generatePdfSuccess,
    generatePdfFail
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeEvery(
    GENERATE_PDF_REQUEST,
    simpleRequest<RT<typeof generatePdf>>({
        success: generatePdfSuccess,
        failure: generatePdfFail,
        getUrl: (action) =>
            `protocol/${
                action.protocolId
            }/protocol_pdf_generate/?${queryString.stringify(action.params)}`,
        enableOffline: true
    })
);
