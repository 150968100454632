import React from 'react';
// @ts-ignore
import FsLightbox from 'fslightbox-react';
import { IUseLightboxProps } from './useLightbox';

import './overrides.scss';

interface IProps extends Omit<IUseLightboxProps, 'openLightbox'> {
    types: string[];
    sources: string[];
}

const StdLightbox: React.FC<IProps> = ({
    toggle,
    slide,
    types,
    sources,
    setSlide
}) => {
    return (
        <FsLightbox
            sourceIndex={slide}
            sources={sources}
            types={types}
            toggler={toggle}
            onClose={() => setSlide(null)}
        />
    );
};

export default StdLightbox;
