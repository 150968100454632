interface standardisedObjectEntity {
    issue_id: number;
    issue_rom_id: {
        rom_id: number;
        rom_level: number;
        rom_name: string;
        rom_ele_count: number;
        rom_roa_id: number;
        rom_has_excessive_issue: false;
        rom_has_normal_issue: false;
        // media_med_path: object[] | number[];
    };
    issue_ele_id: {
        ele_id: number;
        ele_name: string;
        ele_has_items: false;
        // media_med_path: object[] | number[];
    };
    issue_payer_tenant: false;
    issue_title: string;
    issue_date_occured: string;
    issue_fix: false;
    issue_description: string;
    issue_grade: string;
    issue_media: number[];
    issue_dpd_id: {
        dpd_assoc_data: {
            dpd_id: number;
        };
        dpd_id: number;
        dpd_street: string;
        dpd_street_number: number;
    };
}
export const standardiseIssueObject = (type: string, inputObject: { protocolIssueResponse?: any; eleRes?: any; issue?: Partial<IIssue> | undefined; issueElement?: IProtocolElement | { pele_name?: string | undefined; pele_id?: number | undefined; pele_prom_id?: string | undefined; } | undefined; inventoryIssues?: IHash<INormalizedIssue>; protocolElements?: IHash<IProtocolElement>; protocolItems?: IHash<IProtocolItem>; } | any) => { // will fix interface issue

    const standardisedObject: standardisedObjectEntity = {
        issue_id: 0,
        issue_rom_id: {
            rom_id: 0,
            rom_level: 0,
            rom_name: '',
            rom_ele_count: 0,
            rom_roa_id: 0,
            rom_has_excessive_issue: false,
            rom_has_normal_issue: false
            // media_med_path: []
        },
        issue_ele_id: {
            ele_id: 0,
            ele_name: '',
            ele_has_items: false
            // media_med_path: []
        },
        issue_payer_tenant: false,
        issue_title: '',
        issue_date_occured: '',
        issue_fix: false,
        issue_description: '',
        issue_grade: '',
        issue_media: [],
        issue_dpd_id: {
            dpd_assoc_data: {
                dpd_id: 0
            },
            dpd_id: 0,
            dpd_street: '',
            dpd_street_number: 0
        }
    };

    const handleEditIssueModal = (inputObject: any) => { // will fix interface
        const {
            issue_id,
            iss_rom_id: {
                rom_id,
                rom_level,
                rom_name,
                rom_ele_count,
                rom_roa_id,
                rom_has_excessive_issue,
                rom_has_normal_issue
            },
            iss_ele_id: { ele_id, ele_name, ele_has_items },
            issue_payer_tenant,
            issue_title,
            issue_date_occured,
            issue_fix,
            issue_description,
            issue_grade,
            media_med_path,
            issue_dpd_id
        } = inputObject || {};
        const mediaArray = media_med_path.map(
            (media: { med_id: number }) => media.med_id
        );
        return {
            ...standardisedObject,
            issue_id,
            issue_rom_id: {
                rom_id,
                rom_level,
                rom_name,
                rom_ele_count,
                rom_roa_id,
                rom_has_excessive_issue,
                rom_has_normal_issue
            },
            issue_ele_id: {
                ele_id,
                ele_name,
                ele_has_items
            },
            issue_payer_tenant,
            issue_title,
            issue_date_occured,
            issue_fix,
            issue_description,
            issue_grade,
            issue_media: mediaArray,
            issue_dpd_id
        };
    };

    const transformElementIssueHistory = (data: { protocolIssueResponse: any; eleRes: any; }) => { // will make an interface later
        const { protocolIssueResponse: protocolList, eleRes: elementList } =
            data;
        const protocolResolvedIssues = protocolList?.filter(
            (issue: { fix: boolean }) => issue?.fix
        );
        const protocolUnresolvedIssues = protocolList?.filter(
            (issue: { fix: boolean }) => !issue?.fix
        );

        const elementResolvedIssues = elementList?.filter(
            (issue: { iss_fix: boolean }) => issue.iss_fix
        );
        const elementUnresolvedIssues = elementList?.filter(
            (issue: { iss_fix: boolean }) => !issue.iss_fix
        );
        const visibleResolvedIssues =
            protocolResolvedIssues?.length > 0
                ? protocolResolvedIssues
                : elementResolvedIssues;
        const visibleUnresolvedIssues =
            protocolUnresolvedIssues?.length > 0
                ? protocolUnresolvedIssues
                : elementUnresolvedIssues;
        return { visibleResolvedIssues, visibleUnresolvedIssues };
    };

    const transformProtocolIssueModal = (issue: { id: number; piss_id: number; protocol_item: any; }, issueElement: { pele_id: any; }, {inventoryIssues, protocolElements, protocolItems}: any) => { // 
        let resultantObject = {};
        const detailsFromInventoryState = inventoryIssues[issue?.id || issue?.piss_id];
        const detailsFromElementsState = protocolElements[issueElement?.pele_id!]?.issues.find((elementIssue: { piss_id: number; }) => elementIssue.piss_id === issue?.id || elementIssue.piss_id === issue?.piss_id);
        const detailsFromItemsState = protocolItems[issue?.id || issue?.protocol_item]?.issues.find((itemIssue: {piss_id: number}) => itemIssue.piss_id === issue?.id || itemIssue.piss_id === issue?.piss_id);
        resultantObject = detailsFromInventoryState || detailsFromElementsState || detailsFromItemsState;
        return resultantObject;
    }

    // standardisation logic
    switch (type) {
        case 'EDIT_ISSUE_MODAL':
            return handleEditIssueModal(inputObject);
        case 'PROTOCOL_ISSUE_MODAL':
            return standardisedObject;
        case 'EDIT_PROTOCOL_ISSUE_MODAL':
            const {issue, issueElement, inventoryIssues, protocolElements, protocolItems} = inputObject || {};
            return transformProtocolIssueModal(issue, issueElement, {inventoryIssues, protocolElements, protocolItems});
        case 'ELEMENT_ISSUE_HISTORY':
            return transformElementIssueHistory(inputObject);
    }

    return standardisedObject;
};
