import { requestCreatorSet } from '../storeModule';

export const getInvitationsSet = requestCreatorSet(
    'GET_INVITATIONS',
    (type) => {
        return (params: IPaginationParams = { limit: 100 }) => ({
            type,
            params
        });
    }
);

interface IInvitationPayload {
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
}

export const createInvitationSet = requestCreatorSet(
    'CREATE_INVITATION',
    (type) => {
        return (payload: IInvitationPayload, lang: ILang) => ({
            type,
            payload,
            lang
        });
    }
);
export const resendInvitationSet = requestCreatorSet(
    'RESEND_INVITATION',
    (type) => {
        return (id: number, lang: ILang) => ({ type, id, lang });
    }
);
export const deleteInvitationSet = requestCreatorSet(
    'DELETE_INVITATION',
    (type) => {
        return (id: number) => ({ type, id });
    }
);

export const getEnterpriseManagerSet = requestCreatorSet('GET_ENTERPRISE_MANAGERS', (type) => {
    return (params: IPaginationParams = { limit: 100 }) => ({ type, params });
});

export const getManagerSet = requestCreatorSet('GET_MANAGERS', (type) => {
    return (params: IPaginationParams = { limit: 100 }) => ({ type, params });
});
export const deleteManagerSet = requestCreatorSet('DELETE_MANAGER', (type) => {
    return (id: number) => ({ type, id });
});

export const checkTeamInviteSet = requestCreatorSet(
    'CHECK_TEAM_INVITE',
    (type) => {
        return (token: string) => ({ type, token });
    }
);
export const acceptTeamInviteSet = requestCreatorSet(
    'ACCEPT_TEAM_INVITE',
    (type) => {
        return (token: string, password?: string) => ({
            type,
            payload: { token, password }
        });
    }
);
