import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import StdDivider from '../atoms/StdDivider';

interface IProps {
    attrs: IAttribute[];
    disableHint?: boolean;
}

const AttributeList: React.FC<IProps> = ({ attrs, disableHint }) => {
    const { t } = useTranslation();
    return (
        <div>
            {attrs.length || disableHint ? (
                attrs.length > 0 ? (
                    attrs.map((attr, index) => {
                        const isLast = index === attrs.length - 1;
                        const { id, type, value } = attr;
                        return (
                            <Fragment key={id}>
                                <div
                                    className={clsx(
                                        'attribute__container',
                                        !isLast && 'stack-s'
                                    )}
                                >
                                    <div className="attribute__column font--secondary">
                                        {type}
                                    </div>
                                    <div className="attribute__column text__align--right">
                                        {value}
                                    </div>
                                </div>
                                {!isLast && <StdDivider className="stack-s" />}
                            </Fragment>
                        );
                    })
                ) : (
                    <div className="font--secondary">
                        {t('no_attributes_yet')}
                    </div>
                )
            ) : null}
        </div>
    );
};

export default AttributeList;
