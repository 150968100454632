import { takeEvery } from 'redux-saga/effects';

import { DELETE_DPD_REQUEST } from '../../actions';
import {
    deleteDpdSuccess,
    deleteDpdFail,
    IDpdDelete
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeEvery(
    DELETE_DPD_REQUEST,
    simpleRequest<IDpdDelete>({
        success: deleteDpdSuccess,
        failure: deleteDpdFail,
        getUrl: (action) => `dpd/${action.dpdId}/dpd_delete/`,
        method: 'DELETE'
    })
);
