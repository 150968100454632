import { takeEvery } from 'redux-saga/effects';

import { CANCEL_SUBSCRIPTION_REQUEST } from '../../actions';
import {
    cancelSubscription,
    cancelSubscriptionSuccess,
    cancelSubscriptionFail
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeEvery(
    CANCEL_SUBSCRIPTION_REQUEST,
    simpleRequest<ReturnType<typeof cancelSubscription>>({
        success: cancelSubscriptionSuccess,
        failure: cancelSubscriptionFail,
        getUrl: (action) => `subscriptions/cancel?lang=${action.payload.lang}`,
        getBody: (action) => action.payload,
        method: 'DELETE',
        isV2Api: true
    })
);
