import React, { useState, useRef, useCallback } from 'react';
import Exif from 'exif-js';
//@ts-ignore
import exif2css from 'exif2css';
import { IconPrefix, IconName } from '@fortawesome/fontawesome-common-types';

import StdIcon from '../StdIcon';
import clsx from 'clsx';

import './std-image.scss';

// Note: not renerable on it's own due to dimentions being set to 100%
interface IProps extends IClass {
    iconPrefix?: IconPrefix;
    icon?: IconName;
    src?: string;
    alt?: string;
    title?: string;
    checkExif?: boolean;
    contain?: boolean;
    fullWidth?: boolean;
    onClick?: ICallback;
    onImageLoad?: ICallback;
}

const StdImage: React.FC<IProps> = ({
    className,
    src,
    iconPrefix = 'fal',
    icon,
    alt,
    checkExif,
    contain,
    title,
    fullWidth,
    onClick,
    onImageLoad
}) => {
    const imgRef = useRef<HTMLImageElement>(null);

    const [orient, setOrient] = useState(1);
    const [error, setError] = useState<boolean>();

    const handleUpdateOrient = useCallback(() => {
        const img = imgRef.current;
        if (img && checkExif) {
            // Exif js does some cache monkeypatching
            // have to be removed to refresh
            // Also the library is poorly typed

            (img as any).exifdata = null;
            Exif.getData(img as any, () => {
                setOrient(Exif.getTag(img, 'Orientation'));
            });
        }
    }, [checkExif]);

    const onLoad = () => {
        handleUpdateOrient();
        if(onImageLoad) onImageLoad();
    }

    const transInfo: IHash<string> | undefined =
        orient !== 1 ? exif2css(orient) : undefined;
    const imgStyle = transInfo && {
        transform: transInfo['transform'],
        transformOrigin: transInfo['transform-origin']
    };

    const imageClass = clsx(
        'std-picture',
        contain && 'std-picture--contain',
        fullWidth && 'std-picture--full-width',
        className
    );

    if (src && !error) {
        return (
            <img
                className={imageClass}
                ref={imgRef}
                style={imgStyle}
                src={src}
                onClick={onClick}
                onLoad={onLoad}
                onError={() => setError(true)}
                alt={alt}
            />
        );
    }

    const fallbackClass = clsx(
        'std-picture',
        'std-picture--empty',
        error && 'std-picture--error',
        className
    );

    return (
        <div className={fallbackClass} onClick={onClick}>
            {icon && !error && (
                <div>
                    <StdIcon
                        className={clsx(title && 'stack-s')}
                        prefix={iconPrefix}
                        name={icon}
                    />
                    {title && <div>{title}</div>}
                </div>
            )}
            {error && <StdIcon prefix="fal" name="wifi-slash" />}
        </div>
    );
};

export default StdImage;
