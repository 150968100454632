import { takeEvery } from 'redux-saga/effects';

import { GET_PROTOCOL_ROOM_REQUEST } from '../../actions';
import {
    getProtocolRoom,
    getProtocolRoomSuccess,
    getProtocolRoomFail
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

import { normalizeProtocolRoom } from '../modules/normalization';

type IGetProtocolRoomAction = RT<typeof getProtocolRoom>;

export default takeEvery(
    GET_PROTOCOL_ROOM_REQUEST,
    simpleRequest<IGetProtocolRoomAction>({
        success: getProtocolRoomSuccess,
        failure: getProtocolRoomFail,
        getUrl: (action) => `protocol_room/${action.roomId}/`,
        onSuccess: function (data) {
            return normalizeProtocolRoom(data);
        }
    })
);
