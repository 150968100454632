import clsx from 'clsx';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUsageLogs } from '../../../store/action-creators';
import { dateRangeWithTime } from '../../../utils/formatter';
import StdIcon from '../../atoms/StdIcon';

export default function HistoryUsage() {
    const todayDate = moment().format('MMMM YYYY');
    const [date, setDate] = useState<any>(todayDate);
    const [isCurrentMonth, setIsCurrentMonth] = useState<Boolean>(true);
    const dateJoined = useSelector(
        (state: IRootState) => state.user.user.response?.date_joined
    ) as string;
    const invoiceFrom = useSelector(
        (state) =>
            state.user.currentInvoice.response?.current_period.usage.from_date
    );
    const invoiceTo = useSelector(
        (state) =>
            state.user.currentInvoice.response?.current_period.usage.to_date
    );

    const invoiceRange = dateRangeWithTime(invoiceFrom || '', invoiceTo || '');

    const dispatch = useDispatch();
    useEffect(() => {
        !isCurrentMonth &&
            dispatch(
                getUsageLogs(undefined, moment(date).format('YYYY-MM-DD'))
            );
    }, [date]);

    // const currentHandler = () => {
    //     setDate(todayDate);
    // };
    const dateJoinedArr = dateJoined.split(/-/);
    const joinedYear = Number(dateJoinedArr[0]);
    const joinedMonth = Number(dateJoinedArr[1]);
    const activeDate = moment(date).toISOString();
    const activeDateArr = activeDate?.split(/-/) || [];
    const activeYear = Number(activeDateArr[0]);
    const activeMonth = Number(activeDateArr[1]) + 1;

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;

    const hasNext = () => {
        return activeYear < currentYear
            ? true
            : activeYear === currentYear
            ? activeMonth + 1 <= 12
                ? activeMonth + 1 <= currentMonth
                : activeYear + 1 <= currentYear && 1 <= currentMonth
            : false;
    };

    const hasPrev = () => {
        return activeYear > joinedYear
            ? true
            : activeYear === joinedYear
            ? activeMonth - 1 >= 1
                ? activeMonth - 1 >= joinedMonth
                : activeYear - 1 >= joinedYear && 12 >= joinedMonth
            : false;
    };

    const prevHandler = () => {
        const m = moment(date).subtract(1, 'months').format('MMMM YYYY');
        setDate(m);
        setIsCurrentMonth(false);
    };
    const nextHandler = () => {
        const m = moment(date).add(1, 'months').format('MMMM YYYY');
        setDate(m);
        setIsCurrentMonth(false);
    };

    return (
        <div className="invoice-date">
            <StdIcon
                className={clsx(!hasPrev() && 'invoice-date__pickers--disable')}
                name="chevron-left"
                prefix="far"
                onClick={() => {
                    hasPrev() && prevHandler();
                }}
                clickable={hasPrev()}
            />
            <div className="invoice-date__pickers">
                <StdIcon
                    onClick={() => {}}
                    className="inline-s"
                    name="calendar-alt"
                    prefix="fal"
                />
                <span className="invoice-date__month">{date}</span>
            </div>
            <StdIcon
                className={clsx(!hasNext() && 'invoice-date__pickers--disable')}
                name="chevron-right"
                prefix="far"
                clickable={hasNext()}
                onClick={() => {
                    hasNext() && nextHandler();
                }}
            />
            <div className="invoice-date__current">{invoiceRange}</div>
        </div>
    );
}
