// --- Array related helpers

export function iterateTimes(times: number) {
    return Array.from({ length: times });
}

export function iterateOverHash<T>(hash: IHash<T>): T[] {
    return Object.keys(hash).map((id) => hash[id]);
}

export function arrayToHash<K, T>(
    items: K[],
    toEntry: (item: K) => [string, T]
) {
    const hash: IHash<T> = {};
    items.forEach((item) => {
        const [key, value] = toEntry(item);
        hash[key] = value;
    });
    return hash;
}

// Note: should only be used with one type
// won't work with different types, i.e 6 and "6" are deemed eqivalent
// For that use case Set should be used.
export function filterDuplicates<T>(list: T[]) {
    var seenItems: any = {};

    return list.filter((item) => {
        if (!seenItems[item]) {
            seenItems[item] = true;
            return true;
        }
        return false;
    });
}

export const removeAtIndex = <T>(array: T[], index: number) => {
    if (index < 0 || index > array.length) return array;

    return array.slice(0, index).concat(array.slice(index + 1));
};

export const reorder = <T>(arr: T[], from: number, to: number) => {
    const newArr = arr.slice();
    const [removed] = newArr.splice(from, 1);
    newArr.splice(to, 0, removed);
    return newArr;
};

export function findLongestArray<T>(arrays: T[][]): T[] {
    let maxArray: T[] = [];
    for (const currentArray of arrays) {
        if (currentArray.length > maxArray.length) {
            maxArray = currentArray;
        }
    }
    return maxArray;
}

export function replacePrimitve<T>(array: T[], oldValue: T, newValue: T): T[] {
    const index = array.indexOf(oldValue);
    if (index === -1) return array;

    const arrayCopy = array.slice();
    arrayCopy[index] = newValue;

    return arrayCopy;
}

type MapCallback<T, U> = (value: T, index: number, array: T[]) => U;

export function flatMap<T, U>(array: T[], callback: MapCallback<T, U>) {
    return array.flatMap
        ? array.flatMap(callback)
        : fallbackFlatMap(array, callback);
}

export function fallbackFlatMap<T, U>(
    array: T[],
    callback: MapCallback<T, U>
): any[] {
    return flatten(array.map(callback));
}

const flatten = (arr: any[]): any[] => {
    const newArr: any[] = [];

    arr.forEach((ele) => {
        if (Array.isArray(ele)) newArr.push(...flatten(ele));
        else newArr.push(ele);
    });
    return newArr;
};

/*
  TODO: deprecate
  Defaults predicate to finding the supplied key, value pair of id
  Defaults merger to object spread merge
  returns new array with items changed accordingly if predicate matched otherwise returns the supplied array
*/

interface InsertMergePrarms<T> {
    items: T[];
    newItem?: Partial<T>;
    id?: {
        key: keyof T;
        value: number;
    };
    predicate?: (item: T, index: number, items: T[]) => void;
    merger?: (oldItem: T) => T;
}

export const insertMerge = <T>(params: InsertMergePrarms<T>) => {
    const { items, predicate, merger, id, newItem = {} } = params;
    const { key, value } = id || {};

    const predicateFunction =
        predicate ||
        ((item: any) => value != null && key != null && item[key] === value);
    const mergeFunction =
        merger || ((oldItem: T) => ({ ...oldItem, ...newItem }));

    const itemIndex = items.findIndex(predicateFunction);
    return itemIndex !== -1
        ? [
              ...items.slice(0, itemIndex),
              mergeFunction(items[itemIndex]),
              ...items.slice(itemIndex + 1)
          ]
        : items;
};

export const partition = <T>(
    array: T[],
    predicateFn: (item: T) => boolean
): [T[], T[]] => {
    const trues: T[] = [];
    const falses: T[] = [];

    for (const element of array) {
        if (predicateFn(element)) {
            trues.push(element);
        } else {
            falses.push(element);
        }
    }
    return [trues, falses];
};

export const mergeArrays = <T>(arrA: T[], arrB: T[], backwards?: boolean) => {
    return backwards ? arrB.concat(arrA) : arrA.concat(arrB);
};

// Returns an array of key value parirs sorted by groups of values inside the provided object, uses object deep equal
export const nativeBySet = <T, B>(items: T[], itemToObject: (el: T) => B) => {
    const objects = items.map((item) => itemToObject(item));
    const uniqueObjects = deepRemoveDuplicates(objects);

    return uniqueObjects.map((key) => ({
        key,
        value: items.filter((_, index) => deepObjectEqual(key, objects[index]))
    }));
};

// Remove duplicates from array, compares objects recursively by value
const deepRemoveDuplicates = <T>(arr: T[]) => {
    return arr.filter(
        (item1, pos) =>
            arr.findIndex((item2) => deepObjectEqual(item1, item2)) === pos
    );
};

// Check if two objects are 'value equal' recursively
const deepObjectEqual = <T>(
    object1: T,
    object2: T,
    predicate?: ICompareFunction<T>
): boolean => {
    if (typeof object1 !== typeof object2) return false;

    const itemType = typeof object1;

    if (
        Array.isArray(object1) &&
        Array.isArray(object2) &&
        object1.length === object2.length
    ) {
        return object1.every((v1, index) => {
            const v2 = object2[index];
            return deepObjectEqual(v1, v2);
        });
    } else if (Array.isArray(object1) || Array.isArray(object2)) {
        return false;
    } else if (itemType === 'object' && object1 != null && object2 != null) {
        const keys1 = Object.keys(object1) as Array<keyof T>;
        const keys2 = Object.keys(object2) as Array<keyof T>;

        if (keys1.length !== keys2.length) return false;

        return keys1.every((key1, index) => {
            const key2 = keys2[index];
            if (key1 === key2)
                return deepObjectEqual(object1[key1], object2[key2]);
            return false;
        });
    }
    return predicate ? predicate(object1, object2) : object1 === object2;
};

// TODO: unit tests
export function pushToLimit<T>(buffer: T[], size: number, newItem: T) {
    if (buffer.length < size) {
        return buffer.concat(newItem);
    }

    const bufferCopy = buffer.slice();

    bufferCopy.shift();
    bufferCopy.push(newItem);

    return bufferCopy;
}

export function calculateAverage(array: number[]) {
    let sum = 0;
    for (let item of array) {
        sum += item;
    }

    return sum / array.length;
}
