import JSZip from 'jszip';
import FileSaver from 'file-saver';
import env from '../configs/environment';
import { dateWithWithTime } from './formatter';

export const isProduction = env.getProperty('env') === 'production';
export const isDevelopment = env.getProperty('env') === 'development';

export const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === '[::1]' ||
        // 127.0.0.1/8 is considered localhost for IPv4.
        window.location.hostname.match(
            /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
        )
);

export const isDevEnv =
    window.location.hostname === 'dev.aimmo.io' ||
    window.location.hostname === 'dom.aimmo.io';
export const isStageEnv =
    window.location.hostname === 'staging.aimmo.io' ||
    window.location.hostname === 'weisshorn.aimmo.io';

// If hostname is unknown, assume production
// TODO: setup logging in dev/staging
export const isProdEnv = !isDevEnv && !isStageEnv && !isDevelopment;

export function getHostEnv(): string {
    if (isLocalhost) return 'local';
    if (isDevEnv) return 'dev';
    if (isStageEnv) return 'staging';
    return 'production';
}

export const hostEnv = getHostEnv();

// Note: The checks here are basic and don't account for all cases
export const hasMediaSupport = Boolean(
    navigator.mediaDevices && navigator.mediaDevices.getUserMedia
);
export const hasWebAsmSupport =
    typeof WebAssembly === 'object' &&
    typeof WebAssembly.instantiate === 'function';
export const isScanEnabled =
    !isProduction && hasWebAsmSupport && hasMediaSupport;
export const isServiceWorkerSupported = Boolean(navigator.serviceWorker);

export const isServiceWorkerRunning = () =>
    isServiceWorkerSupported &&
    Boolean(navigator.serviceWorker.controller?.state === 'activated');

export const isIOS =
    (/iPad|iPhone|iPod/.test(navigator.platform) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
    !window.MSStream;

export const isMobileQueryStr = '(max-width: 767px)';
export const isTabletQueryStr = '(min-width: 768px) and (max-width: 1024px)';
export const isDeskopQueryStr = '(min-width: 1025px)';
export const isMobileQuery = () => window.matchMedia(isMobileQueryStr);

// @ts-ignore
export const sleep = <T>(ms: number, value?: T) =>
    new Promise<T>((resolve) => setTimeout(() => resolve(value as T), ms));

// ---- match helper implmenetation
const matched = <T>(x: T) => ({
    on: () => matched<T>(x),
    otherwise: () => x,
    end: () => x
});

export const matchSimple = <S = any>() => ({
    on: (pred: boolean, val: S) => (pred ? matched<S>(val) : matchSimple<S>()),
    otherwise: (fn: S) => fn,
    end: () => undefined
});

export const match = <T, S = any>(x: T) => ({
    on: (pred: (x: T) => boolean, fn: (x: T) => S) =>
        pred(x) ? matched<S>(fn(x)) : match<T, S>(x),
    otherwise: (fn: (x: T) => S) => fn(x),
    end: () => undefined
});

export const trimPercent = (percent: string = '') => {
    const stringPercent = String(percent);
    return stringPercent?.endsWith('%')
        ? stringPercent?.substring(0, stringPercent.length - 1)
        : stringPercent;
};

export const omitDecimal = (number: string) => {
    const dotIndex = number.indexOf('.');
    return dotIndex !== -1 ? number.substring(0, dotIndex) : number;
};

export function roundToDecimal(number: number, decimalCount: number = 2) {
    const exponent = Math.pow(10, decimalCount);

    return Math.round((number + Number.EPSILON) * exponent) / exponent;
}

export function justArray<T>(value?: T[] | null): T[] {
    return Array.isArray(value) ? value : [];
}

export const numericToString = (value?: unknown) => {
    if (typeof value !== 'string' && !isNumeric(value)) return '';
    return value?.toString() || '';
};

interface StringLike {
    toString: () => string;
}

export function justString<T extends StringLike>(
    value?: T | null,
    defaultValue: string = ''
): string {
    if (typeof value === 'string') return value;
    return value?.toString() || defaultValue;
}

// Equivalent of 'booleanExpression || undefined'
export const truthyOrNothing = <T>(value: T | false | undefined | null) => {
    return value || undefined;
};

export function intOrNothing(number: unknown) {
    const value = typeof number === 'string' ? parseInt(number) : number;

    if (typeof value === 'number' && Number.isInteger(value)) {
        return value;
    }
    return undefined;
}

export function intOrElse(number: unknown, def: number) {
    const result = intOrNothing(number);
    return result != null ? result : def;
}

export function isNumeric(value: unknown): value is number {
    return typeof value === 'number' && !isNaN(value);
}

export function isInRange(value: number, low: number, high: number) {
    return value >= low && value <= high;
}

export function debounce(funcion: Function, timeout = 1000) {
    let timer: number;
    return (...args: any[]) => {
        clearTimeout(timer);

        // @ts-ignore
        timer = window.setTimeout(() => funcion.apply(this, args), timeout);
    };
}

interface ListToStrConfig {
    // number of entries in a list
    itemLimit?: number;

    // max characters per entry
    charPerItem?: number;

    // min chacracters per entry
    minItemLength?: number;

    // max characters total
    charLimit?: number;
}

const initConfig = {
    itemLimit: 5,
    charPerItem: 10,
    charLimit: Number.POSITIVE_INFINITY,
    minItemLength: 3
};

export const attrListToStr = (
    values: string[],
    userConfig: ListToStrConfig = {}
) => {
    const config = {
        ...initConfig,
        ...userConfig
    };

    const { itemLimit, charPerItem, charLimit, minItemLength } = config;
    const ellispsis = '…, ';
    const separator = ', ';

    let displayedItemsCount = 0;

    const displayedItems = values.slice(0, itemLimit);

    const listString = displayedItems
        .reduce((acc, item) => {
            const nextItemLength =
                item.length > charPerItem
                    ? charPerItem + ellispsis.length
                    : item.length;

            // If we can't fit next item, use substring or return accumulator as is
            if (acc.length + nextItemLength > charLimit) {
                const charsLeft = charLimit - acc.length - ellispsis.length;
                const itemPartial = item.substring(0, charsLeft);

                if (charsLeft >= minItemLength) {
                    displayedItemsCount += 1;
                    return acc.concat(itemPartial.concat(ellispsis));
                }
                return acc;
            }

            displayedItemsCount += 1;

            const trimmedItem =
                item.length > charPerItem
                    ? item.substring(0, charPerItem).trim().concat(ellispsis)
                    : item.concat(separator);
            return acc.concat(trimmedItem);
        }, '')
        .trim();

    const listRest = values.length - displayedItemsCount;

    // Remove trailing comma
    const listVal = listString.endsWith(',')
        ? listString.substring(0, listString.length - 1)
        : listString;

    return {
        listVal,
        listRest
    };
};

export const base64ToBlob = (base64String: string) => {
    const byteCharacters = atob(base64String);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: 'image/png' });
};

export const cleanBase64FromDataURI = (dataURIString: string) => {
    let prefix = ';base64,';
    const URIArray = dataURIString.split(prefix);
    if (URIArray.length) {
        return URIArray[1];
    } else {
        return dataURIString;
    }
};

export const fetchResolvedImageData = async (imageData: [string, any][]) => {
    const imageDataPromises = imageData.map(async (item) => {
        if (item[1]?.type === 'VI' || item[1]?.media?.med_type === 'VI') {
        //     // // running for Videos, temporarily not supporting on UI
        //     // if (item[1]?.media?.med_path) {
        //     //     // If image path is provided
        //     //     const response = await fetch(
        //     //         `https://s3-eu-central-1.amazonaws.com/aimmo-files/${item[1].media.med_path}`
        //     //     );
        //     //     const blob = await response.blob();
        //     //     return {
        //     //         name: `${item[0]}.${
        //     //             item[1]?.media?.med_path?.split('.')[1]
        //     //         }`,
        //     //         blob: blob
        //     //     };
        //     //     // return { name: `${item[0]}.${item[1].path.split('/').pop()?.split('.')[1]}`, blob: blob };
        //     // } else {
        //     //     // If base64 string is provided
        //     //     console.log({
        //     //         name: `${item[0]}.mp4`,
        //     //         base64: item[1].videoBase
        //     //     });
        //     //     return {
        //     //         name: `${item[0]}.mp4`,
        //     //         blob: base64ToBlob(
        //     //             cleanBase64FromDataURI(item[1]?.preview || '')
        //     //         )
        //     //     };
        //     // }
        //     return null;
        } else {
        // running for Images
        if (item[1]?.media?.med_path) {
            // If image path is provided
            const response = await fetch(
                `https://s3-eu-central-1.amazonaws.com/aimmo-files/${item[1].media.med_path}`
            );
            const blob = await response.blob();
            return {
                name: `${item[0]}.${item[1]?.media?.med_path?.split('.')[1]}`,
                blob: blob
            };
        } else {
            // If base64 string is provided
            return {
                name: `${item[0]}.jpg`,
                blob: base64ToBlob(
                    cleanBase64FromDataURI(item[1]?.preview || item[1]?.path || '')
                )
            };
        }
        }
    });
    const resolvedImageData = await Promise.all(imageDataPromises);
    return resolvedImageData;
};

export const handleProtocolDownload = async (
    protocolData: IOfflineProtocol['protocol'],
    imageData: [string, { path: string }][]
) => {
    const resolvedImageData = await fetchResolvedImageData(imageData);

    console.log('this is the resolved Image data ----> ', {
        resolvedImageData
    });
    const zip = new JSZip();
    const protocolNumber = protocolData.pk || 0;
    const downloadDateTime = dateWithWithTime();

    // Add protocol.json to the zip file
    zip.file(
        `aimmo_${downloadDateTime}_protocol_${protocolNumber}.json`,
        JSON.stringify(protocolData)
    );

    // Add media files to the zip file
    const mediaFolder = zip.folder('media');

    if (mediaFolder) {
        resolvedImageData.forEach((item: any) => {
            if (item) {
                mediaFolder.file(item.name, item.blob);
            }
        });

        // Generate the zip file
        zip.generateAsync({ type: 'blob' })
            .then((blob) => {
                // Trigger the download
                FileSaver.saveAs(
                    blob,
                    `aimmo_${downloadDateTime}_protocol_${protocolNumber}.zip`
                );
            })
            .catch((error) => {
                console.error('Error generating zip file:', error);
            });
    } else {
        console.error('Error generating zip file:');
    }
};
