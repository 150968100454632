import {
    LOGO_UPLOAD_LIMIT,
    MAX_LOCAL_IMAGE_WIDTH,
    MAX_LOCAL_IMAGE_HEIGHT
} from '../configs/costants';

export function generateThumbnail(
    videoUrl: string,
    maxWidth = MAX_LOCAL_IMAGE_WIDTH,
    maxHeight = MAX_LOCAL_IMAGE_HEIGHT
): Promise<string> {
    const canvas = document.createElement('canvas');
    const video = document.createElement('video');

    return new Promise((resolve, reject) => {
        try {
            video.setAttribute('playsinline', 'true');
            video.setAttribute('muted', 'true');
            video.src = videoUrl;

            video.currentTime = 0.01;

            video.addEventListener('seeked', () => {
                const coefficient =
                    video.videoWidth > video.videoHeight
                        ? video.videoWidth / maxWidth
                        : video.videoHeight / maxHeight;

                const width = video.videoWidth / coefficient;
                const height = video.videoHeight / coefficient;

                canvas.width = width;
                canvas.height = height;

                const context = canvas.getContext('2d');
                if (!context) {
                    reject(new Error('no_canvas_context'));
                    return;
                }

                context.fillStyle = '#FFFFFF';
                context.fillRect(0, 0, width, height);

                context.drawImage(video, 0, 0, width, height);

                resolve(canvas.toDataURL());
            });

            video.addEventListener('error', (event) => {
                reject(event.error);
            });

            video.load();
        } catch (error) {
            reject(error);
        }
    });
}

export function generateThumbnailFromFile(file: File) {
    const url = URL.createObjectURL(file);
    return generateThumbnail(url).finally(() => URL.revokeObjectURL(url));
}

export function imageToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.addEventListener('load', () => {
            if (typeof reader.result === 'string') resolve(reader.result);
            else reject(new Error('unexpected_result_error'));
        });

        reader.addEventListener('error', () => {
            reject(new Error('unable_to_read_file'));
        });
    });
}

export function blobToFile(blob: Blob, filename: string): File {
    return new File([blob], filename, {
        lastModified: new Date().getTime(),
        type: blob.type
    });
}

export function fileToBlob(file: File): Promise<Blob> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.addEventListener('load', () => {
            if (reader.result instanceof ArrayBuffer)
                resolve(new Blob([reader.result], { type: file.type }));
            else reject(new Error('unexpected_result_error'));
        });
        reader.readAsArrayBuffer(file);
    });
}

export function scaleFileToBlob(
    file: File,
    maxWidth = MAX_LOCAL_IMAGE_WIDTH,
    maxHeight = MAX_LOCAL_IMAGE_HEIGHT
) {
    const url = URL.createObjectURL(file);

    return scaleImageToBlob(url, maxWidth, maxHeight).finally(() =>
        URL.revokeObjectURL(url)
    );
}

export function scaleImageToBlob(
    imageUrl: string,
    maxWidth = MAX_LOCAL_IMAGE_WIDTH,
    maxHeight = MAX_LOCAL_IMAGE_HEIGHT
): Promise<Blob> {
    return scaleImageToCanvas(imageUrl, maxWidth, maxHeight).then((canvas) => {
        return new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
                if (blob == null) {
                    reject(new Error('Resulting blob is null'));
                    return;
                }
                resolve(blob);
            });
        });
    });
}

export function scaledImageToBase64(
    imageUrl: string,
    maxWidth = MAX_LOCAL_IMAGE_WIDTH,
    maxHeight = MAX_LOCAL_IMAGE_HEIGHT
): Promise<string> {
    return scaleImageToCanvas(imageUrl, maxWidth, maxHeight).then((canvas) =>
        canvas.toDataURL('image/png')
    );
}

export function scaleImageToCanvas(
    imageUrl: string,
    maxWidth: number,
    maxHeight: number
): Promise<HTMLCanvasElement> {
    return new Promise((resolve, reject) => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        if (!context) {
            reject(Error('No 2d context'));
            return;
        }

        const image = new Image();
        image.src = imageUrl;
        image.onload = () => {
            const coefficient =
                image.naturalWidth > image.naturalHeight
                    ? maxWidth / image.naturalWidth
                    : maxHeight / image.naturalHeight;

            const width = image.naturalWidth * coefficient;
            const height = image.naturalHeight * coefficient;

            canvas.width = width;
            canvas.height = height;

            context.drawImage(image, 0, 0, width, height);
            resolve(canvas);
        };

        image.onerror = (error) => {
            reject(error);
        };
    });
}

export function scaleImageFromFile(
    file: File,
    maxcWidth = MAX_LOCAL_IMAGE_WIDTH,
    maxHeight = MAX_LOCAL_IMAGE_HEIGHT
) {
    const url = URL.createObjectURL(file);
    return scaledImageToBase64(url, maxcWidth, maxHeight).finally(() =>
        URL.revokeObjectURL(url)
    );
}

export const validateLogo = (file: File) => {
    const image = new Image();

    return new Promise<boolean | string>((resolve) => {
        if (file.size > LOGO_UPLOAD_LIMIT) {
            resolve('logo_too_big_error');
        }

        image.onload = () => {
            if (image.height > image.width) {
                resolve('logo_wrong_ratio_error');
            }
            resolve(true);
        };

        image.onerror = () => resolve(false);

        image.src = URL.createObjectURL(file);
    }).finally(() => URL.revokeObjectURL(image.src));
};

// Legacy utilities
export async function stripExif(
    file: File,
    maxWidth: number | undefined = undefined
) {
    return readOrientation(file).then((orientation) => {
        if (orientation === undefined || orientation === 1) return;
        return applyRotation(file, orientation || 1, maxWidth || 4096);
    });
}

/**
 * @returns EXIF orientation value (or undefined)
 */
const readOrientation = (file: File) =>
    new Promise<number | undefined>((resolve) => {
        const reader = new FileReader();

        reader.onload = () =>
            resolve(
                (() => {
                    const view = new DataView(reader.result as ArrayBuffer);

                    if (view.getUint16(0, false) !== 0xffd8) {
                        return;
                    }

                    const length = view.byteLength;
                    let offset = 2;

                    while (offset < length) {
                        const marker = view.getUint16(offset, false);
                        offset += 2;

                        if (marker === 0xffe1) {
                            offset += 2;

                            if (view.getUint32(offset, false) !== 0x45786966) {
                                return;
                            }
                            offset += 6;

                            const little =
                                view.getUint16(offset, false) === 0x4949;
                            offset += view.getUint32(offset + 4, little);

                            const tags = view.getUint16(offset, little);
                            offset += 2;

                            for (let i = 0; i < tags; i++) {
                                if (
                                    view.getUint16(offset + i * 12, little) ===
                                    0x0112
                                ) {
                                    return view.getUint16(
                                        offset + i * 12 + 8,
                                        little
                                    );
                                }
                            }
                        } else if ((marker & 0xff00) !== 0xff00) {
                            break;
                        } else {
                            offset += view.getUint16(offset, false);
                        }
                    }
                })()
            );

        reader.readAsArrayBuffer(file.slice(0, 64 * 1024));
    });

const applyRotation = (file: File, orientation: number, maxWidth: number) =>
    new Promise<File>((resolve, reject) => {
        const canvas = document.createElement('canvas');
        const url = URL.createObjectURL(file);
        try {
            const image = new Image();

            image.onload = () => {
                const context = canvas.getContext('2d')!;

                let { width, height } = image;

                const [outputWidth, outputHeight] =
                    orientation >= 5 && orientation <= 8
                        ? [height, width]
                        : [width, height];

                const scale =
                    outputWidth > maxWidth ? maxWidth / outputWidth : 1;

                width = width * scale;
                height = height * scale;

                // set proper canvas dimensions before transform & export
                canvas.width = outputWidth * scale;
                canvas.height = outputHeight * scale;

                // transform context before drawing image
                switch (orientation) {
                    case 2:
                        context.transform(-1, 0, 0, 1, width, 0);
                        break;
                    case 3:
                        context.transform(-1, 0, 0, -1, width, height);
                        break;
                    case 4:
                        context.transform(1, 0, 0, -1, 0, height);
                        break;
                    case 5:
                        context.transform(0, 1, 1, 0, 0, 0);
                        break;
                    case 6:
                        context.transform(0, 1, -1, 0, height, 0);
                        break;
                    case 7:
                        context.transform(0, -1, -1, 0, height, width);
                        break;
                    case 8:
                        context.transform(0, -1, 1, 0, 0, width);
                        break;
                    default:
                        break;
                }
                context.drawImage(image, 0, 0, width, height);

                // export file
                canvas.toBlob((blob) => {
                    blob && resolve(new File([blob], file.name));
                    canvas.remove();
                }, 'image/jpeg');
            };
            image.src = url;
        } catch (error) {
            reject(error);
        } finally {
            URL.revokeObjectURL(url);
            canvas.remove();
        }
    });

export function scaleCanvas(srcCanvas: HTMLCanvasElement, scale: number) {
    const width = srcCanvas.width * scale;
    const height = srcCanvas.height * scale;

    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;

    const cctx = canvas.getContext('2d');
    cctx?.drawImage(
        srcCanvas,
        0,
        0,
        srcCanvas.width,
        srcCanvas.height,
        0,
        0,
        width,
        height
    );
    return canvas;
}
