import { takeLatest } from 'redux-saga/effects';
import { RESEND_VERIFICATION_REQUEST } from '../../actions';
import {
    resendVerification,
    resendVerificationSuccess,
    resendVerificationFail
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeLatest(
    RESEND_VERIFICATION_REQUEST,
    simpleRequest<ReturnType<typeof resendVerification>>({
        success: resendVerificationSuccess,
        failure: resendVerificationFail,
        getUrl: (action) => `auth-users/resend_activation/lang=${action.lang}`,
        getBody: (action) => ({ email: action.email }),
        method: 'POST',
        withAuth: false
    })
);
