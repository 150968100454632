import { takeLatest } from 'redux-saga/effects';

import { FINISH_PROTOCOL_REQUEST } from '../../actions';
import {
    finishProtocolSuccess,
    finishProtocolFail,
    finishProtocol
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeLatest(
    FINISH_PROTOCOL_REQUEST,
    simpleRequest<RT<typeof finishProtocol>>({
        success: finishProtocolSuccess,
        failure: finishProtocolFail,
        getUrl: (action) => `protocol/${action.protoId}/protocol_finish/`,
        enableOffline: true,
        noBlock: true
    })
);
