import * as actions from '../actions/offlineProtocol';
import { createAction, createCustomAction } from 'typesafe-actions';
import { actionFail, actionPayload } from '../storeModule';
import { Assign } from 'utility-types';

interface IAttendeeParams {
    att_email?: string;
    att_firstname?: string;
    att_lastname?: string;
    att_phone?: string;
    att_picture?: number;
    address?: Partial<IAddress>;
    att_present: boolean;
}

type IModAttendee = Assign<
    Omit<IAttendee, 'att_id' | 'uploads'>,
    IAttendeeParams
>;

export const getOfflineProtocolCheckoutId = createCustomAction(
    actions.GET_OFFLINE_PROTOCOL_CHECKOUT_ID_REQUEST,
    (type) => {
        return (
            protoId: number,
            overrideValue: {
                override?: Boolean;
                session_id?: string | null;
                check_validity?: boolean;
            } = { override: false, session_id: null, check_validity: false },
            noUpdate: Boolean = false
        ) => ({ type, protoId, overrideValue, noUpdate });
    }
);
export const getOfflineProtocolCheckoutIdSuccess = createCustomAction(
    actions.GET_OFFLINE_PROTOCOL_CHECKOUT_ID_SUCCESS,
    actionPayload
);
export const getOfflineProtocolCheckoutIdFail = createCustomAction(
    actions.GET_OFFLINE_PROTOCOL_CHECKOUT_ID_FAIL,
    actionFail
);

export const syncOfflineProtocol = createCustomAction(
    actions.POST_OFFLINE_PROTOCOL_REQUEST,
    (type) => {
        return (
            prt_id: number,
            payload: IOfflineProtocol | Partial<IOfflineProtocol>
        ) => ({
            type,
            prt_id,
            payload
        });
    }
);
export const syncOfflineProtocolSuccess = createCustomAction(
    actions.POST_OFFLINE_PROTOCOL_SUCCESS,
    actionPayload
);
export const syncOfflineProtocolFail = createCustomAction(
    actions.POST_OFFLINE_PROTOCOL_FAIL,
    actionFail
);
export const patchOfflineProtocolStep = createCustomAction(
    actions.PATCH_OFFLINE_PROTOCOL_STEP,
    (type) => {
        return (
            protoId: number,
            payload: {
                current_step?: string;
                prt_stage: {
                    items_done?: boolean;
                    rooms_done?: boolean;
                    meters_done?: boolean;
                    attendees_done?: boolean;
                };
            }
        ) => ({
            type,
            protoId,
            payload
        });
    }
);
export const patchOfflineProtocolMeters = createCustomAction(
    actions.PATCH_OFFLINE_PROTOCOL_METERS,
    (type) => {
        return (
            protoId: number,
            payload: {
                prt_meter_electricity: string | null;
                prt_meter_gas: string | null;
                prt_meter_water: string | null;
            }
        ) => ({
            type,
            protoId,
            payload
        });
    }
);
export const patchOfflineProtocol = createCustomAction(
    actions.PATCH_OFFLINE_PROTOCOL,
    (type) => {
        return (protoId: number, payload: IOfflineProtocol) => ({
            type,
            protoId,
            payload
        });
    }
);
export const patchOfflineAttendee = createCustomAction(
    actions.PATCH_OFFLINE_ATTENDEE_REQUEST,
    (type) => {
        return (id: number, isPresent: boolean) => ({ type, id, isPresent });
    }
);

interface IOfflineAttendee {
    att_present?: boolean;
    att_contract_start_date?: string;
    att_contract_end_date?: string;
    att_type?: string;
    att_firstname?: string;
    att_lastname?: string;
    att_phone?: string;
    att_email?: string;
    address?: Partial<IAddress> | undefined;
}

// CREATE actions
export const createOfflineProtocolAttendees = createCustomAction(
    actions.CREATE_OFFLINE_PROTOCOL_ATTENDEES,
    (type) => {
        return (attendee: IOfflineAttendee) => ({ type, attendee });
    }
);

interface RoomPayloadEntity {
    prom_prt_id?: number;
    prom_dpd_id?: number;
    prom_name?: string;
    prom_level?: number;
}

export const createOfflineProtocolRoom = createCustomAction(
    actions.CREATE_OFFLINE_PROTOCOL_ROOM,
    (type) => {
        return (room: RoomPayloadEntity) => ({
            type,
            room
        });
    }
);

interface INewElement {
    pele_prom_id?: number;
    pele_name?: string;
    pele_ok?: boolean;
}

export const createOfflineProtocolElement = createCustomAction(
    actions.CREATE_OFFLINE_PROTOCOL_ELEMENT,
    (type) => {
        return (element: INewElement) => ({
            type,
            element
        });
    }
);
export const createOfflineProtocolItem = createCustomAction(
    actions.CREATE_OFFLINE_PROTOCOL_ITEM,
    (type) => {
        return (item: ItemsEntity, attr: Partial<IProtocolItemAttr>[]) => ({
            type,
            item,
            attr
        });
    }
);

export const createOfflineProtocolIssue = createCustomAction(
    actions.CREATE_OFFLINE_PROTOCOL_ISSUE,
    actionPayload
);

export const handleOfflineProtocolAttributeCrd = createCustomAction(
    actions.OFFLINE_PROTOCOL_ATTRIBUTE_CRD,
    actionPayload
);
export const handleOfflineProtocolAttributeUpdate = createCustomAction(
    actions.OFFLINE_PROTOCOL_ATTRIBUTE_UPDATE,
    actionPayload
);

type IProtoModRoom = Assign<
    Omit<IProtocolRoom, 'prom_id' | 'prom_rom_id'>,
    { media_med_id?: number }
>;
type IProtocolElementMod = Assign<
    Omit<IProtocolElement, 'pele_id' | 'pele_ele_id' | 'uploads'>,
    { pele_prom_id: number; media_med_id?: number[] }
>;

type IProtocolElementPatchPayload = Omit<IProtocolElementMod, 'pele_prom_id'>;

interface payloadRoom {
    prom_prt_id: number;
    prom_dpd_id: number;
    template_name: string | undefined;
    prom_name: string;
    prom_type: string | undefined;
    prom_level: number;
}

// UPDATE actions
export const updateOfflineProtocolAttendees = createCustomAction(
    actions.UPDATE_OFFLINE_PROTOCOL_ATTENDEES,
    (type) => {
        return (att_id: number, attendee: IOfflineAttendee) => ({
            type,
            att_id,
            attendee
        });
    }
);
export const updateOfflineProtocolRoom = createCustomAction(
    actions.UPDATE_OFFLINE_PROTOCOL_ROOM,
    (type) => {
        return (roomId: number, room: payloadRoom) => ({
            type,
            roomId,
            room
        });
    }
);

interface IUpdatedElement {
    pele_name?: string;
    pele_date_installation?: string | null;
    media_med_id?: number[] | null;
    uploads?: number[] | null;
}

export const updateOfflineProtocolElement = createCustomAction(
    actions.UPDATE_OFFLINE_PROTOCOL_ELEMENT,
    (type) => {
        return (
            pele_prom_id: number,
            pele_id: number,
            element: IUpdatedElement
        ) => ({
            type,
            pele_prom_id,
            pele_id,
            element
        });
    }
);
export const updateOfflineProtocolItem = createCustomAction(
    actions.UPDATE_OFFLINE_PROTOCOL_ITEM,
    (type) => {
        return (
            itemId: number,
            item: ItemsEntity,
            attr: Partial<IProtocolItemAttr>[]
        ) => ({
            type,
            itemId,
            item,
            attr
        });
    }
);
export const offlineProtoPatchItem = createCustomAction(
    actions.PATCH_OFFLINE_PROTOCOL_ITEMS_CHECK,
    (type) => {
        return (pitm_id: number, pitm_ok: boolean) => ({
            type,
            pitm_id,
            pitm_ok
        });
    }
);
export const offlineProtoPatchRoom = createCustomAction(
    actions.PATCH_OFFLINE_PROTOCOL_ROOM,
    (type) => {
        return (id: number, payload: IProtoModRoom, lang: ILang) => ({
            type,
            id,
            payload,
            lang
        });
    }
);
export const offlineProtoPatchElement = createCustomAction(
    actions.PATCH_OFFLINE_PROTOCOL_ROOM_ELEMENT,
    (type) => {
        return (payload: IProtocolElementPatchPayload) => ({
            type,
            payload
        });
    }
);

export const offlineProtoPatchComment = createCustomAction(
    actions.PATCH_OFFLINE_PROTOCOL_NOTES,
    (type) => {
        return (prt_id: number, prt_comment: string) => ({
            type,
            prt_id,
            prt_comment
        });
    }
);
export const offlineProtoPatchCleaning = createCustomAction(
    actions.PATCH_OFFLINE_PROTOCOL_CLEANING,
    (type) => {
        return (prt_id: number, payload: { prt_date_cleaning: string }) => ({
            type,
            prt_id,
            payload
        });
    }
);

export const updateOfflineProtocolIssue = createCustomAction(
    actions.UPDATE_OFFLINE_PROTOCOL_ISSUE,
    actionPayload
);

// DELETE actions
export const deleteOfflineProtocolAttendees = createCustomAction(
    actions.DELETE_OFFLINE_PROTOCOL_ATTENDEES,
    actionPayload
);
export const deleteOfflineProtocolRoom = createCustomAction(
    actions.DELETE_OFFLINE_PROTOCOL_ROOM,
    (type) => {
        return (roomId: number) => ({
            type,
            roomId
        });
    }
);
export const deleteOfflineProtocolElement = createCustomAction(
    actions.DELETE_OFFLINE_PROTOCOL_ELEMENT,
    (type) => {
        return (pele_prom_id: number, pele_id: number) => ({
            type,
            pele_prom_id,
            pele_id
        });
    }
);
export const deleteOfflineProtocolItem = createCustomAction(
    actions.DELETE_OFFLINE_PROTOCOL_ITEM,
    (type) => {
        return (itemId: number) => ({
            type,
            itemId
        });
    }
);

export const deleteOfflineProtocolIssue = createCustomAction(
    actions.DELETE_OFFLINE_PROTOCOL_ISSUE,
    actionPayload
);

export const discardOfflineProtocolState = createAction(
    actions.DISCARD_OFFLINE_PROTOCOL_STATE
);

// misc
export const offlineProtocolQuickAddRoom = createCustomAction(
    actions.OFFLINE_PROTOCOL_ROOM_QUICKADD,
    actionPayload
);

export const normaliseOfflineProtocolMedia = createCustomAction(
    actions.NORMALISE_ASYNC_PROTOCOL_OFFLINE_MEDIA,
    (type) => {
        return (normaliseType: 'PDF' | 'JSON', uploadedData: any) => ({
            type,
            normaliseType,
            uploadedData
        });
    }
);

type IModSignature = Assign<
    Omit<ISignature, 'id' | 'attendee_id' | 'uploads'>,
    { attendee_id: number; protocol_id: number; media_id?: number }
>;

export const offlineProtocolPostSignature = createCustomAction(
    actions.OFFLINE_PROTOCOL_POST_SIGNATURE_REQUEST,
    (type) => {
        return (signature: IModSignature) => ({ type, signature });
    }
);
export const offlineProtocolPostSignatureSuccess = createCustomAction(
    actions.OFFLINE_PROTOCOL_POST_SIGNATURE_SUCCESS,
    actionPayload
);
export const offlineProtocolPostSignatureFail = createCustomAction(
    actions.OFFLINE_PROTOCOL_POST_SIGNATURE_FAIL,
    actionFail
);
export const offlineProtocolDeleteSignature = createCustomAction(
    actions.OFFLINE_PROTOCOL_SIGNATURE_DELETE,
    (type) => {
        return (signId: number) => ({ type, signId });
    }
);

// mode switcher
export const switchModeAsynchronous = createAction(
    actions.SWITCH_OFFLINE_PROTOCOL_ASYNCHRONOUS
);
export const switchModeSynchronous = createAction(
    actions.SWITCH_OFFLINE_PROTOCOL_SYNCHRONOUS
);

// mode switch helpers
export const onlinePostProtocolSuccess = createCustomAction(
    'POST_PROTOCOL_SUCCESS',
    actionPayload
);
export const switchConnectivtiyChangeOffline = createAction(
    'CONNECTIVITY_CHANGE_OFFLINE'
);
export const getAttendessSyncFail = createCustomAction(
    'GET_ATTENDEES_FAIL',
    actionFail
);
export const protoGetRoomsSyncFail = createCustomAction(
    'GET_PROTOCOL_ROOMS_FAIL',
    actionFail
);
export const protoGetItemsSyncFail = createCustomAction(
    'GET_PROTOCOL_ITEMS_FAIL',
    actionFail
);
export const getProtocolIssueSyncFail = createCustomAction(
    'GET_PROTOCOL_ISSUES_FAIL',
    actionFail
);
export const postAttendeeSyncFail = createCustomAction(
    'POST_ATTENDEE_FAIL',
    actionFail
);
export const protoPostEleSyncFail = createCustomAction(
    'POST_PROTOCOL_ELEMENT_FAIL',
    actionFail
);
export const protoPostItemSyncFail = createCustomAction(
    'POST_PROTOCOL_ITEM_FAIL',
    actionFail
);
export const protoPostRoomSyncFail = createCustomAction(
    'POST_PROTOCOL_ROOM_FAIL',
    actionFail
);
export const postProtocolIssueSyncFail = createCustomAction(
    'POST_PROTOCOL_ISSUE_FAIL',
    actionFail
);
export const protoEleAttrCrudSyncFail = createCustomAction(
    'POST_PROTOCOL_ELEMENT_ATTRIBUTES_FAIL',
    actionFail
);
export const patchAttendeeSyncFail = createCustomAction(
    'PATCH_ATTENDEE_FAIL',
    actionFail
);
export const protoPatchRoomSyncFail = createCustomAction(
    'PATCH_PROTOCOL_ROOM_FAIL',
    actionFail
);
export const protoPatchEleSyncFail = createCustomAction(
    'PATCH_PROTOCOL_ELEMENT_FAIL',
    actionFail
);
export const protoPatchItemSyncFail = createCustomAction(
    'PATCH_PROTOCOL_ITEM_FAIL',
    actionFail
);
export const patchProtocolIssueSyncFail = createCustomAction(
    'PATCH_PROTOCOL_ISSUE_FAIL',
    actionFail
);
export const patchProtocolSyncFail = createCustomAction(
    'PATCH_PROTOCOL_FAIL',
    actionFail
);
export const delAttendeeSyncFail = createCustomAction(
    'DEL_ATTENDEE_FAIL',
    actionFail
);
export const protoDelRoomSyncFail = createCustomAction(
    'DELETE_PROTOCOL_ROOM_FAIL',
    actionFail
);
export const protoDelEleSyncFail = createCustomAction(
    'DELETE_PROTOCOL_ELEMENT_FAIL',
    actionFail
);
export const protoDelItemSyncFail = createCustomAction(
    'DELETE_PROTOCOL_ITEM_FAIL',
    actionFail
);
export const deleteProtocolIssueSyncFail = createCustomAction(
    'DELETE_PROTOCOL_ISSUE_FAIL',
    actionFail
);
export const delProtocolSyncFail = createCustomAction(
    'DELETE_PROTOCOL_FAIL',
    actionFail
);

//pdf collection
export const checkOfflinePdfReady = createCustomAction(
    actions.CHECK_OFFLINE_PDF_READY_REQUEST,
    (type) => {
        return (protoId: number) => ({ type, protoId });
    }
);
export const checkOfflinePdfReadySuccess = createCustomAction(
    actions.CHECK_OFFLINE_PDF_READY_SUCCESS,
    actionPayload
);
export const checkOfflinePdfReadyFail = createCustomAction(
    actions.CHECK_OFFLINE_PDF_READY_FAIL,
    actionFail
);

// meters media patch
export const patchOfflineMedia = createCustomAction(
    actions.PATCH_OFFLINE_MEDIA_REQUEST,
    (type) => {
        return (medId: number, payload: any) => ({
            type,
            medId,
            payload
        });
    }
);
export const patchOfflineMediaSuccess = createCustomAction(
    actions.PATCH_OFFLINE_MEDIA_SUCCESS,
    actionPayload
);
export const patchOfflineMediaFail = createCustomAction(
    actions.PATCH_OFFLINE_MEDIA_FAIL,
    actionFail
);

// tenant deposit patch
export const patchOfflineUsedTenantDeposit = createCustomAction(
    actions.PATCH_USED_TENANT_DEPOSIT,
    actionPayload
);

// ID Updaters
export const postAttendeeSyncSuccess = createCustomAction(
    'POST_ATTENDEE_SUCCESS',
    actionPayload
);
export const protoPostRoomSyncSuccess = createCustomAction(
    'POST_PROTOCOL_ROOM_SUCCESS',
    actionPayload
);
export const protoPostEleSyncSuccess = createCustomAction(
    'POST_PROTOCOL_ELEMENT_SUCCESS',
    actionPayload
);
export const protoPostItemSyncSuccess = createCustomAction(
    'POST_PROTOCOL_ITEM_SUCCESS',
    actionPayload
);
export const protoEleAttrCrudSyncSuccess = createCustomAction(
    'POST_PROTOCOL_ELEMENT_ATTRIBUTES_SUCCESS',
    actionPayload
);
export const postProtocolIssueSyncSuccess = createCustomAction(
    'POST_PROTOCOL_ISSUE_SUCCESS',
    actionPayload
);

export const offlineProtocolFinishState = createCustomAction(
    actions.OFFLINE_PROTOCOL_FINISH_STATE,
    actionPayload
);
// update Media Id helpers
export const createOfflineMediaRequest = createCustomAction(
    'CREATE_MEDIA_REQUEST',
    actionPayload
);
export const setOfflineMediaPreview = createCustomAction(
    'SET_MEDIA_UPLOAD_PREVIEW',
    actionPayload
);
export const setOfflineThumbnailPreview = createCustomAction(
    'SET_MEDIA_UPLOAD_THUMBNAIL_PREVIEW',
    actionPayload
);
export const createOfflineMediaSuccess = createCustomAction(
    'CREATE_MEDIA_SUCCESS',
    actionPayload
);
export const createOfflineMediaFail = createCustomAction(
    'CREATE_MEDIA_FAIL',
    actionFail
);
export const createOfflineMediaObject = createCustomAction(
    'CREATE_MEDIA_OBJECT',
    (type) => {
        return (file: File, params: IHash<string | number>) => ({
            type,
            file,
            params
        });
    }
);

// Async Syncing Helpers
export const initAsyncSyncing = createAction(actions.INITIATE_OFFLINE_SYNCING);

export const endAsyncSyncing = createAction(actions.END_OFFLINE_SYNCING);

export const asyncSyncUploadingProgress = createCustomAction(
    actions.ASYNC_SYNC_UPLOADING_PROGRESS,
    actionPayload
);

// protocol state update on online finish
export const offlineFinishProtocolSuccess = createAction(
    'FINISH_PROTOCOL_SUCCESS'
);

// asynchronous protocol sync and protocol finisher
export const syncOfflineProtocolPreview = createCustomAction(
    actions.SYNC_OFFLINE_PROTOCOL_PREVIEW_REQUEST,
    (type) => {
        return (
            prt_id: number,
            payload: IOfflineProtocol | Partial<IOfflineProtocol>
        ) => ({
            type,
            prt_id,
            payload
        });
    }
);
export const syncOfflineProtocolPreviewSuccess = createCustomAction(
    actions.SYNC_OFFLINE_PROTOCOL_PREVIEW_SUCCESS,
    actionPayload
);
export const syncOfflineProtocolPreviewFail = createCustomAction(
    actions.SYNC_OFFLINE_PROTOCOL_PREVIEW_FAIL,
    actionFail
);

// setting offlineProtocol syncingState
export const setOfflineProtocolSyncingState = createCustomAction(
    actions.SET_OFFLINE_PROTOCOL_SYNCING_STATE,
    (type) => {
        return (syncState: 'CLOSED' | 'SYNCING' | 'SUCCESS' | 'ERROR') => ({
            type,
            syncState
        });
    }
);

// background sync helpers
export const offlineBackgroundSyncStart = createAction('BACKGROUND_SYNC_START');
export const offlineBackgroundSyncFail = createCustomAction(
    'BACKGROUND_SYNC_FAIL',
    (type) => {
        return (error: string) => ({ type, error });
    }
);

// offline protocol checkin status
export const postOfflineProtocolCheckinStatus = createCustomAction(
    actions.POST_OFFLINE_PROTOCOL_CHECKIN_STATUS_REQUEST,
    (type) => {
        return (
            prt_id: number,
            payload: IOfflineProtocol | Partial<IOfflineProtocol>,
            shouldNotUpdateState: boolean = false
        ) => ({
            type,
            prt_id,
            payload,
            shouldNotUpdateState
        });
    }
);
export const postOfflineProtocolCheckinStatusSuccess = createCustomAction(
    actions.POST_OFFLINE_PROTOCOL_CHECKIN_STATUS_SUCCESS,
    actionPayload
);
export const postOfflineProtocolCheckinStatusFail = createCustomAction(
    actions.POST_OFFLINE_PROTOCOL_CHECKIN_STATUS_FAIL,
    actionFail
);
export const resetProtocolInvalidCheckin = createAction(
    actions.RESET_PROTOCOL_INVALID_CHECKIN
);

export const offlineProtocolReadyToCheckin = createAction(
    actions.OFFLINE_PROTOCOL_READY_TO_CHECKIN
);
export const savingOfflineProtocolData = createCustomAction(
    actions.SAVING_OFFLINE_PROTOCOL_DATA,
    (type) => {
        return (isSaving: boolean) => ({
            type,
            isSaving
        });
    }
);
export const setOpenCheckinFailModal = createCustomAction(
    actions.SET_OPEN_CHECKIN_FAIL_MODAL,
    (type) => {
        return (shouldOpen: boolean) => ({
            type,
            shouldOpen
        });
    }
);
export const setCheckinFailCode = createCustomAction(
    actions.SET_CHECKIN_FAIL_CODE,
    (type) => {
        return (errorCode: number) => ({
            type,
            errorCode
        });
    }
);

interface OfflineProtocolSaveConfig {
    exitPath?: string | null;
    willExit?: boolean;
    isAutosave?: boolean;
    openCheckoutWarningOnFail?: boolean;
    showBannerWhenOffline?: boolean;
    willFinishProtocol?: boolean;
    generatePdf?: () => Promise<unknown> | undefined;
}

export const runProtocolSave = createCustomAction(
    actions.RUN_OFFLINE_PROTOCOL_SAVE_REQUEST,
    (type) => {
        return ({
            exitPath = null,
            willExit = false,
            isAutosave = false,
            openCheckoutWarningOnFail = false,
            showBannerWhenOffline = false,
            willFinishProtocol = false,
            generatePdf = () => undefined
        }: OfflineProtocolSaveConfig = {}) => ({
            type,
            exitPath,
            willExit,
            isAutosave,
            openCheckoutWarningOnFail,
            showBannerWhenOffline,
            willFinishProtocol,
            generatePdf
        });
    }
);
export const failProtocolSave = createAction(
    actions.RUN_OFFLINE_PROTOCOL_SAVE_FAIL
);
export const skipProtocolSave = createAction(
    actions.RUN_OFFLINE_PROTOCOL_SAVE_SKIP
);
export const clearProtocolSave = createAction(
    actions.RUN_OFFLINE_PROTOCOL_SAVE_SUCCESS
);

// Protocol state Closure
export const completeAndFinishOfflineProtocol = createAction(
    actions.OFFLINE_PROTOCOL_CLOSE_AND_FINISH
);

export const setOpenFinishBanner = createCustomAction(
    actions.SET_OPEN_FINISH_BANNER,
    (type) => {
        return (openFinishBanner: boolean) => ({
            type,
            openFinishBanner
        });
    }
);

export const checkOfflineProtocolFinishReadiness = createCustomAction(
    actions.POST_OFFLINE_PROTOCOL_CHECK_FINISH_READINESS_REQUEST,
    (type) => {
        return (prt_id: number) => ({
            type,
            prt_id
        });
    }
);
export const checkOfflineProtocolFinishReadinessSuccess = createCustomAction(
    actions.POST_OFFLINE_PROTOCOL_CHECK_FINISH_READINESS_SUCCESS,
    actionPayload
);
export const checkOfflineProtocolFinishReadinessFail = createCustomAction(
    actions.POST_OFFLINE_PROTOCOL_CHECK_FINISH_READINESS_FAIL,
    actionFail
);
