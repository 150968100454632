import React from 'react';
import clsx from 'clsx';
import StdAttachment from '../../atoms/StdAttachment';

import './linked-attachment.scss';

interface IProps extends IClass {
    path: string;
    name: string;
    borderless?: boolean;
}

const LinkedAttachment: React.FC<IProps> = ({
    className,
    name,
    path,
    borderless
}) => {
    return (
        <a
            className={clsx('attachment-link', className)}
            href={path}
            target="_blank"
            rel="noopener noreferrer"
        >
            <StdAttachment
                filename={name}
                width="100%"
                borderless={borderless}
            />
        </a>
    );
};

export default LinkedAttachment;
