import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import {
    ATTACHMENT_TYPES,
    ATTACH_UPLOAD_LIMIT,
    MEDIA_ATTACHMENT_TYPE
} from '../../../configs/costants';
import { IUploaderResult } from '../../../hooks/useUploader';
import useToggle from '../../../hooks/useToggle';

import StdButton from '../../atoms/StdButton';
import ConfirmDeletionModal from '../ConfirmDeletionModal';
import EditableAttachment from '../EditableAttachment';
import StdIcon from '../../atoms/StdIcon';

type IUploaderFns = Pick<
    IUploaderResult,
    'uploadFiles' | 'renameUpload' | 'removeUpload'
>;

interface IProps extends IClass, IUploaderFns {
    previews: IUpload[];
    type?: string;
    accept?: string;
    disabled?: boolean;
    showImg?: boolean;
    showTitle?: boolean;
}

const AttachmentGallery: React.FC<IProps> = (props) => {
    const {
        className,
        accept = ATTACHMENT_TYPES,
        type = MEDIA_ATTACHMENT_TYPE,
        previews,
        disabled,
        showImg,
        showTitle,
        uploadFiles,
        renameUpload,
        removeUpload
    } = props;

    const { t } = useTranslation();
    const [confirm, onConfirmOpen, onConfirmClose] = useToggle<IUpload>();

    const config = {
        type,
        totalLimit: Number.POSITIVE_INFINITY,
        allowedFiletypes: accept,
        sizeLimit: ATTACH_UPLOAD_LIMIT
    };
    return (
        <div className={className}>
            <div className={`modal__heading ${showTitle ? 'stack-m' : ''}`}>
                {t('attachments').toUpperCase()}{' '}
                {showImg ?
                    <span className="paperclip-icon">
                        <StdIcon 
                            name="paperclip" 
                        />
                    </span>
                    : null}
            </div>
            {Boolean(previews.length) && (
                <div className="stack-m">
                    {previews.map((preview, index) => {
                        const isLast = index === previews.length - 1;

                        return (
                            <EditableAttachment
                                key={preview.id}
                                className={clsx(!isLast && 'stack-s')}
                                preview={preview}
                                onEdit={(name) =>
                                    renameUpload(
                                        preview.id,
                                        preview.media.med_path,
                                        name
                                    )
                                }
                                onDelete={() => onConfirmOpen(preview)}
                            />
                        );
                    })}
                </div>
            )}
            <div className="file-uploader">
                <StdButton leadingIcon={{ name: 'plus' }} disabled={disabled} className={`${showImg ? 'fileBtn': ''}`}>
                    {t('attachment')}
                    {!disabled && (
                        <input
                            className="absolute-input"
                            type="file"
                            accept={accept}
                            onChange={(e) => {
                                const files = e.target.files;
                                if (files?.length)
                                    uploadFiles(Array.from(files), config);
                            }}
                            multiple
                        />
                    )}
                </StdButton>
            </div>
            <ConfirmDeletionModal
                message={t('delete_attachment_confirm', {
                    attachment_name: confirm.item?.name || '----'
                })}
                open={confirm.open}
                onClose={onConfirmClose}
                onConfirm={() => {
                    if (confirm.item) removeUpload(confirm.item.id);
                    onConfirmClose();
                }}
            />
        </div>
    );
};

export default AttachmentGallery;
