import { StateType } from 'typesafe-actions';
import { combineReducers } from 'redux';
import { SESSION_END } from '../actions';

import userReducer from './user';
import dpdsReducer from './dpds';
import appStateReducer from './appState';
import basicDataReducer from './basicData';
import sharedIssueReducer from './sharedIssue';
import actStatusReducer from './actionStatus';
import getAdminReducer from './geoAdmin';
import protocolReducer from './protocol';
import offlineProtoReducer from './offlineProtocol';
import globalReducer from './global';
import uploadsReducer from './uploads';
import inventoryReducer from './inventory';
import fetchStatusReducer from './fetchStatus';
import teamReducer from './team';

declare global {
    type IRootState = StateType<typeof appReducer>;
}

export const appReducer = combineReducers({
    global: globalReducer,
    user: userReducer,
    dpds: dpdsReducer,
    appState: appStateReducer,
    basicData: basicDataReducer,
    sharedIssue: sharedIssueReducer,
    pending: actStatusReducer,
    geoAdmin: getAdminReducer,
    protocol: protocolReducer,
    offlineProtocol: offlineProtoReducer,
    uploads: uploadsReducer,
    inventory: inventoryReducer,
    team: teamReducer,
    fetchStatus: fetchStatusReducer
});

const rootReducer = (state: IRootState, action: AnyAction) => {
    if (action.type === SESSION_END) {
        // Carry over only important state data
        const { basicData, appState, global } = state;

        const carriedOver = {
            basicData,
            appState,
            global
        };
        return appReducer(carriedOver as any, action);
    }

    return appReducer(state, action);
};

export default rootReducer;
