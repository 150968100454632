import { v4 as uuidv4 } from 'uuid';

import { pushToLimit } from '../../modules/array';
import * as actions from '../actions';

export function getSessionId() {
    const id = window.sessionStorage.getItem('session_id');
    if (id != null) return id;

    const updatedId = uuidv4();
    window.sessionStorage.setItem('session_id', updatedId);
    return updatedId;
}

export function getProtocolUniqueId() {
    const id = window.sessionStorage.getItem('protocol_unique_id');
    if (id != null) return id;

    const updatedId = uuidv4();
    window.sessionStorage.setItem('protocol_unique_id', updatedId);
    return updatedId;
}

// Note: this part of the state is no persisted
interface IGlobalState {
    clientId: string;
    offlineProtocolSessionId: string | null;

    // Note offline status and offline mode status are tracked in two different places
    isOffline: boolean;
    isBetaOffline: boolean;
    mediaCompressorActive: boolean;
    showUserOfflineNotification: boolean;
    syncStatus: ISyncStatus;
    networkSpeed: number[]; // bytes/s
    networkRTT: number; // rounded to the nearest multiple of 25 milliseconds.

    coreLoading: boolean;
    accountLoading: boolean;
    checkoutLoading: boolean;

    // Note: strictly speaking, for such simple endpoints, the store can be bypassed altogether
    // since there is never a need to look at the previous result
    emailCheck: IRequestResult<IUser | null>;
    publicEmailCheck: {
        isEmailUsed: boolean | null;
    };
}

const initState: IGlobalState = {
    clientId: getSessionId(),
    offlineProtocolSessionId: getProtocolUniqueId(),
    isOffline: !window.navigator.onLine,
    isBetaOffline: !window.navigator.onLine,
    mediaCompressorActive: true,
    showUserOfflineNotification: false,
    syncStatus: {
        status: 'idle'
    },
    networkSpeed: [250 * 1024], // 250 KB/s (average 3g speed)
    networkRTT: 900, // rounded to the nearest multiple of 25 milliseconds.
    coreLoading: false,
    accountLoading: false,
    checkoutLoading: false,
    emailCheck: {
        response: null,
        error: null
    },
    publicEmailCheck: {
        isEmailUsed: null
    }
};

function globalReducer(
    state: IGlobalState = initState,
    action: AnyAction
): IGlobalState {
    switch (action.type) {
        case actions.ASSIGN_PROTOCOL_SESSION_REQUEST:
            return {
                ...state,
                offlineProtocolSessionId: getProtocolUniqueId()
            };
        case actions.CONNECTIVITY_CHANGE_ONLINE:
            // const {useBeta = false} = action;
            // if(useBeta) return state
            return {
                ...state,
                isOffline: false,
                showUserOfflineNotification: false
            };
        case actions.CONNECTIVITY_CHANGE_OFFLINE:
            const { useBeta: betaMode = false } = action;
            if (betaMode) {
                return {
                    ...state,
                    syncStatus: {
                        status: 'idle'
                    }
                };
            }
            return {
                ...state,
                isOffline: true,
                showUserOfflineNotification: true,
                syncStatus: {
                    status: 'idle'
                }
            };

        case actions.BETA_CONNECTIVITY_CHANGE_ONLINE:
            return {
                ...state,
                ...(action?.useBeta && { isBetaOffline: false })
            };
        case actions.BETA_CONNECTIVITY_CHANGE_OFFLINE:
            return {
                ...state,
                ...(action?.useBeta && { isBetaOffline: true })
            };
        case actions.CONNECT_BETA_COMPRESSOR:
            return {
                ...state,
                ...(action?.useBeta && { mediaCompressorActive: true })
            };
        case actions.DISCONNECT_BETA_COMPRESSOR:
            return {
                ...state,
                ...(action?.useBeta && { mediaCompressorActive: false })
            };

        case actions.SET_NETWORK_RTT:
            const { rtt } = action;
            return {
                ...state,
                networkRTT: rtt
            };
        case actions.HIDE_USER_OFFLINE_NOTIFICATION_MODAL:
            return {
                ...state,
                showUserOfflineNotification: false
            };
        case actions.ON_OFFLINE_MDOE_SWITCH:
            return {
                ...state,
                syncStatus: {
                    status: 'idle'
                }
            };
        case actions.BACKGROUND_SYNC_DISCARD:
            return {
                ...state,
                syncStatus: {
                    status: 'sync-success',
                    prio: 'high'
                }
            };
        case actions.SET_CORE_LOADING:
            return {
                ...state,
                coreLoading: action.value
            };
        case actions.SET_ACCOUNT_LOADING:
            return {
                ...state,
                accountLoading: action.value
            };
        case actions.SET_CHECKOUT_LOADING:
            return {
                ...state,
                checkoutLoading: action.value
            };
        case actions.BACKGROUND_SYNC_START:
            return {
                ...state,
                syncStatus: {
                    status: 'syncing',
                    prio: 'high'
                }
            };
        case actions.BACKGROUND_SYNC_SUCCESS:
            return {
                ...state,
                syncStatus: {
                    status: 'sync-success',
                    prio: 'high'
                }
            };
        case actions.BACKGROUND_SYNC_FAIL:
            return {
                ...state,
                syncStatus: {
                    status: 'sync-fail',
                    error: action.error,
                    prio: 'high'
                }
            };
        case actions.CHECK_EMAIL_REQUEST:
            return {
                ...state,
                emailCheck: {
                    ...state.emailCheck,
                    response: null
                }
            };
        case actions.CHECK_EMAIL_SUCCESS:
            return {
                ...state,
                emailCheck: {
                    ...state.emailCheck,
                    response: action.payload
                }
            };
        case actions.CHECK_EMAIL_FAIL:
            const error = action.error as INetworkError;
            return {
                ...state,
                emailCheck: {
                    ...state.emailCheck,
                    response: error.response?.data,
                    error: action.error
                }
            };
        case actions.CLEAR_CHECK_EMAIL:
            return {
                ...state,
                emailCheck: {
                    response: null,
                    error: null
                }
            };
        case actions.PUBLIC_CHECK_EMAIL_SUCCESS: {
            return {
                ...state,
                publicEmailCheck: {
                    ...state.publicEmailCheck,
                    isEmailUsed: true
                }
            };
        }
        case actions.PUBLIC_CHECK_EMAIL_FAIL: {
            return {
                ...state,
                publicEmailCheck: {
                    ...state.publicEmailCheck,
                    isEmailUsed: false
                }
            };
        }
        case actions.PUBLIC_CLEAR_CHECK_EMAIL:
            return {
                ...state,
                publicEmailCheck: {
                    isEmailUsed: null
                }
            };
        case actions.CONNECTION_SPEED_UPDATE: {
            return {
                ...state,
                networkSpeed: pushToLimit(state.networkSpeed, 5, action.speed)
            };
        }
    }
    return state;
}

export default globalReducer;
