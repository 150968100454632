import React from 'react';
import clsx from 'clsx';
import './vertical-progress.scss';

interface IProps {
    progress: number;
    offline?: boolean;
    omitContainer?: boolean;
}

const VerticalProgress: React.FC<IProps> = ({
    progress,
    offline,
    omitContainer
}) => {
    return (
        <div className={clsx(!omitContainer && 'vertical-progress')}>
            <div
                className="vertical-progress__bar"
                style={{ height: `${progress}%` }}
            />
            <span className="vertical-progress__text font--strong">
                {offline ? 'Offline' : `${progress}%`}
            </span>
        </div>
    );
};

export default VerticalProgress;
