export const GET_ROOM_REQUEST = 'GET_ROOM_REQUEST';
export const GET_ROOM_SUCCESS = 'GET_ROOM_SUCCESS';
export const GET_ROOM_FAIL = 'GET_ROOM_FAIL';

export const POST_ROOM_REQUEST = 'POST_ROOM_REQUEST';
export const POST_ROOM_SUCCESS = 'POST_ROOM_SUCCESS';
export const POST_ROOM_FAIL = 'POST_ROOM_FAIL';

export const PATCH_ROOM_REQUEST = 'PATCH_ROOM_REQUEST';
export const PATCH_ROOM_SUCCESS = 'PATCH_ROOM_SUCCESS';
export const PATCH_ROOM_FAIL = 'PATCH_ROOM_FAIL';

export const DELETE_ROOM_REQUEST = 'DELETE_ROOM_REQUEST';
export const DELETE_ROOM_SUCCESS = 'DELETE_ROOM_SUCCESS';
export const DELETE_ROOM_FAIL = 'DELETE_ROOM_FAIL';

export const ROOM_ATTR_CRUD_REQUEST = 'ROOM_ATTR_CRUD_REQUEST';
export const ROOM_ATTR_CRUD_SUCCESS = 'ROOM_ATTR_CRUD_SUCCESS';
export const ROOM_ATTR_CRUD_FAIL = 'ROOM_ATTR_CRUD_FAIL';

export const COPY_ROOM_REQUEST = 'COPY_ROOM_REQUEST';
export const COPY_ROOM_SUCCESS = 'COPY_ROOM_SUCCESS';
export const COPY_ROOM_FAIL = 'COPY_ROOM_FAIL';

// A way to "predict" server results for things like counters
// not to waste requests, modifies the existing store array
export const PREDICT_ROOM = 'PREDICT_ROOM';

export const PREDICT_ROOMS = 'PREDICT_ROOMS';
