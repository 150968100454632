import { all, takeEvery, put } from 'redux-saga/effects';

import {
    DELETE_PROTOCOL_ISSUE_REQUEST,
    DELETE_PROTOCOL_ISSUE_FAIL
} from '../../actions';
import {
    deleteProtocolIssueSuccess,
    deleteProtocolIssueFail,
    deleteProtocolIssue,
    getIssue
} from '../../action-creators';
import { simpleRequest } from '../modules/request';
import i18n from '../../../i18next';

type IDeleteIssueAction = RT<typeof deleteProtocolIssue>;
type IDeleteIsssueFailAction = RT<typeof deleteProtocolIssueFail>;

const requestEffect = simpleRequest<IDeleteIssueAction>({
    success: deleteProtocolIssueSuccess,
    failure: deleteProtocolIssueFail,
    getUrl: (action) => `protocol_issue/${action.issueId}/`,
    getEntityId: (action) => action.issueId,
    method: 'DELETE',
    enableOffline: true
});

const lang = i18n.language as ILang;
export default all([
    takeEvery(DELETE_PROTOCOL_ISSUE_REQUEST, requestEffect),
    takeEvery(
        DELETE_PROTOCOL_ISSUE_FAIL,
        function* (action: IDeleteIsssueFailAction) {
            if (action.itemId == null) return;
            yield put(getIssue(action.itemId, lang));
        }
    )
]);