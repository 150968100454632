import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { isEmpty, isEmail } from 'validator';

import { dispatchAsync } from '../../store/storeModule';
import { resetPassword, showSnackbar } from '../../store/action-creators';
import { validate } from '../../utils/validation';
import { ROOT_ROUTE } from '../../configs/routes';

import useInput from '../../hooks/useInput';
import useValidation from '../../hooks/useValidation';

import StdButton from '../../components/atoms/StdButton';
import StdTextField from '../../components/atoms/StdTextField';
import i18n from '../../i18next';
import LoginHeader from '../../components/login/LoginHeader';

interface IState {
    form: {
        email: string;
    };
    errors: IErrorHash;
}

const initState: IState = {
    form: {
        email: ''
    },
    errors: {}
};

type IStep = { type: 'enter_email' } | { type: 'send_success'; email: string };

const ResetPassword: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [step, setStep] = useState<IStep>({ type: 'enter_email' });

    const [input, setInput] = useState(initState);
    const handleInput = useInput(setInput);

    const validateFields = useValidation(input, setInput, (hash) => {
        const { email } = input.form;
        return [
            validate({ email }, !isEmpty(email), hash) &&
                validate({ email }, isEmail(email), hash)
        ].every(Boolean);
    });

    const lang = i18n.language as ILang;
    const handleSendReset = () => {
        const { email } = input.form;
        dispatchAsync(dispatch, resetPassword(email, lang))
            .then((_) => setStep({ type: 'send_success', email }))
            .catch((_) =>
                dispatch(showSnackbar({ message: 'reset_password_fail' }))
            );
    };

    const handleSubmit = () => {
        validateFields() && handleSendReset();
    };

    const { form, errors } = input;

    const resetNode = step.type === 'enter_email' && (
        <div className="login__card">
            <div className="font__title stack-xl">
                {t('password_reset_title')}
            </div>
            <div className="login__form">
                <div className="font--secondary stack-m">
                    {t('password_reset_text')}
                </div>
                <StdTextField
                    className="stack-l"
                    label={t('email')}
                    type="email"
                    onChange={handleInput}
                    name="email"
                    value={form.email}
                    error={errors['email']}
                    width="100%"
                    autoComplete="email"
                />
                <div className="util__center">
                    <StdButton
                        className="inline-l"
                        type="primary"
                        onClick={handleSubmit}
                        size="large"
                    >
                        {t('send_pass_reset_link')}
                    </StdButton>
                </div>
            </div>
        </div>
    );

    const successNode = step.type === 'send_success' && (
        <div className="login__card">
            <div className="font__title stack-xl">
                {t('send_reset_email_title')}
            </div>
            <div className="login__note">
                <div className="font--secondary stack-l">
                    {t('send_reset_email_text', { email: step.email })}
                </div>
                <div className="login__note-container">
                    <StdButton type="primary" to={ROOT_ROUTE} size="large">
                        {t('back_to_sign_in')}
                    </StdButton>
                </div>
            </div>
        </div>
    );

    return (
        <div className="login__page">
            <LoginHeader />
            <div className="login__content">
                {resetNode}
                {successNode}
            </div>
        </div>
    );
};

export default ResetPassword;
