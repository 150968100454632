import moment from 'moment';
import { denormalize } from 'normalizr';
import React, { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import usePending from '../../../hooks/usePending';
import { t } from '../../../i18next';
import { getElementIssues } from '../../../store/action-creators';
import { issueSchema } from '../../../store/reducers/schema';
import { standardiseIssueObject } from '../../../utils/standardiseIssueObject';
import StdChip from '../../atoms/StdChip';
import StdDivider from '../../atoms/StdDivider';
import ChipIssueList from '../../issues/ChipIssueList';
import './element-issue-history.scss';

interface IProps {
    eleId: number;
    onViewUnresolvedIssue?: (iss: IIssue) => void;
    onViewResolvedIssue?: (iss: IIssue) => void;
    showUnresolved?: boolean;
    viewResolvedIssue?: boolean;
    onEditIssue?: ICallback;
    onDeleteIssue?: ICallback;
}
export default function ElementIssueHistory({
    eleId,
    onViewUnresolvedIssue,
    onViewResolvedIssue,
    showUnresolved = false,
    viewResolvedIssue = false,
    onEditIssue,
    onDeleteIssue
}: IProps) {
    const dispatch = useDispatch();
    const protocolIssueResponse = useSelector(
        (state: IRootState) => state.protocol.elements.byIds[eleId]
    );
    const eleResponse = useSelector(
        (state: IRootState) => state.inventory.elements.response.results
    );

    const eleRes =
        eleResponse.find((element) => element.ele_id === eleId)?.ele_iss || [];
    useLayoutEffect(() => {
        eleId && dispatch(getElementIssues(eleId as number));
    }, [eleId]);
    const isLoading = usePending([getElementIssues]);

    const { visibleResolvedIssues, visibleUnresolvedIssues }: any = // will make interface later
        standardiseIssueObject('ELEMENT_ISSUE_HISTORY', {
            protocolIssueResponse: protocolIssueResponse?.issues,
            eleRes
        });
    return (
        <div>
            {visibleUnresolvedIssues?.length > 0 &&
                !isLoading &&
                showUnresolved && (
                    <>
                        <StdDivider />
                        <div className="modal__section">
                            <div className="modal__heading stack-m">
                                {t('unresolved_issues').toUpperCase()}
                            </div>
                            <div>
                                <ChipIssueList
                                    issues={visibleUnresolvedIssues}
                                    onIssueClick={(iss) => {
                                            onViewUnresolvedIssue?.(iss)
                                    }}
                                    onEditIssue={onEditIssue}
                                    onDeleteIssue={onDeleteIssue}
                                    showCost
                                />
                            </div>
                        </div>
                    </>
                )}
            {visibleResolvedIssues?.length > 0 && !isLoading && (
                <>
                    <StdDivider />
                    <div className="modal__section">
                        <div>
                            <div className="modal__heading stack-m">
                                {t('issues_history').toUpperCase()}
                            </div>
                            {visibleResolvedIssues?.map((iss: IIssue) => (
                                <div
                                    key={iss.iss_id}
                                    className="element-issue-history__resolved"
                                >
                                    <StdChip
                                        className="inline-s stack-s"
                                        type={'neutral'}
                                        onClick={() =>
                                            viewResolvedIssue &&
                                            onViewResolvedIssue?.(iss)
                                        }
                                    >
                                        {iss.title || iss.iss_title || '---'}
                                    </StdChip>
                                    <span className="light-gray">
                                        {moment(iss.iss_date_occured).format(
                                            'Do MMM YYYY'
                                        )}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
