import clsx from 'clsx';
import moment from 'moment';
import { hasMicrophone } from 'detectrtc';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useTimer from '../../../hooks/useTimer';

import StdOverlay from '../../atoms/StdOverlay';
import StdIcon from '../../atoms/StdIcon';

import './camera.scss';

export function displayTimer(secondsCounter: number) {
    const seconds = secondsCounter % 60;

    const minutesCounter = Math.floor(secondsCounter / 60);
    const minutes = minutesCounter % 60;

    const hours = Math.floor(minutesCounter / 60);

    const hourString = hours.toString().padStart(2, '0');
    const minuteString = minutes.toString().padStart(2, '0');
    const secondsString = seconds.toString().padStart(2, '0');

    return `${hourString}:${minuteString}:${secondsString}`;
}

const hasVideoCaputre = Boolean(window.MediaRecorder);
const hasImageCapture = Boolean((window as any).ImageCapture);

// Need to wait until detectRTC library has loaded its checks
// The assumption here is that Camera component will only be rendered if the libary is loaded
const getCameraParams = () => {
    return {
        audio: hasMicrophone, // If microphone is avialable request audio as well
        video: {
            width: { ideal: 640 },
            height: { ideal: 480 },
            advanced: [{ facingMode: 'environment' }]
        }
    };
};

interface IProps {
    open?: boolean;
    onClose?: ICallback;
    onFileCreated?: (file: File) => void;
}

const Camera: React.FC<IProps> = ({ open, onClose, onFileCreated }) => {
    const { t } = useTranslation();

    const { counter, startTimer, stopTimer, resetTimer } = useTimer();

    const hasRequestedRef = useRef(false);
    const videoRef = useRef<HTMLVideoElement>(null);
    const streamRef = useRef<MediaStream | null>(null);
    const recorderRef = useRef<MediaRecorder | null>(null);
    const recordedBlobRef = useRef<Blob | null>(null);

    const pictureCanvasRef = useRef<HTMLCanvasElement>(null);
    const videoPreviewRef = useRef<HTMLVideoElement>(null);

    const [hasTakenPicture, setHasTakenPicture] = useState(false);

    const [isRecordingVideo, setIsRecordingVideo] = useState(false);
    const [hasRecoredVideo, setHasRecordedVideo] = useState(false);

    const getUserMedia = useCallback(() => {
        if (!videoRef.current) return;
        if (!pictureCanvasRef.current) return;

        const videoEl = videoRef.current;
        const pictureCanvas = pictureCanvasRef.current;

        // if the cleanup hasn't run, the function is called
        // due to dependecies, ignore it
        if (hasRequestedRef.current) return;
        hasRequestedRef.current = true;

        navigator.mediaDevices
            .getUserMedia(getCameraParams())
            .then((stream) => {
                const { width = 680, height = 480 } = stream
                    .getVideoTracks()[0]
                    .getSettings();

                videoEl.width = width;
                videoEl.height = height;

                // set canvas dimentions
                pictureCanvas.width = width;
                pictureCanvas.height = height;

                videoEl.setAttribute('autoplay', '');
                videoEl.setAttribute('muted', '');
                videoEl.setAttribute('playsinline', '');

                videoEl.muted = true;
                videoEl.srcObject = stream;
                videoEl.play();

                streamRef.current = stream;
            })
            .catch((_) => {
                alert(t('camera_access_fail_message'));
                onClose?.();
            });
    }, [t, onClose]);

    const takePicture = useCallback(() => {
        const stream = streamRef.current;
        const videoEl = videoRef.current;

        const pictureCanvas = pictureCanvasRef.current;
        if (!pictureCanvas) return;

        const ctx = pictureCanvas.getContext('2d');
        if (!ctx) return;

        if (stream && hasImageCapture) {
            // The new API
            const capture = new ImageCapture(stream.getVideoTracks()[0]);
            capture.grabFrame().then((bitmap) => {
                ctx.clearRect(0, 0, pictureCanvas.width, pictureCanvas.height);
                ctx.drawImage(bitmap, 0, 0, bitmap.width, bitmap.height);

                setHasTakenPicture(true);
            });
        } else if (videoEl) {
            // The old API
            ctx.clearRect(0, 0, pictureCanvas.width, pictureCanvas.height);
            ctx.drawImage(videoEl, 0, 0, videoEl.width, videoEl.height);

            setHasTakenPicture(true);
        }
    }, []);

    const onPictureSave = useCallback(() => {
        const pictureCanvas = pictureCanvasRef.current;
        if (!pictureCanvas) return;

        pictureCanvas.toBlob((blob) => {
            if (!blob) return;

            const timeStamp = moment().unix();
            const file = new File([blob], `camera_picture_${timeStamp}.png`, {
                type: 'image/png',
                lastModified: timeStamp
            });

            onFileCreated?.(file);
        });
        onClose?.();
    }, [onClose, onFileCreated]);

    const recordVideo = useCallback(() => {
        const recordedBlobs: Blob[] = [];

        const stream = streamRef.current;
        const videoPreview = videoPreviewRef.current;
        if (!stream || !videoPreview) return;

        const recorder = new MediaRecorder(stream);
        recorderRef.current = recorder;

        recorder.ondataavailable = (event) => {
            recordedBlobs.push(event.data);
        };

        recorder.onstop = () => {
            const superBuffer = new Blob(recordedBlobs, { type: 'video/webm' });

            videoPreview.src = '';
            videoPreview.srcObject = null;
            videoPreview.src = window.URL.createObjectURL(superBuffer);
            videoPreview.controls = true;

            recordedBlobRef.current = superBuffer;

            setHasRecordedVideo(true);
            setIsRecordingVideo(false);
        };

        recorder.start();
        startTimer();
        setIsRecordingVideo(true);
    }, [startTimer]);

    const stopRecordingVideo = useCallback(() => {
        stopTimer();

        const recorder = recorderRef.current;
        if (!recorder) return;

        recorder.stop();
    }, [stopTimer]);

    const cancelRecordedVideo = useCallback(() => {
        recordedBlobRef.current = null;
        setHasRecordedVideo(false);
        resetTimer();
    }, [resetTimer]);

    const saveRecordedVideo = useCallback(() => {
        const recordedBlob = recordedBlobRef.current;
        if (!recordedBlob) return;

        const timeStamp = moment().unix();
        const file = new File(
            [recordedBlob],
            `camera_video_${timeStamp}.webm`,
            {
                type: 'video/webm',
                lastModified: timeStamp
            }
        );

        onFileCreated?.(file);
        onClose?.();
    }, [onClose, onFileCreated]);

    const freeUserMedia = useCallback(() => {
        const videoEl = videoRef.current;
        const stream = streamRef.current;
        const recorder = recorderRef.current;

        if (stream) {
            stream.getTracks().forEach((track) => track.stop());
            streamRef.current = null;
        }

        if (recorder) {
            recorder.ondataavailable = null;
            recorder.onstop = null;
            recorderRef.current = null;
        }

        if (videoEl) {
            videoEl.pause();

            videoEl.srcObject = null;
            videoEl.load();
        }

        setHasTakenPicture(false);
        cancelRecordedVideo();
        stopRecordingVideo();
        setIsRecordingVideo(false);

        hasRequestedRef.current = false;
    }, [stopRecordingVideo, cancelRecordedVideo]);

    useEffect(() => {
        if (open) getUserMedia();
    }, [open, getUserMedia, freeUserMedia]);

    // Cleanup on unmount
    useEffect(() => {
        return () => freeUserMedia();
    }, [freeUserMedia]);

    const hideInitActions =
        hasTakenPicture || hasRecoredVideo || isRecordingVideo;
    return (
        <StdOverlay open={Boolean(open)} onExited={freeUserMedia}>
            <div className="camera__container">
                <video ref={videoRef} className="camera__output" />
                <canvas
                    ref={pictureCanvasRef}
                    className={clsx(
                        'camera__picture-preview',
                        !hasTakenPicture && 'camera__picture-preview--hidden'
                    )}
                />
                <video
                    ref={videoPreviewRef}
                    className={clsx(
                        'camera__picture-preview',
                        !hasRecoredVideo && 'camera__picture-preview--hidden'
                    )}
                />
                <div className="camera__controls">
                    <StdIcon
                        className="inline-m"
                        name="arrow-left"
                        onClick={onClose}
                        clickable
                    />
                    {hasTakenPicture && (
                        <>
                            <StdIcon
                                className="inline-m"
                                name="check-circle"
                                onClick={onPictureSave}
                                clickable
                            />
                            <StdIcon
                                className="inline-m"
                                name="times-circle"
                                onClick={() => setHasTakenPicture(false)}
                                clickable
                            />
                        </>
                    )}
                    {hasRecoredVideo && (
                        <>
                            <StdIcon
                                className="inline-m"
                                name="check-circle"
                                onClick={saveRecordedVideo}
                                clickable
                            />
                            <StdIcon
                                className="inline-m"
                                name="times-circle"
                                onClick={cancelRecordedVideo}
                                clickable
                            />
                        </>
                    )}
                    {isRecordingVideo && (
                        <>
                            <StdIcon
                                className="inline-m"
                                name="stop-circle"
                                onClick={stopRecordingVideo}
                                clickable
                            />
                            <span className="inline-m">
                                {displayTimer(counter)}
                            </span>
                        </>
                    )}
                    {!hideInitActions && (
                        <>
                            <StdIcon
                                className="inline-m"
                                name="camera"
                                onClick={takePicture}
                                clickable
                            />
                            {hasVideoCaputre && (
                                <StdIcon
                                    className="inline-m"
                                    name="video"
                                    onClick={recordVideo}
                                    clickable
                                />
                            )}
                        </>
                    )}
                </div>
            </div>
        </StdOverlay>
    );
};

export default Camera;
