import React, { useRef } from 'react';
import { isIOS } from '../../../utils';

interface IProps {
    src: string;
    className?: string;
    width?: number;
    height?: number;
    onClick?: ICallback;
}

const CustomVideo: React.FC<IProps> = ({
    src,
    className,
    width,
    height,
    onClick
}) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    // Check if iOS
    const inlineHack = isIOS;

    const onPlay = () => videoRef.current && videoRef.current.pause();

    return (
        <video
            ref={videoRef}
            className={className}
            src={src}
            autoPlay={inlineHack}
            playsInline={inlineHack}
            onPlay={inlineHack ? onPlay : undefined}
            width={width}
            height={height}
            preload="metadata"
            onClick={onClick}
            muted
        />
    );
};
export default CustomVideo;
