import { all, takeEvery, put } from 'redux-saga/effects';

import { DELETE_ISSUE_REQUEST, DELETE_ISSUE_FAIL } from '../../actions';
import {
    deleteIssueSuccess,
    deleteIssueFail,
    deleteIssue,
    getIssue
} from '../../action-creators';
import { simpleRequest } from '../modules/request';
import i18n from '../../../i18next';

type IDeleteIssueAction = RT<typeof deleteIssue>;
type IDeleteIsssueFailAction = RT<typeof deleteIssueFail>;

const requestEffect = simpleRequest<IDeleteIssueAction>({
    success: deleteIssueSuccess,
    failure: deleteIssueFail,
    getUrl: (action) => `issue/${action.issueId}/issue_delete/`,
    getEntityId: (action) => action.issueId,
    method: 'DELETE',
    enableOffline: true
});

const lang = i18n.language as ILang;
export default all([
    takeEvery(DELETE_ISSUE_REQUEST, requestEffect),
    takeEvery(DELETE_ISSUE_FAIL, function* (action: IDeleteIsssueFailAction) {
        if (action.itemId == null) return;
        yield put(getIssue(action.itemId, lang));
    })
]);
