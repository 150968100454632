import { AnyAction } from 'redux';
import { takeEvery, put } from 'redux-saga/effects';

import { getActionId, getAsyncActName } from '../../storeModule';

interface ResponseHelperParams {
    actionName: string;
    processFn: (data: any) => any;
}

// Helper to transform backend response _SUCCESS action and turn
// dispatch _RESPONSE action with modified payload

export function responseHelper(params: ResponseHelperParams) {
    return takeEvery(params.actionName, function* (action: AnyAction) {
        const name = getAsyncActName(action.type);

        const payload = params.processFn(action.payload);

        yield put({
            type: `${name}_RESPONSE`,
            payload,
            localId: getActionId(action)
        });
    });
}
