import usePending, { IPendingItem } from './usePending';
import useDelayedUpdate from './useDelayedUpdate';

const useDelayedPending = (
    pendingItems: IPendingItem[],
    init = true,
    delay = 10
) => {
    return useDelayedUpdate(usePending(pendingItems), init, delay);
};

export default useDelayedPending;
