import { takeLeading } from 'redux-saga/effects';

import { CREATE_ISSUE_LINK_REQUEST } from '../../actions';
import {
    createIssueLink,
    createIssueLinkSuccess,
    createIssueLinkFail
} from '../../action-creators';
import { simpleRequest } from '../modules/request';
import { normalizeIssue } from '../modules/normalization';

export default takeLeading(
    CREATE_ISSUE_LINK_REQUEST,
    simpleRequest<ReturnType<typeof createIssueLink>>({
        success: createIssueLinkSuccess,
        failure: createIssueLinkFail,
        getUrl: (_) => 'issue/issue_create_links/',
        getBody: (action) => ({ iss_id: action.issueId }),
        method: 'POST',
        onSuccess: function (data) {
            return normalizeIssue(data);
        }
    })
);
