import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import StdButton from '../atoms/StdButton';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '../atoms/StdModal';
import StdCheckbox from '../atoms/StdCheckbox';
import { patchAccount } from '../../store/action-creators';

interface BetaSelectorProps {}

const BetaSelector: React.FC<BetaSelectorProps> = ({}) => {
    const dispatch = useDispatch();
    const useBeta = useSelector(
        (state) => state.user?.user?.response?.use_beta
    );
    const firstname = useSelector(
        (state) => state.user?.user?.response?.firstname
    );
    const lastname = useSelector(
        (state) => state.user?.user?.response?.lastname
    );

    const [confirmationModalOpen, setConfirmationModalOpen] =
        useState<boolean>(false);
    const [confirmationCheck, setConfirmationCheck] = useState<boolean>(false);

    const handleBetaModeToggle = () => {
        dispatch(
            patchAccount({
                fullname: `${firstname} ${lastname}`,
                use_beta: !useBeta
            })
        );
    };

    return (
        <>
            <StdButton
                onClick={() => {
                    if (!useBeta) {
                        setConfirmationModalOpen(true);
                    } else {
                        handleBetaModeToggle();
                    }
                }}
                width="170px"
            >
                {!useBeta ? 'SWITCH TO BETA' : 'EXIT BETA MODE'}
            </StdButton>
            <Modal
                open={confirmationModalOpen}
                onClose={() => {}}
                white
                contentClassName="beta-mode-modal-content"
            >
                <div className="modal__section--square beta-mode-modal">
                    <div className={clsx('stack-xl', 'beta-selector')}>
                        <div className="modal-header">
                            Welcome to your AIMMO Beta mode
                        </div>
                        <div className="modal-info__1">
                            AIMMO beta is a pre-release version of platform. It
                            has not be fully tested yet. Please take a look,
                            check new features and provide your feedback.
                        </div>
                        <div className="modal-info__2">
                            By proceeding I agree to test AIMMO platform and
                            report eventual issues.
                        </div>
                        <StdCheckbox
                            className="modal-condition-check"
                            checked={confirmationCheck}
                            onClick={() =>
                                setConfirmationCheck(!confirmationCheck)
                            }
                        >
                            Ok, I understand
                        </StdCheckbox>
                    </div>
                    <div
                        className={clsx('modal__actions', 'beta-modal-actions')}
                    >
                        <StdButton
                            className="beta-cancel"
                            onClick={() => {
                                setConfirmationCheck(false);
                                setConfirmationModalOpen(false);
                            }}
                        >
                            {'GO BACK'}
                        </StdButton>
                        <StdButton
                            className="beta-accept"
                            onClick={() => {
                                handleBetaModeToggle();
                                setConfirmationModalOpen(false);
                                setConfirmationCheck(false);
                            }}
                            disabled={!confirmationCheck}
                        >
                            {'PROCEED WITH BETA'}
                        </StdButton>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default BetaSelector;
