import clsx from 'clsx';
import React from 'react';
import CustomIcon from '../../atoms/CustomIcon';
import SearchInput, { ISearchInputProps } from '../SearchInput';

import './mobile-search-input.scss';

interface IProps extends ISearchInputProps {
    collapsed: boolean;
    toggleCollapsed: ICallback;
}

const MobileSearchInput: React.FC<IProps> = (props) => {
    const { className, collapsed, toggleCollapsed, ...otherProps } = props;

    const containerClass = clsx(
        'mobile-search-input__container',
        collapsed && 'mobile-search-input__container--collapsed',
        className
    );

    if (!collapsed) {
        return (
            <div className={containerClass}>
                <CustomIcon
                    className="inline-s"
                    name="back-arrow"
                    onClick={toggleCollapsed}
                />
                <SearchInput {...otherProps} mobile />
            </div>
        );
    }

    return (
        <div className={containerClass} onClick={toggleCollapsed}>
            <CustomIcon name="search" />
        </div>
    );
};

export default MobileSearchInput;
