import { DropResult } from 'react-beautiful-dnd';
import { reorder } from './array';

import { intOrElse, intOrNothing } from '../utils';
import { t } from '../i18next';

export const isRoomType = (object: unknown): object is IRoom => {
    return (object as IRoom).rom_id != null;
};

export const isProtoRoomType = (object: unknown): object is IProtocolRoom => {
    return (object as IProtocolRoom).prom_id != null;
};

export function respectsFloorCount(room: IProtocolRoom, dpd: IDpd) {
    return isRespectingFloorCount(
        room.prom_level,
        dpd.dpd_floor,
        dpd.dpd_basement_included
    );
}

export function isRespectingFloorCount(
    roomLevel?: number,
    dpdLevel?: number,
    hasFloor?: boolean
) {
    const level = intOrElse(roomLevel, 1);
    const floorCount = intOrNothing(dpdLevel) || 1;
    const baseFloor = +!hasFloor;

    return level >= baseFloor && level <= floorCount;
}

// FIMXE: translations
// Room related helpers
export const levelToFloor = (level: number): string => {
    if (level === 0) return t('UG');
    return level > 1 ? `${level - 1} ${t('OG')}` : t('EG');
};

export const roomToIcon = (room_type: string) => {
    switch (room_type) {
        // kitchen
        case 'K':
            return 'utensils-alt';
        // bathroom
        case 'B':
            return 'faucet-drip';
        // livin room
        case 'W':
            return 'couch';
        default:
            return 'door-closed';
    }
};

export const getRoomsFromFloor = <T extends IRoom | IProtocolRoom>(
    rooms: T[],
    floor: number
): [T[], number] => {
    const floorRooms = rooms.filter((room: T) => {
        if (isRoomType(room)) return floor === intOrElse(room.rom_level, 1);
        if (isProtoRoomType(room))
            return floor === intOrElse(room.prom_level, 1);
        return false;
    });
    return [floorRooms, floor];
};

// if single floor, ignore all floor data
export const reorderRoomsOnDragEnd = (
    rooms: IRoom[],
    result: DropResult,
    singleFloor?: boolean
) => {
    const { source, destination } = result;
    if (!destination || source.index === destination.index) return;

    const idPrefix = 'droppable-floor-';

    if (
        source.droppableId.indexOf(idPrefix) !== 0 ||
        destination.droppableId.indexOf(idPrefix) !== 0
    )
        return;

    const srcFloor = parseInt(
        destination.droppableId.substring(
            idPrefix.length,
            destination.droppableId.length
        )
    );
    const destFloor = parseInt(
        source.droppableId.substring(idPrefix.length, source.droppableId.length)
    );

    if (isNaN(destFloor) || isNaN(srcFloor)) return;

    const roomsByFloor = rooms
        .slice()
        .sort(
            (rom1, rom2) =>
                intOrElse(rom1.rom_level, 1) - intOrElse(rom2.rom_level, 1)
        );

    // Calculate offset for a single floor since floors cannot be different in the current implementation
    const offset = singleFloor
        ? 0
        : roomsByFloor.reduce(
              (acc, room) =>
                  intOrElse(room.rom_level, 1) < srcFloor ? acc + 1 : acc,
              0
          );

    return reorder(
        singleFloor ? rooms : roomsByFloor,
        source.index + offset,
        destination.index + offset
    );
};

// if single floor, ignore all floor data
export const reorderProtocolRoomsOnDragEnd = (
    rooms: IProtocolRoom[],
    result: DropResult,
    singleFloor?: boolean
) => {
    const { source, destination } = result;
    if (!destination || source.index === destination.index) return;

    const idPrefix = 'droppable-floor-';

    if (
        source.droppableId.indexOf(idPrefix) !== 0 ||
        destination.droppableId.indexOf(idPrefix) !== 0
    )
        return;

    const srcFloor = parseInt(
        destination.droppableId.substring(
            idPrefix.length,
            destination.droppableId.length
        )
    );

    const destFloor = parseInt(
        source.droppableId.substring(idPrefix.length, source.droppableId.length)
    );

    if (isNaN(destFloor) || isNaN(srcFloor)) return;

    const roomsByFloor = rooms
        .slice()
        .sort(
            (rom1, rom2) =>
                intOrElse(rom1.prom_level, 1) - intOrElse(rom2.prom_level, 1)
        );

    // Calculate offset for a single floor since floors cannot be different in the current implementation
    const offset = singleFloor
        ? 0
        : roomsByFloor.reduce(
              (acc, room) =>
                  intOrElse(room.prom_level, 1) < srcFloor ? acc + 1 : acc,
              0
          );

    return reorder(
        singleFloor ? rooms : roomsByFloor,
        source.index + offset,
        destination.index + offset
    );
};
