import React, { useRef, useEffect, useCallback } from 'react';
import env from '../../../configs/environment';
import './Map.scss';

interface IProps {
    options?: google.maps.MapOptions;
    onMapLoad: (map: google.maps.Map) => void;
}

export const initMapOptions = {
    center: { lat: 47.3769, lng: 8.5417 },
    zoom: 7,
    mapTypeControl: false
};

const Map: React.FC<IProps> = ({ options, onMapLoad }) => {
    const mapDivRef = useRef<HTMLDivElement>(null);
    const loadingRef = useRef<boolean>(false);

    const onScriptLoad = useCallback(() => {
        loadingRef.current = false;
        const div = mapDivRef.current;
        if (div) {
            // Note: it might be better to have a map reference internally
            onMapLoad(
                new window.google.maps.Map(div, {
                    ...initMapOptions,
                    ...options
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!loadingRef.current) {
            if (!window.google) {
                loadingRef.current = true;

                const s = document.createElement('script');
                s.async = true;
                s.type = 'text/javascript';
                s.src = `https://maps.google.com/maps/api/js?key=${env.getProperty(
                    'googleApi'
                )}&libraries=places`;
                const x = document.getElementsByTagName('script')[0];
                x.parentNode && x.parentNode.insertBefore(s, x);

                s.addEventListener('load', (_) => onScriptLoad());
            } else {
                onScriptLoad();
            }
        }
    }, [onScriptLoad]);

    return <div className="map-container" ref={mapDivRef} />;
};

export default Map;
