import { takeLatest } from 'redux-saga/effects';
import { RESET_PASSWORD_REQUEST } from '../../actions';
import {
    resetPassword,
    resetPasswordSuccess,
    resetPasswordFail
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeLatest(
    RESET_PASSWORD_REQUEST,
    simpleRequest<ReturnType<typeof resetPassword>>({
        success: resetPasswordSuccess,
        failure: resetPasswordFail,
        getUrl: (action) => `auth-users/reset_password/?lang=${action.lang}`,
        getBody: (action) => action.payload,
        method: 'POST',
        withAuth: false
    })
);
