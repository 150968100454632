import { takeEvery, all } from 'redux-saga/effects';
import { normalize } from 'normalizr';

import { POST_SIGNATURE_REQUEST, POST_SIGNATURE_SUCCESS } from '../../actions';
import {
    postSignature,
    postSignatureSuccess,
    postSignatureFail
} from '../../action-creators';

import { simpleRequest } from '../modules/request';
import { responseHelper } from '../modules/promise';
import { signatureSchema } from '../../reducers/schema';

function onSuccessCallback(data: any) {
    const { entities, result } = normalize(data, signatureSchema);
    const { uploads = {}, signatures = {} } = entities;

    const entity = signatures[result];
    return {
        entity,
        uploads
    };
}

const requestEffect = takeEvery(
    POST_SIGNATURE_REQUEST,
    simpleRequest<RT<typeof postSignature>>({
        success: postSignatureSuccess,
        failure: postSignatureFail,
        getUrl: (_) => 'signature/',
        getBody: (action) => action.signature,
        method: 'POST',
        enableOffline: true
    })
);

const responseEffect = responseHelper({
    actionName: POST_SIGNATURE_SUCCESS,
    processFn: onSuccessCallback
});

export default all([requestEffect, responseEffect]);
