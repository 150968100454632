import React, { useState, useCallback, useRef, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    getAllIssue,
    postIssue,
    deleteMedia
} from '../../store/action-creators';

import { trasnslateIssue } from '../../utils/componentInterfaces';

import { SWIPER_DEFAULTS } from '../../configs/costants';
import { getPageLoading, getPaginationParams } from '../../modules/pagination';

import { justArray } from '../../utils';
import { settingsManager } from '../../modules/localSettings';

import useDelayedPending from '../../hooks/useDelayedPending';
import { IToggle } from '../../hooks/useToggle';

import useMatchMedia from '../../hooks/useMatchMedia';
import useScrollToContent from '../../hooks/useScrollToContent';
import usePending from '../../hooks/usePending';

import SwiperWrapper from 'react-id-swiper';

import ExpSection from '../../components/atoms/ExpSection/ExpSection';
import IssueCard from '../issues/IssueCard';
import Loading from '../atoms/Loading';
import TutorialCard from '../inventory/TutorialCard';
import PlanInvoiceCard from '../profile/PlanInvoiceCard';
import i18n from '../../i18next';

interface IIssueProps {
    issuesRes: IPaginatedResult<IIssue>;
    viewedIssue: IToggle<IIssue>;
    sharingDisabled?: boolean;
    onAdd?: ICallback;
    onModalOpen?: ICallback;
    onViewIssOpen: (issue: IIssue) => void;
    onViewIssClose: ICallback;
}

const IssueSection: React.FC<IIssueProps> = ({
    issuesRes,
    viewedIssue,
    sharingDisabled,
    onAdd,
    onViewIssOpen,
    onViewIssClose,
    onModalOpen
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const issueSwiperRef = useRef<typeof SwiperWrapper | null>(null);

    const [issueOpen, setIssueOpen] = useState(false);
    const [sectionRef, scrollToContent] = useScrollToContent({
        onlyMobile: true,
        block: 'center'
    });

    const issueLoading = useDelayedPending([
        getAllIssue,
        postIssue,
        deleteMedia
    ]);
    const issueLoadingInst = usePending([getAllIssue]);
    const uploadStore = useSelector((state) => state.uploads.uploads);

    const isPhone = useMatchMedia('mobile');

    const usageRes = useSelector((state) => state.user.usageTotals.response);
    const issueUsage = usageRes?.ISSUE;
    const issueCount = issueUsage?.count || 0;
    const issueSubtotal = issueUsage?.subtotal || 0;

    const { hasNext, hasPrevious } = issuesRes.paginate;

    const issues = justArray(issuesRes.response.results);
    const issCount = issuesRes.response.count; 
    const lang = i18n.language as ILang;

    const getMoreIssues = useCallback(
        (direction: IPaginateDirection) => {
            const params = getPaginationParams(issuesRes.paginate, direction);

            dispatch(getAllIssue(lang, params, direction));
        },
        [dispatch, issuesRes.paginate, lang]
    );

    const updatedIssue =
        viewedIssue.open && viewedIssue.item
            ? issues.find(
                  (issue) =>
                      viewedIssue.item &&
                      issue.iss_id === viewedIssue.item.iss_id
              )
            : null;
    useEffect(() => {
        if (updatedIssue) onViewIssOpen(updatedIssue);
        else onViewIssClose();
    }, [updatedIssue, onViewIssOpen, onViewIssClose]);

    useEffect(() => {
        const issueSwiper = issueSwiperRef.current?.swiper;
        if (issueOpen && issueSwiper) {
            issueSwiper.on('reachEnd', () => {
                hasNext && !issueLoadingInst && getMoreIssues('forwards');
            });

            issueSwiper.on('reachBeginning', () => {
                hasPrevious && !issueLoadingInst && getMoreIssues('backwards');
            });

            return () => {
                issueSwiper.off('reachEnd');
                issueSwiper.off('reachBeginning');
            };
        }
    }, [issueOpen, hasNext, hasPrevious, getMoreIssues, issueLoadingInst]);

    useEffect(() => {
        setIssueOpen(settingsManager.get('issue_section_open'));
    }, []);

    const onSectionToggle = () => {
        setIssueOpen((state) => {
            settingsManager.set('issue_section_open', !state);
            return !state;
        });
    };

    const issuesNode = [
        issueCount && (
            <PlanInvoiceCard
                className="inline-s"
                key="issue-invoice-card"
                used={issueCount}
                subtotal={issueSubtotal}
                featureName="ISSUE"
                height="168px"
            />
        ),
        !issueCount && !issues?.length && (
            <TutorialCard
                key="issue-tutorial-card"
                onAction={onModalOpen}
                issue
            />
        ),
        Boolean(issues?.length) &&
            issues.map((issue) => {
                return (
                    <IssueCard
                        key={issue?.iss_id}
                        className="inline-s"
                        issueData={trasnslateIssue(issue, uploadStore)}
                        onView={() => onViewIssOpen(issue)}
                        closed={Boolean(issue?.iss_fix)}
                        sharingDisabled={sharingDisabled}
                        outlined
                    />
                );
            })
    ].filter((node) => Boolean(node));

    const emptyNode = <div className="util__none" />;

    const horizontalSpinner = (
        <Loading className="loading__dashboard-item" size="xlarge" />
    );

    return (
        <>
            <div ref={sectionRef} />
            <ExpSection
                open={issueOpen}
                title={
                    <span attr-intercom="issues">
                        {t('reported_issues').toUpperCase()}
                    </span>
                }
                actionTitle={t('issue')}
                count={issCount || issues?.length}
                onClick={onSectionToggle}
                onAction={onAdd}
                wide={isPhone}
                onAnimationComplete={() => {
                    if (issueOpen && sectionRef.current) {
                        scrollToContent();
                    }
                }}
            >
                <div className="stack-l">
                    <SwiperWrapper
                        {...SWIPER_DEFAULTS}
                        ref={issueSwiperRef}
                        shouldSwiperUpdate
                    >
                        {issueLoading &&
                        getPageLoading(issuesRes.paginate) === 'start'
                            ? horizontalSpinner
                            : emptyNode}
                        {issueLoading &&
                        getPageLoading(issuesRes.paginate) === 'middle'
                            ? horizontalSpinner
                            : issuesNode}
                        {issueLoading &&
                        getPageLoading(issuesRes.paginate) === 'end'
                            ? horizontalSpinner
                            : emptyNode}
                    </SwiperWrapper>
                </div>
            </ExpSection>
        </>
    );
};

export default IssueSection;
