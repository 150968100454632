import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import StdOverlay from '../../atoms/StdOverlay';
import TrialSubscribeFlow from '../../trial/TrialSubscribeFlow';
import IssueSharingIntroPage from '../IssueSharingIntroPage';

type IFlowStep = 'intro' | 'subscriptions' | null;

interface IProps extends IModalBase {}

const IssueSharingDisabledFlow: React.FC<IProps> = ({ open, onClose }) => {
    const [step, setStep] = useState<IFlowStep>(null);

    const account = useSelector((state) => state.user.user.response);
    const currentPlan = account?.subscription?.plan;

    useEffect(() => {
        setStep(open ? 'intro' : null);
    }, [open]);

    return (
        <>
            <StdOverlay open={step === 'intro'}>
                <IssueSharingIntroPage
                    onBack={onClose}
                    onNext={() => setStep('subscriptions')}
                />
            </StdOverlay>
            <TrialSubscribeFlow
                currentPackage={currentPlan}
                open={step === 'subscriptions'}
                onClose={() => setStep('intro')}
                onSubscribe={() => setStep(null)}
            />
        </>
    );
};

export default IssueSharingDisabledFlow;
