import { takeEvery } from 'redux-saga/effects';

import { deleteManagerSet } from '../../action-creators/team';
import { simpleRequest } from '../modules/request';

export default takeEvery(
    deleteManagerSet.type,
    simpleRequest<RT<typeof deleteManagerSet.request>>({
        success: deleteManagerSet.success,
        failure: deleteManagerSet.fail,
        getUrl: (action) => `team_membership/${action.id}/`,
        method: 'DELETE',
        isV2Api: true
    })
);
