import React from 'react';
import clsx from 'clsx';

import './trial-cell.scss';
import { t } from '../../../i18next';

interface IProps extends IClass {
    title: string;
    subtitle?: string;
    highlighted?: boolean;
    last?: boolean;
    suffixAmount?: boolean;
    currency?: string;
}

const TrialCell: React.FC<IProps> = ({
    className,
    title,
    subtitle,
    highlighted,
    last,
    suffixAmount,
    currency
}) => {
    const containerClass = clsx(
        'feature-table-cell__container',
        highlighted && 'feature-table-cell__container--highlighted',
        last && 'feature-table-cell__container--last',
        className
    );

    return (
        <div className={containerClass}>
            <div
                className={clsx(
                    'feature-table-cell__title',
                    subtitle && 'stack-m'
                )}
            >
                {title}
                {suffixAmount && ` ${currency}`}
            </div>
            {subtitle && (
                <div className="feature-table-cell__subtitle">{subtitle === '0.000' ? t('plan.upgrade.to.starter'): subtitle}</div>
            )}
        </div>
    );
};

export default TrialCell;
