import React from 'react';
import { useTranslation } from 'react-i18next';

import { ROOT_ROUTE } from '../../../configs/routes';

import StdButton from '../../atoms/StdButton';
import StdModal from '../../atoms/StdModal';

import './set-password-success-modal.scss';

interface IProps extends IModalBase {}

const SetPasswordSuccessModal: React.FC<IProps> = ({ open, onClose }) => {
    const { t } = useTranslation();
    return (
        <StdModal open={open} onClose={onClose}>
            <div className="set-password-success-modal__header">
                {t('invite.password_successfully_set_header')}
            </div>
            <div className="set-password-success-modal__content">
                <img
                    className="set-password-success-modal__graphic"
                    src="/static/images/welcome_checkmark.svg"
                    alt="invite success checkmark"
                />
                <div>
                    <div className="set-password-success-modal__title">
                        {t('invite.welcome_title')}
                    </div>
                    <div className="set-password-success-modal__text">
                        {/* {t('invite.welcome_description')} */}
                    </div>
                    <StdButton to={ROOT_ROUTE} type="primary">
                        {t('invite.welcome_action')}
                    </StdButton>
                </div>
            </div>
        </StdModal>
    );
};

export default SetPasswordSuccessModal;
