import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

import { isEmail } from 'validator';

import { DASHBOARD_PATH } from '../../../configs/routes';
import { adminLogin, enterpriseAdminLogin, showSnackbar } from '../../../store/action-creators';
import { dispatchAsync } from '../../../store/storeModule';

import { validate } from '../../../utils/validation';

import useInput from '../../../hooks/useInput';
import useValidation from '../../../hooks/useValidation';

import StdButton from '../../atoms/StdButton';
import StdTextField from '../../atoms/StdTextField';

interface IProps {
    isEnterprise?: boolean 
}
interface IForm {
    form: {
        email: string;
    };
    errors: IErrorHash;
}

const initForm: IForm = {
    form: {
        email: ''
    },
    errors: {}
};

const AdminSection: React.FC<IProps> = ({isEnterprise = false}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();

    const [input, setInput] = useState(initForm);
    const handleInput = useInput(setInput);

    const { form, errors } = input;

    const validateFields = useValidation(input, setInput, (hash) => {
        const { email } = form;
        return [validate({ email }, isEmail(email), hash)].every(Boolean);
    });

    const handleLogin = useCallback(() => {
        const dispatchAction = isEnterprise ? enterpriseAdminLogin(form.email) : adminLogin(form.email);
        dispatchAsync(dispatch, dispatchAction)
            .then((_) => history.push(DASHBOARD_PATH))
            .catch((_) =>
                dispatch(showSnackbar({ message: 'admin_login_fail_message' }))
            );
    }, [dispatch, history, form.email]);

    const onSubmit = () => {
        if (validateFields()) {
            handleLogin();
        }
    };

    return (
        <div className="profile__section">
            <div className="font--secondary stack-m">
                {t('admin_tab.header')}
            </div>
            <div>
                <StdTextField
                    className="stack-s"
                    label={t('email')}
                    name="email"
                    value={form.email}
                    error={errors['email']}
                    onChange={handleInput}
                    extraDense
                />
            </div>
            <div>
                <StdButton onClick={onSubmit}>
                    {t('admin_tab.log_in')}
                </StdButton>
            </div>
        </div>
    );
};

export default AdminSection;
