import { debounce } from 'redux-saga/effects';

import { PUBLIC_CHECK_EMAIL_REQUEST } from '../../actions';
import {
    publicCheckEmail,
    publicCheckEmailSuccess,
    publicCheckEmailFail
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default debounce(
    2000,
    PUBLIC_CHECK_EMAIL_REQUEST,
    simpleRequest<RT<typeof publicCheckEmail>>({
        success: publicCheckEmailSuccess,
        failure: publicCheckEmailFail,
        getUrl: (_) => 'user/public_check_email/',
        getBody: (action) => action.payload,
        method: 'POST',
        ignoreError: true,
        withAuth: false,
        isV2Api: true
    })
);
