import { IStorageEngine, localStorageEngine } from './storage';

class TokenManager {
    private engine: IStorageEngine;
    private accessLabel: string;
    private refreshLabel: string;

    constructor(
        engine: IStorageEngine,
        accessLabel = 'access',
        refreshLabel = 'refresh'
    ) {
        this.engine = engine;
        this.accessLabel = accessLabel;
        this.refreshLabel = refreshLabel;
    }

    get() {
        const access = this.engine.get(this.accessLabel) as string;
        const refresh = this.engine.get(this.refreshLabel) as string;

        return {
            access,
            refresh
        };
    }

    set(access: string, refresh: string) {
        this.engine.set(this.accessLabel, access);
        this.engine.set(this.refreshLabel, refresh);
    }

    clear() {
        this.engine.remove(this.accessLabel);
        this.engine.remove(this.refreshLabel);
    }

    refresh(newToken: string) {
        this.engine.set(this.accessLabel, newToken);
    }
}

export const tokenManager = new TokenManager(localStorageEngine);
