import { takeEvery, all, put } from 'redux-saga/effects';

import {
    POST_PROTOCOL_ITEM_ATTRIBUTES_FAIL,
    POST_PROTOCOL_ITEM_ATTRIBUTES_REQUEST
} from '../../actions';
import {
    protoItemAttrCrud,
    protoItemAttrCrudSuccess,
    protoItemAttrCrudFail,
    getProtocolItem
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

type IPostProtocolItemAttributesAction = RT<typeof protoItemAttrCrud>;
type IPostProtocolItemAttributesFailAction = RT<typeof protoItemAttrCrudFail>;

const requestEffect = simpleRequest<IPostProtocolItemAttributesAction>({
    success: protoItemAttrCrudSuccess,
    failure: protoItemAttrCrudFail,
    getBody: (action) => action.payload,
    getUrl: (_) => `protocol_item_attribute/item_attribute_crud/`,
    getEntityId: (action) => action.payload.pita_pitm_id,
    method: 'POST',
    enableOffline: true
});

export default all([
    takeEvery(POST_PROTOCOL_ITEM_ATTRIBUTES_REQUEST, requestEffect),
    takeEvery(
        POST_PROTOCOL_ITEM_ATTRIBUTES_FAIL,
        function* (action: IPostProtocolItemAttributesFailAction) {
            if (action.itemId == null) return;
            if (action.itemId < 0) return;
            yield put(getProtocolItem(action.itemId));
        }
    )
]);
