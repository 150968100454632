import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Button as MdcButton } from '@rmwc/button';
import Modal from '../../atoms/StdModal';

interface IProps extends IModalBase, IClass {
    children?: React.ReactNode;
    confirmLabel?: string; // Default to Yes & No
    cancelLabel?: string;
    onConfirm?: ICallback;
    alignRight?: boolean;
    isNotification?: boolean;
    disabled?: boolean;
    isDestructive?: boolean;
}

const ConfirmModal: React.FC<IProps> = (props) => {
    const { t } = useTranslation();

    const {
        className,
        open,
        children,
        confirmLabel = t('yes'),
        cancelLabel = t('no'),
        alignRight,
        isNotification,
        disabled,
        isDestructive,
        onClose,
        onConfirm
    } = props;

    return (
        <Modal open={open} onClose={onClose} white>
            <div className="modal__section--square">
                <div className={clsx('stack-xl', className)}>{children}</div>
                <div
                    className={clsx(
                        'modal__actions',
                        alignRight && 'modal__actions--right'
                    )}
                >
                    <MdcButton className="mdc-button--grey" onClick={onClose}>
                        {cancelLabel}
                    </MdcButton>
                    {!isNotification && (
                        <MdcButton
                            className={clsx(
                                isDestructive && 'mdc-button--danger'
                            )}
                            onClick={onConfirm}
                            disabled={disabled}
                        >
                            {confirmLabel}
                        </MdcButton>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmModal;
