export const SET_ACCESS = 'SET_ACCESS';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const REFRESH_ACCESS_REQUEST = 'REFRESH_ACCESS_REQUEST';
export const REFRESH_ACCESS_SUCCESS = 'REFRESH_ACCESS_SUCCESS';
export const REFRESH_ACCESS_FAIL = 'REFRESH_ACCESS_FAIL';

export const MAGIC_LOGIN_REQUEST = 'MAGIC_LOGIN_REQUEST';
export const MAGIC_LOGIN_SUCCESS = 'MAGIC_LOGIN_SUCCESS';
export const MAGIC_LOGIN_FAIL = 'MAGIC_LOGIN_FAIL';

export const GET_CURRENT_ACC_REQUEST = 'GET_CURRENT_ACC_REQUEST';
export const GET_CURRENT_ACC_SUCCESS = 'GET_CURRENT_ACC_SUCCESS';
export const GET_CURRENT_ACC_FAIL = 'GET_CURRENT_ACC_FAIL';

export const CANCEL_SUBSCRIPTION_REQUEST = 'CANCEL_SUBSCRIPTION_REQUEST';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_FAIL = 'CANCEL_SUBSCRIPTION_FAIL';

export const CHANGE_SUBSCRIPTION_REQUEST = 'CHANGE_SUBSCRIPTION_REQUEST';
export const CHANGE_SUBSCRIPTION_SUCCESS = 'CHANGE_SUBSCRIPTION_SUCCESS';
export const CHANGE_SUBSCRIPTION_FAIL = 'CHANGE_SUBSCRIPTION_FAIL';

export const PATCH_ACCOUNT_REQUEST = 'PATCH_ACCOUNT_REQUEST';
export const PATCH_ACCOUNT_SUCCESS = 'PATCH_ACCOUNT_SUCCESS';
export const PATCH_ACCOUNT_FAIL = 'PATCH_ACCOUNT_FAIL';

export const GET_BILLING_REQUEST = 'GET_BILLING_REQUEST';
export const GET_BILLING_SUCCESS = 'GET_BILLING_SUCCESS';
export const GET_BILLING_FAIL = 'GET_BILLING_FAIL';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

export const CHECK_EMAIL_REQUEST = 'CHECK_EMAIL_REQUEST';
export const CHECK_EMAIL_SUCCESS = 'CHECK_EMAIL_SUCCESS';
export const CHECK_EMAIL_FAIL = 'CHECK_EMAIL_FAIL';
export const CLEAR_CHECK_EMAIL = 'CLEAR_CHECK_EMAIL';

export const PUBLIC_CHECK_EMAIL_REQUEST = 'PUBLIC_CHECK_EMAIL_REQUEST';
export const PUBLIC_CHECK_EMAIL_SUCCESS = 'PUBLIC_CHECK_EMAIL_SUCCESS';
export const PUBLIC_CHECK_EMAIL_FAIL = 'PUBLIC_CHECK_EMAIL_FAIL';
export const PUBLIC_CLEAR_CHECK_EMAIL = 'PUBLIC_CLEAR_CHECK_EMAIL';

export const VERFIY_TOKEN_REQUEST = 'VERIFY_TOKEN_REQUEST';
export const VERIFY_TOKEN_SUCCESS = 'VERIFY_TOKEN_SUCCESS';
export const VERIFY_TOKEN_FAIL = 'VERIFY_TOKEN_FAIL';

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';

export const RESEND_VERIFICATION_REQUEST = 'RESEND_VERIFICATION_REQUEST';
export const RESEND_VERIFICATION_SUCCESS = 'RESEND_VERIFICATION_SUCCESS';
export const RESEND_VERIFICATION_FAIL = 'RESEND_VERIFICATION_FAIL';

export const SET_PASSWORD_REQUEST = 'SET_PASSWORD_REQUEST';
export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS';
export const SET_PASSWORD_FAIL = 'SET_PASSWORD_FAIL';

export const RESET_PASSWORD_CONFIRM_REQUEST = 'RESET_PASSWORD_CONFIRM_REQUEST';
export const RESET_PASSWORD_CONFIRM_SUCCESS = 'RESET_PASSWORD_CONFIRM_SUCCESS';
export const RESET_PASSWORD_CONFIRM_FAIL = 'RESET_PASSWORD_CONFIRM_FAIL';

export const VERIFY_ACCOUNT_REQUEST = 'VERIFY_ACCOUNT_REQUEST';
export const VERIFY_ACCOUNT_SUCCESS = 'VERIFY_ACCOUNT_SUCCESS';
export const VERIFY_ACCOUNT_FAIL = 'VERIFY_ACCOUNT_FAIL';

export const CHECK_ACTIVE_REQUEST = 'CHECK_ACTIVE_REQUEST';
export const CHECK_ACTIVE_SUCCESS = 'CHECK_ACTIVE_SUCCESS';
export const CHECK_ACTIVE_FAIL = 'CHECK_ACTIVE_FAIL';

export const GET_USAGE_LOGS_REQUEST = 'GET_USAGE_LOGS_REQUEST';
export const GET_USAGE_LOGS_SUCCESS = 'GET_USAGE_LOGS_SUCCESS';
export const GET_USAGE_LOGS_FAIL = 'GET_USAGE_LOGS_FAIL';

export const GET_USAGE_HISTORY_REQUEST = 'GET_USAGE_HISTORY_REQUEST';
export const GET_USAGE_HISTORY_SUCCESS = 'GET_USAGE_HISTROY_SUCCESS';
export const GET_USAGE_HISTORY_FAIL = 'GET_USAGE_HISTORY_FAIL';

export const GET_INVOICE_REQUEST = 'GET_INVOICE_REQUEST';
export const GET_INVOICE_SUCCESS = 'GET_INVOICE_SUCCESS';
export const GET_INVOICE_FAIL = 'GET_INVOICE_FAIL';

export const GET_PACKAGES_REQUEST = 'GET_PACKAGES_REQUEST';
export const GET_PACKAGES_SUCCESS = 'GET_PACKAGES_SUCCESS';
export const GET_PACKAGES_FAIL = 'GET_PACKAGES_FAIL';

export const ADMIN_LOGIN_REQUEST = 'ADMIN_LOGIN_REQUEST';
export const ADMIN_LOGIN_SUCCESS = 'ADMIN_LOGIN_SUCCESS';
export const ADMIN_LOGIN_FAIL = 'ADMIN_LOGIN_FAIL';

export const ENTERPRISE_ADMIN_LOGIN_REQUEST = 'ENTERPRISE_ADMIN_LOGIN_REQUEST';
export const ENTERPRISE_ADMIN_LOGIN_SUCCESS = 'ENTERPRISE_ADMIN_LOGIN_SUCCESS';
export const ENTERPRISE_ADMIN_LOGIN_FAIL = 'ENTERPRISE_ADMIN_LOGIN_FAIL';

export const UPDATE_USER_MEDIA_REQUEST = 'UPDATE_USER_MEDIA_REQUEST';
export const UPDATE_USER_MEDIA_SUCCESS = 'UPDATE_USER_MEDIA_SUCCESS';
export const UPDATE_USER_MEDIA_FAIL = 'UPDATE_USER_MEDIA_FAIL';

export const UPDATE_BILLING_REQUEST = 'UPDATE_BILLING_REQUEST';
export const UPDATE_BILLING_SUCCESS = 'UPDATE_BILLING_SUCCESS';
export const UPDATE_BILLING_FAIL = 'UPDATE_BILLING_FAIL';

export const UPDATE_CONTACT_ADDRESS_REQUEST = 'UPDATE_CONTACT_ADDRESS_REQUEST';
export const UPDATE_CONTACT_ADDRESS_SUCCESS = 'UPDATE_CONTACT_ADDRESS_SUCCESS';
export const UPDATE_CONTACT_ADDRESS_FAIL = 'UPDATE_CONTACT_ADDRESS_FAIL';

export const SESSION_START = 'SESSION_START';
export const SESSION_END = 'SESSION_END';

export const PREDICT_ACCOUNT = 'PREDICT_ACCOUNT';
