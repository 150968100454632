import * as actions from '../actions/basicData';
import { createCustomAction, createAction } from 'typesafe-actions';
import { actionFail, actionPayload } from '../storeModule';

export const getBasicData = createCustomAction(
    actions.GET_BASIC_DATA_REQUEST,
    (type) => {
        return (lang: ILang) => ({ type, lang });
    }
);
export type IGetBasicData = ReturnType<typeof getBasicData>;

export const getBasicDataSuccess = createCustomAction(
    actions.GET_BASIC_DATA_SUCCESS,
    actionPayload
);
export const getBasicDataFail = createCustomAction(
    actions.GET_BASIC_DATA_FAIL,
    actionFail
);
