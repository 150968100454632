import { takeLatest, call } from 'redux-saga/effects';
import { normalize } from 'normalizr';

import { GET_STORED_PROTO_ISSUES_REQUEST } from '../../actions';
import {
    getStoredProtoIssues,
    getStoredProtoIssuesSuccess,
    getStoredProtoIssuesFail
} from '../../action-creators';
import { justArray } from '../../../utils';

import { simpleRequest, ISuccessCallback } from '../modules/request';
import { fetchFailError } from '../../../modules/errorHandlers';

import { issueSchema } from '../../reducers/schema';

// Reduce the amount of boilplate by coverting ProtocolIssue to an ordinary Issue
function protocolIssueToIssue(protocolIssue: any) {
    const keys = Object.keys(protocolIssue);
    const issue: any = {
        is_protocol: true
    };

    keys.forEach((key) => {
        if (key.startsWith('p')) {
            const issueKey = key.substring(1, key.length); // 'piss_id' -> 'iss_id' etc
            issue[issueKey] = protocolIssue[key];
        } else {
            issue[key] = protocolIssue[key];
        }
    });

    return issue;
}

type IGetProtocolIssuesAction = RT<typeof getStoredProtoIssues>;

const onSuccess: ISuccessCallback<IGetProtocolIssuesAction> = function (data) {
    const protocolIssues = justArray(data);
    const convertedIssues = protocolIssues.map((issue) =>
        protocolIssueToIssue(issue)
    );

    const { entities, result } = normalize(convertedIssues, [issueSchema]);
    const { issues = {}, uploads } = entities;

    return {
        store: {
            byIds: issues,
            allIds: result
        },
        uploads
    };
};

export default takeLatest(
    GET_STORED_PROTO_ISSUES_REQUEST,
    simpleRequest<IGetProtocolIssuesAction>({
        success: getStoredProtoIssuesSuccess,
        failure: getStoredProtoIssuesFail,
        getUrl: (action) => `protocol/${action.protoId}/protocol_issues/`,
        onFail: function* (error: INetworkError) {
            yield call(fetchFailError, 'fetch_issues_error', error);
        },
        onSuccess
    })
);
