import React, { memo } from 'react';
import clsx from 'clsx';
import { ANIMATE_HEIGHT_PROPS } from '../../../configs/costants';
import StdIcon from '../StdIcon';
import { motion, AnimatePresence } from 'framer-motion';
import './expandable-section-alt.scss';

export interface IProps {
    open: boolean;
    title: string;
    count: number;
    className?: string;
    titleClass?: string;
    children?: React.ReactNode;
    onClick?: ICallback;
}

const ExpSectionAlt: React.FC<IProps> = ({
    open,
    title,
    count,
    className,
    titleClass,
    children,
    onClick
}) => {
    return (
        <div className={clsx('collapsible-list-alt__container', className)}>
            <div
                className={clsx(
                    'collapsible-list-alt__title',
                    open && 'stack-s',
                    titleClass
                )}
                onClick={onClick}
            >
                <span className="inline-m">
                    {title} ({count})
                </span>
                <div className="exp-section__icon">
                    <StdIcon name={open ? 'chevron-down' : 'chevron-right'} />
                </div>
            </div>
            <AnimatePresence initial={false}>
                {open && (
                    <motion.div {...ANIMATE_HEIGHT_PROPS}>
                        {children}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default memo(
    ExpSectionAlt,
    (prevProps, nextProps) =>
        prevProps.open === nextProps.open &&
        prevProps.count === nextProps.count &&
        prevProps.children === nextProps.children
);
