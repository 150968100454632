import React from 'react';
import Modal from '../../atoms/StdModal';
import clsx from 'clsx';
import StdButton from '../../atoms/StdButton';
import { useTranslation } from 'react-i18next';

import './ProtocolDownloadConfirmationModal.scss';

interface ProtocolDownloadConfirmationModalProps {
    open: boolean;
    onAccept: (e?: any) => void | undefined;
    onCancel: (e?: any) => void | undefined;
}

const ProtocolDownloadConfirmationModal: React.FC<
    ProtocolDownloadConfirmationModalProps
> = ({ open, onAccept, onCancel }) => {
    const { t } = useTranslation();

    return (
        <Modal
            open={open}
            onClose={() => {}}
            white
            contentClassName="protocol-download-confirmation-modal-content"
        >
            <div className="modal__section--square protocol-download-confirmation-modal">
                <div className={clsx('stack-xl', 'beta-selector')}>
                    <div className="modal-header">
                        {t('offline_protocol_download_text')}
                    </div>
                </div>
                <div className={clsx('modal__actions', 'beta-modal-actions')}>
                    <StdButton
                        className="beta-cancel"
                        onClick={(e) => {
                            onCancel(e);
                        }}
                    >
                        {t('cancel')}
                    </StdButton>
                    <StdButton
                        className="beta-accept beta-selector-accept-button"
                        onClick={(e) => {
                            onAccept(e);
                        }}
                    >
                        {t('okay')}
                    </StdButton>
                </div>
            </div>
        </Modal>
    );
};

export default ProtocolDownloadConfirmationModal;
