import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';

import { roundToDecimal } from '../../../utils';
import { ANIMATE_HEIGHT_PROPS } from '../../../configs/costants';
import useTrialInfo from '../../../hooks/useTrialInfo';

import StorageBanner from '../../molecules/StorageBanner';

const StorageWarning: React.FC = () => {
    const [open, setOpen] = useState(false);

    const { hasSubscription } = useTrialInfo();
    const documentUsage = useSelector(
        (state) => state.user.usageTotals.response?.DOCUMENT
    );

    const { included, count, unit, unlimited } = documentUsage || {};

    const usedTitle = `${roundToDecimal(count || 0)} ${unit || ''}`.trim();
    const includedTitle = `${included || 0} ${unit || ''}`.trim();

    const isShown =
        hasSubscription &&
        !unlimited &&
        included != null &&
        count != null &&
        count > included;

    useEffect(() => {
        if (isShown) setOpen(true);
    }, [isShown]);

    return (
        <AnimatePresence>
            {open && (
                <motion.div {...ANIMATE_HEIGHT_PROPS}>
                    <StorageBanner used={usedTitle} included={includedTitle} />
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default StorageWarning;
