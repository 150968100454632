import React from 'react';

const LoginHeader: React.FC = () => {
    return (
        <div className="login__header">
            <a href="https://aimmo.io/" target="_self">
                <img
                    className="login__logo"
                    src="/static/images/aimmo-logo-black.svg"
                    alt="AIMMO logo"
                />
            </a>
        </div>
    );
};

export default LoginHeader;
