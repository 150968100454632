import React from 'react';
import { useTranslation } from 'react-i18next';

import { PROFILE_ROUTE } from '../../../configs/routes';
import StdButton from '../../atoms/StdButton';

import './storage-banner.scss';

interface IProps {
    used: string;
    included: string;
}

const StorageBanner: React.FC<IProps> = ({ used, included }) => {
    const { t } = useTranslation();
    return (
        <div className="storage-banner__conatiner">
            <div>
                {t('storage_limit.limit_exceeded', {
                    amount_used: used,
                    amount_included: included
                })}
            </div>
            <StdButton to={PROFILE_ROUTE('plan')}>
                {t('storage_limit.see_details')}
            </StdButton>
        </div>
    );
};

export default StorageBanner;
