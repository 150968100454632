import { takeEvery, take, call } from 'redux-saga/effects';

import { ASYNC_ACTION_SEQUENCE } from '../../actions';
import { asyncActionSequence } from '../../action-creators';

import { isNetworkDownError } from '../../../modules/error';
import { getAsyncActName } from '../../storeModule';
import { isServiceWorkerRunning } from '../../../utils';

// Alternative to promise to dispatching and tying together related actions.
// Used for offline functionality, as normally if there is a network error (simualted by service worker),
// promise rejects.
// Should not be neccessary if offline mode functionality is refactored to not directly dispatch requests.

type AsyncAction = RT<typeof asyncActionSequence>;

const asyncActionEffect = takeEvery(
    ASYNC_ACTION_SEQUENCE,
    function* (asynAction: AsyncAction) {
        const { actions, onFail } = asynAction.payload;
        const getters = actions;

        let result: AnyAction | undefined = undefined;
        for (const actionGetter of getters) {
            const requestAction = yield call(actionGetter, result);
            if (!requestAction) continue;

            const requestActionName = getAsyncActName(requestAction.type);
            const resultAction: AnyAction = yield take([
                `${requestActionName}_SUCCESS`,
                `${requestActionName}_FAIL`,
                `${requestActionName}_SKIP`
            ]);

            const isFailure = resultAction.type.endsWith('_FAIL');
            const shouldFail =
                Boolean(resultAction.error) &&
                !(
                    isNetworkDownError(resultAction.error) &&
                    isServiceWorkerRunning()
                );
            if (isFailure && shouldFail) {
                onFail?.(resultAction);
                break;
            }
            result = resultAction;
        }
    }
);

export default asyncActionEffect;
