import * as actions from '../actions/template';
import { createCustomAction } from 'typesafe-actions';
import { actionFail, actionPayload } from '../storeModule';

export const getTemplate = createCustomAction(
    actions.GET_TEMPALTE_REQUEST,
    (type) => {
        return (propertyType: string, lang: string) => ({
            type,
            propertyType,
            lang
        });
    }
);
export type IGetTemplate = ReturnType<typeof getTemplate>;

export const getTemplateSuccess = createCustomAction(
    actions.GET_TEMPLATE_SUCCESS,
    actionPayload
);
export const getTemplateFail = createCustomAction(
    actions.GET_TEMPLATE_FAIL,
    actionFail
);
