import { takeLatest } from 'redux-saga/effects';

import { GET_OFFLINE_PROTOCOL_CHECKOUT_ID_REQUEST } from '../../actions';
import {
    getOfflineProtocolCheckoutId,
    getOfflineProtocolCheckoutIdSuccess,
    getOfflineProtocolCheckoutIdFail
} from '../../action-creators';

import { simpleRequest } from '../modules/request';
import { normalizeCheckoutData } from '../modules/normalization';

export default takeLatest(
    GET_OFFLINE_PROTOCOL_CHECKOUT_ID_REQUEST,
    simpleRequest<RT<typeof getOfflineProtocolCheckoutId>>({
        method: 'POST',
        success: getOfflineProtocolCheckoutIdSuccess,
        failure: getOfflineProtocolCheckoutIdFail,
        getUrl: (action) => `protocol/${action.protoId}/checkout/`,
        getBody: (action) => action.overrideValue,
        onSuccess: function (data) {
            const normalisedCheckoutData = normalizeCheckoutData(data);

            return normalisedCheckoutData;
        },
        noBlock: true
        // enableOffline: true
    })
);
