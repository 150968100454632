import { takeEvery } from 'redux-saga/effects';

import { acceptTeamInviteSet } from '../../action-creators/team';
import { simpleRequest } from '../modules/request';

export default takeEvery(
    acceptTeamInviteSet.type,
    simpleRequest<RT<typeof acceptTeamInviteSet.request>>({
        success: acceptTeamInviteSet.success,
        failure: acceptTeamInviteSet.fail,
        getUrl: () => `group_invitation/accept_invite/`,
        getBody: (action) => action.payload,
        method: 'POST',
        isV2Api: true
    })
);
