export const GET_OFFLINE_PROTOCOL_CHECKOUT_ID_REQUEST =
    'GET_OFFLINE_PROTOCOL_CHECKOUT_ID_REQUEST';
export const GET_OFFLINE_PROTOCOL_CHECKOUT_ID_SUCCESS =
    'GET_OFFLINE_PROTOCOL_CHECKOUT_ID_SUCCESS';
export const GET_OFFLINE_PROTOCOL_CHECKOUT_ID_FAIL =
    'GET_OFFLINE_PROTOCOL_CHECKOUT_ID_FAIL';

export const DISCARD_OFFLINE_PROTOCOL_STATE = 'DISCARD_OFFLINE_PROTOCOL_STATE';

export const POST_OFFLINE_PROTOCOL_REQUEST = 'POST_OFFLINE_PROTOCOL_REQUEST';
export const POST_OFFLINE_PROTOCOL_SUCCESS = 'POST_OFFLINE_PROTOCOL_SUCCESS';
export const POST_OFFLINE_PROTOCOL_FAIL = 'POST_OFFLINE_PROTOCOL_FAIL';

export const PATCH_OFFLINE_PROTOCOL = 'PATCH_OFFLINE_PROTOCOL';
export const PATCH_OFFLINE_PROTOCOL_STEP = 'PATCH_OFFLINE_PROTOCOL_STEP';
export const PATCH_OFFLINE_PROTOCOL_METERS = 'PATCH_OFFLINE_PROTOCOL_METER';
export const PATCH_OFFLINE_PROTOCOL_NOTES = 'PATCH_OFFLINE_PROTOCOL_NOTES';
export const PATCH_OFFLINE_PROTOCOL_CLEANING =
    'PATCH_OFFLINE_PROTOCOL_CLEANING';
export const PATCH_OFFLINE_PROTOCOL_ROOM = 'PATCH_OFFLINE_PROTOCOL_ROOM';
export const PATCH_OFFLINE_PROTOCOL_ROOM_ELEMENT =
    'PATCH_OFFLINE_PROTOCOL_ROOM_ELEMENT';
export const PATCH_OFFLINE_PROTOCOL_ITEMS_CHECK =
    'PATCH_OFFLINE_PROTOCOL_ITEMS_CHECK';
export const PATCH_OFFLINE_ATTENDEE_REQUEST = 'PATCH_OFFLINE_ATTENDEE_REQUEST';

export const CREATE_OFFLINE_PROTOCOL_ATTENDEES =
    'CREATE_OFFLINE_PROTOCOL_ATTENDEES';
export const CREATE_OFFLINE_PROTOCOL_ROOM = 'CREATE_OFFLINE_PROTOCOL_ROOM';
export const CREATE_OFFLINE_PROTOCOL_ELEMENT =
    'CREATE_OFFLINE_PROTOCOL_ELEMENT';
export const CREATE_OFFLINE_PROTOCOL_ITEM = 'CREATE_OFFLINE_PROTOCOL_ITEM';
export const CREATE_OFFLINE_PROTOCOL_ISSUE = 'CREATE_OFFLINE_PROTOCOL_ISSUE';

export const UPDATE_OFFLINE_PROTOCOL_ATTENDEES =
    'UPDATE_OFFLINE_PROTOCOL_ATTENDEES';
export const UPDATE_OFFLINE_PROTOCOL_ROOM = 'UPDATE_OFFLINE_PROTOCOL_ROOM';
export const UPDATE_OFFLINE_PROTOCOL_ELEMENT =
    'UPDATE_OFFLINE_PROTOCOL_ELEMENT';
export const UPDATE_OFFLINE_PROTOCOL_ITEM = 'UPDATE_OFFLINE_PROTOCOL_ITEM';
export const UPDATE_OFFLINE_PROTOCOL_ISSUE = 'UPDATE_OFFLINE_PROTOCOL_ISSUE';

export const DELETE_OFFLINE_PROTOCOL_ATTENDEES =
    'DELETE_OFFLINE_PROTOCOL_ATTENDEES';
export const DELETE_OFFLINE_PROTOCOL_ROOM = 'DELETE_OFFLINE_PROTOCOL_ROOM';
export const DELETE_OFFLINE_PROTOCOL_ELEMENT =
    'DELETE_OFFLINE_PROTOCOL_ELEMENT';
export const DELETE_OFFLINE_PROTOCOL_ITEM = 'DELETE_OFFLINE_PROTOCOL_ITEM';
export const DELETE_OFFLINE_PROTOCOL_ISSUE = 'DELETE_OFFLINE_PROTOCOL_ISSUE';

// misc
export const SET_OPEN_FINISH_BANNER = 'SET_OPEN_FINISH_BANNER';
export const OFFLINE_PROTOCOL_ROOM_QUICKADD = 'OFFLINE_PROTOCOL_ROOM_QUICKADD';

export const OFFLINE_PROTOCOL_POST_SIGNATURE_REQUEST =
    'OFFLINE_PROTOCOL_POST_SIGNATURE_REQUEST';
export const OFFLINE_PROTOCOL_POST_SIGNATURE_SUCCESS =
    'OFFLINE_PROTOCOL_POST_SIGNATURE_SUCCESS';
export const OFFLINE_PROTOCOL_POST_SIGNATURE_FAIL =
    'OFFLINE_PROTOCOL_POST_SIGNATURE_FAIL';
export const OFFLINE_PROTOCOL_SIGNATURE_DELETE =
    'OFFLINE_PROTOCOL_SIGNATURE_DELETE';

export const SWITCH_OFFLINE_PROTOCOL_ASYNCHRONOUS =
    'SWITCH_OFFLINE_PROTOCOL_ASYNCHRONOUS';
export const SWITCH_OFFLINE_PROTOCOL_SYNCHRONOUS =
    'SWITCH_OFFLINE_PROTOCOL_SYNCHRONOUS';

export const CHECK_OFFLINE_PDF_READY_REQUEST =
    'CHECK_OFFLINE_PDF_READY_REQUEST';
export const CHECK_OFFLINE_PDF_READY_SUCCESS =
    'CHECK_OFFLINE_PDF_READY_SUCCESS';
export const CHECK_OFFLINE_PDF_READY_FAIL = 'CHECK_OFFLINE_PDF_READY_FAIL';
export const OFFLINE_PROTOCOL_ATTRIBUTE_CRD = 'OFFLINE_PROTOCOL_ATTRIBUTE_CRD';
export const OFFLINE_PROTOCOL_ATTRIBUTE_UPDATE =
    'OFFLINE_PROTOCOL_ATTRIBUTE_UPDATE';

export const PATCH_OFFLINE_MEDIA_REQUEST = 'PATCH_OFFLINE_MEDIA_REQUEST';
export const PATCH_OFFLINE_MEDIA_SUCCESS = 'PATCH_OFFLINE_MEDIA_SUCCESS';
export const PATCH_OFFLINE_MEDIA_FAIL = 'PATCH_OFFLINE_MEDIA_FAIL';
export const PATCH_USED_TENANT_DEPOSIT = 'PATCH_USED_TENANT_DEPOSIT';

export const OFFLINE_PROTOCOL_FINISH_STATE = 'OFFLINE_PROTOCOL_FINISH_STATE';
export const INITIATE_OFFLINE_SYNCING = 'INITIATE_OFFLINE_SYNCING';
export const END_OFFLINE_SYNCING = 'END_OFFLINE_SYNCING';
export const ASYNC_SYNC_UPLOADING_PROGRESS = 'ASYNC_SYNC_UPLOADING_PROGRESS';

// media normalizer action for offline reducer
export const NORMALISE_ASYNC_PROTOCOL_OFFLINE_MEDIA =
    'NORMALISE_ASYNC_PROTOCOL_OFFLINE_MEDIA';

// asynchronous protocol sync and protocol finisher
export const SYNC_OFFLINE_PROTOCOL_PREVIEW_REQUEST =
    'SYNC_OFFLINE_PROTOCOL_PREVIEW_REQUEST';
export const SYNC_OFFLINE_PROTOCOL_PREVIEW_SUCCESS =
    'SYNC_OFFLINE_PROTOCOL_PREVIEW_SUCCESS';
export const SYNC_OFFLINE_PROTOCOL_PREVIEW_FAIL =
    'SYNC_OFFLINE_PROTOCOL_PREVIEW_FAIL';

// syncing offline protocol syncing state
export const SET_OFFLINE_PROTOCOL_SYNCING_STATE =
    'SET_OFFLINE_PROTOCOL_SYNCING_STATE';

export const POST_OFFLINE_PROTOCOL_CHECKIN_STATUS_REQUEST =
    'POST_OFFLINE_PROTOCOL_CHECKIN_STATUS_REQUEST';
export const POST_OFFLINE_PROTOCOL_CHECKIN_STATUS_SUCCESS =
    'POST_OFFLINE_PROTOCOL_CHECKIN_STATUS_SUCCESS';
export const POST_OFFLINE_PROTOCOL_CHECKIN_STATUS_FAIL =
    'POST_OFFLINE_PROTOCOL_CHECKIN_STATUS_FAIL';
export const RESET_PROTOCOL_INVALID_CHECKIN = 'RESET_PROTOCOL_INVALID_CHECKIN';

export const OFFLINE_PROTOCOL_READY_TO_CHECKIN =
    'OFFLINE_PROTOCOL_READY_TO_CHECKIN';

export const SAVING_OFFLINE_PROTOCOL_DATA = 'SAVING_OFFLINE_PROTOCOL_DATA';
export const SET_OPEN_CHECKIN_FAIL_MODAL = 'SET_OPEN_CHECKIN_FAIL_MODAL';
export const SET_CHECKIN_FAIL_CODE = 'SET_CHECKIN_FAIL_CODE';

export const RUN_OFFLINE_PROTOCOL_SAVE_REQUEST =
    'RUN_OFFLINE_PROTOCOL_SAVE_REQUEST';
export const RUN_OFFLINE_PROTOCOL_SAVE_FAIL = 'RUN_OFFLINE_PROTOCOL_SAVE_FAIL';
export const RUN_OFFLINE_PROTOCOL_SAVE_SKIP = 'RUN_OFFLINE_PROTOCOL_SAVE_SKIP';
export const RUN_OFFLINE_PROTOCOL_SAVE_SUCCESS =
    'RUN_OFFLINE_PROTOCOL_SAVE_SUCCESS';

// Protocol state closure
export const OFFLINE_PROTOCOL_CLOSE_AND_FINISH =
    'OFFLINE_PROTOCOL_CLOSE_AND_FINISH';

// protocol finish state check
export const POST_OFFLINE_PROTOCOL_CHECK_FINISH_READINESS_REQUEST =
    'POST_OFFLINE_PROTOCOL_CHECK_FINISH_READINESS_REQUEST';
export const POST_OFFLINE_PROTOCOL_CHECK_FINISH_READINESS_SUCCESS =
    'POST_OFFLINE_PROTOCOL_CHECK_FINISH_READINESS_SUCCESS';
export const POST_OFFLINE_PROTOCOL_CHECK_FINISH_READINESS_FAIL =
    'POST_OFFLINE_PROTOCOL_CHECK_FINISH_READINESS_FAIL';
