import React from 'react';
import clsx from 'clsx';

import './std-block.scss';

interface IProps extends IClass {
    children?: React.ReactNode;
    black?: boolean;
    inactive?: boolean;
    borderless?: boolean;
    onClick?: ICallback;
}

const StdBlock: React.FC<IProps> = (props) => {
    const { children, black, inactive, borderless, className, onClick } = props;

    const blockClass = clsx(
        'std-block',
        black && 'std-block--black',
        inactive && 'std-block--inactive',
        borderless && 'std-block--borderless',
        className
    );

    return (
        <div className={blockClass} onClick={onClick}>
            {children}
        </div>
    );
};

export default StdBlock;
