import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'validator';
import clsx from 'clsx';
import i18n from '../../../i18next';

import { validate } from '../../../utils/validation';
import { parseStreetAddress } from '../../../modules/property';
import useInput from '../../../hooks/useInput';
import useValidation from '../../../hooks/useValidation';

import { MenuItem } from '@rmwc/menu';

import StdTextField from '../../atoms/StdTextField';
import StdButton from '../../atoms/StdButton';
import StdSelect from '../../atoms/StdSelect';
import ProfileLogoUploader from '../ProfileLogoUploader';

import './company-details-section.scss';

export interface IBillingForm {
    form: {
        street: string;
        city: string;
        country: string;
        region: string;
        zip: string;
        name: string;
        checked_agreement: boolean;
    };
    errors: IErrorHash;
}

const initForm: IBillingForm = {
    form: {
        street: '',
        city: '',
        country: '',
        region: '',
        zip: '',
        name: '',
        checked_agreement: false
    },
    errors: {}
};

interface IProps {
    user: IUser;
    plan?: IPackage | null;
    companyLogo?: IUpload;
    showBillingAddress: boolean;
    editPermission?: boolean;
    handleInit?: (s: IBillingForm) => IBillingForm;
    handleSubmit?: (s: IBillingForm['form']) => void;
}

const CompanyDetailsSection: React.FC<IProps> = ({
    user,
    plan,
    companyLogo,
    showBillingAddress,
    editPermission = false,
    handleInit,
    handleSubmit
}) => {
    const { t, i18n } = useTranslation();

    const [input, setInput] = useState(initForm);
    const [formDisableState, setFormDisableState] = useState(true);
    const [editButtonState, setEditButtonState] = useState(false);
    const handleInput = useInput(setInput);

    useEffect(() => {
        handleInit && setInput((s) => handleInit(s));
    }, [handleInit]);

    const validateFields = useValidation(input, setInput, (hash) => {
        const { country, city, zip, street, name, checked_agreement } =
            input.form;
        const { street_name, street_num } = parseStreetAddress(street);

        const isStreetValid =
            !isEmpty(street) && !isEmpty(street_name) && !isEmpty(street_num);

        return [
            validate({ country }, !isEmpty(country), hash),
            validate({ city }, !isEmpty(city), hash),
            validate({ zip }, !isEmpty(zip), hash),
            validate({ name }, !isEmpty(name), hash),
            validate({ street }, isStreetValid, hash)
        ].every(Boolean);
    });

    const makeRequest = () => {
        handleSubmit && handleSubmit(input.form);
    };

    const onClickSubmit = () => {
        validateFields() && makeRequest();
    };
    const { form, errors } = input;

    const renderSelectComponent = () => {
        return (
            <>
                {formDisableState ? (
                    <StdTextField
                        disabled
                        className="stack-xl"
                        label={t('country')}
                        name="country"
                        value={t(`${form.country}`)}
                        error={errors['country']}
                        width="100%"
                    />
                ) : (
                    <StdSelect
                        disabled={formDisableState}
                        className="stack-xl"
                        label={t('country')}
                        name="country"
                        value={form.country}
                        error={errors['country']}
                        onChange={handleInput}
                        width="100%"
                    >
                        <MenuItem data-value={'switzerland'}>
                            {t('switzerland')}
                        </MenuItem>
                        <MenuItem data-value={'germany'}>
                            {t('germany')}
                        </MenuItem>
                        <MenuItem data-value={'austria'}>
                            {t('austria')}
                        </MenuItem>
                        <MenuItem data-value={'liechtenstein'}>
                            {t('liechtenstein')}
                        </MenuItem>
                    </StdSelect>
                )}
            </>
        );
    };

    return (
        <div className="company-details__section">
            <div className="font--secondary stack-ll profile__section_subTitle">
                {t('contact_address')}
            </div>
            <div className="company-details__sectionDevision">
                {showBillingAddress && (
                    <div className={clsx('company-details__form')}>
                        <StdTextField
                            disabled={formDisableState}
                            className="stack-l"
                            label={t('name_or_company')}
                            name="name"
                            error={errors['name']}
                            value={form.name}
                            onChange={handleInput}
                            width="100%"
                            autoComplete="billing name"
                        />
                        <StdTextField
                            disabled={formDisableState}
                            className="stack-l"
                            label={t('street_address')}
                            name="street"
                            error={errors['street']}
                            value={form.street}
                            onChange={handleInput}
                            width="100%"
                            autoComplete="billing street-address"
                        />
                        <StdTextField
                            disabled={formDisableState}
                            className="stack-l"
                            label={t('zip')}
                            name="zip"
                            error={errors['zip']}
                            value={form.zip}
                            onChange={handleInput}
                            width="100%"
                            autoComplete="billing postal-code"
                        />
                        <StdTextField
                            disabled={formDisableState}
                            className="stack-l"
                            label={t('city')}
                            name="city"
                            error={errors['city']}
                            value={form.city}
                            onChange={handleInput}
                            width="100%"
                            autoComplete="billing address-level2"
                        />
                        {renderSelectComponent()}
                        {editPermission && (
                            <div className="util__center">
                                <div
                                    className={`stack-m profile__btnWrap ${
                                        editButtonState
                                            ? 'profile__enableBtn'
                                            : ''
                                    }`}
                                >
                                    {editButtonState ? (
                                        <div>
                                            <StdButton
                                                size="large"
                                                onClick={() => {
                                                    setEditButtonState(false);
                                                    setFormDisableState(true);
                                                }}
                                            >
                                                {t('cancel')}
                                            </StdButton>
                                        </div>
                                    ) : null}
                                    <div>
                                        <StdButton
                                            className="profile__editOptionBtn"
                                            type={
                                                editButtonState
                                                    ? 'primary'
                                                    : 'secondary'
                                            }
                                            size="large"
                                            onClick={() => {
                                                setEditButtonState(true);
                                                setFormDisableState(false);
                                                editButtonState &&
                                                    onClickSubmit();
                                            }}
                                        >
                                            {editButtonState
                                                ? t('profile.save_changes')
                                                : t('edit')}
                                        </StdButton>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
                <div className="company-details__logoUploader">
                    <ProfileLogoUploader
                        logo={companyLogo}
                        editPermission={editPermission}
                    />
                </div>
            </div>
        </div>
    );
};

export default CompanyDetailsSection;
