export const getFullname = (
    firstname?: string,
    lastname?: string,
    placeholder: string = '---'
) => {
    return `${firstname || ''} ${lastname || ''}`.trim() || placeholder;
};

// Uility functions meant to handle the communications array on account
export const getCommEmail = (comm?: ICommField[]) => {
    if (!Array.isArray(comm)) return '';
    return comm.find((com) => com.com_type === 'EMAIL')?.com_value || '';
};

export const getCommPhone = (comm?: ICommField[]) => {
    if (!Array.isArray(comm)) return '';
    return comm.find((com) => com.com_type === 'MOB')?.com_value || '';
};

export function accountToForm(user: IUser) {
    const { firstname, lastname, email, com } = user;
    return {
        first_name: firstname || '',
        last_name: lastname || '',
        phone: getCommPhone(com) || '',
        email: getCommEmail(com) || email || ''
    };
}
