import { takeEvery } from 'redux-saga/effects';

import { POST_ELEMENT_REQUEST } from '../../actions';
import {
    postElementSuccess,
    postElementFail,
    IPostElement
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeEvery(
    POST_ELEMENT_REQUEST,
    simpleRequest<IPostElement>({
        success: postElementSuccess,
        failure: postElementFail,
        getUrl: (_) => 'element/element_create/',
        getBody: (action) => action.element,
        method: 'POST'
    })
);
