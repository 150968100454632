import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import StdIcon from '../../atoms/StdIcon';
import StdImage from '../../atoms/StdImage';
import StdButton from '../../atoms/StdButton';

import './manager-card.scss';
import StdChip from '../../atoms/StdChip';

interface IProps extends IClass {
    fullname: string;
    email: string;
    role: 'admin' | 'manager' | 'team_member' | 'owner';
    width?: string;
    status?: 'pending' | 'expired';
    onEdit?: ICallback; // Note: not meant to be implemented at the moment
    onResend?: ICallback;
    onDelete?: ICallback;
}

const ManagerCard: React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    const {
        className,
        fullname,
        role,
        email,
        width,
        status,
        onEdit,
        onResend,
        onDelete
    } = props;

    const canResend =
        (status === 'pending' || status === 'expired') && onResend;
    const isOwner = role === 'owner' || role === 'admin';
    // const isAdmin = role === 'admin';
    return (
        <div
            style={{ width }}
            className={clsx('manager-card__container', className)}
        >
            <div className="manager-card__main">
                <div className="manager-card__picture inline-m">
                    <StdImage icon="user" iconPrefix="far" />
                </div>
                <div className="manager-card__content inline-s">
                    <div className="stack-s">
                        <span className="inline-s">{fullname}</span>
                        <span className="manager-card__role">
                            {t(`team.role.${role}`)}
                        </span>
                    </div>
                    <div className="manager-card__email">
                        <StdIcon
                            className="inline-s"
                            name="envelope"
                            prefix="fas"
                            small
                        />
                        <span>{email}</span>
                        {canResend && (
                            <div className="manager-card__pending inline-l">
                                <StdChip small>{t(`team.status.${status}`)}</StdChip>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="manager-card__actions">
                {canResend && (
                    <StdButton className="inline-s" onClick={onResend}>
                        {t('team.resend_invite')}
                    </StdButton>
                )}
                {!isOwner && !canResend && onEdit && (
                    <StdButton className="inline-s">{t('change')}</StdButton>
                )}
                {!isOwner && onDelete && (
                    <StdButton type="round" onClick={onDelete}>
                        <StdIcon name="trash-alt" prefix="far" small />
                    </StdButton>
                )}
            </div>
        </div>
    );
};

export default ManagerCard;
