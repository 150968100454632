import { takeLatest, put } from 'redux-saga/effects';

import { LOGIN_REQUEST } from '../../actions';
import {
    showSnackbar,
    login,
    loginSuccess,
    loginFail,
    sessionStart,
    sessionEnd
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

import history from '../../../modules/history';

export default takeLatest(
    LOGIN_REQUEST,
    simpleRequest<RT<typeof login>>({
        success: loginSuccess,
        failure: loginFail,
        getUrl: () => `auth-users/jwt/create/`,
        getBody: (action) => action.credentials,
        method: 'POST',
        withAuth: false,
        enableOffline: true, // Note: quickfix to allow to login in offlince mode to sync
        onSuccess: function* (data, action) {
            yield put(sessionEnd());

            const { access, refresh } = data || {};
            yield put(sessionStart({ access, refresh }, { verified: true }));

            history.replace(action.redirect);
            return data;
        },
        onFail: function* (error: INetworkError) {
            yield put(
                showSnackbar({ message: 'login_attempt_failed_error', error })
            );
            yield put(sessionEnd());
        }
    })
);
