import React from 'react';
import * as Sentry from '@sentry/react';
import { isNetworkDownError } from '../modules/error';

import ErrorPageNetwork from '../components/error/ErrorPageNetwork';
import ErrorPageScript from '../components/error/ErrorPageScript';

interface IProps {
    fallback?: React.ReactNode;
}

interface IState {
    hasError: boolean;
    error?: IMaybeNetworkError;
}

class ErrorBoundary extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error: IMaybeNetworkError) {
        console.error('Rendering error: ', error.message);
        console.error('Stack trace: ', error.stack);

        Sentry.captureException(error);
        this.setState({ hasError: true, error });
    }

    render() {
        const { children, fallback } = this.props;
        const { hasError, error } = this.state;

        if (!hasError) return children;
        if (fallback) return fallback;

        // Take care of a case where another chunk cannot be loaded due to network issues
        const isBundleFetchFailure = error && isNetworkDownError(error);

        if (isBundleFetchFailure) {
            return <ErrorPageNetwork />;
        }
        return <ErrorPageScript />;
    }
}

export default ErrorBoundary;
