import { takeEvery } from 'redux-saga/effects';
import { UPDATE_CONTACT_ADDRESS_REQUEST } from '../../actions';
import {
    updateContactAddress,
    updateContactAddressSuccess,
    updateContactAddressFail
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeEvery(
    UPDATE_CONTACT_ADDRESS_REQUEST,
    simpleRequest<ReturnType<typeof updateContactAddress>>({
        success: updateContactAddressSuccess,
        failure: updateContactAddressFail,
        getUrl: (_) => 'user/me/contact_address/',
        getBody: (action) => action.payload,
        method: 'POST'
    })
);
