import { useSelector } from 'react-redux';

// Note: isArray validations can be useful for some outdate fields
// but usually response.results returns an array for paginated endpoints
// so this check is somewhat redundant
const useArraySelector = <T>(
    selector: (state: IRootState) => IGetManyRequestResult<T>
): [T[], IGetManyRequestResult<T>] => {
    const response = useSelector(selector);
    const items = Array.isArray(response.response.results)
        ? response.response.results
        : [];
    return [items, response];
};

export default useArraySelector;
