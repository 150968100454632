import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { MEDIA_ATTACHMENT_TYPE } from '../../../configs/costants';
import { ROOM_ATTRIBUTE_TYPE } from '../../../configs/basicData';
import { safeByIds } from '../../../store/storeModule';

import StdModal from '../../atoms/StdModal';
import StdDivider from '../../atoms/StdDivider';
import StdButton from '../../atoms/StdButton';
import AttributeList from '../AttributeList';
import LinkedAttachment from '../../molecules/LinkedAttachment.tsx';
import ViewModalHeader from '../../atoms/ViewModalHeader';

interface IProps extends IModalBase {
    room?: IRoom;
    onEdit?: (param?: IScrollType) => void;
}

const RoomModal: React.FC<IProps> = ({ open, room, onClose, onEdit }) => {
    const { t } = useTranslation();
    const { rom_name, rom_roa_id, uploads } = room || {};

    const lookupHash = useSelector((state) => state.basicData.response);
    const uploadStore = useSelector((state) => state.uploads.uploads);

    const roomAttrs = Array.isArray(rom_roa_id) ? rom_roa_id : [];
    const attrData = lookupHash?.[ROOM_ATTRIBUTE_TYPE];

    const generalAttrs = roomAttrs.map(
        ({ roa_id, roa_type = '', roa_value }) => ({
            id: roa_id,
            type: attrData?.[roa_type] || roa_type || '//',
            value: roa_value || ''
        })
    );

    const storedUploads = safeByIds(uploads || [], uploadStore.byIds);
    const attachments = storedUploads.filter(
        (upload) => upload.type === MEDIA_ATTACHMENT_TYPE
    );

    return (
        <StdModal open={open} onClose={onClose} white small>
            <ViewModalHeader
                title={t('room_details')}
                onClose={onClose}
                actionNode={
                    <StdButton type="primary" onClick={() => onEdit?.()}>
                        {t('change')}
                    </StdButton>
                }
            />
            <div className="modal__section">
                <div className="font__header">{rom_name}</div>
            </div>
            <StdDivider />
            <div className="modal__section">
                <div className="std-section stack-m">
                    <div className="modal__heading">
                        {t('description').toUpperCase()}
                    </div>
                    <StdButton onClick={() => onEdit?.('attr')} size="small">
                        {t('change')}
                    </StdButton>
                </div>
                <AttributeList attrs={generalAttrs} />
            </div>
            <StdDivider />
            <div className="modal__section">
                <div className="std-section stack-m">
                    <div>
                        <span className="modal__heading inline-xs">
                            {t('attachments').toUpperCase()}
                        </span>
                    </div>
                    <StdButton onClick={() => onEdit?.('attach')} size="small">
                        {t('change')}
                    </StdButton>
                </div>
                {Boolean(attachments.length) ? (
                    attachments.map((attachment, index) => {
                        const isLast = index === attachments.length - 1;
                        return (
                            <LinkedAttachment
                                key={attachment.id}
                                className={clsx(!isLast && 'stack-s')}
                                name={attachment.name}
                                path={attachment.path}
                            />
                        );
                    })
                ) : (
                    <div className="font--secondary">
                        {t('no_attachments_yet')}
                    </div>
                )}
            </div>
        </StdModal>
    );
};

export default RoomModal;
