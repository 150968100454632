import { takeEvery } from 'redux-saga/effects';
import { UPDATE_USER_MEDIA_REQUEST } from '../../actions';
import {
    updateUserMedia,
    updateUserMediaSuccess,
    updateUserMediaFail
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeEvery(
    UPDATE_USER_MEDIA_REQUEST,
    simpleRequest<ReturnType<typeof updateUserMedia>>({
        success: updateUserMediaSuccess,
        failure: updateUserMediaFail,
        getUrl: (_) => 'user/me/update_media/',
        getBody: (action) => action.payload,
        method: 'POST',
        isV2Api: true
    })
);
