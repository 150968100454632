import React from 'react';
import { frontDateFormat } from '../../../utils/formatter';
import { DatePicker, DatePickerProps } from '@material-ui/pickers';

interface IProps extends DatePickerProps {
    format?: string;
}

const StdDatePicker: React.FC<IProps> = (props) => {
    const { format = frontDateFormat, ...otherProps } = props;
    return (
        <DatePicker
            variant="inline"
            className="mui-datepicker__root"
            InputLabelProps={{
                classes: {
                    focused: 'mui-datepicker__input-label--focused'
                }
            }}
            InputProps={{
                classes: {
                    root: 'mui-datepicker__input-root'
                }
            }}
            PopoverProps={{
                classes: {
                    root: 'mui-datepicker__popover'
                }
            }}
            format={format}
            {...otherProps}
        />
    );
};

export default StdDatePicker;
