import { takeLatest } from 'redux-saga/effects';

import { GET_ISSUE_LOGS_REQUEST } from '../../actions';
import {
    getIssueLogs,
    getIssueLogsSuccess,
    getIssueLogsFail
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeLatest(
    GET_ISSUE_LOGS_REQUEST,
    simpleRequest<ReturnType<typeof getIssueLogs>>({
        success: getIssueLogsSuccess,
        failure: getIssueLogsFail,
        getUrl: (action) => `public_sharinglinklog/${action.token}/get_log/`
    })
);
