import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';

import {
    cancelSubscription,
    showSnackbar,
    getCurrentAcc
} from '../../../store/action-creators';
import { dispatchAsync } from '../../../store/storeModule';

import useInput from '../../../hooks/useInput';
import usePending from '../../../hooks/usePending';

import StdDivider from '../../atoms/StdDivider';
import StdButton from '../../atoms/StdButton';
import StdCheckbox from '../../atoms/StdCheckbox';
import StdTextField from '../../atoms/StdTextField';
import IconBullet from '../../atoms/IconBullet';
import i18n from '../../../i18next';

interface IForm {
    form: {
        custom_feedback: string;
        request_deletion: boolean;
    };
    errors: IErrorHash;
}

const initForm: IForm = {
    form: {
        custom_feedback: '',
        request_deletion: false
    },
    errors: {}
};

interface IProps {
    onBack?: ICallback;
}

const TrialCancelFeedback: React.FC<IProps> = ({ onBack }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [checked, setChecked] = useState<IHash<boolean>>({});

    const [input, setInput] = useState(initForm);
    const handleInput = useInput(setInput);

    const isLoading = usePending([cancelSubscription, getCurrentAcc]);
    const lang = i18n.language as ILang;

    const questionsHash: IHash<string> = {
        useage: t('usage_answer'),
        expense: t('expense_answer'),
        packages: t('packages_answer'),
        security: t('security_answer'),
        professionalism: t('professionalism_answer')
    };
    const keys = Object.keys(questionsHash);

    const onSubmit = () => {
        const { custom_feedback, request_deletion } = input.form;

        const byIndexHash = Object.keys(checked).reduce<IHash<string>>(
            (acc, key) => {
                const keyIndex = keys.indexOf(key);
                return checked[key] && keyIndex != null
                    ? {
                          ...acc,
                          [(keyIndex + 1).toString()]: questionsHash[key]
                      }
                    : acc;
            },
            {}
        );

        const feedbackHash = {
            ...byIndexHash,
            [keys.length + 1]: custom_feedback
        };

        const cancelLiteral = {
            reason: feedbackHash,
            deletion: request_deletion,
            lang
        };

        dispatchAsync(dispatch, cancelSubscription(cancelLiteral))
            .then((_) => {
                dispatch(
                    showSnackbar({
                        snackType: 'info',
                        message: 'on_feedback_success'
                    })
                );
                dispatch(getCurrentAcc());
                onBack?.();
            })
            .catch(({ error }) =>
                dispatch(showSnackbar({ message: 'on_feedback_fail', error }))
            );
    };

    const { form } = input;
    return (
        <div className="trial-cancel__container">
            <div className="trial-cancel__header stack-xl">
                <IconBullet className="inline-m" onClick={onBack}>
                    {t('back')}
                </IconBullet>
                <div className="font__header util__center inline-m">
                    {t('cancel_account_title')}
                </div>
                <IconBullet className="util__hidden">{t('back')}</IconBullet>
            </div>
            <StdDivider className="stack-xl" />
            <div className="trial-cancel__section">
                <div className="util__center stack-m">
                    {t('feedback_title')}
                </div>
                <div className="trial-cancel__feedback stack-l">
                    <div className="stack-l">{t('feedback_select_option')}</div>
                    {keys.map((key, index) => {
                        const isLast = index === keys.length - 1;
                        return (
                            <div
                                key={key}
                                className={clsx(isLast && 'stack-m')}
                            >
                                <StdCheckbox
                                    checked={Boolean(checked[key])}
                                    onClick={() =>
                                        setChecked((s) => ({
                                            ...s,
                                            [key]: !s[key]
                                        }))
                                    }
                                >
                                    {questionsHash[key]}
                                </StdCheckbox>
                            </div>
                        );
                    })}
                    <StdTextField
                        label={t('other_feedback')}
                        value={form.custom_feedback}
                        name="custom_feedback"
                        onChange={handleInput}
                        multiline
                        width="100%"
                    />
                </div>
                <div className="trial-cancel__account-option stack-l">
                    <StdCheckbox
                        checked={Boolean(form.request_deletion)}
                        onClick={() =>
                            setInput((s) => ({
                                ...s,
                                form: {
                                    ...s.form,
                                    request_deletion: !s.form.request_deletion
                                }
                            }))
                        }
                        slim
                    >
                        {t('trail_cancel.request_to_delete_account_message')}
                    </StdCheckbox>
                </div>
                <div className="trial-cancel__note stack-m">
                    {t('farewell_message')}
                </div>
                <StdButton
                    type="primary"
                    onClick={onSubmit}
                    disabled={isLoading}
                >
                    {t('canel_done')}
                </StdButton>
            </div>
        </div>
    );
};

export default TrialCancelFeedback;
