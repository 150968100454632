import React from 'react';
import clsx from 'clsx';

import { motion, AnimatePresence } from 'framer-motion';
import { Ripple } from '@rmwc/ripple';

import { ANIMATE_HEIGHT_PROPS } from '../../../configs/costants';

import './select-list-accordion.scss';

interface IProps extends IClass {
    open: boolean;
    width?: string;
    title: React.ReactNode;
    children?: React.ReactNode;
    onClick?: ICallback;
    disabled?: boolean;
}

const SelectListAccordion: React.FC<IProps> = ({
    className,
    open,
    width,
    title,
    children,
    disabled,
    onClick
}) => {
    return (
        <div className={clsx('select-list-accordion__container', className)}>
            <Ripple disabled={disabled}>
                <div
                    style={{ width }}
                    className="select-list-accordion__content"
                    onClick={onClick}
                >
                    {title}
                </div>
            </Ripple>
            {children && (
                <AnimatePresence initial={false}>
                    {open && (
                        <motion.div {...ANIMATE_HEIGHT_PROPS}>
                            {children}
                        </motion.div>
                    )}
                </AnimatePresence>
            )}
        </div>
    );
};

export default SelectListAccordion;
