export const UPLOAD_MEDIA_FUNCTION_REQUEST = 'UPLOAD_MEDIA_FUNCTION_REQUEST';
export const UPLOAD_MEDIA_FUNCTION_SUCCESS = 'UPLOAD_MEDIA_FUNCTION_SUCCESS';
export const UPLOAD_MEDIA_FUNCTION_FAIL = 'UPLOAD_MEDIA_FUNCTION_FAIL';

export const CREATE_MEDIA_OBJECT = 'CREATE_MEDIA_OBJECT';

export const CREATE_MEDIA_REQUEST = 'CREATE_MEDIA_REQUEST';
export const CREATE_MEDIA_SUCCESS = 'CREATE_MEDIA_SUCCESS';
export const CREATE_MEDIA_FAIL = 'CREATE_MEDIA_FAIL';

export const PATCH_MEDIA_REQUEST = 'PATCH_MEDIA_REQUEST';
export const PATCH_MEDIA_SUCCESS = 'PATCH_MEDIA_SUCCESS';
export const PATCH_MEDIA_FAIL = 'PATCH_MEDIA_FAIL';

export const RENAME_MEDIA_REQUEST = 'RENAME_MEDIA_REQUEST';
export const RENAME_MEDIA_SUCCESS = 'RENAME_MEDIA_SUCCESS';
export const RENAME_MEDIA_FAIL = 'RENAME_MEDIA_FAIL';

export const DELETE_MEDIA_REQUEST = 'DELETE_MEDIA_REQUEST';
export const DELETE_MEDIA_SUCCESS = 'DELETE_MEDIA_SUCCESS';
export const DELETE_MEDIA_FAIL = 'DELETE_MEDIA_FAIL';

export const DELETE_LOCAL_MEDIA = 'DELETE_LOCAL_MEDIA';

export const GET_VERSION_REQUEST = 'GET_VERSION_REQUEST';
export const GET_VERSION_SUCCESS = 'GET_VERSION_SUCCESS';
export const GET_VERSION_FAIL = 'GET_VERSION_FAIL';

export const SORTING_CRUD_REQUEST = 'SORTING_CRUD_REQUEST';
export const SORTING_CURD_SUCCESS = 'SORTING_CRUD_SUCCESS';
export const SORTING_CRUD_FAIL = 'SORTING_CRUD_FAIL';

export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const HIDE_SNACKBAR = 'HIDE_SNACKBAR';

export const CANCEL_MEDIA_UPLOAD = 'CANCEL_MEDIA_UPLOAD';

export const SET_CORE_LOADING = 'SET_CORE_LOADING';
export const SET_ACCOUNT_LOADING = 'SET_ACCOUNT_LOADING';
export const SET_CHECKOUT_LOADING = 'SET_CHECKOUT_LOADING';
export const DECREMENT_COUNTER = 'DECREMENT_COUNTER';

export const ON_MEDIA_UPLOAD_REQUEST = 'ON_MEDIA_UPLOAD_REQUEST';
export const ON_MEDIA_UPLOAD_SUCCESS = 'ON_MEDIA_UPLOAD_SUCCESS';
export const ON_MEDIA_UPLOAD_FAIL = 'ON_MEDIA_UPLOAD_FAIL';
export const ON_MEDIA_UPLOAD_CANCEL = 'ON_MEDIA_UPLOAD_CANCEL';
export const ON_MEDIA_UPLOAD_PROGRESS = 'ON_MEDIA_UPLOAD_PROGRESS';

export const SET_MEDIA_UPLOAD_PREVIEW = 'SET_MEDIA_UPLOAD_PREVIEW';
export const SET_MEDIA_UPLOAD_THUMBNAIL_PREVIEW =
    'SET_MEDIA_UPLOAD_THUMBNAIL_PREVIEW';

export const ON_OFFLINE_MDOE_SWITCH = 'ON_OFFLINE_MDOE_SWITCH';
export const ON_ONLINE_MODE_SWITCH = 'ON_ONLINE_MODE_SWITCH';


export const SET_SCREEN_SIZE = 'SET_SCREEN_SIZE';
