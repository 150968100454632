import React from 'react';
import { Tooltip, TooltipProps } from '@rmwc/tooltip';
import clsx from 'clsx';

export interface IProps extends IClass {
    content: TooltipProps['content'];
    showArrow?: TooltipProps['showArrow'];
    children: TooltipProps['children'];
    className?: TooltipProps['className'];
    open?: TooltipProps['open'];
    theme?: 'danger' | 'info' | 'warn';
}

const StdToolTip: React.FC<IProps> = ({
    content,
    showArrow = true,
    className,
    children,
    open,
    theme
}) => {
    return (
        <Tooltip
            content={content}
            className={clsx(
                theme === 'danger' && 'tooltip--danger',
                theme === 'warn' && 'tooltip--warn',
                theme === 'info' && 'tooltip--info',
                className
            )}
            showArrow={showArrow}
            open={open}
        >
            <div>{children}</div>
        </Tooltip>
    );
};

export default StdToolTip;
