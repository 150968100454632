import React from 'react';
import { useTranslation } from 'react-i18next';
import { DASHBOARD_ROUTE } from '../../../configs/routes';

import HeaderLogo from '../../atoms/HeaderLogo';
import StdButton from '../../atoms/StdButton';

const ErrorPageNetwork: React.FC = () => {
    const { t } = useTranslation();
    return (
        <div className="error__page">
            <div className="header">
                <HeaderLogo />
            </div>
            <div className="error__message">
                <div>
                    <img
                        className="error__illustration"
                        src="/static/images/error_page_illustration.png"
                        alt="error illustration"
                    />
                </div>
                <div className="font__title stack-m">
                    {t('offline.unavailable_offline_error_title')}
                </div>
                <div className="stack-xl">
                    {t('offline.unavailable_offline_error_description')}
                </div>
                <div>
                    <StdButton className="inline-l" to={DASHBOARD_ROUTE}>
                        {t('error_to_dashboard')}
                    </StdButton>
                    <StdButton onClick={() => window.location.reload()}>
                        {t('reload_the_page')}
                    </StdButton>
                </div>
            </div>
        </div>
    );
};

export default ErrorPageNetwork;
