import * as actions from '../actions/user';
import { createAction, createCustomAction } from 'typesafe-actions';
import { actionFail, actionPayload } from '../storeModule';

interface ICredentials {
    email: string;
    password: string;
}

interface ITokens {
    access: string | null;
    refresh: string | null;
}

export const login = createCustomAction(actions.LOGIN_REQUEST, (type) => {
    return (credentials: ICredentials, redirect: string) => ({
        type,
        credentials,
        redirect
    });
});

export const loginSuccess = createCustomAction(actions.LOGIN_SUCCESS);
export const loginFail = createCustomAction(actions.LOGIN_FAIL, actionFail);

export const refreshAccess = createAction(actions.REFRESH_ACCESS_REQUEST);
export const refreshAccessSuccess = createCustomAction(
    actions.REFRESH_ACCESS_SUCCESS,
    (type) => {
        return (tokens: ITokens) => ({ type, tokens });
    }
);
export const refreshAccessFail = createCustomAction(
    actions.REFRESH_ACCESS_FAIL,
    actionFail
);

export const logout = createAction(actions.SESSION_END);

export const magicLogin = createCustomAction(
    actions.MAGIC_LOGIN_REQUEST,
    (type) => {
        return (token: string) => ({ type, token });
    }
);
export const magicLoginSuccess = createCustomAction(
    actions.MAGIC_LOGIN_SUCCESS,
    actionPayload
);
export const magicLoginFail = createCustomAction(
    actions.MAGIC_LOGIN_FAIL,
    actionFail
);

export const getCurrentAcc = createAction(actions.GET_CURRENT_ACC_REQUEST);
export const getCurrentAccSuccess = createCustomAction(
    actions.GET_CURRENT_ACC_SUCCESS,
    actionPayload
);
export const getCurrentAccFail = createCustomAction(
    actions.GET_CURRENT_ACC_FAIL,
    actionFail
);

interface IUpdatedAccount {
    fullname?: string;
    mobile_number?: string;
    use_beta?: boolean;
    persona?:
        | 'LANDLORD'
        | 'MANAGEMENT'
        | 'EXPERT'
        | 'BIZ_DEV'
        | 'RENTALS'
        | 'TENANT';
}

export const patchAccount = createCustomAction(
    actions.PATCH_ACCOUNT_REQUEST,
    (type) => {
        return (account: IUpdatedAccount) => ({ type, account });
    }
);
export const patchAccountSuccess = createCustomAction(
    actions.PATCH_ACCOUNT_SUCCESS,
    (type) => {
        return (payload: IUser) => ({ type, payload });
    }
);
export const patchAccountFail = createCustomAction(
    actions.PATCH_ACCOUNT_FAIL,
    actionFail
);

interface ISubscriptionPayload {
    plan: number;
}

export const changeSubscription = createCustomAction(
    actions.CHANGE_SUBSCRIPTION_REQUEST,
    (type) => {
        return (payload: ISubscriptionPayload) => ({ type, payload });
    }
);
export const changeSubscriptionSuccess = createCustomAction(
    actions.CHANGE_SUBSCRIPTION_SUCCESS
);
export const changeSubscriptionFail = createCustomAction(
    actions.CHANGE_SUBSCRIPTION_FAIL,
    actionFail
);

interface ICancelSubscriptionPayload {
    reason: IHash<string>;
    deletion?: boolean;
    lang?: ILang;
}

export const cancelSubscription = createCustomAction(
    actions.CANCEL_SUBSCRIPTION_REQUEST,
    (type) => {
        return (payload: ICancelSubscriptionPayload) => ({ type, payload });
    }
);
export const cancelSubscriptionSuccess = createCustomAction(
    actions.CANCEL_SUBSCRIPTION_SUCCESS
);
export const cancelSubscriptionFail = createCustomAction(
    actions.CANCEL_SUBSCRIPTION_FAIL,
    actionFail
);

export const getBilling = createCustomAction(actions.GET_BILLING_REQUEST);
export const getBillingSuccess = createCustomAction(
    actions.GET_BILLING_SUCCESS,
    actionPayload
);
export const getBillingFail = createCustomAction(
    actions.GET_BILLING_FAIL,
    actionFail
);

export const resetPassword = createCustomAction(
    actions.RESET_PASSWORD_REQUEST,
    (type) => {
        return (email: string, lang: ILang) => ({
            type,
            payload: { email },
            lang
        });
    }
);

export const resetPasswordSuccess = createCustomAction(
    actions.RESET_PASSWORD_SUCCESS
);
export const resetPasswordFail = createCustomAction(
    actions.RESET_PASSWORD_FAIL,
    actionFail
);

export const checkEmail = createCustomAction(
    actions.CHECK_EMAIL_REQUEST,
    (type) => {
        return (email: string) => ({ type, payload: { email } });
    }
);
export const checkEmailSuccess = createCustomAction(
    actions.CHECK_EMAIL_SUCCESS,
    actionPayload
);
export const checkEmailFail = createCustomAction(
    actions.CHECK_EMAIL_FAIL,
    actionFail
);
export const clearCheckEmail = createCustomAction(actions.CLEAR_CHECK_EMAIL);

export const publicCheckEmail = createCustomAction(
    actions.PUBLIC_CHECK_EMAIL_REQUEST,
    (type) => {
        return (email: string) => ({ type, payload: { email } });
    }
);
export const publicCheckEmailSuccess = createCustomAction(
    actions.PUBLIC_CHECK_EMAIL_SUCCESS,
    actionPayload
);
export const publicCheckEmailFail = createCustomAction(
    actions.PUBLIC_CHECK_EMAIL_FAIL,
    actionFail
);
export const publicClearCheckEmail = createCustomAction(
    actions.PUBLIC_CLEAR_CHECK_EMAIL
);

export const verifyToken = createCustomAction(
    actions.VERFIY_TOKEN_REQUEST,
    (type) => {
        return (token: string) => ({ type, token });
    }
);
export const verifyTokenSuccess = createCustomAction(
    actions.VERIFY_TOKEN_SUCCESS,
    actionPayload
);
export const verifyTokenFail = createCustomAction(
    actions.VERIFY_TOKEN_FAIL,
    actionFail
);

interface ICreateUser {
    intercom_id?: string;
    full_name: string;
    email: string;
    phone?: string;
    password: string;
    persona: string;
}

export const createUser = createCustomAction(
    actions.CREATE_USER_REQUEST,
    (type) => {
        return (payload: ICreateUser, lang: ILang) => ({ type, payload, lang });
    }
);
export const createUserSuccess = createCustomAction(
    actions.CREATE_USER_SUCCESS,
    actionPayload
);
export const createUserFail = createCustomAction(
    actions.CREATE_USER_FAIL,
    actionFail
);

export const resendVerification = createCustomAction(
    actions.RESEND_VERIFICATION_REQUEST,
    (type) => {
        return (email: string, lang: ILang) => ({ type, email, lang });
    }
);
export const resendVerificationSuccess = createCustomAction(
    actions.RESEND_VERIFICATION_SUCCESS,
    actionPayload
);
export const resendVerificationFail = createCustomAction(
    actions.RESEND_VERIFICATION_FAIL,
    actionFail
);

interface IModPassword {
    current_password: string;
    new_password: string;
}

export const setPassword = createCustomAction(
    actions.SET_PASSWORD_REQUEST,
    (type) => {
        return (payload: IModPassword) => ({ type, payload });
    }
);
export const setPasswordSuccess = createCustomAction(
    actions.SET_PASSWORD_SUCCESS,
    actionPayload
);
export const setPasswordFail = createCustomAction(
    actions.SET_PASSWORD_FAIL,
    actionFail
);

export const resetPasswordConfirm = createCustomAction(
    actions.RESET_PASSWORD_CONFIRM_REQUEST,
    (type) => {
        return (
            uid: string,
            token: string,
            payload: Pick<IModPassword, 'new_password'>,
            lang: ILang
        ) => ({ type, uid, token, payload, lang });
    }
);
export const resetPasswordConfirmSuccess = createCustomAction(
    actions.RESET_PASSWORD_CONFIRM_SUCCESS,
    actionPayload
);
export const resetPasswordConfirmFail = createCustomAction(
    actions.RESET_PASSWORD_CONFIRM_FAIL
);

export const verifyAccount = createCustomAction(
    actions.VERIFY_ACCOUNT_REQUEST,
    (type) => {
        return (uid: string, token: string) => ({ type, uid, token });
    }
);
export const verifyAccountSuccess = createCustomAction(
    actions.VERIFY_ACCOUNT_SUCCESS
);
export const verifyAccountFail = createCustomAction(
    actions.VERIFY_ACCOUNT_FAIL,
    actionFail
);

export const checkActive = createCustomAction(
    actions.CHECK_ACTIVE_REQUEST,
    (type) => {
        return (email: string, uid: string) => ({ type, email, uid });
    }
);
export const checkActiveSuccess = createCustomAction(
    actions.CHECK_ACTIVE_SUCCESS,
    actionPayload
);
export const checkActiveFail = createCustomAction(
    actions.CHECK_ACTIVE_FAIL,
    actionFail
);

export const getUsageLogs = createCustomAction(
    actions.GET_USAGE_LOGS_REQUEST,
    (type) => {
        return (isSummary?: boolean, date?: string) => ({
            type,
            isSummary,
            date
        });
    }
);
export const getUsageLogsSuccess = createCustomAction(
    actions.GET_USAGE_LOGS_SUCCESS,
    actionPayload
);
export const getUsageLogsFail = createCustomAction(
    actions.GET_USAGE_LOGS_FAIL,
    actionFail
);

export const getPackages = createCustomAction(actions.GET_PACKAGES_REQUEST);
export const getPackagesSuccess = createCustomAction(
    actions.GET_PACKAGES_SUCCESS,
    actionPayload
);
export const getPackagesFail = createCustomAction(
    actions.GET_PACKAGES_FAIL,
    actionFail
);

// --- Unused
export const getUsageHistory = createCustomAction(
    actions.GET_USAGE_HISTORY_REQUEST
);
export const getUsageHistorySuccesss = createCustomAction(
    actions.GET_USAGE_HISTORY_SUCCESS,
    actionPayload
);
export const getUsageHistroyFail = createCustomAction(
    actions.GET_USAGE_HISTORY_FAIL,
    actionFail
);
//

export const getInvoice = createCustomAction(
    actions.GET_INVOICE_REQUEST,
    (type) => {
        return (id: number) => ({ type, id });
    }
);
export const getInvoiceSuccess = createCustomAction(
    actions.GET_INVOICE_SUCCESS,
    actionPayload
);
export const getInvoiceFail = createCustomAction(
    actions.GET_INVOICE_FAIL,
    actionFail
);

export const adminLogin = createCustomAction(
    actions.ADMIN_LOGIN_REQUEST,
    (type) => {
        return (email: string) => ({ type, email });
    }
);
export const adminLoginSuccess = createCustomAction(
    actions.ADMIN_LOGIN_SUCCESS,
    actionPayload
);
export const adminLoginFail = createCustomAction(
    actions.ADMIN_LOGIN_FAIL,
    actionFail
);

export const enterpriseAdminLogin = createCustomAction(
    actions.ENTERPRISE_ADMIN_LOGIN_REQUEST,
    (type) => {
        return (email: string) => ({ type, email });
    }
);
export const enterpriseAdminLoginSuccess = createCustomAction(
    actions.ENTERPRISE_ADMIN_LOGIN_SUCCESS,
    actionPayload
);
export const enterpriseAdminLoginFail = createCustomAction(
    actions.ENTERPRISE_ADMIN_LOGIN_FAIL,
    actionFail
);

interface IUpdateMedia {
    media_ids: number[];
}

export const updateUserMedia = createCustomAction(
    actions.UPDATE_USER_MEDIA_REQUEST,
    (type) => {
        return (payload: IUpdateMedia) => ({ type, payload });
    }
);
export const updateUserMediaSuccess = createCustomAction(
    actions.UPDATE_USER_MEDIA_SUCCESS,
    actionPayload
);
export const updateUserMediaFail = createCustomAction(
    actions.UPDATE_USER_MEDIA_FAIL,
    actionFail
);

interface IUpdateBilling {
    city: string;
    country: string;
    full_name: string;
    street: string;
    street_number: string;
    zip: string;
    region: string;
}

export const updateBilling = createCustomAction(
    actions.UPDATE_BILLING_REQUEST,
    (type) => {
        return (payload: IUpdateBilling) => ({ type, payload });
    }
);
export const updateBillingSuccess = createCustomAction(
    actions.UPDATE_BILLING_SUCCESS,
    actionPayload
);
export const updateBillingFail = createCustomAction(
    actions.UPDATE_BILLING_FAIL,
    actionFail
);

export const updateContactAddress = createCustomAction(
    actions.UPDATE_CONTACT_ADDRESS_REQUEST,
    (type) => {
        return (payload: IUpdateBilling) => ({ type, payload });
    }
);
export const updateContactAddressSuccess = createCustomAction(
    actions.UPDATE_CONTACT_ADDRESS_SUCCESS,
    actionPayload
);
export const updateContactAddressFail = createCustomAction(
    actions.UPDATE_CONTACT_ADDRESS_FAIL,
    actionFail
);

export const sessionStart = createCustomAction(
    actions.SESSION_START,
    (type) => {
        return (
            tokens: ITokens,
            config?: { verified?: boolean; omitUser?: boolean }
        ) => ({ type, tokens, config });
    }
);
export const sessionEnd = createCustomAction(actions.SESSION_END);

export const predictAccount = createCustomAction(
    actions.PREDICT_ACCOUNT,
    (type) => {
        return (payload: Partial<IUser>) => ({ type, payload });
    }
);
