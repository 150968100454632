export const copyToClip = (
    text: string,
    onSuccess?: ICallback,
    onFail?: ICallback
) => {
    // Modern implementation
    if (navigator.clipboard) {
        navigator.clipboard.writeText(text).then(onSuccess).catch(onFail);
    } else {
        // Legacy implementation
        const el = document.createElement('textarea');
        el.value = text;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px'; // Move outside the screen to make it invisible
        document.body.appendChild(el); // Append the <textarea> element to the HTML document

        const selection = document.getSelection();
        const selected =
            selection && selection?.rangeCount > 0 // Check if there is any content selected previously
                ? selection.getRangeAt(0) // Store selection if found
                : false; // Mark as false to know no selection existed before
        el.select();

        const result = document.execCommand('copy'); // Copy - only works as a result of a user action (e.g. click events)
        if (result) onSuccess?.();
        else onFail?.();

        document.body.removeChild(el);

        if (selected && selection) {
            // If a selection existed before copying
            selection.removeAllRanges(); // Unselect everything on the HTML document
            selection.addRange(selected); // Restore the original selection
        }
    }
};
