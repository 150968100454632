import { takeLatest, call } from 'redux-saga/effects';
import queryString from 'query-string';

import { GET_ALL_ISSUE_REQUEST } from '../../actions';
import {
    getAllIssueSuccess,
    getAllIssueFail,
    getAllIssue
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

import { fetchFailError } from '../../../modules/errorHandlers';
import { normalizeIssues } from '../modules/normalization';

type IGetIssuesAction = RT<typeof getAllIssue>;

export default takeLatest(
    GET_ALL_ISSUE_REQUEST,
    simpleRequest<IGetIssuesAction>({
        success: getAllIssueSuccess,
        failure: getAllIssueFail,
        getUrl: (action) =>
            `issue/?lang=${action.lang}&${queryString.stringify(
                action.params || {}
            )}`,
        onFail: function* (error: INetworkError) {
            yield call(fetchFailError, 'fetch_issues_error', error);
        },
        onSuccess: function (data) {
            const normalisedData = normalizeIssues(data);
            return {...data, ...normalisedData};
        }
    })
);
