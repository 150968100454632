import React from 'react';
import clsx from 'clsx';
import { matchSimple } from '../../../utils';

import { IconPrefix, IconName } from '@fortawesome/fontawesome-common-types';
import StdIcon from '../StdIcon';

import './std-step.scss';

interface IProps extends IClass {
    iconName: IconName;
    iconPrefix?: IconPrefix;
    stepIconPrefix?: IconPrefix;
    stepIcon?: IconName;

    title?: string;
    active?: boolean;
    last?: boolean;
    highlighted?: boolean;
    completed?: boolean;
    selected?: boolean;
    onClick?: ICallback;
}

const StdStep: React.FC<IProps> = (props) => {
    const {
        className,
        stepIconPrefix = 'fal',
        stepIcon = 'chevron-right',
        iconPrefix = 'fal',
        iconName,
        title,
        last,
        active,
        highlighted,
        completed,
        selected,
        onClick
    } = props;

    const containerClass = clsx(
        'std-step',
        !active && 'std-step--inactive',
        highlighted && 'std-step--highlighted',
        selected && 'std-step--selected',
        !last && 'inline-s',
        className
    );

    const name = matchSimple()
        .on(Boolean(completed), 'check-circle')
        .on(Boolean(highlighted), 'exclamation-circle')
        .otherwise(iconName);

    const prefix = matchSimple()
        .on(Boolean(completed), 'fal')
        .on(Boolean(highlighted), 'fal')
        .otherwise(iconPrefix);

    return (
        <>
            <div className={containerClass} onClick={onClick}>
                <StdIcon
                    className={clsx(title && 'inline-s')}
                    name={name}
                    prefix={prefix}
                />
                {title && <span>{title}</span>}
            </div>
            {!last && (
                <StdIcon
                    className={clsx('inline-s', !active && 'color-grey')}
                    prefix={stepIconPrefix}
                    name={stepIcon}
                    small
                />
            )}
        </>
    );
};

export default StdStep;
