import { takeLatest, put } from 'redux-saga/effects';

import { ENTERPRISE_ADMIN_LOGIN_REQUEST } from '../../actions';
import {
    enterpriseAdminLogin,
    enterpriseAdminLoginSuccess,
    enterpriseAdminLoginFail,
    sessionStart,
    sessionEnd
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeLatest(
    ENTERPRISE_ADMIN_LOGIN_REQUEST,
    simpleRequest<RT<typeof enterpriseAdminLogin>>({
        success: enterpriseAdminLoginSuccess,
        failure: enterpriseAdminLoginFail,
        getUrl: () => `auth-users/company_member_token/`,
        getBody: (action) => ({ email: action.email }),
        method: 'POST',
        onSuccess: function* (data) {
            yield put(sessionEnd());

            const { access, refresh } = data || {};
            yield put(sessionStart({ access, refresh }, { verified: true }));
            return data;
        }
    })
);
