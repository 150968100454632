import { takeEvery, put } from 'redux-saga/effects';
import queryString from 'query-string';

import { checkTeamInviteSet, sessionEnd } from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeEvery(
    checkTeamInviteSet.type,
    simpleRequest<RT<typeof checkTeamInviteSet.request>>({
        success: checkTeamInviteSet.success,
        failure: checkTeamInviteSet.fail,
        getUrl: (action) =>
            `group_invitation/check_validity/?${queryString.stringify({
                token: action.token
            })}`,
        onSuccess: function* () {
            // TOOD: add 'finally'
            yield put(sessionEnd());
        },
        onFail: function* () {
            yield put(sessionEnd());
        },
        isV2Api: true
    })
);
