import { Action, ActionCreator, AnyAction, Dispatch } from 'redux';
import { createCustomAction } from 'typesafe-actions';
import { cancelErrorFactory } from '../modules/error';

export function safeByIds<T>(ids: number[], hash: IHash<T>) {
    return ids?.map((id) => hash[id]).filter((entity) => Boolean(entity));
}

export const getAsyncActName = (type: string) => {
    const name = type.split('_');
    name.pop();

    return name.join('_');
};

export function getAsyncActionCreatorName(
    actionCreator: ActionCreator<AnyAction>
) {
    return getAsyncActName(getCreatorType(actionCreator));
}

export function isActionIncluded(action: AnyAction, actionNames: string[]) {
    return actionNames.some((actionName) => actionName === action.type);
}

export function getCreatorType(actionCreator: ActionCreator<AnyAction>) {
    return actionCreator().type;
}

export function getActionId(action: AnyAction): number {
    return (action as AnyAction).localId;
}

interface IActionSet<T extends string = string> {
    request: T;
    success: string;
    fail: string;
    skip: string;
}

export function asyncActionSet<T extends string = string>(
    name: string
): IActionSet<T> {
    return {
        request: `${name}_REQUEST` as T,
        success: `${name}_SUCCESS`,
        fail: `${name}_FAIL`,
        skip: `${name}_SKIP`
    };
}

export function requestCreatorSet<
    T extends string,
    AC extends ActionCreator<Action<T>>
>(name: string, createHandler: (type: T) => AC) {
    const actions = asyncActionSet<T>(name);
    return asyncCreatorSet(actions, createHandler);
}

export function asyncCreatorSet<
    T extends string,
    AC extends ActionCreator<Action<T>>
>(set: IActionSet<T>, createHandler: (type: T) => AC) {
    return {
        types: set,
        type: set.request,
        request: createCustomAction(set.request, createHandler),
        success: createCustomAction(set.success, actionPayload),
        fail: createCustomAction(set.fail, actionFail)
    };
}

// Action creator utility functions
export const actionFail =
    <T>(type: T) =>
    (error: Error, itemId?: number) => ({ type, error, itemId });
export const actionPayload =
    <T>(type: T) =>
    (payload: any) => ({ type, payload });

export function skipActionFactory(name: string, meta: object = {}) {
    return {
        type: `${name}_SKIP`,
        error: cancelErrorFactory(),
        ...meta
    };
}

// Unused currently, could be useful for defining default success, fail actions
export function successActionFactory(
    name: string,
    payload: any,
    meta: object = {}
) {
    return {
        type: `${name}_SUCCESS`,
        payload,
        ...meta
    };
}

export function failActionFactory(
    name: string,
    error: Error,
    meta: object = {}
) {
    return {
        type: `${name}_FAIL`,
        error,
        ...meta
    };
}

// Relies on using simpleRequest when defining request
// otherwise the calls have to be implemented manually, i.e for media_create

// i.e [NAME]_REQUEST, [NAME]_SUCCESS, [NAME]_FAIL

interface IDispatchAsyncParams {
    disableLoading?: boolean;
}

export const dispatchAsync = (
    dispatch: Dispatch<AnyAction>,
    action: AnyAction,
    params?: IDispatchAsyncParams
) => {
    const { disableLoading } = params || {};

    return new Promise((resolve, reject) => {
        const __handler: IAsyncActionHandler = {
            disableLoading,
            resolve,
            reject
        };

        dispatch({ ...action, __handler });
    });
};

export const dispatchAll = (
    dispatch: Dispatch<AnyAction>,
    actions: AnyAction[],
    params?: IDispatchAsyncParams
) => {
    return Promise.all(
        actions.map((action) => dispatchAsync(dispatch, action, params))
    );
};

export const disableLoading = (action: AnyAction) => {
    const actionHandler = action.__handler ? action.__handler : {};
    return { ...action, __handler: { ...actionHandler, disableLoading: true } };
};
