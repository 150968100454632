import React from 'react';
import CustomIcon from '../CustomIcon';
import ModalHeader from '../ModalHeader';

interface IProps {
    title: ReactNode;
    actionNode?: ReactNode;
    onClose: ICallback;
    width?: string;
}

const ViewModalHeader: React.FC<IProps> = ({
    title,
    width,
    actionNode,
    onClose
}) => {
    return (
        <ModalHeader
            leftNode={<CustomIcon name="cross" onClick={onClose} ripple />}
            centerNode={title}
            rightNode={actionNode}
            width={width}
        />
    );
};

export default ViewModalHeader;
