import { useRef, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { MAP_ZOOM } from '../configs/costants';
import { showSnackbar } from '../store/action-creators';
import { addressFormToString } from '../modules/property';

type IGeocoderSuccessCallback = (
    result: google.maps.GeocoderResult | google.maps.places.PlaceResult
) => void;

const useGoogleMap = (onChangePlaces?: IGeocoderSuccessCallback) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [mapLoading, setMapLoading] = useState(true);

    const inputRef = useRef<HTMLInputElement | null>(null);
    const mapRef = useRef<google.maps.Map | null>(null);
    const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(
        null
    );
    const markerRef = useRef<google.maps.Marker | null>(null);
    const geocoderRef = useRef<google.maps.Geocoder | null>(null);

    const removeMarker = useCallback(() => {
        markerRef.current?.setMap(null);
        markerRef.current = null;
    }, []);

    const showOnMap = useCallback(
        (latlng: google.maps.LatLng | google.maps.LatLngLiteral) => {
            const map = mapRef.current;
            if (map) {
                map.setCenter(latlng);
                map.setZoom(MAP_ZOOM);

                removeMarker();
                markerRef.current = new google.maps.Marker({
                    map,
                    position: latlng
                });
            }
        },
        [removeMarker]
    );

    const handleChangePlaces = useCallback(() => {
        const place = autocompleteRef.current?.getPlace();
        const map = mapRef.current;

        removeMarker();

        if (!place || !map) return;
        if (!place.geometry) {
            dispatch(
                showSnackbar({
                    snackType: 'black',
                    message: t('locaton_not_found_message')
                })
            );
            return;
        }
        onChangePlaces?.(place);
        if (place.geometry.location) showOnMap(place.geometry.location);
    }, [t, dispatch, removeMarker, showOnMap, onChangePlaces]);

    const handleGeocode = useCallback(
        (location: { lat: number; lng: number }) =>
            new Promise<google.maps.GeocoderResult>((resolve, reject) => {
                const map = mapRef.current;
                const geocoder = geocoderRef.current;

                if (!map || !geocoder) return;

                geocoder.geocode({ location }, (results, status) => {
                    if (status === google.maps.GeocoderStatus.OK && results) {
                        const [result] = results;

                        resolve(result);
                        showOnMap(result.geometry.location);
                    } else {
                        reject();
                    }
                });
            }),
        [showOnMap]
    );

    const handleReverseGeocode = useCallback(
        (address: string) =>
            new Promise<google.maps.GeocoderResult>((resolve, reject) => {
                const map = mapRef.current;
                const geocoder = geocoderRef.current;

                if (geocoder && map) {
                    geocoder.geocode({ address }, (results, status) => {
                        if (
                            status === google.maps.GeocoderStatus.OK &&
                            results
                        ) {
                            const [result] = results;
                            const {
                                geometry: { location }
                            } = result;

                            resolve(result);
                            showOnMap(location);
                        } else {
                            reject();
                        }
                    });
                }
            }),
        [showOnMap]
    );

    const handleLoadMap = useCallback(
        (onMapLoad?: ICallback, disableInput?: boolean) =>
            (map: google.maps.Map) => {
                const addressInput = inputRef.current;

                if (!disableInput && addressInput) {
                    const autocomplete = new google.maps.places.Autocomplete(
                        addressInput
                    );

                    autocomplete.setTypes(['address']);
                    autocomplete.setFields([
                        'address_components',
                        'formatted_address',
                        'geometry'
                    ]);

                    // Bias the autocomplete results towards current map's viewport.
                    map.addListener('bounds_changed', () => {
                        autocomplete.setBounds(
                            map.getBounds() as google.maps.LatLngBounds
                        );
                    });
                    autocomplete.addListener(
                        'place_changed',
                        handleChangePlaces
                    );

                    autocompleteRef.current = autocomplete;
                }

                // Assign the relevant stuff to refs
                mapRef.current = map;
                geocoderRef.current = new google.maps.Geocoder();

                setMapLoading(false);

                onMapLoad && onMapLoad();
            },
        [handleChangePlaces]
    );

    const handleLocate = useCallback(
        (address: IAddressForm) => {
            const { lat, lng } = address.address_extra;
            const fallbackAddress = addressFormToString(address);

            lat != null && lng != null
                ? showOnMap({ lat, lng })
                : handleReverseGeocode(fallbackAddress).catch((_) =>
                      dispatch(
                          showSnackbar({ message: 'address_not_found_message' })
                      )
                  );
        },
        [dispatch, handleReverseGeocode, showOnMap]
    );

    return {
        mapLoading,
        inputRef,
        handleLoadMap,
        handleLocate,
        removeMarker,
        showOnMap,
        handleReverseGeocode,
        handleGeocode
    };
};

export default useGoogleMap;
