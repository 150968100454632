import { takeLatest, call } from 'redux-saga/effects';
import { normalize } from 'normalizr';

import { GET_ATTENDEES_REQUEST } from '../../actions';
import {
    getAttendees,
    getAttendeesSuccess,
    getAttendessFail
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

import { fetchFailError } from '../../../modules/errorHandlers';
import { justArray } from '../../../utils';
import { attendeeSchema } from '../../reducers/schema';

export default takeLatest(
    GET_ATTENDEES_REQUEST,
    simpleRequest<RT<typeof getAttendees>>({
        success: getAttendeesSuccess,
        failure: getAttendessFail,
        getUrl: (action) => `protocol/${action.protoId}/protocol_attendees/`,
        onFail: function* (error: INetworkError) {
            yield call(fetchFailError, 'fetch_tenants_error', error);
        },
        onSuccess: function (data) {
            const { result, entities } = normalize(justArray(data), [
                attendeeSchema
            ]);
            const { attendees = {}, signatures = {}, uploads = {} } = entities;

            return {
                store: {
                    allIds: result,
                    byIds: attendees
                },
                signatureStore: {
                    byIds: signatures,
                    allIds: Object.keys(signatures).map((id) => Number(id))
                },
                uploads
            };
        }
    })
);
