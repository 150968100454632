import React, { useState } from 'react';
import clsx from 'clsx';
import StdTextField, { ITextfieldProps } from '../../atoms/StdTextField';
import StdSelect, { ISelectProps } from '../../atoms/StdSelect';
import StdDivider from '../../atoms/StdDivider';

interface IProps extends IClass {
    selectProps: Omit<ISelectProps, 'className' | 'width'>;
    textfieldProps: Omit<ITextfieldProps, 'className' | 'width'>;
    width?: string;
}

const AttributeSelector: React.FC<IProps> = ({
    className,
    selectProps,
    textfieldProps,
    width
}) => {
    const [focused, setFocused] = useState(false);
    return (
        <div
            className={clsx(
                'mdc-custom-attr-select',
                focused && 'mdc-custom-attr-select--focused',
                className
            )}
            style={{ width }}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
        >
            <StdSelect
                {...selectProps}
                className="mdc-custom-selector"
                width="100%"
            />
            <StdDivider />
            <StdTextField
                {...textfieldProps}
                className="mdc-custom-selector"
                width="100%"
            />
        </div>
    );
};

export default AttributeSelector;
