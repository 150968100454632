import { takeEvery, all, put } from 'redux-saga/effects';

import {
    DELETE_PROTOCOL_ITEM_REQUEST,
    DELETE_PROTOCOL_ITEM_FAIL
} from '../../actions';
import {
    protoDelItem,
    protoDelItemSuccess,
    protoDelItemFail,
    getProtocolItem
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

type IDeleteItemAction = RT<typeof protoDelItem>;
type IDeleteItemFailAction = RT<typeof protoDelItemFail>;

const requestEffect = simpleRequest<IDeleteItemAction>({
    success: protoDelItemSuccess,
    failure: protoDelItemFail,
    getUrl: (action) => `protocol_item/${action.itemId}/`,
    getEntityId: (action) => action.itemId,
    method: 'DELETE',
    enableOffline: true
});

export default all([
    takeEvery(DELETE_PROTOCOL_ITEM_REQUEST, requestEffect),
    takeEvery(
        DELETE_PROTOCOL_ITEM_FAIL,
        function* (action: IDeleteItemFailAction) {
            if (action.itemId == null) return;
            yield put(getProtocolItem(action.itemId));
        }
    )
]);
