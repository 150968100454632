import { takeEvery, call } from 'redux-saga/effects';
import queryString from 'query-string';
import { normalize } from 'normalizr';

import { propertySchema } from '../../reducers/schema';
import { GET_DPD_REQUEST } from '../../actions';
import { getDpdsSuccess, getDpdsFail, getDpds } from '../../action-creators';
import { justArray } from '../../../utils';

import { simpleRequest, ISuccessCallback } from '../modules/request';
import { fetchFailError } from '../../../modules/errorHandlers';

export interface IGetAllDpdsResult {
    response: IGetManyPaginatedSuccess<IDpd>;
    uploads: IHash<IMedia>;
}

type IGetDpdsAction = RT<typeof getDpds>;

const onSuccess: ISuccessCallback<IGetDpdsAction> = function (
    data: any
): IGetAllDpdsResult {
    const { result, entities } = normalize(justArray(data.results), [
        propertySchema
    ]);
    const { uploads = {}, properties = {} } = entities;

    return {
        response: {
            ...data,
            results: result.map((key: any) => properties[key])
        },
        uploads
    };
};

export default takeEvery(
    GET_DPD_REQUEST,
    simpleRequest<IGetDpdsAction>({
        success: getDpdsSuccess,
        failure: getDpdsFail,
        getUrl: (action) => `dpd/?${queryString.stringify(action.params)}`,
        onFail: function* (error: INetworkError) {
            yield call(fetchFailError, 'fetch_dpds_error', error);
        },
        onSuccess
    })
);
