import { normalize } from 'normalizr';
import { justArray } from '../../../utils';

import {
    attendeeSchema,
    checkoutUploadsSchema,
    issueSchema,
    protocolElementSchema,
    protocolItemSchema,
    protocolRoomSchema,
    protocolSchema
} from '../../reducers/schema';

interface IUploadAction {
    uploads: IHash<IUpload>;
}
interface INormalizeIssueResult extends IUploadAction {
    issue: INormalizedIssue;
}

export function normalizeIssue(data: any): INormalizeIssueResult {
    const { result, entities } = normalize(data, issueSchema);
    const { issues = {}, uploads = {} } = entities;

    const issue = issues[result];
    return {
        issue,
        uploads
    };
}

export function normalizePropertyIssues(data: any) {
    const { result, entities } = normalize(justArray(data), [issueSchema]);
    const { uploads = {}, issues = {} } = entities;

    return {
        store: {
            allIds: result,
            byIds: issues
        },
        uploads
    };
}

interface IIssuesResult extends IUploadAction {
    response: any;
}

export function normalizeIssues(data: any): IIssuesResult {
    const { result, entities } = normalize(justArray(data.results), [
        issueSchema
    ]);
    const { uploads = {}, issues = {} } = entities;

    return {
        response: {
            ...data,
            results: result.map((key: any) => issues[key])
        },
        uploads
    };
}

interface IAttendeeResult extends IUploadAction {
    attendee: IAttendee;
    signatures: IHash<ISignature>;
}

export function normalizeAttendee(data: any): IAttendeeResult {
    const { result, entities } = normalize(data, attendeeSchema);
    const { attendees = {}, uploads = {}, signatures = {} } = entities;

    const attendee = attendees[result];
    return {
        attendee,
        signatures,
        uploads
    };
}

interface IProtocolRoomResult extends IUploadAction {
    entity: IAttendee;
    protocol_elements: IHash<IProtocolElement>;
}

export function normalizeProtocolRoom(data: any): IProtocolRoomResult {
    const { result, entities } = normalize(data, protocolRoomSchema);
    const {
        protocol_rooms = {},
        uploads = {},
        protocol_elements = {}
    } = entities;

    const entity = protocol_rooms[result];
    return {
        entity,
        protocol_elements,
        uploads
    };
}

interface IProtocolElementResult extends IUploadAction {
    entity: IProtocolElement;
}

export function normalizeProtocolElement(data: any): IProtocolElementResult {
    const { result, entities } = normalize(data, protocolElementSchema);
    const { protocol_elements = {}, uploads = {} } = entities;

    const entity = protocol_elements[result];
    return {
        entity,
        uploads
    };
}

interface IProtocolResult extends IUploadAction {
    entity: IProtocolItem;
}

export function normalizeProtocolItem(data: any): IProtocolResult {
    const { result, entities } = normalize(data, protocolItemSchema);
    const { protocol_items = {}, uploads = {} } = entities;

    const entity = protocol_items[result];
    return {
        entity,
        uploads
    };
}

interface IProtocolResult extends IUploadAction {
    entity: IProtocolItem;
}

export function normalizeProtocol(data: any): IProtocolResult {
    const { result, entities } = normalize(data, protocolSchema);
    const { protocols = {}, uploads = {} } = entities;

    const entity = protocols[result];
    return {
        entity,
        uploads
    };
}
export function normalizeCheckoutData(data: any): IProtocolResult {
    const { result, entities } = normalize(data, protocolSchema);
    const { protocols = {}, uploads = {} } = entities;

    const entity = protocols[result];
    const normalizedUploadEntities = normalize(
        (entity as any)?.checkout_data,
        checkoutUploadsSchema
    );
    return {
        entity,
        uploads: normalizedUploadEntities.entities.uploads as any
    };
}
