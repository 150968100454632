import { takeLeading } from 'redux-saga/effects';

import { SORTING_CRUD_REQUEST } from '../../actions';
import {
    sortingCrud,
    sortingCrudSuccess,
    sortingCrudFail
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeLeading(
    SORTING_CRUD_REQUEST,
    simpleRequest<ReturnType<typeof sortingCrud>>({
        success: sortingCrudSuccess,
        failure: sortingCrudFail,
        getUrl: (_) => 'sorting/',
        getBody: (action) => action.payload,
        method: 'POST'
    })
);
