import { takeEvery } from 'redux-saga/effects';

import { GET_ATTENDEE_REQUEST } from '../../actions';
import {
    getAttendee,
    getAttendeeSuccess,
    getAttendeeFail
} from '../../action-creators';

import { simpleRequest } from '../modules/request';
import { normalizeAttendee } from '../modules/normalization';

export default takeEvery(
    GET_ATTENDEE_REQUEST,
    simpleRequest<RT<typeof getAttendee>>({
        success: getAttendeeSuccess,
        failure: getAttendeeFail,
        getUrl: (action) => `attendee/${action.id}/`,
        onSuccess: function (data) {
            return normalizeAttendee(data);
        }
    })
);
