import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import './trial-selected-plan.scss';

interface IProps extends IClass {
    plan: IPackage;
    width?: string;
}

const TrialSelectedPlan: React.FC<IProps> = ({ className, plan, width }) => {
    const { t } = useTranslation();
    const { identifier, base_price, currency } = plan;

    const parsedPrice = `${currency} ${parseFloat(base_price)}`;

    const price = t(`plan.backen.feature.protocol.${identifier}`, {
        price: parseFloat(plan?.features[1]?.included),
        currency: currency
    });
    const protocol = t(`plan.backen.feature.extra.${identifier}`, {
        included: parseFloat(plan?.features[0]?.included)
    });

    const additional = t(`plan.backen.feature.additional.${identifier}`, {
        currency: currency,
        price: parseFloat(plan?.features[1]?.included)
    });

    return (
        <div
            style={{ width }}
            className={clsx('selected-plan__container', className)}
        >
            <div className="selected-plan__header">
                <div className="selected-plan__title stack-m">
                    {t(`plan.backend.name.${identifier}`)}
                </div>
                <div className="selected-plan__subtitle stack-m">
                    {parsedPrice}
                </div>
                <div className="selected-plan__duration">{t('per_month')}</div>
            </div>
            <div className='selected-plan__section__item'>
                <div className="selected-plan__section__title">{protocol}</div>
                <div className="selected-plan__section__title">{price}</div>
            </div>
            <div className='selected-plan__section__item'>
                <div className="selected-plan__section__title">
                    {t(`package.backend.feature.extra.plan.${identifier}`)}
                </div>
                <div className="selected-plan__section__title">
                    {plan.identifier === "STARTER" ? t('plan.upgrade.to.starter') : additional}
                </div>
            </div>
        </div>
    );
};

export default TrialSelectedPlan;
