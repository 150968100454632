import React, { useEffect, useState } from 'react';
import queryString from 'query-string';

import { Redirect, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';

import { ROOT_ROUTE } from '../../configs/routes';
import { dispatchAsync } from '../../store/storeModule';
import {
    checkTeamInviteSet,
    acceptTeamInviteSet,
    showSnackbar
} from '../../store/action-creators';

import useDelayedPending from '../../hooks/useDelayedPending';

import SetPasswordSuccessModal from '../../components/team/SetPasswordSuccessModal';
import SetPasswordModal from '../../components/team/SetPasswordModal';
import Loading from '../../components/atoms/Loading';

import './accept-invite-page.scss';

const AcceptInvitePage: React.FC = () => {
    const dispatch = useDispatch();

    const [tokenValid, setTokenValid] = useState(false);
    const [passwordProvided, setPasswordProvided] = useState(false);

    const { search } = useLocation();
    const { token } = queryString.parse(search);

    const tokenLoading = useDelayedPending([checkTeamInviteSet.request]);

    useEffect(() => {
        if (typeof token === 'string') {
            dispatchAsync(dispatch, checkTeamInviteSet.request(token))
                .then((_) => setTokenValid(true))
                .catch((_) =>
                    dispatch(
                        showSnackbar({ message: 'invite.link_inavlid_message' })
                    )
                );
        }
    }, [dispatch, token]);

    const handleSetPassword = (password: string) => {
        if (typeof token != 'string') return;

        dispatchAsync(dispatch, acceptTeamInviteSet.request(token, password))
            .then((_) => setPasswordProvided(true))
            .catch((_) =>
                dispatch(showSnackbar({ message: 'invite.password_set_error' }))
            );
    };

    if (tokenLoading) {
        return (
            <div className="accept-invite-page">
                <Loading className="loading__page" size="xlarge" />
            </div>
        );
    }

    if (!tokenValid) {
        return <Redirect to={ROOT_ROUTE} />;
    }

    return (
        <div className="accept-invite-page">
            <SetPasswordModal
                open={!passwordProvided}
                onClose={() => {}}
                handleSetPassword={handleSetPassword}
            />
            <SetPasswordSuccessModal
                open={passwordProvided}
                onClose={() => {}}
            />
        </div>
    );
};

export default AcceptInvitePage;
