import { takeLatest } from 'redux-saga/effects';
import { RESET_PASSWORD_CONFIRM_REQUEST } from '../../actions';
import {
    resetPasswordConfirm,
    resetPasswordConfirmSuccess,
    resetPasswordConfirmFail
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeLatest(
    RESET_PASSWORD_CONFIRM_REQUEST,
    simpleRequest<ReturnType<typeof resetPasswordConfirm>>({
        success: resetPasswordConfirmSuccess,
        failure: resetPasswordConfirmFail,
        getUrl: (action) =>
            `auth-users/reset_password_confirm/${action.uid}/${action.token}/?lang=${action.lang}`,
        getBody: (action) => action.payload,
        method: 'POST',
        withAuth: false
    })
);
