import { all } from 'redux-saga/effects';
import loginEffect from './login';
import logoutEffect from './logout';
import getAccEffect from './getCurrentAcc';
import refreshAccessEffect from './refreshToken';
import resetPasswordEffect from './restPassword';
import createAccountEffect from './create';
import resendConfirmEffect from './resendConfirmation';
import resendPassConfirmEffect from './resetPasswordConfirm';
import verifyAccountEffect from './verify';
import verifyTokenEffect from './verifyToken';
import publicCheckEmailEffect from './publicCheckEmail';
import publicCheckActiveEffect from './checkActive';
import setPasswordEffect from './setPassword';
import checkEmailEffect from './checkEmail';

export default all([
    loginEffect,
    logoutEffect,
    getAccEffect,
    refreshAccessEffect,
    resetPasswordEffect,
    createAccountEffect,
    resendConfirmEffect,
    resendPassConfirmEffect,
    verifyAccountEffect,
    verifyTokenEffect,
    publicCheckEmailEffect,
    publicCheckActiveEffect,
    setPasswordEffect,
    checkEmailEffect
]);
