import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import IssueModule from '../../../modules/issue';
import StdChip from '../../atoms/StdChip';
import { MenuItem, MenuSurfaceAnchor } from '@rmwc/menu';
import StdDropdown from '../../atoms/StdDropdown';
import { useSelector } from 'react-redux';


interface IProps {
    issues?: IIssue[];
    showCost?: boolean;
    onIssueClick?: (issue: IIssue) => void;
    onEditIssue?: ICallback;
    onDeleteIssue?: ICallback;
}

const ChipIssueList: React.FC<IProps> = ({
    issues,
    showCost,
    onIssueClick,
    onEditIssue,
    onDeleteIssue
}) => {
    const { t } = useTranslation();

    const [showActions, setShowActions] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const protocolCurrency = useSelector(state => state.protocol.protocol.response?.currency);
    return (
        <Fragment>
            {issues?.map((issue, index) => {
                const hasCost = showCost && IssueModule.hasCosts(issue);
                const isExcessive = IssueModule.isExcessive(issue);

                const cost = IssueModule.displayCosts(issue, protocolCurrency, t);

                // TODO: reduce reliance on css 'util__' classes
                return (
                    <div key={issue.id || issue.piss_id || issue.iss_id}>
                    <StdChip
                        className="inline-s stack-s"
                        type={isExcessive ? 'danger' : 'warn'}
                        onClick={() => {
                            onIssueClick?.(issue);
                            setSelectedIndex(index);
                            onEditIssue &&
                                onDeleteIssue &&
                                setShowActions(true);
                        }}
                        nowrap
                    >
                        <div className="util__chip-wrapper">
                            <span
                                className={clsx(
                                    'util__ellipsis',
                                    hasCost && 'inline-xs',
                                    'util__chip-text'
                                )}
                            >
                                {issue.title || issue.iss_title || '---'}
                            </span>
                            {hasCost && (
                                <>
                                    <span className="inline-xs">-</span>
                                    <span className="util__inflexible">
                                        {cost}
                                    </span>
                                </>
                            )}
                        </div>
                    </StdChip>
                        <MenuSurfaceAnchor style={{ width: '60px' }}>
                            <StdDropdown
                                width="60px"
                                open={selectedIndex === index && showActions}
                                onClose={() => setShowActions(false)}
                                onSelect={(e) => {
                                    const {
                                        detail: { item }
                                    } = e;
                                    const value = item.dataset['value'];
                                    switch (value) {
                                        case 'edit_issue':
                                            onEditIssue?.();
                                            break;
                                        case 'del_issue':
                                            onDeleteIssue?.();
                                            break;
                                    }
                                }}
                            >
                                <MenuItem data-value="edit_issue">
                                    {t('edit')}
                                </MenuItem>
                                <MenuItem data-value="del_issue">
                                    {t('delete')}
                                </MenuItem>
                            </StdDropdown>
                        </MenuSurfaceAnchor>
                    </div>
                );
            })}
        </Fragment>
    );
};

export default ChipIssueList;
