export const GET_TENANT_REQUEST = 'GET_TENANT_REQUEST';
export const GET_TENANT_SUCCESS = 'GET_TENANT_SUCCESS';
export const GET_TENANT_FAIL = 'GET_TENANT_FAIL';

export const POST_TENANT_REQUEST = 'POST_TENANT_REQUEST';
export const POST_TENANT_SUCCESS = 'POST_TENANT_SUCCESS';
export const POST_TENANT_FAIL = 'POST_TENANT_FAIL';

export const PATCH_TENANT_REQUEST = 'PATCH_TENANT_REQUEST';
export const PATCH_TENANT_SUCCESS = 'PATCH_TENANT_SUCCESS';
export const PATCH_TENANT_FAIL = 'PATCH_TENANT_FAIL';

export const DELETE_TENANT_REQUEST = 'DELETE_TENANT_REQUEST';
export const DELETE_TENANT_SUCCESS = 'DELETE_TENANT_SUCCESS';
export const DELETE_TENANT_FAIL = 'DELETE_TENANT_FAIL';
