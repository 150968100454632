import React, {
    useState,
    useEffect,
    useLayoutEffect,
    useCallback,
    useMemo
} from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch, batch } from 'react-redux';
import { useLocation } from 'react-router';

import queryString from 'query-string';

import {
    getCurrentAcc,
    showSnackbar,
    getBilling,
    getUsageLogs,
    updateBilling,
    updateContactAddress
} from '../../store/action-creators';

import { parseStreetAddress } from '../../modules/property';
import { PDF_LOGO_TYPE } from '../../configs/costants';
import { DASHBOARD_ROUTE } from '../../configs/routes';

import { getCommPhone, getFullname } from '../../modules/account';
import { dispatchAsync, safeByIds } from '../../store/storeModule';

import useDelayedPending from '../../hooks/useDelayedPending';

import StdDivider from '../../components/atoms/StdDivider';
import StdIcon from '../../components/atoms/StdIcon';
import Loading from '../../components/atoms/Loading';

import { TabBar, Tab } from '@rmwc/tabs';

import TrialBilling, {
    IBillingForm
} from '../../components/trial/TrialBilling';
import TrialSubscribeFlow from '../../components/trial/TrialSubscribeFlow';
import TrialCancelFeedback from '../../components/trial/TrialCancelFeedback';
import EditPhoneModal from '../../components/inventory/EditPhoneModal';
import EditNameModal from '../../components/inventory/EditNameModal';
import StdOverlay from '../../components/atoms/StdOverlay';

import ChangePasswordModal from '../../components/inventory/ChangePasswordModal';
import ProfileDetialsSection from '../../components/profile/ProfileDetailsSection';
import PlanSection from '../../components/profile/PlanSection';
import CompanyDetailsSection from '../../components/profile/CompanyDetailsSection';
import AdminSection from '../../components/profile/AdminSection';
import ProfileTeamSection from '../../components/team/ProfileTeamSection';

import './profile.scss';
import UpdateBilling from '../../components/profile/UpdateBilling';

function isBillingValid(billing: IBilling | null) {
    return Boolean(
        billing &&
            billing.zip &&
            billing.street &&
            billing.street_number &&
            billing.country
    );
}

function displayRole(role: IUserRole) {
    switch (role) {
        case 'MA':
        case 'MANAGER':
            return 'role.manager';
        case 'TE':
            return 'role.tenant';
        case 'TEAM_MEMBER':
            return 'role.team_member';
        case 'ADMIN':
            return 'role.admin';
        default:
            return 'role.landlord';
    }
}

const UserProfile: React.FC = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { search } = useLocation();
    const { section } = queryString.parse(search);

    const [resetOpen, setResetOpen] = useState(false);
    const [billingOpen, setBillingOpen] = useState(false);
    const [planOpen, setPlanOpen] = useState(false);
    const [nameOpen, setNameOpen] = useState(false);
    const [phoneOpen, setPhoneOpen] = useState(false);
    const [subOpen, setSubOpen] = useState(false);

    const [activeTab, setActiveTab] = useState(0);

    const account = useSelector((state) => state.user.user.response);
    const pageLoading = useDelayedPending([getCurrentAcc, getBilling]);

    const uploadStore = useSelector((state) => state.uploads.uploads);
    const storedUploads = safeByIds(account?.uploads || [], uploadStore.byIds);
    const logo = useMemo(
        () =>
            storedUploads
                .slice()
                .reverse()
                .find((upload) => upload.type === PDF_LOGO_TYPE),
        [storedUploads]
    );

    const currentPlan = account?.subscription?.plan;

    const showAdmin = Boolean(
        (account && account.is_staff) ||
            (currentPlan?.identifier.includes('ENTERPRISE') &&
                account?.role === 'ADMIN')
    );
    const showPlan = Boolean((account?.role === 'PO') || (account?.role === 'ADMIN'));
    const showTeam = Boolean(
        account &&
            ((account.role === 'PO' && account?.permissions?.team?.manage) ||
                (account.role === 'PO' &&
                    currentPlan?.identifier.includes('ENTERPRISE')) ||
                (account.role === 'MANAGER' &&
                    account?.permissions?.team?.manage) ||
                (account.role === 'ADMIN' &&
                    currentPlan?.identifier.includes('ENTERPRISE')))
    );
    const showCompany = Boolean(
        account && (account?.permissions?.company?.manage || (
            (account.role === 'PO' || account.role === 'MA' || account.role === 'ADMIN' || account.role === 'MANAGER' || account.role === 'TEAM_MEMBER')
        ))
    );

    const companyDetailEditPermissions = Boolean(
        account &&
            ((account?.permissions?.company?.manage && !account?.trial?.is_trial) ||
                (account.role === 'PO' || account.role === 'ADMIN' || account.role === 'MANAGER')
            )
    );

    const initVisibleTabs = () => {
        let visibleTabs = ['DETAIL'];
        if (showPlan) visibleTabs.push('PLAN');
        if (showTeam) visibleTabs.push('TEAM');
        if (showCompany) visibleTabs.push('COMPANY');
        if (showAdmin) visibleTabs.push('ADMIN');
        return visibleTabs;
    };

    const billingRes = useSelector((state) => state.user.billing.response);
    const contactRes = useSelector((state) => state.user?.user?.response?.contact_address);
    const currentInvoice = useSelector(
        (state) => state.user.currentInvoice.response
    );

    const billing = isBillingValid(billingRes) ? billingRes : null;
    const contact = isBillingValid(contactRes as IBilling) ? contactRes : null;
    const resetPasswordToggle = () => {
        setResetOpen(!resetOpen);
    };
    useEffect(() => {
        if (section === 'plan') setActiveTab(1);
    }, [section]);

    useLayoutEffect(() => {
        batch(() => {
            dispatch(getBilling());
            dispatch(getUsageLogs());
            dispatch(getCurrentAcc());
        });
    }, [dispatch]);

    const onBillingInit = useCallback(
        (state: IBillingForm) => {
            if (billing && billingOpen) {
                const {
                    city,
                    country,
                    full_name,
                    region,
                    street,
                    street_number,
                    zip
                } = billing;
                return {
                    ...state,
                    form: {
                        ...state.form,
                        name: full_name || '',
                        city: city || '',
                        region: region || '',
                        country: country || '',
                        street: `${street || ''} ${street_number || ''}`.trim(),
                        zip: zip || ''
                    }
                };
            }
            return state;
        },
        [billingOpen, billing]
    );

    const onCompanyBillingInit = useCallback(
        (state: IBillingForm) => {
            if (contact) {
                const {
                    city,
                    country,
                    full_name,
                    region,
                    street,
                    street_number,
                    zip
                } = contact;
                return {
                    ...state,
                    form: {
                        ...state.form,
                        name: full_name || '',
                        city: city || '',
                        region: region || '',
                        country: country || '',
                        street: `${street || ''} ${street_number || ''}`.trim(),
                        zip: zip || ''
                    }
                };
            }
            return state;
        },
        [contact]
    );

    const onBillingSubmit = (input: IBillingForm['form']) => {
        const { street, zip, city, region, name, country } = input;
        const { street_name, street_num } = parseStreetAddress(street);

        const address = {
            full_name: name,
            street: street_name,
            street_number: street_num,
            country,
            zip,
            city,
            region
        };

        dispatchAsync(dispatch, updateBilling(address))
            .then((_) => dispatch(getBilling()))
            .catch(({ error }) =>
                dispatch(
                    showSnackbar({ message: 'on_update_billing_fail', error })
                )
            );
        setBillingOpen(false);
    };
    const onContactAddressSubmit = (input: IBillingForm['form']) => {
        const { street, zip, city, region, name, country } = input;
        const { street_name, street_num } = parseStreetAddress(street);

        const address = {
            full_name: name,
            street: street_name,
            street_number: street_num,
            country,
            zip,
            city,
            region
        };

        dispatchAsync(dispatch, updateContactAddress(address))
            .then((_) => dispatch(getCurrentAcc()))
            .catch(({ error }) =>
                dispatch(
                    showSnackbar({ message: 'on_update_billing_fail', error })
                )
            );
        setBillingOpen(false);
    };
    const { firstname, lastname, email, com } = account || {};
    const phone = getCommPhone(com);
    const username = getFullname(firstname, lastname, '');
    const visibleTabs = initVisibleTabs();
    const showContactAddress = contact || account?.role !== 'TE' ? true : false;
    const userRole = account?.role ? displayRole(account.role) : '';
    const isHidden = (value: string) => {
        if (visibleTabs[activeTab] !== value) {
            return true;
        }
        return false;
    };
    const editedPhoneNumber = () => {
        const reversedArr = [...com!].reverse();
        const latestMatchValue = reversedArr.find(
            (value) => value.com_type === 'MOB'
        )?.com_value;
        return latestMatchValue;
    };
    return (
        <div className="profile__page">
            <div className="header header--large">
                <div className="util__inflexible inline-m profile__title">
                    <Link className="std-blank-link" to={DASHBOARD_ROUTE}>
                        <StdIcon
                            className="inline-s profile__directionArrow"
                            name="arrow-left"
                            small
                        />
                        <span>{t('back')}</span>
                    </Link>
                </div>
                <div className="profile__title">{t('profile')}</div>
                <div className="util__hidden profile__title">
                    <StdIcon className="inline-s" name="arrow-left" />
                    <span>{t('back')}</span>
                </div>
            </div>
            {pageLoading ? (
                <Loading className="loading__page" size="xlarge" />
            ) : (
                <div className="profile__wrapper">
                    <div className="profile__content">
                        <div className="profile__header">
                            <div className="font__title">{t('setting')}</div>
                        </div>
                        <TabBar
                            activeTabIndex={activeTab}
                            onActivate={(e: any) =>
                                setActiveTab(e.target.index)
                            }
                        >
                            <Tab>{t('profile.details_tab').toUpperCase()}</Tab>
                            {showPlan && (
                                <Tab>{t('profile.plan_tab').toUpperCase()}</Tab>
                            )}
                            {showTeam && (
                                <Tab>{t('profile.team_tab').toUpperCase()}</Tab>
                            )}
                            {showCompany && (
                                <Tab>
                                    {t('profile.company_tab').toUpperCase()}
                                </Tab>
                            )}
                            {showAdmin && (
                                <Tab>
                                    {t('profile.admin_tab').toUpperCase()}
                                </Tab>
                            )}
                        </TabBar>
                        <StdDivider />
                        <div hidden={isHidden('DETAIL')}>
                            <ProfileDetialsSection
                                editedName={username}
                                editedPhone={editedPhoneNumber()}
                                email={email}
                                role={t(userRole)}
                                onChangePassword={resetPasswordToggle}
                            />
                        </div>
                        {showPlan && (
                            <div hidden={isHidden('PLAN')}>
                                <PlanSection
                                    account={account}
                                    currentPackage={currentPlan}
                                    currentInvoice={currentInvoice}
                                    billingAddress={billing}
                                    onBillingEdit={() => setBillingOpen(true)}
                                    onPlanChange={() => setPlanOpen(true)}
                                    onPlanCancel={() => setSubOpen(true)}
                                />
                            </div>
                        )}
                        {showTeam && (
                            <div hidden={isHidden('TEAM')}>
                                <ProfileTeamSection
                                    user={account as IUser}
                                    isEnterprise={currentPlan?.identifier.includes(
                                        'ENTERPRISE'
                                    )}
                                    role={t(userRole)}
                                />
                            </div>
                        )}
                        {showCompany && (
                            <div hidden={isHidden('COMPANY')}>
                                <CompanyDetailsSection
                                    user={account as IUser}
                                    plan={currentPlan}
                                    showBillingAddress={showContactAddress}
                                    handleInit={onCompanyBillingInit}
                                    handleSubmit={onContactAddressSubmit}
                                    companyLogo={logo}
                                    editPermission={companyDetailEditPermissions}
                                />
                            </div>
                        )}
                        <div hidden={isHidden('ADMIN')}>
                            {showAdmin && (
                                <AdminSection
                                    isEnterprise={currentPlan?.identifier.includes(
                                        'ENTERPRISE'
                                    )}
                                />
                            )}
                        </div>
                    </div>
                </div>
            )}
            <ChangePasswordModal
                open={resetOpen}
                onClose={() => setResetOpen(false)}
                isHeader
            />
            {/* <StdOverlay open={billingOpen}>
                <TrialBilling
                    edit
                    headerText={t('update_billing')}
                    plan={currentPlan}
                    onBack={() => setBillingOpen(false)}
                    handleInit={onBillingInit}
                    handleSubmit={onBillingSubmit}
                />
            </StdOverlay> */}
            <UpdateBilling
                edit
                headerText={t('update_billing')}
                plan={currentPlan}
                onBack={() => setBillingOpen(false)}
                handleInit={onBillingInit}
                handleSubmit={onBillingSubmit}
                modalOpen={billingOpen}
                onModalClose={() => setBillingOpen(false)}
            />
            <TrialSubscribeFlow
                open={planOpen}
                currentPackage={currentPlan}
                onClose={() => setPlanOpen(false)}
            />
            <EditNameModal
                editedName={username}
                open={nameOpen}
                onClose={() => setNameOpen(false)}
                onSubmit={() => dispatch(getCurrentAcc())}
            />
            <EditPhoneModal
                editedPhone={phone}
                open={phoneOpen}
                onClose={() => setPhoneOpen(false)}
                onSubmit={() => dispatch(getCurrentAcc())}
            />
            <StdOverlay open={subOpen}>
                <TrialCancelFeedback onBack={() => setSubOpen(false)} />
            </StdOverlay>
        </div>
    );
};

export default UserProfile;
