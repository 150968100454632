import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Button as MdcButton } from '@rmwc/button';
import Modal from '../../atoms/StdModal';
import StdButton from '../../atoms/StdButton';

interface IProps extends IModalBase, IClass {
    children?: React.ReactNode;
    confirmLabel?: string; // Default to Yes & No
    cancelLabel?: string;
    onConfirm?: ICallback;
    alignRight?: boolean;
    isNotification?: boolean;
    disabled?: boolean;
    isDestructive?: boolean;
}

const ConfirmModal: React.FC<IProps> = (props) => {
    const { t } = useTranslation();

    const {
        className,
        open,
        children,
        alignRight,
        disabled,
        onClose,
        onConfirm
    } = props;

    return (
        <Modal open={open} onClose={onClose} white>
            <div className="modal__section--square">
                <div className={clsx('stack-xl', className)}>{children}</div>
                <div
                    className={clsx(
                        'modal__actions',
                        alignRight && 'modal__actions--right'
                    )}
                >
                    <div className="modal__section--ButtonGrp">
                        <div>
                            <StdButton
                                className="modal__section--ButtonGrp__selectOptionBtn"
                                type="primary"
                                onClick={onClose}
                            >
                                {t("cancel")}
                            </StdButton>
                        </div>
                        <div>
                            <StdButton
                                className="modal__section--ButtonGrp__selectOptionBtn"
                                onClick={onConfirm}
                            >
                                {t("yes_delete")}
                            </StdButton>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmModal;
