import React from 'react';
import { Redirect } from 'react-router';
import {
    NOT_FOUND_ERROR_ROUTE,
    SERVER_ERROR_ROUTE
} from '../../configs/routes';
import { isNetworkDownError } from '../../modules/error';

import ErrorPageNetwork from './ErrorPageNetwork';

interface IProps {
    error?: IMaybeNetworkError | null;
}

const ErrorRouter: React.FC<IProps> = ({ error }) => {
    if (!error) throw new Error('ErrorRouter: no error provided');
    const { request } = error;
    const { status } = request || {};

    const isDisconnected = error && isNetworkDownError(error); 

    if (isDisconnected) return <ErrorPageNetwork />;

    if (status === 404) {
        return <Redirect to={NOT_FOUND_ERROR_ROUTE} />;
    }
    return <Redirect to={SERVER_ERROR_ROUTE} />;
};

export default ErrorRouter;
