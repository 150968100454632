import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import {
    SUBDPD_TYPE_GENERAL,
    MEDIA_ATTACHMENT_TYPE
} from '../../../configs/costants';
import { safeByIds } from '../../../store/storeModule';
import { justArray } from '../../../utils';
import { partition } from '../../../modules/array';

import {
    propertyAddressToForm,
    propertyToIcon,
    objectToIcon
} from '../../../modules/property';
import {
    DPD_TYPE,
    DPD_ATTR_TYPE,
    SUBDPD_TYPE
} from '../../../configs/basicData';
import useGoogleMap from '../../../hooks/useGoogleMap';

import StdModal from '../../atoms/StdModal';
import StdDivider from '../../atoms/StdDivider';
import StdButton from '../../atoms/StdButton';
import StdIcon from '../../atoms/StdIcon';
import Map from '../../misc/Map';
import AttributeList from '../AttributeList';
import StdMedia from '../../atoms/StdMedia';
import StdLightbox, {
    useLightbox,
    getLightboxUploads
} from '../../atoms/StdLightbox';
import LinkedAttachment from '../../molecules/LinkedAttachment.tsx';
import ViewModalHeader from '../../atoms/ViewModalHeader';

interface IProps extends IModalBase {
    dpd: IDpd;
    mapEnabled?: boolean;
    onEdit?: (param?: IScrollType) => void;
}

const ObjectModal: React.FC<IProps> = ({
    open,
    dpd,
    mapEnabled,
    onClose,
    onEdit
}) => {
    const { t } = useTranslation();

    const { mapLoading, handleLoadMap, handleLocate } = useGoogleMap();
    const { openLightbox, ...lightboxProps } = useLightbox();

    const uploadStore = useSelector((state) => state.uploads.uploads);

    const {
        dpd_street,
        dpd_street_number,
        dpd_type,
        dpd_title,
        dpd_assoc_data,
        dpd_attributes,
        dpd_description,
        uploads
    } = dpd;

    const dpdType = dpd_type || '';
    const isSubdpd = Boolean(dpd_assoc_data);
    const isGeneralRooms = dpdType === SUBDPD_TYPE_GENERAL;

    const storedUploads = safeByIds(uploads as number[], uploadStore.byIds);

    const [attachments, pictures] = partition(
        storedUploads,
        (upload) => upload.type === MEDIA_ATTACHMENT_TYPE
    );
    const [picture] = pictures;

    const lightboxUploads = getLightboxUploads(pictures);

    const lookupHash = useSelector((state) => state.basicData.response);
    const dpdTypes = isSubdpd
        ? lookupHash?.[SUBDPD_TYPE]
        : lookupHash?.[DPD_TYPE];

    const typeIcon = isSubdpd ? objectToIcon(dpdType) : propertyToIcon(dpdType);
    const typeName = dpdTypes?.[dpdType] || dpdType || '//';

    const address = `${dpd_street || '----'}  ${dpd_street_number || '--'}`;

    const title = isSubdpd ? dpd_title : address;
    const subTitle = isSubdpd ? address : '';

    const dpdAttrs = justArray(dpd_attributes);
    const attrData = lookupHash?.[DPD_ATTR_TYPE];

    const generalAttrs = dpdAttrs.map(
        ({ dpa_id, dpa_type = '', dpa_value }) => ({
            id: dpa_id,
            type: attrData?.[dpa_type] || dpa_type || '//',
            value: dpa_value || ''
        })
    );

    useEffect(() => {
        if (dpd && !mapLoading) handleLocate(propertyAddressToForm(dpd));
    }, [dpd, mapLoading, handleLocate]);

    const onMapLoad = handleLoadMap(undefined, true);

    const backUpNode = mapEnabled && <Map onMapLoad={onMapLoad} />;

    return (
        <StdModal open={open} onClose={onClose} white small>
            <ViewModalHeader
                title={t('property_details')}
                onClose={onClose}
                actionNode={
                    <StdButton type="primary" onClick={() => onEdit?.()}>
                        {t('change')}
                    </StdButton>
                }
            />
            <div className="modal__section">
                {picture ? (
                    <div className="property__image stack-m">
                        <StdMedia
                            icon="th-large"
                            media={picture}
                            width={352}
                            height={256}
                            onClick={() =>
                                lightboxUploads.length && openLightbox()
                            }
                            alt="object picture"
                        />
                    </div>
                ) : (
                    backUpNode
                )}
                <div className="font__header stack-l">{title}</div>
                <div className={clsx(subTitle && 'stack-m')}>
                    <StdIcon className="inline-s" name={typeIcon} small grey />
                    <span className="modal__heading">
                        {typeName.toUpperCase()}
                    </span>
                </div>
                {Boolean(subTitle) && (
                    <div className="font--small">{subTitle}</div>
                )}
            </div>
            {Boolean(dpd_description) && (
                <>
                    <StdDivider />
                    <div className="modal__section">
                        <div className="stack-m">
                            <StdIcon
                                className="inline-s"
                                name="sticky-note"
                                small
                                grey
                            />
                            <span className="modal__heading">
                                {t('note').toUpperCase()}
                            </span>
                        </div>
                        <div>{dpd_description}</div>
                    </div>
                </>
            )}
            {!isGeneralRooms && (
                <>
                    <StdDivider />
                    <div className="modal__section">
                        <div className="std-section stack-m">
                            <div>
                                <StdIcon
                                    className="inline-s"
                                    name="list"
                                    small
                                    grey
                                />
                                <span className="modal__heading">
                                    {t('description').toUpperCase()}
                                </span>
                            </div>
                            <StdButton
                                onClick={() => onEdit?.('attr')}
                                size="small"
                            >
                                {t('change')}
                            </StdButton>
                        </div>
                        <AttributeList attrs={generalAttrs} />
                    </div>
                    <StdDivider />
                    <div className="modal__section">
                        <div className="std-section stack-m">
                            <div>
                                <StdIcon
                                    className="inline-s"
                                    name="paperclip"
                                    small
                                    grey
                                />
                                <span className="modal__heading">
                                    {t('attachments').toUpperCase()}
                                </span>
                            </div>
                            <StdButton
                                onClick={() => onEdit?.('attach')}
                                size="small"
                            >
                                {t('change')}
                            </StdButton>
                        </div>
                        {Boolean(attachments.length) ? (
                            attachments.map((attachment, index) => {
                                const isLast = index === attachments.length - 1;
                                return (
                                    <LinkedAttachment
                                        key={attachment.id}
                                        className={clsx(!isLast && 'stack-s')}
                                        path={attachment.path}
                                        name={attachment.name}
                                    />
                                );
                            })
                        ) : (
                            <div className="font--secondary">
                                {t('no_attachments_yet')}
                            </div>
                        )}
                    </div>
                </>
            )}
            <StdLightbox
                {...lightboxProps}
                sources={lightboxUploads.map((prev) => prev.path)}
                types={lightboxUploads.map((prev) => prev.useType)}
            />
        </StdModal>
    );
};

export default ObjectModal;
