import { all } from 'redux-saga/effects';
import delRoom from './delete';
import getRoom from './get';
import patchRoom from './patch';
import postRoom from './post';
import getRoomTemplate from './getTemplate';
import roomAttrCrud from './attributeCrud';
import copyRoom from './copy';

export default all([
    delRoom,
    getRoom,
    patchRoom,
    postRoom,
    getRoomTemplate,
    roomAttrCrud,
    copyRoom
]);
