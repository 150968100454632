import React, { memo } from 'react';
import { Menu, MenuItem, MenuProps } from '@rmwc/menu';

export interface IProps extends MenuProps {
    open: boolean;
    className?: string;
    options?: string[];
    width?: string;
    children?: React.ReactNode;
    onSelect?: (event: ISelectEvent) => void;
    onClose: ICallback;
}

const StdDropdown: React.FC<IProps> = ({
    open,
    className,
    options,
    width = 'auto',
    children,
    onSelect,
    onClose,
    ...otherProps
}) => {
    const optionsList =
        options &&
        options.map((option, index) => (
            <MenuItem key={index} style={{ width }}>
                {option}
            </MenuItem>
        ));
    return (
        <Menu
            {...otherProps}
            className={className}
            open={open}
            onClose={onClose}
            onSelect={onSelect}
            focusOnOpen={false}
            fixed
        >
            {optionsList || children}
        </Menu>
    );
};

export default memo(
    StdDropdown,
    (prevProps, nextProps) => prevProps.open === nextProps.open
);
