import { takeLatest } from 'redux-saga/effects';
import { GET_PACKAGES_REQUEST } from '../../actions';
import {
    getPackages,
    getPackagesSuccess,
    getPackagesFail
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeLatest(
    GET_PACKAGES_REQUEST,
    simpleRequest<RT<typeof getPackages>>({
        success: getPackagesSuccess,
        failure: getPackagesFail,
        getUrl: (_) => 'plans/',
        isV2Api: true
    })
);
