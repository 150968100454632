import { takeLatest, put } from 'redux-saga/effects';

import { VERIFY_ACCOUNT_REQUEST } from '../../actions';
import {
    verifyAccount,
    verifyAccountSuccess,
    verifyAccountFail,
    sessionStart
} from '../../action-creators';

import { simpleRequest } from '../modules/request';

export default takeLatest(
    VERIFY_ACCOUNT_REQUEST,
    simpleRequest<ReturnType<typeof verifyAccount>>({
        success: verifyAccountSuccess,
        failure: verifyAccountFail,
        getUrl: (action) =>
            `auth-users/activation/${action.uid}/${action.token}/`,
        onSuccess: function* (data) {
            const { access, refresh } = data || {};

            yield put(sessionStart({ access, refresh }, { verified: true }));
            return {
                access,
                refresh
            };
        },
        method: 'GET',
        withAuth: false
    })
);
