import { takeEvery } from 'redux-saga/effects';
import queryString from 'query-string';

import { getEnterpriseManagerSet } from '../../action-creators/team';
import { simpleRequest } from '../modules/request';

export default takeEvery(
    getEnterpriseManagerSet.type,
    simpleRequest<RT<typeof getEnterpriseManagerSet.request>>({
        success: getEnterpriseManagerSet.success,
        failure: getEnterpriseManagerSet.fail,
        getUrl: (action) =>
            `company_membership/?${queryString.stringify(action.params)}`,
        isV2Api: true
    })
);
