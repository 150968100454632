import { takeEvery, put, all } from 'redux-saga/effects';

import {
    PATCH_PROTOCOL_ELEMENT_REQUEST,
    PATCH_PROTOCOL_ELEMENT_SUCCESS,
    PATCH_PROTOCOL_ELEMENT_FAIL
} from '../../actions';
import {
    protoPatchEle,
    protoPatchEleSuccess,
    protoPatchEleFail,
    getProtocolElement
} from '../../action-creators';

import { simpleRequest } from '../modules/request';
import { normalizeProtocolElement } from '../modules/normalization';
import { responseHelper } from '../modules/promise';

type IPatchElementAction = RT<typeof protoPatchEle>;
type IPatchElementFailAction = RT<typeof protoPatchEleFail>;

function onSuccessCallback(data: any) {
    return normalizeProtocolElement(data);
}

const requestEffect = simpleRequest<IPatchElementAction>({
    success: protoPatchEleSuccess,
    failure: protoPatchEleFail,
    getUrl: (action) =>
        `protocol_element/${action.protoEleId}/?lang=${action.lang}`,
    getBody: (action) => action.payload,
    getEntityId: (action) => action.protoEleId,
    method: 'PATCH',
    enableOffline: true
});

const responseEffect = responseHelper({
    actionName: PATCH_PROTOCOL_ELEMENT_SUCCESS,
    processFn: onSuccessCallback
});

export default all([
    takeEvery(PATCH_PROTOCOL_ELEMENT_REQUEST, requestEffect),
    takeEvery(
        PATCH_PROTOCOL_ELEMENT_FAIL,
        function* (action: IPatchElementFailAction) {
            if (action.itemId == null) return;
            yield put(getProtocolElement(action.itemId));
        }
    ),
    responseEffect
]);
