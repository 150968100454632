import { takeLatest } from 'redux-saga/effects';

import { normalize } from 'normalizr';

import { GET_SHARED_ISSUE_REQUEST } from '../../actions';
import {
    getSharedIssue,
    getSharedIssueSuccess,
    getSharedIssueFail
} from '../../action-creators';
import { simpleRequest, ISuccessCallback } from '../modules/request';
import { sharedIssueSchema } from '../../reducers/schema';

type IGetSharedIssueAction = RT<typeof getSharedIssue>;
const onSuccess: ISuccessCallback<IGetSharedIssueAction> = function (data) {
    const { result, entities } = normalize(data, sharedIssueSchema);
    const { issues = {}, uploads = {} } = entities;

    const issue = issues[result];
    return {
        issue,
        uploads
    };
};

export default takeLatest(
    GET_SHARED_ISSUE_REQUEST,
    simpleRequest<IGetSharedIssueAction>({
        success: getSharedIssueSuccess,
        failure: getSharedIssueFail,
        getUrl: (action) => `issue/${action.token}/get_issue/`,
        onSuccess,
        withAuth: false
    })
);
