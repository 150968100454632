export const GET_ELEMENT_REQUEST = 'GET_ELEMENT_REQUEST';
export const GET_ELEMENT_SUCCESS = 'GET_ELEMENT_SUCCESS';
export const GET_ELEMENT_FAIL = 'GET_ELEMENT_FAIL';

export const POST_ELEMENT_REQUEST = 'POST_ELEMENT_REQUEST';
export const POST_ELEMENT_SUCCESS = 'POST_ELEMENT_SUCCESS';
export const POST_ELEMENT_FAIL = 'POST_ELEMENT_FAIL';

export const PATCH_ELEMENT_REQUEST = 'PATCH_ELEMENT_REQUEST';
export const PATCH_ELEMENT_SUCCESS = 'PATCH_ELEMENT_SUCCESS';
export const PATCH_ELEMENT_FAIL = 'PATCH_ELEMENT_FAIL';

export const DELETE_ELEMENT_REQUEST = 'DELETE_ELEMENT_REQUEST';
export const DELETE_ELEMENT_SUCCESS = 'DELETE_ELEMENT_SUCCESS';
export const DELETE_ELEMENT_FAIL = 'DELETE_ELEMENT_FAIL';

export const PREDICT_ELEMENTS = 'PREDICT_ELEMENTS';
