import React, { useMemo } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { MEDIA_ATTACHMENT_TYPE } from '../../../configs/costants';
import { EL_ATTRIBUTE_TYPE } from '../../../configs/basicData';

import { backDateToYear } from '../../../utils/formatter';
import { intOrElse, justArray } from '../../../utils';
import { safeByIds } from '../../../store/storeModule';
import { nativeBySet, partition } from '../../../modules/array';
import { levelToFloor } from '../../../modules/rooms';
import { setImageDimentions } from '../../../modules/upload';
import { subtypeToGeneric, lookupType } from '../../../modules/attribute';

import IssueModule from '../../../modules/issue';

import StdModal from '../../atoms/StdModal';
import StdChip from '../../atoms/StdChip';
import StdImage from '../../atoms/StdImage';
import StdDivider from '../../atoms/StdDivider';
import StdIcon from '../../atoms/StdIcon';
import StdButton from '../../atoms/StdButton';
import AttributeList from '../AttributeList';
import LinkedAttachment from '../../molecules/LinkedAttachment.tsx';
import StdLightbox, {
    useLightbox,
    getLightboxUploads
} from '../../atoms/StdLightbox';
import ViewModalHeader from '../../atoms/ViewModalHeader';

import './element-modal.scss';
import ElementIssueHistory from '../ElemenntIssueHistory';
import { useHistory } from 'react-router';
import ObjectEditModal from '../../atoms/ObjectEditModal/ObjectEditModal';

function mapElementAttributes(
    attrs: IElementAttribute[],
    hash: ILookupHash | null
) {
    return subtypeToGeneric(attrs, 'ela_id', 'ela_type', 'ela_value').map(
        (attr) => {
            return {
                ...attr,
                type: lookupType(EL_ATTRIBUTE_TYPE, attr.type, hash),
                value: attr.value || ''
            };
        }
    );
}

interface IProps extends IModalBase {
    room?: IRoom;
    element?: IElement;
    link?: string;
    contentClassName?: string
    onEdit?: (param?: IScrollType) => void;
}

const ElementModal: React.FC<IProps> = ({
                                            open,
                                            room,
                                            element,
                                            link,
                                            onClose,
                                            onEdit
                                        }) => {
    const { t } = useTranslation();

    const { ele_name, ele_ela_id, ele_iss, ele_date_installation, uploads } =
    element || {};
    const { rom_name, rom_level } = room || {};

    const yearOfInstallation = ele_date_installation
        ? backDateToYear(ele_date_installation)
        : '--/--/----';

    const { openLightbox, ...lightboxProps } = useLightbox();

    const lookupHash = useSelector((state) => state.basicData.response);

    const uploadStore = useSelector((state) => state.uploads.uploads);
    const storedUploads = safeByIds(uploads || [], uploadStore.byIds);

    const elementIssues = Array.isArray(ele_iss) ? ele_iss : [];

    const [attachments, pictures] = partition(
        storedUploads,
        (upload) => upload.type === MEDIA_ATTACHMENT_TYPE
    );
    const [picture] = pictures;

    const lightboxUploads = getLightboxUploads(pictures);

    const byFix = nativeBySet(elementIssues, ({ fix }) => {
        if (!fix) return { fix: 0 };
        return { fix: 1 };
    });

    const genericAttributes = useMemo(
        () => mapElementAttributes(justArray(ele_ela_id), lookupHash),
        [ele_ela_id, lookupHash]
    );
    const yearAttribute = {
        id: -1,
        type: t('year_of_installation'),
        value: yearOfInstallation
    };


    // const issueData = byFix.map((item, index) => {
    //     const {
    //         key: { fix },
    //         value
    //     } = item;

    //     const isFixed = Boolean(fix);
    //     const hasNext = index !== byFix.length - 1;
    //     return (
    //         <div key={index}>
    //             <div className="modal__heading stack-m">
    //                 {isFixed
    //                     ? t('resolved_issues').toUpperCase()
    //                     : t('unresolved_issues').toUpperCase()}
    //             </div>
    //             <div className={clsx(hasNext && 'stack-l')}>
    //                 {value.map((issue) => {
    //                     const isExcessive = IssueModule.isExcessive(issue);

    //                     return (
    //                         <StdChip
    //                             className="inline-xs"
    //                             key={issue.piss_id}
    //                             to={
    //                                 link
    //                                     ? `${link}/?issue=${issue.piss_id}`
    //                                     : undefined
    //                             }
    //                             type={isExcessive ? 'danger' : 'warn'}
    //                             nowrap
    //                         >
    //                             <span>{issue.title || '--'}</span>
    //                         </StdChip>
    //                     );
    //                 })}
    //             </div>
    //         </div>
    //     );
    // });

    const history = useHistory();

    return (
        <StdModal open={open} onClose={onClose} small contentClassName={'element_modalBorder'}>
            <ObjectEditModal
                title={t('element_detail')}
                onClose={onClose}
            />
            <div className="modal__section">
                <div className="element-modal__title-section stack-m">
                    <div className="element-modal__title stack-s">
                        {ele_name || '--'}
                    </div>
                    <div>
                        {levelToFloor(intOrElse(rom_level, 1))} /{' '}
                        {rom_name || '///'}
                    </div>
                </div>
                {Boolean(picture) && (
                    <div className="element-modal__image">
                        <StdImage
                            icon="image"
                            src={setImageDimentions(picture.path, 352, 264)}
                            onClick={() =>
                                lightboxUploads.length && openLightbox()
                            }
                        />
                    </div>
                )}
            </div>
            <div className="modal__section">
                <div className="std-section stack-m">
                    <div className="modal__heading">
                        {t('description').toUpperCase()}
                    </div>
                </div>
                <AttributeList attrs={genericAttributes} disableHint />
            </div>
            <div className="modal__section">
                <div className="std-section stack-m">
                    <div>
                        <span className="modal__heading inline-xs">
                            {t('attachments').toUpperCase()}
                        </span>
                        <StdIcon name="paperclip" />
                    </div>
                </div>
                {Boolean(attachments.length) ? (
                    attachments.map((attachment, index) => {
                        const isLast = index === attachments.length - 1;
                        return (
                            <LinkedAttachment
                                key={attachment.id}
                                className={clsx(!isLast && 'stack-s')}
                                name={attachment.name}
                                path={attachment.path}
                            />
                        );
                    })
                ) : (
                    null
                )}
            </div>
            <ElementIssueHistory
                eleId={element?.ele_id as number}
                onViewUnresolvedIssue={(iss) => {
                    history.push(`${link}/?category=unresolved&issue=${iss.iss_id}`);
                }}
                onViewResolvedIssue={(iss) => {
                    history.push(`${link}/?category=resolved&issue=${iss.iss_id}`);
                }}
                showUnresolved
                viewResolvedIssue
            />
            <div className="modal__section modal__section--addProperty modal__section--btnSpace">
                <div className='modal__section--saveBtn'>
                    <StdButton
                        type="primary"
                        onClick={() => onEdit?.()}
                    >
                        {t("edit")}
                    </StdButton>
                </div>
            </div>
         
            <StdLightbox
                {...lightboxProps}
                sources={lightboxUploads.map((preview) => preview.path)}
                types={lightboxUploads.map((preview) => preview.useType)}
            />
        </StdModal>
    );
};

export default ElementModal;
