import React from 'react';
import clsx from 'clsx';

import { setImageDimentions } from '../../modules/upload';
import { IconPrefix, IconName } from '@fortawesome/fontawesome-common-types';

import StdImage from './StdImage';
import CustomVideo from './CustomVideo';
import StdIcon from './StdIcon';

interface IProps extends IClass {
    media?: IUpload;
    iconPrefix?: IconPrefix;
    icon?: IconName;
    alt?: string;
    placeholder?: string;
    checkExif?: boolean;
    width?: number;
    height?: number;
    onClick?: ICallback;
}

const StdMedia: React.FC<IProps> = ({
    className,
    media,
    icon,
    iconPrefix,
    alt,
    placeholder,
    checkExif,
    width,
    height,
    onClick
}) => {
    const { useType, path, thumbnail, media: uploadedMedia } = media || {};
    const { med_path = '' } = uploadedMedia || ({} as Partial<IMedia>);
    const isVideo = useType === 'video';

    const url = thumbnail || path || med_path || placeholder || '';
    const imageNode = (
        <StdImage
            className={className}
            src={setImageDimentions(url, width || 0, height || 0)}
            icon={icon}
            iconPrefix={iconPrefix}
            alt={alt}
            onClick={onClick}
            checkExif={checkExif}
        />
    );
    const videoPlayerNode = (
        <CustomVideo
            className="std-video__content"
            src={path || ''}
            height={height}
            width={width}
            onClick={onClick}
        />
    );

    const videoPreviewNode = (
        <div className={clsx('std-video__container', className)}>
            {thumbnail ? imageNode : videoPlayerNode}
            <div className="gallery__play-icon">
                <StdIcon prefix="fas" name="play" />
            </div>
        </div>
    );

    return isVideo ? videoPreviewNode : imageNode;
};

export default StdMedia;
