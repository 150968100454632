import React, { useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { MEDIA_DOCUMENT_TYPE } from '../../../configs/costants';
import { isoDateTimeToDateAlt } from '../../../utils/formatter';

import { partition } from '../../../modules/array';
import { safeByIds } from '../../../store/storeModule';
import { getPropertyLink } from '../../../modules/property';

import IssueModule from '../../../modules/issue';

import { MenuSurfaceAnchor, MenuItem } from '@rmwc/menu';

import StdModal from '../../atoms/StdModal';
import IssueProgress from '../IssueProgress';
import StdMedia from '../../atoms/StdMedia';
import StdDropdown from '../../atoms/StdDropdown';
import StdIcon from '../../atoms/StdIcon';
import StdLightbox, {
    useLightbox,
    getLightboxUploads
} from '../../atoms/StdLightbox';
import ViewModalHeader from '../../atoms/ViewModalHeader';
import StdLink from '../../atoms/StdLink';
import ElementIssueHistory from '../../inventory/ElemenntIssueHistory';

function getElementLink(
    propertyLink: string,
    roomId: number,
    elementId: number
) {
    return `${propertyLink}?room=${roomId}&element=${elementId}`;
}

function getRoomLink(propertyLink: string, roomId: number) {
    return `${propertyLink}?room=${roomId}`;
}

interface IProps extends IModalBase {
    issue?: IIssue;
    inProtocol?: boolean;
    editable?: boolean;
    sharingDisabled?: boolean;
    onShare?: ICallback;
    onFix?: ICallback;
    onFetch?: ICallback;
    onEdit?: ICallback;
    onDelete?: ICallback;
}

const IssueModal: React.FC<IProps> = ({
    issue,
    open,
    inProtocol,
    editable,
    sharingDisabled,
    onClose,
    onShare,
    onFix,
    onFetch,
    onEdit,
    onDelete
}) => {
    const { t } = useTranslation();

    const [changedUploads, setChangedUploads] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const [optionsOpen, setOptionsOpen] = useState(false);

    const uploadStore = useSelector((state) => state.uploads.uploads);

    const { openLightbox, ...lightboxProps } = useLightbox();

    const viewedIssue: Partial<IIssue> = issue || {};
    const { iss_title, iss_date_occured, iss_description, iss_fix, uploads: mediaUploads, media_med_path } =
        viewedIssue;
        const uploads = mediaUploads?.length ? mediaUploads : media_med_path?.map(media => media?.med_id) || []
    const element = issue?.piss_ele_id || issue?.iss_ele_id;
    const room = issue?.piss_rom_id || issue?.iss_rom_id;
    const object = issue?.piss_dpd_id || issue?.iss_dpd_id;
    const item = issue?.piss_itm_id || issue?.iss_itm_id;

    const locationTitle = element?.ele_name || item?.itm_name;

    const storedUploads = safeByIds(uploads as number[], uploadStore.byIds);
    const [documents, mediaItems] = partition(
        storedUploads,
        (upload) => upload.type === MEDIA_DOCUMENT_TYPE
    );

    const lightboxUploads = getLightboxUploads(mediaItems);
    const lightboxOffest = mediaItems.length - lightboxUploads.length;

    const lookupHash = useSelector((state) => state.basicData.response);
    const issGrade = IssueModule.displayGrade(viewedIssue, lookupHash);

    const dpdLink =
        !inProtocol && object
            ? getPropertyLink(object.dpd_id, object.dpd_assoc_data?.dpd_id)
            : undefined;
    const roomLink =
        dpdLink && room ? getRoomLink(dpdLink, room.rom_id) : undefined;
    const elementLink =
        dpdLink && room && element
            ? getElementLink(dpdLink, room.rom_id, element.ele_id)
            : undefined;

    const excessive = IssueModule.isExcessive(viewedIssue);

    const onLightboxOpen = (index: number) => {
        if (lightboxUploads.length) openLightbox(index - lightboxOffest);
    };

    const cleanup = () => {
        setShowMore(false);
        if (changedUploads) {
            onFetch?.();
            setChangedUploads(false);
        }
    };

    return (
        <StdModal open={open} onClose={onClose} onExited={cleanup}>
            <ViewModalHeader
                title={
                    <span
                        className={clsx(
                            'iss-modal__grade',
                            excessive && 'iss-modal__grade--danger'
                        )}
                    >
                        {excessive ? t('excessive_issue') : t('normal_issue')}
                    </span>
                }
                onClose={onClose}
                actionNode={
                    <MenuSurfaceAnchor>
                        <StdDropdown
                            open={optionsOpen}
                            onClose={() => setOptionsOpen(false)}
                            onSelect={(e) => {
                                const {
                                    detail: { item }
                                } = e;
                                const value = item.dataset['value'];
                                switch (value) {
                                    case 'edit_issue':
                                        onEdit?.();
                                        break;
                                    case 'delete_issue':
                                        onDelete?.();
                                        break;
                                }
                            }}
                        >
                            {!iss_fix && (
                                <MenuItem data-value="edit_issue">
                                    {t('edit_issue')}
                                </MenuItem>
                            )}
                            <MenuItem data-value="delete_issue">
                                {t('delete_issue')}
                            </MenuItem>
                        </StdDropdown>
                        <StdIcon
                            className={clsx(!editable && 'util__hidden')}
                            onClick={() => setOptionsOpen(true)}
                            prefix="far"
                            name="ellipsis-v"
                            clickable
                            small
                        />
                    </MenuSurfaceAnchor>
                }
            />
            <div className="stack-s" />
            <div className="modal__section">
                <div className="iss-modal__title stack-l">
                    <span className="font__header">
                        {iss_title || t('no_title')}
                    </span>
                </div>
                {issue && !inProtocol && (
                    <IssueProgress
                        documents={documents}
                        showMoreLogs={showMore}
                        issue={issue}
                        onShare={onShare}
                        onFix={onFix}
                        onShowClick={() => setShowMore(true)}
                        onModifiedDocuments={() => setChangedUploads(true)}
                        sharingDisabled={sharingDisabled}
                        sharingHidden={!element}
                    />
                )}
            </div>
            <div className="modal__section">
                <div className="modal__heading stack-m">
                    {t('description').toUpperCase()}
                </div>
                {Boolean(mediaItems.length) && (
                    <div className="iss-modal__media stack-l">
                        {mediaItems.map((upload, index) => {
                            return (
                                <div
                                    key={upload.id}
                                    className="iss-modal__media-item inline-s"
                                    onClick={() => onLightboxOpen(index)}
                                >
                                    <StdMedia
                                        media={upload}
                                        width={150}
                                        height={112}
                                        icon="image"
                                    />
                                </div>
                            );
                        })}
                    </div>
                )}
                <div>
                    <div className="stack-l">
                        <span className="iss-modal__desc-title inline-s">
                            {t('grade')}
                        </span>
                        <span className="iss-modal__desc-value">
                            {issGrade}
                        </span>
                    </div>
                    <div className="stack-l">
                        <div className="iss-modal__location stack-m">
                            {t('place')}
                        </div>
                        <div className="iss-modal__breadcrumbs">
                            {object && (
                                <span className="iss-modal__breadcrumb">
                                    <StdLink
                                        className="inline-s"
                                        to={dpdLink}
                                        nowrap
                                    >
                                        {object.dpd_street || '----'}{' '}
                                        {object.dpd_street_number || '--'}
                                    </StdLink>
                                    <span className="font--secondary inline-s">
                                        /
                                    </span>
                                </span>
                            )}
                            {room && (
                                <span className="iss-modal__breadcrumb">
                                    <StdLink
                                        className="inline-s"
                                        to={roomLink}
                                        nowrap
                                    >
                                        {room.rom_name || '--'}
                                    </StdLink>
                                    <span className="font--secondary inline-s">
                                        /
                                    </span>
                                </span>
                            )}
                            <span className="iss-modal__breadcrumb">
                                <StdLink to={elementLink} nowrap>
                                    {locationTitle || '---'}
                                </StdLink>
                            </span>
                        </div>
                    </div>
                    <div className="stack-l">
                        <span className="iss-modal__desc-title inline-s">
                            {t('reported_on')}
                        </span>
                        <span className="iss-modal__desc-value">
                            {iss_date_occured
                                ? isoDateTimeToDateAlt(iss_date_occured)
                                : '--/--/----'}
                        </span>
                    </div>
                    <div>
                        <span className="iss-modal__desc-title inline-s">
                            {t('description')}
                        </span>
                        <span className="iss-modal__desc-value">
                            {iss_description || '----'}
                        </span>
                    </div>
                </div>
            </div>
            <ElementIssueHistory
                eleId={issue?.piss_ele_id?.ele_id as number}
            />
            <StdLightbox
                {...lightboxProps}
                sources={lightboxUploads.map((upload) => upload.path)}
                types={lightboxUploads.map((upload) => upload.useType)}
            />
        </StdModal>
    );
};

export default memo(IssueModal, (prevProps, nextProps) => {
    return (
        prevProps.open === nextProps.open &&
        prevProps.issue === nextProps.issue &&
        prevProps.sharingDisabled === nextProps.sharingDisabled
    );
});
