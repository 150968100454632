import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import clsx from 'clsx';

import {
    TENANT_TYPE_LIVING,
    TENANT_TYPE_MOVED_OUT,
    TENANT_TYPE_MOVING_IN
} from '../../../configs/costants';
import { PROTOCOL_ROUTE } from '../../../configs/routes';

import {
    deleteTenant,
    showSnackbar,
    getTenant,
    postProtocol
} from '../../../store/action-creators';
import { getFullname } from '../../../modules/account';

import { backDateToFrontAlt } from '../../../utils/formatter';
import { dispatchAsync, safeByIds } from '../../../store/storeModule';
import { matchSimple } from '../../../utils';
import { setImageDimentions } from '../../../modules/upload';

import { handleStartProtocol } from '../../../utils/requests';

import usePending from '../../../hooks/usePending';

import StdButton from '../../atoms/StdButton';
import StdIcon from '../../atoms/StdIcon';
import ConfirmDeletionModal from '../../molecules/ConfirmDeletionModal';
import EditTenantModal from '../EditTenantModal';
import StdImage from '../../atoms/StdImage';
import StdDivider from '../../atoms/StdDivider';

import './tenant-view.scss';

interface IProps {
    dpdId: number;
    tenant?: ITenant;
    onTenantsLoaded?: ICallback;
    onDelete?: ICallback;
    canStartProtocol: boolean;
    width?: string;
}

const TenantView: React.FC<IProps> = ({
    dpdId,
    canStartProtocol,
    tenant,
    width,
    onTenantsLoaded,
    onDelete
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();

    const uploadStore = useSelector((state) => state.uploads.uploads);
    const offlineProtocolSessionId = useSelector(
        (state) => state.global.offlineProtocolSessionId
    );

    const [editOpen, setEditOpen] = useState(false);
    const [delOpen, setDelOpen] = useState(false);

    const {
        id,
        contact_details,
        protocols,
        to_date,
        from_date,
        type,
        uploads
    } = tenant || {};
    const { first_name, last_name, email, phone } = contact_details || {};
    const { prt_move_in, prt_move_out } = protocols || {};

    const isMovingIn = type === TENANT_TYPE_MOVING_IN;
    const isLiving = type === TENANT_TYPE_LIVING;
    const isArchived = type === TENANT_TYPE_MOVED_OUT;

    const storedUploads = safeByIds(uploads || [], uploadStore.byIds);

    const name = getFullname(first_name, last_name);
    const [picture] = storedUploads;

    const tenantsLoading = usePending([getTenant]);
    const protocolLoading = usePending([postProtocol]);

    useEffect(() => {
        if (!tenantsLoading) onTenantsLoaded?.();
    }, [id, tenantsLoading, onTenantsLoaded]);

    const handleRefetch = () => {
        dispatch(getTenant(dpdId));
    };

    const handleDelete = () => {
        if (id == null) return;

        dispatchAsync(dispatch, deleteTenant(id))
            .then((_) => {
                handleRefetch();
                history.push({ search: 'tab=tenants' });

                onDelete?.();
            })
            .catch(({ error }) =>
                dispatch(
                    showSnackbar({ message: 'delete_tenant_error', error })
                )
            );
        setDelOpen(false);
    };

    const onProtocolStart = () => {
        if (canStartProtocol) handleStartProtocol({ dispatch, dpdId, history, offlineProtocolSessionId });
    };

    const moveInLabel =
        !isMovingIn && from_date
            ? `${t('moved_in_on')} ${backDateToFrontAlt(from_date)}`
            : t('move_in');
    const moveOutLabel =
        isArchived && to_date
            ? `${t('moved_out_on')} ${backDateToFrontAlt(to_date)}`
            : t('move_out');

    const moveInButtonNode = matchSimple()
        .on(
            canStartProtocol && isMovingIn,
            <StdButton
                className="util__center"
                type="primary"
                onClick={onProtocolStart}
                disabled={protocolLoading}
            >
                {t('add_protocol')}
            </StdButton>
        )
        .on(
            Boolean(prt_move_in),
            <StdButton
                className="util__center"
                type="secondary"
                to={PROTOCOL_ROUTE(prt_move_in?.prt_id as number)}
                disabled={protocolLoading}
            >
                {t('see_protocol')}
            </StdButton>
        )
        .otherwise(<span>----</span>);

    const moveOutButtonNode = matchSimple()
        .on(
            canStartProtocol && isLiving,
            <StdButton
                className="util__center"
                type="primary"
                onClick={onProtocolStart}
                disabled={protocolLoading}
            >
                {t('add_protocol')}
            </StdButton>
        )
        .on(
            Boolean(prt_move_out),
            <StdButton
                className="util__center"
                type="secondary"
                to={PROTOCOL_ROUTE(prt_move_out?.prt_id || NaN)}
                disabled={protocolLoading}
            >
                {t('see_protocol')}
            </StdButton>
        )
        .otherwise(<span>----</span>);

    return (
        <div style={{ width }} className="tenant-view__container">
            <div className="tenant-view__header">
                <span className="font__header inline-s">{name}</span>
                <div className="util__flex">
                    <StdButton
                        className="inline-s"
                        onClick={() => setEditOpen(true)}
                    >
                        {t('edit')}
                    </StdButton>
                    <StdButton
                        type="round"
                        onClick={() => setDelOpen(true)}
                        leadingIcon={{ name: 'trash' }}
                    />
                </div>
            </div>
            <StdDivider />
            <div className="tenant-view__section tenant-view__section--photo">
                <div className="tenant-view__photo">
                    {picture ? (
                        <StdImage
                            src={setImageDimentions(picture.path, 128, 128)}
                            icon="user"
                        />
                    ) : (
                        <StdButton
                            leadingIcon={{ name: 'plus' }}
                            onClick={() => setEditOpen(true)}
                        >
                            {t('photo')}
                        </StdButton>
                    )}
                </div>
            </div>
            <StdDivider />
            <div className="tenant-view__section">
                <div className="modal__heading stack-l">
                    {t('tenant_details').toUpperCase()}
                </div>
                <div className="std-section stack-m">
                    <div>
                        <StdIcon className="inline-s" name="sign-in" grey />
                        <span
                            className={clsx(
                                'font--small',
                                !from_date && 'font--secondary'
                            )}
                        >
                            {moveInLabel}
                        </span>
                    </div>
                    {moveInButtonNode}
                </div>
                <div className="std-section">
                    <div>
                        <StdIcon className="inline-s" name="sign-out" grey />
                        <span
                            className={clsx(
                                'font--small',
                                !to_date && 'font--secondary'
                            )}
                        >
                            {moveOutLabel}
                        </span>
                    </div>
                    {moveOutButtonNode}
                </div>
            </div>
            <StdDivider />
            <div className="tenant-view__section">
                <div className="modal__heading stack-l">
                    {t('contact_detail').toUpperCase()}
                </div>
                <div className="stack-m">
                    <StdIcon className="inline-s" name="phone" grey />
                    <span className="font--small">
                        {phone || t('no_phone')}
                    </span>
                </div>
                <div>
                    <StdIcon className="inline-s" name="envelope" grey />
                    <span className="font--small">
                        {email || t('no_email')}
                    </span>
                </div>
            </div>
            <ConfirmDeletionModal
                message={t('delete_from_tenants', { name })}
                open={delOpen}
                onClose={() => setDelOpen(false)}
                onConfirm={handleDelete}
            />
            <EditTenantModal
                dpdId={dpdId}
                tenant={tenant}
                open={editOpen}
                onClose={() => setEditOpen(false)}
                onSubmit={handleRefetch}
            />
        </div>
    );
};

export default TenantView;
