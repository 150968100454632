import { useEffect, useRef, useState } from 'react';

const useWaitTimeout = (isWaiting: boolean, delay: number) => {
    const timeoutRef = useRef<number>();
    const [active, setActive] = useState(false);

    useEffect(() => {
        if (isWaiting) {
            setActive(true);
        } else {
            timeoutRef.current = window.setTimeout(() => {
                setActive(false);
            }, delay);
        }

        return () => {
            if (timeoutRef.current != null)
                window.clearTimeout(timeoutRef.current);
        };
    }, [isWaiting, delay]);

    return active;
};

export default useWaitTimeout;
