import { justArray } from '../utils';

export function parseUnit(unit: IFeatureUnit) {
    return unit !== 'COUNT' ? unit : '';
}

export function getFeatureDetails(featureName: IFeatureName, plan?: IPackage) {
    const planFeature = justArray(plan?.features).find(
        (planFeature) => planFeature.feature === featureName
    );

    const included = planFeature?.included
        ? parseFloat(planFeature?.included)
        : undefined;
    const price = planFeature?.per_extra;
    const allowed = planFeature ? Boolean(planFeature.allowed) : true;

    const parsedPrice = price
        ? `${parseFloat(price)}${plan?.currency || ''}`
        : undefined;

    return {
        included: included,
        price: parsedPrice,
        allowed: allowed,
        isLimited: planFeature && isLimited(planFeature)
    };
}

export function parsePrice(price: string | number, currency: string) {
    const parsedPrice = typeof price === 'string' ? parseFloat(price) : price;
    return parsedPrice != null ? `${price}${currency}` : '';
}

export function parseFeaturePartial(feature: IFeature) {
    const unitTitle = parseUnit(feature.unit);
    const includedTitle = `${parseFloat(feature.included)} ${unitTitle}`.trim();

    return {
        includedTitle,
        unitTitle
    };
}

export function parseFeature(feature: IFeature, currency: string) {
    const { unitTitle, includedTitle } = parseFeaturePartial(feature);
    const priceTitle = `${parseFloat(feature.per_extra)}${currency}`;

    return {
        includedTitle,
        priceTitle,
        unitTitle
    };
}

export function getExtraFeatures(features: IFeature[]) {
    return features.filter((feature) => isLimited(feature));
}

export function isLimited(feature: IFeature) {
    return feature.allowed && !feature.unlimited;
}
