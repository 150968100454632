import React, { ReactNode } from 'react';
import { select, put } from 'redux-saga/effects';

import { t } from '../i18next';
import { showSnackbar } from '../store/action-creators/appState';
import { matchSimple } from '../utils';

import { isNetworkDownError } from './error';

// Note: should be component
export const composeErrorMessage = (
    description: string,
    error: IMaybeNetworkError
): React.ReactNode => {
    const { response } = error;
    const { status } = response || {};
    const isDisconnected = isNetworkDownError(error);
    const isServerError = status === 400 || status === 500;
    const isServerDown = status === 502;
    const isServerTimeout = status === 504;
    const isNotFound = status === 404;

    const messageNode: ReactNode = matchSimple()
        .on(
            isDisconnected,
            <div>
                <div>{t(description)}</div>
                <div>{t('offline.unavailable_offline')}</div>
            </div>
        )
        .on(
            isServerError,
            <div>
                <div className="inline-m">
                    <div>{t(description)}</div>
                    <div className="stack-s">{t('server_error_message')}</div>
                    <div>{t('please_report')}</div>
                </div>
            </div>
        )
        .on(
            isServerTimeout || isServerDown,
            <div>
                <div>{t(description)}</div>
                <div>{t('server_timeout_message')}</div>
            </div>
        )
        .on(
            isNotFound,
            <div>
                <div>{t(description)}</div>
                <div>{t('not_found_error_message')}</div>
            </div>
        )
        .otherwise(<div>{t(description)}</div>);
    return messageNode;
};

export function* fetchFailError(messageTitle: string, error: INetworkError) {
    const isOnline: boolean = yield select(
        (state: IRootState) =>
            !state.global.isOffline && !state.appState.isManualOffline
    );

    const errorNode = composeErrorMessage(messageTitle, error);
    if (isOnline && errorNode) yield put(showSnackbar({ message: errorNode }));
}
