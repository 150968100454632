import { takeLatest } from 'redux-saga/effects';
import { normalize } from 'normalizr';

import { CHECK_OFFLINE_PDF_READY_REQUEST } from '../../actions';
import {
    checkOfflinePdfReady,
    checkOfflinePdfReadySuccess,
    checkOfflinePdfReadyFail
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

import { mediaToUpload } from '../../../modules/upload';
import { uploadSchema } from '../../reducers/schema';

export default takeLatest(
    CHECK_OFFLINE_PDF_READY_REQUEST,
    simpleRequest<RT<typeof checkOfflinePdfReady>>({
        success: checkOfflinePdfReadySuccess,
        failure: checkOfflinePdfReadyFail,
        getUrl: (action) => `protocol/${action.protoId}/check_pdf_object/`,
        onSuccess: function (media) {
            const { result, entities } = normalize(
                mediaToUpload(media),
                uploadSchema
            );
            const { uploads = {} } = entities;

            return {
                result,
                uploads
            };
        }
    })
);
