import { takeEvery } from 'redux-saga/effects';

import { SAVE_PROTOCOL_ADDITIONAL_NOTES_REQUEST } from '../../actions';
import {
    saveProtocolNotes,
    saveProtocolNotesSuccess,
    saveProtocolNotesFail
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeEvery(
    SAVE_PROTOCOL_ADDITIONAL_NOTES_REQUEST,
    simpleRequest<RT<typeof saveProtocolNotes>>({
        success: saveProtocolNotesSuccess,
        failure: saveProtocolNotesFail,
        getUrl: (action) => `protocol/${action.protoId}/`,
        getBody: (action) => action.proto,
        method: 'PATCH',
        enableOffline: true
    })
);
