import { FFmpeg } from '@ffmpeg/ffmpeg';
import { base64ToImageData, fileToBase64 } from '../modules/file';

const compressUploader = (
    useBeta = false,
    isInsideProtocol = false,
    allowFFmpegCompression = false
) => {
    const ffmpeg = new FFmpeg();

    const loadFfmpeg = async () => {
        await ffmpeg.load();
    };

    const compressAndUpload = async (file: any) => {
        if (!useBeta || !isInsideProtocol) {
            console.log('will not Compress and Upload ---> ', { file });
            return file;
        }

        try {
            if (allowFFmpegCompression) {
                console.log('starting Compress and Upload ---> ', { file });
                await loadFfmpeg();

                // const canvasCompressedImage = await compressFileOnCanvas(file);
                const compressedFile = await compressFileOnFFmpeg(file);
                console.log(
                    'FILE READY ----- ',
                    compressedFile
                    // canvasCompressedImage
                );
                return compressedFile;
            } else {
                return file;
                // do canvas compression (image only for now)
                // const canvasCompressedImage = await compressFileOnCanvas(file);
                // console.log('canvas FILE READY ----- ', canvasCompressedImage);
                // return canvasCompressedImage;
            }
        } catch (error) {
            console.error('Error uploading file:', error.message);
            return file;
        }
    };

    const compressFileOnFFmpeg = async (file: any) => {
        const { name, type } = file;
        const data = new Uint8Array(await file.arrayBuffer());

        await ffmpeg.writeFile(name, data);

        let outputFileName;
        let compressionCommand;

        if (type.startsWith('video/')) {
            console.log('running compression for VIDEO');
            // Compression logic for videos
            outputFileName = 'output.mp4';
            compressionCommand = [
                '-i',
                name,
                '-c:v',
                'libx264',
                '-crf',
                '36',
                '-c:a',
                'aac',
                '-b:a',
                '128k',
                outputFileName
            ];
        } else if (type.startsWith('image/')) {
            console.log('running compression for IMAGE');
            // Compression logic for images
            outputFileName = 'output.jpg';
            compressionCommand = ['-i', name, '-q:v', '23', outputFileName];
        } else {
            throw new Error('Unsupported file type');
        }

        try {
            await ffmpeg.exec(compressionCommand);

            const result = await ffmpeg.readFile(outputFileName);

            const compressedFile = new File([result], name, { type });

            return compressedFile;
        } catch (e) {
            console.error('MediaCompressor Failed :', e);
            return file;
        }
    };

    const compressFileOnCanvas = async (file: any) => {
        const { name, type } = file;

        if (type.startsWith('video/')) {
            console.log('running canvas compression for VIDEO');
            return file; // temporarily not working with videos in Canvas compression
        } else if (type.startsWith('image/')) {
            console.log('running canvas compression for IMAGE');
            // Compression logic for images
            const canvasCompressedImage = await generateCanvasImage(file);
            console.log('this is the canvas compressed image --> ', {
                canvasCompressedImage,
                file
            });
            return canvasCompressedImage;
        } else {
            throw new Error('Unsupported file type');
        }
    };

    return { compressAndUpload };
};

const generateCanvasImage = async (file: File) => {
    try {
        const imageBase64 = await fileToBase64(file);
        // let canvasImageBase64 = '';
        const canvasImageBase64 = base64ToImageData(imageBase64);

        console.log('returning canvasImageBase64 ---> ', { canvasImageBase64 });
        return canvasImageBase64;
    } catch (e) {
        console.error(e);
    }
};

export default compressUploader;
