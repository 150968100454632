import { debounce } from 'redux-saga/effects';

import { CHECK_EMAIL_REQUEST } from '../../actions';
import {
    checkEmail,
    checkEmailSuccess,
    checkEmailFail
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default debounce(
    2000,
    CHECK_EMAIL_REQUEST,
    simpleRequest<RT<typeof checkEmail>>({
        success: checkEmailSuccess,
        failure: checkEmailFail,
        getUrl: (_) => 'user/check_email/',
        getBody: (action) => action.payload,
        method: 'POST',
        ignoreError: true,
        isV2Api: true,
        noBlock: true
    })
);
