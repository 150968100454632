import React from 'react';
import StdButton from '../../atoms/StdButton';
import Modal from '../../atoms/StdModal';

import { 
    useSelector, 
    useDispatch 
} from 'react-redux';
import './user-offline-notification-modal.scss';
import { isServiceWorkerRunning } from '../../../utils';
import { handleOnlineSwitch, handleOfflineSwitch } from '../../../modules/serviceWorker';
import { hideUserOfflineNotification } from '../../../store/action-creators';
import { useTranslation } from 'react-i18next';

const UserOfflineNotificationModal = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const showUserOfflineNotification = useSelector(state => state.global.showUserOfflineNotification)
    const useBeta = useSelector(state => state.user.user.response?.use_beta)

    return (
        <Modal
            open={isServiceWorkerRunning() && !useBeta && showUserOfflineNotification}
            onClose={() => {}}
            white
            contentClassName="user-offline-notification-modal-content"
        >
            <>
                <div className="modal__section--square user-offline-notification-modal">
                    <div className="modal-info modal-info__1">
                        {t('user_offline_notification_modal.title')}
                    </div>
                    <div className="modal-info modal-info__2">
                        {t('user_offline_notification_modal.content')}
                    </div>
                </div>
                <div className="modal__actions user-offline-notification-modal-actions">
                    <StdButton
                        className="user-offline-notification__discard"
                        onClick={() => handleOnlineSwitch(dispatch)}
                    >
                        {t('user_offline_notification_modal.reconnect')}
                    </StdButton>
                    <StdButton
                        type="primary"
                        className="user-offline-notification__retry"
                        onClick={() => {
                            handleOfflineSwitch(dispatch);
                            dispatch(hideUserOfflineNotification());
                        }}
                    >
                        {t(`user_offline_notification_modal.go_offline`)}
                    </StdButton>
                </div>
            </>
        </Modal>
    );
};

export default UserOfflineNotificationModal;
