import React, { useState, useCallback, useRef, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getProtocols } from '../../store/action-creators';
import { SWIPER_DEFAULTS } from '../../configs/costants';

import { getPaginationParams } from '../../modules/pagination';
import { settingsManager } from '../../modules/localSettings';
import { justArray } from '../../utils';

import useDelayedPending from '../../hooks/useDelayedPending';
import useMatchMedia from '../../hooks/useMatchMedia';
import useScrollToContent from '../../hooks/useScrollToContent';
import usePending from '../../hooks/usePending';

import SwiperWrapper from 'react-id-swiper';

import ExpSection from '../../components/atoms/ExpSection/ExpSection';
import Loading from '../atoms/Loading';
import ProtocolCard from '../protocol/ProtocolCard';
import TutorialCard from '../inventory/TutorialCard';
import PlanInvoiceCard from '../profile/PlanInvoiceCard';
import clsx from 'clsx';

interface ProtocolProps {
    protocolRes: IPaginatedResult<IProtocol>;
    onAdd?: ICallback;
    onModalOpen?: ICallback;
}

export const ProtocolSection: React.FC<ProtocolProps> = ({
    protocolRes,
    onAdd,
    onModalOpen
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const usageRes = useSelector((state) => state.user.usageTotals.response);
    const useBeta = useSelector((state) => state.user.user.response?.use_beta);
    const protocolUsage = usageRes?.MIOP;
    const protocolCount = protocolUsage?.count || 0;
    const protocolSubtotal = protocolUsage?.subtotal || 0;

    const isPhone = useMatchMedia('mobile');

    const [protoOpen, setProtoOpen] = useState(true);
    const [sectionRef, scrollToContent] = useScrollToContent({
        onlyMobile: true,
        block: 'center'
    });

    const protocolSwiperRef = useRef<typeof SwiperWrapper | null>(null);
    const { hasNext, hasPrevious } = protocolRes.paginate;

    const protocolLoading = useDelayedPending([getProtocols]);
    const protocolLoadingInst = usePending([getProtocols]);

    const protocols = justArray(protocolRes.response.results);
    const protoCount = protocolRes.response.count;

    const getMoreProtocols = useCallback(
        (direction: IPaginateDirection) => {
            const params = getPaginationParams(protocolRes.paginate, direction);

            dispatch(getProtocols(params, direction));
        },
        [dispatch, protocolRes.paginate]
    );

    // TODO use a hook for swiper pagination
    useEffect(() => {
        const protocolSwiper = protocolSwiperRef.current?.swiper;
        if (protoOpen && protocolSwiper) {
            protocolSwiper.on('reachEnd', () => {
                hasNext && !protocolLoadingInst && getMoreProtocols('forwards');
            });

            protocolSwiper.on('reachBeginning', () => {
                hasPrevious &&
                    !protocolLoadingInst &&
                    getMoreProtocols('backwards');
            });

            return () => {
                protocolSwiper.off('reachEnd');
                protocolSwiper.off('reachBeginning');
            };
        }
    }, [
        protoOpen,
        hasNext,
        hasPrevious,
        getMoreProtocols,
        protocolLoadingInst
    ]);

    const onSectionToggle = () => {
        setProtoOpen((state) => {
            settingsManager.set('protocol_section_open', !state);
            return !state;
        });
    };

    // Swiper only properly accepts arrays
    const protocolsNode = [
        protocolCount && (
            <PlanInvoiceCard
                className={clsx("inline-s", useBeta && "betaMode-invoice")}
                key="protocol-invoice-card"
                used={protocolCount}
                subtotal={protocolSubtotal}
                featureName="MIOP"
            />
        ),
        !protocolCount && !protocols.length && (
            <TutorialCard
                key="protocol-tutorial-card"
                onAction={onModalOpen}
                protocol
            />
        ),
        Boolean(protocols.length) &&
            protocols.map((protocol) => {
                const { prt_id } = protocol;
                return (
                    <ProtocolCard
                        key={prt_id}
                        className="inline-s"
                        protocol={protocol}
                    />
                );
            })
    ].filter((node) => Boolean(node));

    return (
        <>
            <div ref={sectionRef} />
            <ExpSection
                open={protoOpen}
                title={
                    <span attr-intercom="miop">
                        {t('open_protocols').toUpperCase()}
                    </span>
                }
                actionTitle={t('protocol')}
                count={protoCount || protocols.length}
                onClick={onSectionToggle}
                onAction={onAdd}
                wide={isPhone}
                onAnimationComplete={() => {
                    if (protoOpen && sectionRef.current) {
                        scrollToContent();
                    }
                }}
            >
                <div className="stack-l">
                    <SwiperWrapper
                        {...SWIPER_DEFAULTS}
                        ref={protocolSwiperRef}
                        shouldSwiperUpdate
                    >
                        {protocolLoading ? (
                            <Loading
                                className="loading__dashborad"
                                size="xlarge"
                            />
                        ) : (
                            protocolsNode
                        )}
                    </SwiperWrapper>
                </div>
            </ExpSection>
        </>
    );
};

export default ProtocolSection;
