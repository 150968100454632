import { takeLatest } from 'redux-saga/effects';
import { normalize } from 'normalizr';

import { GET_ELEMENT_ISSUES_REQUEST } from '../../actions';
import {
    getElementIssues,
    getElementIssuesSuccess,
    getElementIssueFail
} from '../../action-creators';
import { simpleRequest, ISuccessCallback } from '../modules/request';

import { issueSchema } from '../../reducers/schema';

type IGetElementIssuesAction = RT<typeof getElementIssues>;

const onSuccess: ISuccessCallback<IGetElementIssuesAction> = function (data) {
    const { entities, result } = normalize(data, [issueSchema]);
    const { issues = {}, uploads } = entities;

    return {
        store: {
            allIds: result,
            byIds: issues
        },
        uploads
    };
};

export default takeLatest(
    GET_ELEMENT_ISSUES_REQUEST,
    simpleRequest<IGetElementIssuesAction>({
        success: getElementIssuesSuccess,
        failure: getElementIssueFail,
        getUrl: (action) => `element/${action.eleId}/issues/`,
        onSuccess
    })
);
