import { takeEvery, all, put } from 'redux-saga/effects';

import {
    POST_PROTOCOL_ELEMENT_ATTRIBUTES_REQUEST,
    POST_PROTOCOL_ELEMENT_ATTRIBUTES_FAIL
} from '../../actions';
import {
    protoEleAttrCrud,
    protoEleAttrCrudSuccess,
    protoEleAttrCrudFail,
    getProtocolElement
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

type IPostProtocolElementAttributesAction = RT<typeof protoEleAttrCrud>;
type IPostProtocolElementAttributesFailAction = RT<typeof protoEleAttrCrudFail>;

const requestEffect = simpleRequest<IPostProtocolElementAttributesAction>({
    success: protoEleAttrCrudSuccess,
    failure: protoEleAttrCrudFail,
    getBody: (action) => action.payload,
    getUrl: (_) => `protocol_element_attribute/element_attribute_crud/`,
    getEntityId: (action) => action.payload.pela_pele_id,
    method: 'POST',
    enableOffline: true
});

export default all([
    takeEvery(POST_PROTOCOL_ELEMENT_ATTRIBUTES_REQUEST, requestEffect),
    takeEvery(
        POST_PROTOCOL_ELEMENT_ATTRIBUTES_FAIL,
        function* (action: IPostProtocolElementAttributesFailAction) {
            if (action.itemId == null) return;
            if (action.itemId < 0) return;
            yield put(getProtocolElement(action.itemId));
        }
    )
]);
