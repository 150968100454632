import { takeEvery } from 'redux-saga/effects';

import { POST_DPD_REQUEST } from '../../actions';
import { postDpd, postDpdSuccess, postDpdFail } from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeEvery(
    POST_DPD_REQUEST,
    simpleRequest<ReturnType<typeof postDpd>>({
        success: postDpdSuccess,
        failure: postDpdFail,
        getUrl: (_) => 'dpd/dpd_create/',
        getBody: (action) => action.dpd,
        method: 'POST'
    })
);
