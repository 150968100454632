import * as actions from '../actions/protocol';
import { createCustomAction } from 'typesafe-actions';
import { actionFail, actionPayload } from '../storeModule';
import { Assign } from 'utility-types';

import { IGetProtocolRoomsResult } from '../sagas/protocol/getRooms';

type ModifyableFields = Pick<
    IIssue,
    | 'title'
    | 'fixcost'
    | 'agreedshare'
    | 'date_occurred'
    | 'fix'
    | 'handling'
    | 'description'
    | 'grade'
>;

type IModifiableIssue = ModifyableFields & {
    piss_rom_id?: number | null;
    piss_ele_id?: number | null;
    piss_pele_id?: number | null;
    piss_prom_id?: number | string | null;
    protocol_element?: number | null;
    protocol_room?: number | string | null;
    protocol_item?: number | null;
    piss_itm_id?: number | null;
    piss_pitm_id?: number | null;
    medias?: number[] | null;
};

export const getProtocols = createCustomAction(
    actions.GET_PROTOCOLS_REQUEST,
    (type) => {
        return (
            params: IPaginationParams = {},
            paginated?: IPaginateDirection
        ) => ({ type, params, paginated });
    }
);

export const getProtocolsSuccess = createCustomAction(
    actions.GET_PROTOCOLS_SUCCESS,
    actionPayload
);
export const getProtocolsFail = createCustomAction(
    actions.GET_PROTOCOLS_FAIL,
    actionFail
);

export const getProtocol = createCustomAction( 
    actions.GET_PROTOCOL_REQUEST,
    (type) => {
        return (protoId: number) => ({ type, protoId });
    }
);
export const getProtocolSuccess = createCustomAction(
    actions.GET_PROTOCOL_SUCCESS,
    actionPayload
);
export const getProtocolFail = createCustomAction(
    actions.GET_PROTOCOL_FAIL,
    actionFail
);

export const getProtocolForLatestData = createCustomAction( 
    actions.GET_PROTOCOL_FOR_LATEST_DATA_REQUEST,
    (type) => {
        return (protoId: number) => ({ type, protoId });
    }
);
export const getProtocolForLatestDataSuccess = createCustomAction(
    actions.GET_PROTOCOL_FOR_LATEST_DATA_SUCCESS,
    actionPayload
);
export const getProtocolForLatestDataFail = createCustomAction(
    actions.GET_PROTOCOL_FOR_LATEST_DATA_FAIL,
    actionFail
);

type IPredictProtocol = Omit<Partial<IProtocol>, 'prt_id'>;

type IProtocolChanges = { media_med_id?: number[] };
type IModProtocol = Assign<Omit<IPredictProtocol, 'uploads'>, IProtocolChanges>;

export const postProtocol = createCustomAction(
    actions.POST_PROTOCOL_REQUEST,
    (type) => {
        return (protocol: Assign<IModProtocol, { dpd_id: number }>) => ({
            type,
            protocol
        });
    }
);
export const postProtocolSuccess = createCustomAction(
    actions.POST_PROTOCOL_SUCCESS,
    actionPayload
);
export const postProtocolFail = createCustomAction(
    actions.POST_PROTOCOL_FAIL,
    actionFail
);

export const patchProtocol = createCustomAction(
    actions.PATCH_PROTOCOL_REQUEST,
    (type) => {
        return (protoId: number, proto: IModProtocol) => ({
            type,
            protoId,
            proto
        });
    }
);
export const patchProtocolSuccess = createCustomAction(
    actions.PATCH_PROTOCOL_SUCCESS
);
export const patchProtocolFail = createCustomAction(
    actions.PATCH_PROTOCOL_FAIL,
    actionFail
);

export const delProtocol = createCustomAction(
    actions.DELETE_PROTOCOL_REQUEST,
    (type) => {
        return (protoId: number) => ({ type, protoId });
    }
);
export const delProtocolSuccess = createCustomAction(
    actions.DELETE_PROTOCOL_SUCCESS
);
export const delProtocolFail = createCustomAction(
    actions.DELETE_PROTOCOL_FAIL,
    actionFail
);

export const protoGetRooms = createCustomAction(
    actions.GET_PROTOCOL_ROOMS_REQUEST,
    (type) => {
        return (protoId: number, lang: ILang) => ({ type, protoId, lang });
    }
);
export const protoGetRoomsSuccess = createCustomAction(
    actions.GET_PROTOCOL_ROOMS_SUCCESS,
    (type) => {
        return (payload: IGetProtocolRoomsResult) => ({ type, payload });
    }
);
export const protoGetRoomsFail = createCustomAction(
    actions.GET_PROTOCOL_ROOMS_FAIL,
    actionFail
);

type IProtoModRoom = Assign<
    Omit<IProtocolRoom, 'prom_id' | 'prom_rom_id'>,
    { media_med_id?: number }
>;
export const protoPostRoom = createCustomAction(
    actions.POST_PROTOCOL_ROOM_REQUEST,
    (type) => {
        return (room: IProtoModRoom, lang: ILang) => ({ type, room, lang });
    }
);
export const protoPostRoomSuccess = createCustomAction(
    actions.POST_PROTOCOL_ROOM_SUCCESS,
    actionPayload
);
export const protoPostRoomFail = createCustomAction(
    actions.POST_PROTOCOL_ROOM_FAIL,
    actionFail
);

export const protoPatchRoom = createCustomAction(
    actions.PATCH_PROTOCOL_ROOM_REQUEST,
    (type) => {
        return (id: number, payload: IProtoModRoom, lang: ILang) => ({
            type,
            id,
            payload,
            lang
        });
    }
);
export const protoPatchRoomSuccess = createCustomAction(
    actions.PATCH_PROTOCOL_ROOM_SUCCESS,
    actionPayload
);
export const protoPatchRoomFail = createCustomAction(
    actions.PATCH_PROTOCOL_ROOM_FAIL,
    actionFail
);

export const protoDelRoom = createCustomAction(
    actions.DELETE_PROTOCOL_ROOM_REQUEST,
    (type) => {
        return (id: number) => ({ type, id });
    }
);
export const protoDelRoomSuccess = createCustomAction(
    actions.DELETE_PROTOCOL_ROOM_SUCCESS
);
export const protoDelRoomFail = createCustomAction(
    actions.DELETE_PROTOCOL_ROOM_FAIL,
    actionFail
);

export const protoGetEles = createCustomAction(
    actions.GET_PROTOCOL_ELEMENTS_REQUEST,
    (type) => {
        return (protoRoomId: number) => ({ type, protoRoomId });
    }
);
export const protoGetElesSuccess = createCustomAction(
    actions.GET_PROTOCOL_ELEMENTS_SUCCESS,
    actionPayload
);
export const protoGetElesFail = createCustomAction(
    actions.GET_PROTOCOL_ELEMENTS_FAIL,
    actionFail
);

type IProtocolElementMod = Assign<
    Omit<IProtocolElement, 'pele_id' | 'pele_ele_id' | 'uploads'>,
    { pele_prom_id: number; media_med_id?: number[] }
>;
type IProtocolElementPatchPayload = Omit<IProtocolElementMod, 'pele_prom_id'>;

export const protoPostEle = createCustomAction(
    actions.POST_PROTOCOL_ELEMENT_REQUEST,
    (type) => {
        return (element: IProtocolElementMod) => ({ type, payload: element });
    }
);
export const protoPostEleSuccess = createCustomAction(
    actions.POST_PROTOCOL_ELEMENT_SUCCESS,
    actionPayload
);
export const protoPostEleFail = createCustomAction(
    actions.POST_PROTOCOL_ELEMENT_FAIL,
    actionFail
);

export const protoPatchEle = createCustomAction(
    actions.PATCH_PROTOCOL_ELEMENT_REQUEST,
    (type) => {
        return (
            protoEleId: number,
            payload: IProtocolElementPatchPayload,
            lang: ILang
        ) => ({
            type,
            protoEleId,
            payload,
            lang
        });
    }
);
export const protoPatchEleSuccess = createCustomAction(
    actions.PATCH_PROTOCOL_ELEMENT_SUCCESS,
    actionPayload
);
export const protoPatchEleFail = createCustomAction(
    actions.PATCH_PROTOCOL_ELEMENT_FAIL,
    actionFail
);

export const protoDelEle = createCustomAction(
    actions.DELETE_PROTOCOL_ELEMENT_REQUEST,
    (type) => {
        return (id: number) => ({ type, id });
    }
);
export const protoDelEleSuccess = createCustomAction(
    actions.DELETE_PROTOCOL_ELEMENT_SUCCESS
);
export const protoDelEleFail = createCustomAction(
    actions.DELETE_PROTOCOL_ELEMENT_FAIL,
    actionFail
);

export const protoEleAttrCrud = createCustomAction(
    actions.POST_PROTOCOL_ELEMENT_ATTRIBUTES_REQUEST,
    (type) => {
        return (payload: {
            pela_pele_id: number;
            pela_state: Partial<IProtocolElementAttr>[];
        }) => ({ type, payload });
    }
);
export const protoEleAttrCrudSuccess = createCustomAction(
    actions.POST_PROTOCOL_ELEMENT_ATTRIBUTES_SUCCESS,
    actionPayload
);
export const protoEleAttrCrudFail = createCustomAction(
    actions.POST_PROTOCOL_ELEMENT_ATTRIBUTES_FAIL,
    actionFail
);

type IModProtoItem = Assign<
    Omit<IProtocolItem, 'pitm_id' | 'uploads'>,
    { media_med_id?: number[] }
>;

export const protoGetItems = createCustomAction(
    actions.GET_PROTOCOL_ITEMS_REQUEST,
    (type) => {
        return (protoId: number) => ({ type, protoId });
    }
);
export const protoGetItemsSuccess = createCustomAction(
    actions.GET_PROTOCOL_ITEMS_SUCCESS,
    actionPayload
);
export const protoGetItemsFail = createCustomAction(
    actions.GET_PROTOCOL_ITEMS_FAIL,
    actionFail
);

export const protoPostItem = createCustomAction(
    actions.POST_PROTOCOL_ITEM_REQUEST,
    (type) => {
        return (
            item: Assign<
                IModProtoItem,
                { pitm_prt_id: number; pitm_dpd_id: number }
            >
        ) => ({ type, payload: item });
    }
);
export const protoPostItemSuccess = createCustomAction(
    actions.POST_PROTOCOL_ITEM_SUCCESS,
    actionPayload
);
export const protoPostItemFail = createCustomAction(
    actions.POST_PROTOCOL_ITEM_FAIL,
    actionFail
);

export const protoPatchItem = createCustomAction(
    actions.PATCH_PROTOCOL_ITEM_REQUEST,
    (type) => {
        return (itemId: number, item: IModProtoItem) => ({
            type,
            itemId,
            payload: item
        });
    }
);
export const protoPatchItemSuccess = createCustomAction(
    actions.PATCH_PROTOCOL_ITEM_SUCCESS,
    actionPayload
);
export const protoPatchItemFail = createCustomAction(
    actions.PATCH_PROTOCOL_ITEM_FAIL,
    actionFail
);

export const protoDelItem = createCustomAction(
    actions.DELETE_PROTOCOL_ITEM_REQUEST,
    (type) => {
        return (itemId: number) => ({ type, itemId });
    }
);
export const protoDelItemSuccess = createCustomAction(
    actions.DELETE_PROTOCOL_ITEM_SUCCESS
);
export const protoDelItemFail = createCustomAction(
    actions.DELETE_PROTOCOL_ITEM_FAIL,
    actionFail
);

interface IProtocolItemAttributePayload {
    pita_pitm_id: number;
    pita_state: Partial<IProtocolItemAttr>[];
}

export const protoItemAttrCrud = createCustomAction(
    actions.POST_PROTOCOL_ITEM_ATTRIBUTES_REQUEST,
    (type) => {
        return (payload: IProtocolItemAttributePayload) => ({ type, payload });
    }
);
export const protoItemAttrCrudSuccess = createCustomAction(
    actions.POST_PROTOCOL_ITEM_ATTRIBUTES_SUCCESS,
    actionPayload
);
export const protoItemAttrCrudFail = createCustomAction(
    actions.POST_PROTOCOL_ITEM_ATTRIBUTES_FAIL,
    actionFail
);

export const getAttendees = createCustomAction(
    actions.GET_ATTENDEES_REQUEST,
    (type) => {
        return (protoId: number) => ({ type, protoId });
    }
);
export const getAttendeesSuccess = createCustomAction(
    actions.GET_ATTENDEES_SUCCESS,
    actionPayload
);
export const getAttendessFail = createCustomAction(
    actions.GET_ATTENDEES_FAIL,
    actionFail
);

interface IAttendeeParams {
    att_email?: string;
    att_firstname?: string;
    att_lastname?: string;
    att_phone?: string;
    att_picture?: number;
    address?: Partial<IAddress>;
}

type IModAttendee = Assign<
    Omit<IAttendee, 'att_id' | 'uploads'>,
    IAttendeeParams
>;

export const postAttendee = createCustomAction(
    actions.POST_ATTENDEE_REQUEST,
    (type) => {
        return (attendee: Assign<IModAttendee, { att_prt_id: number }>) => ({
            type,
            attendee
        });
    }
);
export const postAttendeeSuccess = createCustomAction(
    actions.POST_ATTENDEE_SUCCESS,
    actionPayload
);
export const postAttendeeFail = createCustomAction(
    actions.POST_ATTENDEE_FAIL,
    actionFail
);

export const patchAttendee = createCustomAction(
    actions.PATCH_ATTENDEE_REQUEST,
    (type) => {
        return (id: number, attendee: IModAttendee) => ({ type, id, attendee });
    }
);
export const patchAttendeeSuccess = createCustomAction(
    actions.PATCH_ATTENDEE_SUCCESS,
    actionPayload
);
export const patchAttendeeFail = createCustomAction(
    actions.PATCH_ATTENDEE_FAIL,
    actionFail
);

export const delAttendee = createCustomAction(
    actions.DEL_ATTENDEE_REQUEST,
    (type) => {
        return (id: number) => ({ type, id });
    }
);
export const delAttendeeSuccess = createCustomAction(
    actions.DEL_ATTENDEE_SUCCESS
);
export const delAttendeeFail = createCustomAction(
    actions.DEL_ATTENDEE_FAIL,
    actionFail
);

type IModSignature = Assign<
    Omit<ISignature, 'id' | 'attendee_id' | 'uploads'>,
    { attendee_id: number; protocol_id: number; media_id?: number }
>;

export const postSignature = createCustomAction( 
    actions.POST_SIGNATURE_REQUEST,
    (type) => {
        return (signature: IModSignature) => ({ type, signature });
    }
);
export const postSignatureSuccess = createCustomAction(
    actions.POST_SIGNATURE_SUCCESS,
    actionPayload
);
export const postSignatureFail = createCustomAction(
    actions.POST_SIGNATURE_FAIL,
    actionFail
);

export const delSignature = createCustomAction(
    actions.DELETE_SIGNATURE_REQUEST,
    (type) => {
        return (signId: number) => ({ type, signId });
    }
);
export const delSignatureSuccess = createCustomAction(
    actions.DELETE_SIGNATURE_SUCCESS
);
export const delSignatureFail = createCustomAction(
    actions.DELETE_SIGNATURE_FAIL,
    actionFail
);

export const finishProtocol = createCustomAction(
    actions.FINISH_PROTOCOL_REQUEST,
    (type) => {
        return (protoId: number) => ({ type, protoId });
    }
);
export const finishProtocolSuccess = createCustomAction(
    actions.FINISH_PROTOCOL_SUCCESS,
    actionPayload
);
export const finishProtocolFail = createCustomAction(
    actions.FINISH_PROTOCOL_FAIL,
    actionFail
);

export const checkPdfReady = createCustomAction(
    actions.CHECK_PDF_READY_REQUEST,
    (type) => {
        return (protoId: number) => ({ type, protoId });
    }
);

export const setHeaderProtocolPreview = createCustomAction(
    actions.SET_HEADER_PROTOCOL_PREVIEW,
    (type) => {
        return (state: boolean) => ({ type, payload: state });
    }
);
export const checkPdfReadySuccess = createCustomAction(
    actions.CHECK_PDF_READY_SUCCESS,
    actionPayload
);
export const checkPdfReadyFail = createCustomAction(
    actions.CHECK_PDF_READY_FAIL,
    actionFail
);

export const previewPdfReady = createCustomAction(
    actions.PREVIEW_PDF_READY_REQUEST,
    (type) => {
        return (protoId: number, lang: ILang) => {
            return { type, protoId, lang };
        };
    }
);

export const previewPdfReadySuccess = createCustomAction(
    actions.PREVIEW_PDF_READY_SUCCESS,
    actionPayload
);

export const previewPdfReadyFail = createCustomAction(
    actions.PREVIEW_PDF_READY_FAIL
);

export const generatePdf = createCustomAction(
    actions.GENERATE_PDF_REQUEST,
    (type) => {
        return (protocolId: number, params: { lang?: string } = {}) => ({
            type,
            protocolId,
            params
        });
    }
);
export const generatePdfSuccess = createCustomAction(
    actions.GENERATE_PDF_SUCCESS
);
export const generatePdfFail = createCustomAction(actions.GENERATE_PDF_FAIL);

export const getItemTemplates = createCustomAction(
    actions.GET_ITEM_TEMPLATES_REQUEST,
    (type) => {
        return (itemType: 'S' | 'H', lang: ILang) => ({ type, itemType, lang });
    }
);
export const getItemTemplatesSuccess = createCustomAction(
    actions.GET_ITEM_TEMPLATES_SUCCESS,
    actionPayload
);
export const getItemTemplatesFail = createCustomAction(
    actions.GET_ITEM_TEMPLATES_FAIL,
    actionFail
);

export const authorizePartnerProtocol = createCustomAction(
    actions.AUTHORIZE_PARTNER_PROTOCOL_REQUEST,
    (type) => {
        return (protocolToken: string, authToken: string) => ({
            type,
            protocolToken,
            authToken
        });
    }
);
export const authorizePartnerProtocolSuccess = createCustomAction(
    actions.AUTHORIZE_PARTNER_PROTOCOL_SUCCESS,
    actionPayload
);
export const authorizePartnerProtocolFail = createCustomAction(
    actions.AUTHORIZE_PARTNER_PROTOCOL_FAIL,
    actionFail
);

export const patchPartnerProtocol = createCustomAction(
    actions.PATCH_PARTNER_PROTOCOL_REQUEST,
    (type) => {
        return (protocolToken: string, data: { miop_id: number }) => ({
            type,
            protocolToken,
            data
        });
    }
);
export const patchPartnerProtocolSuccess = createCustomAction(
    actions.PATCH_PARTNER_PROTOCOL_SUCCESS,
    actionPayload
);
export const patchPartnerProtocolFail = createCustomAction(
    actions.PATCH_PARTNER_PROTOCOL_FAIL,
    actionFail
);

export const getProtocolRoom = createCustomAction(
    actions.GET_PROTOCOL_ROOM_REQUEST,
    (type) => {
        return (roomId: number) => ({ type, roomId });
    }
);
export const getProtocolRoomSuccess = createCustomAction(
    actions.GET_PROTOCOL_ROOM_SUCCESS,
    actionPayload
);
export const getProtocolRoomFail = createCustomAction(
    actions.GET_PROTOCOL_ROOM_FAIL,
    actionFail
);

export const getProtocolElement = createCustomAction(
    actions.GET_PROTOCOL_ELEMENT_REQUEST,
    (type) => {
        return (elementId: number) => ({ type, elementId });
    }
);
export const getProtocolElementSuccess = createCustomAction(
    actions.GET_PROTOCOL_ELEMENT_SUCCESS,
    actionPayload
);
export const getProtocolElementFail = createCustomAction(
    actions.GET_PROTOCOL_ELEMENT_FAIL,
    actionFail
);

export const getProtocolItem = createCustomAction(
    actions.GET_PROTOCOL_ITEM_REQUEST,
    (type) => {
        return (itemId: number) => ({ type, itemId });
    }
);
export const getProtocolItemSuccess = createCustomAction(
    actions.GET_PROTOCOL_ITEM_SUCCESS,
    actionPayload
);
export const getProtocolItemFail = createCustomAction(
    actions.GET_PROTOCOL_ITEM_FAIL,
    actionFail
);

export const getAttendee = createCustomAction(
    actions.GET_ATTENDEE_REQUEST,
    (type) => {
        return (id: number) => ({ type, id });
    }
);
export const getAttendeeSuccess = createCustomAction(
    actions.GET_ATTENDEE_SUCCESS,
    actionPayload
);
export const getAttendeeFail = createCustomAction(
    actions.GET_ATTENDEE_FAIL,
    actionFail
);

// Note: These actions allow modifying the store without making any requests
// This showcases an issue with the current approach
// Ideally there should be a way to distinguish the two within the same action
// without creating extra duplication

export const predictProtocolItemAttibutes = createCustomAction(
    actions.PREDICT_PROTOCOL_ITEM_ATTRIBUTES,
    (type) => {
        return (payload: IProtocolItemAttributePayload) => ({ type, payload });
    }
);
export const createLocalProtocolElement = createCustomAction(
    actions.CREATE_LOCAL_PROTOCOL_ELEMENT,
    (type) => {
        return (element: IProtocolElementMod) => ({ type, payload: element });
    }
);
export const predictAttendee = createCustomAction(
    actions.PREDICT_ATTENDEE,
    (type) => {
        return (attId: number, payload: IModAttendee) => ({
            type,
            attId,
            payload
        });
    }
);
export const predictProtoRoom = createCustomAction(
    actions.PREDICT_PROTO_ROOM,
    (type) => {
        return (roomId: number, payload: IProtoModRoom) => ({
            type,
            roomId,
            payload
        });
    }
);
export const predictProtoEle = createCustomAction(
    actions.PREDICT_PROTO_ELE,
    (type) => {
        return (eleId: number, payload: IProtocolElementPatchPayload) => ({
            type,
            eleId,
            payload
        });
    }
);
export const predictProtocolElements = createCustomAction(
    actions.PREDICT_PROTOCOL_ELEMENTS,
    (type) => {
        return (elements: IProtocolElement[]) => ({ type, elements });
    }
);
export const predictProtocolElementsOrder = createCustomAction(
    actions.PREDICT_PROTOCOL_ELEMENTS_ORDER,
    (type) => {
        return (roomId: number, elements: IProtocolElement[]) => ({
            type,
            payload: { elements, roomId }
        });
    }
);
export const predictProtocolRoomsOrder = createCustomAction(
    actions.PREDICT_PROTOCOL_ROOMS_ORDER,
    (type) => {
        return (rooms: IProtocolRoom[]) => ({
            type,
            rooms
        });
    }
);

export const predictProtocolRooms = createCustomAction(
    actions.PREDICT_PROTOCOL_ROOMS,
    (type) => {
        return (rooms: IProtocolRoom[]) => ({ type, rooms });
    }
);

export const predictProtoItem = createCustomAction(
    actions.PREDICT_PROTO_ITEM,
    (type) => {
        return (itemId: number, payload: IModProtoItem) => ({
            type,
            itemId,
            payload
        });
    }
);
export const predictCurrentProtocol = createCustomAction(
    actions.PREDICT_CURRNET_PROTOCOL,
    (type) => {
        return (payload: IPredictProtocol) => ({ type, payload });
    }
);
export const predictCurrnetProtocolDpd = createCustomAction(
    actions.PREDICT_CURRENT_PROTOCOL_DPD,
    (type) => {
        return (payload: Partial<IDpd>) => ({ type, payload });
    }
);
// export const postIssue = createCustomAction( // will be deleted once Inventory is uncommented
//     actions.POST_ISSUE_REQUEST,
//     (type) => {
//         return (payload: IModifiableIssue) => ({ type, payload });
//     }
// );
export const postProtocolIssue = createCustomAction(
    actions.POST_PROTOCOL_ISSUE_REQUEST,
    (type) => {
        return (payload: IModifiableIssue) => ({ type, payload });
    }
);
// export const patchIssue = createCustomAction( // will be deleted once Inventory is uncommented
//     actions.PATCH_ISSUE_REQUEST,
//     (type) => {
//         return (issueId: number, payload: IModifiableIssue, lang: ILang, isOffline: boolean) => ({
//             type,
//             issueId,
//             payload,
//             lang,
//             isOffline
//         });
//     }
// );
export const patchProtocolIssue = createCustomAction(
    actions.PATCH_PROTOCOL_ISSUE_REQUEST,
    (type) => {
        return (
            issueId: number,
            payload: IModifiableIssue,
            lang: ILang,
            isOffline: boolean
        ) => ({
            type,
            issueId,
            payload,
            lang,
            isOffline
        });
    }
);
// export const deleteIssue = createCustomAction( // will be deleted once Inventory is uncommented
//     actions.DELETE_ISSUE_REQUEST,
//     (type) => {
//         return (issueId: number, isOffline: boolean) => ({ type, issueId, isOffline });
//     }
// );
export const deleteProtocolIssue = createCustomAction(
    actions.DELETE_PROTOCOL_ISSUE_REQUEST,
    (type) => {
        return (issueId: number, isOffline: boolean) => ({
            type,
            issueId,
            isOffline
        });
    }
);
// export const deleteIssueSuccess = createCustomAction( // will be deleted once Inventory is uncommented
//     actions.DELETE_ISSUE_SUCCESS,
//     actionPayload
// );
export const deleteProtocolIssueSuccess = createCustomAction(
    actions.DELETE_PROTOCOL_ISSUE_SUCCESS,
    actionPayload
);
// export const deleteIssueFail = createCustomAction( // will be deleted once Inventory is uncommented
//     actions.DELETE_ISSUE_FAIL,
//     actionFail
// );
export const deleteProtocolIssueFail = createCustomAction(
    actions.DELETE_PROTOCOL_ISSUE_FAIL,
    actionFail
);
// export const patchIssueResponse = createCustomAction( // will be deleted once Inventory is uncommented
//     actions.PATCH_ISSUE_RESPONSE,
//     actionPayload
// );
export const patchProtocolIssueResponse = createCustomAction(
    actions.PATCH_PROTOCOL_ISSUE_RESPONSE,
    actionPayload
);
// export const postIssueResponse = createCustomAction( // will be deleted once Inventory is uncommented
//     actions.POST_ISSUE_RESPONSE,
//     actionPayload
// );
export const postProtocolIssueResponse = createCustomAction(
    actions.POST_PROTOCOL_ISSUE_RESPONSE,
    actionPayload
);

export const saveProtocolNotes = createCustomAction(
    actions.SAVE_PROTOCOL_ADDITIONAL_NOTES_REQUEST,
    (type) => {
        return (protoId: number, proto: IModProtocol) => ({
            type,
            protoId,
            proto
        });
    }
);
export const saveProtocolNotesSuccess = createCustomAction(
    actions.SAVE_PROTOCOL_ADDITIONAL_NOTES_SUCCESS
);
export const saveProtocolNotesFail = createCustomAction(
    actions.SAVE_PROTOCOL_ADDITIONAL_NOTES_FAIL,
    actionFail
);

export const protocolTrackerViewPdf = createCustomAction(
    actions.PROTOCOL_TRACKER_VIEW_PDF_REQUEST,
    actionPayload
);
export const protocolTrackerViewPdfSuccess = createCustomAction(
    actions.PROTOCOL_TRACKER_VIEW_PDF_SUCCESS,
    actionPayload
);
export const protocolTrackerViewPdfFail = createCustomAction(
    actions.PROTOCOL_TRACKER_VIEW_PDF_FAIL,
    actionFail
);

// resend pdf to attendee
export const resendAttendeeEmail = createCustomAction(
    actions.RESEND_ATTENDEE_EMAIL,
    (type) => {
        return (attendee_id: number) => ({
            type,
            attendee_id
        });
    }
);
export const resendAttendeeEmailSuccess = createCustomAction(
    actions.RESEND_ATTENDEE_EMAIL_SUCCESS,
    actionPayload
);
export const resendAttendeeEmailFail = createCustomAction(
    actions.RESEND_ATTENDEE_EMAIL_FAIL,
    actionFail
);