import { takeEvery } from 'redux-saga/effects';

import { PATCH_ROOM_REQUEST } from '../../actions';
import {
    patchRoomSuccess,
    patchRoomFail,
    IPatchRoom
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeEvery(
    PATCH_ROOM_REQUEST,
    simpleRequest<IPatchRoom>({
        success: patchRoomSuccess,
        failure: patchRoomFail,
        getUrl: (action) => `room/${action.roomId}/`,
        method: 'PATCH',
        getBody: (action) => action.payload
    })
);
