import { AnyAction } from 'redux';
import * as actions from '../actions/basicData';
import { transformer } from '../../utils/transformer';

function basicDataToHash(payload: any[]) {
    const lookupHash: IHash<any> = {};

    payload.forEach((data) => {
        const { bas_grp_id, bas_term, bas_text1 } = data;
        const hashExists = Boolean(lookupHash[bas_grp_id]);

        if (hashExists) {
            lookupHash[bas_grp_id][bas_term] = bas_text1;
        } else {
            lookupHash[bas_grp_id] = { [bas_term]: bas_text1 };
        }
    });
    return lookupHash;
}

interface IBasicDataState {
    error: Error | null;
    response: ILookupHash | null;
}

const basicInitalState: IBasicDataState = {
    error: null,
    response: null
};

function basicDataReducer(
    state: IBasicDataState = basicInitalState,
    action: AnyAction
): IBasicDataState {
    switch (action.type) {
        case actions.GET_BASIC_DATA_SUCCESS:
            return {
                ...state,
                response: basicDataToHash(transformer(action.payload))
            };
        case actions.GET_BASIC_DATA_FAIL:
            return {
                ...state,
                error: action.error
            };
    }
    return state;
}

export default basicDataReducer;
