import { takeEvery } from 'redux-saga/effects';

import { resendInvitationSet } from '../../action-creators/team';
import { simpleRequest } from '../modules/request';

export default takeEvery(
    resendInvitationSet.type,
    simpleRequest<RT<typeof resendInvitationSet.request>>({
        success: resendInvitationSet.success,
        failure: resendInvitationSet.fail,
        getUrl: (action) =>
            `group_invitation/${action.id}/resend/?lang=${action.lang}`,
        isV2Api: true
    })
);
