import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { roundToDecimal } from '../../../utils';

import { LinearProgress } from '@rmwc/linear-progress';
import './storage-progress.scss';

interface IProps extends IClass {
    included: number;
    used: number;
    unit: string;
}

const StorageProgress: React.FC<IProps> = ({
    className,
    included,
    used,
    unit
}) => {
    const { t } = useTranslation();

    const paid = roundToDecimal(Math.max(used - included, 0));
    const total = included + Math.ceil(paid);

    const isOverFree = used > included;
    const isOverTotal = used > total;

    const available = total - used;
    const progress = (total - available) / total;

    const progressClass = clsx(
        'mdc-custom-thick-progress',
        isOverFree && 'mdc-custom-thick-progress--accent',
        isOverTotal && 'mdc-custom-thick-progress--danger',
        'stack-s'
    );

    const noteClass = clsx(
        'storage-progress__note',
        isOverTotal && 'storage-progress__note--danger'
    );

    const availableClass = clsx(
        'storage-progress__available',
        isOverTotal && 'storage-progress__available--danger'
    );

    const title = (
        <>
            {t('plan.storage.free_space', { amount: `${included}${unit}` })}
            {Boolean(paid) &&
                t('plan.storage.paid_space', {
                    amount: `${Math.ceil(paid)}${unit}`
                })}
        </>
    );

    const overLimitTitle = t('plan.storage.limit_exceeded');

    return (
        <div className={clsx('storage-progress__container', className)}>
            <div className={clsx(noteClass, 'stack-s')}>
                {isOverTotal ? overLimitTitle : title}
            </div>
            <LinearProgress
                className={progressClass}
                progress={progress}
                buffer={1}
            />
            <div className="storage-progress__info-container">
                <div className={clsx(noteClass, 'inline-s')}>
                    {isOverTotal
                        ? t('plan.storage.over_limit_space', {
                              amount_used: `${roundToDecimal(used)}${unit}`,
                              amount_total: `${total}${unit}`
                          })
                        : t('plan.storage.total_space', {
                              amount: `${roundToDecimal(used)}${unit}`
                          })}
                </div>
                <div className={availableClass}>
                    {!isOverTotal &&
                        t('plan.storage.available_space', {
                            amount: `${roundToDecimal(available)}${unit}`
                        })}
                </div>
            </div>
        </div>
    );
};

export default StorageProgress;
