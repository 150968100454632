import { takeEvery, all } from 'redux-saga/effects';

import {
    POST_PROTOCOL_ROOM_REQUEST,
    POST_PROTOCOL_ROOM_SUCCESS
} from '../../actions';
import {
    protoPostRoom,
    protoPostRoomSuccess,
    protoPostRoomFail
} from '../../action-creators';

import { simpleRequest } from '../modules/request';
import { normalizeProtocolRoom } from '../modules/normalization';
import { responseHelper } from '../modules/promise';

function onSuccessCallback(data: any) {
    return normalizeProtocolRoom(data);
}

const requestEffect = takeEvery(
    POST_PROTOCOL_ROOM_REQUEST,
    simpleRequest<RT<typeof protoPostRoom>>({
        success: protoPostRoomSuccess,
        failure: protoPostRoomFail,
        getUrl: (action) => `protocol_room/room_create/?lang=${action.lang}`,
        getBody: (action) => action.room,
        method: 'POST',
        enableOffline: true
    })
);

const responseEffect = responseHelper({
    actionName: POST_PROTOCOL_ROOM_SUCCESS,
    processFn: onSuccessCallback
});

export default all([requestEffect, responseEffect]);
