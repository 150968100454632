import { takeLatest } from 'redux-saga/effects';

import { POST_OFFLINE_PROTOCOL_CHECKIN_STATUS_REQUEST } from '../../actions';
import {
    postOfflineProtocolCheckinStatus,
    postOfflineProtocolCheckinStatusSuccess,
    postOfflineProtocolCheckinStatusFail
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeLatest(
    POST_OFFLINE_PROTOCOL_CHECKIN_STATUS_REQUEST,
    simpleRequest<RT<typeof postOfflineProtocolCheckinStatus>>({
        success: postOfflineProtocolCheckinStatusSuccess,
        onSuccess: postOfflineProtocolCheckinStatusSuccess,
        failure: postOfflineProtocolCheckinStatusFail,
        getUrl: (action) => `protocol/${action.prt_id}/checkin/`,
        getBody: (action) => action.payload,
        method: 'POST',
        noBlock: true
        // enableOffline: true
    })
);
