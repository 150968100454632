import { takeLeading } from 'redux-saga/effects';

import { POST_PUBLIC_LOG_REQUEST } from '../../actions';
import {
    postPublicLogSuccess,
    postPublicLogFail,
    postPublicLog
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeLeading(
    POST_PUBLIC_LOG_REQUEST,
    simpleRequest<ReturnType<typeof postPublicLog>>({
        success: postPublicLogSuccess,
        failure: postPublicLogFail,
        getUrl: (_) => `public_sharinglinklog/create_log/`,
        getBody: (action) => action.payload,
        method: 'POST',
        withAuth: false
    })
);
