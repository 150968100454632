import { takeLatest } from 'redux-saga/effects';
import { GET_CURRENT_ACC_REQUEST } from '../../actions';
import {
    getCurrentAccSuccess,
    getCurrentAccFail,
    getCurrentAcc
} from '../../action-creators';
import { simpleRequest, ISuccessCallback } from '../modules/request';

import { accountSchema } from '../../reducers/schema';
import { normalize } from 'normalizr';

type IGetAccountAction = RT<typeof getCurrentAcc>;
const onSuccess: ISuccessCallback<IGetAccountAction> = function (data) {
    const { result, entities } = normalize(data, accountSchema);
    const { accounts = {}, uploads = {} } = entities;

    const account = accounts[result];
    return {
        account,
        uploads
    };
};

export default takeLatest(
    GET_CURRENT_ACC_REQUEST,
    simpleRequest<IGetAccountAction>({
        success: getCurrentAccSuccess,
        failure: getCurrentAccFail,
        getUrl: (_) => 'user/me/',
        enableOffline: true, // Note: quickfix to allow to login in offlince mode to sync
        logoutOnFail: true,
        isV2Api: true,
        onSuccess
    })
);
