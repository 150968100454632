import { SagaIterator } from 'redux-saga';
import { put, call, select, takeLeading } from 'redux-saga/effects';

import { REFRESH_ACCESS_REQUEST } from '../../actions';
import {
    refreshAccessSuccess,
    refreshAccessFail,
    logout
} from '../../action-creators';

import { getRefreshToken } from '../../selectors/user';
import { tokenManager } from '../../../modules/tokens';

import axios, { AxiosRequestConfig } from 'axios';
import env from '../../../configs/environment';

function* refreshAccess(): SagaIterator {
    try {
        const options: AxiosRequestConfig = {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            data: { refresh: yield select(getRefreshToken) }
        };
        const response = yield call(
            axios,
            `${env.getProperty('apiUrl')}/api/v1/auth-users/jwt/refresh/`,
            options
        );
        const { data } = response;

        tokenManager.refresh(data.access);
        yield put(refreshAccessSuccess(response.data));
    } catch (err) {
        yield put(logout());
        yield put(refreshAccessFail(err));
    }
}

export default takeLeading(REFRESH_ACCESS_REQUEST, refreshAccess);
