import { useCallback, useRef, useState } from 'react';

const useTimer = () => {
    const [counter, setCounter] = useState(0);
    const intervalRef = useRef<number>();

    const startTimer = useCallback(() => {
        intervalRef.current = window.setInterval(() => {
            setCounter((s) => s + 1);
        }, 1000);
    }, []);

    const stopTimer = useCallback(() => {
        window.clearInterval(intervalRef.current);
    }, []);

    const resetTimer = useCallback(() => {
        setCounter(0);
    }, []);

    return {
        counter,
        startTimer,
        stopTimer,
        resetTimer
    };
};

export default useTimer;
