import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware, { SagaMiddleware } from 'redux-saga';

import { persistStore, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

//@ts-ignore
import {
    createStateSyncMiddleware,
    initMessageListener
} from 'redux-state-sync';

import { composeWithDevTools } from 'redux-devtools-extension';

import localforage from 'localforage';

import rootReducer from './reducers';
import rootSaga from './sagas';

import {
    failedActionHandlerMiddleware,
    networkIdMiddleware,
    handleActionSideJobs,
    cleanActionMiddleware,
    languageHandlerMiddleware
} from './middleware';
import { CLEAN_ACTIONS } from '../configs/costants';

// Needs to be incremented manually on any breaking change
// If versions differ, the store is cleared which could result in loss of data
// Migrations could be written to address such issues if neccessary though.
const storeVersion = 11;

const persistConfig = {
    key: 'root',
    storage: localforage,
    blacklist: ['global', 'pending', 'fetchStatus'],
    version: storeVersion,
    stateReconciler: autoMergeLevel2,
    migrate: (state: any, version: number) => {
        if (version !== storeVersion) {
            return Promise.resolve(undefined);
        }
        return Promise.resolve(state);
    }
};

const persistedReducer = persistReducer(persistConfig, rootReducer as any);

const sagaMiddleware: SagaMiddleware = createSagaMiddleware();

// Note: a quickfix to resolve sync conflict issues across multiple tabs
// Using this just for the counter is a bit suboptimal
// It would be great if more of actions could use this
const syncMiddleWare = createStateSyncMiddleware({
    channel: 'aimmo_redux_state_sync',
    whitelist: ['DECREMENT_COUNTER', ...CLEAN_ACTIONS]
});

const middlwares = [
    languageHandlerMiddleware,
    networkIdMiddleware,
    sagaMiddleware,
    cleanActionMiddleware,
    syncMiddleWare,
    failedActionHandlerMiddleware,
    handleActionSideJobs
];

const store = createStore(
    persistedReducer,
    undefined,
    composeWithDevTools(applyMiddleware(...middlwares))
);
const persistor = persistStore(store);

initMessageListener(store);

export { store, persistor };

sagaMiddleware.run(rootSaga);
