import type { IProtocolActions } from '../action-creators/types';
import * as acts from '../actions';

import {
    storePostFail,
    storeReplaceId,
    storeDelete,
    storeUpdate,
    storeAdd,
    storeAddOrUpdate,
    storeUpdateAssociation,
    storeDeleteAssociation,
    storeAddAssociation
} from './reducerModule';

import { getActionId } from '../storeModule';
import { replacePrimitve } from '../../modules/array';

import { paginateStore, onPaginateStart } from '../../modules/pagination';

import {
    ATTENDEE_TYPE_LANDLORD,
    STORE_PAGE_INIT
} from '../../configs/costants';

// Handle media patch
import {backgroundSyncSuccess, patchMediaSuccess} from '../action-creators';
import { PATCH_MEDIA_SUCCESS } from '../actions';

interface IParnterProtocol {
    user: IUser;
    property: IDpd;
    miop: number | null;
    property_reused: boolean;
}

const initNormalizedState = {
    allIds: [],
    byIds: {},
    errors: {}
};

interface IReducer {
    protocols: IPaginatedResult<IProtocol>;
    protocol: {
        response: IProtocol | null;
        error: Error | null;
        protocolPreviewHtml: string | null | any;
        isHeaderProtocolPreview: boolean;
        // Utility flag for offline mode
        // used to help decide if the user should
        // be redirected to it
        isSynced: boolean;
    };
    rooms: {
        byIds: IHash<IProtocolRoom>;
        allIds: number[];
        errors: IHash<Error>;
    };
    elements: {
        byIds: IHash<IProtocolElement>;
        allIds: number[];
        errors: IHash<Error>;
    };
    items: {
        byIds: IHash<IProtocolItem>;
        allIds: number[];
        errors: IHash<Error>;
    };
    attendees: {
        byIds: IHash<IAttendee>;
        allIds: number[];
        errors: IHash<Error>;
    };
    signatures: {
        byIds: IHash<ISignature>;
        allIds: number[];
        errors: IHash<Error>;
    };
    itemTemplates: IGetManyRequestResult<ITemplate>;
    partnerProtocol: IRequestResult<IParnterProtocol | null>;
}

const initReducer: IReducer = {
    protocols: {
        error: null,
        response: {
            count: 0,
            next: null,
            previous: null,
            results: []
        },
        paginate: {
            ...STORE_PAGE_INIT
        }
    },
    protocol: {
        error: null,
        response: null,
        protocolPreviewHtml: null,
        isSynced: false,
        isHeaderProtocolPreview: false
    },
    rooms: {
        ...initNormalizedState
    },
    elements: {
        ...initNormalizedState
    },
    items: {
        ...initNormalizedState
    },
    attendees: {
        ...initNormalizedState
    },
    signatures: {
        ...initNormalizedState
    },
    itemTemplates: {
        error: null,
        response: {
            results: []
        }
    },
    partnerProtocol: {
        error: null,
        response: null
    }
};


type IIssuerReducer = {};

function handleIssueCreateRequest(state: IReducer, action: AnyAction) {
    const localId = getActionId(action);
    const issue = action.payload;
    if (issue.protocol_element && issue.protocol_element !== null) {
        const newIssue = {
            ...issue,
            id: localId
        }
        // for room element issues
        const elementId = issue.protocol_element;

        if (elementId == null) return state;
        
        const updatedIssues = [...state?.elements?.byIds[elementId]?.issues, newIssue];
        
        return {
            ...state,
            elements: {
                ...state.elements,
                byIds: {
                    ...state.elements.byIds,
                    [elementId]: {
                        ...state.elements.byIds[elementId],
                        issues: updatedIssues
                    }
                }
            }
        };
    } else {
        const newIssue = {
            ...issue,
            piss_id: localId
        }
        // for Item issues
        const itemId = issue.protocol_item;

        if (itemId == null) return state;

        const updatedIssues = [...state?.items?.byIds[itemId]?.issues, newIssue];
        
        return {
            ...state,
            items: {
                ...state.items,
                byIds: {
                    ...state.items.byIds,
                    [itemId]: {
                        ...state.items.byIds[itemId],
                        issues: updatedIssues
                    }
                }
            }
        }
    }
}

function handleIssueUpdateRequest(state: IReducer, action: AnyAction) {
    const localId = action.issueId;
    const issue = action.payload;
    if ( issue.protocol_element && issue.protocol_element !== null ) {
        const newIssue = {
            ...issue,
            id: localId
        }

        // for room element issues
        const elementId = issue.protocol_element;
        if (elementId == null) return state;
        
        const stateIssues = [...state.elements.byIds[elementId].issues];
        const matchedIssue = stateIssues.find(
            (element: { id: any }) => element.id === issue.id || element.id === localId
            );
        stateIssues.splice(stateIssues.indexOf(matchedIssue), 1, newIssue);
            
        return {
            ...state,
            elements: {
                ...state.elements,
                byIds: {
                    ...state.elements.byIds,
                    [elementId]: {
                        ...state.elements.byIds[elementId],
                        issues: stateIssues
                    }
                }
            }
        };
    } else {
        const newIssue = {
            ...issue,
            piss_id: localId
        }
        // for Item issues
        const itemId = issue.protocol_item;

        if (itemId == null) return state;

        const stateItemIssues = [...state.items.byIds[itemId].issues];
        const matchedItemIssue = stateItemIssues.find(
            (element: { piss_id: any }) => element.piss_id === issue.piss_id || element.piss_id == localId
            );

        stateItemIssues.splice(stateItemIssues.indexOf(matchedItemIssue), 1, newIssue);
        
        return {
            ...state,
            items: {
                ...state.items,
                byIds: {
                    ...state.items.byIds,
                    [itemId]: {
                        ...state.items.byIds[itemId],
                        issues: stateItemIssues
                    }
                }
            }
        };
    }
}

function handleIssueDeleteRequest (state: IReducer, action: AnyAction) {
    const id = action.issueId;
    
    if (id == null) return state;
    
    const elements = Object.values(state.elements.byIds);
    let isIssueFromElement = false;
    let matchedElement;
    for (let i = 0; i < elements.length; i++) {
        let element = elements[i];
        const matchFound = element.issues.find((issue: { id: number }) => {
            return issue.id === id;
        });
        if (matchFound) {
            matchedElement = element;
            isIssueFromElement = true
        }
    }
    
    if (isIssueFromElement){
        const elementId = matchedElement?.pele_id!;
        let updatedElements = { ...state.elements.byIds };
        if (updatedElements[elementId]) {
            updatedElements[elementId] = {
                ...updatedElements[elementId],
                issues: updatedElements[elementId].issues?.filter((issue: { id: number}) => issue.id !== id)
            };
        }

        return {
            ...state,
            elements: {
                ...state.elements,
                byIds: updatedElements
            }
        }
    } else {
        const items = Object.values(state.items.byIds);
        
        let matchedItem;
        for (let i = 0; i < items.length; i++) {
            let item = items[i];
            const matchFound = item.issues.find((issue: { piss_id: number }) => {
                return issue.piss_id === id;
            });
            if (matchFound) {
                matchedItem = item;
            }
        }
        const itemId = matchedItem?.pitm_id!;
        let updatedItems = { ...state.items.byIds };
        if (updatedItems[itemId]) {
            updatedItems[itemId] = {
                ...updatedItems[itemId],
                issues: updatedItems[itemId].issues?.filter((issue: { piss_id: number}) => issue.piss_id !== id)
            };
        }

        return {
            ...state,
            items: {
                ...state.items,
                byIds: updatedItems
            }
        }
    }
}

function handleIssueCreate(state: IReducer, action: AnyAction) {
    const { issue } = action.payload;
    const localId = getActionId(action)
    if (issue.protocol_element && issue.protocol_element !== null) {

        issue['id'] = issue['pk'];
        delete issue['pk'];
        const elementId = issue.protocol_element;
        if (elementId == null) return state;
        
        const stateIssues = [...state.elements.byIds[elementId].issues];
        const matchedIssue = stateIssues.find(
            (element: { id: any }) => element.id === localId
            );
        stateIssues.splice(stateIssues.indexOf(matchedIssue), 1, issue);
            
        return {
            ...state,
            elements: {
                ...state.elements,
                byIds: {
                    ...state.elements.byIds,
                    [elementId]: {
                        ...state.elements.byIds[elementId],
                        issues: stateIssues
                    }
                }
            }
        };
    } else {
        issue['piss_id'] = issue['pk'];
        delete issue['pk'];
        const itemId = issue.protocol_item;

        if (itemId == null) return state;

        let stateItemIssues = [...state.items.byIds[itemId].issues];
        const matchedItemIssue = stateItemIssues.find(
            (element: { piss_id: any }) => element.piss_id === itemId || element.piss_id === localId
            );

        if (matchedItemIssue) {
            stateItemIssues.splice(
                stateItemIssues.indexOf(matchedItemIssue),
                1,
                issue
            );
        } else {
            stateItemIssues.push(issue);
        }
        return {
            ...state,
            items: {
                ...state.items,
                byIds: {
                    ...state.items.byIds,
                    [itemId]: {
                        ...state.items.byIds[itemId],
                        issues: stateItemIssues
                    }
                }
            }
        };
    }
}

function handleIssueUpdate(state: IReducer, action: AnyAction) {
    const localId = action.issueId;
    const { issue } = action.payload;
    if ( issue.protocol_element && issue.protocol_element !== null ) {
        // for room element issues
        issue['id'] = issue['pk'];
        delete issue['pk'];
        const elementId = issue.protocol_element;
        if (elementId == null) return state;
        
        const stateIssues = [...state.elements.byIds[elementId].issues];
        const matchedIssue = stateIssues.find(
            (element: { id: any }) => element.id === issue.id || element.id === localId
            );
        stateIssues.splice(stateIssues.indexOf(matchedIssue), 1, issue);
            
        return {
            ...state,
            elements: {
                ...state.elements,
                byIds: {
                    ...state.elements.byIds,
                    [elementId]: {
                        ...state.elements.byIds[elementId],
                        issues: stateIssues
                    }
                }
            }
        };
    } else {
        // for Item issues
        issue['piss_id'] = issue['pk'];
        delete issue['pk'];
        const itemId = issue.protocol_item;

        if (itemId == null) return state;

        const stateItemIssues = [...state.items.byIds[itemId].issues];
        const matchedItemIssue = stateItemIssues.find(
            (element: { piss_id: any }) => element.piss_id === issue.piss_id || element.piss_id === localId
            );

        stateItemIssues.splice(stateItemIssues.indexOf(matchedItemIssue), 1, issue);
        
        return {
            ...state,
            items: {
                ...state.items,
                byIds: {
                    ...state.items.byIds,
                    [itemId]: {
                        ...state.items.byIds[itemId],
                        issues: stateItemIssues
                    }
                }
            }
        };
    }
}

function handleIssueDelete(state: IReducer, action: AnyAction) {
    const id = action.entityId;
    
    if (id == null) return state;
    
    const elements = Object.values(state.elements.byIds);
    let isIssueFromElement = false;
    let matchedElement;
    for (let i = 0; i < elements.length; i++) {
        let element = elements[i];
        const matchFound = element.issues.find((issue: { id: number }) => {
            return issue.id === id;
        });
        if (matchFound) {
            matchedElement = element;
            isIssueFromElement = true
        }
    }
    
    if (isIssueFromElement){
        const elementId = matchedElement?.pele_id!;
        let updatedElements = { ...state.elements.byIds };
        if (updatedElements[elementId]) {
            updatedElements[elementId] = {
                ...updatedElements[elementId],
                issues: updatedElements[elementId].issues?.filter((issue: { id: number}) => issue.id !== id)
            };
        }

        return {
            ...state,
            elements: {
                ...state.elements,
                byIds: updatedElements
            }
        }
    } else {
        const items = Object.values(state.items.byIds);
        
        let matchedItem;
        for (let i = 0; i < items.length; i++) {
            let item = items[i];
            const matchFound = item.issues.find((issue: { piss_id: number }) => {
                return issue.piss_id === id;
            });
            if (matchFound) {
                matchedItem = item;
            }
        }
        const itemId = matchedItem?.pitm_id!;
        let updatedItems = { ...state.items.byIds };
        if (updatedItems[itemId]) {
            updatedItems[itemId] = {
                ...updatedItems[itemId],
                issues: updatedItems[itemId].issues?.filter((issue: { piss_id: number}) => issue.piss_id !== id)
            };
        }

        return {
            ...state,
            items: {
                ...state.items,
                byIds: updatedItems
            }
        }
    }
}

type UpdatedProtocolActions =
    | IProtocolActions
    | RT<typeof patchMediaSuccess>
    | RT<typeof backgroundSyncSuccess>;

function protoReducer(
    state = initReducer,
    action: UpdatedProtocolActions
): IReducer {
    switch (action.type) {
        case acts.GET_PROTOCOLS_REQUEST: {
            return {
                ...state,
                protocols: {
                    ...onPaginateStart(state.protocols, action.paginated)
                }
            };
        }
        case acts.GET_PROTOCOLS_SUCCESS: {
            return {
                ...state,
                protocols: {
                    ...paginateStore(state.protocols, action.payload)
                }
            };
        }
        case acts.GET_PROTOCOLS_FAIL:
            return {
                ...state,
                protocols: {
                    ...state.protocols,
                    error: action.error
                }
            };
        case acts.GET_PROTOCOL_REQUEST:
            return {
                ...state,
                protocol: {
                    ...state.protocol,
                    error: null
                }
            };
        case acts.GET_PROTOCOL_SUCCESS: {
            const protocol = action.payload.entity; 

            return {
                ...state,
                protocol: {
                    ...state.protocol,
                    response: protocol,
                    isSynced: protocol.prt_status === 'done'
                }
            };
        }
        case acts.GET_PROTOCOL_FAIL:
            return {
                ...state,
                protocol: {
                    ...state.protocol,
                    error: action.error
                }
            };
        case acts.GET_PROTOCOL_FOR_LATEST_DATA_REQUEST:
            return {
                ...state,
                // protocol: {
                //     ...state.protocol,
                //     error: null
                // }
            };
        case acts.GET_PROTOCOL_FOR_LATEST_DATA_SUCCESS: {
            // const protocol = action.payload.entity; 

            return {
                ...state,
                // protocol: {
                //     ...state.protocol,
                //     response: protocol,
                //     isSynced: protocol.prt_status === 'done'
                // }
            };
        }
        case acts.GET_PROTOCOL_FOR_LATEST_DATA_FAIL:
            return {
                ...state,
                // protocol: {
                //     ...state.protocol,
                //     error: action.error
                // }
            };
        case acts.PATCH_PROTOCOL_REQUEST:
            const oldProtocol = state.protocol.response;
            const newProtocol = action.proto;

            if (action.protoId !== oldProtocol?.prt_id) return state;

            return {
                ...state,
                protocol: {
                    ...state.protocol,
                    response: {
                        ...oldProtocol,
                        ...newProtocol
                    }
                }
            };
        case acts.FINISH_PROTOCOL_REQUEST: {
            if (action.protoId !== state.protocol.response?.prt_id) {
                return state;
            }
            return {
                ...state,
                protocol: {
                    ...state.protocol,
                    response: {
                        ...state.protocol.response,
                        prt_status: 'done',
                        prt_current_step: 'done'
                    }
                }
            };
        }
        case acts.FINISH_PROTOCOL_SUCCESS: {
            const id = action.payload?.prt_id;
            const protocol = state.protocol.response;

            if (protocol == null || id == null || id !== protocol.prt_id)
                return state;

            return {
                ...state,
                protocol: {
                    ...state.protocol,
                    isSynced: true
                }
            };
        }
        case acts.FINISH_PROTOCOL_FAIL: {
            const protocol = state.protocol.response;

            if (protocol == null)
                return state;

            return {
                ...state,
                protocol: {
                    ...state.protocol,
                    response: {
                        ...protocol,
                        prt_status: 'locate',
                        prt_current_step: 'confirm'
                    },
                    isSynced: false
                }
            };
        }
        // --- Rooms
        case acts.GET_PROTOCOL_ROOMS_SUCCESS:
            const { roomsHash, roomsIds, elementsHash } = action.payload;

            return {
                ...state,
                rooms: {
                    ...state.rooms,
                    byIds: roomsHash,
                    allIds: roomsIds
                },
                elements: {
                    ...state.elements,
                    byIds: elementsHash // Note: do we need allIds?
                }
            };

        case acts.GET_PROTOCOL_ROOMS_FAIL: {
            return {
                ...state,
                rooms: {
                    ...state.rooms
                }
            };
        }
        case acts.POST_PROTOCOL_ROOM_REQUEST: {
            const localId = getActionId(action);

            // TODO: use factories for create POST requests
            const newRoom: IProtocolRoom = {
                ...action.room,
                prom_pele_id: [],
                prom_id: localId
            };

            return {
                ...state,
                rooms: {
                    ...state.rooms,
                    ...storeAdd(state.rooms, localId, newRoom)
                }
            };
        }
        // case 'POST_PROTOCOL_ROOM_SUCCESS': { // didn't work, reverted to old case
        case 'POST_PROTOCOL_ROOM_RESPONSE' as 'POST_PROTOCOL_ROOM_SUCCESS': {
            const { entity, protocol_elements } = action.payload;
            const realId = entity.prom_id;

            const localId = getActionId(action);

            if (realId == null) return state;

            return {
                ...state,
                rooms: {
                    ...state.rooms,
                    ...storeReplaceId(state.rooms, localId, realId, entity)
                },
                elements: {
                    ...state.elements,
                    byIds: {
                        ...state.elements.byIds,
                        ...protocol_elements
                    }
                }
            };
        }
        case acts.POST_PROTOCOL_ROOM_FAIL: {
            return {
                ...state,
                rooms: {
                    ...state.rooms,
                    ...storePostFail(state.rooms, action)
                }
            };
        }
        case acts.PATCH_PROTOCOL_ROOM_REQUEST: {
            return {
                ...state,
                rooms: {
                    ...state.rooms,
                    ...storeUpdate(state.rooms, action.id, action.payload)
                }
            };
        }
        // case 'PATCH_PROTOCOL_ROOM_SUCCESS': { // didn't work, reverted to old case
        case 'PATCH_PROTOCOL_ROOM_RESPONSE' as 'PATCH_PROTOCOL_ROOM_SUCCESS': {
            const { entity, protocol_elements } = action.payload;
            return {
                ...state,
                rooms: {
                    ...state.rooms,
                    ...storeUpdate(state.rooms, entity.prom_id, entity)
                },
                elements: {
                    ...state.elements,
                    byIds: {
                        ...state.elements.byIds,
                        ...protocol_elements
                    }
                }
            };
        }
        case acts.DELETE_PROTOCOL_ROOM_REQUEST: {
            return {
                ...state,
                rooms: {
                    ...state.rooms,
                    ...storeDelete(state.rooms, action.id)
                }
            };
        }
        // --- Rooms end

        // --- Items
        case acts.GET_PROTOCOL_ITEMS_SUCCESS: {
            const { store } = action.payload;
            return {
                ...state,
                items: {
                    ...state.items,
                    ...store
                }
            };
        }
        case acts.GET_PROTOCOL_ITEM_SUCCESS: {
            const { entity } = action.payload;
            return {
                ...state,
                items: {
                    ...state.items,
                    ...storeAddOrUpdate(state.items, entity.pitm_id, entity)
                }
            };
        }
        case acts.POST_PROTOCOL_ITEM_REQUEST: {
            const itemId = getActionId(action);
            const item: IProtocolItem = {
                ...action.payload,
                pitm_id: itemId,
                issues: [],
                uploads: action.payload.media_med_id || []
            };

            return {
                ...state,
                items: {
                    ...state.items,
                    ...storeAdd(state.items, itemId, item)
                }
            };
        }
        // case 'POST_PROTOCOL_ITEM_SUCCESS': {  // didn't work, reverted to old case
        case 'POST_PROTOCOL_ITEM_RESPONSE' as 'POST_PROTOCOL_ITEM_SUCCESS': { 
            const { entity } = action.payload;
            const realId = entity.pitm_id;
            const localId = getActionId(action);

            if (realId == null) return state;

            const synchedItem = {
                ...entity,
                pitm_id: realId
            };

            return {
                ...state,
                items: {
                    ...state.items,
                    ...storeReplaceId(state.items, localId, realId, synchedItem)
                }
            };
        }
        case acts.POST_PROTOCOL_ITEM_FAIL: {
            return {
                ...state,
                items: {
                    ...state.items,
                    ...storePostFail(state.items, action)
                }
            };
        }
        case acts.PATCH_PROTOCOL_ITEM_REQUEST: {
            const item = action.payload;
            const updatedItem = {
                ...item,
                uploads: item.media_med_id || []
            };

            return {
                ...state,
                items: {
                    ...state.items,
                    ...storeUpdate(state.items, action.itemId, updatedItem)
                }
            };
        }
        // case 'PATCH_PROTOCOL_ITEM_SUCCESS': { // didn't work, reverted to old case
        case 'PATCH_PROTOCOL_ITEM_RESPONSE' as 'PATCH_PROTOCOL_ITEM_SUCCESS': {
            const { entity } = action.payload;
            const itemId = entity.pitm_id;

            return {
                ...state,
                items: {
                    ...state.items,
                    ...storeUpdate(state.items, itemId, entity)
                }
            };
        }
        case acts.DELETE_PROTOCOL_ITEM_REQUEST: {
            return {
                ...state,
                items: {
                    ...state.items,
                    ...storeDelete(state.items, action.itemId)
                }
            };
        }
        // --- Items end

        case acts.POST_ATTENDEE_REQUEST: {
            const {
                att_email,
                att_firstname,
                att_lastname,
                att_phone,
                address,
                att_picture,
                ...otherParams
            } = action.attendee;
            const localId = getActionId(action);

            // TOOD: dates, convert all the dates, media, etc in reducers to avoid the need to deal with backend formats with these optimistic updates
            const attendee: IAttendee = {
                att_id: localId,
                att_present: true,
                att_contact_details: {
                    address: address as any, //FIXME types
                    email: att_email,
                    first_name: att_firstname,
                    last_name: att_lastname,
                    phone: att_phone
                },
                ...otherParams,
                signatures: [],
                uploads: att_picture != null ? [att_picture] : []
            };

            return {
                ...state,
                attendees: {
                    ...state.attendees,
                    ...storeAdd(state.attendees, localId, attendee)
                }
            };
        }
        // case 'POST_ATTENDEE_SUCCESS': { // didn't work for attendee's list, reverted to old case
        case 'POST_ATTENDEE_RESPONSE' as 'POST_ATTENDEE_SUCCESS': {
            const localId = getActionId(action);

            const { attendee } = action.payload;
            const realId = attendee.att_id;

            const byIds = {
                ...state.attendees.byIds,
                [realId]: attendee,
                [localId]: undefined
            };

            const allIds = replacePrimitve(
                state.attendees.allIds,
                localId,
                realId
            );

            return {
                ...state,
                attendees: {
                    ...state.attendees,
                    allIds,
                    byIds
                }
            };
        }
        case acts.POST_ATTENDEE_FAIL: {
            return {
                ...state,
                attendees: {
                    ...state.attendees,
                    ...storePostFail(state.attendees, action)
                }
            };
        }
        // NOTE: almost a duplicate of POST & messy
        // TODO: cleanup with factory pattern
        case acts.PATCH_ATTENDEE_REQUEST: {
            const {
                att_email,
                att_firstname,
                att_lastname,
                att_phone,
                address,
                att_type,
                att_picture,
                ...otherParams
            } = action.attendee;

            const currentTenant = state.attendees.byIds[action.id];
            const currentTenantDetails =
                currentTenant.att_contact_details || {};

            const type =
                currentTenant.att_type !== ATTENDEE_TYPE_LANDLORD
                    ? att_type
                    : ATTENDEE_TYPE_LANDLORD;
            const newType = type || currentTenant.att_type;
            const attendee = {
                att_id: action.id, // Disallow changin the type of landlord
                att_type: newType,
                att_contact_details: {
                    address: (address as any) || currentTenantDetails.address, //FIXME types
                    email: att_email || currentTenantDetails.email,
                    first_name:
                        att_firstname || currentTenantDetails.first_name,
                    last_name: att_lastname || currentTenantDetails.last_name,
                    phone: att_phone || currentTenantDetails.phone
                },
                uploads:
                    att_picture != null ? [att_picture] : currentTenant.uploads,
                ...otherParams
            };

            const newAttendee: IAttendee = {
                ...currentTenant,
                ...attendee,
                att_contact_details: {
                    ...currentTenantDetails,
                    ...attendee.att_contact_details
                }
            };

            return {
                ...state,
                attendees: {
                    ...state.attendees,
                    byIds: {
                        ...state.attendees.byIds,
                        [action.id]: newAttendee
                    }
                }
            };
        }
        // case 'PATCH_ATTENDEE_SUCCESS': { // didn't work, reverted to old case
        case 'PATCH_ATTENDEE_RESPONSE' as 'PATCH_ATTENDEE_SUCCESS': {
            const { attendee, signatures } = action.payload;
            const attId = attendee.att_id;

            return {
                ...state,
                attendees: {
                    ...state.attendees,
                    ...storeUpdate(state.attendees, attId, attendee)
                },
                signatures: {
                    ...state.signatures,
                    byIds: {
                        ...state.signatures.byIds,
                        ...signatures
                    }
                }
            };
        }
        case acts.DEL_ATTENDEE_REQUEST:
            return {
                ...state,
                attendees: {
                    ...state.attendees,
                    ...storeDelete(state.attendees, action.id)
                }
            };
        case acts.GET_ATTENDEES_SUCCESS: {
            const { store, signatureStore } = action.payload;
            return {
                ...state,
                attendees: {
                    ...state.attendees,
                    ...store
                },
                signatures: {
                    ...state.signatures,
                    ...signatureStore
                }
            };
        }
        case acts.POST_SIGNATURE_REQUEST: {
            const localId = getActionId(action);

            const { signature } = action;

            const newSignature: ISignature = {
                ...signature,
                id: localId,
                uploads:
                    signature.media_id != null
                        ? [signature.media_id]
                        : []
            };

            return {
                ...state,
                signatures: {
                    ...state.signatures,
                    ...storeAdd(state.signatures, localId, newSignature)
                },
                attendees: {
                    ...state.attendees,
                    ...storeAddAssociation(
                        state.attendees,
                        signature.attendee_id,
                        'signatures',
                        localId
                    )
                }
            };
        }
        // case 'POST_SIGNATURE_SUCCESS': { // worked out solution to get rid of data overWrite | look for a better alternative.
        case 'POST_SIGNATURE_RESPONSE' as 'POST_SIGNATURE_SUCCESS': { // causing data overwrite | removed this before, but didn't help | look for a better alternative for "_RESPONSE" actions
            const localId = getActionId(action);
            const { entity } = action.payload;

            return {
                ...state,
                signatures: {
                    ...state.signatures,
                    ...storeReplaceId(
                        state.signatures,
                        localId,
                        entity?.id,
                        entity
                    )
                },
                attendees: {
                    ...state.attendees,
                    ...storeUpdateAssociation(
                        state.attendees,
                        entity.attendee_id,
                        'signatures',
                        localId,
                        entity?.id
                    )
                }
            };
        }
        case acts.DELETE_SIGNATURE_REQUEST: {
            return {
                ...state,
                signatures: {
                    ...state.signatures,
                    ...storeDelete(state.signatures, action.signId)
                },
                attendees: {
                    ...state.signatures,
                    ...storeDeleteAssociation(
                        state.attendees,
                        state.signatures,
                        'signatures',
                        'attendee_id',
                        action.signId
                    )
                }
            };
        }
        case acts.CREATE_LOCAL_PROTOCOL_ELEMENT:
        case acts.POST_PROTOCOL_ELEMENT_REQUEST: {
            const elementId = getActionId(action);

            const protocolId =
                state.rooms.byIds[action.payload.pele_prom_id]?.prom_prt_id;
            const protocol = state.protocol.response;

            const element: IProtocolElement = {
                ...action.payload,
                pele_id: elementId,
                issues: [],
                uploads: action.payload.media_med_id || []
            };
            const roomId = element.pele_prom_id as number;

            return {
                ...state,
                elements: {
                    ...state.elements,
                    ...storeAdd(state.elements, elementId, element)
                },
                rooms: {
                    ...state.rooms,
                    ...storeAddAssociation(
                        state.rooms,
                        roomId,
                        'prom_pele_id',
                        elementId
                    )
                }
            };
        }
        // case 'POST_PROTOCOL_ELEMENT_SUCCESS': { // didn't work, reverted to old case
        case 'POST_PROTOCOL_ELEMENT_RESPONSE' as 'POST_PROTOCOL_ELEMENT_SUCCESS': {
            const { entity } = action.payload;
            const realId = entity.pele_id;
            const localId = getActionId(action);

            if (realId == null) return state;

            return {
                ...state,
                elements: {
                    ...state.elements,
                    ...storeReplaceId(state.elements, localId, realId, entity)
                },
                rooms: {
                    ...state.rooms,
                    ...storeUpdateAssociation(
                        state.rooms,
                        entity.pele_prom_id,
                        'prom_pele_id',
                        localId,
                        realId
                    )
                }
            };
        }

        case acts.POST_PROTOCOL_ELEMENT_FAIL: {
            const localElementId = getActionId(action);

            return {
                ...state,
                elements: {
                    ...state.elements,
                    ...storePostFail(state.elements, action)
                },
                rooms: {
                    ...state.rooms,
                    ...storeDeleteAssociation(
                        state.rooms,
                        state.elements,
                        'prom_pele_id',
                        'pele_prom_id',
                        localElementId
                    )
                }
            };
        }
        case acts.PATCH_PROTOCOL_ELEMENT_REQUEST:
            const element = action.payload;
            const changes = {
                ...element,
                uploads: element.media_med_id || []
            };
            return {
                ...state,
                elements: {
                    ...state.elements,
                    ...storeUpdate(state.elements, action.protoEleId, changes)
                }
            };
        // case 'PATCH_PROTOCOL_ELEMENT_SUCCESS': { // didn't work, reverted to old case
        case 'PATCH_PROTOCOL_ELEMENT_RESPONSE' as 'PATCH_PROTOCOL_ELEMENT_SUCCESS': {
            const { entity } = action.payload;

            // NOTE: workaround to simulate exact backend behavior
            // (i.e prom_ok is not derived from child elements correctly)
            // TODO: ask backend team to fix
            const room = state.rooms.byIds[entity.pele_prom_id];

            const elementState = {
                ...state.elements,
                ...storeUpdate(state.elements, entity.pele_id, entity)
            };

            if (room != null) {
                const isOk = entity.pele_ok
                    ? room.prom_pele_id
                          ?.map((elementId) => state.elements.byIds[elementId])
                          .filter(
                              (element) =>
                                  element && element.pele_id !== entity.pele_id
                          )
                          .every((element) => Boolean(element.pele_ok))
                    : false;

                return {
                    ...state,
                    elements: elementState,
                    rooms: {
                        ...state.rooms,
                        ...storeUpdate(state.rooms, room.prom_id, {
                            prom_ok: isOk
                        })
                    }
                };
            }

            return {
                ...state,
                elements: elementState
            };
        }
        case acts.DELETE_PROTOCOL_ELEMENT_REQUEST:
            return {
                ...state,
                elements: {
                    ...state.elements,
                    ...storeDelete(state.elements, action.id)
                },
                rooms: {
                    ...state.rooms,
                    ...storeDeleteAssociation(
                        state.rooms,
                        state.elements,
                        'prom_pele_id',
                        'pele_prom_id',
                        action.id
                    )
                }
            };
        case acts.POST_PROTOCOL_ELEMENT_ATTRIBUTES_REQUEST: {
            const elementId = action.payload.pela_pele_id;
            const element = state.elements.byIds[elementId];

            const arributes: IProtocolElementAttr[] =
                action.payload.pela_state.map((attr, index) => ({
                    ...attr,
                    pela_id: -index
                }));

            const updatedElement = {
                ...element,
                pele_pela_id: arributes
            };

            return {
                ...state,
                elements: {
                    ...state.elements,
                    byIds: {
                        ...state.elements.byIds,
                        [elementId]: updatedElement
                    }
                }
            };
        }
        // Note 1: This endpoint should have error handling as 'edit' not 'create', if neccessary could renamed
        case acts.POST_PROTOCOL_ELEMENT_ATTRIBUTES_SUCCESS: {
            const data = action.payload;
            if (data == null) return state;

            const attrs = data.state;
            const id = data.pela_pele_id;

            return {
                ...state,
                elements: {
                    ...state.elements,
                    ...storeUpdate(state.elements, id, { pele_pela_id: attrs })
                }
            };
        }
        case acts.POST_PROTOCOL_ITEM_ATTRIBUTES_REQUEST:
        case acts.PREDICT_PROTOCOL_ITEM_ATTRIBUTES: {
            const data = action.payload;

            const itemId = data.pita_pitm_id;
            const attrs: IProtocolItemAttr[] = data.pita_state.map(
                (attr, index) => ({
                    ...attr,
                    pita_id: -index
                })
            );

            return {
                ...state,
                items: {
                    ...state.items,
                    ...storeUpdate(state.items, itemId, { pitm_pita_id: attrs })
                }
            };
        }
        // Note: syncing might not be 100% neccessary, see comment above
        case acts.POST_PROTOCOL_ITEM_ATTRIBUTES_SUCCESS: {
            const data = action.payload;
            if (data == null) return state;

            const attrs = data.state;
            const id = data.pita_pitm_id;

            return {
                ...state,
                items: {
                    ...state.items,
                    ...storeUpdate(state.items, id, { pitm_pita_id: attrs })
                }
            };
        }
        case PATCH_MEDIA_SUCCESS: {
            const currentProtocol = state.protocol.response;
            if (
                currentProtocol &&
                currentProtocol.prt_id === action.payload.med_prt_id
            ) {
                return {
                    ...state,
                    protocol: {
                        ...state.protocol,
                        response: {
                            ...currentProtocol,
                            uploads: currentProtocol?.uploads?.concat(
                                action.payload.med_id
                            )
                        }
                    }
                };
            }
            return state;
        }
        case acts.GET_ITEM_TEMPLATES_SUCCESS:
            return {
                ...state,
                itemTemplates: {
                    ...state.itemTemplates,
                    response: action.payload
                }
            };
        case acts.GET_ITEM_TEMPLATES_FAIL:
            return {
                ...state,
                itemTemplates: {
                    ...state.itemTemplates,
                    error: action.error
                }
            };
        case acts.AUTHORIZE_PARTNER_PROTOCOL_REQUEST: {
            return {
                ...state,
                partnerProtocol: {
                    ...state.partnerProtocol,
                    error: null
                }
            };
        }
        case acts.AUTHORIZE_PARTNER_PROTOCOL_SUCCESS: {
            return {
                ...state,
                partnerProtocol: {
                    ...state.partnerProtocol,
                    response: action.payload.data
                }
            };
        }
        case acts.AUTHORIZE_PARTNER_PROTOCOL_FAIL: {
            return {
                ...state,
                partnerProtocol: {
                    ...state.partnerProtocol,
                    error: action.error
                }
            };
        }
        case acts.GET_ATTENDEE_SUCCESS: {
            const { attendee } = action.payload;
            return {
                ...state,
                attendees: {
                    ...state.attendees,
                    ...storeAddOrUpdate(
                        state.attendees,
                        attendee.att_id,
                        attendee
                    )
                }
            };
        }
        case acts.GET_PROTOCOL_ROOM_SUCCESS: {
            const { entity, protocol_elements } = action.payload;
            return {
                ...state,
                rooms: {
                    ...state.rooms,
                    ...storeAddOrUpdate(state.rooms, entity.prom_id, entity)
                },
                elements: {
                    ...state.elements,
                    byIds: {
                        ...state.elements.byIds,
                        ...protocol_elements
                    }
                }
            };
        }
        case acts.GET_PROTOCOL_ELEMENT_SUCCESS: {
            const { entity } = action.payload; // Need parent id to fully 'add' in the tree
            return {
                ...state,
                elements: {
                    ...state.elements,
                    ...storeAddOrUpdate(state.elements, entity.pele_id, entity)
                }
            };
        }
        case acts.CHECK_PDF_READY_SUCCESS: {
            const { result } = action.payload;

            const oldProtocol = state.protocol.response;
            if (!oldProtocol) return state;

            return {
                ...state,
                protocol: {
                    ...state.protocol,
                    response: {
                        ...oldProtocol,
                        protocol_pdf: result
                    }
                }
            };
        }

        case acts.PREVIEW_PDF_READY_SUCCESS: {
            const { html } = action.payload;
            const oldProtocol = state.protocol.response;
            if (!oldProtocol) return state;
            return {
                ...state,
                protocol: {
                    ...state.protocol,
                    protocolPreviewHtml: html
                }
            };
        }

        case acts.SET_HEADER_PROTOCOL_PREVIEW: {
            const oldProtocol = state.protocol.response;
            if (!oldProtocol) return state;
            return {
                ...state,
                protocol: {
                    ...state.protocol,
                    isHeaderProtocolPreview: action.payload
                }
            };
        }

        case acts.PREDICT_PROTO_ROOM: {
            const { byIds } = state.rooms;
            if (!byIds.hasOwnProperty(action.roomId)) return state;

            return {
                ...state,
                rooms: {
                    ...state.rooms,
                    byIds: {
                        ...state.rooms.byIds,
                        [action.roomId]: {
                            ...byIds[action.roomId],
                            ...action.payload
                        }
                    }
                }
            };
        }

        case acts.PREDICT_PROTO_ELE: {
            const { byIds } = state.elements;
            if (!byIds.hasOwnProperty(action.eleId)) return state;

            return {
                ...state,
                elements: {
                    ...state.elements,
                    byIds: {
                        ...state.elements.byIds,
                        [action.eleId]: {
                            ...byIds[action.eleId],
                            ...action.payload
                        }
                    }
                }
            };
        }

        case acts.PREDICT_PROTOCOL_ELEMENTS: {
            const ids = action.elements.map((element) => element.pele_id);
            const updatedElesHash: IHash<IProtocolElement> = {};

            ids.forEach((id, index) => {
                if(id) {
                    updatedElesHash[id] = {
                        ...state.elements.byIds[id],
                        ...action.elements[index]
                    };
                }
            });
            return {
                ...state,
                elements: {
                    ...state.elements,
                    byIds: {
                        ...state.elements.byIds,
                        ...updatedElesHash
                    }
                }
            };
        }

        case acts.PREDICT_PROTOCOL_ELEMENTS_ORDER: {
            const { roomId, elements } = action.payload;
            const ids = elements.map((element) => element.pele_id);
            const room = state.rooms.byIds[roomId];
            const updatedRoom = { ...room, prom_pele_id: ids };
            return {
                ...state,
                rooms: {
                    ...state.rooms,
                    byIds: { ...state.rooms.byIds, [roomId]: updatedRoom }
                }
            };
        }

        case acts.PREDICT_PROTOCOL_ROOMS_ORDER: {
            const ids = action.rooms.map((room) => room.prom_id);

            return {
                ...state,
                rooms: {
                    ...state.rooms,
                    allIds: ids
                }
            };
        }

        case acts.PREDICT_PROTOCOL_ROOMS: {
            const ids = action.rooms.map((room) => room.prom_id);
            const updatedRoomHash: IHash<IProtocolRoom> = {};
            ids.forEach((id, index) => {
                updatedRoomHash[id] = {
                    ...action.rooms[index]
                };
            });
            return {
                ...state,
                rooms: {
                    ...state.rooms,
                    byIds: {
                        ...state.rooms.byIds,
                        ...updatedRoomHash
                    }
                }
            };
        }

        case acts.PREDICT_ATTENDEE: {
            // unused
            const prevAttendee = state.attendees.byIds[action.attId];
            if (!prevAttendee) return state;

            return {
                ...state,
                attendees: {
                    ...state.attendees,
                    byIds: {
                        ...state.attendees.byIds,
                        [action.attId]: {
                            ...prevAttendee,
                            ...action.payload
                        }
                    }
                }
            };
        }
        case acts.PREDICT_CURRNET_PROTOCOL: {
            const newProtocol = state.protocol.response
                ? { ...state.protocol.response, ...action.payload }
                : null;
            return {
                ...state,
                protocol: {
                    ...state.protocol,
                    response: newProtocol
                }
            };
        }
        case acts.PREDICT_CURRENT_PROTOCOL_DPD: {
            if (!state.protocol.response) return state;

            const prevProperty = state.protocol.response?.prt_dpd_id;
            const newDpd = prevProperty
                ? { ...prevProperty, ...action.payload }
                : undefined;

            return {
                ...state,
                protocol: {
                    ...state.protocol,
                    response: {
                        ...state.protocol.response,
                        prt_dpd_id: newDpd
                    }
                }
            };
        }
        case acts.POST_PROTOCOL_ISSUE_REQUEST: {
            return handleIssueCreateRequest(state, action);
        }
        case acts.PATCH_PROTOCOL_ISSUE_REQUEST: {
            return handleIssueUpdateRequest(state, action);
        }
        case acts.DELETE_PROTOCOL_ISSUE_REQUEST: {
            return handleIssueDeleteRequest(state, action);
        }
        case acts.POST_PROTOCOL_ISSUE_RESPONSE: { 
            return handleIssueCreate(state, action);
        }
        case acts.PATCH_PROTOCOL_ISSUE_RESPONSE: { 
            return handleIssueUpdate(state, action);
        }
        case acts.DELETE_PROTOCOL_ISSUE_SUCCESS: {
            return handleIssueDelete(state, action);
        }
        /*
    case acts.BACKGROUND_SYNC_SUCCESS: {
      const protocol = state.protocol.response;

      if (protocol == null) return state;

      return {
        ...state,
        protocol: {
          ...state.protocol,
          isSynced: true
        }
      }
    }*/
    }
    return state;
}

export default protoReducer;
function countersOnIssueCreate(state: IReducer, action: AnyAction) {
    throw new Error('Function not implemented.');
}
