interface IIterms {
    name: string;
    choices: { title: string; value: string }[];
}

export function transformer(items: IIterms[]) {
    const ids: Record<string, number> = {
        building_types: 102,
        room_types: 105,
        element_types: 106,
        element_attribute_types: 107,
        room_attribute_types: 108,
        communication_types: 110,
        media_types: 112,
        protocol_types: 113,
        currency_type: 114,
        issue_handling_types: 115,
        issue_category_types: 116,
        issue_statuses: 117,
        issue_grades: 118,
        object_types: 120,
        item_types: 121,
        item_attribute_types: 122,
        dpd_attribute_types: 123,
        company_roles: 124,
        invitation_types: 125,
        cost_bearer: 126,
        persona_types: 127,
        protocol_tracker_status: 128,
        attendee_types: 129
    };

    const capitalize = (s: string) => {
        return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
    };

    const formatBasName = (name: string) => {
        const formatted = name
            .split('_')
            .map((a) => {
                if (a.toLowerCase() === 'types') {
                    return capitalize(a.slice(0, -1));
                } else {
                    return capitalize(a);
                }
            })
            .join('_');
        return formatted;
    };

    const baseItemTitles = items.map((i: any) => ({
        bas_grp_id: 0,
        bas_term: i.name === 'object_types' ? 120 : ids[i.name],
        bas_text1:
            i.name === 'object_types' ? 'SubDPD_Type' : formatBasName(i.name)
    }));

    const transArray: any[] = [];
    for (let i = 0; i < items.length; i++) {
        const item = items[i];
        // const itemKey: string = item.name;
        const bas_grp_id = ids[item.name];
        item.choices.forEach((itm: any) => {
            transArray.push({
                bas_grp_id,
                bas_term: itm.value,
                bas_text1: itm.title
            });
        });
    }

    return [...baseItemTitles, ...transArray];
    // return JSON.stringify([...baseItemTitles, ...transArray]);
    // return JSON.stringify(items);
}
