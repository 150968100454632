import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'validator';
import { useDispatch } from 'react-redux';

import useInput from '../../../hooks/useInput';
import useValidation from '../../../hooks/useValidation';
import { validate } from '../../../utils/validation';
import { dispatchAsync } from '../../../store/storeModule';
import { showSnackbar, patchAccount } from '../../../store/action-creators';

import StdModal from '../../atoms/StdModal';
import StdTextField from '../../atoms/StdTextField';
import StdButton from '../../atoms/StdButton';
import ModalHeader from '../../atoms/ModalHeader';
import CustomIcon from '../../atoms/CustomIcon';

interface IProps extends IModalBase {
    editedName?: string;
    onSubmit?: ICallback;
}

interface IForm {
    form: {
        name: string;
    };
    errors: IErrorHash;
}

const initForm: IForm = {
    form: {
        name: ''
    },
    errors: {}
};

const EditNameModal: React.FC<IProps> = ({
    editedName,
    open,
    onClose,
    onSubmit
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [input, setInput] = useState(initForm);

    useEffect(() => {
        if (editedName) {
            setInput((s) => ({
                ...s,
                form: {
                    ...s.form,
                    name: editedName
                }
            }));
        }
    }, [editedName]);
    const handleInput = useInput(setInput);

    const validateFields = useValidation(input, setInput, (hash) => {
        const { name } = input.form;
        return [validate({ name }, !isEmpty(name), hash)].every(Boolean);
    });

    const makeRequest = () => {
        if (validateFields()) {
            const { name } = input.form;
            dispatchAsync(dispatch, patchAccount({ fullname: name }))
                .then(onSubmit)
                .catch(({ error }) =>
                    dispatch(
                        showSnackbar({ message: 'change_name_error', error })
                    )
                );
            onClose();
        }
    };

    const { form, errors } = input;
    return (
        <StdModal open={open} onClose={onClose} small>
            <ModalHeader
                leftNode={t('edit_name')}
                rightNode={<CustomIcon name="cross" onClick={onClose} ripple />}
            />
            <div className="modal__section std-pt">
                <StdTextField
                    className="inline-m"
                    label={t('name')}
                    value={form.name}
                    name="name"
                    error={errors['name']}
                    width="100%"
                    onChange={handleInput}
                />
                <div className="button-wrapper std-flex-between std-mt">
                    <StdButton width="120px" onClick={onClose}>
                        {t('cancel')}
                    </StdButton>
                    <StdButton
                        width={'120px'}
                        type="primary"
                        onClick={makeRequest}
                    >
                        {t('save')}
                    </StdButton>
                </div>
            </div>
        </StdModal>
    );
};

export default EditNameModal;
