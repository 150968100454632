import React from 'react';
import { useTranslation } from 'react-i18next';

import StdButton from '../../atoms/StdButton';

import './existing-tenant-card.scss';

interface IProps {
    email: string;
    name: string;
    onClick: ICallback;
}

const ExistingTenantCard: React.FC<IProps> = ({ email, name, onClick }) => {
    const { t } = useTranslation();

    const title = `${t('user_with')} ${email} ${t('already_exists')}`;
    return (
        <div className="existing-tenant-card">
            <div className="stack-s">{title}</div>
            <StdButton className="inline-xs" type="primary" onClick={onClick}>
                {t('add')} {name}
            </StdButton>
        </div>
    );
};

export default ExistingTenantCard;
