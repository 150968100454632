import React from 'react';

import { useTranslation } from 'react-i18next';
import StdListItem from '../atoms/StdListItem';

interface IProps extends IClass {
    resolvedCount: number;
    unresolvedCount: number;
    excessiveCount: number;
    lightCount: number;
    filter?: IIssueFilter;
    onFilterChange: (filter: IIssueFilter) => void;
}

const IssueTab: React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    const {
        className,
        filter,
        resolvedCount,
        unresolvedCount,
        excessiveCount,
        lightCount,
        onFilterChange
    } = props;

    return (
        <div className={className}>
            <span className="sidebar-title">{t('all').toUpperCase()}</span>
            <StdListItem
                icon="question-circle"
                textData={{
                    main: t('issue_category.unresolved'),
                    sub: `${unresolvedCount} ${unresolvedCount > 1 ? t('issues') : t('issue')}`
                }}
                onClick={() => onFilterChange('unresolved')}
                active={filter === 'unresolved'}
            />
            <StdListItem
                className="stack-xl"
                icon="check-circle"
                textData={{
                    main: t('issue_category.resolved'),
                    sub: `${resolvedCount} ${resolvedCount > 1 ? t('issues') : t('issue')}`
                }}
                onClick={() => onFilterChange('resolved')}
                active={filter === 'resolved'}
            />
            <span className="sidebar-title">
                {t('by_category').toUpperCase()}
            </span>
            <StdListItem
                icon="exclamation-triangle"
                textData={{
                    main: t('light_wear'),
                    sub: `${lightCount} ${lightCount > 1 ? t('issues') : t('issue')}`
                }}
                onClick={() => onFilterChange('light')}
                active={filter === 'light'}
            />
            <StdListItem
                icon="fragile"
                textData={{
                    main: t('excessive_wear'),
                    sub: `${excessiveCount} ${excessiveCount > 1 ? t('issues') : t('issue')}`
                }}
                onClick={() => onFilterChange('excessive')}
                active={filter === 'excessive'}
            />
        </div>
    );
};

export default IssueTab;
