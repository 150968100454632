import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import './std-link.scss';

interface IProps extends IClass {
    to?: string;
    children?: ReactNode;
    nowrap?: boolean;
}

const StdLink: React.FC<IProps> = ({ className, to, children, nowrap }) => {
    const linkClass = clsx('std-link', nowrap && 'std-link--nowrap', className);

    if (!to) {
        return (
            <span className={clsx(linkClass, 'std-link--nolink')}>
                {children}
            </span>
        );
    }

    return (
        <Link to={to} className={linkClass}>
            {children}
        </Link>
    );
};

export default StdLink;
