import { useState, useCallback } from 'react';
import { isEmpty } from 'validator';

import {
    INT_TYPE_REGEX,
    DPD_NUMBER_OF_FLOORS_REGEX,
    STREET_NUMBER_REGEX
} from '../configs/costants';
import { validate } from '../utils/validation';

import useValidation from './useValidation';
import useInput from './useInput';

interface IForm {
    form: IAddressForm;
    errors: IErrorHash;
}

export const initForm: IForm = {
    form: {
        street: '',
        zip: '',
        city: '',
        address: '',
        country: '',
        street_number: '',
        address_extra: {
            commune: '',
            region: '',
            country: '',
            lat: undefined,
            lng: undefined
        }
    },
    errors: {}
};

// TODO: Move into AddressEditForm component folder
const useAddressEdit = () => {
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [dirty, setDirty] = useState(false);
    const [input, setInput] = useState(initForm);

    const handleInput = useInput(setInput, (name, value, newState) => {
        if (name === 'floor' && !DPD_NUMBER_OF_FLOORS_REGEX.test(value))
            return input;
        if (name === 'ewid' && !INT_TYPE_REGEX.test(value)) return input;
        if (
            name === 'street' ||
            name === 'city' ||
            name === 'street_number' ||
            name === 'zip' ||
            name === 'country'
        )
            setDirty(true);
        return newState;
    });

    const validateAddress = useValidation(input, setInput, (hash) => {
        const { street, street_number, city, zip, country } = input.form;

        return [
            validate({ street }, !isEmpty(street.trim()), hash),
            validate(
                { street_number },
                STREET_NUMBER_REGEX.test(street_number.trim()),
                hash
            ),
            validate({ city }, !isEmpty(city.trim()), hash),
            validate({ country }, !isEmpty(country.trim()), hash),
            validate({ zip }, !isEmpty(zip.trim()), hash)
        ].every(Boolean);
    });

    // Note: maybe return the full thing
    const getAddressForm = useCallback(() => {
        return input.form;
    }, [input]);

    const addressCleanup = useCallback(() => {
        setInput(initForm);
        setConfirmOpen(false);
        setDirty(false);
    }, []);

    return {
        input,
        dirty,
        confirmOpen,
        addressCleanup,
        setConfirmOpen,
        setDirty,
        setInput,
        handleInput,
        validateAddress,
        getAddressForm
    };
};

export type IUseAddressEdit = RT<typeof useAddressEdit>;
export type IUseAddressEditProps = Omit<
    IUseAddressEdit,
    'validateAddress' | 'getAddressForm' | 'addressCleanup'
>;
export default useAddressEdit;
