import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { AnimatePresence, motion } from 'framer-motion';

import {
    ANIMATE_HEIGHT_PROPS,
    OFFLINE_MESSAGE_DELAY
} from '../../../configs/costants';

import {
    handleOnlineSwitch,
    handleDiscardSync,
    isPathSupported
} from '../../../modules/serviceWorker';

import { isServiceWorkerRunning } from '../../../utils';
import { calculateAverage } from '../../../modules/array';
import { bytesToMB } from '../../../modules/file';

import useWaitTimeout from '../../../hooks/useWaitTimeout';

import OfflineBar from '../../offline/OfflineBar';
import {
    normaliseOfflineProtocolMedia,
    setOfflineProtocolSyncingState
} from '../../../store/action-creators';

function insertError(error: string | null): ISyncStatus {
    return {
        status: 'sync-fail',
        error: error
    };
}

const OfflineIndicator: React.FC = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const [runningSyncSuccess, setRunningSyncSucess] = useState(false);

    const isOffline = useSelector((state) => state.global.isOffline);
    const useBeta = useSelector((state) => state.user.user.response?.use_beta);
    const syncStatus = useSelector((state) => state.global.syncStatus);
    const isManualOffline = useSelector(
        (state) => state.appState.isManualOffline
    );
    const protocolCheckoutStatus = useSelector(
        (state) => state.offlineProtocol.protocol?.checkout_data
    );
    const persistedError = useSelector((state) => state.appState.syncFailure);
    const betaProtocolMedia = useSelector(
        (state) => state.offlineProtocol.protocolMedia
    );
    const protocolState = useSelector(
        (state) => state.offlineProtocol.protocolState
    );
    const {
        is_protocol_normal: isProtocolNormalised,
        is_finished: isProtocolAsyncFinished
    } = protocolState;

    const isInsideProtocol = window.location.pathname.includes('protocol');

    const isSyncing = syncStatus.status === 'syncing';

    const isInsideBetaOfflineProtocol = (isInsideProtocol && useBeta) || false;
    const shouldOfflineOpen =
        isOffline ||
        Boolean(
            useBeta
                ? protocolCheckoutStatus?.status === 'ACTIVE'
                : isManualOffline
        ) ||
        isSyncing ||
        isInsideBetaOfflineProtocol;
    const openTimeout = useWaitTimeout(
        shouldOfflineOpen,
        1000 // temporary change
        // OFFLINE_MESSAGE_DELAY
    );

    const uploadStore = useSelector((state) => state.uploads.uploads);
    const networkSpeed = useSelector((state) =>
        calculateAverage(state.global.networkSpeed)
    );

    const totalBytes = useMemo(() => {
        // TODO: use allIds instead of Object.keys by keeping those two up to date
        // Currently allIds for Media is not updated for certain actions
        return Object.keys(uploadStore.byIds)
            .map((id) => uploadStore.byIds[id])
            .map((upload) =>
                upload && upload.status === 'in-progress' ? upload.size : 0
            )
            .reduce((acc, val) => acc + val, 0);
    }, [uploadStore.byIds]);

    const totalTime = Math.ceil(totalBytes / networkSpeed / 60);
    const totalSize = Math.ceil(bytesToMB(totalBytes));

    const onGoBackOnline = useCallback(() => {
        handleOnlineSwitch(dispatch, useBeta);
    }, [dispatch]);

    const onDiscardData = useCallback(() => {
        handleDiscardSync(dispatch);
    }, [dispatch]);

    const highlightError =
        persistedError.hasFailed && syncStatus.prio !== 'high';
    const priorisedStatus = highlightError
        ? insertError(persistedError.error)
        : syncStatus;
    const isError = priorisedStatus.status === 'sync-fail';
    const hasSucceded = priorisedStatus.status === 'sync-success';

    const calculateProgress = () => {
        if (isSyncing) {
            dispatch(setOfflineProtocolSyncingState('SYNCING'));
            return;
        }
        if (hasSucceded) {
            setRunningSyncSucess(true);
            setTimeout(() => {
                dispatch(
                    normaliseOfflineProtocolMedia('PDF', betaProtocolMedia)
                );
            }, 5000);
        }
        if (isError) {
            setRunningSyncSucess(false);
            dispatch(setOfflineProtocolSyncingState('ERROR')); // handle fail case better
        }
    };

    const handleSyncProcessMonitoring = () => {
        if (!isProtocolAsyncFinished) return;
        if (!runningSyncSuccess) {
            calculateProgress(); // running on check to prevent event-call loop
        }
    };

    useEffect(() => {
        handleSyncProcessMonitoring();
    }, [syncStatus, isProtocolNormalised]);

    // If it's an error keep the message until user acts on it
    const indicatorOpen = isError || openTimeout;

    const shouldOpenForBetaMode = useBeta ? isInsideBetaOfflineProtocol : true;
    return (
        <AnimatePresence>
            {indicatorOpen && shouldOpenForBetaMode && (
                <motion.div {...ANIMATE_HEIGHT_PROPS}>
                    <OfflineBar
                        filesize={totalSize}
                        time={totalTime}
                        isOffline={isOffline}
                        isManualOffline={isManualOffline}
                        status={priorisedStatus}
                        isUnsupported={!isPathSupported(location.pathname)}
                        onSwitchOnline={
                            isServiceWorkerRunning()
                                ? onGoBackOnline
                                : undefined
                        }
                        onRetrySync={
                            isServiceWorkerRunning()
                                ? onGoBackOnline
                                : undefined
                        }
                        onDiscardData={
                            isServiceWorkerRunning() ? onDiscardData : undefined
                        }
                    />
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default OfflineIndicator;
