import React, { Suspense, lazy, memo } from 'react';
import { useSelector } from 'react-redux';

import { Switch, Route, Redirect, useLocation } from 'react-router';

import isAuth from '../store/selectors/isAuth';
import * as routes from '../configs/routes';

import Loading from '../components/atoms/Loading';
import TrialInfo from '../components/global/TrialInfo';
import StorageWarning from '../components/global/StorageWarning';
import AccountExpiredWarning from '../components/misc/AccountExpiredWarning';
import UnsyncedProtocolOverlay from '../components/offline/UnsyncedProtocolOverlay';

// Core routes
import Dashboard from './DashboardPage';
import PropertyRoute from './PropertyRoute';
import ProfilePage from './ProfilePage';
import OpenProtocolWarningOverlay from '../components/offline/OpenProtocolWarningOverlay';

// Optional routes
const ProtocolPage = lazy(
    () => import(/* webpackPrefetch: true */ './ProtocolPage')
);
const BetaProtocolPage = lazy(
    () => import(/* webpackPrefetch: true */ './BetaProtocolPage')
);

const Routes: React.FC = () => {
    const location = useLocation();
    const isUserAuth = useSelector(isAuth);
    const useBeta = useSelector((state) => state.user.user.response?.use_beta);
    const isAsync = useSelector(
        (state) => state.offlineProtocol.protocolState.is_async
    );

    const isAccountLoading = useSelector(
        (state) => state.global.accountLoading
    );
    const isCheckoutLoading = useSelector(
        (state) => state.global.checkoutLoading
    );

    const loadingNode = (
        <Loading
            className={`${
                useBeta
                    ? 'loading__page loading__beta_protocol--loader'
                    : 'loading__page'
            }`}
            size="xlarge"
        />
    );

    if (!isUserAuth) {
        const redirect = {
            pathname: routes.ROOT_ROUTE,
            state: {
                previousUrl: location
            }
        };
        return <Redirect to={redirect} />;
    }

    console.log({ isAccountLoading });
    if (isAccountLoading || isCheckoutLoading) return loadingNode;
    return (
        <Suspense fallback={loadingNode}>
            <TrialInfo />
            <StorageWarning />
            <Switch>
                <Route
                    exact
                    path={routes.PROFILE_PATH}
                    component={ProfilePage}
                />
                <Route
                    exact
                    path={routes.DASHBOARD_PATH}
                    component={Dashboard}
                />
                <Route exact path={routes.DPD_PATH} component={PropertyRoute} />
                <Route
                    exact
                    path={routes.PROTOCOL_PATH}
                    component={useBeta ? BetaProtocolPage : ProtocolPage}
                />
                <Redirect to={routes.DASHBOARD_ROUTE} />
            </Switch>
            {/* {!isAsync && <UnsyncedProtocolOverlay />} */}
            {useBeta && <OpenProtocolWarningOverlay />}
            <AccountExpiredWarning />
        </Suspense>
    );
};

export default memo(Routes);
