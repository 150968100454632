import { MEDIA_TYPE_IMAGE, MEDIA_TYPE_VIDEO } from '../../../configs/costants';
import { escapeFilename } from '../../../modules/file';

export function fileParamsToFormData(
    file: Blob,
    name: string,
    params: IHash<string | number>
) {
    const mediaData = new FormData();

    Object.keys(params).forEach((key) => {
        if (params[key] !== undefined) {
            // Check if the value is not undefined
            mediaData.set(key, params[key].toString());
        }
    });
    mediaData.set('med_path', file, escapeFilename(name, true));

    if (!params['med_type']) {
        const [type] = file.type.split('/');
        mediaData.set(
            'med_type',
            type === 'video' ? MEDIA_TYPE_VIDEO : MEDIA_TYPE_IMAGE
        );
    }
    return mediaData;
}

export function urlFieldsToFormData(file: File, fields: IHash<string>) {
    const awsData = new FormData();

    Object.keys(fields).forEach((key) => awsData.set(key, fields[key]));
    awsData.set('file', file, file.name.toLowerCase());
    return awsData;
}
