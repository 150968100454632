import React, { ReactNode } from 'react';
import clsx from 'clsx';

import { IconName } from '@fortawesome/fontawesome-common-types';

import StdIcon from '../StdIcon';
import StdBlock from '../StdBlock';

import './std-list-item.scss';

interface IProps {
    className?: string;
    textData: {
        main: ReactNode;
        sub?: ReactNode;
    };
    icon?: IconName;
    active?: boolean;
    badge?: boolean;
    black?: boolean;
    dragging?: boolean;
    onClick?: ICallback;
}

const StdListItem: React.FC<IProps> = ({
    className,
    textData,
    active,
    black,
    dragging,
    badge,
    icon,
    onClick
}) => {
    const { main, sub } = textData;

    const containerClass = clsx(
        'list__item',
        !active && 'list__item--inactive',
        dragging && 'list__item--dragging',
        className
    );
    return (
        <div className={containerClass} onClick={onClick}>
            <StdBlock className="inline-m" black={black} inactive={!active}>
                {icon && (
                    <StdIcon prefix={active ? 'fas' : 'fal'} name={icon} />
                )}
            </StdBlock>
            <div>
                <div className="stack-xs">
                    {badge && <div className="std-badge inline-xs" />}
                    <span className={clsx(active && 'font--strong')}>
                        {main}
                    </span>
                </div>
                {sub && (
                    <span
                        className={clsx(
                            active
                                ? 'font--small'
                                : 'font--small font--secondary'
                        )}
                    >
                        {sub}
                    </span>
                )}
            </div>
        </div>
    );
};

export default StdListItem;
