import { takeLatest } from 'redux-saga/effects';

import { GET_PROTOCOL_FOR_LATEST_DATA_REQUEST } from '../../actions';
import {
    getProtocolForLatestData,
    getProtocolForLatestDataSuccess,
    getProtocolForLatestDataFail
} from '../../action-creators';

import { simpleRequest } from '../modules/request';
import { normalizeProtocol } from '../modules/normalization';

export default takeLatest(
    GET_PROTOCOL_FOR_LATEST_DATA_REQUEST,
    simpleRequest<RT<typeof getProtocolForLatestData>>({
        success: getProtocolForLatestDataSuccess,
        failure: getProtocolForLatestDataFail,
        getUrl: (action) => `protocol/${action.protoId}/`,
        onSuccess: function (data) {
            return normalizeProtocol(data);
        },
        enableOffline: true
    })
);
