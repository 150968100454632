import { useCallback } from 'react';

interface INativeShareParams {
    title: string;
    text: string;
    url: string;
}

const useShareLink = () => {
    const handleShare = useCallback(
        ({ title, text, url }: INativeShareParams) => {
            if ((navigator as any).share) {
                (navigator as any).share({
                    title,
                    text,
                    url
                });
            }
        },
        []
    );

    return handleShare;
};

export default useShareLink;
