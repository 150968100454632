import { takeEvery } from 'redux-saga/effects';

import { PATCH_ACCOUNT_REQUEST } from '../../actions';
import {
    patchAccount,
    patchAccountSuccess,
    patchAccountFail
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeEvery(
    PATCH_ACCOUNT_REQUEST,
    simpleRequest<ReturnType<typeof patchAccount>>({
        success: patchAccountSuccess,
        failure: patchAccountFail,
        getUrl: () => `user/me/update/`,
        getBody: (action) => action.account,
        method: 'PATCH',
        isV2Api: true
    })
);
