import React, {
    useState,
    useLayoutEffect,
    useEffect,
    useRef,
    memo
} from 'react';
import clsx from 'clsx';

import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult
} from 'react-beautiful-dnd';

import { intOrElse, justArray, attrListToStr } from '../../../utils';

import { reorder } from '../../../modules/array';
import { dispatchAsync } from '../../../store/storeModule';

import {
    deleteRoom,
    postElement,
    getElement,
    showSnackbar,
    patchElement,
    copyRoom,
    deleteElement,
    elAttrCrud,
    predictRoom,
    predictElements,
    sortingCrud,
    deleteMedia
} from '../../../store/action-creators';

import useDelayedPending from '../../../hooks/useDelayedPending';
import useToggle from '../../../hooks/useToggle';

import { MenuSurfaceAnchor, MenuItem } from '@rmwc/menu';

import { ElementForm } from '../../protocol/ElementForm';
import ElementCard from '../ElementCard';
import StdDropdown from '../../atoms/StdDropdown';
import ConfirmDeletionModal from '../../molecules/ConfirmDeletionModal';
import StdTextField from '../../atoms/StdTextField';
import RoomModal from '../RoomModal';
import EditRoomModal from '../EditRoomDetailsModal';
import Loading from '../../atoms/Loading';
import StdIcon from '../../atoms/StdIcon';
import ElementModal from '../ElementModal';
import EditElementModal from '../EditElementModal';
import EditIssueModal from '../../issues/EditIssueModal';
import StdButton from '../../atoms/StdButton';

import './room-view.scss';
import i18n from '../../../i18next';

function searchElements(elements: IElement[], search: string) {
    return elements.filter((element) =>
        (element.ele_name || '').toLowerCase().includes(search.toLowerCase())
    );
}

interface IProps {
    room?: IRoom;
    dpd: IDpd;
    link?: string;
    initEleId?: number;
    onDelete: ICallback;
    onEdit: ICallback;
    onCopy: ICallback;
    onElesLoaded?: ICallback;
    scrollToEnd?: ICallback;
}

const RoomView: React.FC<IProps> = ({
    room,
    dpd,
    link,
    initEleId,
    onDelete,
    onEdit,
    onCopy,
    onElesLoaded,
    scrollToEnd
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();

    const searchRef = useRef<HTMLInputElement>();
    const [search, setSearch] = useState({ open: false, search: '' });

    const [optionsOpen, setOptionsOpen] = useState(false);
    const [adding, setAdding] = useState(false);

    const [viewOpen, setViewOpen] = useState(false);
    const [delOpen, setDelOpen] = useState(false);
    const [edit, onEditOpen, onEditClose] = useToggle<IScrollType>({
        cleanupOnChange: true
    });
    const [scrollEnd, setScrollEnd] = useState(false);
    const [addIssue, onAddIssueOpen, onAddIssueClose] = useToggle<IElement>();

    const [eleEditOpen, onEleEditOpen, onEleEditClose] = useToggle<{
        ele?: IElement;
        param?: IScrollType;
    }>({ cleanupOnChange: true });
    const [eleViewOpen, onEleViewOpen, onEleViewClose] = useToggle<IElement>();
    const [eleDelOpen, onEleDelOpen, onEleDelClose] = useToggle<IElement>();

    const { rom_id, rom_name, rom_ele_count, rom_roa_id } = room || {};

    const elementRes = useSelector((state) => state.inventory.elements);
    const elements = justArray(elementRes.response.results);

    const filteredEles = search.open
        ? searchElements(elements, search.search)
        : elements;

    const elementsLoading = useDelayedPending([
        getElement,
        postElement,
        patchElement,
        deleteElement,
        elAttrCrud,
        deleteMedia
    ]);

    const roomAttrs = Array.isArray(rom_roa_id) ? rom_roa_id : [];
    const { listVal } = attrListToStr(
        roomAttrs.map((attr) => attr.roa_value || ''),
        { charLimit: 42, charPerItem: 15 }
    );

    const lang = i18n.language;

    useLayoutEffect(() => {
        if (rom_id == null) return;

        dispatch(getElement(rom_id, lang as ILang));
    }, [dispatch, rom_id, lang]);

    useEffect(() => {
        !elementsLoading && onElesLoaded && onElesLoaded();
    }, [elementsLoading, onElesLoaded]);

    useEffect(() => {
        if (!elementsLoading && scrollEnd && scrollToEnd) {
            scrollToEnd();
            setScrollEnd(false);
        }
    }, [elementsLoading, scrollEnd, scrollToEnd]);

    useEffect(() => {
        if (search.open) {
            // timeout for the non native part of the component to react accordingly
            setTimeout(() => {
                searchRef.current?.focus();
            }, 0);
        }
    }, [search.open]);

    useEffect(() => {
        if (elementsLoading) return;

        const element =
            initEleId != null &&
            elements.find((ele) => ele.ele_id === initEleId);
        element ? onEleViewOpen(element) : onEleViewClose();
    }, [elements, onEleViewOpen, onEleViewClose, elementsLoading, initEleId]);

    const fetchElements = () => {
        if (rom_id == null) return;
        dispatch(getElement(rom_id, lang as ILang));
    };

    const handleEleDelete = () => {
        if (!eleDelOpen.item || rom_id == null) return;

        const { ele_id } = eleDelOpen.item;

        dispatchAsync(dispatch, deleteElement(ele_id))
            .then((_) => {
                dispatch(getElement(rom_id, lang as ILang));
                dispatch(
                    predictRoom(rom_id, {
                        rom_ele_count:
                            rom_ele_count != null
                                ? rom_ele_count - 1
                                : elements.length
                    })
                );
            })
            .catch(({ error }) =>
                dispatch(
                    showSnackbar({
                        message: 'delete_element_fail_message',
                        error
                    })
                )
            );
        onEleDelClose();
    };

    const createElement = (name: string) => {
        if (rom_id == null) return;

        const elementLiteral = {
            ele_rom_id: rom_id,
            ele_name: name
        };

        dispatchAsync(dispatch, postElement(elementLiteral))
            .then((_) => {
                dispatchAsync(dispatch, getElement(rom_id, lang as ILang)).then(
                    (_) => setScrollEnd(true)
                );

                // TODO: react to postElement action by updating rom_ele_count in the reducer
                // instead of having a separate action for it
                dispatch(
                    predictRoom(rom_id, {
                        rom_ele_count: intOrElse(rom_ele_count, 0) + 1
                    })
                );
            })
            .catch(({ error }) =>
                dispatch(
                    showSnackbar({ message: 'add_element_fail_message', error })
                )
            );
    };

    const handleCopyRoom = () => {
        if (rom_id == null) return;

        dispatchAsync(dispatch, copyRoom(rom_id))
            .then(onCopy)
            .catch(({ error }) =>
                dispatch(showSnackbar({ message: 'room_copy_error', error }))
            );
    };

    const handleDelete = () => {
        if (rom_id == null) return;

        dispatchAsync(dispatch, deleteRoom(rom_id))
            .then(onDelete)
            .catch(({ error }) =>
                dispatch(
                    showSnackbar({
                        message: 'delete_element_fail_message',
                        error
                    })
                )
            );
        setDelOpen(false);
    };

    const onDragEnd = (result: DropResult) => {
        if (rom_id == null) return;

        const { source, destination } = result;
        if (!destination || source.index === destination.index) return;

        const newEles = reorder(elements, source.index, destination.index);
        dispatch(predictElements(newEles));
        dispatch(
            sortingCrud({
                srt_rom_id: rom_id,
                context: 'srt_rom_id',
                srt_order: newEles.map(({ ele_id }) => ({ pk: ele_id }))
            })
        );
    };

    const onAddSelect = () => {
        setAdding(true);
        scrollToEnd?.();
    };

    return (
        <div className="room-view">
            <div className="room-view__header">
                {search.open ? (
                    <>
                        <StdTextField
                            className="mdc-textfield-custom"
                            ref={searchRef}
                            placeholder={t('search_for_elements')}
                            value={search.search}
                            onChange={(e) => {
                                const { value } = e.target;
                                setSearch((s) => ({ ...s, search: value }));
                            }}
                            icon={<StdIcon name="search" prefix="far" />}
                            trailingIcon={
                                <StdIcon
                                    name="times"
                                    prefix="far"
                                    onClick={() =>
                                        setSearch({ open: false, search: '' })
                                    }
                                />
                            }
                            width="100%"
                        />
                    </>
                ) : (
                    <>
                        <div className="std-section">
                            <div
                                className="font__header util__pointer stack-xs room-view__title"
                                onClick={() => setViewOpen(true)}
                            >
                                {rom_name}{' '}
                                <div
                                    className="room-view__info-icon"
                                    onClick={() => setViewOpen(true)}
                                >
                                    <StdIcon
                                        clickable
                                        name="info-circle"
                                        prefix="fal"
                                    />
                                </div>
                            </div>
                            <div>
                                <StdIcon
                                    name="search"
                                    prefix="far"
                                    clickable
                                    onClick={() =>
                                        setSearch((s) => ({ ...s, open: true }))
                                    }
                                />
                                <MenuSurfaceAnchor className="util__inline">
                                    <StdDropdown
                                        open={optionsOpen}
                                        onClose={() => setOptionsOpen(false)}
                                        onSelect={(e) => {
                                            const {
                                                detail: { item }
                                            } = e;
                                            const value = item.dataset['value'];
                                            switch (value) {
                                                case 'edit_room':
                                                    onEditOpen(undefined);
                                                    break;
                                                case 'copy_room':
                                                    handleCopyRoom();
                                                    break;
                                                case 'delete_room':
                                                    setDelOpen(true);
                                                    break;
                                            }
                                        }}
                                    >
                                        <MenuItem data-value="edit_room">
                                            {t('edit_room')}
                                        </MenuItem>
                                        <MenuItem data-value="copy_room">
                                            {t('copy_room')}
                                        </MenuItem>
                                        <MenuItem data-value="delete_room">
                                            {t('delete_room')}
                                        </MenuItem>
                                    </StdDropdown>
                                    <StdIcon
                                        name="ellipsis-v"
                                        prefix="far"
                                        clickable
                                        onClick={() => setOptionsOpen(true)}
                                    />
                                </MenuSurfaceAnchor>
                            </div>
                        </div>
                        <div>
                            {/* {Boolean(listVal) && (
                                <span
                                    className="info__attributes inline-xs"
                                    onClick={() => setViewOpen(true)}
                                >
                                    {listVal}
                                </span>
                            )} */}
                            <StdButton
                                className="room-view__add-ele-btn"
                                leadingIcon={{ name: 'plus' }}
                                onClick={() => onAddSelect?.()}
                                size="small"
                            >
                                {t('element')}
                            </StdButton>
                        </div>
                    </>
                )}
            </div>
            <div className={clsx(elementsLoading && 'loading__elements')}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable-elements">
                        {(provided) => {
                            return (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    {filteredEles.map((elmenet, index) => {
                                        const { ele_id } = elmenet;
                                        return (
                                            <Draggable
                                                key={ele_id}
                                                draggableId={ele_id?.toString()}
                                                index={index}
                                                isDragDisabled={search.open}
                                            >
                                                {(provided, snapshot) => {
                                                    return (
                                                        <div
                                                            ref={
                                                                provided.innerRef
                                                            }
                                                            {...provided.draggableProps}
                                                            // Note: quite clearly a typing bug of some sort
                                                            {...(provided.dragHandleProps as any)}
                                                        >
                                                            <ElementCard
                                                                className={clsx(
                                                                    snapshot.isDragging &&
                                                                        'ele-card-alt__dragging'
                                                                )}
                                                                element={
                                                                    elmenet
                                                                }
                                                                onView={() => {
                                                                    if (!room)
                                                                        return;
                                                                    history.push(
                                                                        `?room=${room.rom_id}&element=${elmenet.ele_id}`
                                                                    );
                                                                }}
                                                                onEdit={() =>
                                                                    onEleEditOpen(
                                                                        {
                                                                            ele: elmenet
                                                                        }
                                                                    )
                                                                }
                                                                onDel={() =>
                                                                    onEleDelOpen(
                                                                        elmenet
                                                                    )
                                                                }
                                                                onAddIssue={() =>
                                                                    onAddIssueOpen(
                                                                        elmenet
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    );
                                                }}
                                            </Draggable>
                                        );
                                    })}
                                    {provided.placeholder}
                                </div>
                            );
                        }}
                    </Droppable>
                </DragDropContext>
                <div className="room-view__divider" />
                <ElementForm
                    open={adding}
                    setOpen={(value) => setAdding(value)}
                    onElementAdd={createElement}
                />
                {elementsLoading && (
                    <Loading className="loading__item" size="large" />
                )}
            </div>
            <RoomModal
                room={room}
                open={viewOpen}
                onClose={() => setViewOpen(false)}
                onEdit={onEditOpen}
            />
            <EditRoomModal
                open={edit.open}
                object={dpd}
                room={room}
                onClose={onEditClose}
                onSubmit={onEdit}
                toAttr={edit.item === 'attr'}
                toAttach={edit.item === 'attach'}
            />
            <ConfirmDeletionModal
                open={delOpen}
                onClose={() => setDelOpen(false)}
                message={t('delete_room_message', { name: rom_name || '--' })}
                onConfirm={handleDelete}
            />
            <ConfirmDeletionModal
                open={eleDelOpen.open}
                onClose={onEleDelClose}
                message={t('delete_element_message', {
                    name: eleDelOpen.item?.ele_name || '--'
                })}
                onConfirm={handleEleDelete}
            />
            <ElementModal
                open={eleViewOpen.open}
                onClose={() => history.push({ search: `?room=${rom_id}` })}
                link={link}
                room={room}
                element={eleViewOpen.item}
                onEdit={(param) => {
                    if (eleViewOpen.item)
                        onEleEditOpen({ ele: eleViewOpen.item, param });
                }}
            />
            <EditElementModal
                open={eleEditOpen.open}
                element={eleEditOpen.item?.ele}
                onClose={onEleEditClose}
                onSuccess={fetchElements}
                toAttr={eleEditOpen.item?.param === 'attr'}
                toAttach={eleEditOpen.item?.param === 'attach'}
            />
            <EditIssueModal
                issue={{
                    piss_ele_id: addIssue.item,
                    piss_rom_id: room
                }}
                open={addIssue.open}
                onClose={onAddIssueClose}
                onSubmit={() =>
                    dispatch(
                        showSnackbar({
                            snackType: 'info',
                            message: 'issue_add_success_message'
                        })
                    )
                }
                omitSelection
            />
        </div>
    );
};

export default memo(
    RoomView,
    (prevProps, nextProps) =>
        prevProps.room === nextProps.room &&
        prevProps.initEleId === nextProps.initEleId
);
