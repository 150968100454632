import React from 'react';
import clsx from 'clsx';
import StdIcon from '../StdIcon';
import { IconPrefix, IconName } from '@fortawesome/fontawesome-common-types';
import { match } from '../../../utils';

import './std-tab.scss';

interface IProps {
    disabled?: boolean;
    inactive?: boolean;
    onClick?: IClickHandler;
    children?: React.ReactNode;
    icon?: IconName;
    iconPrefix?: IconPrefix;
    width?: string;
}

const StdTab: React.FC<IProps> = ({
    children,
    icon,
    iconPrefix = 'fas',
    width = '100%',
    inactive,
    disabled,
    onClick
}) => {
    const tabClass = match<string, string>('')
        .on(
            (_) => Boolean(disabled),
            (_) => 'std-tab--disabled'
        )
        .on(
            (_) => Boolean(inactive),
            (_) => 'std-tab--inactive'
        )
        .otherwise((_) => '');

    return (
        <button
            style={{ width }}
            className={clsx('std-tab', tabClass)}
            onClick={onClick}
        >
            <div className="stack-s">
                {icon && <StdIcon name={icon} prefix={iconPrefix} />}
            </div>
            {children}
        </button>
    );
};

export default StdTab;
