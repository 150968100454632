import React from 'react';

import clsx from 'clsx';
import { matchSimple } from '../../../utils';

import { Ripple } from '@rmwc/ripple';

import './custom-icon.scss';

type ICustomIconTitle = 'search' | 'cross' | 'back-arrow';

const iconPath = '/static/images/';

function findIconByName(name: ICustomIconTitle): [string, string] {
    const iconFilename = matchSimple()
        .on(name === 'search', 'search_icon.svg')
        .on(name === 'cross', 'cross_icon.svg')
        .on(name === 'back-arrow', 'back_icon.svg')
        .otherwise('');

    return [iconPath.concat(iconFilename), `custom-icon__container--${name}`];
}

interface IProps extends IClass {
    name: ICustomIconTitle;
    ripple?: boolean;
    onClick?: ICallback;
}

const CustomIcon: React.FC<IProps> = ({ className, name, ripple, onClick }) => {
    const alt = `${name} icon`;

    const [iconPath, iconClass] = findIconByName(name);
    const iconNode = (
        <div
            className={clsx('custom-icon__container', iconClass, className)}
            onClick={onClick}
        >
            <img className="custom-icon__icon" src={iconPath} alt={alt} />
        </div>
    );

    if (!ripple) {
        return iconNode;
    }

    return <Ripple>{iconNode}</Ripple>;
};

export default CustomIcon;
