import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faStickyNote,
    faCalendar,
    faCalendarAlt,
    faCheckCircle,
    faEllipsisV,
    faThLarge,
    faBuilding,
    faPlus,
    faUsers,
    faFileAlt,
    faCouch,
    faDoorClosed,
    faQuestionCircle,
    faExclamationTriangle,
    faPlay,
    faTimesCircle,
    faCheck,
    faUser,
    faMinusCircle,
    faKey,
    faBolt,
    faTint,
    faBurn,
    faEnvelope,
    faTrash,
    faTrashAlt,
    faDesktop
    // faMobile
} from '@fortawesome/free-solid-svg-icons';

import {
    faUtensilsAlt,
    faFaucetDrip,
    faBooks,
    faFragile,
    faCaretDown
} from '@fortawesome/pro-solid-svg-icons';

import {
    faExclamationCircle as faExclamationCircleRegular,
    faEdit as faEditRegular,
    faArrowLeft as faArrowLeftRegular,
    faEllipsisV as faEllipsisVRegular,
    faCheck as faCheckRegular,
    faChevronRight as faChevronRightRegular,
    faChevronLeft as faChevronLeftRegular,
    faAngleLeft as faAngleLeftRegular,
    faAngleRight as faAngleRightRegular,
    faSignature as faSignatureRegular,
    faEyeSlash as faEyeSlashRegular,
    faEye as faEyeRegular,
    faSearch as faSearchRegular,
    faTimes as faTimesRegular,
    faLockAlt as faLockAltRegular,
    faUser as faUserRegular,
    faTrashAlt as faTrashAltRegular,
    faExternalLinkAlt,
    faCompressArrowsAlt,
    faDesktopAlt,
    faMobile
} from '@fortawesome/pro-regular-svg-icons';

import {
    faEnvelope as faEnvelopeLight,
    faBuilding as faBuildingLight,
    faStickyNote as faStickyNoteLight,
    faUser as faUserLight,
    faThLarge as faThLargeLight,
    faPlus as faPlusLight,
    faUsers as faUsersLight,
    faFileAlt as faFileAltLight,
    faCalendarWeek as faCalendarWeekLight,
    faInfoSquare as faInfoSquareLight,
    faInfoCircle as faInfoCircleLight,
    faEdit as faEditLight,
    faTrash as faTrashLight,
    faUtensilsAlt as faUtensilsAltLight,
    faCouch as faCouchLight,
    faDoorClosed as faDoorClosedLight,
    faImage as faImageLight,
    faPhone as faPhoneLight,
    faQuestionCircle as faQuestionCircleLight,
    faCheckCircle as faCheckCircleLight,
    faExclamationTriangle as faExclamationTriangleLight,
    faLocationCircle as faLocationCircleLight,
    faPhotoVideo as faPhotoVideoLight,
    faChevronRight as faChevronRightLight,
    faChevronDown as faChevronDownLight,
    faChevronUp as faChevronUpLight,
    faShareAlt as faShareAltLight,
    faArrowLeft as faArrowLeftLight,
    faFaucetDrip as faFaucetDripLight,
    faTimes as faTimesLight,
    faTools as faToolsLight,
    faFileUpload as faFileUploadLight,
    faReceipt as faReceiptLight,
    faFileInvoice as faFileInvoiceLight,
    faKey as faKeyLight,
    faCheck as faCheckLight,
    faSignIn as faSignInLight,
    faSignOut as faSignOutLight,
    faBooks as faBooksLight,
    faExclamationCircle as faExclamationCircleLight,
    faPaperclip as faPaperclipLight,
    faFilePdf as faFilePdfLight,
    faFileWord as faFileWordLight,
    faFileExcel as faFileExcelLight,
    faAngleRight as faAngleRightLight,
    faHouse as faHouseLight,
    faCity as faCityLight,
    faGarage as faGarageLight,
    faWarehouseAlt as faWarehouseAltLight,
    faCamera as faCameraAlt,
    faVideo as faVideoAlt,
    faTimesCircle as faTimesCircleLight,
    faStopCircle as faStopCircleLight,
    faBolt as faBoltLight,
    faTint as faTintLight,
    faBurn as faBurnLight,
    faList as faListLight,
    faTachometerAlt as faTachometerAltLight,
    faPencilAlt as faPencilAltLight,
    faEyeSlash as faEyeSlashLight,
    faEye as faEyeLight,
    faFragile as faFragileLight,
    faWifiSlash as faWifiSlashLight,
    faMinus as faMinusLight,
    faCalendarAlt as faCalendarLight
} from '@fortawesome/pro-light-svg-icons';

library.add(
    faStickyNote,
    faCalendar,
    faExclamationCircleRegular,
    faPlus,
    faPlusLight,
    faEnvelopeLight,
    faCheckCircle,
    faEditRegular,
    faEllipsisV,
    faArrowLeftRegular,
    faBuildingLight,
    faStickyNoteLight,
    faUserLight,
    faChevronRightLight,
    faChevronDownLight,
    faThLarge,
    faThLargeLight,
    faBuilding,
    faUsersLight,
    faFileAltLight,
    faUsers,
    faFileAlt,
    faCalendarWeekLight,
    faInfoSquareLight,
    faInfoCircleLight,
    faEditLight,
    faTrashLight,
    faUtensilsAlt,
    faUtensilsAltLight,
    faCouch,
    faCouchLight,
    faDoorClosed,
    faDoorClosedLight,
    faImageLight,
    faPhoneLight,
    faQuestionCircle,
    faQuestionCircleLight,
    faCheckCircleLight,
    faExclamationTriangle,
    faExclamationTriangleLight,
    faLocationCircleLight,
    faPhotoVideoLight,
    faEllipsisV,
    faEllipsisVRegular,
    faShareAltLight,
    faArrowLeftLight,
    faFaucetDrip,
    faFaucetDripLight,
    faTimesLight,
    faCheckRegular,
    faToolsLight,
    faChevronRightRegular,
    faChevronLeftRegular,
    faFileUploadLight,
    faPlay,
    faReceiptLight,
    faFileInvoiceLight,
    faKeyLight,
    faAngleLeftRegular,
    faAngleRightRegular,
    faTimesCircle,
    faCheckLight,
    faCheck,
    faSignInLight,
    faSignOutLight,
    faUser,
    faBooks,
    faBooksLight,
    faSignatureRegular,
    faMinusCircle,
    faEyeSlashRegular,
    faEyeRegular,
    faExclamationCircleLight,
    faPaperclipLight,
    faFilePdfLight,
    faFileWordLight,
    faFileExcelLight,
    faAngleRightLight,
    faSearchRegular,
    faTimesRegular,
    faHouseLight,
    faCityLight,
    faGarageLight,
    faWarehouseAltLight,
    faKey,
    faCameraAlt,
    faVideoAlt,
    faTimesCircleLight,
    faStopCircleLight,
    faBolt,
    faTint,
    faBurn,
    faBoltLight,
    faTintLight,
    faBurnLight,
    faListLight,
    faTachometerAltLight,
    faPencilAltLight,
    faEyeSlashLight,
    faEyeLight,
    faFragileLight,
    faFragile,
    faLockAltRegular,
    faWifiSlashLight,
    faCaretDown,
    faEnvelope,
    faUserRegular,
    faTrashAltRegular,
    faMinusLight,
    faExternalLinkAlt,
    faCompressArrowsAlt,
    faChevronUpLight,
    faCalendarAlt,
    faCalendarLight,
    faTrash,
    faTrashAlt,
    faDesktop,
    faDesktopAlt,
    faMobile
);
