import { AnyAction } from 'redux';
import * as actions from '../actions/issue';

type ISharedIssueState = IRequestResult<ISharedIssue | null>;

const initState: ISharedIssueState = {
    response: null,
    error: null
};

function reducer(state = initState, action: AnyAction): ISharedIssueState {
    switch (action.type) {
        case actions.GET_SHARED_ISSUE_REQUEST:
            return {
                ...state,
                error: null
            };
        case actions.GET_SHARED_ISSUE_SUCCESS:
            return {
                ...state,
                response: action.payload.issue
            };
        case actions.GET_SHARED_ISSUE_FAIL:
            return {
                ...state,
                error: action.error
            };
    }
    return state;
}

export default reducer;
