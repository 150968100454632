import { useSelector } from 'react-redux';
import { AnyAction, ActionCreator } from 'redux';

import { getAsyncActionCreatorName } from '../store/storeModule';

interface IPendingAction {
    action: ActionCreator<AnyAction>;
    fetchType?: string;
}

export type IPendingItem = ActionCreator<AnyAction> | IPendingAction;

function isPending(hash: IHash<boolean>, pendingItems: IPendingItem[]) {
    return pendingItems.some((pendingItem) => {
        const fetchType =
            'fetchType' in pendingItem ? pendingItem.fetchType : null;
        const actionCreator =
            'action' in pendingItem ? pendingItem.action : pendingItem;

        const actionName = getAsyncActionCreatorName(actionCreator);
        const actionLabel =
            fetchType != null ? actionName.concat('-', fetchType) : actionName;

        return hash[actionLabel];
    });
}

const usePending = (pendingItems: IPendingItem[]) => {
    return useSelector((state) => isPending(state.pending, pendingItems));
};

export default usePending;
