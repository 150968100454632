import { takeEvery, all, put } from 'redux-saga/effects';

import {
    PATCH_ATTENDEE_REQUEST,
    PATCH_ATTENDEE_SUCCESS,
    PATCH_ATTENDEE_FAIL
} from '../../actions';
import {
    patchAttendee,
    patchAttendeeSuccess,
    patchAttendeeFail,
    getAttendee
} from '../../action-creators';

import { simpleRequest } from '../modules/request';
import { normalizeAttendee } from '../modules/normalization';
import { responseHelper } from '../modules/promise';

type IPatchAttndeeAction = RT<typeof patchAttendee>;
type IPatchAttnedeeFailAction = RT<typeof patchAttendeeFail>;

function onSuccessCallback(data: any) {
    return normalizeAttendee(data);
}

const requestEffect = simpleRequest<IPatchAttndeeAction>({
    success: patchAttendeeSuccess,
    failure: patchAttendeeFail,
    getUrl: (action) => `attendee/${action.id}/`,
    getBody: (action) => action.attendee,
    getEntityId: (action) => action.id,
    method: 'PATCH',
    enableOffline: true
});

const responseEffect = responseHelper({
    actionName: PATCH_ATTENDEE_SUCCESS,
    processFn: onSuccessCallback
});

export default all([
    takeEvery(PATCH_ATTENDEE_REQUEST, requestEffect),
    takeEvery(
        PATCH_ATTENDEE_FAIL,
        function* (action: IPatchAttnedeeFailAction) {
            if (action.itemId == null) return;
            yield put(getAttendee(action.itemId));
        }
    ),
    responseEffect
]);
