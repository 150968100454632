import { takeEvery } from 'redux-saga/effects';

import { DELETE_ELEMENT_REQUEST } from '../../actions';
import {
    deleteElementSuccess,
    deleteElementFail,
    IDeleteElement
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeEvery(
    DELETE_ELEMENT_REQUEST,
    simpleRequest<IDeleteElement>({
        success: deleteElementSuccess,
        failure: deleteElementFail,
        getUrl: (action) => `element/${action.elId}/element_delete/`,
        method: 'DELETE'
    })
);
