import { takeLatest } from 'redux-saga/effects';
import { SET_PASSWORD_REQUEST } from '../../actions';
import {
    setPassword,
    setPasswordSuccess,
    setPasswordFail
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeLatest(
    SET_PASSWORD_REQUEST,
    simpleRequest<ReturnType<typeof setPassword>>({
        success: setPasswordSuccess,
        failure: setPasswordFail,
        getUrl: (_) => 'auth-users/set_password/',
        getBody: (action) => action.payload,
        method: 'POST'
    })
);
