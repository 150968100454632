import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { isEmpty } from 'validator';
import clsx from 'clsx';

import {
    DPD_EWID_REGEX,
    DPD_UPLOAD_CONFIG,
    OBJECT_FLOOR_LIMIT,
    SUBDPD_TYPE_GENERAL,
    DPD_NUMBER_OF_FLOORS_REGEX,
    MEDIA_ATTACHMENT_TYPE
} from '../../../configs/costants';

import { DPD_ATTR_TYPE, SUBDPD_TYPE } from '../../../configs/basicData';
import { dispatchAll } from '../../../store/storeModule';
import {
    postDpd,
    patchDpd,
    showSnackbar,
    dpdAttrCrud
} from '../../../store/action-creators';

import { intOrElse } from '../../../utils';
import { isHouseType, INT_TYPE_REGEX } from '../../../configs/costants';
import { validate } from '../../../utils/validation';
import {
    propertyAddressToForm,
    objectToIcon,
    objectHasTemplate,
    propertyToObjectForm
} from '../../../modules/property';
import { getAttrHashKeys } from '../../../utils/attributes';

import useValidation from '../../../hooks/useValidation';
import useInput from '../../../hooks/useInput';
import useAttributeList from '../../../hooks/useAttributeList';
import useAddressEdit from '../../../hooks/useAddressEdit';
import useGeoAdmin from '../../../hooks/useGeoAdmin';
import useReactScrollTo from '../../../hooks/useReactScrollTo';
import { MenuItem } from '@rmwc/menu';
import StdModal from '../../atoms/StdModal';
import StdGallery from '../../molecules/StdGallery';
import TextField from '../../atoms/StdTextField';
import StdDivider from '../../atoms/StdDivider';
import EditAttributeList from '../EditAttributeList';
import AttachmentGallery from '../../molecules/AttachmentGallery';
import AddressEditForm from '../AddressEditForm';
import StdSelect from '../../atoms/StdSelect';
import StdIcon from '../../atoms/StdIcon';
import StdCheckbox from '../../atoms/StdCheckbox';
import useUploader from '../../../hooks/useUploader';
import ConfirmModal from '../../molecules/ConfirmModal';
import EditModalHeader from '../../atoms/EditModalHeader';

interface IProps extends IModalBase {
    parentId?: number;
    dpd?: IDpd;
    toAttr?: boolean;
    toAttach?: boolean;
    onSuccess?: ICallback;
    isHidingRooms?: (upatedFloors: number) => boolean;
    hasBasementRooms?: boolean;
}

interface IInput {
    title: string;
    egid: string;
    ewid: string;
    floor: string;
    description: string;
    type: string;
    include_basement: boolean;
    use_template: boolean;
}

interface IForm {
    form: IInput;
    errors: IErrorHash;
}

const initForm: IForm = {
    form: {
        title: '',
        egid: '',
        ewid: '',
        floor: '',
        description: '',
        type: '',
        include_basement: false,
        use_template: false
    },
    errors: {}
};

const EditObjectModal: React.FC<IProps> = (props) => {
    const {
        parentId,
        dpd,
        open,
        toAttach,
        toAttr,
        hasBasementRooms,
        isHidingRooms,
        onSuccess,
        onClose
    } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [input, setInput] = useState(initForm);

    const galleryUploader = useUploader();
    const attachUploader = useUploader();

    const attrListProps = useAttributeList();
    const { attrState, resetAttrs, validateAttrs } = attrListProps;

    const { validateAddress, getAddressForm, ...addressEditProps } =
        useAddressEdit();

    const [confirmBasementOpen, setConfirmBasementOpen] = useState(false);
    const [confirmFloorOpen, setConfirmFloorOpen] = useState(false);

    const lookupHash = useSelector((state) => state.basicData.response);
    const attrData = lookupHash?.[DPD_ATTR_TYPE];

    const isHouse = dpd && isHouseType(dpd.dpd_type || '');

    // Is general stuff dpd type?
    const isGeneral = input.form.type === SUBDPD_TYPE_GENERAL;

    const isEditing = Boolean(dpd);
    const showDetails = isEditing && !isGeneral;

    const typesHash =
        useSelector((state) => state.basicData.response?.[SUBDPD_TYPE]) || {};
    const generalTitle = typesHash[SUBDPD_TYPE_GENERAL] || '';

    const attrRef = useReactScrollTo(toAttr);
    const attachRef = useReactScrollTo(toAttach);

    // NOTE: Sort of "forward" referential equaility for address of a dpd
    const addressForm = useMemo(() => dpd && propertyAddressToForm(dpd), [dpd]);

    const handleEGID = useCallback((egid?: string, ewid?: string) => {
        setInput((s) => ({
            ...s,
            form: {
                ...s.form,
                egid: egid || '',
                ewid: ewid || ''
            }
        }));
    }, []);

    const getGeoData = useGeoAdmin(handleEGID);

    const handleInput = useInput(setInput, (name, value, newState) => {
        if (name === 'floor' && !DPD_NUMBER_OF_FLOORS_REGEX.test(value))
            return input;
        if (name === 'egid' && !INT_TYPE_REGEX.test(value)) return input;
        if (name === 'ewid' && !INT_TYPE_REGEX.test(value)) return input;
        return newState;
    });

    const resetInput = useCallback(() => {
        if (open && dpd) {
            const { dpd_attributes, uploads = [] } = dpd;

            const dpdAttrs = Array.isArray(dpd_attributes)
                ? dpd_attributes
                : [];

            const hashForm: IHash<string> = {};
            const attrIds = dpdAttrs.map((dpdAttr) => {
                const { dpa_id, dpa_type, dpa_value } = dpdAttr;
                const idLiteral = { id: dpa_id };

                const { typeKey, valueKey } = getAttrHashKeys(idLiteral);

                hashForm[typeKey] = dpa_type || '';
                hashForm[valueKey] = dpa_value || '';

                return idLiteral;
            });

            setInput((state) => ({
                ...state,
                form: {
                    ...state.form,
                    ...propertyToObjectForm(dpd)
                }
            }));

            resetAttrs(attrIds, hashForm);

            galleryUploader.setPartialUploads(
                uploads,
                (upload) => upload.type !== MEDIA_ATTACHMENT_TYPE
            );
            attachUploader.setPartialUploads(
                uploads,
                (upload) => upload.type === MEDIA_ATTACHMENT_TYPE
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dpd, open, resetAttrs]);

    useEffect(() => {
        setInput((s) => ({
            ...s,
            form: {
                ...s.form,
                title: isGeneral ? generalTitle : ''
            }
        }));
    }, [isGeneral, generalTitle]);

    useEffect(() => {
        resetInput();
    }, [resetInput]);

    const mediaIds = galleryUploader.ids.concat(attachUploader.ids);

    const makeRequest = () => {
        const {
            title,
            description,
            floor,
            ewid,
            egid,
            type,
            include_basement,
            use_template
        } = input.form;
        const { zip, street, street_number, city, country, address_extra } =
            getAddressForm();
        const { region, commune, lat, lng } = address_extra;

        const { attrIds, hashForm, dirtyAttr } = attrState;

        const parsedEWID = parseInt(ewid);
        const parsedEGID = parseInt(egid);

        const locationLiteral = isHouse
            ? {
                  dpd_zip: zip,
                  dpd_geo_lat: lat?.toString(),
                  dpd_geo_lng: lng?.toString(),
                  dpd_street: street,
                  dpd_street_number: street_number,
                  dpd_country: country,
                  dpd_region: region,
                  dpd_city: city,
                  dpd_commune: commune
              }
            : {};

        const objectLiteral = {
            dpd_dpd_id: parentId,
            dpd_title: title,
            dpd_description: description,
            dpd_floor: intOrElse(floor, 1), //default floor to one for any object
            dpd_ewid: Number.isInteger(parsedEWID) ? parsedEWID : null,
            dpd_egid: Number.isInteger(parsedEGID) ? parsedEGID : null,
            dpd_type: type,
            dpd_basement_included: include_basement,
            template_type: !dpd && use_template ? type : undefined,
            media_med_id: mediaIds,
            ...locationLiteral
        };

        const actions: AnyAction[] = [];
        const context = attrIds.map((attrId) => {
            const { typeKey, valueKey } = getAttrHashKeys(attrId);
            return {
                dpa_type: hashForm[typeKey],
                dpa_value: hashForm[valueKey]
            };
        });

        const modAction = dpd
            ? patchDpd(dpd.dpd_id, objectLiteral)
            : postDpd({ ...objectLiteral, context: 'create_child_dpd' });
        actions.push(modAction);

        if (dirtyAttr && dpd?.dpd_id != null)
            actions.push(
                dpdAttrCrud({ dpa_dpd_id: dpd.dpd_id, dpa_state: context })
            );

        dispatchAll(dispatch, actions, { disableLoading: true })
            .then((_) =>
                Promise.all([
                    galleryUploader.submitChanges(),
                    attachUploader.submitChanges()
                ])
            )
            .then(onSuccess)
            .catch(({ error }) =>
                dispatch(
                    showSnackbar({
                        message: dpd
                            ? 'object_edit_fail_message'
                            : 'object_create_fail_message',
                        error
                    })
                )
            );
        onClose();
    };

    const validateFields = useValidation(input, setInput, (hash) => {
        const { title, floor, type } = input.form;
        const parsedFloor = parseInt(floor);

        const addressValid = !isHouse || validateAddress();
        const attrsValid = !isHouse || validateAttrs().every(Boolean);

        return [
            validate({ title }, !isEmpty(title), hash),
            validate({ type }, !isEmpty(type), hash),
            !isHouse ||
                (validate({ floor }, Number.isInteger(parsedFloor), hash) &&
                    validate({ floor }, parsedFloor > 0, hash) &&
                    validate(
                        { floor },
                        parsedFloor <= OBJECT_FLOOR_LIMIT,
                        hash
                    )),
            addressValid,
            attrsValid
        ].every(Boolean);
    });

    const onConfirmEdit = () => {
        validateFields() && makeRequest();
    };

    const handleSubmit = () => {
        const isValid = validateFields();
        if (!isValid) return;

        const isRemovingBasement = !input.form.include_basement;
        if (hasBasementRooms && isRemovingBasement) {
            setConfirmBasementOpen(true);
            return;
        }

        const updatedFloor = parseInt(input.form.floor);
        if (isHidingRooms?.(updatedFloor)) {
            setConfirmFloorOpen(true);
            return;
        }

        makeRequest();
    };

    const cleanup = () => {
        setInput(initForm);
    };

    const onModalClose = () => {
        // Note: for attachments, cancel is redundant
        galleryUploader.cancelChanges();
        onClose();
    };

    const { form, errors } = input;

    const hasTemplates = objectHasTemplate(form.type);
    const modalTitle = dpd ? t('edit_object') : t('add_object');
    return (
        <StdModal open={open} onClose={onClose} onExited={cleanup} small>
            <EditModalHeader
                title={modalTitle}
                submitLabel={t('save')}
                onSubmit={handleSubmit}
                onClose={onModalClose}
            />
            <div className="modal__section modal__section--slim">
                <div className="modal__heading stack-m">
                    {t('object_detail').toUpperCase()}
                </div>
                <StdGallery
                    {...galleryUploader}
                    className="stack-l"
                    uploadConfig={DPD_UPLOAD_CONFIG}
                    icon="photo-video"
                />
            </div>
            {!isHouse && (
                <StdSelect
                    className="modal__section--slimed"
                    label={t('type')}
                    name="type"
                    value={form.type}
                    error={errors['type']}
                    onChange={handleInput}
                    width="auto"
                >
                    {Object.keys(typesHash).map((key) => {
                        return (
                            <MenuItem key={key} data-value={key}>
                                <StdIcon
                                    className="inline-m"
                                    name={objectToIcon(key)}
                                />
                                <span>{typesHash[key]}</span>
                            </MenuItem>
                        );
                    })}
                </StdSelect>
            )}
            <div className="modal__section--slimed">
                <TextField
                    label={t('object_name')}
                    name="title"
                    value={form.title}
                    error={errors['title']}
                    onChange={handleInput}
                    disabled={isGeneral}
                    width="100%"
                    helpText={{
                        persistent: true,
                        children: (
                            <>
                                {t('object_name_format_hint')}
                                <br />
                                {t('object_name_format_example')}
                            </>
                        )
                    }}
                />
            </div>
            {/* {!isGeneral && (
                <div
                    className={clsx(
                        'modal__section--slimed',
                        isHouse && 'modal__section--slimed'
                    )}
                >
                    {isHouse ? (
                        <TextField
                            className="inline-m"
                            label="EGID"
                            name="egid"
                            value={form.egid}
                            onChange={handleInput}
                            width="100%"
                        />
                    ) : (
                        <>
                            <TextField
                                className="inline-m"
                                label={t('number_of_floors')}
                                name="floor"
                                value={form.floor}
                                error={errors['floor']}
                                onChange={handleInput}
                                width="40%"
                                required
                            />
                            <StdCheckbox
                                checked={form.include_basement}
                                onClick={() =>
                                    setInput((s) => ({
                                        ...s,
                                        form: {
                                            ...s.form,
                                            include_basement:
                                                !s.form.include_basement
                                        }
                                    }))
                                }
                            >
                                {t('include_basement')}
                            </StdCheckbox>
                        </>
                    )}
                </div>
            )} */}
            {!isGeneral && (
                <div className="modal__section--slimed">
                    <TextField
                        className="inline-m"
                        label="EGID"
                        name="egid"
                        value={form.egid}
                        onChange={handleInput}
                        width="100%"
                    />
                </div>
            )}
            {hasTemplates && !Boolean(dpd) && (
                <div>
                    <StdCheckbox
                        checked={form.use_template}
                        onClick={() =>
                            setInput((s) => ({
                                ...s,
                                form: {
                                    ...s.form,
                                    use_template: !s.form.use_template
                                }
                            }))
                        }
                    >
                        {t('use_template')} 
                    </StdCheckbox>
                </div>
            )}
            <div className="divider" />
            <div className="modal__section modal__section--slim">
                <div className="modal__heading stack-l">
                    {t('note').toUpperCase()}
                </div>
                <TextField
                    label={t('description')}
                    name="description"
                    value={form.description}
                    error={errors['description']}
                    onChange={handleInput}
                    width="100%"
                    rows={2}
                    rowsMax={2}
                    multiline
                />
            </div>
            {isHouse && (
                <>
                    <StdDivider />
                    <AddressEditForm
                        {...addressEditProps}
                        address={addressForm}
                        onGeoLocateSuccess={getGeoData}
                    />
                </>
            )}
            {showDetails && (
                <>
                    <StdDivider />
                    <div
                        className="modal__section modal__section--slim"
                        ref={attrRef}
                    >
                        <div className="modal__heading stack-l">
                            {t('description').toUpperCase()}
                        </div>
                        <EditAttributeList
                            {...attrListProps}
                            {...attrState}
                            attrData={attrData}
                        />
                    </div>
                    <StdDivider />
                    <div
                        className="modal__section modal__section--slim"
                        ref={attachRef}
                    >
                        <AttachmentGallery {...attachUploader} />
                    </div>
                </>
            )}
            <ConfirmModal
                open={confirmBasementOpen}
                onClose={() => setConfirmBasementOpen(false)}
                onConfirm={onConfirmEdit}
                isDestructive
            >
                <Trans t={t} components={{ br: <br /> }}>
                    {t('propety.basement_confirm_deletion_message')}
                </Trans>
            </ConfirmModal>
            <ConfirmModal
                open={confirmFloorOpen}
                onClose={() => setConfirmFloorOpen(false)}
                onConfirm={onConfirmEdit}
                isDestructive
            >
                <Trans t={t} components={{ br: <br /> }}>
                    {t('propety.floors_confirm_deletion_message')}
                </Trans>
            </ConfirmModal>
        </StdModal>
    );
};

export default EditObjectModal;
