import { getActionId } from '../storeModule';
import { replacePrimitve } from '../../modules/array';

export function storeReplaceId<T>(
    store: INormalizedList<T>,
    oldId: number,
    newId: number,
    entity: T
) {
    const allIds = replacePrimitve(store.allIds, oldId, newId);
    return {
        ...store,
        allIds,
        byIds: {
            ...store.byIds,
            [newId]: entity,
            [oldId]: undefined
        }
    };
}

export function storeMergeEntities<T>(
    store: INormalizedList<T>,
    entities: IHash<T>
) {
    return {
        ...store,
        byIds: {
            ...store.byIds,
            ...entities
        }
    };
}

export const storeAdd = <T>(
    store: INormalizedList<T>,
    newEntityId: number,
    item: T
) => {
    const { byIds, allIds } = store;
    const newIds = allIds.concat(newEntityId);
    const uniqueIds = newIds.filter(
        (id, index) => newIds.indexOf(id) === index // set only unique ids
    );

    return {
        ...store,
        byIds: {
            ...byIds,
            [newEntityId]: item
        },
        allIds: uniqueIds
    };
};

export const storeUpdate = <T>(
    store: INormalizedList<T>,
    itemId: number,
    entity: Partial<T>
): INormalizedList<T> => {
    const { byIds } = store;
    const prevItem = byIds[itemId];

    if (prevItem == null) return store;

    return {
        ...store,
        byIds: {
            ...byIds,
            [itemId]: {
                ...prevItem,
                ...entity
            }
        }
    };
};

export const storeDelete = <T>(
    store: INormalizedList<T>,
    entityId: number
): INormalizedList<T> => {
    const { allIds, byIds } = store;
    return {
        ...store,
        allIds: allIds.filter((id) => id !== entityId),
        byIds: {
            ...byIds,
            [entityId]: undefined
        }
    };
};

export function storeAddOrUpdate<T>(
    state: INormalizedState<T>,
    entityId: number,
    item: T
): INormalizedState<T> {
    const { allIds } = state;
    const hasItem = allIds.indexOf(entityId) !== -1;
    return {
        ...state, // Ignore ordering for now
        allIds: hasItem ? allIds : allIds.concat(entityId),
        byIds: {
            ...state.byIds,
            [entityId]: item
        }
    };
}

export function storePostFail<T>(
    state: INormalizedState<T>,
    action: AnyAction
): INormalizedState<T> {
    const localId = getActionId(action);
    const allIds = state.allIds.filter((id) => id !== localId);

    return {
        ...state,
        allIds,
        byIds: {
            ...state.byIds,
            [localId]: undefined
        }
    };
}

export function storeAddAssociation<T>(
    state: INormalizedState<T>,
    parentId: number,
    parentKey: keyof T,
    newId: number
) {
    const parent = state.byIds[parentId];
    if (!parent) return state;

    const field = parent[parentKey];
    if (Array.isArray(field)) {
        return {
            ...state,
            byIds: {
                ...state.byIds,
                [parentId]: {
                    ...parent,
                    [parentKey]: field.concat(newId)
                }
            }
        };
    }
    return {
        ...state,
        byIds: {
            ...state.byIds,
            [parentId]: {
                ...parent,
                [parentKey]: newId
            }
        }
    };
}

export function storeUpdateAssociation<T>(
    state: INormalizedState<T>,
    parentId: number,
    parentKey: keyof T,
    oldId: number,
    newId: number,
    replaceRequired: boolean = false
) {
    const parent = state.byIds[parentId];
    if (!parent) return state;

    const field = parent[parentKey];
    if (Array.isArray(field)) {
        const hasOldId = field.indexOf(oldId) !== -1;
        if (!hasOldId && replaceRequired) return state;

        const newValue = hasOldId
            ? replacePrimitve(field, oldId, newId)
            : field.concat(newId);
        return {
            ...state,
            byIds: {
                ...state.byIds,
                [parentId]: {
                    ...parent,
                    [parentKey]: newValue
                }
            }
        };
    }
    if (typeof field === 'number') {
        return {
            ...state,
            byIds: {
                ...state.byIds,
                [parentId]: {
                    ...parent,
                    [parentKey]: newId
                }
            }
        };
    }
    return state;
}

export function storeDeleteAssociation<T, K>(
    parentState: INormalizedState<T>,
    childState: INormalizedState<K>,
    parentAssociationKey: keyof T,
    childParentIdKey: keyof K,
    childId: number
) {
    const child = childState.byIds[childId];
    if (child == null) return parentState;

    const parentId = child[childParentIdKey];
    if (typeof parentId != 'number') return parentState;

    const parent = parentState.byIds[parentId];
    if (parent == null) return parentState;

    return storeClearAssociation(
        parentState,
        parentAssociationKey,
        parentId,
        childId
    );
}

export function storeClearAssociation<T>(
    state: INormalizedState<T>,
    key: keyof T,
    entityId: number,
    assocId: number
): INormalizedState<T> {
    const item = state.byIds[entityId];
    const value = item[key];

    // Has many
    if (Array.isArray(value)) {
        const newValue = value.filter((id) => id !== assocId);
        return {
            ...state,
            byIds: {
                ...state.byIds,
                [entityId]: {
                    ...item,
                    [key]: newValue
                }
            }
        };
    }

    // Has one
    if (Number.isInteger(value)) {
        return {
            ...state,
            byIds: {
                ...state.byIds,
                [entityId]: {
                    ...item,
                    [key]: undefined
                }
            }
        };
    }
    return state;
}
