import React, { ReactNode, memo } from 'react';
import clsx from 'clsx';
import { ANIMATE_HEIGHT_PROPS } from '../../../configs/costants';
import { motion, AnimatePresence } from 'framer-motion';

import StdButton from '../StdButton';
import StdIcon from '../StdIcon';
import StdInterpunct from '../StdInterpunct';

import './expandable-section.scss';

export interface IProps {
    open: boolean;
    title: ReactNode;
    count: ReactNode;
    actionTitle: React.ReactNode;
    className?: string;
    children?: ReactNode;
    wide?: boolean;
    onClick?: IClickHandler;
    onAction?: ICallback;
    onExitComplete?: ICallback;
    onAnimationComplete?: ICallback;
    onAnimationStart?: ICallback;
}

const ExpSection: React.FC<IProps> = (props) => {
    const {
        open,
        title,
        count,
        actionTitle,
        className,
        children,
        wide,
        onClick,
        onAction,
        onExitComplete,
        onAnimationComplete,
        onAnimationStart
    } = props;

    return (
        <div className={clsx(className)}>
            <div
                className={clsx(
                    'collapsible-list__header',
                    wide && 'collapsible-list__header--wide',
                    'stack-m'
                )}
            >
                <span className="collapsible-list__title" onClick={onClick}>
                    <span className="inline-xs">{title}</span>
                    <StdInterpunct />
                    <div className="collapsible-list__count">
                        <span className="inline-s">{count}</span>
                        <StdIcon
                            name={open ? 'chevron-up' : 'chevron-down'}
                            grey
                            small
                        />
                    </div>
                </span>
                <StdButton
                    leadingIcon={{ name: 'plus' }}
                    onClick={onAction}
                    width="170px"
                >
                    {actionTitle}
                </StdButton>
            </div>
            <AnimatePresence initial={false} onExitComplete={onExitComplete}>
                {open && (
                    <motion.div
                        className="collapsible-list__content"
                        onAnimationComplete={onAnimationComplete}
                        onAnimationStart={onAnimationStart}
                        {...ANIMATE_HEIGHT_PROPS}
                    >
                        {children}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default memo(
    ExpSection,
    (prevProps, nextProps) =>
        prevProps.open === nextProps.open &&
        prevProps.count === nextProps.count &&
        prevProps.children === nextProps.children
);
