import React, { useCallback, useLayoutEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';

import { isPropertyObject } from '../modules/property';
import {
    getOneDpd,
    postDpd,
    patchDpd,
    deleteDpd,
    dpdAttrCrud
} from '../store/action-creators';
import useDelayedPending from '../hooks/useDelayedPending';

import PropertyPage from './PropertyPage';
import ObjectPage from './ObjectPage';
import Loading from '../components/atoms/Loading';
import ErrorRouter from '../components/error/ErrorRouter';

interface IRoute {
    id: string;
    subdpd_id?: string;
}

const PropertyRoute: React.FC = () => {
    const dispatch = useDispatch();
    const { params } = useRouteMatch<IRoute>();

    // NOTE: to avoid redundant requests and have more control
    // over displayed items it might be benificial to only
    // request the parent and then lookup the child if neccessary,
    const id = params.subdpd_id ? params.subdpd_id : params.id;

    const dpdRes = useSelector((state) => state.inventory.property);
    const pageLoading = useDelayedPending([
        getOneDpd,
        postDpd,
        patchDpd,
        dpdAttrCrud,
        deleteDpd
    ]);

    const handleFetch = useCallback(() => {
        dispatch(getOneDpd(parseInt(id)));
    }, [dispatch, id]);

    useLayoutEffect(() => {
        handleFetch();
    }, [handleFetch]);

    const dpd = dpdRes.response;
    const error = dpdRes.error;

    if (pageLoading) return <Loading className="loading__page" size="xlarge" />;
    if (!pageLoading && (!dpd || error)) return <ErrorRouter error={error} />;

    const dpdVer = dpd as IDpd;

    return isPropertyObject(dpdVer) ? (
        <ObjectPage object={dpdVer} handleFetch={handleFetch} />
    ) : (
        <PropertyPage building={dpdVer} handleFetch={handleFetch} />
    );
};

export default PropertyRoute;
