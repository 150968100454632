import sanitize from 'sanitize-filename';

export function isVideoFile(file: File) {
    const [category] = file.type.split('/');
    return category === 'video';
}

export const bytesToMB = (bytes: number) => {
    const coefficient = 1024 * 1024;
    return bytes / coefficient;
};

// Note: used for regex consistency
// eslint-disable-next-line no-useless-escape
const invalidURIRegex =
    /(\;|\/|\?|\:|\@|\&|\=|\+|\$|\+|\$|\,|\{|\}|\||\\|\^|\[|\]|\`)/g;

export const escapeFilename = (filename: string, hasExtension?: boolean) => {
    if (hasExtension) {
        const extension = getExtension(filename);
        const name = omitExtension(filename);

        const escapedName = sanitize(name).replace(invalidURIRegex, '_');
        return `${escapedName}.${extension.toLowerCase()}`;
    }
    return sanitize(filename).replace(invalidURIRegex, '_');
};

const getFileHash = (hashedName: string) => {
    return hashedName.split('_')[0];
};

// returns [filename, pathPrefix]
const stripDirectories = (path: string): [string, string] => {
    const filepath = path.split('/');
    const filename = filepath.pop() || '';
    const pathPrefix = filepath.join('/');

    return [filename, pathPrefix];
};

export const getExtension = (filename: string) => {
    const extensionArr = filename.split('.');
    return extensionArr[extensionArr.length - 1];
};

export const omitExtension = (filename: string) => {
    const extension = getExtension(filename);
    return filename.substring(0, filename.length - extension.length - 1);
};

export const getHashStartIndex = (hashedName: string) => {
    return getFileHash(hashedName).length + 1;
};

export const renamePath = (path: string, newName: string) => {
    const [hashFilename, pathPrefix] = stripDirectories(path);
    const extension = getExtension(hashFilename);

    const hashIndex = getHashStartIndex(hashFilename);
    const newFilename = hashFilename
        .substring(0, hashIndex)
        .concat(newName)
        .concat('.')
        .concat(extension);

    return pathPrefix.concat('/').concat(newFilename);
};

export const pathToName = (path: string) => {
    const [hashFilename] = stripDirectories(path);

    return hashFilename.substring(getHashStartIndex(hashFilename));
};

export function renameUploadKey(oldKey: string, newFilename: string) {
    const escapedName = escapeFilename(newFilename);
    const newKey = renamePath(oldKey, escapedName);

    const extension = getExtension(oldKey);
    const newName = `${escapedName}.${extension}`;

    return { newKey, newName };
}

export function toArrayBuffer(file: File): Promise<ArrayBuffer> {
    if (!('File' in window))
        return Promise.reject(new Error('File api not supported'));

    if ('arrayBuffer' in file) {
        return file.arrayBuffer();
    }
    return new Promise((resolve, reject) => {
        let fr = new FileReader();
        fr.onload = () => {
            if (!(fr.result instanceof ArrayBuffer)) {
                const resultType = typeof fr.result;
                reject(
                    new Error(
                        `Incorrect result type. Expected ArrayBuffer got ${resultType}`
                    )
                );
                return;
            }
            resolve(fr.result);
        };
        fr.readAsArrayBuffer(file);
    });
}

export function fileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.addEventListener('load', () => {
            if (typeof reader.result === 'string') {
                resolve(reader.result);
            } else {
                reject(
                    new Error(
                        `Incorrect result type. Expected string got ${reader.result}`
                    )
                );
            }
        });

        reader.addEventListener('error', () => {
            reject(new Error('File reader error'));
        });

        reader.readAsDataURL(file);
    });
}

export function base64ToImageData(
    base64: string
    // callback: (imageData: ImageData | null, error: Error | null) => void
): void {
    // Create an image element
    const img = new Image();

    img.onload = () => {
        // Create a canvas element
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        if (!context) {
            // callback(null, new Error('Failed to get canvas context'));
            return null;
        }

        // Set the canvas dimensions to the image dimensions
        canvas.width = img.width;
        canvas.height = img.height;

        // Draw the image onto the canvas
        context.drawImage(img, 0, 0);

        // Get the ImageData from the canvas
        const imageData = context.getImageData(
            0,
            0,
            canvas.width,
            canvas.height
        );

        // Call the callback with the ImageData
        // callback(imageData, null);
        return imageData;
    };

    // Handle image loading errors
    img.onerror = () => {
        // callback(null, new Error('Failed to load image'));
        return null;
    };

    // Set the image source to the Base64 string
    img.src = base64;
}
