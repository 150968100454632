import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import StdButton from '../../atoms/StdButton';

import './trial-plan.scss';

export interface IFeatureCell {
    title: string;
    subtitle?: string;
}

interface IProps {
    name: string;
    price: string;
    highlighted?: boolean;
    isCurrent?: boolean;
    onSelect?: ICallback;
}

const TrialPlan: React.FC<IProps> = ({
    name,
    price,
    highlighted,
    isCurrent,
    onSelect
}) => {
    const { t } = useTranslation();

    const actionTitle = isCurrent ? t('current_plan') : t('choose_plan');
    return (
        <div
            className={clsx(
                'trial-plan__wrapper',
                highlighted && 'trial-plan__wrapper--highlighted'
            )}
        >
            <div
                className={clsx(
                    'trial-plan__container',
                    highlighted && 'trial-plan__container--highlighted'
                )}
            >
                <div
                    className={clsx(
                        'trial-plan__header-container',
                        highlighted &&
                            'trial-plan__header-container--highlighted'
                    )}
                >
                    <div className="trial-plan__title stack-m">{name}</div>
                    <div className="trial-plan__price stack-s">{price}</div>
                    <div className="trial-plan__duration stack-l">
                        {t('per_month')}
                    </div>
                    <StdButton
                        type={highlighted ? 'primary' : 'secondary'}
                        width="100%"
                        onClick={onSelect}
                        disabled={isCurrent}
                    >
                        {actionTitle}
                    </StdButton>
                </div>
            </div>
        </div>
    );
};

export default TrialPlan;
