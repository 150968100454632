import { AnyAction } from 'redux';
import { getAsyncActName } from '../storeModule';

// Loading indicator reducer, looks at action type to match request and result action types
// fetchType is used to tell apart loading of requests for the same endpoint

const actStatusReducer = (state: IHash<boolean> = {}, action: AnyAction) => {
    const { type, __handler } = action;
    if (__handler?.disableLoading) return state;

    const actType = typeof type === 'string' ? type : '';
    const requestLabel = getAsyncActName(actType);

    const fetchType = action.fetchType || action.originator?.fetchType;

    const fetchName =
        fetchType && typeof fetchType === 'string' ? fetchType : '';
    const entryLabel = fetchName
        ? requestLabel.concat('-', fetchName)
        : requestLabel;

    if (actType.endsWith('_REQUEST')) {
        return {
            ...state,
            [entryLabel]: true
        };
    } else if (
        actType.endsWith('_SUCCESS') ||
        actType.endsWith('_FAIL') ||
        actType.endsWith('_SKIP')
    ) {
        return {
            ...state,
            [entryLabel]: false
        };
    }
    return state;
};

export default actStatusReducer;
