import { takeEvery } from 'redux-saga/effects';

import { DELETE_PROTOCOL_REQUEST } from '../../actions';
import {
    delProtocol,
    delProtocolSuccess,
    delProtocolFail
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeEvery(
    DELETE_PROTOCOL_REQUEST,
    simpleRequest<RT<typeof delProtocol>>({
        success: delProtocolSuccess,
        failure: delProtocolFail,
        getUrl: (action) => `protocol/${action.protoId}/protocol_delete/`,
        method: 'DELETE'
    })
);
