import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import { getIssues, getIssue } from '../../../store/action-creators';

import { safeByIds } from '../../../store/storeModule';
import { trasnslateIssue } from '../../../utils/componentInterfaces';
import {
    handleShareIssue,
    handleFixIssue,
    handleDeleteIssue
} from '../../../utils/requests';

import { createIssueLink, getAllIssue } from '../../../store/action-creators';

import IssueModule from '../../../modules/issue';

import useToggle from '../../../hooks/useToggle';
import useDelayedPending from '../../../hooks/useDelayedPending';
import useMatchMedia from '../../../hooks/useMatchMedia';

import LazyLoad from 'react-lazyload';
import { MenuSurfaceAnchor, MenuItem } from '@rmwc/menu';

import IssueCard from '../IssueCard';
import EditIssueModal from '../EditIssueModal';
import IssueModal from '../IssueModal';
import ConfirmDeletionModal from '../../molecules/ConfirmDeletionModal';
import Loading from '../../atoms/Loading';
import StdDropdown from '../../atoms/StdDropdown';
import IssueSharingDisabledFlow from '../IssueSharingDisabledFlow';
import StdDivider from '../../atoms/StdDivider';
import StdButton from '../../atoms/StdButton';

import './issue-view.scss';
import i18n from '../../../i18next';

function categoryToView(category: IIssueFilter, issues: IIssue[]) {
    switch (category) {
        case 'unresolved':
            return {
                title: 'issue_category.unresolved',
                list: issues.filter(IssueModule.isOpen)
            };
        case 'resolved':
            return {
                title: 'issue_category.resolved',
                list: issues.filter(IssueModule.isClosed)
            };
        case 'light':
            return {
                title: 'light_wear',
                list: issues.filter(IssueModule.isNormal)
            };
        case 'excessive':
            return {
                title: 'excessive_wear',
                list: issues.filter(IssueModule.isExcessive)
            };
        default:
            return {
                title: '',
                list: []
            };
    }
}

interface IProps {
    filter?: IIssueFilter;
    initIssueId?: number;
    dpdLink?: string;
    onIssuesLoaded?: ICallback;
}

const IssueView: React.FC<IProps> = ({
    filter = 'unresolved',
    initIssueId,
    dpdLink,
    onIssuesLoaded
}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { t } = useTranslation();

    const isPhone = useMatchMedia('mobile');

    const [issCatOpen, setIssCatOpen] = useState(false);
    const [sharingPlanOpen, setSharingPlanOpen] = useState(false);

    const uploadStore = useSelector((state) => state.uploads.uploads);
    const user = useSelector((state) => state.user.user.response);

    const issueState = useSelector((state) => state.inventory.issues.object);
    const issues = safeByIds(issueState.allIds, issueState.byIds);

    const issuesLoading = useDelayedPending([getIssues]);

    const [add, onAddOpen, onAddClose] = useToggle<'light' | 'excessive'>();
    const [del, onDelOpen, onDelClose] = useToggle<IIssue>();
    const [edited, onEditOpen, onEditClose] = useToggle<IIssue>();
    const [viewed, onViewOpen, onViewClose] = useToggle<IIssue>();

    const account = useSelector((state) => state.user.user.response);
    const isSharingEnabled = Boolean(account?.permissions.issues.share);
    const isManualOffline = useSelector((state) => state.appState.isManualOffline)

    const isSharing = useDelayedPending([createIssueLink, getAllIssue]);

    const lang = i18n.language as ILang;
    const fetchIssue = useCallback(
        (id: number) => {
            dispatch(getIssue(id, lang));
        },
        [dispatch, lang]
    );

    const urlIssue =
        (!issuesLoading &&
            initIssueId != null &&
            issues.find((issue) => issue.iss_id === initIssueId)) ||
        null;
    useEffect(() => {
        if (initIssueId != null) {
            fetchIssue(initIssueId);
        }
    }, [fetchIssue, initIssueId]);

    useEffect(() => {
        if (urlIssue) {
            onViewOpen(urlIssue);
        } else {
            onViewClose();
        }
    }, [urlIssue, onViewOpen, onViewClose]);

    const viewdIssue =
        (viewed.open &&
            issues.find(
                (issue) => viewed.item && viewed.item.iss_id === issue.iss_id
            )) ||
        null;
    useEffect(() => {
        if (viewdIssue) {
            onViewOpen(viewdIssue);
        }
    }, [viewdIssue, onViewOpen]);

    useEffect(() => {
        if (!issuesLoading) onIssuesLoaded?.();
    }, [filter, issuesLoading, onIssuesLoaded]);

    const onView = (issue: IIssue) => {
        history.push({ search: `?category=${filter}&issue=${issue.iss_id}` });
    };

    const onIssueClose = () => {
        history.push({ search: `?category=${filter}` });
    };

    const onAddIssueClick = () => {
        if (filter !== 'light' && filter !== 'excessive') {
            setIssCatOpen(true);
        } else {
            onAddOpen(filter);
        }
    };

    const onIssueFix = () => {
        const { item } = viewed;

        if (item) {
            handleFixIssue({
                dispatch,
                id: item.iss_id,
                isOffline: isManualOffline
            });
        }
    };

    const onIssueShare = (issue: IIssue) => {
        if (isSharing) return;

        if (!isSharingEnabled) {
            setSharingPlanOpen(true);
            return;
        }
        const id = issue.iss_id;

        onView(issue);
        handleShareIssue({ t, dispatch, user, id });
    };

    const onIssueDelete = () => {
        const { item } = del;
        if (!item) return;

        handleDeleteIssue({
            dispatch,
            id: item.iss_id
        });
        onDelClose();
    };

    const issueTuple = categoryToView(filter, issues);
    return (
        <div className="issue-view">
            <div className="issue-view__header">
                <span className="font__header">{t(issueTuple.title)}</span>
            </div>
            {issuesLoading ? (
                <>
                    <StdDivider />
                    <Loading className="loading__issue" size="large" />
                </>
            ) : (
                <>
                    {issueTuple.list.map((issue) => {
                        return (
                            <Fragment key={issue.iss_id}>
                                <StdDivider />
                                <LazyLoad
                                    scrollContainer={
                                        !isPhone ? '.object__main' : undefined
                                    }
                                    height={176}
                                    offset={100}
                                    once
                                >
                                    <IssueCard
                                        issueData={trasnslateIssue(
                                            issue,
                                            uploadStore
                                        )}
                                        onView={() => onView(issue)}
                                        onEdit={() => onEditOpen(issue)}
                                        onDelete={() => onDelOpen(issue)}
                                        onShare={() => onIssueShare(issue)}
                                        sharingDisabled={!isSharingEnabled}
                                        editable
                                        highlight
                                        width="100%"
                                    />
                                </LazyLoad>
                            </Fragment>
                        );
                    })}
                    {filter !== 'resolved' && (
                        <>
                            <StdDivider />
                            <MenuSurfaceAnchor>
                                <StdDropdown
                                    open={issCatOpen}
                                    onClose={() => setIssCatOpen(false)}
                                    onSelect={(e) => {
                                        const {
                                            detail: { item }
                                        } = e;
                                        const value = item.dataset['value'];
                                        switch (value) {
                                            case 'add_normal':
                                                onAddOpen('light');
                                                break;
                                            case 'add_excessive':
                                                onAddOpen('excessive');
                                                break;
                                        }
                                    }}
                                >
                                    <MenuItem data-value="add_excessive">
                                        {t('excessive_wear')}
                                    </MenuItem>
                                    <MenuItem data-value="add_normal">
                                        {t('light_wear')}
                                    </MenuItem>
                                </StdDropdown>
                                <div className="object__action">
                                    <StdButton
                                        leadingIcon={{ name: 'plus' }}
                                        onClick={onAddIssueClick}
                                    >
                                        {t('issue')}
                                    </StdButton>
                                </div>
                            </MenuSurfaceAnchor>
                        </>
                    )}
                </>
            )}
            <EditIssueModal
                defaultCategory={add.item}
                open={add.open}
                onClose={onAddClose}
                dpdLink={dpdLink}
            />
            <EditIssueModal
                issue={edited.item}
                open={edited.open}
                onClose={onEditClose}
                dpdLink={dpdLink}
            />
            <IssueModal
                issue={viewed.item}
                open={viewed.open}
                onClose={onIssueClose}
                onShare={() => {
                    if (viewed.item) {
                        onIssueShare(viewed.item);
                    }
                }}
                onFix={onIssueFix}
                onEdit={() => {
                    if (viewed.item) onEditOpen(viewed.item);
                }}
                onDelete={() => {
                    if (viewed.item) onDelOpen(viewed.item);
                }}
                sharingDisabled={!isSharingEnabled}
                editable
            />
            <ConfirmDeletionModal
                open={del.open}
                onClose={onDelClose}
                message={t('delete_issue_message', {
                    name: del.item?.iss_title || '--'
                })}
                onConfirm={onIssueDelete}
            />
            <IssueSharingDisabledFlow
                open={sharingPlanOpen}
                onClose={() => setSharingPlanOpen(false)}
            />
        </div>
    );
};

export default IssueView;
