import { all } from 'redux-saga/effects';
import deleteDpd from './delete';
import getAllDpds from './getAll';
import getLocationCH from './getLocationCH';
import getOneDpd from './getOne';
import patchDpd from './patch';
import postDpd from './post';
import dpdAttrCrud from './attributeCrud';
import copyDpd from './copy';

export default all([
    deleteDpd,
    getAllDpds,
    getLocationCH,
    getOneDpd,
    patchDpd,
    postDpd,
    dpdAttrCrud,
    copyDpd
]);
