import { takeEvery, all } from 'redux-saga/effects';

import { POST_ATTENDEE_REQUEST, POST_ATTENDEE_SUCCESS } from '../../actions';
import {
    postAttendee,
    postAttendeeSuccess,
    postAttendeeFail
} from '../../action-creators';

import { simpleRequest } from '../modules/request';
import { normalizeAttendee } from '../modules/normalization';
import { responseHelper } from '../modules/promise';

function onSuccessCallback(data: any) {
    return normalizeAttendee(data);
}

const requestEffect = takeEvery(
    POST_ATTENDEE_REQUEST,
    simpleRequest<RT<typeof postAttendee>>({
        success: postAttendeeSuccess,
        failure: postAttendeeFail,
        getUrl: (_) => 'attendee/attendee_create/',
        getBody: (action) => action.attendee,
        method: 'POST',
        enableOffline: true
    })
);

const responseEffect = responseHelper({
    actionName: POST_ATTENDEE_SUCCESS,
    processFn: onSuccessCallback
});

export default all([requestEffect, responseEffect]);
