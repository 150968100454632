import React, { memo } from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconPrefix, IconName } from '@fortawesome/fontawesome-common-types';

import './std-icon.scss';

interface IProps {
    className?: string;
    prefix?: IconPrefix;
    name: IconName;
    clickable?: boolean;
    grey?: boolean;
    small?: boolean;
    onClick?: IClickHandler;
}

const StdIcon: React.FC<IProps> = ({
    className,
    prefix = 'fal',
    name,
    clickable,
    small,
    grey,
    onClick
}) => {
    const iconClass = clsx(
        'std-icon',
        clickable && 'std-icon--clickable',
        small && 'std-icon--small',
        grey && 'std-icon--secondary',
        className
    );

    return (
        <div className={iconClass} onClick={onClick}>
            <FontAwesomeIcon className="icon-override" icon={[prefix, name]} />
        </div>
    );
};

const memoFn = (prevProps: IProps, nextProps: IProps) => {
    return (
        prevProps.name === nextProps.name &&
        prevProps.prefix === nextProps.prefix &&
        prevProps.onClick === nextProps.onClick &&
        prevProps.className === nextProps.className
    );
};

export default memo(StdIcon, memoFn);
