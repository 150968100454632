import { takeLatest, call } from 'redux-saga/effects';
import { normalize } from 'normalizr';

import { GET_ELEMENT_REQUEST } from '../../actions';
import {
    getElementSuccess,
    getElementFail,
    IGetElement
} from '../../action-creators';
import { simpleRequest, ISuccessCallback } from '../modules/request';

import { fetchFailError } from '../../../modules/errorHandlers';
import { justArray } from '../../../utils';
import { elementSchema } from '../../reducers/schema';

const onSuccess: ISuccessCallback<IGetElement> = function (data) {
    const { result, entities } = normalize(justArray(data.results), [
        elementSchema
    ]);
    const { elements = {}, uploads = {} } = entities;

    return {
        response: {
            ...data,
            results: result.map((key: any) => elements[key])
        },
        uploads
    };
};

export default takeLatest(
    GET_ELEMENT_REQUEST,
    simpleRequest<IGetElement>({
        success: getElementSuccess,
        failure: getElementFail,
        getUrl: (action) =>
            `room/${action.roomId}/elements/?lang=${action.lang}`,
        onFail: function* (error: INetworkError) {
            yield call(fetchFailError, 'fetch_elements_error', error);
        },
        onSuccess
    })
);
