import { PER_PAGE, STORE_PAGE_INIT, STORE_MAX } from '../configs/costants';
import { mergeArrays } from './array';

// TODO: rework
// The current implementation is very simplistic (it just fetches more)
// which could lead to duplicate items easily

export function getPaginationParams(
    info: IStoredPagination,
    direction?: IPaginateDirection
) {
    const offset = getPageOffset(info.startPage, info.page, direction);
    return {
        offset,
        limit: PER_PAGE
    };
}

export function getPageLoading(pagination: IStoredPagination) {
    const { page, direction } = pagination;

    if (page >= 1 && direction === 'backwards') return 'start';
    if (page >= 1 && direction === 'forwards') return 'end';
    return 'middle';
}

export function getPageOffset(
    startPage: number,
    page: number,
    dir?: IPaginateDirection,
    limit: number = PER_PAGE
) {
    if (!dir) return 0;

    const index = dir === 'backwards' ? startPage - 1 : page;
    return limit * index;
}

export function onPaginateStart<T>(
    store: IPaginatedResult<T>,
    direction?: IPaginateDirection
) {
    const pageParams = direction ? {} : STORE_PAGE_INIT;
    return {
        ...store,
        paginate: {
            ...store.paginate,
            ...pageParams,
            direction
        }
    };
}

interface IPayloadAction<T> {
    count: number;
    results: T[];
}

// Utility pagination function to avoid having to manually set all the fields on store
export function paginateStore<T>(
    store: IPaginatedResult<T>,
    response: IPayloadAction<T>
): IPaginatedResult<T> {
    const dataParams = {
        storedItems: store.response.results,
        pageData: store.paginate,
        newItems: response.results,
        totalItems: response.count
    };
    const { updatedItems, paginationStatus } = paginateData(dataParams);

    return {
        ...store,
        response: {
            ...store.response,
            ...response,
            results: updatedItems
        },
        paginate: {
            ...store.paginate,
            ...paginationStatus
        }
    };
}

function limitArray<T>(items: T[], direction: IPaginateDirection) {
    if (direction === 'backwards') {
        return items.slice(0, STORE_MAX - PER_PAGE);
    }
    return items.slice(PER_PAGE);
}

interface IPaginateDataParams<T> {
    storedItems: T[];
    newItems: T[];
    totalItems: number;
    pageData: IStoredPagination;
}

function paginateData<T>(params: IPaginateDataParams<T>) {
    const { totalItems, storedItems, newItems, pageData } = params;
    const { direction } = pageData;

    const prevStartPage = pageData.startPage;
    const prevPage = pageData.page;

    if (!direction) {
        const pageInfoParams = {
            direction,
            totalItems,
            startPage: STORE_PAGE_INIT.startPage,
            oldPage: STORE_PAGE_INIT.page
        };

        const paginationStatus = getPageInfo(pageInfoParams);

        return {
            updatedItems: newItems,
            paginationStatus
        };
    }

    const isOver = storedItems.length + newItems.length > STORE_MAX;

    const currentItems = isOver
        ? limitArray(storedItems, direction)
        : storedItems;
    const updatedItems = mergeArrays(
        currentItems,
        newItems,
        direction === 'backwards'
    );

    const updatedStartPage =
        direction === 'backwards' ? prevStartPage - 1 : prevStartPage + 1;
    const startPage = isOver ? updatedStartPage : prevStartPage;

    const paginationStatus = getPageInfo({
        totalItems,
        startPage,
        direction,
        oldPage: prevPage
    });

    return {
        updatedItems,
        paginationStatus
    };
}

interface IPageInfoParams {
    totalItems: number;
    startPage: number;
    oldPage: number;
    direction?: IPaginateDirection;
}

function getPageInfo(params: IPageInfoParams) {
    const { totalItems, startPage, oldPage, direction } = params;

    const page = direction === 'backwards' ? oldPage - 1 : oldPage + 1;
    const endPage = Math.ceil(totalItems / PER_PAGE);

    const hasNext = page < endPage;
    const hasPrevious = startPage > 0;
    return {
        startPage,
        page,
        endPage,
        hasNext,
        hasPrevious
    };
}
