import React, { useState, useCallback, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { dispatchAsync } from '../../store/storeModule';
import { getFullname } from '../../modules/account';
import {
    getCurrentAcc,
    showSnackbar,
    changeSubscription,
    getBilling,
    updateBilling
} from '../../store/action-creators';
import { parseStreetAddress } from '../../modules/property';

import { DASHBOARD_ROUTE } from '../../configs/routes';
import usePending from '../../hooks/usePending';

import TrialPlanSelect from './TrialPlanSelect';
import TrialBilling, { IBillingForm } from './TrialBilling';
import StdOverlay from '../atoms/StdOverlay';
import TrialSubscribeSuccess from './TrialSubscribeSuccess';

interface IProps {
    open: boolean;
    currentPackage?: IPackage | null;
    onClose?: ICallback;
    onSubscribe?: ICallback;
}

type IFlowStep =
    | { type: 'plan_select' }
    | { type: 'billing'; plan: IPackage }
    | { type: 'subscribe_success'; plan: IPackage };

const TrialSubscribeFlow: React.FC<IProps> = ({
    open,
    currentPackage,
    onClose,
    onSubscribe
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();

    const [step, setStep] = useState<IFlowStep>({ type: 'plan_select' });

    const isEditing = Boolean(currentPackage);

    const account = useSelector((state) => state.user.user.response);
    const billing = useSelector((state) => state.user.billing.response);

    const plans = useSelector((state) => state.user.packages.response);

    const billingLoading = usePending([getBilling]);
    const accLoading = usePending([getCurrentAcc]);

    useLayoutEffect(() => {
        if (open && !billing) dispatch(getBilling());
    }, [dispatch, open, billing]);

    const onBillingInit = useCallback(
        (state: IBillingForm) => {
            if (!billingLoading && billing) {
                const {
                    city,
                    country,
                    full_name,
                    region,
                    street,
                    street_number,
                    zip
                } = billing;
                return {
                    ...state,
                    form: {
                        ...state.form,
                        name: full_name || '',
                        city: city || '',
                        region: region || '',
                        country: country || '',
                        street: `${street || ''} ${street_number || ''}`.trim(),
                        zip: zip || ''
                    }
                };
            } else if (!accLoading && account) {
                const { firstname, lastname } = account;
                return {
                    ...state,
                    form: {
                        ...state.form,
                        name: getFullname(firstname, lastname)
                    }
                };
            }
            return state;
        },
        [account, accLoading, billingLoading, billing]
    );

    const handleSubscribe = (form: IBillingForm['form']) => {
        if (step.type === 'billing') {
            const { country, city, region, zip, street, name } = form;
            const { street_name, street_num } = parseStreetAddress(street);

            const address = {
                full_name: name,
                street: street_name,
                street_number: street_num,
                country,
                zip,
                city,
                region
            };

            dispatchAsync(dispatch, updateBilling(address))
                .then((_) =>
                    dispatchAsync(
                        dispatch,
                        changeSubscription({ plan: step.plan.id })
                    )
                )
                .then((_) => dispatch(getCurrentAcc()))
                .then((_) =>
                    setStep({ type: 'subscribe_success', plan: step.plan })
                )
                .catch(({ error }) =>
                    dispatch(
                        showSnackbar({
                            message: isEditing
                                ? 'on_change_subscription_fail'
                                : 'on_subscribe_fail',
                            error
                        })
                    )
                );
        }
    };

    const handleFinishSubscription = () => {
        onClose?.();
        onSubscribe?.();

        history.push(DASHBOARD_ROUTE);
    };

    return (
        <StdOverlay open={open}>
            {step.type === 'plan_select' && (
                <TrialPlanSelect
                    plans={plans || []}
                    currentPackage={currentPackage}
                    onPlanSelect={(plan) =>
                        setStep({ type: 'billing', plan: plan })
                    }
                    onBack={onClose}
                />
            )}
            {step.type === 'billing' && (
                <TrialBilling
                    headerText={
                        isEditing
                            ? t('check_details_message')
                            : t('enter_payment_message')
                    }
                    plan={step.plan}
                    onBack={() => setStep({ type: 'plan_select' })}
                    handleSubmit={handleSubscribe}
                    handleInit={onBillingInit}
                />
            )}
            {step.type === 'subscribe_success' && (
                <TrialSubscribeSuccess
                    plan={t(`plan.backend.name.${step.plan.identifier}`)}
                    onContinue={handleFinishSubscription}
                />
            )}
        </StdOverlay>
    );
};

export default TrialSubscribeFlow;
