import { takeEvery } from 'redux-saga/effects';
import { GET_VERSION_REQUEST } from '../../actions';
import {
    getVersion,
    getVersionSuccess,
    getVersionFail
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeEvery(
    GET_VERSION_REQUEST,
    simpleRequest<RT<typeof getVersion>>({
        success: getVersionSuccess,
        failure: getVersionFail,
        getUrl: () => `version/`,
        withAuth: false
    })
);
