import { useState, useCallback } from 'react';

export interface IToggle<T> {
    issues?: Array<object>;
    open: boolean;
    item?: T;
}

interface IParams<T> {
    initState?: IToggle<T>;
    cleanupOnChange?: boolean;
}

const state = { open: false, item: undefined };

const initParams = {
    initState: state,
    cleanupOnChange: false
};

const useToggle = <T>(
    userParams: IParams<T> = {}
): [IToggle<T>, (item?: T) => void, () => void] => {
    const params = {
        ...initParams,
        ...userParams
    };
    const { initState, cleanupOnChange } = params;

    const [item, setItem] = useState<IToggle<T>>(initState);

    const onOpen = useCallback((item?: T) => {
        setItem({ open: true, item });
    }, []);

    const onClose = useCallback(() => {
        setItem((s) => {
            const literal = cleanupOnChange ? initState : s;
            return {
                ...literal,
                open: false
            };
        });
    }, [initState, cleanupOnChange]);

    return [item, onOpen, onClose];
};

export default useToggle;
