import { takeEvery } from 'redux-saga/effects';

import { PATCH_MEDIA_REQUEST } from '../../actions';
import {
    patchMedia,
    patchMediaSuccess,
    patchMediaFail
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeEvery(
    PATCH_MEDIA_REQUEST,
    simpleRequest<ReturnType<typeof patchMedia>>({
        success: patchMediaSuccess,
        failure: patchMediaFail,
        getUrl: (action) => `media/${action.medId}/`,
        getBody: (action) => action.payload,
        method: 'PATCH',
        enableOffline: true
    })
);
