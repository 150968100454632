import { debounce, put } from 'redux-saga/effects';
import qs from 'query-string';

import { CHECK_ACTIVE_REQUEST } from '../../actions';
import {
    checkActive,
    checkActiveSuccess,
    checkActiveFail,
    sessionStart
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default debounce(
    1000,
    CHECK_ACTIVE_REQUEST,
    simpleRequest<RT<typeof checkActive>>({
        success: checkActiveSuccess,
        failure: checkActiveFail,
        getUrl: (action) =>
            `auth-users/is_activated/?${qs.stringify({
                email: action.email,
                uid: action.uid
            })}`,
        method: 'GET',
        withAuth: false,
        onSuccess: function* (data) {
            const { access, refresh } = data || {};
            yield put(sessionStart({ access, refresh }, { verified: true }));
        },
        ignoreError: true
    })
);
