import React, { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';

import { useHistory } from 'react-router';

import {
    getRoom,
    postRoom,
    showSnackbar
} from '../../../store/action-creators';
import { roomToIcon } from '../../../modules/rooms';
import { dispatchAsync } from '../../../store/storeModule';

import useArraySelector from '../../../hooks/useArraySelector';

import { MenuSurfaceAnchor } from '@rmwc/menu';
import {
    DragDropContext,
    Draggable,
    Droppable,
    DropResult
} from 'react-beautiful-dnd';

import StdListItem from '../../../components/atoms/StdListItem';
import StdDropdown from '../../../components/atoms/StdDropdown';
import TemplateList from '../../../components/inventory/TemplateList';
import StdButton from '../../atoms/StdButton';
import i18n from '../../../i18next';

interface IProps {
    dpdId: number;
    currentFloor: number;
    rooms: IRoom[];
    viewedRoomId?: number;
    onRoomDragEnd: (result: DropResult) => void;
    onAddCustomSelect: ICallback;
}

const ObjectFloor: React.FC<IProps> = ({
    dpdId,
    rooms,
    currentFloor,
    viewedRoomId,
    onRoomDragEnd,
    onAddCustomSelect
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const lang = i18n.language as ILang;

    const [addingOpen, setAddingOpen] = useState(false);

    const [templates] = useArraySelector(
        (state) => state.inventory.roomTemplates
    );

    const handleRoomQuickAdd = (template: ITemplate) => {
        const { name, type_for_icon, pk } = template;

        const roomLiteral = {
            template_name: pk,
            rom_dpd_id: dpdId,
            rom_level: currentFloor,
            rom_name: pk,
            rom_type: type_for_icon || undefined
        };
        dispatchAsync(dispatch, postRoom(roomLiteral))
            .then((action: any) => {
                dispatch(getRoom(dpdId, lang));
                const roomId = action.payload.rom_id;
                if (roomId != null) history.push({ search: `?room=${roomId}` });
            })
            .catch(({ error }) =>
                dispatch(
                    showSnackbar({ message: 'room_add_fail_message', error })
                )
            );
        setAddingOpen(false);
    };

    const onTemplateSelect = (e: ISelectEvent) => {
        const {
            detail: { item }
        } = e;
        const { value } = item.dataset;

        if (!value) return;

        if (value === '__custom') {
            onAddCustomSelect();
            return;
        }

        const template = templates.find((template) => template.name === value);
        if (template) handleRoomQuickAdd(template);
    };

    return (
        <Fragment>
            <DragDropContext onDragEnd={onRoomDragEnd}>
                <Droppable droppableId={`droppable-floor-${currentFloor}`}>
                    {(provided, snapshot) => {
                        return (
                            <div
                                className={clsx(
                                    snapshot.isDraggingOver && 'dragging-list'
                                )}
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {rooms.map((room, index) => {
                                    const {
                                        rom_id,
                                        rom_name,
                                        rom_type,
                                        rom_ele_count,
                                        rom_has_excessive_issue
                                    } = room;

                                    return (
                                        <Draggable
                                            key={rom_id}
                                            draggableId={rom_id.toString()}
                                            index={index}
                                        >
                                            {(provided, snapshot) => {
                                                return (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        // Note: quite clearly a typing bug of some sort
                                                        {...(provided.dragHandleProps as any)}
                                                    >
                                                        <StdListItem
                                                            key={rom_id}
                                                            icon={roomToIcon(
                                                                rom_type || ''
                                                            )}
                                                            textData={{
                                                                main:
                                                                    rom_name ||
                                                                    '---',
                                                                sub: `${rom_ele_count} ${t(
                                                                    'elements'
                                                                )}`
                                                            }}
                                                            onClick={() =>
                                                                history.push({
                                                                    search: `?room=${rom_id}`
                                                                })
                                                            }
                                                            active={
                                                                viewedRoomId !=
                                                                    null &&
                                                                viewedRoomId ===
                                                                    rom_id
                                                            }
                                                            badge={Boolean(
                                                                rom_has_excessive_issue
                                                            )}
                                                            dragging={
                                                                snapshot.isDragging
                                                            }
                                                        />
                                                    </div>
                                                );
                                            }}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </div>
                        );
                    }}
                </Droppable>
            </DragDropContext>
            <div className="object__action">
                <MenuSurfaceAnchor>
                    <StdDropdown
                        open={addingOpen}
                        onClose={() => setAddingOpen(false)}
                        onSelect={onTemplateSelect}
                    >
                        <TemplateList templates={templates} />
                    </StdDropdown>
                    <StdButton
                        leadingIcon={{ name: 'plus' }}
                        onClick={() => setAddingOpen(true)}
                    >
                        {t('room')}
                    </StdButton>
                </MenuSurfaceAnchor>
            </div>
        </Fragment>
    );
};

export default ObjectFloor;
