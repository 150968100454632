import * as actions from '../actions/issue';
import { createCustomAction } from 'typesafe-actions';
import { actionFail, actionPayload } from '../storeModule';

type ModifyableFields = Pick<
    IIssue,
    | 'title'
    | 'fixcost'
    | 'agreedshare'
    | 'date_occurred'
    | 'fix'
    | 'handling'
    | 'description'
    | 'grade'
>;

type IModifiableIssue = ModifyableFields & {
    piss_rom_id?: number | null;
    piss_ele_id?: number | null;
    piss_pele_id?: number | null;
    piss_prom_id?: number | null;
    protocol_element?: number | null;
    protocol_room?: number | null;
    protocol_item?: number | null;
    piss_itm_id?: number | null;
    piss_pitm_id?: number | null;
    iss_rom_id?: number | null;
    iss_ele_id?: number | null;
    iss_pele_id?: number | null;
    iss_prom_id?: number | null;
    iss_itm_id?: number | null;
    iss_pitm_id?: number | null;
    medias?: number[] | null;
    iss_fix?: boolean;
};

export interface IFilterParams {
    iss_fix?: boolean;
    iss_grade?: string;
    lang: ILang;
}

export const getIssues = createCustomAction(
    actions.GET_ISSUES_REQUEST,
    (type) => {
        return (dpdId: number, params: IFilterParams) => ({
            type,
            dpdId,
            params
        });
    }
);
export const getIssuesSuccess = createCustomAction(
    actions.GET_ISSUES_SUCCESS,
    actionPayload
);
export const getIssuesFail = createCustomAction(
    actions.GET_ISSUES_FAIL,
    actionFail
);

export const getAllIssue = createCustomAction(
    actions.GET_ALL_ISSUE_REQUEST,
    (type) => {
        return (
            lang: ILang,
            params?: IPaginationParams,
            paginated?: IPaginateDirection
        ) => ({
            type,
            params,
            paginated,
            lang
        });
    }
);
export const getAllIssueSuccess = createCustomAction(
    actions.GET_ALL_ISSUE_SUCCESS,
    actionPayload
);
export const getAllIssueFail = createCustomAction(
    actions.GET_ALL_ISSUE_FAIL,
    actionFail
);

export const postIssue = createCustomAction( 
    actions.POST_ISSUE_REQUEST,
    (type) => {
        return (payload: IModifiableIssue) => ({ type, payload });
    }
);
export type IPostIssue = ReturnType<typeof postIssue>;

export const postIssueSuccess = createCustomAction(
    actions.POST_ISSUE_SUCCESS,
    actionPayload
);
export const postProtocolIssueSuccess = createCustomAction(
    actions.POST_PROTOCOL_ISSUE_SUCCESS,
    actionPayload
);
export const postIssueFail = createCustomAction(
    actions.POST_ISSUE_FAIL,
    actionFail
);
export const postProtocolIssueFail = createCustomAction(
    actions.POST_PROTOCOL_ISSUE_FAIL,
    actionFail
);

export const patchIssue = createCustomAction( 
    actions.PATCH_ISSUE_REQUEST,
    (type) => {
        return (issueId: number, payload: IModifiableIssue, lang: ILang) => ({
            type,
            issueId,
            payload,
            lang
        });
    }
);
export const patchIssueSuccess = createCustomAction(
    actions.PATCH_ISSUE_SUCCESS,
    actionPayload
);
export const patchProtocolIssueSuccess = createCustomAction(
    actions.PATCH_PROTOCOL_ISSUE_SUCCESS,
    actionPayload
);
export const patchIssueFail = createCustomAction(
    actions.PATCH_ISSUE_FAIL,
    actionFail
);
export const patchProtocolIssueFail = createCustomAction(
    actions.PATCH_PROTOCOL_ISSUE_FAIL,
    actionFail
);

export const deleteIssue = createCustomAction( 
    actions.DELETE_ISSUE_REQUEST,
    (type) => {
        return (issueId: number) => ({ type, issueId });
    }
);
export const deleteIssueSuccess = createCustomAction(
    actions.DELETE_ISSUE_SUCCESS,
    actionPayload
);
export const deleteIssueFail = createCustomAction(
    actions.DELETE_ISSUE_FAIL,
    actionFail
);

export const createIssueLink = createCustomAction(
    actions.CREATE_ISSUE_LINK_REQUEST,
    (type) => {
        return (issueId: number) => ({ type, issueId });
    }
);
export const createIssueLinkSuccess = createCustomAction(
    actions.CREATE_ISSUE_LINK_SUCCESS,
    actionPayload
);
export const createIssueLinkFail = createCustomAction(
    actions.CREATE_ISSUE_LINK_FAIL,
    actionFail
);

export const getSharedIssue = createCustomAction(
    actions.GET_SHARED_ISSUE_REQUEST,
    (type) => {
        return (token: string) => ({ type, token });
    }
);
export const getSharedIssueSuccess = createCustomAction(
    actions.GET_SHARED_ISSUE_SUCCESS,
    actionPayload
);
export const getSharedIssueFail = createCustomAction(
    actions.GET_SHARED_ISSUE_FAIL,
    actionFail
);

interface IPostPublicLogPayload {
    sll_sharinglink: string;
    sll_ip?: string;
    sll_logmsg: string;
    sll_public: number;
}

export const postPublicLog = createCustomAction(
    actions.POST_PUBLIC_LOG_REQUEST,
    (type) => {
        return (payload: IPostPublicLogPayload) => ({ type, payload });
    }
);
export const postPublicLogSuccess = createCustomAction(
    actions.POST_PUBLIC_LOG_SUCCESS
);
export const postPublicLogFail = createCustomAction(
    actions.POST_PUBLIC_LOG_FAIL,
    actionFail
);

interface IPostPrivateLogPayload {
    sll_iss_id?: number;
    sll_ip?: string;
    sll_logmsg?: string;
    sll_public?: number;
}

export const postPrivateLog = createCustomAction(
    actions.POST_PRIVATE_LOG_REQUEST,
    (type) => {
        return (payload: IPostPrivateLogPayload) => ({ type, payload });
    }
);
export const postPrivateLogSuccess = createCustomAction(
    actions.POST_PRIVATE_LOG_SUCCESS
);
export const postPrivateLogFail = createCustomAction(
    actions.POST_PRIVATE_LOG_FAIL,
    actionFail
);

export const getIssueLogs = createCustomAction(
    actions.GET_ISSUE_LOGS_REQUEST,
    (type) => {
        return (token: string) => ({ type, token });
    }
);
export const getIssueLogsSuccess = createCustomAction(
    actions.GET_ISSUE_LOGS_SUCCESS,
    actionPayload
);
export const getIssueLogsFail = createCustomAction(
    actions.GET_ISSUE_LOGS_FAIL,
    actionFail
);

export const getProtocolIssues = createCustomAction(
    actions.GET_PROTOCOL_ISSUES_REQUEST,
    (type) => {
        return (protoId?: number) => ({ type, protoId });
    }
);
export const getProtocolIssuesSuccess = createCustomAction(
    actions.GET_PROTOCOL_ISSUES_SUCCESS,
    actionPayload
);
export const getProtocolIssueFail = createCustomAction(
    actions.GET_PROTOCOL_ISSUES_FAIL,
    actionFail
);

export const getElementIssues = createCustomAction(
    actions.GET_ELEMENT_ISSUES_REQUEST,
    (type) => {
        return (eleId: number) => ({ type, eleId });
    }
);
export const getElementIssuesSuccess = createCustomAction(
    actions.GET_ELEMENT_ISSUES_SUCCESS,
    actionPayload
);
export const getElementIssueFail = createCustomAction(
    actions.GET_ELEMENT_ISSUES_FAIL,
    actionFail
);

export const getStoredProtoIssues = createCustomAction(
    actions.GET_STORED_PROTO_ISSUES_REQUEST,
    (type) => {
        return (protoId: number) => ({ type, protoId });
    }
);
export const getStoredProtoIssuesSuccess = createCustomAction(
    actions.GET_STORED_PROTO_ISSUES_SUCCESS,
    actionPayload
);
export const getStoredProtoIssuesFail = createCustomAction(
    actions.GET_STORED_PROTO_ISSUES_FAIL,
    actionFail
);

export const getIssue = createCustomAction(
    actions.GET_ISSUE_REQUEST,
    (type) => {
        return (issueId: number, lang: ILang) => ({ type, issueId, lang });
    }
);
export const getIssueSuccess = createCustomAction(
    actions.GET_ISSUE_SUCCESS,
    actionPayload
);
export const getIssueFail = createCustomAction(
    actions.GET_ISSUE_FAIL,
    actionFail
);

export const predictIssue = createCustomAction(
    actions.PREDICT_ISSUE,
    (type) => {
        return (issueId: number, payload: Omit<Partial<IIssue>, 'iss_id'>) => ({
            type,
            issueId,
            payload
        });
    }
);
