import { takeLatest } from 'redux-saga/effects';
import { normalize } from 'normalizr';

import { propertySchema } from '../../reducers/schema';

import { GET_ONE_DPD_REQUEST } from '../../actions';
import {
    getOneDpdSucces,
    getOneDpdFail,
    IGetOneDpd
} from '../../action-creators';

import { simpleRequest, ISuccessCallback } from '../modules/request';

const onSuccess: ISuccessCallback<IGetOneDpd> = function (data) {
    const { result, entities } = normalize(data, propertySchema);
    const { uploads = {}, properties = {} } = entities;

    const property = properties[result];
    return {
        property,
        uploads
    };
};

export default takeLatest(
    GET_ONE_DPD_REQUEST,
    simpleRequest<IGetOneDpd>({
        success: getOneDpdSucces,
        failure: getOneDpdFail,
        getUrl: (action) => `dpd/${action.id}/`,
        onSuccess
    })
);
