import React, { useState, Fragment } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Ripple } from '@rmwc/ripple';

import { isHouseType } from '../../../configs/costants';
import { justArray } from '../../../utils';

import StdIcon from '../../atoms/StdIcon';
import StdDivider from '../../atoms/StdDivider';
import StdButton from '../../atoms/StdButton';
import SelectListItem from '../SelectListItem';
import SelectListAccordion from '../SelectListAccordion';

import './property-accordion.scss';

interface IProps extends IClass {
    property: IDpd;
    width?: string;
    onObjectSelect: (id: number) => void;
    onObjectAdd?: ICallback;
    renderDisabledBadge: (object: IDpd) => React.ReactNode;
}

const PropertyAccordion: React.FC<IProps> = ({
    property,
    className,
    width,
    renderDisabledBadge,
    onObjectSelect,
    onObjectAdd
}) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const { dpd_dpd_id, dpd_street, dpd_street_number, dpd_type } = property;
    const objects = Array.isArray(dpd_dpd_id) ? dpd_dpd_id : [];

    const objectCount = objects.length;
    const isHouse = isHouseType(dpd_type || '');

    const disabledNode = isHouse ? renderDisabledBadge(property) : null;

    const onClick = () => {
        if (disabledNode) return;

        if (isHouse) {
            onObjectSelect(property.dpd_id);
        } else {
            setOpen((s) => !s);
        }
    };

    const propertyTitle = `${dpd_street || '----'} ${
        dpd_street_number || '--'
    }`.toUpperCase();
    const objectCountLabel = `${objectCount} ${t('objects')}`.toUpperCase();

    return (
        <SelectListAccordion
            className={className}
            open={open}
            onClick={onClick}
            disabled={Boolean(disabledNode)}
            width={width}
            title={
                <div>
                    <div
                        className={clsx(
                            'property-accordion__header',
                            disabledNode && 'stack-s'
                        )}
                    >
                        <span
                            className={clsx(
                                'property-accordion__title',
                                !isHouse && 'inline-m'
                            )}
                        >
                            <span className="inline-xs">{propertyTitle}</span>
                            {!isHouse && (
                                <>
                                    <span className="inline-xs">·</span>
                                    <span>{objectCountLabel}</span>
                                </>
                            )}
                        </span>
                        {!isHouse && (
                            <StdIcon
                                className={clsx(
                                    'property-accordion__arrow',
                                    open && 'property-accordion__arrow--open'
                                )}
                                name="angle-right"
                                prefix="far"
                            />
                        )}
                    </div>
                    {disabledNode && <div>{disabledNode}</div>}
                </div>
            }
        >
            {objects.map((object) => {
                const title = object.dpd_title || '---';
                const roomsLabel =
                    justArray(object.dpd_rooms).reduce(
                        (acc, curr) => `${acc} ${curr.rom_name || '--'}, `,
                        ''
                    ) || '...';

                const disabledNode = renderDisabledBadge(object);
                return (
                    <Fragment key={object.dpd_id}>
                        <StdDivider />
                        <SelectListItem
                            title={title}
                            subtitle={
                                <div>
                                    {disabledNode && (
                                        <div className="stack-s">
                                            {disabledNode}
                                        </div>
                                    )}
                                    <div>{roomsLabel}</div>
                                </div>
                            }
                            onClick={() => onObjectSelect(object.dpd_id)}
                            disabled={Boolean(disabledNode)}
                        />
                    </Fragment>
                );
            })}
            <StdDivider />
            <Ripple disabled>
                <div className="property-accordion__new">
                    <StdButton onClick={onObjectAdd}>
                        {t('add_object')}
                    </StdButton>
                </div>
            </Ripple>
        </SelectListAccordion>
    );
};

export default PropertyAccordion;
