import { takeLatest, call } from 'redux-saga/effects';
import queryString from 'query-string';

import { GET_PROTOCOLS_REQUEST } from '../../actions';
import {
    getProtocols,
    getProtocolsSuccess,
    getProtocolsFail
} from '../../action-creators';
import { simpleRequest } from '../modules/request';
import { fetchFailError } from '../../../modules/errorHandlers';

export default takeLatest(
    GET_PROTOCOLS_REQUEST,
    simpleRequest<RT<typeof getProtocols>>({
        success: getProtocolsSuccess,
        failure: getProtocolsFail,
        getUrl: (action) => `protocol/?${queryString.stringify(action.params)}`,
        onFail: function* (error: INetworkError) {
            yield call(fetchFailError, 'fetch_protocols_error', error);
        },
        enableOffline: true
    })
);
