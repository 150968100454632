import React, { useState } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { validate } from '../../../utils/validation';
import useInput from '../../../hooks/useInput';
import useValidation from '../../../hooks/useValidation';

import ObjectConfirmModal from '../ObjectConfirmModal/ObjectConfirmModal';
import CustomIcon from '../../atoms/CustomIcon';

interface IForm {
    form: {
        verification: string;
    };
    errors: IErrorHash;
}

const initForm: IForm = {
    form: {
        verification: ''
    },
    errors: {}
};

interface IProps extends IModalBase, IClass {
    message: string;
    verifyNote?: string;
    verifyMessage?: string;
    onConfirm?: ICallback;
    title?: string;
}

const ConfirmDeletionModal: React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    const [input, setInput] = useState(initForm);
    const { form, errors } = input;

    const { message, verifyMessage, verifyNote, onConfirm, onClose, title } = props;

    const handleInput = useInput(setInput);

    const isConfirmed = form.verification === verifyMessage;

    const validateFields = useValidation(input, setInput, (hash) => {
        const { verification } = input.form;
        return [
            !verifyMessage || validate({ verification }, isConfirmed, hash)
        ].every(Boolean);
    });

    const onAccept = () => {
        if (validateFields()) onConfirm?.();
    };

    const isDisabled = Boolean(verifyMessage && !isConfirmed);

    return (
        <ObjectConfirmModal
            {...props}
            isDestructive
            onClose={onClose}
        >
            <div className="dltModal-header">
                <div className="dltModal-header-title">
                    {t("delete_property")}
                </div>
                <img src={'/static/images/close.svg'} onClick={onClose}/>
            </div>
            <div className="dltModal-dltMessage">
                {verifyNote && verifyNote && (
                    <>
                        <div className="dltModal-header-title">
                            <Trans t={t} components={{ bold: <strong /> }}>
                                    {t("want-to-delete", {
                                        name: title
                                    })}
                            </Trans>
                        </div>
                    </>
                )}
            </div>
            
        </ObjectConfirmModal>
    );
};

export default ConfirmDeletionModal;
