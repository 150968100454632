import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, isEmail } from 'validator';

import isAuth from '../../store/selectors/isAuth';
import { validate } from '../../utils/validation';
import { login } from '../../store/action-creators';
import {
    DASHBOARD_ROUTE,
    PASSWORD_RESET_ROUTE,
    REGISTRATION_ROUTE
} from '../../configs/routes';

import useInput from '../../hooks/useInput';
import useValidation from '../../hooks/useValidation';

import StdTextField from '../../components/atoms/StdTextField';
import StdButton from '../../components/atoms/StdButton';
import LoginHeader from '../../components/login/LoginHeader';

interface IState {
    form: {
        email: string;
        password: string;
    };
    errors: IErrorHash;
}

const initState: IState = {
    form: {
        email: '',
        password: ''
    },
    errors: {}
};

export const LoginPage: React.FC = () => {
    const [state, setState] = useState(initState);
    const handleInput = useInput(setState);

    const { t } = useTranslation();

    const dispatch = useDispatch();
    const location = useLocation<{ previousUrl: string }>();
    const version = useSelector(
        (state) => state.appState.version.response?.results?.[0]
    );

    const validateFields = useValidation(state, setState, (hash) => {
        const { email, password } = state.form;
        return [
            validate({ email }, !isEmpty(email), hash) &&
                validate({ email }, isEmail(email), hash),
            validate({ password }, !isEmpty(password), hash)
        ].every(Boolean);
    });

    const handleSubmit = () => {
        const { email, password } = state.form;
        if (validateFields()) {
            const { previousUrl } = location.state || {};
            const prevLoc = previousUrl ? previousUrl : DASHBOARD_ROUTE;

            dispatch(login({ email, password }, prevLoc));
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent) => {
        e.key === 'Enter' && handleSubmit();
    };

    const { errors, form } = state;
    const { email, password } = form;
    const { ver_app } = version || {};

    return (
        <div className="login__page">
            <LoginHeader />
            <div className="login__content">
                <div className="login__card">
                    <div className="font__title stack-xl">{t('welcome')}</div>
                    <div className="login__form">
                        <div className="stack-m">
                            <StdTextField
                                autoComplete="email"
                                label={t('sign_in.username')}
                                type="email"
                                onChange={handleInput}
                                name="email"
                                value={email}
                                error={errors['email']}
                                width="100%"
                            />
                        </div>
                        <div className="stack-m">
                            <StdTextField
                                autoComplete="password"
                                name="password"
                                label={t('sign_in.password')}
                                type="password"
                                error={errors['password']}
                                onChange={handleInput}
                                onKeyPress={handleKeyPress}
                                value={password}
                                width="100%"
                            />
                        </div>
                        <div className="login__recover-password stack-l">
                            <Link
                                className="login__recover-note"
                                to={PASSWORD_RESET_ROUTE}
                            >
                                {t('sign_in.forgot_password')}
                            </Link>
                        </div>
                        <div className="form__line stack-l">
                            <StdButton
                                type="primary"
                                size="large"
                                width="100%"
                                onClick={handleSubmit}
                            >
                                {t('sign_in.login')}
                            </StdButton>
                        </div>
                        <div className="login__register">
                            <span>
                                {t('sign_in.register_note')}{" "}
                                <Link
                                    className="login__register-note"
                                    to={REGISTRATION_ROUTE}
                                >
                                    {t('sign_in.register_note_title')}{"."}
                                </Link>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="login__version">
                    <span>
                        {ver_app ? t('version_note', { version: ver_app }) : ''}
                    </span>
                </div>
            </div>
        </div>
    );
};

const LoginPageContainer: React.FC = () => {
    const isUserAuth = useSelector(isAuth);

    if (isUserAuth) return <Redirect to={DASHBOARD_ROUTE} />;

    return <LoginPage />;
};

export default LoginPageContainer;
