import React from 'react';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';

import { ANIMATE_HEIGHT_PROPS } from '../../../configs/costants';
import StdIcon from '../StdIcon';

import './expandable-title.scss';

interface IProps {
    className?: string;
    open: boolean;
    title: string;
    subtitle?: ReactNode;
    children?: ReactNode;
    onToggle?: ICallback;
}

const ExpandableTitle: React.FC<IProps> = ({
    className,
    title,
    open,
    subtitle,
    children,
    onToggle
}) => {
    return (
        <div>
            <div
                className={clsx(
                    'expandable-title__toggle',
                    subtitle && 'stack-m',
                    className
                )}
                onClick={onToggle}
            >
                <span className="inline-xs">{title}</span>
                <StdIcon name={open ? 'eye' : 'eye-slash'} grey small />
            </div>
            {subtitle}
            <AnimatePresence initial={false}>
                {open && (
                    <motion.div {...ANIMATE_HEIGHT_PROPS}>
                        {children}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default ExpandableTitle;
