import { takeLatest, put } from 'redux-saga/effects';

import { MAGIC_LOGIN_REQUEST } from '../../actions';
import {
    magicLogin,
    magicLoginSuccess,
    magicLoginFail,
    sessionStart,
    sessionEnd
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeLatest(
    MAGIC_LOGIN_REQUEST,
    simpleRequest<RT<typeof magicLogin>>({
        success: magicLoginSuccess,
        failure: magicLoginFail,
        getUrl: () => `auth-users/token_login/`,
        getBody: (action) => ({ token: action.token }),
        method: 'POST',
        withAuth: false,
        onSuccess: function* (data) {
            yield put(sessionEnd());

            const { access, refresh } = data || {};
            yield put(sessionStart({ access, refresh }, { verified: true }));
            return data;
        },
        onFail: function* () {
            yield put(sessionEnd());
        }
    })
);
