import React from 'react';
import clsx from 'clsx';

import { useTranslation } from 'react-i18next';

import { Ripple } from '@rmwc/ripple';
import StdIcon from '../../atoms/StdIcon';

import './shared-link.scss';

interface IProps extends IClass {
    link: string;
    handleCopy?: ICallback;
    handleShare?: ICallback;
}

const SharedLink: React.FC<IProps> = ({
    className,
    link,
    handleCopy,
    handleShare
}) => {
    const { t } = useTranslation();
    const shareSupport = Boolean((navigator as any).share);

    return (
        <div className={clsx('shared-link__container', className)}>
            <div className="shared-link__link-section">
                <a
                    className="shared-link__content"
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {link}
                </a>
            </div>
            <Ripple>
                <div className="ripple-button" onClick={handleCopy}>
                    {t('copy').toUpperCase()}
                </div>
            </Ripple>
            {shareSupport && (
                <Ripple>
                    <div className="ripple-button" onClick={handleShare}>
                        <StdIcon name="share-alt" />
                    </div>
                </Ripple>
            )}
        </div>
    );
};

export default SharedLink;
