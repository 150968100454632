export const GET_ISSUES_REQUEST = 'GET_ISSUES_REQUEST';
export const GET_ISSUES_SUCCESS = 'GET_ISSUES_SUCCESS';
export const GET_ISSUES_FAIL = 'GET_ISSUES_FAIL';

export const GET_ALL_ISSUE_REQUEST = 'GET_ALL_ISSUE_REQUEST';
export const GET_ALL_ISSUE_SUCCESS = 'GET_ALL_ISSUE_SUCCESS';
export const GET_ALL_ISSUE_FAIL = 'GET_ALL_ISSUE_FAIL';

export const POST_ISSUE_REQUEST = 'POST_ISSUE_REQUEST';
export const POST_ISSUE_SUCCESS = 'POST_ISSUE_SUCCESS';
export const POST_PROTOCOL_ISSUE_SUCCESS = 'POST_PROTOCOL_ISSUE_SUCCESS';
export const POST_ISSUE_FAIL = 'POST_ISSUE_FAIL';
export const POST_PROTOCOL_ISSUE_FAIL = 'POST_PROTOCOL_ISSUE_FAIL';

export const PATCH_ISSUE_REQUEST = 'PATCH_ISSUE_REQUEST';
export const PATCH_ISSUE_SUCCESS = 'PATCH_ISSUE_SUCCESS';
export const PATCH_PROTOCOL_ISSUE_SUCCESS = 'PATCH_PROTOCOL_ISSUE_SUCCESS';
export const PATCH_ISSUE_FAIL = 'PATCH_ISSUE_FAIL';
export const PATCH_PROTOCOL_ISSUE_FAIL = 'PATCH_PROTOCOL_ISSUE_FAIL';

export const DELETE_ISSUE_REQUEST = 'DELETE_ISSUE_REQUEST';
export const DELETE_ISSUE_SUCCESS = 'DELETE_ISSUE_SUCCESS';
export const DELETE_ISSUE_FAIL = 'DELETE_ISSUE_FAIL';

export const CREATE_ISSUE_LINK_REQUEST = 'CREATE_ISSUE_LINK_REQUEST';
export const CREATE_ISSUE_LINK_SUCCESS = 'CREATE_ISSUE_LINK_SUCCESS';
export const CREATE_ISSUE_LINK_FAIL = 'CREATE_ISSUE_LINK_FAIL';

export const GET_SHARED_ISSUE_REQUEST = 'GET_SHARED_ISSUE_REQUEST';
export const GET_SHARED_ISSUE_SUCCESS = 'GET_SHARED_ISSUE_SUCCESS';
export const GET_SHARED_ISSUE_FAIL = 'GET_SHARED_ISSUE_FAIL';

export const POST_PUBLIC_LOG_REQUEST = 'POST_PUBLIC_LOG_REQUEST';
export const POST_PUBLIC_LOG_SUCCESS = 'POST_PUBLIC_SUCCESS';
export const POST_PUBLIC_LOG_FAIL = 'POST_PUBLIC_LOG_FAIL';

export const POST_PRIVATE_LOG_REQUEST = 'POST_PRIVATE_LOG_REQUEST';
export const POST_PRIVATE_LOG_SUCCESS = ' POST_PRIVATE_LOG_SUCCESS';
export const POST_PRIVATE_LOG_FAIL = 'POST_PRIVATE_LOG_FAIL';

export const GET_ISSUE_LOGS_REQUEST = 'GET_ISSUE_LOGS_REQUEST';
export const GET_ISSUE_LOGS_SUCCESS = 'GET_ISSUE_LOGS_SUCCESS';
export const GET_ISSUE_LOGS_FAIL = 'GET_ISSUE_LOGS_FAIL';

export const GET_PROTOCOL_ISSUES_REQUEST = 'GET_PROTOCOL_ISSUES_REQUEST';
export const GET_PROTOCOL_ISSUES_SUCCESS = 'GET_PROTOCOL_ISSUES_SUCCESS';
export const GET_PROTOCOL_ISSUES_FAIL = 'GET_PROTOCOL_ISSUES_FAIL';

export const GET_ELEMENT_ISSUES_REQUEST = 'GET_ELEMENT_ISSUES_REQUEST';
export const GET_ELEMENT_ISSUES_SUCCESS = 'GET_ELEMENT_ISSUES_SUCCESS';
export const GET_ELEMENT_ISSUES_FAIL = 'GET_ELEMENT_ISSUES_FAIL';

export const GET_STORED_PROTO_ISSUES_REQUEST =
    'GET_STORED_PROTO_ISSUES_REQUEST';
export const GET_STORED_PROTO_ISSUES_SUCCESS =
    'GET_STORED_PROTO_ISSUES_SUCCESS';
export const GET_STORED_PROTO_ISSUES_FAIL = 'GET_STORED_PROTO_ISSUES_FAIL';

export const GET_ISSUE_REQUEST = 'GET_ISSUE_REQUEST';
export const GET_ISSUE_SUCCESS = 'GET_ISSUE_SUCCESS';
export const GET_ISSUE_FAIL = 'GET_ISSUE_FAIL';

export const PREDICT_ISSUE = 'PREDICT_ISSUE';
