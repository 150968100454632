import { takeLatest } from 'redux-saga/effects';
import { CREATE_USER_REQUEST } from '../../actions';
import {
    createUser,
    createUserSuccess,
    createUserFail
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeLatest(
    CREATE_USER_REQUEST,
    simpleRequest<ReturnType<typeof createUser>>({
        success: createUserSuccess,
        failure: createUserFail,
        getUrl: (action) => `auth-users/create/?lang=${action.lang}`,
        getBody: (action) => action.payload,
        method: 'POST',
        withAuth: false
    })
);
