import * as actions from '../actions/elAttribute';
import { createCustomAction } from 'typesafe-actions';
import { actionFail, actionPayload } from '../storeModule';

export const elAttrCrud = createCustomAction(
    actions.EL_ATTR_CRUD_REQUEST,
    (type) => {
        return (payload: {
            ela_ele_id: number;
            ela_state: Partial<IElementAttribute>[];
        }) => ({ type, payload });
    }
);
export const elAttrCrudSuccess = createCustomAction(
    actions.EL_ATTR_CRUD_SUCCESS,
    actionPayload
);
export const elAttrCrudFail = createCustomAction(
    actions.EL_ATTR_CRUD_FAIL,
    actionFail
);
