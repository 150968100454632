import { takeEvery } from 'redux-saga/effects';

import { PATCH_TENANT_REQUEST } from '../../actions';
import {
    patchTenant,
    patchTenantSuccess,
    patchTenantFail
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeEvery(
    PATCH_TENANT_REQUEST,
    simpleRequest<ReturnType<typeof patchTenant>>({
        success: patchTenantSuccess,
        failure: patchTenantFail,
        getUrl: (action) => `tenant/${action.tenId}/`,
        method: 'PATCH',
        getBody: (action) => action.tenant
    })
);
