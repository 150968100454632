import { all } from 'redux-saga/effects';
import getVersion from './getVersion';
import mediaCreate from './mediaCreate';
import mediaPatch from './mediaPatch';
import mediaRename from './mediaRename';
import mediaDelete from './mediaDelete';
import sortingCrud from './sortingCrud';
import snackbarEffect from './snackbar';

export default all([
    getVersion,
    mediaCreate,
    mediaPatch,
    mediaRename,
    mediaDelete,
    sortingCrud,
    snackbarEffect
]);
