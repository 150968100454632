import type { IOfflineProtocolActions } from '../action-creators/types';
import * as acts from '../actions';
import {
    ELECTRICITY_PROOF_TYPE,
    GAS_PROOF_TYPE,
    WATER_PROOF_TYPE
} from '../../configs/costants';

import { backgroundSyncSuccess, patchMediaSuccess } from '../action-creators';
import moment from 'moment';
// import { getActionId } from '../storeModule';
// import { act } from '@testing-library/react';

const initialProtocolState: IOfflineProtocol = {
    protocol: {
        pk: 0,
        localId: null,
        prt_comment: null,
        prt_date_cleaning: null,
        prt_createddate: '',
        prt_used_tenant_deposit: false,
        prt_meter_electricity: null,
        prt_meter_gas: null,
        prt_meter_water: null,
        electricity_media: null,
        electricity_media_id: null,
        gas_media: null,
        gas_media_id: null,
        water_media: null,
        water_media_id: null,
        attendees: [],
        rooms: [],
        dpd: null,
        prt_status: 'locate',
        prt_stage: {
            items_done: false,
            rooms_done: false,
            meters_done: false,
            attendees_done: false
        },
        items: [],
        uploads: [],
        current_step: null,
        protocol_pdf: null,
        checkout_data: null
    },
    protocolState: {
        protocol_sync_required: false,
        is_user_editing: false,
        is_async: true,
        is_completed: false,
        is_finished: false,
        protocol_syncing: false,
        is_protocol_normal: false,
        sync_upload_progress: {
            totalUploadLength: 0,
            currentUploadProgress: 0,
            uploadedMediaSuccess: 0,
            uploadedMediaFail: 0
        },
        syncingState: 'CLOSED',
        checkinInvalid: false,
        lastCheckinTime: '',
        protocolReadyToCheckin: false,
        saving_protocol_data: false,
        protocolOfflineSave: {
            run: false,
            exit: false,
            isAutosave: false,
            path: null,
            failed: false,
            openCheckoutFail: false,
            finishWhenReady: false,
            bannerWhenOffline: false,
            generatePdf: () => undefined
        },
        openCheckinFailModal: false,
        checkinFailCode: 0,
        openFinishBanner: true
    },
    protocolMedia: {}
};

const makeMediaArray = (medias: any[]) => {
    let newMedias = [] as number[];
    medias.map((media) => {
        newMedias.push(Number(media.pk));
    });
    return newMedias as number[];
};

const makeElementIssues = (originalIssuesArr?: IssuesEntity[] | null) => {
    const newIssues = [] as IssuesEntity[];
    originalIssuesArr?.map((oldIssue: IssuesEntity) => {
        const newElementIssueMediaIds =
            oldIssue?.medias && oldIssue.medias?.length > 0
                ? makeMediaArray(oldIssue.medias)
                : [];
        newIssues.push({
            ...oldIssue,
            media_ids: newElementIssueMediaIds
        });
    });
    return newIssues;
};
const makeItemIssues = (originalItemIssuesArr?: IssuesEntity[] | null) => {
    const newIssues = [] as IssuesEntity[];
    originalItemIssuesArr?.map((oldItemIssue: IssuesEntity) => {
        const newItemIssueMediaIds =
            oldItemIssue?.medias && oldItemIssue.medias?.length > 0
                ? makeMediaArray(oldItemIssue.medias)
                : [];
        newIssues.push({
            ...oldItemIssue,
            media_ids: newItemIssueMediaIds
        });
    });
    return newIssues;
};

const handleOfflineProtocolSave = (
    state: IOfflineProtocol,
    action: AnyAction
) => {
    const { checkout_data: protocol, ...protocolCheckoutData } =
        action.payload.entity;
    const serverUploadedMedia = action.payload.uploads;
    const updateProtocolState = !Boolean(action.originator?.noUpdate);
    const { pk: checkout_id } = protocolCheckoutData;
    const { dpd_city, dpd_country, dpd_zip, dpd_street, dpd_street_number } =
        protocol.dpd;

    const newAttendees = protocol.attendees.map(
        (attendee: AttendeesEntity, index: number) => {
            const attendeeFullname = `${attendee.firstname} ${attendee.lastname}`;
            const newUploads = attendee.picture_media
                ? [attendee.picture_media?.pk]
                : [];
            return {
                ...attendee,
                address: {
                    city: dpd_city || '',
                    country: dpd_country || '',
                    full_name: attendeeFullname,
                    street: dpd_street || '',
                    street_number: dpd_street_number || '',
                    type: 'LADDRS',
                    zip: dpd_zip || ''
                },
                uploads: newUploads,
                ...(attendee.signature_media
                    ? {
                          signature_media_id: attendee.signature_media.pk,
                          signature_declined: false
                      }
                    : { signature_declined: true })
            };
        }
    );

    const newRooms = protocol.rooms.map(
        (room: RoomsEntity, roomIndex: number) => {
            const newElements = room.elements?.map(
                (element: ElementsEntity, elementIndex: number) => {
                    return {
                        ...element,
                        index: elementIndex + 1,
                        issues: makeElementIssues(element.issues)
                    };
                }
            );

            return {
                ...room,
                elements: newElements,
                index: roomIndex + 1
            };
        }
    );

    const newItems = protocol.items.map((item: ItemsEntity) => {
        const newItemIssues = makeItemIssues(item.issues);
        const newMedia = item.medias?.map((media) => media.pk);
        return {
            ...item,
            issues: newItemIssues,
            media_ids: newMedia
        };
    });

    const shouldUpdateProtocol = true;
    // action.payload.entity.protocol !== state.protocol.pk; // should update media only when the Protocols dont match

    console.log('OFFLINEPROTOCOL MEDIA WILL UPDATE ?', shouldUpdateProtocol);
    console.log(
        'OFFLINEPROTOCOL IDS',
        action.payload.entity.protocol,
        protocol.pk
    );

    const willUpdateProtocol = updateProtocolState;
    // ? shouldUpdateProtocol
    // : false;

    const protocolState = willUpdateProtocol
        ? {
              ...state.protocol,
              ...protocol,
              checkout_id,
              checkout_data: protocolCheckoutData,
              uploads: protocolCheckoutData || protocol.uploads,
              attendees: newAttendees,
              rooms: newRooms,
              items: newItems
          }
        : {
              ...state.protocol,
              checkout_id,
              checkout_data: protocolCheckoutData
          };

    const protocolMedia = willUpdateProtocol
        ? {
              ...initialProtocolState.protocolMedia,
              ...serverUploadedMedia
          }
        : {
              ...state.protocolMedia
          };

    return {
        ...state,
        protocol: protocolState,
        protocolState: {
            ...state.protocolState,
            protocol_sync_required: false,
            is_async: true,
            is_completed: false,
            is_finished: false,
            protocol_syncing: false,
            sync_upload_progress: {
                totalUploadLength: 0,
                currentUploadProgress: 0,
                uploadedMediaSuccess: 0,
                uploadedMediaFail: 0
            },
            checkinInvalid: false,
            protocolReadyToCheckin: false,
            saving_protocol_data: false
        },
        protocolMedia: protocolMedia
    };
};

const handleCreateOfflineAttendees = (
    state: IOfflineProtocol,
    action: AnyAction
) => {
    const localId = state.protocol.attendees?.length
        ? state.protocol.attendees.length + 1
        : 0;

    const {
        att_contract_start_date,
        att_contract_end_date,
        att_type = 'PO',
        att_firstname,
        att_lastname,
        att_phone,
        att_email,
        address,
        signature_declined = true,
        att_picture = null
    } = action.attendee;
    const newAttendeesUploads = att_picture ? [att_picture] : [];

    const offlineProtocolMedia: { [key: string]: object } = {};

    // make offline media object for protocolMedia
    newAttendeesUploads.forEach((media: number) => {
        offlineProtocolMedia[media] = {
            ...state.protocolMedia[media],
            localId: media,
            protocol_attendee: localId
        };
    });

    const newAttendee = {
        localId: localId,
        att_contract_start_date,
        att_contract_end_date,
        att_present: true,
        att_type,
        firstname: att_firstname,
        lastname: att_lastname,
        email: att_email,
        phone: att_phone,
        address,
        att_contact_details: {
            first_name: att_firstname,
            last_name: att_lastname,
            email: att_email,
            phone: att_phone
        },
        signature_declined,
        uploads: newAttendeesUploads,
        picture_media_id: att_picture
    };
    const { attendees } = state.protocol;
    const attendeesList = [...(attendees || []), newAttendee];
    return {
        ...state,
        protocol: {
            ...state.protocol,
            attendees: attendeesList
        },
        protocolMedia: {
            ...state.protocolMedia,
            ...offlineProtocolMedia
        },
        protocolState: {
            ...state.protocolState,
            is_user_editing: true,
            protocol_sync_required: true
        }
    };
};

const handleUpdateOfflineAttendees = (
    state: IOfflineProtocol,
    action: AnyAction
) => {
    const { att_id, attendee } = action;
    const { dpd_city, dpd_country, dpd_zip, dpd_street, dpd_street_number } =
        state.protocol.dpd || {};
    const oldAttendees = state.protocol.attendees;
    const updatedAttendeesUploads = attendee?.att_picture
        ? [attendee?.att_picture]
        : [];

    const offlineProtocolMedia: { [key: string]: object } = {};

    // make offline media object for protocolMedia
    updatedAttendeesUploads.forEach((media: number) => {
        offlineProtocolMedia[media] = {
            ...state.protocolMedia[media],
            localId: media,
            protocol_attendee: att_id
        };
    });
    const updatedAttendee = {
        localId: att_id,
        att_contract_start_date: attendee?.att_contract_start_date,
        att_contract_end_date: attendee?.att_contract_end_date,
        att_present: attendee?.att_present || false,
        att_type: attendee?.att_type || 'PO',
        firstname: attendee?.att_firstname,
        lastname: attendee?.att_lastname,
        email: attendee?.att_email,
        phone: attendee?.att_phone,
        signature_declined: attendee?.signature_declined || true,
        att_contact_details: {
            first_name: attendee?.att_firstname,
            last_name: attendee?.att_lastname,
            email: attendee?.att_email,
            phone: attendee?.att_phone,
            address: attendee?.address
        },
        address: {
            city: dpd_city || '',
            country: dpd_country || '',
            full_name: `${attendee?.att_firstname} ${attendee?.att_lastname}`,
            street: dpd_street || '',
            street_number: dpd_street_number || '',
            type: 'LADDRS',
            zip: dpd_zip || ''
        },
        uploads: updatedAttendeesUploads,
        picture_media_id: attendee?.att_picture
    };
    const updatedAttendeesArray = oldAttendees?.map(
        (attendee: AttendeesEntity) => {
            if (attendee.pk === att_id || attendee.localId === att_id) {
                return { ...attendee, ...updatedAttendee };
            } else {
                return attendee;
            }
        }
    );
    return {
        ...state,
        protocol: {
            ...state.protocol,
            attendees: updatedAttendeesArray
        },
        protocolMedia: {
            ...state.protocolMedia,
            ...offlineProtocolMedia
        },
        protocolState: {
            ...state.protocolState,
            is_user_editing: true,
            protocol_sync_required: true
        }
    };
};

const handleRoomQuickAdd = (state: IOfflineProtocol, action: AnyAction) => {
    const { room, elements } = action.payload;
    const {
        prom_prt_id,
        prom_dpd_id,
        template_name,
        prom_name,
        prom_type = null,
        prom_level
    } = room;
    const stateRooms = [...(state.protocol.rooms || [])];
    const localId = stateRooms.length + 1;
    const quickElements = elements.map(
        (
            element: { ele_name: string; attributes: object[] },
            index: number
        ) => {
            const { ele_name, attributes } = element;
            const elementLocalId = index + 1;
            const quickAttributes = attributes?.filter(
                (attribute: object) => Object.keys(attribute).length
            );
            return {
                localId: elementLocalId,
                pele_ok: false,
                pele_name: ele_name,
                pele_date_installation: null,
                pele_needs_cleaning: false,
                attributes: quickAttributes,
                issues: [],
                medias: [],
                index: elementLocalId
            };
        }
    );
    const quickRoom = {
        prom_prt_id,
        prom_dpd_id,
        template_name,
        prom_name,
        prom_type,
        prom_level,
        localId,
        index: localId,
        prom_ok: false,
        elements: quickElements,
        prom_needs_cleaning: false,
        prom_half_room: false
    };
    const updatedRooms = [...stateRooms, quickRoom];
    return {
        ...state,
        protocol: {
            ...state.protocol,
            rooms: updatedRooms
        },
        protocolState: {
            ...state.protocolState,
            is_user_editing: true,
            protocol_sync_required: true
        }
    };
};

const handleCreateOfflineRoom = (
    state: IOfflineProtocol,
    action: AnyAction
) => {
    const {
        prom_prt_id,
        prom_dpd_id,
        template_name,
        prom_name,
        prom_type = null,
        prom_level
    } = action.room;
    const stateRooms = [...(state.protocol.rooms || [])];
    const localId = stateRooms.length + 1;
    const newRoom = {
        prom_prt_id,
        prom_dpd_id,
        template_name,
        prom_name,
        prom_type,
        prom_level,
        localId,
        index: localId,
        prom_ok: false,
        elements: [],
        prom_needs_cleaning: false,
        prom_half_room: false
    };
    const updatedRooms = [...stateRooms, newRoom];
    return {
        ...state,
        protocol: {
            ...state.protocol,
            rooms: updatedRooms
        },
        protocolState: {
            ...state.protocolState,
            is_user_editing: true,
            protocol_sync_required: true
        }
    };
};

const handleUpdateOfflineRoom = (
    state: IOfflineProtocol,
    action: AnyAction
) => {
    const { roomId, room } = action;
    const {
        prom_prt_id,
        prom_dpd_id,
        prom_name,
        prom_level,
        prom_type,
        template_name
    } = room;
    const stateRooms = [...(state.protocol.rooms || [])];
    const updatedRooms = stateRooms.map((room) => {
        if (room.pk === roomId || room.localId === roomId) {
            return {
                ...room,
                prom_prt_id,
                prom_dpd_id,
                prom_name,
                prom_level,
                prom_type,
                template_name
            };
        } else {
            return room;
        }
    });
    return {
        ...state,
        protocol: {
            ...state.protocol,
            rooms: updatedRooms
        },
        protocolState: {
            ...state.protocolState,
            is_user_editing: true,
            protocol_sync_required: true
        }
    };
};

const handleDeleteOfflineRoom = (
    state: IOfflineProtocol,
    action: AnyAction
) => {
    const { roomId } = action;
    const stateRooms = [...(state.protocol.rooms || [])];
    const deleteIndex = stateRooms.findIndex(
        (room) => room.pk === roomId || room.localId === roomId
    );
    stateRooms.splice(deleteIndex, 1);

    return {
        ...state,
        protocol: {
            ...state.protocol,
            rooms: stateRooms
        },
        protocolState: {
            ...state.protocolState,
            is_user_editing: true,
            protocol_sync_required: true
        }
    };
};

const handleCreateOfflineElement = (
    state: IOfflineProtocol,
    action: AnyAction
) => {
    const {
        pele_prom_id = null,
        pele_name = '',
        pele_ok = false,
        pele_date_installation = null,
        pele_needs_cleaning = false,
        attributes = [],
        issues = [],
        medias = []
    } = action.element;
    const stateRooms = [...(state.protocol.rooms || [])];
    const matchedRoom = stateRooms.find(
        (room) => room.pk === pele_prom_id || room.localId === pele_prom_id
    );
    if (matchedRoom) {
        const stateElements = [...(matchedRoom?.elements || [])];
        const elementLocalId = stateElements.length + 1;
        const newElementLiteral = {
            localId: elementLocalId,
            pele_ok,
            pele_name,
            pele_date_installation,
            pele_needs_cleaning,
            attributes,
            issues,
            medias,
            index: elementLocalId
        };
        const newElements = [...stateElements, newElementLiteral];
        const newMatchedRoom = { ...matchedRoom, elements: newElements };
        stateRooms.splice(stateRooms.indexOf(matchedRoom), 1, newMatchedRoom);

        return {
            ...state,
            protocol: {
                ...state.protocol,
                rooms: stateRooms
            },
            protocolState: {
                ...state.protocolState,
                is_user_editing: true,
                protocol_sync_required: true
            }
        };
    } else {
        return state;
    }
};

const handleUpdateOfflineElement = (
    state: IOfflineProtocol,
    action: AnyAction
) => {
    const { pele_prom_id, pele_id, element: updatedElement } = action;
    const stateRooms = [...(state.protocol.rooms || [])];
    const matchedRoom = stateRooms.find(
        (room) => room.pk === pele_prom_id || room.localId === pele_prom_id
    );
    if (matchedRoom) {
        const { media_ids } = updatedElement;

        const offlineProtocolMedia: { [key: string]: object } = {};

        // make offline media object for protocolMedia
        media_ids.forEach((media: number) => {
            offlineProtocolMedia[media] = {
                ...state.protocolMedia[media],
                localId: media,
                protocol_room: pele_prom_id,
                protocol_element: pele_id
            };
        });

        const stateElements = [...(matchedRoom.elements || [])];
        const newElements = stateElements.map((element) => {
            if (element.pk === pele_id || element.localId === pele_id) {
                return {
                    ...element,
                    ...updatedElement
                };
            } else {
                return element;
            }
        });
        const updatedMatchedRoom = { ...matchedRoom, elements: newElements };
        stateRooms.splice(
            stateRooms.indexOf(matchedRoom),
            1,
            updatedMatchedRoom
        );
        return {
            ...state,
            protocol: {
                ...state.protocol,
                rooms: stateRooms
            },
            protocolMedia: {
                ...state.protocolMedia,
                ...offlineProtocolMedia
            },
            protocolState: {
                ...state.protocolState,
                is_user_editing: true,
                protocol_sync_required: true
            }
        };
    } else {
        return state;
    }
};

const handleOfflineElementDelete = (
    state: IOfflineProtocol,
    action: AnyAction
) => {
    const { pele_prom_id, pele_id } = action;
    const stateRooms = [...(state.protocol.rooms || [])];
    const matchedRoom = stateRooms.find(
        (room) => room.pk === pele_prom_id || room.localId === pele_prom_id
    );
    if (matchedRoom) {
        const deleteIndex = matchedRoom.elements?.findIndex(
            (element) => element.pk === pele_id || element.localId === pele_id
        );
        matchedRoom.elements?.splice(deleteIndex as number, 1);
        stateRooms.splice(stateRooms.indexOf(matchedRoom), 1, matchedRoom);
        return {
            ...state,
            protocol: {
                ...state.protocol,
                rooms: stateRooms
            },
            protocolState: {
                ...state.protocolState,
                is_user_editing: true,
                protocol_sync_required: true
            }
        };
    } else {
        return state;
    }
};

const handlePatchProtocolRoomElement = (
    state: IOfflineProtocol,
    action: AnyAction
) => {
    const { pele_prom_id, pele_id, pele_ok, pele_needs_cleaning } =
        action.payload;

    const matchedRoom = state.protocol.rooms?.find(
        (room) => room.pk === pele_prom_id || room.localId === pele_prom_id
    );
    if (matchedRoom) {
        const updatedElements = matchedRoom?.elements?.map((element) => {
            if (element.pk === pele_id || element.localId === pele_id) {
                const newElement = { ...element, pele_ok, pele_needs_cleaning };
                return newElement;
            } else {
                return element;
            }
        });
        let allRooms = [...(state.protocol.rooms || [])];
        const findIndex = state.protocol.rooms?.indexOf(matchedRoom);
        let newRoom = { ...matchedRoom, elements: updatedElements };

        // edge case : updating room_ok status if the current element update makes all element as 'checked'
        const allElementsChecked = newRoom.elements?.every(
            (element) => element.pele_ok === true
        );
        newRoom = { ...newRoom, prom_ok: allElementsChecked };
        if ((findIndex as number) > -1) {
            allRooms?.splice(findIndex as number, 1, newRoom);
        }
        return {
            ...state,
            protocol: {
                ...state.protocol,
                rooms: allRooms
            },
            protocolState: {
                ...state.protocolState,
                is_user_editing: true,
                protocol_sync_required: true
            }
        };
    } else {
        return state;
    }
};

const handleProtocolItemCheck = (
    state: IOfflineProtocol,
    action: AnyAction
) => {
    const { pitm_id, pitm_ok } = action;
    const oldItems = [...(state.protocol.items || [])];
    const newItems = oldItems.map((item) => {
        if (item.pk === pitm_id || item.localId === pitm_id) {
            return { ...item, pitm_ok: pitm_ok };
        } else {
            return item;
        }
    });
    return {
        ...state,
        protocol: {
            ...state.protocol,
            items: newItems
        },
        protocolState: {
            ...state.protocolState,
            is_user_editing: true,
            protocol_sync_required: true
        }
    };
};

const handleOfflineItemCreate = (
    state: IOfflineProtocol,
    action: AnyAction
) => {
    const {
        pitm_name = '',
        pitm_type = '',
        pitm_ok = false,
        media_med_id = [],
        issues = []
    } = action.item;
    const itemAttributes = action.attr;
    const stateItems = [...(state.protocol.items || [])];
    const localId = stateItems.length + 1;
    const newItemLiteral = {
        localId,
        pitm_name,
        pitm_type,
        pitm_ok,
        media_ids: media_med_id,
        attributes: itemAttributes,
        issues,
        index: localId
    };

    const { media_ids } = newItemLiteral;

    const offlineProtocolMedia: { [key: string]: object } = {};

    // make offline media object for protocolMedia
    media_ids.forEach((media: number) => {
        offlineProtocolMedia[media] = {
            ...state.protocolMedia[media],
            localId: media,
            protocol_item: newItemLiteral.localId
        };
    });

    stateItems.push(newItemLiteral);
    return {
        ...state,
        protocol: {
            ...state.protocol,
            items: stateItems
        },
        protocolMedia: {
            ...state.protocolMedia,
            ...offlineProtocolMedia
        },
        protocolState: {
            ...state.protocolState,
            is_user_editing: true,
            protocol_sync_required: true
        }
    };
};

const handleOfflineItemUpdate = (
    state: IOfflineProtocol,
    action: AnyAction
) => {
    const { itemId, item: newItem, attr } = action;

    const { media_ids } = newItem;

    const offlineProtocolMedia: { [key: string]: object } = {};

    // make offline media object for protocolMedia
    media_ids.forEach((media: number) => {
        offlineProtocolMedia[media] = {
            ...state.protocolMedia[media],
            localId: media,
            protocol_item: itemId
        };
    });

    const stateItems = [...(state.protocol.items || [])];
    const newItems = stateItems.map((item) => {
        if (item.pk === itemId || item.localId === itemId) {
            return {
                ...item,
                ...newItem,
                attributes: attr,
                media_ids: newItem.media_ids
            };
        } else {
            return item;
        }
    });
    return {
        ...state,
        protocol: {
            ...state.protocol,
            items: newItems
        },
        protocolMedia: {
            ...state.protocolMedia,
            ...offlineProtocolMedia
        },
        protocolState: {
            ...state.protocolState,
            is_user_editing: true,
            protocol_sync_required: true
        }
    };
};

const handleOfflineItemDelete = (
    state: IOfflineProtocol,
    action: AnyAction
) => {
    const { itemId } = action;
    const stateItems = [...(state.protocol.items || [])];
    const deleteIndex = stateItems.findIndex(
        (item) => item.pk === itemId || item.localId === itemId
    );
    stateItems.splice(deleteIndex, 1);
    return {
        ...state,
        protocol: {
            ...state.protocol,
            items: stateItems
        },
        protocolState: {
            ...state.protocolState,
            is_user_editing: true,
            protocol_sync_required: true
        }
    };
};

const handleOfflineIssueCreate = (
    state: IOfflineProtocol,
    action: AnyAction
) => {
    const {
        title,
        description,
        grade,
        fix,
        date_occurred,
        medias = [],
        protocol_room = null,
        protocol_element = null,
        cost_bearer,
        fixcost,
        agreedshare,
        protocol_item = null,
        handling = '',
        recording_date = null
    } = action.payload;
    const stateRooms = [...(state.protocol.rooms || [])];
    const stateItems = [...(state.protocol.items || [])];

    const newIssue = {
        protocol_room,
        protocol_element,
        protocol_item,
        cost_bearer,
        title,
        fix,
        fixcost,
        grade,
        description,
        agreedshare,
        handling,
        recording_date,
        date_occurred,
        uploads: medias,
        media_ids: medias
    };

    const offlineProtocolMedia: { [key: string]: object } = {};

    // make offline media object for protocolMedia
    medias.forEach((media: number) => {
        offlineProtocolMedia[media] = {
            ...state.protocolMedia[media],
            localId: media,
            protocol_room,
            protocol_element,
            protocol_item
        };
    });

    if (protocol_item === null) {
        // is element Issue
        const matchedRoom = stateRooms.find(
            (room) =>
                room.pk === protocol_room || room.localId === protocol_room
        );

        if (matchedRoom) {
            const updatedElements = matchedRoom?.elements?.map((element) => {
                if (
                    element.pk === protocol_element ||
                    element.localId === protocol_element
                ) {
                    const issues = [...(element.issues || [])];
                    const localElementIssueId = Date.now();
                    issues.push({
                        ...newIssue,
                        localId: localElementIssueId
                    });
                    return {
                        ...element,
                        issues
                    };
                } else {
                    return element;
                }
            });

            stateRooms.splice(stateRooms.indexOf(matchedRoom), 1, {
                ...matchedRoom,
                elements: updatedElements
            });
            return {
                ...state,
                protocol: {
                    ...state.protocol,
                    rooms: stateRooms
                },
                protocolMedia: {
                    ...state.protocolMedia,
                    ...offlineProtocolMedia
                },
                protocolState: {
                    ...state.protocolState,
                    is_user_editing: true,
                    protocol_sync_required: true
                }
            };
        } else {
            return state;
        }
    } else {
        // is Item Issue
        const updatedItems = stateItems.map((item) => {
            if (item.pk === protocol_item || item.localId === protocol_item) {
                const issues = [...(item.issues || [])];
                const localItemIssueId = Date.now();
                issues.push({
                    ...newIssue,
                    localId: localItemIssueId
                });

                return {
                    ...item,
                    issues
                };
            } else {
                return item;
            }
        });

        return {
            ...state,
            protocol: {
                ...state.protocol,
                items: updatedItems
            },
            protocolMedia: {
                ...state.protocolMedia,
                ...offlineProtocolMedia
            },
            protocolState: {
                ...state.protocolState,
                is_user_editing: true,
                protocol_sync_required: true
            }
        };
    }
};

const handleOfflineIssueUpdate = (
    state: IOfflineProtocol,
    action: AnyAction
) => {
    const { issueId, issueLiteral, lang, isManualOffline } = action.payload;
    const {
        title,
        description,
        grade,
        fix,
        date_occurred,
        medias = [],
        protocol_room = null,
        protocol_element = null,
        cost_bearer,
        fixcost,
        agreedshare,
        protocol_item = null,
        handling = '',
        recording_date = null
    } = issueLiteral;
    const stateRooms = [...(state.protocol.rooms || [])];
    const stateItems = [...(state.protocol.items || [])];

    const offlineProtocolMedia: { [key: string]: object } = {};

    // make offline media object for protocolMedia
    medias.forEach((media: number) => {
        offlineProtocolMedia[media] = {
            ...state.protocolMedia[media],
            localId: media,
            protocol_room,
            protocol_element,
            protocol_item
        };
    });

    if (protocol_item === null) {
        // is element Issue
        const matchedRoom = stateRooms.find(
            (room) =>
                room.pk === protocol_room || room.localId === protocol_room
        );

        if (matchedRoom) {
            const updateRoomIndex = stateRooms.indexOf(matchedRoom);
            const matchedElement =
                matchedRoom?.elements?.find((element) => {
                    return (
                        element.pk === protocol_element ||
                        element.localId === protocol_element
                    );
                }) || ({} as ElementsEntity);

            const updatedIssues = matchedElement?.issues?.map((issue) => {
                if (issue.pk === issueId || issue.localId === issueId) {
                    const updatedIssue = {
                        ...issue,
                        title,
                        description,
                        grade,
                        fix,
                        date_occurred,
                        protocol_room,
                        protocol_element,
                        cost_bearer,
                        fixcost,
                        agreedshare,
                        handling,
                        recording_date,
                        uploads: medias,
                        media_ids: medias
                    };
                    return updatedIssue;
                } else {
                    return issue;
                }
            });
            matchedRoom.elements?.splice(
                matchedRoom.elements.indexOf(matchedElement),
                1,
                {
                    ...matchedElement,
                    issues: updatedIssues
                }
            );

            stateRooms.splice(updateRoomIndex, 1, matchedRoom);

            return {
                ...state,
                protocol: {
                    ...state.protocol,
                    rooms: stateRooms
                },
                protocolMedia: {
                    ...state.protocolMedia,
                    ...offlineProtocolMedia
                },
                protocolState: {
                    ...state.protocolState,
                    is_user_editing: true,
                    protocol_sync_required: true
                }
            };
        } else {
            return state;
        }
    } else {
        // is Item Issue
        const matchedItem =
            stateItems.find(
                (item) =>
                    item.pk === protocol_item || item.localId === protocol_item
            ) || ({} as ItemsEntity);

        const updatedIssues = matchedItem?.issues?.map((issue) => {
            if (issue.pk === issueId || issue.localId === issueId) {
                const updatedIssue = {
                    ...issue,
                    title,
                    description,
                    grade,
                    fix,
                    date_occurred,
                    cost_bearer,
                    fixcost,
                    agreedshare,
                    protocol_item,
                    handling,
                    recording_date,
                    uploads: medias,
                    media_ids: medias
                };
                return updatedIssue;
            } else {
                return issue;
            }
        });

        stateItems.splice(stateItems.indexOf(matchedItem), 1, {
            ...matchedItem,
            issues: updatedIssues
        });

        return {
            ...state,
            protocol: {
                ...state.protocol,
                items: stateItems
            },
            protocolMedia: {
                ...state.protocolMedia,
                ...offlineProtocolMedia
            },
            protocolState: {
                ...state.protocolState,
                is_user_editing: true,
                protocol_sync_required: true
            }
        };
    }
};

const handleOfflineIssueDelete = (
    state: IOfflineProtocol,
    action: AnyAction
) => {
    const { issueId, elementId, roomId, itemId } = action.payload;
    if (!itemId) {
        // is element issue
        const stateRooms = [...(state.protocol.rooms || [])];
        const matchedRoom =
            stateRooms?.find(
                (room) => room.pk === roomId || room.localId === roomId
            ) || ({} as RoomsEntity);
        const matchedRoomIndex = stateRooms.findIndex(
            (room) => room.pk === roomId || room.localId === roomId
        );
        const matchedElement = matchedRoom?.elements?.find(
            (element) =>
                element.pk === elementId || element.localId === elementId
        );
        const matchedElementIndex = matchedRoom?.elements?.findIndex(
            (element) =>
                element.pk === elementId || element.localId === elementId
        );

        if (matchedElement) {
            const updatedIssues = matchedElement?.issues?.filter(
                (issue) => issue.pk !== issueId && issue.localId !== issueId
            );

            matchedRoom.elements?.splice(Number(matchedElementIndex), 1, {
                ...matchedElement,
                issues: updatedIssues
            });
            stateRooms.splice(matchedRoomIndex, 1, matchedRoom);

            return {
                ...state,
                protocol: {
                    ...state.protocol,
                    rooms: stateRooms
                },
                protocolState: {
                    ...state.protocolState,
                    is_user_editing: true,
                    protocol_sync_required: true
                }
            };
        } else {
            return state;
        }
    } else {
        // is item issue
        const stateItems = [...(state.protocol.items || [])];
        const matchedItem = stateItems?.find(
            (item) => item.pk === itemId || item.localId === itemId
        );
        const matchedItemIndex = stateItems?.findIndex(
            (item) => item.pk === itemId || item.localId === itemId
        );

        if (matchedItem) {
            const updatedIssues = matchedItem.issues?.filter(
                (issue) => issue.pk !== issueId && issue.localId !== issueId
            );

            stateItems?.splice(matchedItemIndex, 1, {
                ...matchedItem,
                issues: updatedIssues
            });

            return {
                ...state,
                protocol: {
                    ...state.protocol,
                    items: stateItems
                },
                protocolState: {
                    ...state.protocolState,
                    is_user_editing: true,
                    protocol_sync_required: true
                }
            };
        } else {
            return state;
        }
    }
};

const handleOfflineAttributeCrd = (
    state: IOfflineProtocol,
    action: AnyAction
) => {
    const { pela_pele_id, pela_state } = action.payload;

    const stateRooms = [...(state.protocol.rooms || [])];

    for (let roomIndex = 0; roomIndex < stateRooms.length; roomIndex++) {
        const room = stateRooms[roomIndex];
        const target = room.elements?.find(
            (element) =>
                element.pk === pela_pele_id || element.localId === pela_pele_id
        );
        if (target) {
            const matchedRoom = stateRooms[roomIndex];
            const matchedElement = target;
            if (matchedRoom && matchedElement) {
                const updatedAttributes = pela_state.map(
                    (attr: { pk: any; localId: any }, index: number) => {
                        if (!attr.pk || !attr.localId) {
                            const localAttrId = -(index + 1);
                            return {
                                ...attr,
                                localId: localAttrId
                            };
                        } else {
                            return attr;
                        }
                    }
                );
                const updatedElement = {
                    ...matchedElement,
                    attributes: updatedAttributes
                };
                matchedRoom.elements?.splice(
                    matchedRoom.elements.indexOf(matchedElement),
                    1,
                    updatedElement
                );
                stateRooms.splice(roomIndex, 1, matchedRoom);

                return {
                    ...state,
                    protocol: {
                        ...state.protocol,
                        rooms: stateRooms
                    },
                    protocolState: {
                        ...state.protocolState,
                        is_user_editing: true,
                        protocol_sync_required: true
                    }
                };
            }
            break;
        }
    }

    return state;
};

const handleOfflineMetersMediaPatchRequest = (
    state: IOfflineProtocol,
    action: AnyAction
) => {
    const { id, proofType } = action.payload;
    let updatedProtocol = { ...state.protocol };

    if (proofType === ELECTRICITY_PROOF_TYPE) {
        updatedProtocol = { ...state.protocol, electricity_media_id: id };
    }
    if (proofType === WATER_PROOF_TYPE) {
        updatedProtocol = { ...state.protocol, water_media_id: id };
    }
    if (proofType === GAS_PROOF_TYPE) {
        updatedProtocol = { ...state.protocol, gas_media_id: id };
    }

    return {
        ...state,
        protocol: updatedProtocol,
        protocolMedia: {
            ...state.protocolMedia,
            [id]: {
                ...state.protocolMedia[id],
                proofType,
                localId: id
            }
        },
        protocolState: {
            ...state.protocolState,
            is_user_editing: true,
            protocol_sync_required: true
        }
    };
};

const handleOfflineMetersMediaPatchSuccess = (
    state: IOfflineProtocol,
    action: AnyAction
) => {
    const { med_id: id, med_type: proofType } = action.payload;
    let updatedProtocol = { ...state.protocol };

    if (proofType === ELECTRICITY_PROOF_TYPE) {
        updatedProtocol = { ...state.protocol, electricity_media_id: id };
    }
    if (proofType === WATER_PROOF_TYPE) {
        updatedProtocol = { ...state.protocol, water_media_id: id };
    }
    if (proofType === GAS_PROOF_TYPE) {
        updatedProtocol = { ...state.protocol, gas_media_id: id };
    }

    return {
        ...state,
        protocol: updatedProtocol,
        // protocolMedia: {
        //     ...state.protocolMedia,
        //     [id]: {
        //         ...state.protocolMedia[id],
        //         proofType,
        //         // localId: id
        //     }
        // },
        protocolState: {
            ...state.protocolState,
            is_user_editing: false
        }
    };
};

const handleOfflineUsedTenantDeposit = (
    state: IOfflineProtocol,
    action: AnyAction
) => {
    const { prt_used_tenant_deposit } = action.payload;

    return {
        ...state,
        protocol: {
            ...state.protocol,
            prt_used_tenant_deposit
        },
        protocolState: {
            ...state.protocolState,
            is_user_editing: true,
            protocol_sync_required: true
        }
    };
};

const handleResetOfflineProtocolState = () => {
    return initialProtocolState;
};

const handleUpdateSynchronousAttendee = (
    state: IOfflineProtocol,
    action: AnyAction
) => {
    const { att_id } = action.payload;

    const oldAttendees = state.protocol.attendees;

    const updatedAttendeesArray = oldAttendees?.map(
        (attendee: AttendeesEntity) => {
            if (!attendee.pk) {
                return { ...attendee, pk: att_id };
            } else {
                return attendee;
            }
        }
    );
    return {
        ...state,
        protocol: {
            ...state.protocol,
            attendees: updatedAttendeesArray
        },
        protocolState: {
            ...state.protocolState,
            is_user_editing: true,
            protocol_sync_required: true
        }
    };
};

const handleUpdateSynchronousRoom = (
    state: IOfflineProtocol,
    action: AnyAction
) => {
    const { prom_id, prom_pele_id } = action.payload;

    const stateRooms = [...(state.protocol.rooms || [])];
    const updatedRooms = stateRooms.map((room) => {
        if (!room.pk) {
            return {
                ...room,
                pk: prom_id,
                elements: prom_pele_id
            };
        } else {
            return room;
        }
    });
    return {
        ...state,
        protocol: {
            ...state.protocol,
            rooms: updatedRooms
        },
        protocolState: {
            ...state.protocolState,
            is_user_editing: true,
            protocol_sync_required: true
        }
    };
};

const handleUpdateSynchronousElement = (
    state: IOfflineProtocol,
    action: AnyAction
) => {
    const { pele_id, pele_prom_id } = action.payload;

    const stateRooms = [...(state.protocol.rooms || [])];
    const matchedRoom = stateRooms.find((room) => room.pk === pele_prom_id);
    let newState = state;
    if (matchedRoom) {
        const stateElements = [...(matchedRoom.elements || [])];
        const newElements = stateElements.map((element) => {
            if (!element.pk) {
                return {
                    ...element,
                    pk: pele_id
                };
            } else {
                return element;
            }
        });
        const updatedMatchedRoom = { ...matchedRoom, elements: newElements };
        stateRooms.splice(
            stateRooms.indexOf(matchedRoom),
            1,
            updatedMatchedRoom
        );

        newState = {
            ...state,
            protocol: {
                ...state.protocol,
                rooms: stateRooms
            },
            protocolState: {
                ...state.protocolState,
                is_user_editing: true,
                protocol_sync_required: true
            }
        };
    }
    return newState;
};

const handleUpdateSynchronousItem = (
    state: IOfflineProtocol,
    action: AnyAction
) => {
    const { pitm_id } = action.payload;
    const stateItems = [...(state.protocol.items || [])];
    const newItems = stateItems.map((item) => {
        if (!item.pk) {
            return {
                ...item,
                pk: pitm_id
            };
        } else {
            return item;
        }
    });
    return {
        ...state,
        protocol: {
            ...state.protocol,
            items: newItems
        },
        protocolState: {
            ...state.protocolState,
            is_user_editing: true,
            protocol_sync_required: true
        }
    };
};

const handleUpdateSynchronousElementAttributes = (
    state: IOfflineProtocol,
    action: AnyAction
) => {
    const { pela_pele_id, state: pela_state } = action.payload;

    const stateRooms = [...(state.protocol.rooms || [])];

    for (let roomIndex = 0; roomIndex < stateRooms.length; roomIndex++) {
        const room = stateRooms[roomIndex];
        const target = room.elements?.find(
            (element) =>
                element.pk === pela_pele_id || element.localId === pela_pele_id
        );
        if (target) {
            const matchedRoom = stateRooms[roomIndex];
            const matchedElement = target;
            if (matchedRoom && matchedElement) {
                const updatedAttributes = pela_state.map(
                    (attr: { pk: number; pela_id: number }, index: number) => {
                        if (!attr.pk) {
                            // const localAttrId = -(index + 1);
                            return {
                                ...attr,
                                pk: attr.pela_id
                            };
                        } else {
                            return attr;
                        }
                    }
                );
                const updatedElement = {
                    ...matchedElement,
                    attributes: updatedAttributes
                };
                matchedRoom.elements?.splice(
                    matchedRoom.elements.indexOf(matchedElement),
                    1,
                    updatedElement
                );
                stateRooms.splice(roomIndex, 1, matchedRoom);

                return {
                    ...state,
                    protocol: {
                        ...state.protocol,
                        rooms: stateRooms
                    },
                    protocolState: {
                        ...state.protocolState,
                        is_user_editing: true,
                        protocol_sync_required: true
                    }
                };
            }
            break;
        }
    }

    return state;
};

const handleUpdateSynchronousIssues = (
    state: IOfflineProtocol,
    action: AnyAction
) => {
    const {
        pk: issueId,
        protocol_room,
        protocol_element,
        protocol_item
    } = action.payload;

    const stateRooms = [...(state.protocol.rooms || [])];
    const stateItems = [...(state.protocol.items || [])];

    if (protocol_item === null) {
        // is element Issue
        const matchedRoom = stateRooms.find(
            (room) =>
                room.pk === protocol_room || room.localId === protocol_room
        );

        if (matchedRoom) {
            const updateRoomIndex = stateRooms.indexOf(matchedRoom);
            const matchedElement =
                matchedRoom?.elements?.find((element) => {
                    return (
                        element.pk === protocol_element ||
                        element.localId === protocol_element
                    );
                }) || ({} as ElementsEntity);

            const updatedIssues = matchedElement?.issues?.map((issue) => {
                if (!issue.pk) {
                    const updatedIssue = {
                        ...issue,
                        pk: issueId
                    };
                    return updatedIssue;
                } else {
                    return issue;
                }
            });
            matchedRoom.elements?.splice(
                matchedRoom.elements.indexOf(matchedElement),
                1,
                {
                    ...matchedElement,
                    issues: updatedIssues
                }
            );

            stateRooms.splice(updateRoomIndex, 1, matchedRoom);

            return {
                ...state,
                protocol: {
                    ...state.protocol,
                    rooms: stateRooms
                },
                protocolState: {
                    ...state.protocolState,
                    is_user_editing: true,
                    protocol_sync_required: true
                }
            };
        } else {
            return state;
        }
    } else {
        // is Item Issue
        const matchedItem =
            stateItems.find((item) => item.pk === protocol_item) ||
            ({} as ItemsEntity);

        const updatedIssues = matchedItem?.issues?.map((issue) => {
            if (!issue.pk) {
                const updatedIssue = {
                    ...issue,
                    pk: issueId
                };
                return updatedIssue;
            } else {
                return issue;
            }
        });

        stateItems.splice(stateItems.indexOf(matchedItem), 1, {
            ...matchedItem,
            issues: updatedIssues
        });

        return {
            ...state,
            protocol: {
                ...state.protocol,
                items: stateItems
            },
            protocolState: {
                ...state.protocolState,
                is_user_editing: true,
                protocol_sync_required: true
            }
        };
    }
};

const startSyncingAsyncProtocol = (
    state: IOfflineProtocol,
    action: AnyAction
) => {
    return {
        ...state,
        protocolState: {
            ...state.protocolState,
            protocol_syncing: true
        }
    };
};

const endSyncingAsyncProtocol = (
    state: IOfflineProtocol,
    action: AnyAction
) => {
    return {
        ...state,
        protocolState: {
            ...state.protocolState,
            protocol_syncing: false
        }
    };
};

// for future ref
const handleUpdateMediaIdSuccessCount = (
    state: IOfflineProtocol,
    action: AnyAction
) => {
    // handle update local protocol media with the one recieved from BE
    // const { payload, localId } = action;
    // const { media } = payload;

    // const isLocalIdPresent = Object.keys(state.protocolMedia).includes(localId);

    // const updatedProtocolMedia = {
    //     ...state.protocolMedia,
    //     [localId]: {
    //         ...state.protocolMedia[localId],
    //         media
    //     }
    // };

    return {
        ...state,
        protocolState: {
            ...state.protocolState,
            sync_upload_progress: {
                ...state.protocolState.sync_upload_progress,
                uploadedMediaSuccess:
                    state.protocolState.sync_upload_progress
                        .uploadedMediaSuccess + 1
            }
        }
        // protocolMedia: updatedProtocolMedia
    };
};

const handleUpdateProtocolMedia = (
    state: IOfflineProtocol,
    action: AnyAction
) => {
    // handle update local protocol media with the one recieved from BE
    const { payload, localId } = action;
    const { media } = payload;

    const updatedProtocolMedia = {
        ...state.protocolMedia,
        [localId]: {
            ...state.protocolMedia[localId],
            media
        }
    };

    const stateWithUpdatedMediaId = {
        ...state,
        protocolMedia: updatedProtocolMedia
    };

    return handleNormaliseOfflineMedia(stateWithUpdatedMediaId, {
        saveNormalState: false
    }); // normalising RIGHT AFTER updating media id
    return {
        ...state,
        protocolMedia: updatedProtocolMedia
    };
};

const handleUpdateMediaIdFail = (
    state: IOfflineProtocol,
    action: AnyAction
) => {
    return {
        ...state,
        protocolState: {
            ...state.protocolState,
            sync_upload_progress: {
                ...state.protocolState.sync_upload_progress,
                uploadedMediaFail:
                    state.protocolState.sync_upload_progress
                        .uploadedMediaSuccess + 1
            }
        }
    };
};

const handleUpdateSyncingProgress = (
    state: IOfflineProtocol,
    action: AnyAction
) => {
    const { uploadQueue = [], valueType = 'CURRENT' } = action.payload;
    if (valueType === 'INITIAL') {
        const totalQueueLength = uploadQueue.length;
        return {
            ...state,
            protocolState: {
                ...state.protocolState,
                sync_upload_progress: {
                    ...state.protocolState.sync_upload_progress,
                    totalUploadLength: totalQueueLength,
                    currentUploadProgress: 0
                }
            }
        };
    }
    if (valueType === 'CURRENT') {
        const currentQueueLength = uploadQueue.length;
        return {
            ...state,
            protocolState: {
                ...state.protocolState,
                sync_upload_progress: {
                    ...state.protocolState.sync_upload_progress,
                    currentUploadProgress: currentQueueLength
                }
            }
        };
    }
    return state;
};

const handleOfflinePreviewPathSave = (
    state: IOfflineProtocol,
    action: AnyAction
) => {
    const { id, preview } = action;

    // const isLocalIdPresent = Object.keys(state.protocolMedia).includes(id);

    const updatedProtocolMedia = {
        ...state.protocolMedia,
        [id]: {
            ...state.protocolMedia[id],
            preview
        }
    };

    return {
        ...state,
        protocolMedia: updatedProtocolMedia
    };
};
const handleOfflineThumbnailPreviewPathSave = (
    state: IOfflineProtocol,
    action: AnyAction
) => {
    const { id, thumbnail, videoBase = '' } = action;

    const updatedProtocolMedia = {
        ...state.protocolMedia,
        [id]: {
            ...state.protocolMedia[id],
            preview: thumbnail,
            videoBase
        }
    };

    return {
        ...state,
        protocolMedia: updatedProtocolMedia
    };
};

const replaceLocalMediaId = (
    state: IOfflineProtocol,
    localIdArray: number[]
) => {
    const protocolMedias = { ...state.protocolMedia };
    const updatedIdArray: number[] = [];
    localIdArray.map((localMediaId) => {
        if (localMediaId < 0) {
            const newMediaId =
                protocolMedias[localMediaId]?.media?.med_id || localMediaId;
            updatedIdArray.push(newMediaId);
        } else {
            updatedIdArray.push(localMediaId);
        }
    });
    return updatedIdArray;
};

const handleNormaliseOfflineMedia = (
    state: IOfflineProtocol,
    { saveNormalState = false }: { saveNormalState: boolean }
) => {
    // lay down all the media in protocol media, on the protocol based on the matching local IDs

    const mediaList = { ...state.protocolMedia };

    let updatedOfflineProtocol = { ...state.protocol };

    let updatedRooms = [...(state.protocol.rooms || [])];
    let updatedItems = [...(state.protocol.items || [])];
    let updatedAttendees = [...(state.protocol.attendees || [])];
    // let updatedElectricityMediaId = state.protocol.electricity_media_id;
    // let updatedWaterMediaId = state.protocol.water_media_id;
    // let updatedGasMediaId = state.protocol.gas_media_id;

    Object.values(mediaList)?.forEach((mediaElement: any) => {
        const {
            protocol_attendee = null,
            protocol_room = null,
            protocol_element = null,
            protocol_item = null,
            proofType = null,
            media = {},
            localId = null
        } = mediaElement;
        const { med_id } = media;

        if (!localId) return; // edge case , just in case

        if (protocol_element) {
            // element media
            const matchedRoom = updatedRooms.find(
                (room) =>
                    room.pk === protocol_room || room.localId === protocol_room
            );
            if (matchedRoom) {
                const matchedRoomElements = [...(matchedRoom.elements || [])];
                const matchedElement = matchedRoomElements.find(
                    (element) =>
                        element.pk === protocol_element ||
                        element.localId === protocol_element
                );
                if (matchedElement) {
                    const newIssues = matchedElement?.issues?.map((issue) => {
                        if (issue.media_ids?.length) {
                            return {
                                ...issue,
                                media_ids: replaceLocalMediaId(
                                    state,
                                    issue.media_ids
                                ),
                                uploads: replaceLocalMediaId(
                                    state,
                                    issue.media_ids
                                )
                            };
                        } else {
                            return issue;
                        }
                    });
                    const updatedMatchedElement = {
                        ...matchedElement,
                        ...(matchedElement.media_ids && {
                            media_ids: replaceLocalMediaId(
                                state,
                                matchedElement.media_ids
                            ),
                            uploads: replaceLocalMediaId(
                                state,
                                matchedElement.media_ids
                            )
                        }),
                        issues: newIssues
                    };
                    matchedRoomElements.splice(
                        matchedRoomElements.indexOf(matchedElement),
                        1,
                        updatedMatchedElement
                    );
                    const updatedMatchedRoom = {
                        ...matchedRoom,
                        elements: matchedRoomElements
                    };
                    updatedRooms.splice(
                        updatedRooms.indexOf(matchedRoom),
                        1,
                        updatedMatchedRoom
                    );
                }

                updatedOfflineProtocol = {
                    ...updatedOfflineProtocol,
                    rooms: updatedRooms
                };
            }
        }

        if (protocol_item) {
            // items media
            const matchedItem =
                updatedItems.find(
                    (item) =>
                        item.pk === protocol_item ||
                        item.localId === protocol_item
                ) || ({} as ItemsEntity);

            const updatedIssues = matchedItem?.issues?.map((issue) => {
                if (issue.media_ids?.length) {
                    return {
                        ...issue,
                        media_ids: replaceLocalMediaId(state, issue.media_ids),
                        uploads: replaceLocalMediaId(state, issue.media_ids)
                    };
                } else {
                    return issue;
                }
            });

            updatedItems.splice(updatedItems.indexOf(matchedItem), 1, {
                ...matchedItem,
                ...(matchedItem.media_ids && {
                    media_ids: replaceLocalMediaId(
                        state,
                        matchedItem.media_ids
                    ),
                    uploads: replaceLocalMediaId(state, matchedItem.media_ids)
                }),
                issues: updatedIssues
            });

            updatedOfflineProtocol = {
                ...updatedOfflineProtocol,
                items: updatedItems
            };
        }

        // attendees Profile picture media
        if (protocol_attendee) {
            const matchedAttendee =
                updatedAttendees.find(
                    (item) =>
                        item.pk === protocol_attendee ||
                        item.localId === protocol_attendee
                ) || ({} as AttendeesEntity);

            updatedAttendees.splice(
                updatedAttendees.indexOf(matchedAttendee),
                1,
                {
                    ...matchedAttendee,
                    ...(matchedAttendee.uploads && {
                        picture_media_id: replaceLocalMediaId(
                            state,
                            matchedAttendee.uploads
                        )[0],
                        uploads: replaceLocalMediaId(
                            state,
                            matchedAttendee.uploads
                        )
                    })
                }
            );

            updatedOfflineProtocol = {
                ...updatedOfflineProtocol,
                attendees: updatedAttendees
            };
        }

        // meters media
        if (proofType === ELECTRICITY_PROOF_TYPE) {
            updatedOfflineProtocol = {
                ...updatedOfflineProtocol,
                electricity_media_id: med_id
            };
        }
        if (proofType === WATER_PROOF_TYPE) {
            updatedOfflineProtocol = {
                ...updatedOfflineProtocol,
                water_media_id: med_id
            };
        }
        if (proofType === GAS_PROOF_TYPE) {
            updatedOfflineProtocol = {
                ...updatedOfflineProtocol,
                gas_media_id: med_id
            };
        }
    });

    return {
        ...state,
        protocol: updatedOfflineProtocol,
        protocolState: {
            ...state.protocolState,
            is_protocol_normal: saveNormalState,
            is_user_editing: true,
            protocol_sync_required: true
        }
    };
};

const handleSetSyncingState = (state: IOfflineProtocol, action: AnyAction) => {
    const { syncState = 'CLOSED' } = action;

    return {
        ...state,
        protocolState: {
            ...state.protocolState,
            syncingState: syncState
        }
    };
};

type UpdatedProtocolActions =
    | IOfflineProtocolActions
    | RT<typeof patchMediaSuccess>
    | RT<typeof backgroundSyncSuccess>;

function offlineProtoReducer(
    state = initialProtocolState,
    action: UpdatedProtocolActions
) {
    switch (action.type) {
        case acts.GET_OFFLINE_PROTOCOL_CHECKOUT_ID_REQUEST: {
            const updateProtocolState = !Boolean(action.noUpdate);
            const shouldUpdateProtocol = action.protoId !== state.protocol.pk;
            const willUpdateProtocol = updateProtocolState
                ? shouldUpdateProtocol
                : false;
            const protocolState = willUpdateProtocol
                ? {
                      ...initialProtocolState.protocol,
                      prt_stage: {
                          items_done: false,
                          rooms_done: false,
                          meters_done: false,
                          attendees_done: false
                      }
                  }
                : {
                      ...state.protocol
                  };
            return {
                ...state,
                protocol: protocolState
            };
        }
        case acts.GET_OFFLINE_PROTOCOL_CHECKOUT_ID_SUCCESS: {
            return handleOfflineProtocolSave(state, action);
        }
        case acts.GET_OFFLINE_PROTOCOL_CHECKOUT_ID_FAIL:
            return state;
        case acts.POST_OFFLINE_PROTOCOL_CHECKIN_STATUS_SUCCESS:
            const {
                status: checkStatus,
                checkout_data: checkedOutProtocol,
                ...newCheckoutData
            } = action.payload.payload;
            const setUserEditing =
                checkStatus === 'ACTIVE'
                    ? Boolean(state.protocolState.is_user_editing)
                    : false;
            const currentTime = moment().toISOString();

            const shouldNotUpdateState = Boolean(
                (action as AnyAction).originator?.shouldNotUpdateState
            );

            if (shouldNotUpdateState) {
                return {
                    ...state
                };
            }

            return {
                ...state,
                protocol: {
                    ...state.protocol,
                    checkout_data: {
                        ...state.protocol.checkout_data,
                        ...newCheckoutData,
                        status: checkStatus
                    },
                    checkout_id: newCheckoutData.pk
                },
                protocolState: {
                    ...state.protocolState,
                    protocol_sync_required: false,
                    is_user_editing: setUserEditing,
                    checkinInvalid: false,
                    lastCheckinTime: currentTime,
                    protocolOfflineSave: {
                        ...state.protocolState.protocolOfflineSave,
                        run: false,
                        exit: false,
                        path: null,
                        failed: false,
                        openCheckoutFail: false,
                        bannerWhenOffline: false
                    }
                }
            };
        case acts.POST_OFFLINE_PROTOCOL_CHECKIN_STATUS_FAIL:
            return {
                ...state,
                protocolState: {
                    ...state.protocolState,
                    checkinInvalid: true,
                    saving_protocol_data: false,
                    protocolOfflineSave: {
                        ...state.protocolState.protocolOfflineSave,
                        run: false,
                        exit: false,
                        path: null,
                        failed: false,
                        openCheckoutFail: false,
                        bannerWhenOffline: false
                    }
                }
            };
        case acts.RESET_PROTOCOL_INVALID_CHECKIN:
            return {
                ...state,
                protocolState: {
                    ...state.protocolState,
                    checkinInvalid: false
                }
            };
        case acts.SYNC_OFFLINE_PROTOCOL_PREVIEW_REQUEST:
            return {
                ...state,
                protocolState: {
                    ...state.protocolState,
                    syncingState: 'SYNCING'
                }
            };
        case acts.POST_OFFLINE_PROTOCOL_REQUEST:
            return {
                ...state,
                protocol: {
                    ...state.protocol,
                    prt_status: 'done'
                }
            };
        case acts.SYNC_OFFLINE_PROTOCOL_PREVIEW_SUCCESS:
            return {
                ...state,
                protocolState: {
                    ...state.protocolState,
                    syncingState: 'SUCCESS'
                }
            };
        case acts.POST_OFFLINE_PROTOCOL_SUCCESS:
            return {
                ...state,
                protocol: {
                    ...state.protocol,
                    prt_status: 'done'
                },
                protocolState: {
                    ...state.protocolState,
                    is_user_editing: false,
                    is_completed: true
                }
            };
        case acts.SYNC_OFFLINE_PROTOCOL_PREVIEW_FAIL:
            return {
                ...state,
                protocolState: {
                    ...state.protocolState,
                    syncingState: 'ERROR'
                }
            };
        case acts.POST_OFFLINE_PROTOCOL_FAIL:
            return state;
        case acts.PATCH_OFFLINE_PROTOCOL:
            const protocol = action.payload;

            return {
                ...state,
                protocol: {
                    ...state.protocol,
                    ...protocol
                }
            };
        case acts.PATCH_OFFLINE_PROTOCOL_METERS:
            const { prt_meter_electricity, prt_meter_gas, prt_meter_water } =
                action.payload;
            const metersUpdatedProtocol = {
                ...state.protocol,
                prt_meter_electricity,
                prt_meter_gas,
                prt_meter_water
            };
            const stateProtocolWasUpdated = !Boolean(
                state.protocol.prt_meter_electricity ===
                    metersUpdatedProtocol.prt_meter_electricity &&
                    state.protocol.prt_meter_gas ===
                        metersUpdatedProtocol.prt_meter_gas &&
                    state.protocol.prt_meter_water ===
                        metersUpdatedProtocol.prt_meter_water
            );
            return {
                ...state,
                protocol: metersUpdatedProtocol,
                protocolState: {
                    ...state.protocolState,
                    protocol_sync_required: stateProtocolWasUpdated
                }
            };
        case acts.PATCH_OFFLINE_PROTOCOL_STEP:
            const { current_step, prt_stage } = action.payload;
            const stepUpdatedProtocol = {
                ...state.protocol,
                current_step,
                prt_stage
            };
            return {
                ...state,
                protocol: stepUpdatedProtocol,
                protocolState: {
                    ...state.protocolState
                }
            };
        case acts.PATCH_OFFLINE_PROTOCOL_ROOM:
            const { payload, id } = action;
            const { prom_ok, prom_needs_cleaning } = payload;
            let updatedRooms: RoomsEntity[] = [];
            state.protocol.rooms?.map((room) => {
                let newRoom = { ...room };
                if (room.pk === id || room.localId === id) {
                    newRoom = {
                        ...room,
                        prom_ok,
                        prom_needs_cleaning
                    };

                    const updatedElements: ElementsEntity[] = [];
                    newRoom?.elements?.map((element) => {
                        element.pele_ok = prom_ok;
                        updatedElements.push(element);
                    });
                    newRoom.elements = updatedElements;
                }
                updatedRooms.push(newRoom);
            });

            return {
                ...state,
                protocol: {
                    ...state.protocol,
                    rooms: updatedRooms
                },
                protocolState: {
                    ...state.protocolState,
                    is_user_editing: true,
                    protocol_sync_required: true
                }
            };
        case acts.PATCH_OFFLINE_ATTENDEE_REQUEST: {
            const { id, isPresent } = action;
            const oldAttendees = state.protocol.attendees;
            const updatedAttendee = oldAttendees?.map((oldAtt) => {
                if (
                    oldAtt.pk === id ||
                    oldAtt.localId === id ||
                    oldAtt.att_id === id
                ) {
                    oldAtt.att_present = isPresent;
                }
                return oldAtt;
            });
            return {
                ...state,
                protocol: {
                    ...state.protocol,
                    attendees: updatedAttendee
                },
                protocolState: {
                    ...state.protocolState,
                    is_user_editing: true,
                    protocol_sync_required: true
                }
            };
        }
        case acts.PATCH_OFFLINE_PROTOCOL_NOTES:
            const { prt_comment } = action;
            return {
                ...state,
                protocol: {
                    ...state.protocol,
                    prt_comment
                },
                protocolState: {
                    ...state.protocolState,
                    is_user_editing: true,
                    protocol_sync_required: true
                }
            };
        case acts.PATCH_OFFLINE_PROTOCOL_CLEANING:
            const { prt_date_cleaning } = action.payload;
            return {
                ...state,
                protocol: {
                    ...state.protocol,
                    prt_date_cleaning
                },
                protocolState: {
                    ...state.protocolState,
                    is_user_editing: true,
                    protocol_sync_required: true
                }
            };
        case acts.PATCH_OFFLINE_PROTOCOL_ROOM_ELEMENT:
            return handlePatchProtocolRoomElement(state, action);
        case acts.CREATE_OFFLINE_PROTOCOL_ATTENDEES:
            return handleCreateOfflineAttendees(state, action);
        case acts.CREATE_OFFLINE_PROTOCOL_ELEMENT:
            return handleCreateOfflineElement(state, action);
        case acts.OFFLINE_PROTOCOL_ROOM_QUICKADD:
            return handleRoomQuickAdd(state, action);
        case acts.CREATE_OFFLINE_PROTOCOL_ROOM:
            return handleCreateOfflineRoom(state, action);
        case acts.CREATE_OFFLINE_PROTOCOL_ITEM:
            return handleOfflineItemCreate(state, action);
        case acts.CREATE_OFFLINE_PROTOCOL_ISSUE:
            return handleOfflineIssueCreate(state, action);
        case acts.OFFLINE_PROTOCOL_ATTRIBUTE_CRD:
            return handleOfflineAttributeCrd(state, action);
        case acts.UPDATE_OFFLINE_PROTOCOL_ATTENDEES:
            return handleUpdateOfflineAttendees(state, action);
        case acts.UPDATE_OFFLINE_PROTOCOL_ELEMENT:
            return handleUpdateOfflineElement(state, action);
        case acts.UPDATE_OFFLINE_PROTOCOL_ROOM:
            return handleUpdateOfflineRoom(state, action);
        case acts.UPDATE_OFFLINE_PROTOCOL_ITEM:
            return handleOfflineItemUpdate(state, action);
        case acts.UPDATE_OFFLINE_PROTOCOL_ISSUE:
            return handleOfflineIssueUpdate(state, action);
        case acts.PATCH_OFFLINE_PROTOCOL_ITEMS_CHECK:
            return handleProtocolItemCheck(state, action);
        case acts.DELETE_OFFLINE_PROTOCOL_ATTENDEES:
            const attendeeId = action.payload;
            const stateAttendee = state.protocol.attendees;
            const matchedAttendee = stateAttendee?.find(
                (attendee) =>
                    attendee.pk === attendeeId ||
                    attendee.localId === attendeeId
            );
            stateAttendee?.splice(
                stateAttendee.indexOf(matchedAttendee as AttendeesEntity),
                1
            );
            return {
                ...state,
                protocol: {
                    ...state.protocol,
                    attendees: stateAttendee
                },
                protocolState: {
                    ...state.protocolState,
                    is_user_editing: true,
                    protocol_sync_required: true
                }
            };
        case acts.DELETE_OFFLINE_PROTOCOL_ELEMENT:
            return handleOfflineElementDelete(state, action);
        case acts.DELETE_OFFLINE_PROTOCOL_ROOM:
            return handleDeleteOfflineRoom(state, action);
        case acts.DELETE_OFFLINE_PROTOCOL_ITEM:
            return handleOfflineItemDelete(state, action);
        case acts.DELETE_OFFLINE_PROTOCOL_ISSUE:
            return handleOfflineIssueDelete(state, action);

        // misc
        case acts.OFFLINE_PROTOCOL_POST_SIGNATURE_REQUEST: {
            const { media_id, attendee_id, declined } = action.signature;

            const signatureMediaId = media_id || null;

            if (!signatureMediaId) return state;

            const stateAttendees = [...(state.protocol.attendees || [])];

            const newAttendees = stateAttendees.map((attendee) => {
                if (
                    attendee.pk === attendee_id ||
                    attendee.localId === attendee_id
                ) {
                    return {
                        ...attendee,
                        signature_media_id: signatureMediaId || null,
                        signature_media: {
                            ...attendee.signature_media,
                            declined
                        },

                        ...(signatureMediaId && { signature_declined: false })
                    };
                } else {
                    return attendee;
                }
            });
            return {
                ...state,
                protocol: {
                    ...state.protocol,
                    attendees: newAttendees
                },
                protocolState: {
                    ...state.protocolState,
                    is_user_editing: true,
                    protocol_sync_required: true
                }
                // protocolMedia: {
                //     ...state.protocolMedia,
                //     [signatureMediaId]: {
                //         ...state.protocolMedia[signatureMediaId],
                //         attendee_id
                //     }
                // }
            };
        }
        case 'OFFLINE_PROTOCOL_POST_SIGNATURE_RESPONSE' as 'OFFLINE_PROTOCOL_POST_SIGNATURE_SUCCESS': {
            const { uploads, entity } = action.payload;
            const { declined } = entity;

            const signatureMediaId = entity.uploads[0] || null;

            const signatureMediaObject = uploads[signatureMediaId].media;

            const stateAttendees = [...(state.protocol.attendees || [])];

            const newAttendees = stateAttendees.map((attendee) => {
                if (
                    attendee.pk === entity.attendee_id ||
                    attendee.localId === entity.attendee_id
                ) {
                    return {
                        ...attendee,
                        signature_media_id: signatureMediaId || null,
                        signature_media: {
                            ...signatureMediaObject,
                            declined
                        },

                        ...(signatureMediaId && { signature_declined: false })
                    };
                } else {
                    return attendee;
                }
            });
            return {
                ...state,
                protocol: {
                    ...state.protocol,
                    attendees: newAttendees
                },
                protocolState: {
                    ...state.protocolState,
                    is_user_editing: true,
                    protocol_sync_required: true
                    // sync_upload_progress: {
                    //     ...state.protocolState.sync_upload_progress,
                    //     uploadedMediaSuccess:
                    //         state.protocolState.sync_upload_progress
                    //             .uploadedMediaSuccess + 1
                    // }
                }
            };
        }
        case acts.OFFLINE_PROTOCOL_SIGNATURE_DELETE:
            const updatedProtocolMedia = state.protocolMedia;
            delete updatedProtocolMedia[action.signId];
            return {
                ...state,
                protocolMedia: updatedProtocolMedia
            };

        case acts.SWITCH_OFFLINE_PROTOCOL_ASYNCHRONOUS:
            return {
                ...state,
                protocolState: {
                    ...state.protocolState,
                    is_async: true,
                    is_finished: false
                }
            };
        case acts.SWITCH_OFFLINE_PROTOCOL_SYNCHRONOUS:
            return {
                ...state,
                protocolState: {
                    ...state.protocolState,
                    is_async: false
                }
            };
        case acts.OFFLINE_PROTOCOL_POST_SIGNATURE_FAIL:
            return state;
        case acts.CHECK_OFFLINE_PDF_READY_SUCCESS: {
            const { result, uploads } = action.payload;
            const pdfPath = uploads[result].path;

            const oldProtocol = state.protocol;
            if (!oldProtocol) return state;

            return {
                ...state,
                protocol: {
                    ...state.protocol,
                    protocol_pdf: pdfPath
                },
                protocolState: {
                    ...state.protocolState,
                    is_user_editing: false,
                    protocolOfflineSave: {
                        ...state.protocolState.protocolOfflineSave,
                        run: false,
                        exit: false,
                        path: null,
                        failed: false,
                        openCheckoutFail: false
                    }
                }
            };
        }
        case acts.PATCH_OFFLINE_MEDIA_REQUEST:
            console.log('PATCH_OFFLINE_MEDIA_REQUEST ----->', action);
            // return state;
            return handleOfflineMetersMediaPatchRequest(state, action);
        case acts.PATCH_OFFLINE_MEDIA_SUCCESS:
            console.log('PATCH_OFFLINE_MEDIA_SUCCESS ----->', action);
            return handleOfflineMetersMediaPatchSuccess(state, action);
        case acts.PATCH_OFFLINE_MEDIA_FAIL:
            console.log('PATCH_OFFLINE_MEDIA_FAIL ----->', action);
            return state;
        case acts.PATCH_USED_TENANT_DEPOSIT:
            return handleOfflineUsedTenantDeposit(state, action);

        // reset protocol state for cases:
        case 'POST_PROTOCOL_SUCCESS':
        case acts.DISCARD_OFFLINE_PROTOCOL_STATE:
            return handleResetOfflineProtocolState();

        // Async Syncing Helpers
        case acts.INITIATE_OFFLINE_SYNCING:
            return startSyncingAsyncProtocol(state, action);
        case acts.END_OFFLINE_SYNCING:
            return endSyncingAsyncProtocol(state, action);
        case acts.ASYNC_SYNC_UPLOADING_PROGRESS:
            return handleUpdateSyncingProgress(state, action);
        // case acts.BACKGROUND_SYNC_SUCCESS:
        // case acts.CREATE_MEDIA_SUCCESS:
        // case acts.OFFLINE_PROTOCOL_POST_SIGNATURE_SUCCESS:
        case acts.NORMALISE_ASYNC_PROTOCOL_OFFLINE_MEDIA:
            return handleNormaliseOfflineMedia(state, {
                saveNormalState: true
            });
        case acts.SET_OFFLINE_PROTOCOL_SYNCING_STATE:
            return handleSetSyncingState(state, action);

        // synchronous mode Id updaters
        case 'POST_ATTENDEE_SUCCESS':
            return handleUpdateSynchronousAttendee(state, action);
        case 'POST_PROTOCOL_ROOM_SUCCESS':
            return handleUpdateSynchronousRoom(state, action);
        case 'POST_PROTOCOL_ELEMENT_SUCCESS':
            return handleUpdateSynchronousElement(state, action);
        case 'POST_PROTOCOL_ITEM_SUCCESS':
            return handleUpdateSynchronousItem(state, action);
        case 'POST_PROTOCOL_ELEMENT_ATTRIBUTES_SUCCESS':
            return handleUpdateSynchronousElementAttributes(state, action);
        case 'POST_PROTOCOL_ISSUE_SUCCESS':
            return handleUpdateSynchronousIssues(state, action);
        case 'FINISH_PROTOCOL_SUCCESS':
            return {
                ...state,
                protocolState: {
                    ...state.protocolState,
                    is_user_editing: false,
                    // is_async: false,
                    is_completed: true
                }
            };

        case 'BACKGROUND_SYNC_START':
            return {
                ...state,
                protocolState: {
                    ...state.protocolState,
                    is_protocol_normal: false
                }
            };
        case 'BACKGROUND_SYNC_FAIL':
            return {
                ...state,
                protocolState: {
                    ...state.protocolState,
                    is_protocol_normal: false
                }
            };

        // async mode switch helpers
        // case 'CONNECTIVITY_CHANGE_OFFLINE':

        // case 'POST_ATTENDEE_FAIL':
        // case 'POST_PROTOCOL_ELEMENT_FAIL':
        // case 'POST_PROTOCOL_ITEM_FAIL':
        // case 'POST_PROTOCOL_ROOM_FAIL':
        // case 'POST_PROTOCOL_ISSUE_FAIL':
        // case 'POST_PROTOCOL_ELEMENT_ATTRIBUTES_FAIL':

        // case 'PATCH_ATTENDEE_FAIL':
        // case 'PATCH_PROTOCOL_ROOM_FAIL':
        // case 'PATCH_PROTOCOL_ELEMENT_FAIL':
        // case 'PATCH_PROTOCOL_ITEM_FAIL':
        // case 'PATCH_PROTOCOL_ISSUE_FAIL':
        // case 'PATCH_PROTOCOL_FAIL':

        // case 'DEL_ATTENDEE_FAIL':
        // case 'DELETE_PROTOCOL_ROOM_FAIL':
        // case 'DELETE_PROTOCOL_ELEMENT_FAIL':
        // case 'DELETE_PROTOCOL_ITEM_FAIL':
        // case 'DELETE_PROTOCOL_ISSUE_FAIL':
        // case 'DELETE_PROTOCOL_FAIL':
        //     const { useBeta = false } = action as AnyAction;
        //     if (!useBeta) return state;
        //     return {
        //         ...state,
        //         protocolState: {
        //             ...state.protocolState,
        //             is_async: true,
        //             is_finished: false
        //         }
        //     };

        case acts.OFFLINE_PROTOCOL_FINISH_STATE:
            const { isFinished } = action.payload;
            return {
                ...state,
                protocolState: {
                    ...state.protocolState,
                    is_finished: isFinished
                }
            };

        // media Id helper
        case 'CREATE_MEDIA_OBJECT':
            const { params, localId = null } = action as AnyAction;
            const { med_type = '' } = params;

            if (!Boolean(localId)) {
                return state;
            }
            return {
                ...state,
                protocol: {
                    ...state.protocol,
                    ...(med_type === ELECTRICITY_PROOF_TYPE && {
                        electricity_media_id: localId
                    }),
                    ...(med_type === WATER_PROOF_TYPE && {
                        water_media_id: localId
                    }),
                    ...(med_type === GAS_PROOF_TYPE && {
                        gas_media_id: localId
                    })
                }
            };
        case 'CREATE_MEDIA_REQUEST':
            return state;
        case 'SET_MEDIA_UPLOAD_PREVIEW':
            return handleOfflinePreviewPathSave(state, action);
        case 'SET_MEDIA_UPLOAD_THUMBNAIL_PREVIEW':
            return handleOfflineThumbnailPreviewPathSave(state, action);
        case 'CREATE_MEDIA_SUCCESS':
            return handleUpdateProtocolMedia(state, action); // save media on offline protocolMedia

        case 'CREATE_MEDIA_SUCCESS':
        case 'OFFLINE_PROTOCOL_POST_SIGNATURE_SUCCESS':
            return handleUpdateMediaIdSuccessCount(state, action);
        case 'CREATE_MEDIA_FAIL':
        case 'OFFLINE_PROTOCOL_POST_SIGNATURE_FAIL':
            return handleUpdateMediaIdFail(state, action);
        case acts.OFFLINE_PROTOCOL_READY_TO_CHECKIN:
            return {
                ...state,
                protocolState: {
                    ...state.protocolState,
                    protocolReadyToCheckin: true
                }
            };
        case acts.SAVING_OFFLINE_PROTOCOL_DATA:
            const { isSaving = false } = action;
            return {
                ...state,
                protocolState: {
                    ...state.protocolState,
                    saving_protocol_data: isSaving
                }
            };
        case acts.RUN_OFFLINE_PROTOCOL_SAVE_REQUEST:
            const {
                exitPath = null,
                willExit = false,
                isAutosave = false,
                openCheckoutWarningOnFail = false,
                showBannerWhenOffline = false,
                willFinishProtocol = false,
                generatePdf
            } = action;
            return {
                ...state,
                protocolState: {
                    ...state.protocolState,
                    protocolOfflineSave: {
                        ...state.protocolState.protocolOfflineSave,
                        run: true,
                        exit: willExit,
                        isAutosave: isAutosave,
                        path: exitPath,
                        failed: false,
                        openCheckoutFail: openCheckoutWarningOnFail,
                        bannerWhenOffline: showBannerWhenOffline,
                        finishWhenReady: willFinishProtocol,
                        generatePdf
                    }
                }
            };
        case acts.RUN_OFFLINE_PROTOCOL_SAVE_FAIL:
            return {
                ...state,
                protocolState: {
                    ...state.protocolState,
                    protocolOfflineSave: {
                        ...state.protocolState.protocolOfflineSave,
                        run: false,
                        exit: false,
                        isAutosave: false,
                        path: null,
                        failed: true
                    },
                    saving_protocol_data: false
                }
            };
        case acts.RUN_OFFLINE_PROTOCOL_SAVE_SUCCESS:
            return {
                ...state,
                protocolState: {
                    ...state.protocolState,
                    is_protocol_normal: false,
                    syncingState: 'CLOSED',
                    protocolOfflineSave: {
                        ...state.protocolState.protocolOfflineSave,
                        run: false,
                        exit: false,
                        isAutosave: false,
                        path: null,
                        failed: false,
                        openCheckoutFail: false,
                        bannerWhenOffline: false
                    }
                }
            };
        case acts.OFFLINE_PROTOCOL_CLOSE_AND_FINISH:
            console.log('running OFFLINE_PROTOCOL_CLOSE_AND_FINISH');
            return {
                ...state,
                protocol: {
                    ...state.protocol,
                    prt_status: 'done',
                    current_step: 'confirm'
                },
                protocolState: {
                    ...state.protocolState,
                    is_completed: true,
                    is_finished: true,
                    syncingState: 'SUCCESS',
                    is_protocol_normal: true
                }
            };
        case acts.SET_OPEN_FINISH_BANNER:
            const { openFinishBanner = true } = action;
            console.log('running SET_OPEN_FINISH_BANNER');
            return {
                ...state,
                protocolState: {
                    ...state.protocolState,
                    openFinishBanner: openFinishBanner
                }
            };
        case acts.SET_OPEN_CHECKIN_FAIL_MODAL:
            const { shouldOpen = false } = action;
            return {
                ...state,
                protocolState: {
                    ...state.protocolState,
                    openCheckinFailModal: shouldOpen
                }
            };
        case acts.SET_CHECKIN_FAIL_CODE:
            const { errorCode = 0 } = action;
            return {
                ...state,
                protocolState: {
                    ...state.protocolState,
                    checkinFailCode: errorCode
                }
            };
        default: {
            return state;
        }
    }
}

export default offlineProtoReducer;
