import { SCROLL_END_DETECT_MARGIN } from '../configs/costants';

// Only supports vertical
export function detectScrollEnd(
    container: HTMLElement,
    scrollEndMargin = SCROLL_END_DETECT_MARGIN
) {
    const totalHeight = container.scrollHeight;
    const viewportHeight = container.clientHeight;
    const scrollTopPosition = container.scrollTop;

    return totalHeight - scrollTopPosition <= viewportHeight + scrollEndMargin;
}
