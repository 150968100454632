import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { isEmpty } from 'validator';

import { omitExtension } from '../../../modules/file';
import { validate } from '../../../utils/validation';

import useInput from '../../../hooks/useInput';
import useValidation from '../../../hooks/useValidation';

import { LinearProgress } from '@rmwc/linear-progress';

import StdIcon from '../../atoms/StdIcon';
import LinkedAttachment from '../LinkedAttachment.tsx';
import StdTextField from '../../atoms/StdTextField';

interface IProps extends IClass {
    preview: IUpload;
    onEdit?: (name: string) => void;
    onDelete?: ICallback;
}

interface IForm {
    form: {
        name: string;
    };
    errors: IErrorHash;
}

const initForm: IForm = {
    form: {
        name: ''
    },
    errors: {}
};

const EditableAttachment: React.FC<IProps> = ({
    className,
    preview,
    onEdit,
    onDelete
}) => {
    const [isEditing, setEditing] = useState(false);

    const [input, setInput] = useState(initForm);
    const handleInput = useInput(setInput);

    const name = omitExtension(preview.name);

    const validateFields = useValidation(input, setInput, (hash) => {
        const { name } = input.form;
        return [validate({ name }, !isEmpty(name), hash)].every(Boolean);
    });

    useEffect(() => {
        if (!isEditing) return;

        setInput((s) => ({
            ...s,
            form: {
                ...s.form,
                name
            }
        }));
    }, [isEditing, name]);

    const cleanup = () => {
        setEditing(false);
        setInput(initForm);
    };

    const { form, errors } = input;

    const onNameSubmit = () => {
        if (!validateFields()) return;

        onEdit?.(form.name);
        cleanup();
    };

    const onCancel = () => {
        setEditing(false);
    };

    const previewNode =
        preview.status === 'uploaded' ? (
            <LinkedAttachment
                className="inline-s"
                name={preview.name}
                path={preview.path}
            />
        ) : (
            <LinearProgress progress={preview.progress / 100} />
        );

    return (
        <div className={clsx('util__flex-align', className)}>
            {isEditing ? (
                <>
                    <StdTextField
                        className="inline-s"
                        name="name"
                        value={form.name}
                        error={errors['name']}
                        onChange={handleInput}
                        width="100%"
                        extraDense
                    />
                    <StdIcon
                        className="inline-s"
                        name="check-circle"
                        onClick={onNameSubmit}
                        clickable
                    />
                    <StdIcon name="times-circle" onClick={onCancel} clickable />
                </>
            ) : (
                <>
                    {previewNode}
                    <StdIcon
                        className="inline-s"
                        name="pencil-alt"
                        onClick={() => setEditing(true)}
                        clickable
                    />
                    <StdIcon name="trash" onClick={onDelete} clickable />
                </>
            )}
        </div>
    );
};

export default EditableAttachment;
