import React from 'react';
import clsx from 'clsx';

import { useTranslation } from 'react-i18next';
import './invoice-card.scss';

interface IProps extends IClass {
    current: number;
    included?: number;
    price?: string;
    height?: number | string;
    subtitle?: string;
}

const InvoiceCard: React.FC<IProps> = ({
    className,
    current,
    included,
    price,
    height,
    subtitle
}) => {
    const { t } = useTranslation();

    const isOverLimit = included != null && current > included;
    return (
        <div
            style={{ height }}
            className={clsx('invoice-card__container', className)}
        >
            <div className="invoice-card__title stack-m">
                <span className="inline-xs">{t('invoice.title')}</span>
                {isOverLimit && price && <span>{price}</span>}
            </div>
            <div className="invoice-card__containers-container stack-s">
                <span className="invoice-card__counter">{current}</span>
                {included != null && <span>/{included}</span>}
            </div>
            <div className="invoice-card__subtitle">
                {subtitle || t('invoice.subtitle_completed')}
            </div>
        </div>
    );
};

export default InvoiceCard;
