import { takeLatest, put } from 'redux-saga/effects';

import { AUTHORIZE_PARTNER_PROTOCOL_REQUEST } from '../../actions';
import {
    authorizePartnerProtocol,
    authorizePartnerProtocolSuccess,
    authorizePartnerProtocolFail,
    sessionStart,
    sessionEnd
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeLatest(
    AUTHORIZE_PARTNER_PROTOCOL_REQUEST,
    simpleRequest<RT<typeof authorizePartnerProtocol>>({
        success: authorizePartnerProtocolSuccess,
        failure: authorizePartnerProtocolFail,
        getUrl: (action) =>
            `auth-users/${action.protocolToken}/${action.authToken}/`,
        method: 'GET',
        withAuth: false,
        onSuccess: function* (data) {
            yield put(sessionEnd());

            const { access, refresh } = data || {};
            yield put(
                sessionStart(
                    { access, refresh },
                    { verified: true, omitUser: true }
                )
            );
            return data;
        }
    })
);
