import { useState, useEffect } from 'react';
import { load } from 'detectrtc';

// Module global to handle a terrible libary api
let isDetectRTCLoaded = false;

const useRTCDetect = () => {
    const [hasLoaded, setHasLoaded] = useState(isDetectRTCLoaded);

    useEffect(() => {
        if (isDetectRTCLoaded) return;

        load(() => {
            isDetectRTCLoaded = true;
            setHasLoaded(true);
        });
    }, []);

    return hasLoaded;
};

export default useRTCDetect;
