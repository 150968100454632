import { useCallback, useState } from 'react';

export function getLightboxUploads(uploads: IUpload[]): IUpload[] {
    return uploads.filter((upload) => upload.status === 'uploaded');
}

export const useLightbox = () => {
    const [toggle, setToggle] = useState(false);
    const [slide, setSlide] = useState<number | null>(null);

    const openLightbox = useCallback((slide: number = 0) => {
        setToggle((s) => !s);
        setSlide(slide);
    }, []);

    return {
        toggle,
        slide,
        setSlide,
        openLightbox
    };
};

export type IUseLightboxProps = RT<typeof useLightbox>;
