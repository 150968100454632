import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import StdChip from '../StdChip';
import './header-logo.scss';

interface IProps extends IClass {}

const HeaderLogo: React.FC<IProps> = ({ className }) => {
    const useBeta = useSelector(
        (state) => state.user?.user?.response?.use_beta
    );
    return (
        <div className={clsx('header-logo__container', className)}>
            <img
                className="header-logo__image"
                src="/static/images/aimmo_logo.svg"
                alt="AIMMO logo"
            />
            {useBeta && (
                <StdChip className="header-pill" type={'info'} small>
                    BETA
                </StdChip>
            )}
        </div>
    );
};

export default HeaderLogo;
