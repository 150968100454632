import { STORE_PAGE_INIT } from '../../configs/costants';
import { intOrElse, justArray } from '../../utils';

import {
    storeAdd,
    storeReplaceId,
    storeDelete,
    storeUpdate,
    storeAddAssociation,
    storePostFail,
    storeAddOrUpdate
} from './reducerModule';

import { getActionId } from '../storeModule';

import { onPaginateStart, paginateStore } from '../../modules/pagination';

import { filterDuplicates, insertMerge } from '../../modules/array';
import IssueModule from '../../modules/issue';

import * as actions from '../actions';

interface IInventoryReducer {
    property: IRequestResult<IDpd | null>;
    tenants: IPaginatedResult<ITenant>;
    rooms: IGetManyRequestResult<IRoom>;
    issueLogs: IRequestResult<IResponseAlt<ILinkLog[] | null>>;
    elements: IGetManyRequestResult<IElement>;
    roomTemplates: IGetManyRequestResult<ITemplate>;
    issues: {
        dashboard: IPaginatedResult<IIssue>;
        object: {
            objectId: number | null;
            byIds: IHash<IIssue>;
            allIds: number[];
            errors: IHash<Error>;
        };
        protocol: {
            byIds: IHash<INormalizedIssue>;
            allIds: number[];
            errors: IHash<Error>;
        };
        element: {
            byIds: IHash<INormalizedIssue>;
            allIds: number[];
            errors: IHash<Error>;
        };
        storedProto: {
            byIds: IHash<INormalizedIssue>;
            allIds: number[];
            errors: IHash<Error>;
        };
    };
}

const initNormalizedState = {
    allIds: [],
    byIds: {},
    errors: {}
};

const initIssueState: IInventoryReducer['issues'] = {
    dashboard: {
        error: null,
        response: {
            count: 0,
            next: null,
            previous: null,
            results: []
        },
        paginate: STORE_PAGE_INIT
    },
    object: {
        ...initNormalizedState,
        objectId: null
    },
    protocol: initNormalizedState,
    element: initNormalizedState,
    storedProto: initNormalizedState
};

const initState: IInventoryReducer = {
    property: {
        error: null,
        response: null
    },
    tenants: {
        error: null,
        response: {
            count: 0,
            next: null,
            previous: null,
            results: []
        },
        paginate: STORE_PAGE_INIT
    },
    rooms: {
        error: null,
        response: {
            results: []
        }
    },
    issueLogs: {
        error: null,
        response: {
            result: {
                data: null
            }
        }
    },
    elements: {
        error: null,
        response: {
            results: []
        }
    },
    roomTemplates: {
        error: null,
        response: {
            results: []
        }
    },
    issues: initIssueState
};

type IIssuerReducer = IInventoryReducer['issues'];

function handleIssueCreateRequest(state: IIssuerReducer, action: AnyAction) {
    const localId = getActionId(action);

    // NOTE: clear propties due to compatability with non protocol usages
    const {
        iss_pele_id,
        iss_rom_id,
        iss_pitm_id,
        media_med_path,
        ...payloadIssue
    } = action.payload;

    const issue = {
        ...payloadIssue,
        iss_id: localId,
        uploads: media_med_path || []
    };

    return {
        ...state,
        protocol: {
            ...state.protocol,
            ...storeAdd(state.protocol, localId, issue)
        }
    };
}

function handleIssueEditRequest(state: IIssuerReducer, action: AnyAction) {
    // NOTE: clear propties due to compatability with non protocol use cases
    // TODO: normalize issue fully
    const { iss_ele_id, iss_rom_id, iss_itm_id, media_med_path, ...issue } =
        action.payload;

    const updatedIssue = {
        ...issue,
        uploads: media_med_path || []
    };
    return {
        ...state,
        protocol: {
            ...state.protocol,
            ...storeUpdate(state.protocol, action.issueId, updatedIssue)
        }
    };
}

// sub reducer
function issueReducer(
    state: IIssuerReducer,
    action: AnyAction
): IIssuerReducer {
    switch (action.type) {
        case actions.GET_ALL_ISSUE_REQUEST:
            return {
                ...state,
                dashboard: onPaginateStart(state.dashboard, action.paginated)
            };
        case actions.GET_ALL_ISSUE_SUCCESS: {
            const response = action.payload;
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    ...paginateStore(state.dashboard, response)
                }
            };
        }
        case actions.GET_ALL_ISSUE_FAIL:
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    error: action.error
                }
            };
        case actions.GET_PROTOCOL_ISSUES_SUCCESS: {
            const { store } = action.payload;
            return {
                ...state,
                protocol: {
                    ...state.protocol,
                    ...store
                }
            };
        }

        case actions.GET_ELEMENT_ISSUES_SUCCESS: {
            const { store } = action.payload;

            return {
                ...state,
                element: {
                    ...state.element,
                    ...store
                }
            };
        }
        case actions.GET_STORED_PROTO_ISSUES_SUCCESS: {
            const { store } = action.payload;
            return {
                ...state,
                storedProto: {
                    ...state.storedProto,
                    ...store
                }
            };
        }
        case actions.POST_ISSUE_REQUEST: {
            return handleIssueCreateRequest(state, action);
        }
        case actions.POST_PROTOCOL_ISSUE_FAIL:
        case actions.POST_ISSUE_FAIL: {
            return {
                ...state,
                protocol: {
                    ...state.protocol,
                    ...storePostFail(state.protocol, action)
                }
            };
        }
        case actions.PATCH_ISSUE_REQUEST: {
            return handleIssueEditRequest(state, action);
        }
        case actions.DELETE_ISSUE_REQUEST: {
            return {
                ...state,
                protocol: {
                    ...state.protocol,
                    ...storeDelete(state.protocol, action.issueId)
                }
            };
        }
        case actions.CREATE_ISSUE_LINK_SUCCESS: {
            const { issue } = action.payload;

            const updatedFields = {
                sharing_link_public: issue.sharing_link_public,
                sharing_link_private: issue.sharing_link_private
            };

            return {
                ...state,
                object: {
                    ...state.object,
                    ...storeUpdate(state.object, issue.iss_id, updatedFields)
                }
            };
        }
        case actions.FINISH_PROTOCOL_REQUEST: {
            const ordinaryIssueState = state.protocol;
            return {
                ...state,
                storedProto: ordinaryIssueState
            };
        }
        case actions.PATCH_MEDIA_SUCCESS: {
            const mediaId = action.payload.med_id;
            const issueId = action.payload.med_iss_id;

            if (mediaId == null || issueId == null) {
                return state;
            }

            return {
                ...state,
                object: {
                    ...state.object,
                    ...storeAddAssociation(
                        state.object,
                        issueId,
                        'uploads',
                        mediaId
                    )
                }
            };
        }
        case actions.PREDICT_ISSUE: {
            const items = state.dashboard.response.results;
            const results = insertMerge({
                items,
                newItem: action.payload,
                id: { key: 'iss_id', value: action.issueId }
            });
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    response: {
                        ...state.dashboard.response,
                        results
                    }
                }
            };
        }
    }
    return state;
}

// sub-case reducer
function countersOnIssueCreate(state: IInventoryReducer, action: AnyAction) {
    const { issue } = action.payload;

    const issuePropertyId = issue.iss_dpd_id?.dpd_id;
    const property = state.property.response;

    const defaultState = {
        updatedIssues: state.issues.object,
        updatedProperty: state.property
    };

    if (property == null || issuePropertyId == null) return defaultState;

    // Will not add the issue if the loaded object doesn't match
    if (property.dpd_id === issuePropertyId) {
        const isNormal = IssueModule.isNormal(issue);

        const openCounter = intOrElse(property.open_issue_count, 0) + 1;
        const lightCounter =
            intOrElse(property.light_issue_count, 0) + Number(isNormal);
        const excessiveCounter =
            intOrElse(property.excessive_issue_count, 0) + Number(!isNormal);

        const updatedProperty = {
            ...property,
            open_issue_count: openCounter,
            light_issue_count: lightCounter,
            excessive_issue_count: excessiveCounter
        };

        return {
            updatedIssues: {
                ...state.issues.object,
                ...storeAdd(state.issues.object, issue.iss_id, issue)
            },
            updatedProperty: {
                ...state.property,
                response: updatedProperty
            }
        };
    }

    return defaultState;
}

// case reducer
function handleIssueCreate(state: IInventoryReducer, action: AnyAction) {
    const { issue } = action.payload;

    const realId = issue.iss_id;
    if (realId == null) return state;

    const localId = getActionId(action);
    const inventoryUpdate = countersOnIssueCreate(state, action);

    return {
        ...state,
        issues: {
            ...state.issues,
            protocol: {
                ...state.issues.protocol,
                ...storeReplaceId(state.issues.protocol, localId, realId, issue)
            },
            // Note: edge case
            storedProto: {
                ...state.issues.storedProto,
                ...storeReplaceId(
                    state.issues.storedProto,
                    localId,
                    realId,
                    issue
                )
            },
            object: inventoryUpdate.updatedIssues
        },
        property: inventoryUpdate.updatedProperty
    };
}

// sub-case reducer
function countersOnIssueUpdate(state: IInventoryReducer, action: AnyAction) {
    const { issue } = action.payload;

    const previousIssue = state.issues.object.byIds[issue.iss_id];
    const property = state.property.response;

    if (previousIssue && property) {
        const prevState = IssueModule.isOpen(previousIssue);
        const nextState = IssueModule.isOpen(issue);

        if (prevState !== nextState) {
            const updatedProperty = {
                ...property,
                open_issue_count: intOrElse(property.open_issue_count, 0) - 1,
                closed_issue_count:
                    intOrElse(property.closed_issue_count, 0) + 1
            };

            return {
                ...state.property,
                response: updatedProperty
            };
        }
    }
    return state.property;
}

// Sub-case reducer
// Note: workaround to protocol issues not syncing properly
function storedOnIssueUpdate(state: IInventoryReducer, action: AnyAction) {
    const { issue } = action.payload;

    const matchingIssue = state.issues.storedProto.byIds[issue.iss_id];
    if (matchingIssue != null && !matchingIssue.is_protocol) {
        return {
            ...state.issues.storedProto,
            ...storeUpdate(state.issues.storedProto, issue.iss_id, issue)
        };
    }

    return state.issues.storedProto;
}

// case reducer
function handleIssueUpdate(state: IInventoryReducer, action: AnyAction) {
    const { issue } = action.payload;

    return {
        ...state,
        property: countersOnIssueUpdate(state, action),
        issues: {
            ...state.issues,
            protocol: {
                ...state.issues.protocol,
                ...storeUpdate(state.issues.protocol, issue.iss_id, issue)
            },
            object: {
                ...state.issues.object,
                ...storeUpdate(state.issues.object, issue.iss_id, issue)
            },
            storedProto: storedOnIssueUpdate(state, action)
        }
    };
}

// case reducer
function handleIssueDelete(state: IInventoryReducer, action: AnyAction) {
    const id = action.entityId;

    if (id == null) return state;

    const issue = state.issues.object.byIds[id];
    const property = state.property.response;

    const issueState = {
        ...state.issues,
        object: {
            ...state.issues.object,
            ...storeDelete(state.issues.object, id)
        }
    };

    if (issue && property) {
        const isOpen = IssueModule.isOpen(issue);
        const isNormal = IssueModule.isNormal(issue);

        const openCounter =
            intOrElse(property.open_issue_count, 0) - Number(isOpen);
        const closedCounter =
            intOrElse(property.closed_issue_count, 0) - Number(!isOpen);
        const lightCounter =
            intOrElse(property.light_issue_count, 0) - Number(isNormal);
        const excessiveCounter =
            intOrElse(property.excessive_issue_count, 0) - Number(!isNormal);

        const updatedProperty = {
            ...property,
            open_issue_count: openCounter,
            closed_issue_count: closedCounter,
            light_issue_count: lightCounter,
            excessive_issue_count: excessiveCounter
        };
        return {
            ...state,
            issues: issueState,
            property: {
                ...state.property,
                response: updatedProperty
            }
        };
    }

    return {
        ...state,
        issues: issueState
    };
}

function handleObjectIssuesFetch(state: IInventoryReducer, action: AnyAction) {
    const { store } = action.payload;

    const requestedId = action.originator?.dpdId;

    return {
        ...state,
        issues: {
            ...state.issues,
            object: {
                ...state.issues.object,
                ...store,
                objectId: requestedId
            }
        }
    };
}

function handleIssueFetch(state: IInventoryReducer, action: AnyAction) {
    const { issue } = action.payload;

    const propertyId = issue?.iss_dpd_id?.dpd_id;
    const currentPropertyId = state.property.response?.dpd_id;

    const protocolState = {
        ...state.issues.protocol,
        ...storeAddOrUpdate(state.issues.protocol, issue.iss_id, issue)
    };

    // Will not update the issue if the loaded object doesn't match
    if (
        propertyId != null &&
        currentPropertyId != null &&
        currentPropertyId === propertyId
    ) {
        return {
            ...state,
            issues: {
                ...state.issues,
                object: {
                    ...state.issues.object,
                    ...storeAddOrUpdate(
                        state.issues.object,
                        issue.iss_id,
                        issue
                    )
                },
                protocol: protocolState
            }
        };
    }
    return {
        ...state,
        issues: {
            ...state.issues,
            protocol: protocolState
        }
    };
}

// ---------

function inventoryReducer(
    state = initState,
    action: AnyAction
): IInventoryReducer {
    switch (action.type) {
        // -- Issue actions requiring other entities in scope
        // Some of these work both for protocol context and object page context
        case actions.GET_ISSUES_SUCCESS: {
            return handleObjectIssuesFetch(state, action);
        }
        case actions.GET_ISSUE_SUCCESS: {
            return handleIssueFetch(state, action);
        }
        case 'POST_ISSUE_RESPONSE': {
            return handleIssueCreate(state, action);
        }
        case 'PATCH_ISSUE_RESPONSE': {
            return handleIssueUpdate(state, action);
        }
        case actions.DELETE_ISSUE_SUCCESS: {
            return handleIssueDelete(state, action);
        }
        // ---

        // Property start
        case actions.GET_ONE_DPD_REQUEST:
            return {
                ...state,
                property: {
                    ...state.property,
                    error: null
                }
            };
        case actions.GET_ONE_DPD_SUCCESS:
            return {
                ...state,
                property: {
                    ...state.property,
                    response: action.payload.property
                }
            };
        case actions.GET_ONE_DPD_FAIL:
            return {
                ...state,
                property: {
                    ...state.property,
                    error: action.error
                }
            };
        // Property end
        // Tenants start
        case actions.GET_TENANT_REQUEST: {
            return {
                ...state,
                tenants: onPaginateStart(state.tenants, action.paginated)
            };
        }
        case actions.GET_TENANT_SUCCESS: {
            const { response } = action.payload;

            return {
                ...state,
                tenants: paginateStore(state.tenants, response)
            };
        }
        case actions.GET_TENANT_FAIL: {
            return {
                ...state,
                tenants: {
                    ...state.tenants,
                    error: action.error
                }
            };
        }
        // Tenants end
        // Rooms start
        case actions.GET_ROOM_SUCCESS:
            const { response } = action.payload;
            return {
                ...state,
                rooms: {
                    ...state.rooms,
                    response
                }
            };
        case actions.GET_ROOM_FAIL:
            return {
                ...state,
                rooms: {
                    ...state.rooms,
                    error: action.error
                }
            };
        case actions.PREDICT_ROOM: {
            const items = state.rooms.response.results;
            const results = insertMerge({
                items,
                newItem: action.payload,
                id: { key: 'rom_id', value: action.roomId }
            });
            return {
                ...state,
                rooms: {
                    ...state.rooms,
                    response: {
                        ...state.rooms.response,
                        results
                    }
                }
            };
        }
        case actions.PREDICT_ROOMS:
            return {
                ...state,
                rooms: {
                    ...state.rooms,
                    response: {
                        ...state.rooms.response,
                        results: action.rooms
                    }
                }
            };
        // ROOMS end
        // Issue logs start
        case actions.GET_ISSUE_LOGS_SUCCESS:
            return {
                ...state,
                issueLogs: {
                    ...state.issueLogs,
                    response: action.payload
                }
            };
        case actions.GET_ISSUE_LOGS_FAIL:
            return {
                ...state,
                issueLogs: {
                    ...state.issueLogs,
                    error: action.error
                }
            };
        // Issue logs end
        // Elements start
        case actions.GET_ELEMENT_SUCCESS: {
            const { response } = action.payload;
            return {
                ...state,
                elements: {
                    ...state.elements,
                    response
                }
            };
        }
        case actions.GET_ELEMENT_FAIL:
            return {
                ...state,
                elements: {
                    ...state.elements,
                    error: action.error
                }
            };
        case actions.POST_ISSUE_SUCCESS: {
            // TODO: refactor after state normalization
            // Keep in mind the difference of _SUCCESS vs _RESPONSE

            const issue: IIssue = action.payload;
            if (!issue) return state;

            const rooms = state.rooms.response.results;
            const updatedRooms =
                issue.iss_rom_id && IssueModule.isExcessive(issue)
                    ? insertMerge({
                          items: rooms,
                          newItem: {
                              rom_has_excessive_issue: true
                          },
                          id: {
                              key: 'rom_id',
                              value: Number(issue.iss_rom_id.rom_id)
                          }
                      })
                    : rooms;

            const elements = state.elements.response.results;
            const updatedElements = issue?.iss_ele_id?.ele_id
                ? insertMerge({
                      items: elements,
                      merger: (old) => ({
                          ...old,
                          ele_iss: justArray(old.ele_iss).concat(issue)
                      }),
                      id: {
                          key: 'ele_id',
                          value: Number(issue?.iss_ele_id?.ele_id)
                      }
                  })
                : elements;

            return {
                ...state,
                elements: {
                    ...state.elements,
                    response: {
                        ...state.elements.response,
                        results: updatedElements
                    }
                },
                rooms: {
                    ...state.rooms,
                    response: {
                        ...state.rooms.response,
                        results: updatedRooms
                    }
                }
            };
        }
        case actions.POST_PROTOCOL_ISSUE_SUCCESS: {

            const issue: IIssue = action.payload;
            if (!issue) return state;

            const rooms = state.rooms.response.results;
            const updatedRooms =
                issue.iss_rom_id && IssueModule.isExcessive(issue)
                    ? insertMerge({
                          items: rooms,
                          newItem: {
                              rom_has_excessive_issue: true
                          },
                          id: {
                              key: 'rom_id',
                              value: Number(issue.iss_rom_id.rom_id)
                          }
                      })
                    : rooms;

            const elements = state.elements.response.results;
            const updatedElements = issue.iss_ele_id
                ? insertMerge({
                      items: elements,
                      merger: (old) => ({
                          ...old,
                          ele_iss: justArray(old.ele_iss).concat(issue)
                      }),
                      id: {
                          key: 'ele_id',
                          value: Number(issue.iss_ele_id.ele_id)
                      }
                  })
                : elements;

            return {
                ...state,
                elements: {
                    ...state.elements,
                    response: {
                        ...state.elements.response,
                        results: updatedElements
                    }
                },
                rooms: {
                    ...state.rooms,
                    response: {
                        ...state.rooms.response,
                        results: updatedRooms
                    }
                }
            };
        }
        case actions.PREDICT_ELEMENTS:
            const { elements } = action;
            return {
                ...state,
                elements: {
                    ...state.elements,
                    response: {
                        ...state.elements.response,
                        results: elements
                    }
                }
            };

        case actions.GET_TEMPLATE_SUCCESS:
            return {
                ...state,
                roomTemplates: {
                    ...state.roomTemplates,
                    response: action.payload
                }
            };
        case actions.GET_TEMPLATE_FAIL:
            return {
                ...state,
                roomTemplates: {
                    ...state.roomTemplates,
                    error: action.error
                }
            };
        // Templates end
    }

    const issueReducerState = issueReducer(state.issues, action);
    if (state.issues !== issueReducerState) {
        return {
            ...state,
            issues: issueReducerState
        };
    }

    return state;
}

export default inventoryReducer;
