import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { showSnackbar } from '../store/action-creators';

import { copyToClip } from '../utils/clipboard';

const useCopyLink = () => {
    const dispatch = useDispatch();

    const handleCopy = useCallback(
        (link: string) => {
            copyToClip(
                link,
                () =>
                    dispatch(
                        showSnackbar({
                            message: 'copy_link_success_message',
                            snackType: 'info'
                        })
                    ),
                () =>
                    dispatch(
                        showSnackbar({ message: 'copy_link_fail_messagen' })
                    )
            );
        },
        [dispatch]
    );

    return handleCopy;
};

export default useCopyLink;
