import { SagaIterator } from '@redux-saga/types';
import { put, select } from 'redux-saga/effects';
import moment from 'moment';

import { deleteLocalMedia } from '../store/action-creators';

import { getExtension, pathToName } from './file';
import { justArray } from '../utils';

import env from '../configs/environment';

const THUMBOR_DIMENTIONS_REGEX = /\/thumb\/\d+x\d+\//;

// Used for thumbor urls for images
export function setImageDimentions(url: string, width: number, height: number) {
    return url?.replace(THUMBOR_DIMENTIONS_REGEX, `/thumb/${width}x${height}/`);
}

export function setBetaImageDimentions(
    url: string,
    width: number,
    height: number
) {
    return `/thumb/${width}x${height}/${url}`;
}

export const previewsToIds = (previews: IUpload[]) => {
    return previews.map((preview) => preview.id);
};

export function isUploadViewable({
    preview,
    isProtocolOffline = false
}: {
    preview: IUpload;
    isProtocolOffline: boolean;
}) {
    return (
        preview.status === 'uploaded' ||
        (preview.status === 'in-progress' &&
            preview.isViewable &&
            isProtocolOffline)
    );
}

function isUploadExpired(timestamp: number) {
    return (
        moment().unix() > timestamp + moment.duration(24, 'hour').asSeconds()
    );
}

function getImageUrl(
    width: number,
    height: number,
    filename: string | None
): string {
    return filename
        ? `${env.getProperty(
              'imagesHost'
          )}/thumb/${width}x${height}/${filename}`
        : '';
}

function getVideoUrl(
    filename: string | None,
    placeholder: string = '/static/images/image-placeholder.png'
) {
    return filename
        ? `${env.getProperty('imagesHost')}/video/${filename}`
        : placeholder;
}

function getAttachUrl(filename: string) {
    return `/pdf/${filename}`;
}

type ICustomUploadParams = Pick<IUpload, 'useType' | 'path' | 'thumbnail'>;

const defaultParams = {
    thumbWidth: 96,
    thumbHeight: 96
};

export interface IVideoParams {
    width?: number;
    height?: number;
}

// Note: Cannot have full size thumbnail & smaller version at the same time (due to only one thumbnail field)
export function videoToUpload(
    media: IMedia,
    params?: IVideoParams
): ICustomUploadParams {
    const { width = 0, height = 0 } = params || {};
    const { med_path, med_thumbnail } = media;

    const videoThumb = med_thumbnail
        ? getImageUrl(width, height, med_thumbnail)
        : undefined;

    return {
        path: getVideoUrl(med_path),
        useType: 'video',
        thumbnail: videoThumb
    };
}

export interface IImageParams {
    width?: number;
    height?: number;
    thumbWidth?: number;
    thumbHeight?: number;
}

function imageToUpload(
    media: IMedia,
    params: IImageParams = defaultParams
): ICustomUploadParams {
    const { width = 0, height = 0, thumbWidth, thumbHeight } = params;
    const { med_path } = media;

    const imageThumb =
        thumbWidth != null && thumbHeight != null
            ? getImageUrl(thumbWidth, thumbHeight, med_path)
            : undefined;

    return {
        useType: 'image',
        path: getImageUrl(width, height, med_path),
        thumbnail: imageThumb
    };
}

interface ICommonMedia
    extends Pick<
        IUpload,
        'id' | 'media' | 'type' | 'name' | 'hasAssociations'
    > {
    status: 'uploaded';
}

function mediaToCommon(media: IMedia): ICommonMedia {
    const { med_id, med_path, med_type, pk = 0 } = media;
    return {
        media,
        status: 'uploaded',
        id: med_id || pk,
        type: med_type,
        name: pathToName(med_path),
        hasAssociations: true
    };
}

const videoExtensions = 'mov|mp4|flv|avi|webm|ogg';
const imageExtensions = 'jpg|jpeg|png|gif|svg';

function isSupportedExtension(path: string, extensions: string) {
    const fileExtension = getExtension(path);
    return extensions
        .split('|')
        .some((extension) => extension === fileExtension);
}

export function mediaToUpload(media: IMedia): IUpload {
    const common = mediaToCommon(media);

    const isVideo = isSupportedExtension(media.med_path, videoExtensions);
    const isImage = isSupportedExtension(media.med_path, imageExtensions);

    if (isVideo) {
        const videoUpload = videoToUpload(media);

        return {
            ...common,
            ...videoUpload
        };
    } else if (isImage) {
        const imageUpload = imageToUpload(media);

        return {
            ...common,
            ...imageUpload
        };
    }

    // Is file, i.e pdf, xls etc
    return {
        ...common,
        useType: 'file',
        path: getAttachUrl(media.med_path)
    };
}

export function mediasToUploads(medias: IMedia[]): IUpload[] {
    return medias.map((media) => mediaToUpload(media));
}

interface IKeyToUploadsConfig {
    many: boolean;
    oldKey: string;
    newKey: string;
}

const keyToUploadDefaults: IKeyToUploadsConfig = {
    many: true,
    oldKey: 'media_med_path',
    newKey: 'uploads'
};

export function keyToUploads(
    json: any,
    userConfig?: Partial<IKeyToUploadsConfig>
) {
    const config = {
        ...keyToUploadDefaults,
        ...userConfig
    };
    const { oldKey, newKey } = config;
    const media = json[oldKey];
    if (!config.many && media != null && typeof media === 'object') {
        return {
            ...json,
            [oldKey]: undefined,
            [newKey]: [mediaToUpload(media)]
        };
    }

    return {
        ...json,
        [oldKey]: undefined,
        [newKey]: mediasToUploads(justArray(media))
    };
}

export function* removeExpiredUploads(): SagaIterator {
    const uploads: INormalizedState<IUpload> = yield select(
        (state: IRootState) => state.uploads.uploads
    );

    const deletions = Object.keys(uploads.byIds)
        .map((key) => uploads.byIds[key])
        .filter((upload) => {
            if (
                upload &&
                upload.status === 'in-progress' &&
                upload.timestamp != null
            ) {
                return isUploadExpired(upload.timestamp);
            }
            return false;
        });

    for (const upload of deletions) {
        yield put(deleteLocalMedia(upload.id));
    }
}
