import { takeLeading } from 'redux-saga/effects';

import { POST_PRIVATE_LOG_REQUEST } from '../../actions';
import {
    postPrivateLogSuccess,
    postPrivateLogFail,
    postPrivateLog
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeLeading(
    POST_PRIVATE_LOG_REQUEST,
    simpleRequest<ReturnType<typeof postPrivateLog>>({
        success: postPrivateLogSuccess,
        failure: postPrivateLogFail,
        getUrl: (_) => `sharinglinklog/create_log/`,
        getBody: (action) => action.payload,
        method: 'POST'
    })
);
