import { IStorageEngine, storageManager } from './storage';

interface IAvailableSettings {
    attendee_address_hint_dismissed: boolean;
    protocol_section_open: boolean;
    issue_section_open: boolean;
    ad_section_open: boolean;
    property_section_open: boolean;
}

type ILocalSettings = IAvailableSettings | null;

class LocalSettingsManager {
    private engine: IStorageEngine;
    private label: string;

    private defaultSettings: IAvailableSettings = {
        attendee_address_hint_dismissed: false,
        protocol_section_open: false,
        issue_section_open: false,
        ad_section_open: false,
        property_section_open: true
    };

    constructor(engine: IStorageEngine, label = 'settings') {
        this.engine = engine;
        this.label = label;
    }

    private getSettings(): IAvailableSettings {
        const settings = this.engine.get(this.label);
        if (settings == null || typeof settings != 'object')
            return this.defaultSettings;

        return settings as IAvailableSettings;
    }

    get(key: keyof IAvailableSettings): IAvailableSettings[typeof key] {
        const settings = this.getSettings();
        return (settings as any)[key];
    }

    set(key: keyof IAvailableSettings, value: IAvailableSettings[typeof key]) {
        const settings = this.getSettings();

        const updatedSettings: ILocalSettings = {
            ...settings,
            [key]: value
        };

        this.engine.set(this.label, updatedSettings);
    }
}

export const settingsManager = new LocalSettingsManager(storageManager);
