// TODO: Deprecate all usage of basicData table

export const DPD_TYPE = '102';
export const EL_ATTRIBUTE_TYPE = '107';
export const ROOM_ATTRIBUTE_TYPE = '108';
export const ISSUE_SEVERITY = '118';
export const SUBDPD_TYPE = '120';
export const ITEM_ATTR_TYPE = '122';
export const DPD_ATTR_TYPE = '123';
export const PERSONA_TYPE = '127';
export const PROTOCOL_TRACKER_STATUS = '128';
export const ATTENDEE_TYPES = '129';