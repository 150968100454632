import { takeEvery } from 'redux-saga/effects';
import { VERFIY_TOKEN_REQUEST } from '../../actions';
import {
    verifyToken,
    verifyTokenSuccess,
    verifyTokenFail
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeEvery(
    VERFIY_TOKEN_REQUEST,
    simpleRequest<ReturnType<typeof verifyToken>>({
        success: verifyTokenSuccess,
        failure: verifyTokenFail,
        getUrl: () => `auth-users/jwt/verify/`,
        getBody: (action) => ({ token: action.token }),
        method: 'POST',
        withAuth: false,
        ignoreError: true
    })
);
