import React from 'react';
import clsx from 'clsx';

import { matchSimple } from '../../../utils';

import { IconName, IconPrefix } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';

import StdIcon from '../StdIcon';

import './std-button.scss';
import { useHistory } from 'react-router';

interface IProps {
    className?: string;
    to?: string;
    type?: 'primary' | 'secondary' | 'danger' | 'round' | 'dark' | 'black';
    size?: 'small' | 'regular' | 'medium' | 'large';
    outline?: 'white';
    onClick?: IClickHandler;
    children?: React.ReactNode;
    width?: string;
    disabled?: boolean;
    externalUrl?: boolean;
    openTab?: boolean;
    leadingIcon?: {
        name: IconName;
        prefix?: IconPrefix;
    };
}

const StdButton: React.FC<IProps> = (props, ref) => {
    const history = useHistory();
    const {
        type = 'secondary',
        className = '',
        width = 'auto',
        to,
        disabled,
        size,
        leadingIcon,
        children,
        externalUrl,
        openTab,
        outline,
        onClick,
        ...other
    } = props;

    const buttonColor = matchSimple<string | null>()
        .on(type === 'primary', 'std-button--primary')
        .on(type === 'danger', 'std-button--danger')
        .on(type === 'round', 'std-button--round')
        .on(type === 'dark', 'std-button--dark')
        .on(type === 'black', 'std-button--black')
        .otherwise(null);

    const buttonSize = matchSimple<string | null>()
        .on(size === 'small', 'std-button--small')
        .on(size === 'medium', 'std-button--medium')
        .on(size === 'large', 'std-button--large')
        .otherwise(null);

    const buttonOutline = matchSimple<string | null>()
        .on(outline === 'white', 'std-button--white-outline')
        .otherwise(null);

    const btnClasses = clsx(
        'std-button',
        buttonColor,
        buttonSize,
        buttonOutline,
        disabled && 'std-button--disabled',
        className
    );

    const { name = 'arrow-left', prefix = 'fal' } = leadingIcon || {};
    const iconNode = leadingIcon && (
        <StdIcon
            className={clsx(children && 'inline-s')}
            name={name}
            prefix={prefix}
            small
        />
    );

    // Make sure to always wrap text in <span> to all to adjust vertical-align
    const shouldWrap =
        typeof children === 'string' ||
        (Array.isArray(children) && typeof children[0] === 'string');

    const childNode = shouldWrap ? <span>{children}</span> : children;

    return to ? (
        <Link
            {...other}
            ref={ref}
            to={externalUrl ? { pathname: to } : to}
            className={clsx(
                'std-blank-link',
                btnClasses,
                'std-button--tag-fallback'
            )}
            style={{ width }}
            target={openTab ? '_blank' : undefined}
        >
            {iconNode}
            {childNode}
        </Link>
    ) : (
        <button
            {...other}
            ref={ref}
            className={btnClasses}
            onClick={onClick}
            style={{ width }}
            disabled={disabled}
        >
            {iconNode}
            {childNode}
        </button>
    );
};

export default React.forwardRef(StdButton);
