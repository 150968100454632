import React from 'react';
import clsx from 'clsx';

import { Trans, useTranslation } from 'react-i18next';
import { parseFeature } from '../../../modules/plans';

import './extra-feature.scss';

interface IProps {
    feature: IFeature;
    currency: string;
    inline?: boolean;
}

const ExtraFeature: React.FC<IProps> = ({
    feature: planFeature,
    currency,
    inline
}) => {
    const { t } = useTranslation();
    const { feature } = planFeature;
    const { unitTitle, priceTitle } = parseFeature(planFeature, currency);

    const featureName = t(`plan.backend.feature.extra.${feature}`, {
        unit: unitTitle
    });
    const price = t(`plan.backend.feature.pricing.${feature}`, {
        price: priceTitle
    });
    return (
        <div
            className={clsx(
                'extra-feature__container',
                inline && 'extra-feature__container--inline'
            )}
        >
            <div className={inline ? 'inline-xs' : 'stack-xs'}>
                <Trans t={t} components={{ bold: <strong /> }}>
                    {featureName}
                </Trans>
            </div>
            <div className="extra-feature__highlight">{price}</div>
        </div>
    );
};

export default ExtraFeature;
