import { SetStateAction, Dispatch, useCallback } from 'react';

export interface IValidationState {
    //form: object;
    errors: IErrorHash;
}

type IValidationCallback = (hash: IErrorHash) => boolean;

const useValidation = <T extends IValidationState>(
    state: T,
    setState: Dispatch<SetStateAction<T>>,
    callback: IValidationCallback
) => {
    return useCallback(() => {
        const hash = { ...state.errors };
        const valid = callback(hash);

        setState({
            ...state,
            errors: hash
        });
        return valid;
    }, [state, setState, callback]);
};

export default useValidation;
