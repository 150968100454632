import React, { memo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import useMatchMedia from '../../../hooks/useMatchMedia';

import HeaderLogo from '../../atoms/HeaderLogo';
import UserActions from '../../profile/UserActions';
import SearchInput from '../../molecules/SearchInput';
import MobileSearchInput from '../../molecules/MobileSearchInput';

interface IProps {
    value: string;
    onChange: IInputEventHandler;
}

const DashboardHeader: React.FC<IProps> = ({ value, onChange }) => {
    const [collapsed, setCollapsed] = useState(true);

    const { t } = useTranslation();
    const isPhone = useMatchMedia('mobile');

    return (
        <div className="header">
            {collapsed && <HeaderLogo className="inline-s" />}
            <div className="dashboard__search inline-s">
                {isPhone ? (
                    <MobileSearchInput
                        collapsed={collapsed}
                        value={value}
                        onChange={onChange}
                        toggleCollapsed={() => setCollapsed((s) => !s)}
                        placeholder={t('dasboard_search_label')}
                    />
                ) : (
                    <SearchInput
                        placeholder={t('dasboard_search_label')}
                        value={value}
                        onChange={onChange}
                        width="100%"
                    />
                )}
            </div>
            <UserActions />
        </div>
    );
};

export default memo(
    DashboardHeader,
    (prevProps, nextProps) => prevProps.value === nextProps.value
);
