import React from 'react';
import clsx from 'clsx';
import { IconPrefix, IconName } from '@fortawesome/fontawesome-common-types';

import StdIcon from '../StdIcon';

interface IProps extends IClass {
    iconClass?: string;
    iconName?: IconName;
    iconPrefix?: IconPrefix;
    onClick?: ICallback;
    trailing?: boolean;
}

const IconBullet: React.FC<IProps> = ({
    className,
    iconName = 'arrow-left',
    iconPrefix = 'fal',
    iconClass,
    trailing,
    children,
    onClick
}) => {
    const iconNode = (
        <StdIcon
            className={clsx(!trailing && 'inline-s')}
            name={iconName}
            prefix={iconPrefix}
            small
        />
    );

    return (
        <div className={clsx('std-icon-bullet', className)} onClick={onClick}>
            {!trailing && iconNode}
            <span
                className={clsx(
                    'std-icon-bullet__text',
                    trailing && 'inline-s',
                    iconClass
                )}
            >
                {children}
            </span>
            {trailing && iconNode}
        </div>
    );
};

export default IconBullet;
