import { takeLatest } from 'redux-saga/effects';

import { POST_OFFLINE_PROTOCOL_REQUEST } from '../../actions';
import {
    syncOfflineProtocol,
    syncOfflineProtocolSuccess,
    syncOfflineProtocolFail
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeLatest(
    POST_OFFLINE_PROTOCOL_REQUEST,
    simpleRequest<RT<typeof syncOfflineProtocol>>({
        success: syncOfflineProtocolSuccess,
        failure: syncOfflineProtocolFail,
        getUrl: (action) => `protocol/${action.prt_id}/sync/`,
        getBody: (action) => action.payload,
        method: 'POST'
    })
);
