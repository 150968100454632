import React from 'react';
import clsx from 'clsx';
import { IconName } from '@fortawesome/fontawesome-svg-core';

import { getExtension } from '../../../modules/file';
import { matchSimple } from '../../../utils';
import StdIcon from '../StdIcon';

import './std-attachment.scss';

function extensionToIcon(extension: string): IconName {
    const iconName: IconName = matchSimple()
        .on(extension === 'pdf', 'file-pdf')
        .on(extension === 'doc' || extension === 'docx', 'file-word')
        .on(extension === 'xls' || extension === 'xlsx', 'file-excel')
        .on(
            extension === 'png' || extension === 'jpg' || extension === 'jpeg',
            'image'
        )
        .otherwise('file-alt');

    return iconName;
}

interface IProps extends IClass {
    filename: string;
    width?: string;
    borderless?: boolean;
}

const StdAttachment: React.FC<IProps> = ({
    className,
    filename,
    width,
    borderless
}) => {
    const extension = getExtension(filename);
    const iconName = extensionToIcon(extension);

    const attachmentClass = clsx(
        'std-attachment',
        borderless && 'std-attachment--borderless',
        className
    );

    return (
        <div style={{ width }} className={attachmentClass}>
            <StdIcon className="inline-m" name={iconName} />
            <span>{filename}</span>
        </div>
    );
};

export default StdAttachment;
