import React, { useState } from 'react';
import clsx from 'clsx';
import { motion, AnimatePresence } from 'framer-motion';
import { ANIMATE_HEIGHT_PROPS } from '../../../configs/costants';
import StdIcon from '../StdIcon';

import './expandable-list.scss';

interface IProps extends IClass {
    width?: string;
    first?: boolean;
    last?: boolean;
    small?: boolean;
    isEmpty?: boolean;
    title?: React.ReactNode;
    children?: React.ReactNode;
}

const ExpandableList: React.FC<IProps> = ({
    className,
    width,
    first,
    last,
    title,
    small,
    isEmpty,
    children
}) => {
    const [open, setOpen] = useState(false);
    const isCenter = first && last;

    const itemClass = clsx(
        'expandable-list__container',
        open && 'expandable-list__container--active',
        first && 'expandable-list__container--top',
        last && 'expandable-list__container--bottom',
        isCenter && 'expandable-list__container--center',
        className
    );

    const handleToggle = () => {
        setOpen((s) => !s);
    };

    return (
        <div className={itemClass} style={{ width }}>
            <div
                className={clsx(
                    'expandable-list__title',
                    small && 'expandable-list__title--small'
                )}
                onClick={!isEmpty ? handleToggle : undefined}
            >
                {title}
                <StdIcon
                    className={clsx(isEmpty && 'util__hidden')}
                    name={open ? 'chevron-down' : 'chevron-right'}
                />
            </div>
            <AnimatePresence initial={false}>
                {open && (
                    <motion.div {...ANIMATE_HEIGHT_PROPS}>
                        {children}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default ExpandableList;
