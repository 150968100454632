import { takeEvery } from 'redux-saga/effects';

import { COPY_ROOM_REQUEST } from '../../actions';
import { copyRoom, copyRoomSuccess, copyRoomFail } from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeEvery(
    COPY_ROOM_REQUEST,
    simpleRequest<RT<typeof copyRoom>>({
        success: copyRoomSuccess,
        failure: copyRoomFail,
        getUrl: (action) => `room/${action.roomId}/copy/`
    })
);
