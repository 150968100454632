import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import StdModal from '../../atoms/StdModal';
import StdButton from '../../atoms/StdButton';
import TrialSubscribeFlow from '../TrialSubscribeFlow';

import './trial-cancelled-modal.scss';

interface IProps {
    open: boolean;
}

const TrailCancelledModal: React.FC<IProps> = ({ open }) => {
    const [selectOpen, setSelectOpen] = useState(false);
    const { t } = useTranslation();
    return (
        <StdModal open={open} onClose={() => {}} white large>
            <div className="trial-cancelled-modal">
                <div className="font__header stack-l">{t('cancel_title')}</div>
                <div className="stack-xl">{t('cancel_info')}</div>
                <StdButton type="primary" onClick={() => setSelectOpen(true)}>
                    {t('buy')}
                </StdButton>
            </div>
            <TrialSubscribeFlow
                open={selectOpen}
                onClose={() => setSelectOpen(false)}
            />
        </StdModal>
    );
};

export default TrailCancelledModal;
