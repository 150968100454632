import { all } from 'redux-saga/effects';
import getProtocols from './getProtocols';
import getProtocol from './getProtocol';
import postProtocol from './postProtocol';
import patchProtocol from './patchProtocol';
import delProtocol from './delProtocol';
import protoFinsih from './protoFinish';
import postEle from './postEle';
import patchEle from './patchEle';
import delEle from './delEle';
import getRooms from './getRooms';
import postRoom from './postRoom';
import patchRoom from './patchRoom';
import delRoom from './delRoom';
import eleAttrCrud from './eleAttrCrud';
import getItems from './getItems';
import postItem from './postItem';
import patchItem from './patchItem';
import delItem from './delItem';
import itemAttributeCrud from './itemAttrCrud';
import getAttendees from './getAttendees';
import postAttendee from './postAttendee';
import patchAttendee from './patchAttendee';
import delAttendee from './delAttendee';
import postSignature from './postSignature';
import delSignature from './delSignature';
import checkPdf from './checkPdf';
import previewPdf from './previewPdf';
import generatePdf from './generatePdf';
import getItemTemplates from './getItemTemplates';
import authPartner from './authPartner';
import patchPartnerProtocol from './patchPartnerProtocol';
import getElement from './getElement';
import getRoom from './getRoom';
import getItem from './getItem';
import getAttendee from './getAttendee';
import postProtocolIssue from './postProtocolIssue';
import patchProtocolIssue from './patchProtocolIssue';
import deleteProtocolIssue from './deleteProtocolIssue';
import saveProtocolNotes from './saveProtocolNotes'
import protocolTrackerViewPdf from './protocolTrackerViewPdf';
import resendAttendeeEmail from './resendAttendeeEmail';
import getProtocolForLatestData from './getProtocolForLatestData';

export default all([
    getProtocols,
    getProtocol,
    getProtocolForLatestData,
    postProtocol,
    postEle,
    patchEle,
    delEle,
    getRooms,
    postRoom,
    patchRoom,
    delRoom,
    eleAttrCrud,
    getItems,
    postItem,
    patchItem,
    delItem,
    itemAttributeCrud,
    getAttendees,
    postAttendee,
    patchAttendee,
    delAttendee,
    patchProtocol,
    delProtocol,
    postSignature,
    delSignature,
    protoFinsih,
    checkPdf,
    previewPdf,
    generatePdf,
    getItemTemplates,
    authPartner,
    patchPartnerProtocol,
    getElement,
    getRoom,
    getItem,
    getAttendee,
    postProtocolIssue,
    patchProtocolIssue,
    deleteProtocolIssue,
    saveProtocolNotes,
    protocolTrackerViewPdf,
    resendAttendeeEmail
]);
