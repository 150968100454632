import { put, takeEvery, all } from 'redux-saga/effects';

import {
    PATCH_PROTOCOL_ROOM_REQUEST,
    PATCH_PROTOCOL_ROOM_FAIL,
    PATCH_PROTOCOL_ROOM_SUCCESS
} from '../../actions';
import {
    protoPatchRoom,
    protoPatchRoomSuccess,
    protoPatchRoomFail,
    getProtocolRoom
} from '../../action-creators';

import { simpleRequest } from '../modules/request';
import { normalizeProtocolRoom } from '../modules/normalization';
import { responseHelper } from '../modules/promise';
import i18n from '../../../i18next';

type IPatchRoomAction = RT<typeof protoPatchRoom>;
type IPatchRoomFailAction = RT<typeof protoPatchRoomFail>;

function onSuccessCallback(data: any) {
    return normalizeProtocolRoom(data);
}

const requestEffect = simpleRequest<IPatchRoomAction>({
    success: protoPatchRoomSuccess,
    failure: protoPatchRoomFail,
    getUrl: (action) => `protocol_room/${action.id}/?lang=${action.lang}`,
    getBody: (action) => action.payload,
    getEntityId: (action) => action.id,
    method: 'PATCH',
    enableOffline: true
});

const responseEffect = responseHelper({
    actionName: PATCH_PROTOCOL_ROOM_SUCCESS,
    processFn: onSuccessCallback
});

export default all([
    takeEvery(PATCH_PROTOCOL_ROOM_REQUEST, requestEffect),
    takeEvery(
        PATCH_PROTOCOL_ROOM_FAIL,
        function* (action: IPatchRoomFailAction) {
            if (action.itemId == null) return;
            yield put(getProtocolRoom(action.itemId));
        }
    ),
    responseEffect
]);
