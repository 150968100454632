import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { DPD_TYPE } from '../../../configs/basicData';
import { propertyToIcon } from '../../../modules/property';
import { IDpdData } from '../../../utils/componentInterfaces';

import { isHouseType } from '../../../configs/costants';
import useMatchMedia from '../../../hooks/useMatchMedia';

import StdIcon from '../../atoms/StdIcon';
import StdMedia from '../../atoms/StdMedia';

import './dpd-card.scss';

interface IProps {
    className?: string;
    dpdInfo: IDpdData;
    link?: string;
}

const DpdCard: React.FC<IProps> = (props) => {
    const { link, className = '', dpdInfo } = props;
    const {
        type,
        street,
        streetNum,
        zip,
        city,
        media,
        roomNum = 0,
        objNum = 0,
        issNum = 0,
        newIssNum = 0
    } = dpdInfo;
    const typeChar = type || '';

    const { t } = useTranslation();
    const lookupHash = useSelector((state) => state.basicData.response);
    const isPhone = useMatchMedia('mobile');

    const isHouse = isHouseType(typeChar);

    const dpdType = lookupHash?.[DPD_TYPE]?.[typeChar] || t('dpd');

    const imgComp = (
        <StdMedia
            icon={propertyToIcon(typeChar)}
            media={media}
            width={343}
            height={160}
            alt="property image"
        />
    );

    const address = (
        <>
            <div className="font__header stack-s">
                {street} {streetNum}
            </div>
            <div className="font--secondary stack-m">
                {zip} {city}
            </div>
        </>
    );

    return (
        <div className={clsx('dpd-card', className)}>
            <div
                className={clsx(
                    'dpd-card__block',
                    isPhone ? 'stack-m' : 'inline-m'
                )}
            >
                {link ? (
                    <Link className="std-blank-link" to={link}>
                        {imgComp}
                    </Link>
                ) : (
                    imgComp
                )}
            </div>
            <div className="dpd-card__info">
                {link ? (
                    <Link className="std-blank-link" to={link}>
                        {address}
                    </Link>
                ) : (
                    address
                )}
                <div className="font--secondary stack-s">
                    <div>
                        <StdIcon
                            className="inline-s"
                            name={propertyToIcon(typeChar)}
                        />
                        <span className="inline-xs">{dpdType}</span>
                        <span className="inline-xs">·</span>
                        <span>
                            {link ? (
                                isHouse ? (
                                    <Link
                                        className="std-blank-link"
                                        to={`${link}/?tab=rooms`}
                                    >
                                        <u className="font--secondary">
                                            {roomNum} {t('apartment_rooms')}
                                        </u>
                                    </Link>
                                ) : (
                                    <Link className="std-blank-link" to={link}>
                                        <u className="font--secondary">
                                            {objNum} {t('objects')}
                                        </u>
                                    </Link>
                                )
                            ) : isHouse ? (
                                <span>
                                    {roomNum} {t('apartment_rooms')}
                                </span>
                            ) : (
                                <span>
                                    {objNum} {t('objects')}
                                </span>
                            )}
                        </span>
                    </div>
                </div>
                <div className="font--secondary">
                    <StdIcon
                        className="font--secondary inline-s"
                        name="exclamation-circle"
                    />
                    {isHouse && link ? (
                        <Link
                            className="std-blank-link"
                            to={`${link}/?tab=reporting`}
                        >
                            <span className="inline-xs">
                                <u className="font--secondary">
                                    {issNum} {issNum > 1 ? t('issues') : t('issue')}
                                </u>
                            </span>
                        </Link>
                    ) : (
                        <span
                            className={clsx(Boolean(newIssNum) && 'inline-xs')}
                        >
                            {issNum} {issNum > 1 ? t('issues') : t('issue')}
                        </span>
                    )}
                    {Boolean(newIssNum) && (
                        <>
                            <span className="inline-xs">·</span>
                            <span className="color-danger">
                                {newIssNum} {t('new')}
                            </span>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DpdCard;
