import { takeEvery } from 'redux-saga/effects';

import { GET_PROTOCOL_ELEMENT_REQUEST } from '../../actions';
import {
    getProtocolElement,
    getProtocolElementSuccess,
    getProtocolElementFail
} from '../../action-creators';

import { simpleRequest, ISuccessCallback } from '../modules/request';
import { normalizeProtocolElement } from '../modules/normalization';

type IGetProtocolElementAction = RT<typeof getProtocolElement>;

const onSuccess: ISuccessCallback<IGetProtocolElementAction> = function (data) {
    return normalizeProtocolElement(data);
};

export default takeEvery(
    GET_PROTOCOL_ELEMENT_REQUEST,
    simpleRequest<IGetProtocolElementAction>({
        success: getProtocolElementSuccess,
        failure: getProtocolElementFail,
        getUrl: (action) => `protocol_element/${action.elementId}/`,
        onSuccess
    })
);
