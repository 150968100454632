import React from 'react';
import { useTranslation } from 'react-i18next';
import IconBullet from '../../atoms/IconBullet';
import StdButton from '../../atoms/StdButton';

import './sharing-intro.scss';

interface IProps {
    onBack?: ICallback;
    onNext?: ICallback;
}

const IssueSharingIntroPage: React.FC<IProps> = ({ onBack, onNext }) => {
    const { t } = useTranslation();

    return (
        <div className="sharing-intro__page">
            <div className="sharing-intro__header">
                <IconBullet className="inline-m" onClick={onBack}>
                    {t('back')}
                </IconBullet>
                <div />
                <IconBullet className="util__hidden">{t('back')}</IconBullet>
            </div>
            <div className="sharing-intro__content">
                <div className="sharing-intro__title stack-xl">
                    {t('issue_sharing.intro_title')}
                </div>
                <div className="stack-xl">
                    <img
                        className="sharing-intro__illustration"
                        src="/static/images/issue_sharing_feature_illustration.png"
                        alt=""
                    />
                </div>
                <div className="stack-xl">{t('issue_sharing.intro_text')}</div>
                <div>
                    <div className="stack-m">
                        <StdButton
                            type="primary"
                            to="https://aimmo.io/schaden-reparaturen/"
                            externalUrl
                            openTab
                        >
                            {t('issue_sharing.explore_feature')}
                        </StdButton>
                    </div>
                    <div>
                        <StdButton onClick={onNext}>
                            {t('issue_sharing.see_pricing')}
                        </StdButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IssueSharingIntroPage;
