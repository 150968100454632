import { takeLatest } from 'redux-saga/effects';

import { POST_PROTOCOL_REQUEST } from '../../actions';
import {
    postProtocol,
    postProtocolSuccess,
    postProtocolFail
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeLatest(
    POST_PROTOCOL_REQUEST,
    simpleRequest<RT<typeof postProtocol>>({
        success: postProtocolSuccess,
        failure: postProtocolFail,
        getUrl: (_) => 'protocol/',
        getBody: (action) => action.protocol,
        method: 'POST'
    })
);
