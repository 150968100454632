import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty, isEmail } from 'validator';

import { validate } from '../../../utils/validation';
import { PHONE_TYPE_REGEX } from '../../../configs/costants';

import useInput from '../../../hooks/useInput';
import useValidation from '../../../hooks/useValidation';
import StdModal from '../../atoms/StdModal';
import StdTextField from '../../atoms/StdTextField';

import './edit-manager-modal.scss';
import ModalHeader from '../../atoms/ModalHeader';
import StdButton from '../../atoms/StdButton';
import clsx from 'clsx';
import CustomIcon from '../../atoms/CustomIcon';

export interface IModalInput {
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
}

interface IForm {
    form: IModalInput;
    errors: IErrorHash;
}

const initForm: IForm = {
    form: {
        first_name: '',
        last_name: '',
        email: '',
        phone: ''
    },
    errors: {}
};

interface IProps extends IModalBase {
    item?: Partial<IModalInput>;
    onSubmit: (form: IModalInput) => void;
    isUserEnterpriseManager?: boolean;
}

const EditManagerModal: React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    const { open, item, onSubmit, onClose, isUserEnterpriseManager } = props;

    const [input, setInput] = useState(initForm);
    const handleInput = useInput(setInput, (name, value, newState) => {
        // TODO: make input restrictions easier to use
        if (name === 'phone' && !PHONE_TYPE_REGEX.test(value)) return input;
        return newState;
    });

    const validateFields = useValidation(input, setInput, (hash) => {
        const { first_name, last_name, email, phone } = input.form;

        return [
            validate({ first_name }, !isEmpty(first_name), hash),
            validate({ last_name }, !isEmpty(last_name), hash),
            validate({ email }, isEmail(email), hash),
            validate({ phone }, !isEmpty(phone), hash)
        ].every(Boolean);
    });

    useEffect(() => {
        if (open && item) {
            setInput((s) => ({
                ...s,
                ...item
            }));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const submit = () => {
        if (validateFields()) {
            onSubmit(input.form);
            onClose();
        }
    };

    const cleanup = () => {
        setInput(initForm);
    };

    const { form, errors } = input;
    return (
        <StdModal open={open} onClose={onClose} onExited={cleanup} contentClassName="edit-manager-modal">
            <ModalHeader
                leftNode={
                    <span className="std-space-nowrap">
                        {isUserEnterpriseManager ? t('team.add_team_member') : t('team.add_manager')}
                    </span>
                }
                rightNode={<CustomIcon name="cross" onClick={onClose} ripple />}
            />
            <div className="modal__section">
                <div className="modal__heading stack-m">
                    {isUserEnterpriseManager ? t('team.team_member_details').toUpperCase() : t('team.manager_details').toUpperCase()}
                </div>
                <StdTextField
                    className="stack-m"
                    label={t('team.first_name')}
                    value={form.first_name}
                    error={errors['first_name']}
                    name="first_name"
                    onChange={handleInput}
                    width="100%"
                    required
                />
                <StdTextField
                    className="stack-m"
                    label={t('team.last_name')}
                    value={form.last_name}
                    error={errors['last_name']}
                    name="last_name"
                    onChange={handleInput}
                    width="100%"
                    required
                />
                <StdTextField
                    className="stack-m"
                    label={t('email')}
                    value={form.email}
                    error={errors['email']}
                    name="email"
                    onChange={handleInput}
                    width="100%"
                    required
                />
                <StdTextField
                    className="stack-l"
                    label={t('phone')}
                    value={form.phone}
                    error={errors['phone']}
                    name="phone"
                    onChange={handleInput}
                    width="100%"
                    required
                />
                <div
                    className={clsx('std-flex-between edit-manager__btn-save')}
                >
                    <StdButton width={'120px'} type="primary" onClick={submit}>
                        {t('save')}
                    </StdButton>
                </div>
            </div>
        </StdModal>
    );
};

export default EditManagerModal;
