import { takeEvery } from 'redux-saga/effects';

import { CHANGE_SUBSCRIPTION_REQUEST } from '../../actions';
import {
    changeSubscription,
    changeSubscriptionSuccess,
    changeSubscriptionFail
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeEvery(
    CHANGE_SUBSCRIPTION_REQUEST,
    simpleRequest<ReturnType<typeof changeSubscription>>({
        success: changeSubscriptionSuccess,
        failure: changeSubscriptionFail,
        getUrl: (_) => 'subscriptions/',
        getBody: (action) => action.payload,
        method: 'POST',
        isV2Api: true
    })
);
