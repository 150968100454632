import React, { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import {
    ANIMATE_HEIGHT_PROPS,
    TRIAL_MESSAGE_DURATION
} from '../../../configs/costants';
import useTrialInfo from '../../../hooks/useTrialInfo';

import TrialNote from '../../trial/TrialNote';
import TrialExpiredModal from '../../trial/TrialExpiredModal';
import TrialSubscribeFlow from '../../trial/TrialSubscribeFlow';
import TrailCancelledModal from '../../trial/TrialCancelledModal';

import useToggleTimeout from '../../../hooks/useToggleTimeout';

const TrialInfo: React.FC = () => {
    const [planSelectOpen, setPlanSelectOpen] = useState(false);
    const { daysLeft, isPartner, isCancelled, isExpired, inTrial } =
        useTrialInfo();

    const showTrialMessage =
        !isPartner && !isCancelled && !isExpired && inTrial;
    const showExpiredOverlay = !isPartner && !isCancelled && isExpired;

    const showTrial = useToggleTimeout(
        showTrialMessage,
        TRIAL_MESSAGE_DURATION
    );

    return (
        <>
            <AnimatePresence>
                {showTrial && daysLeft != null && (
                    <motion.div {...ANIMATE_HEIGHT_PROPS}>
                        <TrialNote
                            daysLeft={daysLeft}
                            warn={Boolean(daysLeft <= 3)}
                            onUpgrade={() => setPlanSelectOpen(true)}
                        />
                    </motion.div>
                )}
            </AnimatePresence>
            <TrialSubscribeFlow
                open={planSelectOpen}
                onClose={() => setPlanSelectOpen(false)}
            />
            <TrialExpiredModal open={showExpiredOverlay} />
            <TrailCancelledModal open={isCancelled} />
        </>
    );
};

export default TrialInfo;
