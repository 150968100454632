import { takeEvery } from 'redux-saga/effects';

import { POST_ROOM_REQUEST } from '../../actions';
import { postRoomSuccess, postRoomFail, postRoom } from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeEvery(
    POST_ROOM_REQUEST,
    simpleRequest<RT<typeof postRoom>>({
        success: postRoomSuccess,
        failure: postRoomFail,
        getUrl: (_) => 'room/room_create/',
        method: 'POST',
        getBody: (action) => action.room
    })
);
