import { ISSUE_SEVERITY } from '../configs/basicData';
import { ISSUE_TYPE_EXCESSIVE, ISSUE_TYPE_NORMAL } from '../configs/costants';
import { omitDecimal } from '../utils';

type IPartialIssue = Partial<IIssue>;

export function isNormal(issue: IPartialIssue) {
    return (
        issue.grade === ISSUE_TYPE_NORMAL ||
        issue?.iss_grade === ISSUE_TYPE_NORMAL
    );
}

export function isExcessive(issue: IPartialIssue) {
    return (
        issue?.grade === ISSUE_TYPE_EXCESSIVE ||
        issue?.iss_grade === ISSUE_TYPE_EXCESSIVE
    );
}

export function isOpen(issue: IPartialIssue) {
    const pissIssue =
        typeof issue.fix === 'boolean' ? !issue.fix : !issue.iss_fix;
    return pissIssue;
}

export function isClosed(issue: IPartialIssue) {
    return Boolean(issue.fix || issue.iss_fix);
}

export function isLandLordIssue(issue: IPartialIssue) {
    return Boolean(issue.cost_bearer === 'OWNER');
}
export function isTenantIssue(issue: IPartialIssue) {
    return Boolean(issue.cost_bearer === 'TENANT');
}

export function isOpenCostIssue(issue: IPartialIssue) {
    return Boolean(issue.cost_bearer === 'OPEN');
}

export function hasCosts(issue: IPartialIssue) {
    return (
        isExcessive(issue) &&
        (isTenantIssue(issue) &&
        (issue.agreedshare != null ||
            issue.fixcost != null ||
            issue.iss_agreedshare != null ||
            issue.iss_fixcost != null
        ) || Boolean(issue.cost_bearer === 'OPEN' || (issue.cost_bearer !== 'OWNER' && issue.fixcost === null && issue.agreedshare === null)))
    );
}

export function displayCosts(issue: IPartialIssue, currency: "CHF" | "EUR" = "CHF", t: any) {
    // TODO: validate with Number()
    if (issue.agreedshare) {
        const share = omitDecimal(issue.agreedshare.toString());
        return `${share}%`;
    }

    if (issue.fixcost) {
        const fixCost = omitDecimal(issue.fixcost.toString());
        return `${fixCost}${currency}`;
    }

    if (Boolean(issue.cost_bearer === 'OPEN' || (issue.agreedshare === null && issue.fixcost === null))) {
        return t('open_share_cost');
    }

}

export function displayGrade(
    issue: IPartialIssue,
    hash: ILookupHash | null
): string {
    if (!hash || !hash[ISSUE_SEVERITY]) return '--';

    const grade = issue.grade || issue.iss_grade || ISSUE_TYPE_NORMAL;
    return hash[ISSUE_SEVERITY][grade] || '--';
}

const IssueModule = {
    isNormal,
    isExcessive,
    isOpen,
    isClosed,
    isLandLordIssue,
    isTenantIssue,
    isOpenCostIssue,
    hasCosts,
    displayCosts,
    displayGrade
};

export default IssueModule;
