import { SagaIterator } from '@redux-saga/types';
import {
    all,
    fork,
    takeLatest,
    call,
    put,
    take,
    delay
} from 'redux-saga/effects';

import appEffects from './app';
import dpdEffects from './dpd';
import issueEffects from './issue';
import attrEffects from './elAttributes';
import eleEffects from './element';
import roomEffects from './room';
import tenantEffects from './tenant';
import protocolEffects from './protocol';
import offlineProtocolEffects from './offlineProtocol'
import teamEffects from './team';
import globalEffects from './global';
import adminEffects from './admin/getToken';
import enterpriseAdminEffects from './admin/enterpriseGetToken';

import publicUserEffects from './user/index.public';
import privateUserEffects from './user/index';

import getDataEffect from './app/getBasicData';
import getSharedIss from './issue/getShared';
import postIssLogPublic from './issue/logPublic';

import { tokenManager } from '../../modules/tokens';

import { mediaWatcher } from './modules/media';
import { removeExpiredUploads } from '../../modules/upload';

import { isServiceWorkerRunning } from '../../utils';
import { BASIC_DATA_RETRY_DELAY } from '../../configs/costants';

import {
    getBasicData,
    getVersion,
    sessionStart,
    sessionEnd,
    refreshAccess,
    getCurrentAcc,
    setCoreLoading,
    verifyToken,
    getPackages,
    getUsageLogs,
    setAccountLoading
} from '../action-creators';

import * as acts from '../actions';

import { queue } from '../../components/misc/GlobalSnackbar/queue'; // NOTE: A workaround
import i18n from '../../i18next';

// NOTE: code is imperative due to the issues of JavaScript with recursion
const lang = i18n.language;

function* retryBasicData() {
    let resultAction: AnyAction;
    do {
        yield delay(BASIC_DATA_RETRY_DELAY);
        yield put(getBasicData(lang as ILang));

        resultAction = yield take([
            acts.GET_BASIC_DATA_SUCCESS,
            acts.GET_BASIC_DATA_FAIL
        ]);
    } while (resultAction.type === acts.GET_BASIC_DATA_FAIL);
}

// Throws if fails to refresh the access token
function* refreshToken(): SagaIterator {
    yield put(refreshAccess());
    const action = yield take([
        acts.REFRESH_ACCESS_SUCCESS,
        acts.REFRESH_ACCESS_FAIL
    ]);
    if (action.type === acts.REFRESH_ACCESS_FAIL) {
        yield put(sessionEnd());
        throw Error();
    }
}

export function* onPageEnter(): SagaIterator {
    yield put(setCoreLoading(true));
    yield fork(removeExpiredUploads);

    yield put(getBasicData(lang as ILang));
    const dataAction: AnyAction = yield take([
        acts.GET_BASIC_DATA_SUCCESS,
        acts.GET_BASIC_DATA_FAIL
    ]);

    if (dataAction.type === acts.GET_BASIC_DATA_FAIL) {
        yield fork(retryBasicData);
    }

    yield put(getVersion());
    yield take([acts.GET_VERSION_SUCCESS, acts.GET_VERSION_FAIL]);

    yield put(sessionStart(tokenManager.get()));
    yield put(setCoreLoading(false));
}

export function* onSessionStart(action: RT<typeof sessionStart>): SagaIterator {
    // Verification start
    const { access, refresh } = action.tokens;
    const { verified, omitUser } = action.config || {};

    try {
        tokenManager.set(access || '', refresh || '');

        queue.clearAll();

        if (!omitUser) yield put(setAccountLoading(true));
        if (!access && !refresh) throw Error();

        const onlyRefresh = refresh && !access;
        if (onlyRefresh) {
            yield call(refreshToken);
        }

        // NOTE: disabled token validation to enable offline functionality
        if (!isServiceWorkerRunning() && !verified && !onlyRefresh) {
            yield put(verifyToken(access || ''));
            const action = yield take([
                acts.VERIFY_TOKEN_SUCCESS,
                acts.VERIFY_TOKEN_FAIL
            ]);

            if (action.type === acts.VERIFY_TOKEN_FAIL) {
                yield call(refreshToken);
            }
        }

        // Core requests start
        if (!omitUser) {
            yield put(getCurrentAcc());
            yield take([
                acts.GET_CURRENT_ACC_SUCCESS,
                acts.GET_CURRENT_ACC_FAIL
            ]);
        }

        yield put(getPackages());
        yield put(getUsageLogs(true));
    } catch (e) {
        //using errors for control flow
    } finally {
        if (!omitUser) yield put(setAccountLoading(false));
    }
}

export default function* rootSaga() {
    yield all([
        publicUserEffects,
        getDataEffect,
        getSharedIss,
        postIssLogPublic,
        appEffects,
        dpdEffects,
        issueEffects,
        roomEffects,
        eleEffects,
        attrEffects,
        tenantEffects,
        protocolEffects,
        offlineProtocolEffects,
        privateUserEffects,
        teamEffects,
        globalEffects,
        adminEffects,
        enterpriseAdminEffects,
        takeLatest(acts.SESSION_START, onSessionStart),
        fork(onPageEnter),
        fork(mediaWatcher)
    ]);
}
