import React from 'react';
import CustomIcon from '../CustomIcon';
import ModalHeader from '../ModalHeader';

interface IProps {
    title: string;
    onClose: ICallback;
    width?: string;
    className?: string;
}

const ObjectEditModal: React.FC<IProps> = ({
    title,
    onClose,
    className,
    width
}) => {
    return (
        <ModalHeader
            className={`modal-header__Content modal-header-centerNode ${className}`}
            rightNode={<CustomIcon name="cross" onClick={onClose} ripple />}
            leftNode={title}
            width={width}
        />
    );
};

export default ObjectEditModal;
