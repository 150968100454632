import * as actions from '../actions/tenant';
import { createAction, createCustomAction } from 'typesafe-actions';
import { Assign } from 'utility-types';
import { actionFail, actionPayload } from '../storeModule';

export const getTenant = createCustomAction(
    actions.GET_TENANT_REQUEST,
    (type) => {
        return (
            dpdId: number,
            params: IPaginationParams = {},
            paginated?: IPaginateDirection
        ) => ({ type, dpdId, params, paginated });
    }
);

export const getTenantSuccess = createCustomAction(
    actions.GET_TENANT_SUCCESS,
    actionPayload
);
export const getTenantFail = createCustomAction(
    actions.GET_TENANT_FAIL,
    actionFail
);

type IModTenant = Assign<
    Omit<ITenant, 'id' | 'uploads'>,
    {
        ten_email?: string;
        ten_firstname?: string;
        ten_lastname?: string;
        ten_phone?: string;
        ten_picture?: number;
    }
>;

export const postTenant = createCustomAction(
    actions.POST_TENANT_REQUEST,
    (type) => {
        return (tenant: Assign<IModTenant, { dpd: number }>) => ({
            type,
            tenant
        });
    }
);
export const postTenantSuccess = createAction(actions.POST_TENANT_SUCCESS);
export const postTenantFail = createCustomAction(
    actions.POST_TENANT_FAIL,
    actionFail
);

export const patchTenant = createCustomAction(
    actions.PATCH_TENANT_REQUEST,
    (type) => {
        return (tenId: number, tenant: IModTenant) => ({ type, tenId, tenant });
    }
);
export const patchTenantSuccess = createCustomAction(
    actions.PATCH_TENANT_SUCCESS
);
export const patchTenantFail = createCustomAction(
    actions.PATCH_TENANT_FAIL,
    actionFail
);

export const deleteTenant = createCustomAction(
    actions.DELETE_TENANT_REQUEST,
    (type) => {
        return (tenantId: number) => ({ type, tenantId });
    }
);
export const deleteTenantSuccess = createAction(actions.DELETE_TENANT_SUCCESS);
export const deleteTenantFail = createCustomAction(
    actions.DELETE_TENANT_FAIL,
    actionFail
);
