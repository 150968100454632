import React, { Fragment } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { IconPrefix, IconName } from '@fortawesome/fontawesome-common-types';
import { isMobileSafari } from 'react-device-detect';
import { useSelector } from 'react-redux';

import { isUploadViewable } from '../../../modules/upload';
import { IUploaderResult, IUploadConfig } from '../../../hooks/useUploader';

import StdButton from '../../atoms/StdButton';
import StdImage from '../../atoms/StdImage';
import StdLightbox, {
    useLightbox,
    getLightboxUploads
} from '../../atoms/StdLightbox';
import StdFilepicker from '../../atoms/StdFilepicker';
import StdMedia from '../../atoms/StdMedia';
import VerticalProgress from '../../atoms/VerticalProgress';
import Loading from '../../atoms/Loading';

import './std-gallery.scss';
import StdIcon from '../../atoms/StdIcon';

type IUploaderFns = Pick<IUploaderResult, 'uploadFiles' | 'removeUpload'>;

interface IProps extends IClass, IUploaderFns {
    previews: IUpload[];
    alt?: string;
    iconPrefix?: IconPrefix;
    icon?: IconName;
    noTitle?: boolean;
    dark?: boolean;
    centered?: boolean;
    disabledWebcam?: boolean;
    large?: boolean;
    inline?: boolean;
    disabled?: boolean;
    title?: string;
    uploadConfig?: IUploadConfig;
    noMargin?: boolean;
    isPropertyGallery?: boolean;
    isMedia?: boolean;
    backgroundColor?: string;
    isTitleUpperCase?: boolean;
    isUploading: boolean;
}

const StdGallery: React.FC<IProps> = (props) => {
    const {
        previews,
        className,
        alt,
        icon = 'building',
        iconPrefix = 'fal',
        noTitle,
        title,
        dark,
        centered,
        large,
        noMargin,
        inline,
        disabled,
        isTitleUpperCase,
        disabledWebcam,
        isPropertyGallery,
        uploadFiles,
        removeUpload,
        backgroundColor,
        uploadConfig = {},
        isMedia,
        isUploading
    } = props;

    const { t } = useTranslation();
    const { openLightbox, ...lightboxProps } = useLightbox();

    const isOffline = useSelector(
        (state) => state.global.isOffline || state.appState.isManualOffline
    );
    const useBeta = useSelector((state) => state.user.user.response?.use_beta);
    // const isAsync = useSelector(
    //     (state) => state.offlineProtocol.protocolState.is_async
    // );
    const lightboxUploads = getLightboxUploads(previews);
    const lightboxOffest = previews.length - lightboxUploads.length;

    const config = {
        ...uploadConfig,
        totalLimit:
            uploadConfig.totalLimit != null ? uploadConfig.totalLimit : 1,
        allowedFiletypes:
            uploadConfig.allowedFiletypes != null
                ? uploadConfig.allowedFiletypes
                : 'image/*'
    };

    const isUnderLimit = previews.length < config.totalLimit;

    const galleryEmptyClass = clsx(
        'gallery__media',
        'gallery__media--empty',
        dark && 'gallery__media--dark',
        large && 'gallery__media--large',
        'stack-s'
    );

    const galleryContentsClass = clsx(
        'gallery__container',
        large && 'gallery__container--large',
        centered && 'gallery__container--centered'
    );

    const onLightboxOpen = (index: number) => {
        if (lightboxUploads.length) {
            openLightbox(index - lightboxOffest);
        }
    };

    const text = isMedia ? 'media' : 'photo';

    return (
        <Fragment>
            <div
                className={clsx(
                    'gallery',
                    inline && 'gallery--inline',
                    disabled && 'gallery--disabled',
                    className
                )}
            >
                {!noTitle && (
                    <div
                        className={`gallery__title stack-m ${
                            isTitleUpperCase ? 'boldTitleText' : null
                        }`}
                    >
                        {isTitleUpperCase
                            ? t('photo').toUpperCase()
                            : t('photo')}
                    </div>
                )}
                <div className={galleryContentsClass}>
                    {isUnderLimit && (
                        <div
                            className={`gallery__item ${
                                noMargin ? 'gallery_items_margin' : ''
                            }`}
                        >
                            <StdFilepicker
                                backgroundColor={backgroundColor}
                                className="gallery__button"
                                title={title || t(text)}
                                accept={config.allowedFiletypes}
                                onFileUpload={(files) =>
                                    uploadFiles(files, config)
                                }
                                enableWebcam={!disabledWebcam}
                            />
                        </div>
                    )}
                    {previews.map((preview, index) => {
                        const isViewable = isUploadViewable({
                            preview,
                            isProtocolOffline: Boolean(isOffline || useBeta)
                        });
                        return (
                            <div key={index} className="gallery__item">
                                <div
                                    className={clsx(
                                        'gallery__media stack-s',
                                        large && 'gallery__media--large'
                                    )}
                                    onClick={() => onLightboxOpen(index)}
                                >
                                    {isViewable && (
                                        <StdMedia
                                            media={preview}
                                            icon={icon}
                                            iconPrefix={iconPrefix}
                                            width={96}
                                            height={96}
                                            alt={alt}
                                            checkExif={!isMobileSafari}
                                        />
                                    )}
                                    {isUploading && (
                                        <Loading
                                            className="loading__element"
                                            size="large"
                                        />
                                    )}
                                    {preview.status === 'in-progress' &&
                                        !isUploading && (
                                            <VerticalProgress
                                                progress={preview.progress}
                                                offline={isOffline}
                                                omitContainer
                                            />
                                        )}
                                </div>
                                {isViewable && (
                                    <div
                                        className="gallery__action"
                                        onClick={() => removeUpload(preview.id)}
                                    >
                                        <StdIcon
                                            className="gallery__trash-icon"
                                            name="trash-alt"
                                            prefix="fas"
                                        />
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
            <StdLightbox
                {...lightboxProps}
                sources={lightboxUploads.map((prev) => prev.path)}
                types={lightboxUploads.map((prev) => prev.useType)}
            />
        </Fragment>
    );
};

export default StdGallery;
