import { eventChannel, END, SagaIterator, buffers } from 'redux-saga';
import { take, put } from 'redux-saga/effects';

import { setScreenSize } from '../../action-creators';
import {
    isMobileQueryStr,
    isTabletQueryStr,
    isDeskopQueryStr
} from '../../../utils';

export function* mediaWatcher(): SagaIterator {
    const queryChannel = eventChannel<IScreenSizeType>((emit) => {
        const queryListenerCreator = (size: IScreenSizeType) => {
            return (matcher: MediaQueryListEvent) => {
                if (matcher.matches) emit(size);
            };
        };

        const isMobileQuery = matchMedia(isMobileQueryStr);
        if (isMobileQuery.matches) emit('mobile');

        const isTabletQuery = matchMedia(isTabletQueryStr);
        if (isTabletQuery.matches) emit('tablet');

        const isDesktopQuery = matchMedia(isDeskopQueryStr);
        if (isDesktopQuery.matches) emit('desktop');

        const mobileListener = queryListenerCreator('mobile');
        isMobileQuery.addEventListener('change', mobileListener);

        const tabletListner = queryListenerCreator('tablet');
        isTabletQuery.addEventListener('change', tabletListner);

        const desktopListener = queryListenerCreator('desktop');
        isDesktopQuery.addEventListener('change', desktopListener);

        return () => {
            isMobileQuery.removeEventListener('change', mobileListener);
            isTabletQuery.removeEventListener('change', tabletListner);
            isDesktopQuery.removeEventListener('change', desktopListener);
        };
    }, buffers.dropping(1));

    while (true) {
        const message = yield take(queryChannel);

        if (message !== END) {
            yield put(setScreenSize(message));
        } else {
            break;
        }
    }
}
