import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
    getInvitationsSet,
    getEnterpriseManagerSet,
    getManagerSet,
    createInvitationSet,
    resendInvitationSet,
    deleteInvitationSet,
    deleteManagerSet,
    showSnackbar
} from '../../../store/action-creators';

import { dispatchAsync } from '../../../store/storeModule';
import { getFullname } from '../../../modules/account';

import useToggle from '../../../hooks/useToggle';

import StdDivider from '../../atoms/StdDivider';
import ManagerCard from '../ManagerCard';
import StdButton from '../../atoms/StdButton';
import EditManagerModal, { IEditManagerInput } from '../EditManagerModal';

import './profile-team-section.scss';
import i18n from '../../../i18next';

function validateInvitationStatus(status: 'PENDING' | 'EXPIRED') {
    const isViewableStatus = status === 'PENDING' || status === 'EXPIRED';
    const lowercaseStatus = status?.toLowerCase() as 'pending' | 'expired';

    return isViewableStatus ? lowercaseStatus : undefined;
}

interface ICommonProps {
    user: IUser;
    isEnterprise?: boolean;
    role?: string;
}

interface IProps extends ICommonProps {
    managers: ITeamMembership[];
    invitations: ITeamInvitation[];
    role?: string;
    onCreateInvitation: (payload: IEditManagerInput) => void;
    onResendInvitation: (id: number) => void;
    onDeleteInvitation: (id: number) => void;
    onDeleteManager: (id: number) => void;
}

export const ProfileTeamSection: React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    const {
        user,
        managers,
        invitations,
        onCreateInvitation,
        onResendInvitation,
        onDeleteInvitation,
        onDeleteManager,
        role,
        isEnterprise
    } = props;
    const [editedManager, onEditedManagerOpen, onEditedManagerClose] =
        useToggle<Partial<IEditManagerInput>>();

    const hasManagers = Boolean(managers.length);
    const hasInvitations = Boolean(invitations.length);

    const getMainAccountLabel = (role: string) => {
        switch (role) {
            case 'MANAGER':
                return 'manager';
            case 'ADMIN':
                return 'admin';
            default:
                return 'owner';
        }
    };

    const getSubAccountLabel = (role: string) => {
        switch (role) {
            case 'MANAGER':
                return 'team_member';
            case 'ADMIN':
                return 'manager';
            default:
                return 'manager';
        }
    }

    return (
        <div>
            <div className="profile-team-tab__main">
                <ManagerCard
                    className={clsx(
                        (hasManagers || hasInvitations) && 'stack-s'
                    )}
                    fullname={getFullname(user.firstname, user.lastname)}
                    email={user.email}
                    role={getMainAccountLabel(user.role)}
                />
                {managers.map((manager, index) => {
                    const { firstname, lastname, email } = manager.member;

                    const isLast = index === managers.length - 1;
                    return (
                        <ManagerCard
                            key={manager.id}
                            className={clsx(
                                (!isLast || hasInvitations) && 'stack-s'
                            )}
                            fullname={getFullname(firstname, lastname)}
                            onDelete={() => onDeleteManager(manager.id)}
                            role={getSubAccountLabel(user.role)}
                            email={email}
                            width="100%"
                        />
                    );
                })}
                {invitations?.map((invitation, index) => {
                    const isLast = index === invitations.length - 1;

                    const status = validateInvitationStatus(invitation?.status);
                    return (
                        <ManagerCard
                            key={invitation.id}
                            className={clsx(!isLast && 'stack-s')}
                            fullname={getFullname(
                                invitation.first_name,
                                invitation.last_name
                            )}
                            email={invitation.email}
                            onResend={() => onResendInvitation(invitation.id)}
                            onDelete={() => onDeleteInvitation(invitation.id)}
                            status={status}
                            role={getSubAccountLabel(user.role)}
                            width="100%"
                        />
                    );
                })}
            </div>
            <StdDivider />
            <div className="profile-team-tab__action">
                <StdButton
                    leadingIcon={{ name: 'plus' }}
                    onClick={() => onEditedManagerOpen()}
                >
                    {getMainAccountLabel(user.role) === 'manager' ? t('role.team_member') : t('role.manager')}
                </StdButton>
            </div>
            <EditManagerModal
                item={editedManager.item}
                open={editedManager.open}
                onClose={onEditedManagerClose}
                onSubmit={onCreateInvitation}
                isUserEnterpriseManager={getMainAccountLabel(user.role) === 'manager'}
            />
        </div>
    );
};

const ProfileTeamSectionContainer: React.FC<ICommonProps> = (props) => {
    const { user, isEnterprise } = props;
    const dispatch = useDispatch();
    const lang = i18n.language as ILang;
    const invitations = useSelector(
        (state) => state.team.invitations.response.results
    );
    const managers = useSelector(
        (state) => state.team.members.response.results
    );

    useEffect(() => {
        const companyManage = user?.permissions?.company?.manage;
        const teamManage = user?.permissions?.team?.manage;
        dispatch(getInvitationsSet.request());
        if (companyManage) {
            dispatch(getEnterpriseManagerSet.request());
        }
        if(teamManage){
            dispatch(getManagerSet.request());
        }
    }, [dispatch]);

    const isAdmin = props.user.role === 'ADMIN';

    const handleCreateInvitation = (payload: IEditManagerInput) => {
        const { first_name, last_name, email, phone } = payload;
        const type = isAdmin ? 'COMPANY' : 'TEAM';
        const updatedPayload = {
            first_name,
            last_name,
            email,
            phone_number: phone,
            type
        };
        dispatchAsync(
            dispatch,
            createInvitationSet.request(updatedPayload, lang)
        ).catch((_) =>
            dispatch(showSnackbar({ message: 'team.add_manager_fail' }))
        );
    };

    const handleResendInvitation = (id: number) => {
        dispatchAsync(dispatch, resendInvitationSet.request(id, lang))
            .then((_) =>
                dispatch(
                    showSnackbar({
                        snackType: 'info',
                        message: 'team.resend_invitation_success'
                    })
                )
            )
            .catch((_) =>
                dispatch(
                    showSnackbar({ message: 'team.resend_invitation_fail' })
                )
            );
    };

    const handleDeleteInvitation = (id: number) => {
        dispatchAsync(dispatch, deleteInvitationSet.request(id)).catch((_) =>
            dispatch(showSnackbar({ message: 'team.delete_manager_fail' }))
        );
    };

    const handleDeleteManager = (id: number) => {
        if (isAdmin && isEnterprise){
            return dispatch(showSnackbar({ message: "Action not allowed, Please contact support." }));
        }
        dispatchAsync(dispatch, deleteManagerSet.request(id)).catch((_) =>
            dispatch(showSnackbar({ message: 'team.delete_manager_fail' }))
        );
    };

    return (
        <ProfileTeamSection
            {...props}
            managers={managers}
            invitations={invitations}
            onCreateInvitation={handleCreateInvitation}
            onResendInvitation={handleResendInvitation}
            onDeleteInvitation={handleDeleteInvitation}
            onDeleteManager={handleDeleteManager}
        />
    );
};

export default ProfileTeamSectionContainer;
