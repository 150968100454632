import React, { useRef, useEffect, memo } from 'react';
import { createPortal } from 'react-dom';

// TODO: use framer motion instead
import { CSSTransition } from 'react-transition-group';

import clsx from 'clsx';

interface IProps extends IModalBase {
    children?: React.ReactNode;
    dismissable?: boolean;
    white?: boolean;
    small?: boolean;
    large?: boolean;
    onExited?: ICallback;
    contentClassName?: string;
}

const root = document.getElementById('root');

const StdModal: React.FC<IProps> = ({
    open,
    children,
    dismissable,
    white,
    small,
    large,
    onClose,
    onExited,
    contentClassName
}) => {
    const backdropRef = useRef(null);
    const everOpen = useRef(false);
    const beforeOpen = useRef<string>();

    useEffect(() => {
        return () => {
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        if (!everOpen.current && open) {
            beforeOpen.current = document.body.style.overflow;
            everOpen.current = true;
            document.body.style.overflow = 'hidden';
        } else if (everOpen.current) {
            document.body.style.overflow = open
                ? 'hidden'
                : beforeOpen.current || '';
        }
    }, [open]);

    const onBackdropClick = (event: React.MouseEvent) => {
        if (event.target === backdropRef.current) {
            dismissable && onClose();
        }
    };

    return createPortal(
        <CSSTransition
            in={open}
            classNames="modal"
            timeout={300}
            onExited={onExited}
            unmountOnExit
        >
            <div className={clsx('modal')}>
                <div className="modal__scroller">
                    <div
                        ref={backdropRef}
                        className="modal__backdrop"
                        onMouseDown={onBackdropClick}
                    >
                        <div
                            className={clsx(
                                'modal__content',
                                small && 'modal__content--small',
                                white && 'modal__content--white',
                                large && 'modal__content--large',
                                contentClassName && contentClassName
                            )}
                        >
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </CSSTransition>,
        root || document.body
    );
};

export default memo(
    StdModal,
    (prevProps, nextProps) =>
        prevProps.open === nextProps.open &&
        prevProps.children === nextProps.children
);
