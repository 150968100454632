import { takeEvery } from 'redux-saga/effects';

import { GET_PROTOCOL_ITEM_REQUEST } from '../../actions';
import {
    getProtocolItem,
    getProtocolItemSuccess,
    getProtocolItemFail
} from '../../action-creators';
import { simpleRequest, ISuccessCallback } from '../modules/request';
import { normalizeProtocolItem } from '../modules/normalization';

type IGetProtocolItemAction = RT<typeof getProtocolItem>;

const onSuccess: ISuccessCallback<IGetProtocolItemAction> = function (data) {
    return normalizeProtocolItem(data);
};

export default takeEvery(
    GET_PROTOCOL_ITEM_REQUEST,
    simpleRequest<IGetProtocolItemAction>({
        success: getProtocolItemSuccess,
        failure: getProtocolItemFail,
        getUrl: (action) => `protocol_item/${action.itemId}/`,
        onSuccess
    })
);
