import React from 'react';
import { useTranslation } from 'react-i18next';

import StdButton from '../../atoms/StdButton';

import './trial-subscribe-success.scss';

interface IProps {
    plan: string;
    onContinue?: ICallback;
}

const TrialSubscribeSuccess: React.FC<IProps> = ({ plan, onContinue }) => {
    const { t } = useTranslation();
    return (
        <div className="subscribe-success__page">
            <div className="subscribe-success__illustration-container stack-s">
                <img
                    className="subscribe-success__illustration"
                    src="/static/images/Subscribe_Success_Illustration.svg"
                    alt=""
                />
            </div>
            <div className="subscribe-success__title stack-l">
                {t('subscription_success_note', { plan })}
            </div>
            <div className="subscribe-success__subtitle stack-l">
                {t('subscription_success_invoice_details')}
            </div>
            <StdButton size="large" width="250px" onClick={onContinue}>
                {t('to_dashboard')}
            </StdButton>
        </div>
    );
};

export default TrialSubscribeSuccess;
