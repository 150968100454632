import { useState, useLayoutEffect, useRef } from 'react';

const useDelayedUpdate = <T>(value: T, initVal: T, delay: number = 10) => {
    const [val, setVal] = useState<T>(initVal);
    const timeoutRef = useRef<number | null>(null);

    useLayoutEffect(() => {
        const timeout = timeoutRef.current;
        timeout != null && window.clearTimeout(timeout);

        timeoutRef.current = window.setTimeout(() => setVal(value), delay);

        return () => {
            timeout != null && window.clearTimeout(timeout);
        };
    }, [value, delay]);

    return val;
};

export default useDelayedUpdate;
