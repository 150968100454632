import { takeEvery } from 'redux-saga/effects';

import { PATCH_ELEMENT_REQUEST } from '../../actions';
import {
    patchElementSuccess,
    patchElementFail,
    patchElement
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeEvery(
    PATCH_ELEMENT_REQUEST,
    simpleRequest<ReturnType<typeof patchElement>>({
        success: patchElementSuccess,
        failure: patchElementFail,
        getUrl: (action) => `element/${action.eleId}/`,
        getBody: (action) => action.payload,
        method: 'PATCH'
    })
);
