import React from 'react';
import { useTranslation } from 'react-i18next';

import Loading from '../../atoms/Loading';
import StdButton from '../../atoms/StdButton';
import StdIcon from '../../atoms/StdIcon';

interface IProps {
    onShare?: ICallback;
    isSharing?: boolean;
    disabled?: boolean;
}

const ShareIssueSection: React.FC<IProps> = ({
    onShare,
    isSharing,
    disabled
}) => {
    const { t } = useTranslation();
    return (
        <div>
            <StdButton
                className="stack-m"
                onClick={onShare}
                disabled={isSharing}
                width="100%"
            >
                {isSharing ? (
                    <Loading className="loading__button" />
                ) : (
                    <span>
                        {disabled && (
                            <StdIcon
                                className="inline-m"
                                name="lock-alt"
                                prefix="far"
                                small
                            />
                        )}
                        <span>
                            {t('issue_sharing.share_issue').toUpperCase()}
                        </span>
                    </span>
                )}
            </StdButton>
            <div className="font--small font--secondary">
                {t('issue_sharing.share_issue_note')}
            </div>
        </div>
    );
};

export default ShareIssueSection;
