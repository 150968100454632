import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { ONE_DPD } from '../../../configs/routes';
import { SUBDPD_TYPE } from '../../../configs/basicData';
import { MEDIA_ATTACHMENT_TYPE } from '../../../configs/costants';

import { setImageDimentions } from '../../../modules/upload';
import { safeByIds } from '../../../store/storeModule';
import { justArray } from '../../../utils';
import { objectToIcon } from '../../../modules/property';
import useMatchMedia from '../../../hooks/useMatchMedia';

import StdChip from '../../atoms/StdChip';
import StdImage from '../../atoms/StdImage';
import StdIcon from '../../atoms/StdIcon';

import './object-card.scss';

interface IProps {
    parentId: number;
    className?: string;
    subDpd: IDpd;
    link?: string;
    isDragging?: boolean;
}

const ObjectCard: React.FC<IProps> = ({
    className,
    parentId,
    link,
    subDpd,
    isDragging
}) => {
    const { t } = useTranslation();
    const {
        dpd_id,
        dpd_title,
        dpd_type,
        dpd_rooms,
        dpd_iss_count,
        dpd_new_iss_count,
        uploads
    } = subDpd;

    const typesHash =
        useSelector((state) => state.basicData.response?.[SUBDPD_TYPE]) || {};

    const isPhone = useMatchMedia('mobile');
    const uploadStore = useSelector((state) => state.uploads.uploads);

    const storedUploads = safeByIds(uploads as number[], uploadStore.byIds);

    const media = storedUploads.find(
        (upload) => upload.type !== MEDIA_ATTACHMENT_TYPE
    );
    const path = media?.path
        ? setImageDimentions(media.path, 343, 160)
        : undefined;

    const rooms = justArray(dpd_rooms);
    const roomText = `${rooms.length} ${
        rooms.length === 1 ? t('room') : t('rooms')
    }`;

    const imgComp = (
        <div
            className={clsx(
                'object-card__image',
                isPhone ? 'stack-m' : 'inline-m'
            )}
        >
            <StdImage icon="th-large" src={path} title={roomText} />
            {path && (
                <div className="object-card__count font--small">{roomText}</div>
            )}
        </div>
    );

    const infoComp = (
        <div className="stack-s">
            <div className="util__flex-wrap stack-xs">
                <span
                    className={clsx(
                        'font--strong',
                        dpd_iss_count && 'inline-s'
                    )}
                >
                    {dpd_title || '----'}
                </span>
                {Boolean(dpd_iss_count) && (
                    <span className="font--secondary">
                        <span
                            className={clsx(dpd_new_iss_count && 'inline-xs')}
                        >
                            {dpd_iss_count} {dpd_iss_count && dpd_iss_count > 1 ? t('issues') : t('issue')}
                        </span>
                        {Boolean(dpd_new_iss_count) && (
                            <>
                                <span className="inline-xs">·</span>
                                <span className="color-danger">
                                    {dpd_new_iss_count} {t('new')}
                                </span>
                            </>
                        )}
                    </span>
                )}
            </div>
            {dpd_type && (
                <div className="font--small font--secondary">
                    <StdIcon
                        className="inline-xs"
                        name={objectToIcon(dpd_type)}
                        small
                    />
                    <span>{typesHash[dpd_type] || '---'}</span>
                </div>
            )}
        </div>
    );

    return (
        <div
            className={clsx(
                'object-card__container',
                isDragging && 'object-card__container--dragging',
                className
            )}
        >
            {link ? (
                <Link className="std-blank-link" to={link}>
                    {imgComp}
                </Link>
            ) : (
                imgComp
            )}
            <div className="object-card__content">
                {link ? (
                    <Link className="std-blank-link" to={link}>
                        {infoComp}
                    </Link>
                ) : (
                    infoComp
                )}
                <div className="object-card__rooms">
                    {rooms.slice(0, 5).map((room) => {
                        const { rom_id, rom_name } = room;
                        return (
                            <StdChip
                                key={rom_id}
                                className="inline-xs stack-s"
                                to={`${ONE_DPD(
                                    parentId,
                                    dpd_id
                                )}/?room=${rom_id}`}
                            >
                                <span>{rom_name || '--'}</span>
                            </StdChip>
                        );
                    })}
                    {rooms.length > 5 && (
                        <StdChip to={link} className="inline-xs stack-s">
                            <span>...</span>
                        </StdChip>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ObjectCard;
