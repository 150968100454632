import { takeEvery } from 'redux-saga/effects';

import { deleteInvitationSet } from '../../action-creators/team';
import { simpleRequest } from '../modules/request';

export default takeEvery(
    deleteInvitationSet.type,
    simpleRequest<RT<typeof deleteInvitationSet.request>>({
        success: deleteInvitationSet.success,
        failure: deleteInvitationSet.fail,
        getUrl: (action) => `group_invitation/${action.id}/`,
        method: 'DELETE',
        isV2Api: true
    })
);
