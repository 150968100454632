import { takeLeading } from 'redux-saga/effects';

import { PATCH_PARTNER_PROTOCOL_REQUEST } from '../../actions';
import {
    patchPartnerProtocol,
    patchPartnerProtocolSuccess,
    patchPartnerProtocolFail
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeLeading(
    PATCH_PARTNER_PROTOCOL_REQUEST,
    simpleRequest<RT<typeof patchPartnerProtocol>>({
        success: patchPartnerProtocolSuccess,
        failure: patchPartnerProtocolFail,
        getUrl: (action) => `partners/miop/${action.protocolToken}/`,
        getBody: (action) => action.data,
        method: 'PATCH',
        isV2Api: true
    })
);
