import * as actions from '../actions';
import type { IUserActions } from '../action-creators/types';

import type { authorizePartnerProtocolSuccess } from '../action-creators';
import { AUTHORIZE_PARTNER_PROTOCOL_SUCCESS } from '../actions';

interface IUserState {
    access: string | null;
    refresh: string | null;
    user: IRequestResult<IUser | null>;
    billing: IRequestResult<IBilling | null>;
    currentInvoice: IRequestResult<IInvoiceHistory | null>;
    invoiceHistory: IHash<IOneEnitityState<IInvoice>>;
    packages: IRequestResult<IPackage[] | null>;
    usageTotals: IRequestResult<IUsageTotals | null>;
}

const userInital: IUserState = {
    access: null,
    refresh: null,
    user: {
        response: null,
        error: null
    },
    billing: {
        response: null,
        error: null
    },
    packages: {
        response: null,
        error: null
    },
    currentInvoice: {
        response: null,
        error: null
    },
    invoiceHistory: {},
    usageTotals: {
        response: null,
        error: null
    }
};

type IAugmentedUserActions =
    | IUserActions
    | RT<typeof authorizePartnerProtocolSuccess>;

function userReducer(
    state = userInital,
    action: IAugmentedUserActions
): IUserState {
    switch (action.type) {
        case actions.SESSION_START:
            return {
                ...state,
                ...action.tokens
            };
        case actions.SESSION_END:
            return {
                ...state,
                access: null,
                refresh: null
            };
        case actions.REFRESH_ACCESS_SUCCESS:
            return {
                ...state,
                ...action.tokens
            };
        case actions.GET_CURRENT_ACC_REQUEST: {
            return {
                ...state,
                user: {
                    ...state.user,
                    error: null
                }
            };
        }
        case actions.GET_CURRENT_ACC_SUCCESS:
            return {
                ...state,
                user: {
                    error: null,
                    response: action.payload.account
                }
            };
        case actions.GET_CURRENT_ACC_FAIL:
            return {
                ...state,
                user: {
                    ...state.user,
                    error: action.error
                }
            };
        case actions.GET_BILLING_SUCCESS:
            return {
                ...state,
                billing: {
                    ...state.billing,
                    response: action.payload
                }
            };
        case actions.GET_BILLING_FAIL:
            return {
                ...state,
                billing: {
                    ...state.billing,
                    error: action.error
                }
            };
        case actions.GET_PACKAGES_REQUEST: {
            return {
                ...state,
                packages: {
                    ...state.packages,
                    error: null
                }
            };
        }
        case actions.GET_PACKAGES_SUCCESS: {
            return {
                ...state,
                packages: {
                    ...state.packages,
                    response: action.payload.results
                }
            };
        }
        case actions.GET_PACKAGES_FAIL: {
            return {
                ...state,
                packages: {
                    ...state.packages,
                    error: action.error
                }
            };
        }
        case actions.GET_USAGE_LOGS_REQUEST: {
            return {
                ...state,
                currentInvoice: {
                    ...state.currentInvoice,
                    error: null
                }
            };
        }
        case actions.GET_USAGE_LOGS_SUCCESS: {
            const usgaeAction = action as any as IAsyncResponseAction;

            const hasForwarded = Boolean(usgaeAction.originator);
            if (hasForwarded && usgaeAction.originator.isSummary) {
                return {
                    ...state,
                    usageTotals: {
                        ...state.usageTotals,
                        response: action.payload
                    }
                };
            }

            return {
                ...state,
                currentInvoice: {
                    ...state.currentInvoice,
                    response: {
                        current_period: {
                            // Currently period data is unused as only the current month usage is shown
                            id: NaN,
                            currency: '',
                            total: '',
                            start_date: '',
                            end_date: '',
                            usage: action.payload
                        },
                        other_periods: []
                    }
                }
            };
        }
        case actions.GET_USAGE_LOGS_FAIL:
            return {
                ...state,
                currentInvoice: {
                    ...state.currentInvoice,
                    error: action.error
                }
            };
        case actions.GET_USAGE_HISTORY_REQUEST: {
            const id = (action as any).id;
            const oldData = state.invoiceHistory[id]?.response;

            return {
                ...state,
                invoiceHistory: {
                    ...state.invoiceHistory,
                    [id]: {
                        response: oldData,
                        isLoading: true,
                        error: null
                    }
                }
            };
        }
        case actions.GET_USAGE_HISTORY_SUCCESS: {
            const id = (action as any).entityId;
            return {
                ...state,
                invoiceHistory: {
                    ...state.invoiceHistory,
                    [id]: {
                        isLoading: false,
                        response: action.payload,
                        error: null
                    }
                }
            };
        }
        case actions.GET_USAGE_HISTORY_FAIL: {
            const id = (action as any).entityId;
            const oldData = state.invoiceHistory[id]?.response;

            return {
                ...state,
                invoiceHistory: {
                    ...state.invoiceHistory,
                    [id]: {
                        isLoading: false,
                        response: oldData,
                        error: action.error
                    }
                }
            };
        }
        case AUTHORIZE_PARTNER_PROTOCOL_SUCCESS: {
            const account = action.payload.data?.user;
            return {
                ...state,
                user: {
                    error: null,
                    response: account
                }
            };
        }
        case actions.PREDICT_ACCOUNT:
            const currentAccount = state.user.response;
            const account =
                currentAccount != null
                    ? { ...currentAccount, ...action.payload }
                    : null;
            return {
                ...state,
                user: {
                    ...state.user,
                    response: account
                }
            };
        case actions.PATCH_ACCOUNT_SUCCESS: {
            return {
                ...state,
                user: {
                    error: null,
                    response: action.payload
                }
            }
        }
    }
    return state;
}

export default userReducer;
