import React from 'react';
import StdOverlay from '../../atoms/StdOverlay';

import './offline-overlay.scss';

interface IProps {
    open: boolean;
    message: ReactNode;
    action: ReactNode;
}

const MessageOverlay: React.FC<IProps> = ({ open, message, action }) => {
    return (
        <StdOverlay open={open} transparent>
            <div className="message-overlay__content">
                <div className="message-overlay__note">
                    {message}
                    {action}
                </div>
            </div>
        </StdOverlay>
    );
};

export default MessageOverlay;
