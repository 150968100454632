import React from 'react';
import clsx from 'clsx';
import { TextField, TextFieldProps } from '@rmwc/textfield';

export interface IProps
    extends React.InputHTMLAttributes<HTMLInputElement>,
        TextFieldProps {
    value?: string | undefined;
    error?: string;
    width?: string;
    multiline?: boolean;
    rows?: number;
    rowsMax?: number;
    //dense?: boolean;
    extraDense?: boolean;
}

const StdTextField: React.FC<IProps> = (props, ref) => {
    const {
        className,
        error,
        multiline,
        width,
        rows,
        rowsMax,
        extraDense,
        outlined = true,
        ...otherProps
    } = props;

    const attrs = { 'data-hj-allow': true };
    const allProps = { ...otherProps, ...attrs };
    return (
        <TextField
            {...allProps}
            className={clsx(
                extraDense && 'mdc-text-field--extra-dense',
                className
            )}
            ref={ref}
            invalid={Boolean(error)}
            rows={rows}
            rowSpan={rowsMax}
            style={{ width }}
            textarea={multiline}
            outlined={outlined}
        />
    );
};

export default React.forwardRef(StdTextField);
