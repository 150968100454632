import { all } from 'redux-saga/effects';
import createIssLink from './createLink';
import delIss from './delete';
import getIss from './get';
import getAllIss from './getAll';
import getIssLogs from './getLogs';
import logIssPrivate from './logPrivate';
import patchIss from './patch';
import postIss from './post';
import getProtocol from './getProtocol';
import getElement from './getElement';
import getStoredProto from './getStoredProto';
import getOne from './getOne';

export default all([
    createIssLink,
    delIss,
    getIss,
    getAllIss,
    getIssLogs,
    logIssPrivate,
    patchIss,
    postIss,
    getProtocol,
    getElement,
    getStoredProto,
    getOne
]);
