import { takeEvery } from 'redux-saga/effects';

import { PATCH_PROTOCOL_REQUEST } from '../../actions';
import {
    patchProtocol,
    patchProtocolSuccess,
    patchProtocolFail
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeEvery(
    PATCH_PROTOCOL_REQUEST,
    simpleRequest<RT<typeof patchProtocol>>({
        success: patchProtocolSuccess,
        failure: patchProtocolFail,
        getUrl: (action) => `protocol/${action.protoId}/`,
        getBody: (action) => action.proto,
        method: 'PATCH',
        enableOffline: true
    })
);
