import React, {useState} from 'react';
import clsx from 'clsx';
import StdButton from '../../atoms/StdButton';
import { useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { PERSONA_TYPE } from '../../../configs/basicData';
import Modal from '../../atoms/StdModal';
import { patchAccount, showSnackbar } from '../../../store/action-creators';

import { Radio } from '@rmwc/radio';
import { useEffect } from 'react';
import { dispatchAsync } from '../../../store/storeModule';

const UserPersonaSelectorModal = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    const [selectedPersona, setSelectedPersona] = useState<string | undefined>();
    const[showModal, setShowModal] = useState<boolean>(false);

    const userDataResponse = useSelector(state => state.user.user.response);
    const userPersona = userDataResponse?.persona;
    const lookupHash = useSelector((state) => state.basicData.response);

    const userIsAdmin = userDataResponse?.role  === 'ADMIN';
    const personaData = lookupHash?.[PERSONA_TYPE];
    const personaKeys = Object.keys(personaData || {});

    const userPersonaNotPresent = !Boolean(userPersona);

    useEffect(() => {
        if(userDataResponse && pathname.includes('dpd') && userPersonaNotPresent && userIsAdmin) {
            setShowModal(true);
        }
    }, [pathname, userDataResponse])

    const handleUpdatePersona = () => {
        selectedPersona &&
            dispatchAsync(
                dispatch,
                patchAccount({ persona: selectedPersona as IUser['persona'] })
            )
                .then(() => {
                    setShowModal(false);
                })
                .catch(({ error }) => {
                    dispatch(
                        showSnackbar({ message: 'server_error_message', error })
                    );
                    setSelectedPersona(undefined);
                    setShowModal(true);
                });
    };

    return (
        <>
            <Modal
                    open={showModal}
                    onClose={() => {}}
                    white
                    contentClassName="user-persona-selector-modal-content"
                >
                    <div className="modal__section--square user-persona-selector-modal">
                        <div
                            className={clsx(
                                'stack-xl',
                                'user-persona-selector-content-wrapper'
                            )}
                        >
                            <div className="modal-header">
                                {'Welcome to your AIMMO'}
                            </div>
                            <div className="modal-info__2">
                                {t('user_persona.description')}
                            </div>
                        </div>
                        <div className="user-persona-selector__selection-list">
                            {
                                personaKeys.map((key, index) => {
                                    const displayString = personaData?.[key].split(" | ") || [];
                                    const isSubtitleProvided = displayString?.length > 1;
                                    return (
                                        <div key={index} className={"form__line-action stack-s"}>
                                            <Radio
                                                className="inline-xs"
                                                value={key}
                                                name="user-persona"
                                                
                                                onChange={(e: {target:{value: string}}) => setSelectedPersona(e.target.value)}
                                            />
                                            <span>{displayString[0]}</span>
                                            {isSubtitleProvided && <span className="user-persona-selector__list-item-highlight">{`(${displayString[1]})`}</span>}
                                        </div>
                                    )
                                })
                            }
                            
                        </div>
                        <div className={clsx("modal__actions", "user-persona-selector-action-button")}>
                            <StdButton
                                width="200px"
                                className="user-persona-selector-action-proceed"
                                onClick={() => handleUpdatePersona()}
                                disabled={!selectedPersona}
                            >
                                {t('user_persona.button_proceed')}
                            </StdButton>
                        </div>
                    </div>
                </Modal>
        </>
    );
};

export default UserPersonaSelectorModal;
