import { takeEvery } from 'redux-saga/effects';

import { DELETE_TENANT_REQUEST } from '../../actions';
import {
    deleteTenantSuccess,
    deleteTenantFail,
    deleteTenant
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeEvery(
    DELETE_TENANT_REQUEST,
    simpleRequest<RT<typeof deleteTenant>>({
        success: deleteTenantSuccess,
        failure: deleteTenantFail,
        getUrl: (action) => `tenant/${action.tenantId}/`,
        method: 'DELETE'
    })
);
