import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty, isEmail } from 'validator';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import clsx from 'clsx';

import {
    MEDIA_PROFILE_PICTURE_TYPE,
    PHONE_TYPE_REGEX,
    TENANT_TYPE_LIVING,
    TENANT_TYPE_MOVED_OUT,
    TENANT_TYPE_MOVING_IN
} from '../../../configs/costants';

import {
    postTenant,
    showSnackbar,
    patchTenant,
    checkEmail,
    clearCheckEmail
} from '../../../store/action-creators';

import { validateErrorCode } from '../../../utils/validation';
import { getCommPhone, getFullname } from '../../../modules/account';

import { dispatchAsync } from '../../../store/storeModule';
import { backDateToISO, ISOToBackDate } from '../../../utils/formatter';
import { validate } from '../../../utils/validation';

import useInput from '../../../hooks/useInput';
import useValidation from '../../../hooks/useValidation';
import usePending from '../../../hooks/usePending';
import useUploader from '../../../hooks/useUploader';

import StdModal from '../../atoms/StdModal';
import StdGallery from '../../molecules/StdGallery';
import StdTextField from '../../atoms/StdTextField';
import StdDivider from '../../atoms/StdDivider';
import StdRadio from '../../atoms/StdRadio';
import StdDatePicker from '../../atoms/StdDatePicker';
import ExistingTenantCard from '../ExistingTenantCard';
import EditModalHeader from '../../atoms/EditModalHeader';
import ObjectEditModal from '../../atoms/ObjectEditModal/ObjectEditModal';
import StdButton from '../../atoms/StdButton';

interface IProps extends IModalBase {
    tenant?: ITenant;
    dpdId: number;
    onSubmit?: ICallback;
}

interface IForm {
    form: {
        name: string;
        email: string;
        phone: string;
        status: 'move-in' | 'living';
        contract_start_date: string;
        move_in_date: string;
    };
    errors: IErrorHash;
}

const initForm: IForm = {
    form: {
        name: '',
        email: '',
        phone: '',
        status: 'living',
        contract_start_date: moment().toISOString(),
        move_in_date: moment().toISOString()
    },
    errors: {}
};

const EditTenantModal: React.FC<IProps> = ({
    dpdId,
    tenant,
    open,
    onClose,
    onSubmit
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [input, setInput] = useState(initForm);

    const handleInput = useInput(setInput, (name, value, newState) => {
        if (name === 'phone' && !PHONE_TYPE_REGEX.test(value)) return input;
        return newState;
    });

    const galleryUploader = useUploader();

    const isCheckLoading = usePending([checkEmail]);
    const checkResponse = useSelector((state) => state.global.emailCheck);
    const canProcceed = !isCheckLoading && Boolean(checkResponse);

    const tenantUser = checkResponse?.response;
    const exists = Boolean(tenantUser);

    const { firstname, lastname, email, com } = tenantUser || {};
    const existingEmail = email;
    const existingName = getFullname(firstname, lastname);

    const isArchived = tenant?.type === TENANT_TYPE_MOVED_OUT;
    const hasProtocols = Boolean(
        tenant?.protocols?.prt_move_in || tenant?.protocols?.prt_move_out
    );
    const hideStatus = tenant && (hasProtocols || isArchived);

    useEffect(() => {
        if (tenant && open) {
            const {
                contact_details,
                contract_start_date,
                type,
                from_date,
                uploads
            } = tenant;
            const { first_name, last_name, phone, email } =
                contact_details || {};

            const name = getFullname(first_name, last_name, '');

            setInput((s) => ({
                ...s,
                form: {
                    ...s.form,
                    name,
                    email: email || '',
                    phone: phone || '',
                    contract_start_date: contract_start_date
                        ? backDateToISO(contract_start_date)
                        : moment().toISOString(),
                    move_in_date: from_date
                        ? backDateToISO(from_date)
                        : moment().toISOString(),
                    status:
                        type === TENANT_TYPE_MOVING_IN ? 'move-in' : 'living'
                }
            }));

            galleryUploader.setUploads(uploads as number[]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenant, open]);

    useEffect(() => {
        if (open && !tenant && isEmail(input.form.email)) {
            dispatch(checkEmail(input.form.email));
        }
    }, [dispatch, input.form.email, tenant, open]);

    const validateFields = useValidation(input, setInput, (hash) => {
        const { name, email, contract_start_date, move_in_date } = input.form;
        return [
            validate({ name }, !isEmpty(name), hash),
            validate({ email }, isEmail(email), hash),
            validate(
                { contract_start_date },
                !isEmpty(contract_start_date),
                hash
            ),
            validate({ move_in_date }, !isEmpty(move_in_date), hash)
        ].every(Boolean);
    });

    const composeLiteral = () => {
        const {
            name,
            email,
            phone,
            contract_start_date,
            status,
            move_in_date
        } = input.form;
        const [first, ...rest] = name.trim().split(' ');
        const last = rest.join(' ');

        const isMovingIn = status === 'move-in';
        const isLiving = status === 'living';

        const newTenantType = isLiving
            ? TENANT_TYPE_LIVING
            : TENANT_TYPE_MOVING_IN;

        const baseLiteral = {
            contract_start_date: isMovingIn
                ? ISOToBackDate(contract_start_date)
                : undefined,
            type: !hideStatus ? newTenantType : undefined,
            from_date: isLiving ? ISOToBackDate(move_in_date) : undefined
        };

        if (tenantUser) {
            const exsitingPhone = getCommPhone(com);

            return {
                ...baseLiteral,
                ten_email: existingEmail || '',
                ten_firstname: firstname,
                ten_lastname: lastname,
                ten_phone: exsitingPhone
            };
        }

        return {
            ...baseLiteral,
            ten_email: email,
            ten_firstname: first,
            ten_lastname: last,
            ten_phone: phone,
            ten_picture: galleryUploader.ids[0]
        };
    };

    const createTenant = () => {
        // cancel upload if existing since we don't get the profile picture anywhere
        if (tenantUser) {
            galleryUploader.cancelChanges();
        }

        const dataLiteral = composeLiteral();
        const modAction = tenant
            ? patchTenant(tenant.id, { ...dataLiteral })
            : postTenant({ dpd: dpdId, ...dataLiteral });

        dispatchAsync(dispatch, modAction)
            .then(galleryUploader.submitChanges)
            .then(onSubmit)
            .catch((action) =>
                dispatch(
                    showSnackbar({
                        message: t(
                            validateErrorCode(
                                action.error,
                                tenant
                                    ? 'edit_tenant_error'
                                    : 'add_tenant_error'
                            )
                        )
                    })
                )
            );
        dispatch(clearCheckEmail());

        onClose();
    };

    const handleSumbit = () => {
        validateFields() && createTenant();
    };

    const cleanup = () => {
        setInput(initForm);
        dispatch(clearCheckEmail());
    };

    const onModalClose = () => {
        galleryUploader.cancelChanges();
        onClose();
    };

    const modalTitle = tenant ? t('edit_tenant') : t('add_tenant');
    const { form, errors } = input;
    return (
        <StdModal open={open} onClose={onClose} onExited={cleanup} contentClassName={'profilePageModal'}>
            <ObjectEditModal
                className='profilePageHeader'
                title={modalTitle}
                onClose={onModalClose}
            />
            <div className="modal__section modal__section--space">
                <StdGallery
                    backgroundColor='transparent'
                    isTitleUpperCase
                    uploadConfig={{
                        type: MEDIA_PROFILE_PICTURE_TYPE
                    }}
                    {...galleryUploader}
                    icon="user"
                    disabled={exists}
                    centered
                    dark
                />
            </div>
            <div className="modal__section modal__section--space modal__section--space--bottom">
                <div className="modal__heading modal-heading-bold stack-l">
                    {t('tenant_details').toUpperCase()}
                </div>
                <StdTextField
                    label={t('fullname')}
                    value={form.name}
                    error={errors['name']}
                    name="name"
                    type="name"
                    onChange={handleInput}
                    width="100%"
                    disabled={exists}
                    required
                />
            </div>
            <StdDivider />
            <div className="modal__section modal__section--space">
                <div className="modal__heading modal-heading-bold stack-l">
                    {t('contact_details').toUpperCase()}
                </div>
                <StdTextField
                    className="stack-m"
                    label={t('email')}
                    value={form.email}
                    error={errors['email']}
                    name="email"
                    type="email"
                    onChange={handleInput}
                    width="100%"
                    required
                />
                <StdTextField
                    className={clsx(exists && 'stack-m')}
                    label={t('phone')}
                    value={form.phone}
                    error={errors['phone']}
                    name="phone"
                    type="tel"
                    onChange={handleInput}
                    width="100%"
                    disabled={exists}
                />
                {exists && (
                    <ExistingTenantCard
                        name={existingName}
                        email={existingEmail || '----'}
                        onClick={createTenant}
                    />
                )}
            </div>
            {!hideStatus ? (
                <>
                    <div className="modal__section modal__section--space modal__section--space--bottom">
                        <div className="modal__heading modal-heading-bold stack-l">
                            {t('status').toUpperCase()}
                        </div>
                        <div className="stack-m">
                            <StdRadio
                                isLabelBold
                                value="move-in"
                                name="status"
                                onChange={handleInput}
                                checked={form.status === 'move-in'}
                                trim
                            >
                                {t('going_to_move_in')}
                            </StdRadio>
                        </div>
                        <div className="stack-l">
                            <StdRadio
                                isLabelBold
                                value="living"
                                name="status"
                                onChange={handleInput}
                                checked={form.status === 'living'}
                                trim
                            >
                                {t('currently_living')}
                            </StdRadio>
                        </div>
                        <div className='stack-l'>
                            {form.status === 'move-in' ? (
                                <StdDatePicker
                                    className="font-bold"
                                    variant="inline"
                                    label={t('contract_starts_on')}
                                    value={form.contract_start_date}
                                    error={errors['contract_start_date']}
                                    onChange={(date) =>
                                        setInput((s) => ({
                                            ...s,
                                            form: {
                                                ...s.form,
                                                contract_start_date:
                                                    date?.toISOString() || ''
                                            }
                                        }))
                                    }
                                    inputVariant="outlined"
                                    fullWidth
                                />
                            ) : (
                                <StdDatePicker
                                    className="font-bold"
                                    variant="inline"
                                    label={t('moved_in_on')}
                                    value={form.move_in_date}
                                    error={errors['moved_in_on']}
                                    onChange={(date) =>
                                        setInput((s) => ({
                                            ...s,
                                            form: {
                                                ...s.form,
                                                move_in_date:
                                                    date?.toISOString() || ''
                                            }
                                        }))
                                    }
                                    inputVariant="outlined"
                                    fullWidth
                                    disableFuture
                                />
                            )}
                        </div>
                        <div className='modal__section__saveButton'>
                            <StdButton
                                type="primary"
                                onClick={handleSumbit}
                                disabled={!canProcceed || exists}
                            >
                                {t('save')}
                            </StdButton>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="modal__section modal__section--space modal__section--space--bottom">
                        <div className="modal__section__saveButton">
                            <StdButton
                                type="primary"
                                onClick={handleSumbit}
                                disabled={!canProcceed || exists}
                            >
                                {t('save')}
                            </StdButton>
                        </div>
                    </div>
                </>
            )}
        </StdModal>
    );
};

export default EditTenantModal;
