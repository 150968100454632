import { AnyAction } from 'redux';

import { insertMerge, removeAtIndex } from '../../modules/array';

import {
    getInvitationsSet,
    getEnterpriseManagerSet,
    getManagerSet,
    resendInvitationSet,
    createInvitationSet,
    deleteInvitationSet,
    deleteManagerSet
} from '../action-creators/team';

interface ITeamState {
    invitations: IGetManyRequestResult<ITeamInvitation>;
    members: IGetManyRequestResult<ITeamMembership>;
}

const initState: ITeamState = {
    invitations: {
        response: {
            results: []
        },
        error: null
    },
    members: {
        response: {
            results: []
        },
        error: null
    }
};

function reducer(state = initState, action: AnyAction): ITeamState {
    switch (action.type) {
        case getInvitationsSet.types.success: {
            return {
                ...state,
                invitations: {
                    ...state.invitations,
                    response: {
                        ...state.invitations.response,
                        ...action.payload
                    }
                }
            };
        }
        case getEnterpriseManagerSet.types.success: {
            return {
                ...state,
                members: {
                    ...state.members,
                    response: {
                        ...state.members.response,
                        ...action.payload
                    }
                }
            };
        }
        case getManagerSet.types.success: {
            return {
                ...state,
                members: {
                    ...state.members,
                    response: {
                        ...state.members.response,
                        ...action.payload
                    }
                }
            };
        }
        case createInvitationSet.types.success: {
            const previousInvitations = state.invitations.response.results;
            return {
                ...state,
                invitations: {
                    ...state.invitations,
                    response: {
                        ...state.invitations.response,
                        results: previousInvitations.concat(action.payload)
                    }
                }
            };
        }
        case deleteInvitationSet.types.success: {
            const id = action.originator?.id;
            if (id == null) return state;

            const previousInvitations = state.invitations.response.results;
            const index = previousInvitations.findIndex(
                (invitation) => invitation.id === id
            );

            if (index === -1) return state;
            return {
                ...state,
                invitations: {
                    ...state.invitations,
                    response: {
                        ...state.invitations.response,
                        results: removeAtIndex(previousInvitations, index)
                    }
                }
            };
        }
        case deleteManagerSet.types.success: {
            const id = action.originator?.id;
            if (id == null) return state;

            const previousManagers = state.members.response.results;
            const index = previousManagers.findIndex(
                (manager) => manager.id === id
            );

            if (index === -1) return state;
            return {
                ...state,
                members: {
                    ...state.members,
                    response: {
                        ...state.members.response,
                        results: removeAtIndex(previousManagers, index)
                    }
                }
            };
        }
        case resendInvitationSet.types.success: {
            const id = action.originator?.id;

            if (id == null) return state;

            const previousInvites = state.invitations.response.results;

            const updatedInvites = insertMerge({
                items: previousInvites,
                id: {
                    key: 'id',
                    value: id
                },
                merger: (previousInvite) =>
                    ({
                        ...previousInvite,
                        status: 'PENDING'
                    } as ITeamInvitation)
            });

            return {
                ...state,
                invitations: {
                    ...state.invitations,
                    response: {
                        ...state.invitations.response,
                        results: updatedInvites
                    }
                }
            };
        }
    }
    return state;
}

export default reducer;
