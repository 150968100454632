import * as actions from '../actions';
import { AnyAction } from 'redux';

const init: IRequestResult<{ results: IGeoAdminItem[] }> = {
    response: { results: [] },
    error: null
};

const reducer = (state = init, action: AnyAction) => {
    switch (action.type) {
        case actions.GET_LOCATION_CH_REQUEST:
            return {
                ...state,
                response: {
                    ...state.response,
                    results: []
                }
            };
        case actions.GET_LOCATION_CH_SUCCESS:
            return { ...state, response: action.payload };
        case actions.GET_LOCATION_CH_FAIL:
            return { ...state, error: action.error };
    }
    return state;
};

export default reducer;
