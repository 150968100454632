import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { DASHBOARD_ROUTE } from '../../../configs/routes'

import HeaderLogo from '../../atoms/HeaderLogo';
import StdButton from '../../atoms/StdButton';

const ErrorPageScript: React.FC = () => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const history = useHistory();

    return (
        <div className="error__page">
            <div className="header">
                <HeaderLogo />
            </div>
            <div className="error__message">
                <div>
                    <img
                        className="error__illustration"
                        src="/static/images/error_page_illustration.png"
                        alt="error illustration"
                    />
                </div>
                <div className="font__title stack-m">
                    {t('script_error_title')}
                </div>
                <div className="stack-xl">{t('script_error_description')}</div>
                <div className="error__button-stack">
                    <StdButton onClick={() => window.location.replace(pathname)}>
                        {t('refresh_screen_btn')}
                    </StdButton>
                    <StdButton onClick={() => {
                        history.push({pathname: DASHBOARD_ROUTE });
                        window.location.reload();
                    }}>
                        {t('dashboard_navigation_btn')}
                    </StdButton>
                    <StdButton onClick={() => window.location.reload()}>
                        {t('hard_reload_btn')}
                    </StdButton>
                    <StdButton attr-intercom="report-problem">
                        {t('report_a_problem')}
                    </StdButton>
                </div>
            </div>
        </div>
    );
};

export default ErrorPageScript;
