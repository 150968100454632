import { all } from 'redux-saga/effects';

import changeSubscriptionEffect from './changeSubscription';
import cancelSubscriptionEffect from './cancelSubscription';
import getBillingEffect from './getBilling';
import updateAccountEffect from './updateAccount';
import getUsageEffect from './getUsage';
import getPackagesEffect from './getPackages';
import magicLoginEffect from './magicLogin';
import updateUserMediaEffect from './updateUserMedia';
import updateBillingEffect from './updateBilling';
import updateContactAddressEffect from './updateContactAddress';

export default all([
    changeSubscriptionEffect,
    cancelSubscriptionEffect,
    getBillingEffect,
    updateAccountEffect,
    getUsageEffect,
    getPackagesEffect,
    magicLoginEffect,
    updateUserMediaEffect,
    updateBillingEffect,
    updateContactAddressEffect
]);
