// Simple local attirbute implementation
export const getAttrKey = (attr: IAttrId): string => {
    return attr.id != null ? attr.id.toString() : `__${attr.fakeId}`;
};

export const getAttrHashKeys = (attr: IAttrId) => {
    return {
        typeKey: `attr__type__${getAttrKey(attr)}`,
        valueKey: `attr__value__${getAttrKey(attr)}`
    };
};
