import { takeLatest } from 'redux-saga/effects';

import { GET_TEMPALTE_REQUEST } from '../../actions';
import {
    getTemplateSuccess,
    getTemplateFail,
    IGetTemplate
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeLatest(
    GET_TEMPALTE_REQUEST,
    simpleRequest<IGetTemplate>({
        success: getTemplateSuccess,
        failure: getTemplateFail,
        getUrl: (action) =>
            `room/room_template/?type=${
                action.propertyType
            }&lang=${action.lang.substring(0, 2)}`,
        noBlock: true
    })
);
