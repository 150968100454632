import { takeLatest } from 'redux-saga/effects';
import { normalize } from 'normalizr';

import { CHECK_PDF_READY_REQUEST } from '../../actions';
import {
    checkPdfReady,
    checkPdfReadySuccess,
    checkPdfReadyFail
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

import { mediaToUpload } from '../../../modules/upload';
import { uploadSchema } from '../../reducers/schema';

export default takeLatest(
    CHECK_PDF_READY_REQUEST,
    simpleRequest<RT<typeof checkPdfReady>>({
        success: checkPdfReadySuccess,
        failure: checkPdfReadyFail,
        getUrl: (action) => `protocol/${action.protoId}/check_pdf_object/`,
        onSuccess: function (media) {
            const { result, entities } = normalize(
                mediaToUpload(media),
                uploadSchema
            );
            const { uploads = {} } = entities;

            return {
                result,
                uploads
            };
        }
    })
);
