import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'validator';
import clsx from 'clsx';

import { validate } from '../../../utils/validation';
import { parseStreetAddress } from '../../../modules/property';
import useInput from '../../../hooks/useInput';
import useValidation from '../../../hooks/useValidation';
import useMatchMedia from '../../../hooks/useMatchMedia';

import { Checkbox } from '@rmwc/checkbox';
import { MenuItem } from '@rmwc/menu';

import StdTextField from '../../atoms/StdTextField';
import StdButton from '../../atoms/StdButton';
import StdSelect from '../../atoms/StdSelect';

import './update-billing.scss';
import StdModal from '../../atoms/StdModal';
import ModalHeader from '../../atoms/ModalHeader';
import CustomIcon from '../../atoms/CustomIcon';

export interface IBillingForm {
    form: {
        street: string;
        city: string;
        country: string;
        region: string;
        zip: string;
        name: string;
        checked_agreement: boolean;
    };
    errors: IErrorHash;
}

const initForm: IBillingForm = {
    form: {
        street: '',
        city: '',
        country: '',
        region: '',
        zip: '',
        name: '',
        checked_agreement: false
    },
    errors: {}
};

interface IProps {
    headerText?: string;
    edit?: boolean;
    plan?: IPackage | null;
    onBack?: ICallback;
    handleInit?: (s: IBillingForm) => IBillingForm;
    handleSubmit?: (s: IBillingForm['form']) => void;
    modalOpen: boolean;
    onModalClose: () => void;
}

const UpdateBilling: React.FC<IProps> = ({
    edit,
    plan,
    headerText,
    onBack,
    handleInit,
    handleSubmit,
    modalOpen,
    onModalClose
}) => {
    const { t } = useTranslation();

    const [input, setInput] = useState(initForm);
    const handleInput = useInput(setInput);

    useEffect(() => {
        handleInit && setInput((s) => handleInit(s));
    }, [handleInit]);

    const validateFields = useValidation(input, setInput, (hash) => {
        const { country, city, zip, street, name, checked_agreement } =
            input.form;
        const { street_name, street_num } = parseStreetAddress(street);

        const isStreetValid =
            !isEmpty(street) && !isEmpty(street_name) && !isEmpty(street_num);
        return [
            validate({ country }, !isEmpty(country), hash),
            validate({ city }, !isEmpty(city), hash),
            validate({ zip }, !isEmpty(zip), hash),
            validate({ name }, !isEmpty(name), hash),
            validate({ street }, isStreetValid, hash),
            edit ||
                validate(
                    { checked_agreement },
                    Boolean(checked_agreement),
                    hash
                )
        ].every(Boolean);
    });

    const makeRequest = () => {
        handleSubmit && handleSubmit(input.form);
    };

    const onClickSubmit = () => {
        validateFields() && makeRequest();
    };

    const onAgreementToggle = () => {
        setInput((s) => ({
            ...s,
            form: { ...s.form, checked_agreement: !s.form.checked_agreement }
        }));
    };

    // GTM tracking attribute
    const submitAttr =
        !edit && plan ? { 'gtm-trigger': `purchase_${plan.base_price}` } : {};

    const { form, errors } = input;
    return (
        <StdModal open={modalOpen} onClose={onModalClose} contentClassName="update-billing">
            <ModalHeader
                leftNode={
                    <span className="std-space-nowrap">
                        {t('editing_billing_details')}
                    </span>
                }
                rightNode={<CustomIcon name="cross" onClick={onModalClose} ripple />}
            />
                    <div
                        className={clsx(
                            'update-billing__form',
                        )}
                    >
                        <StdTextField
                            className="stack-l"
                            label={t('name_or_company')}
                            name="name"
                            error={errors['name']}
                            value={form.name}
                            onChange={handleInput}
                            width="100%"
                            autoComplete="billing name"
                        />
                        <StdTextField
                            className="stack-l"
                            label={t('street_address')}
                            name="street"
                            error={errors['street']}
                            value={form.street}
                            onChange={handleInput}
                            width="100%"
                            autoComplete="billing street-address"
                        />
                        <div className="form__line stack-m">
                            <StdTextField
                                className="stack-l update-billing__inline-field-1"
                                label={t('city')}
                                name="city"
                                error={errors['city']}
                                value={form.city}
                                onChange={handleInput}
                                width="100%"
                                autoComplete="billing address-level2"
                            />
                            <StdTextField
                                className="stack-l update-billing__inline-field-2"
                                label={t('zip')}
                                name="zip"
                                error={errors['zip']}
                                value={form.zip}
                                onChange={handleInput}
                                width="100%"
                                autoComplete="billing postal-code"
                            />
                        </div>
                        <StdSelect
                            className="stack-xl"
                            label={t('country')}
                            name="country"
                            value={form.country}
                            error={errors['country']}
                            onChange={handleInput}
                            width="100%"
                        >
                            <MenuItem data-value={'switzerland'}>
                                {t('switzerland')}
                            </MenuItem>
                            <MenuItem data-value={'germany'}>
                                {t('germany')}
                            </MenuItem>
                            <MenuItem data-value={'austria'}>
                                {t('austria')}
                            </MenuItem>
                            <MenuItem data-value={'liechtenstein'}>
                                {t('liechtenstein')}
                            </MenuItem>
                        </StdSelect>
                        <div className="update-billing__action-buttons">
                            <StdButton
                                {...submitAttr}
                                className="stack-l"
                                type="secondary"
                                onClick={onModalClose}
                            >
                                {'Cancel'}
                            </StdButton>
                            <StdButton
                                {...submitAttr}
                                className="stack-l"
                                type="primary"
                                onClick={onClickSubmit}
                            >
                                {'Save'}
                            </StdButton>
                        </div>
                        {!edit && (
                            <div>
                                {errors['checked_agreement'] && (
                                    <div className="stack-s font--small color-danger">
                                        **{t('unread_agreement_error')}
                                    </div>
                                )}
                                <div className="util__flex">
                                    <Checkbox
                                        checked={Boolean(
                                            form.checked_agreement
                                        )}
                                        onClick={onAgreementToggle}
                                    />
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: t('invoice_note')
                                        }}
                                    ></div>
                                </div>
                            </div>
                        )}
                    </div>
        </StdModal>
    );
};

export default UpdateBilling;
