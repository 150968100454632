import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ExpandableTitle from '../../atoms/ExpandableTitle';
import Invoice from '../Invoice';

interface IProps {
    invoice: IInvoice;
    openByDefault?: boolean;
}

const InvoicePeriod: React.FC<IProps> = ({ invoice, openByDefault }) => {
    const { t } = useTranslation();
    const [historyOpen, setHistoryOpen] = useState(Boolean(openByDefault));

    return (
        <ExpandableTitle
            open={historyOpen}
            title={t('plan.current_invoice')}
            onToggle={() => setHistoryOpen((s) => !s)}
        >
            <Invoice invoice={invoice} />
        </ExpandableTitle>
    );
};

export default InvoicePeriod;
