import React from 'react';
import Modal from '../../atoms/StdModal';
import clsx from 'clsx';
import StdButton from '../../atoms/StdButton';

import './ProtocolBetaWarningModal.scss';

interface ProtocolBetaWarningModalProps {
    open: boolean;
    onAccept: () => void | undefined
    onCancel: () => void | undefined
}

const ProtocolBetaWarningModal: React.FC<ProtocolBetaWarningModalProps> = ({
    open,
    onAccept,
    onCancel
}) => {
    return (
        <Modal
            open={open}
            onClose={() => {}}
            white
            contentClassName="protocol-beta-mode-modal-content"
        >
            <div className="modal__section--square protocol-beta-mode-modal">
                <div className={clsx('stack-xl', 'beta-selector')}>
                    <div className="modal-header">
                        Starting protocol in BETA mode
                    </div>
                    <div className="modal-info__3">
                    Switching from BETA to the regular mode in the protocol is not available. If you decide to proceed you will not be able to change mode.
                    </div>
                </div>
                <div className={clsx('modal__actions', 'beta-modal-actions')}>
                    <StdButton
                        className="beta-cancel"
                        onClick={() => {
                            onCancel();
                        }}
                    >
                        {'GO BACK'}
                    </StdButton>
                    <StdButton
                        className="beta-accept"
                        onClick={() => {
                            onAccept();
                        }}
                    >
                        {'PROCEED WITH BETA'}
                    </StdButton>
                </div>
            </div>
        </Modal>
    );
};

export default ProtocolBetaWarningModal;
