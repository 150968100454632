import { takeEvery } from 'redux-saga/effects';

import { GET_BASIC_DATA_REQUEST } from '../../actions';
import {
    getBasicDataSuccess,
    getBasicDataFail,
    IGetBasicData
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeEvery(
    GET_BASIC_DATA_REQUEST,
    simpleRequest<IGetBasicData>({
        success: getBasicDataSuccess,
        failure: getBasicDataFail,
        getUrl: (action) => `choices/?lang=${action.lang.substring(0, 2)}`,
        withAuth: false,
        noBlock: true
    })
);
