import { SagaIterator } from 'redux-saga';
import axios, { AxiosRequestConfig, Method } from 'axios';
import { call, select, put, take, cancelled } from 'redux-saga/effects';
import { AnyAction, ActionCreator, Action } from 'redux';

import * as acts from '../../actions';
import { getAccessToken } from '../../selectors/user';
import { refreshAccess, logout } from '../../action-creators';

import { isNetworkDownError } from '../../../modules/error';
import { isServiceWorkerRunning } from '../../../utils';
import {
    getActionId,
    getAsyncActName,
    getCreatorType,
    skipActionFactory
} from '../../storeModule';

import {
    getClientId,
    getOfflineStatus,
    getOfflineModeStatus
} from '../../selectors/user';

import { workerHeaders } from '../../../modules/serviceWorker';

import env from '../../../configs/environment';

type ISupportedMethod = Extract<
    Method,
    'GET' | 'DELETE' | 'POST' | 'PUT' | 'PATCH'
>;

function requestHeader(
    method: ISupportedMethod,
    data: unknown,
    token?: string
) {
    const auth = token ? { Authorization: `Bearer ${token}` } : {};

    const isFormData = data instanceof FormData;
    const contentType = {
        'content-type': isFormData ? 'multipart/form-data' : 'application/json'
    };

    const noContHeader = {
        method,
        data,
        headers: { ...auth }
    };
    const contHeader = {
        method,
        headers: { ...contentType, ...auth }
    };

    switch (method) {
        case 'GET':
            return contHeader;
        case 'DELETE':
        case 'POST':
        case 'PUT':
        case 'PATCH':
            return noContHeader;
    }
}

export function* getHeader(
    method: ISupportedMethod,
    data: unknown,
    withAuth: boolean = true
): SagaIterator {
    const token = yield select(getAccessToken);
    return requestHeader(method, data, withAuth ? token : undefined);
}

interface IReqParams {
    method?: ISupportedMethod;
    withAuth?: boolean;
    fullUrl?: boolean;
    logoutOnFail?: boolean;
    ignoreError?: boolean;
    isV2Api?: boolean;
}

interface IRefreshCallParams extends IReqParams {
    requestUrl: string;
    requestData?: unknown;
    customHeader?: object;
    recievedFullUrl?: boolean;
    isManualOffline?: boolean;
    method?: ISupportedMethod | undefined;
}

export function* callWithRefresh(params: IRefreshCallParams): SagaIterator {
    const {
        requestUrl,
        requestData,
        fullUrl,
        logoutOnFail,
        ignoreError,
        isV2Api,
        method = 'GET',
        withAuth = true,
        customHeader = {},
        recievedFullUrl
    } = params;

    const cancelSource = axios.CancelToken.source();
    try {
        const builtUrl = recievedFullUrl
            ? `${env.getProperty('apiUrl')}${requestUrl}`
            : `${env.getProperty('apiUrl')}${
                  isV2Api ? '/api/v2/' : '/api/v1/'
              }${requestUrl}`;
        const url = fullUrl ? requestUrl : builtUrl;

        const axiosHeaders = (yield call(
            getHeader,
            method,
            requestData,
            withAuth
        )) as AxiosRequestConfig;
        const header = {
            ...axiosHeaders,
            headers: {
                ...axiosHeaders.headers,
                ...customHeader
            }
        };

        const response = yield call(axios, url, {
            ...header,
            cancelToken: cancelSource.token
        });

        return response;
    } catch (error) {
        // isNetworkDownError check is redundant as all handling needs a response
        if (ignoreError || isNetworkDownError(error as Error)) throw error;

        const { response } = error as any;
        const isUnath = response?.status === 401;
        const isCriticalFalure =
            logoutOnFail && typeof response?.status === 'number';

        const isTokenExpired =
            isUnath && response?.data.code === 'token_not_valid';

        if (isTokenExpired) {
            yield put(refreshAccess());
            const refreshAct = (yield take([
                acts.REFRESH_ACCESS_SUCCESS,
                acts.REFRESH_ACCESS_FAIL
            ])) as AnyAction;
            if (refreshAct.type === acts.REFRESH_ACCESS_SUCCESS) {
                return yield call(callWithRefresh, params);
            }
        } else if (isUnath || isCriticalFalure) {
            yield put(logout());
        }
        throw error;
    } finally {
        if (yield cancelled()) yield call(cancelSource.cancel);
    }
}

// Standard app http request interface
// Requests not using simpleRequest: media_create (callWithRefresh), refresh_token (direct)

export type ISuccessCallback<T> = (
    data: any,
    action: T,
    state: IRootState
) => any;
export type IFailCallback<T> = (
    error: INetworkError,
    action: T,
    state: IRootState
) => void;

interface IRequestFactoryParams<
    T extends Action,
    S extends AnyAction = AnyAction,
    F extends AnyAction = AnyAction
> extends IReqParams {
    // success action
    success: ActionCreator<S>;
    // fail action
    failure: ActionCreator<F>;

    getUrl: (action: T) => string;

    // Used to get request body
    getBody?: (action: T) => unknown;
    getMethod?: (action: T) => unknown;
    getEntityId?: (action: T) => number;

    // Returns data that is returned in the success callback
    onSuccess?: ISuccessCallback<T>;
    onFail?: IFailCallback<T> | any;
    enableOffline?: boolean;
    noBlock?: boolean;
}

export function simpleRequest<T extends Action>(
    params: IRequestFactoryParams<T>
) {
    return function* (requestAction: T): SagaIterator {
        const {
            success,
            failure,
            getUrl,
            getBody,
            getEntityId,
            onSuccess,
            onFail,
            enableOffline = false,
            noBlock = false,
            ...callParams
        } = params;
        const { resolve, reject } = (requestAction as any).__handler || {};

        const reduxStore: IRootState = yield select((state) => state);

        // Note: ids withing actions

        // localId - id of locally created entities
        // itemId - is added to sw response in order to identify the subject of the action (same as entity id)
        // entityId - id of the subject of the action (can be replaced by better convention within actions)

        const localId = getActionId(requestAction);
        const entityId = getEntityId?.(requestAction);

        const metadata = { localId, entityId, originator: requestAction };
        const skipAction = skipActionFactory(
            getAsyncActName(requestAction.type),
            metadata
        );

        const isOffline = getOfflineStatus(reduxStore);
        const isManualOffline = getOfflineModeStatus(reduxStore);

        const isOfflineMode =
            enableOffline && isManualOffline && isServiceWorkerRunning();

        try {
            // fail if either request is disabled for offline mode or
            // we are outside of offline mode
            const isRequestUnsupported = !enableOffline && isManualOffline;
            const isRequestOffline = isOffline && !isManualOffline;

            if (
                !Boolean(noBlock) && // will enable choiced APIs to run without being blocked by support and offline check
                (isRequestUnsupported || isRequestOffline) &&
                isServiceWorkerRunning()
            ) {
                throw Error('Network Error');
            }

            const requestUrl = getUrl(requestAction);
            const requestData = getBody?.(requestAction);

            // if offline mode is active, include worker headers
            const customHeader = isOfflineMode
                ? workerHeaders(
                      getClientId(reduxStore),
                      metadata.localId,
                      getCreatorType(success),
                      getCreatorType(failure)
                  )
                : undefined;

            const requestParams = {
                ...callParams,
                customHeader,
                requestUrl,
                requestData
            };

            const response = yield call(callWithRefresh, requestParams);
            const { data } = response || {};

            const modifiedData = onSuccess
                ? yield call(onSuccess, data, requestAction, reduxStore)
                : data;

            const successAction = { ...success(modifiedData), ...metadata };
            yield put(successAction);
            resolve?.(successAction);
        } catch (error) {
            // Ignore network down if sevice worker is running and manual offline is enabled
            const avoidError =
                isOfflineMode && isNetworkDownError(error as unknown as any);
            if (avoidError) {
                yield put(skipAction);
                reject?.(skipAction);
                return;
            }

            console.error('Request failed: ', error);

            if (onFail) yield call(onFail, error, requestAction, reduxStore);

            const failAction = { ...failure(error, entityId), ...metadata };
            yield put(failAction);
            reject?.(failAction);
        } finally {
            if (yield cancelled()) {
                yield put(skipAction);
                reject?.(skipAction);
            }
        }
    };
}
