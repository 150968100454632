import { takeLatest } from 'redux-saga/effects';

import { SYNC_OFFLINE_PROTOCOL_PREVIEW_REQUEST } from '../../actions';
import {
    syncOfflineProtocolPreview,
    syncOfflineProtocolPreviewSuccess,
    syncOfflineProtocolPreviewFail
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeLatest(
    SYNC_OFFLINE_PROTOCOL_PREVIEW_REQUEST,
    simpleRequest<RT<typeof syncOfflineProtocolPreview>>({
        success: syncOfflineProtocolPreviewSuccess,
        onSuccess: syncOfflineProtocolPreviewSuccess,
        failure: syncOfflineProtocolPreviewFail,
        getUrl: (action) => `protocol/${action.prt_id}/sync/`,
        getBody: (action) => action.payload,
        method: 'POST'
    })
);
