import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { logout } from '../../../store/action-creators';

import StdModal from '../../atoms/StdModal';
import StdIcon from '../../atoms/StdIcon';
import StdButton from '../../atoms/StdButton';
import StdOverlay from '../../atoms/StdOverlay';
import TrialCancelFeedback from '../TrialCancelFeedback';
import TrialSubscribeFlow from '../TrialSubscribeFlow';

import './trial-exipred-modal.scss';

interface IProps {
    open: boolean;
}

const TrialExpiredModal: React.FC<IProps> = ({ open }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [feedbackOpen, setFeedbackOpen] = useState(false);
    const [selectOpen, setSelectOpen] = useState(false);

    const onLogout = () => {
        dispatch(logout());
    };

    return (
        <StdModal open={open} onClose={() => {}} white large>
            <div className="trial-modal">
                <div className="font__header stack-l">
                    {t('trial_expired_title')}
                </div>
                <div className="stack-xl">{t('trial_expired_description')}</div>
                <div className="trial-modal__highlights stack-xl">
                    <div className="stack-m">
                        <StdIcon
                            className="trial-modal__mark inline-s"
                            name="check-circle"
                            prefix="fas"
                            small
                        />
                        <span>{t('subscription_point_1')}</span>
                    </div>
                    <div className="stack-m">
                        <StdIcon
                            className="trial-modal__mark inline-s"
                            name="check-circle"
                            prefix="fas"
                            small
                        />
                        <span>{t('subscription_point_2')}</span>
                    </div>
                    <div>
                        <StdIcon
                            className="trial-modal__mark inline-s"
                            name="check-circle"
                            prefix="fas"
                            small
                        />
                        <span>{t('subscription_point_3')}</span>
                    </div>
                </div>
                <div className="trial-modal__actions">
                    <div>
                        <StdButton
                            type="primary"
                            className="stack-m"
                            onClick={() => setSelectOpen(true)}
                        >
                            {t('subscribe_text').toUpperCase()}
                        </StdButton>
                        <div className="stack-m">{t('or')}</div>
                        <StdButton onClick={() => setFeedbackOpen(true)}>
                            {t('cancel_text').toUpperCase()}
                        </StdButton>
                    </div>
                    <div className="trial-modal__logout">
                        <StdIcon name="sign-out" onClick={onLogout} clickable />
                    </div>
                </div>
            </div>
            <StdOverlay open={feedbackOpen}>
                <TrialCancelFeedback onBack={() => setFeedbackOpen(false)} />
            </StdOverlay>
            <TrialSubscribeFlow
                open={selectOpen}
                onClose={() => setSelectOpen(false)}
            />
        </StdModal>
    );
};

export default TrialExpiredModal;
