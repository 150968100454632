import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'validator';
import { useDispatch } from 'react-redux';

import { dispatchAsync } from '../../../store/storeModule';
import { setPassword, showSnackbar } from '../../../store/action-creators';
import { validatePassword, validate } from '../../../utils/validation';
import useInput from '../../../hooks/useInput';
import useValidation from '../../../hooks/useValidation';

import StdModal from '../../atoms/StdModal';
import StdTextField from '../../atoms/StdTextField';
import StdIcon from '../../atoms/StdIcon';
import StdButton from '../../atoms/StdButton';
import CustomIcon from '../../atoms/CustomIcon';
import ModalHeader from '../../atoms/ModalHeader';
import clsx from 'clsx';

interface IForm {
    form: {
        old_password: string;
        new_password: string;
    };
    errors: IErrorHash;
}

const initForm: IForm = {
    form: {
        old_password: '',
        new_password: ''
    },
    errors: {}
};

interface IProps extends IModalBase {
    isHeader?: boolean;
}

const ChangePasswordModal: React.FC<IProps> = ({ open, onClose, isHeader }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [hideOldPassword, setHideOldPassword] = useState(true);
    const [hideNewPassword, setHideNewPassword] = useState(true);
    const [input, setInput] = useState(initForm);
    const handleInput = useInput(setInput);

    const validateFields = useValidation(input, setInput, (hash) => {
        const { old_password, new_password } = input.form;
        const [passValid, passMessage] = validatePassword(new_password);

        return [
            validate({ old_password }, !isEmpty(old_password), hash),
            validate({ new_password }, passValid, hash, passMessage)
        ].every(Boolean);
    });

    const handleChangePassword = () => {
        const { old_password, new_password } = input.form;

        dispatchAsync(
            dispatch,
            setPassword({ current_password: old_password, new_password })
        )
            .then((_) =>
                dispatch(
                    showSnackbar({
                        message: 'set_password_success',
                        snackType: 'info'
                    })
                )
            )
            .catch(({ error }) =>
                dispatch(showSnackbar({ message: 'set_password_fail', error }))
            );
        onClose();
    };

    const handleSubmit = () => {
        validateFields() && handleChangePassword();
    };

    const { form, errors } = input;
    return (
        <StdModal
            open={open}
            onClose={onClose}
            onExited={() => setInput(initForm)}
            contentClassName={isHeader ? 'profilePageModal' : ''}
        >
            <ModalHeader
                className='profilePageHeader'
                leftNode={
                    <span className="std-space-nowrap">
                        {t('change_password_title')}
                    </span>
                }
                rightNode={<CustomIcon name="cross" onClick={onClose} ripple />}
            />
            <div className="modal__section std-pt-25">
                <StdTextField
                    className="stack-l"
                    label={isHeader ? t('old_password_msg') : t('old_password')}
                    name="old_password"
                    type={hideOldPassword ? 'password' : 'text'}
                    value={form.old_password}
                    error={errors['old_password']}
                    width="100%"
                    onChange={handleInput}
                    trailingIcon={{
                        icon: (
                            <StdIcon
                                prefix="far"
                                name={hideOldPassword ? 'eye-slash' : 'eye'}
                            />
                        ),
                        onClick: () => setHideOldPassword((s) => !s)
                    }}
                />
                <StdTextField
                    label={isHeader ? t('new_password_msg') : t('new_password')}
                    name="new_password"
                    type={hideNewPassword ? 'password' : 'text'}
                    value={form.new_password}
                    error={errors['new_password']}
                    width="100%"
                    onChange={handleInput}
                    trailingIcon={{
                        icon: (
                            <StdIcon
                                prefix="far"
                                name={hideNewPassword ? 'eye-slash' : 'eye'}
                            />
                        ),
                        onClick: () => setHideNewPassword((s) => !s)
                    }}
                    helpText={{
                        persistent: true,
                        children: t(errors['new_password']),
                        validationMsg: true
                    }}
                    autoComplete="new-password"
                />
                <div
                    className={clsx(
                        'button-wrapper std-flex-between',
                        errors['new_password'] ? 'std-mt-25' : 'std-mt-5',
                        isHeader ? 'profileModalGrpBtn' : ''
                    )}
                >
                    <StdButton
                        className={isHeader ? 'profileModalBtn' : ''}
                        width="120px"
                        onClick={onClose}>
                        {t('cancel')}
                    </StdButton>
                    <StdButton
                        className={isHeader ? 'profileModalBtn' : ''}
                        width={'120px'}
                        type="primary"
                        onClick={handleSubmit}
                    >
                        {t('save')}
                    </StdButton>
                </div>
            </div>
        </StdModal>
    );
};

export default ChangePasswordModal;
