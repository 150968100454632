import React, { useState, useEffect, useCallback, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { AnyAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'validator';

import { validate } from '../../../utils/validation';
import { dispatchAll } from '../../../store/storeModule';
import { getAttrHashKeys } from '../../../utils/attributes';
import { getRoomFloors } from '../../../modules/property';

import { MEDIA_ATTACHMENT_TYPE, isHouseType } from '../../../configs/costants';
import { ROOM_ATTRIBUTE_TYPE } from '../../../configs/basicData';
import {
    patchRoom,
    showSnackbar,
    roomAttrCrud
} from '../../../store/action-creators';

import { levelToFloor } from '../../../modules/rooms';
import { intOrElse } from '../../../utils';

import useInput from '../../../hooks/useInput';
import useValidation from '../../../hooks/useValidation';
import useAttributeList from '../../../hooks/useAttributeList';
import useReactScrollTo from '../../../hooks/useReactScrollTo';
import useUploader from '../../../hooks/useUploader';

import { MenuItem } from '@rmwc/menu';

import StdModal from '../../atoms/StdModal';
import TextField from '../../atoms/StdTextField';
import StdDivider from '../../atoms/StdDivider';
import AttachmentGallery from '../../molecules/AttachmentGallery';
import EditAttributeList from '../EditAttributeList';
import Select from '../../atoms/StdSelect';
import EditModalHeader from '../../atoms/EditModalHeader';

interface IProps {
    open: boolean;
    object: IDpd;
    room?: IRoom;
    toAttr?: boolean;
    toAttach?: boolean;
    onSubmit: ICallback;
    onClose: ICallback;
}

interface IForm {
    form: {
        name: string;
        floor: string;
    };
    errors: IErrorHash;
}

const initForm: IForm = {
    form: {
        name: '',
        floor: ''
    },
    errors: {}
};

const EditRoomDetailsModal: React.FC<IProps> = ({
    open,
    room,
    object,
    toAttr,
    toAttach,
    onClose,
    onSubmit
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [input, setInput] = useState(initForm);
    const handleInput = useInput(setInput);

    const lookupHash = useSelector((state) => state.basicData.response);

    const attachUploader = useUploader();

    const attrListProps = useAttributeList();
    const { attrState, resetAttrs, validateAttrs } = attrListProps;

    const attrData = lookupHash?.[ROOM_ATTRIBUTE_TYPE];

    const attrRef = useReactScrollTo(toAttr);
    const attachRef = useReactScrollTo(toAttach);

    const validateFields = useValidation(input, setInput, (hash) => {
        const { name, floor } = input.form;

        const attrRes = validateAttrs();
        return [
            validate({ name }, !isEmpty(name), hash),
            validate({ floor }, !isEmpty(floor), hash),
            ...attrRes
        ].every(Boolean);
    });

    const resetInput = useCallback(() => {
        if (room) {
            const { rom_name, rom_level, rom_roa_id, uploads = [] } = room;

            const roomAttrs = Array.isArray(rom_roa_id) ? rom_roa_id : [];

            const hashForm: IHash<string> = {};
            const attrIds = roomAttrs.map((roomAttr) => {
                const { roa_id, roa_type, roa_value } = roomAttr;
                const idLiteral = { id: roa_id };

                const { typeKey, valueKey } = getAttrHashKeys(idLiteral);

                hashForm[typeKey] = roa_type || '';
                hashForm[valueKey] = roa_value || '';

                return idLiteral;
            });

            const level = intOrElse(rom_level, 1);
            setInput((s) => ({
                ...s,
                form: {
                    type: '',
                    name: rom_name || '',
                    floor: level.toString()
                }
            }));

            resetAttrs(attrIds, hashForm);

            attachUploader.setPartialUploads(
                uploads,
                (upload) => upload.type === MEDIA_ATTACHMENT_TYPE
            );
        } else {
            setInput(initForm);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [room, resetAttrs]);

    useEffect(() => {
        resetInput();
    }, [resetInput]);

    const makeRequest = () => {
        if (room) {
            const { name, floor } = input.form;
            const { attrIds, hashForm, dirtyAttr } = attrState;

            const roomLiteral = {
                rom_dpd_id: object.dpd_id,
                rom_name: name,
                rom_level: parseInt(floor),
                media_med_id: attachUploader.ids
            };

            const context = attrIds.map((attrId) => {
                const { typeKey, valueKey } = getAttrHashKeys(attrId);
                return {
                    roa_type: hashForm[typeKey],
                    roa_value: hashForm[valueKey]
                };
            });

            const actions: AnyAction[] = [];
            actions.push(patchRoom(room.rom_id, roomLiteral));

            if (dirtyAttr)
                actions.push(
                    roomAttrCrud({
                        roa_rom_id: room.rom_id,
                        roa_state: context
                    })
                );

            dispatchAll(dispatch, actions)
                .then(attachUploader.submitChanges)
                .then(onSubmit)
                .catch(({ error }) =>
                    dispatch(
                        showSnackbar({
                            message: 'room_edit_fail_message',
                            error
                        })
                    )
                );
            handleClose();
        }
    };

    const hanldeSubmit = () => {
        validateFields() && makeRequest();
    };

    const cleanup = () => {
        setInput(initForm);
        resetInput();
    };

    const handleClose = () => {
        onClose();
        cleanup();
    };

    const floorRooms = getRoomFloors(object);

    const objectIsHouse = isHouseType(object.dpd_type || '');

    const { form, errors } = input;
    return (
        <StdModal open={open} onClose={handleClose} small>
            <EditModalHeader
                title={t('edit_room')}
                submitLabel={t('save')}
                onSubmit={hanldeSubmit}
                onClose={handleClose}
            />
            <div className="modal__section modal__section--slim">
                <TextField
                    className="stack-m"
                    label={t('name')}
                    name="name"
                    value={form.name}
                    error={errors['name']}
                    onChange={handleInput}
                    width="100%"
                    required
                />
                {objectIsHouse && 
                    <Select
                        label={t('level')}
                        name="floor"
                        value={form.floor}
                        error={errors['floor']}
                        onChange={handleInput}
                        width="100%"
                        required
                    >
                        {floorRooms.map((floor) => {
                            return (
                                <MenuItem key={floor} data-value={floor}>
                                    {levelToFloor(floor)}
                                </MenuItem>
                            );
                        })}
                    </Select>
                }
            </div>
            <StdDivider />
            <div className="modal__section modal__section--slim" ref={attrRef}>
                <div className="modal__heading stack-l">
                    {t('description').toUpperCase()}
                </div>
                <EditAttributeList
                    {...attrListProps}
                    {...attrState}
                    attrData={attrData}
                />
            </div>
            <StdDivider />
            <div
                className="modal__section modal__section--slim"
                ref={attachRef}
            >
                <AttachmentGallery {...attachUploader} />
            </div>
        </StdModal>
    );
};

export default memo(
    EditRoomDetailsModal,
    (prevProps, nextProps) => prevProps.open === nextProps.open
);
