export const ROOT_PATH = '/';
export const ROOT_ROUTE = '/';

export const DASHBOARD_PATH = ROOT_PATH + 'dpd'; //'/dpd';
export const DASHBOARD_ROUTE = DASHBOARD_PATH;

export const DPD_PATH = DASHBOARD_PATH + '/:id(\\d+)/:subdpd_id(\\d+)?';
export const ONE_DPD = (id: number, subDpdId?: number) =>
    `${DASHBOARD_PATH}/${id}` + (subDpdId ? `/${subDpdId}` : '');

export const PROTOCOL_PATH = '/protocol/:protocol_id(\\d+)';
export const PROTOCOL_ROUTE = (protoId: number) =>
    ROOT_PATH + `protocol/${protoId}`;

export const PROFILE_PATH = '/profile';
export const PROFILE_ROUTE = (section?: string) =>
    `/profile${section != null ? '/?section='.concat(section) : ''}`;


// Partly public

export const SHARED_ISSUE_PATH = '/share/issue/:token';


export const PARTNER_PROTOCOL_PATH = '/partner_miop/:protocolToken/:authToken';

export const MAGIC_LOGIN_PATH = '/magic_login';
export const MAGIC_LOGIN_ROUTE = MAGIC_LOGIN_PATH;

export const ACCEPT_INVITE_PATH = '/accept_invite';
export const ACCEPT_INVITE_ROUTE = ACCEPT_INVITE_PATH;

// public
export const PASSWORD_RESET_PATH = '/reset_password';
export const PASSWORD_RESET_ROUTE = PASSWORD_RESET_PATH;

export const REGISTRATION_PATH = '/register';
export const REGISTRATION_ROUTE = REGISTRATION_PATH;

export const ACTIVATION_PATH = '/account-activation';
export const ACTIVATION_ROUTE = ACTIVATION_PATH;

export const RESET_CONFIRMATION_PATH = '/confirm_reset';
export const RESET_CONFIRMATION_ROUTE = RESET_CONFIRMATION_PATH;

export const PROTOCOL_TRACKER_PATH = '/miop_pdf';
export const PROTOCOL_TRACKER_ROUTE = RESET_CONFIRMATION_PATH;

export const NOT_FOUND_ERROR_PATH = '/404';
export const NOT_FOUND_ERROR_ROUTE = NOT_FOUND_ERROR_PATH;

export const SERVER_ERROR_PATH = '/503';
export const SERVER_ERROR_ROUTE = SERVER_ERROR_PATH;
