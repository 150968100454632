import React from 'react';
import StdIcon from '../StdIcon';

import './media-count.scss';

interface IProps {
    count?: number;
}

const MediaCount: React.FC<IProps> = ({ count }) => {
    return (
        <div className="media-count">
            {Boolean(count) && <span className="inline-xs">{count}</span>}
            <StdIcon name="photo-video" />
        </div>
    );
};

export default MediaCount;
