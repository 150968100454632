import React, { useState, useEffect, useCallback, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'validator';
import { useHistory } from 'react-router';

import { intOrElse, justArray } from '../../../utils';
import { getRoomFloors } from '../../../modules/property';
import { levelToFloor } from '../../../modules/rooms';
import { validate } from '../../../utils/validation';

import { dispatchAsync } from '../../../store/storeModule';
import {
    postRoom,
    patchRoom,
    showSnackbar
} from '../../../store/action-creators';

import useInput from '../../../hooks/useInput';
import useValidation from '../../../hooks/useValidation';

import { MenuItem } from '@rmwc/menu';
import StdModal from '../../atoms/StdModal';
import TextField from '../../atoms/StdTextField';
import Select from '../../atoms/StdSelect';
import ObjectEditModal from '../../atoms/ObjectEditModal/ObjectEditModal';
import StdButton from '../../atoms/StdButton';

interface IProps extends IModalBase {
    object: IDpd;
    room?: Partial<IRoom>;
    onSuccess?: ICallback;
}

interface IForm {
    form: {
        type: string;
        name: string;
        floor: string;
    };
    errors: IErrorHash;
}

const initForm: IForm = {
    form: {
        type: '',
        name: '',
        floor: ''
    },
    errors: {}
};

const EditRoomModal: React.FC<IProps> = ({
    open,
    room,
    object,
    onSuccess,
    onClose
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const history = useHistory();

    const [input, setInput] = useState(initForm);
    const handleInput = useInput(setInput);

    const roomId = room?.rom_id;
    const isEditing = roomId != null;

    const templateRes = useSelector((state) => state.inventory.roomTemplates);
    const templates = justArray(templateRes.response.results);

    const validateFields = useValidation(input, setInput, (hash) => {
        const { type, name, floor } = input.form;
        return [
            isEditing || validate({ type }, !isEmpty(type), hash),
            validate({ name }, !isEmpty(name), hash),
            validate({ floor }, !isEmpty(floor), hash) &&
                validate({ floor }, !isNaN(parseInt(floor)), hash)
        ].every(Boolean);
    });

    const resetInput = useCallback(() => {
        if (room) {
            const { rom_name, rom_type, rom_level } = room;
            const level = intOrElse(rom_level, 1);

            setInput((s) => ({
                ...s,
                form: {
                    type: rom_type || '',
                    name: rom_name || '',
                    floor: level.toString()
                }
            }));
        } else {
            setInput(initForm);
        }
    }, [room]);

    useEffect(() => {
        if (open) resetInput();
    }, [open, resetInput]);

    const makeRequest = () => {
        const { type, name, floor } = input.form;
        const template = templates.find((template) => template.name === type);

        const roomLiteral = {
            template_name: template?.pk,
            rom_dpd_id: object.dpd_id,
            rom_name: name,
            rom_type: template?.type_for_icon,
            rom_level: parseInt(floor)
        };

        const modAction =
            roomId != null
                ? patchRoom(roomId, roomLiteral)
                : postRoom(roomLiteral);

        dispatchAsync(dispatch, modAction)
            .then((action: any) => {
                if (!isEditing) {
                    const createdRoomId = action.payload.rom_id;
                    if (createdRoomId != null)
                        history.push({ search: `?room=${createdRoomId}` });
                }
                onSuccess?.();
            })
            .catch(({ error }) =>
                dispatch(
                    showSnackbar({
                        message: isEditing
                            ? 'room_edit_fail_message'
                            : 'room_add_fail_message',
                        error
                    })
                )
            );
        onClose();
    };

    const handleSubmit = () => {
        if (validateFields()) makeRequest();
    };

    const roomFloors = getRoomFloors(object);

    const modalTitle = isEditing ? t('edit_room') : t('add_room');
    const { form, errors } = input;
    return (
        <StdModal open={open} onClose={onClose} contentClassName={'profilePageModal'}>
             <ObjectEditModal  
                className='profilePageHeader'
                title={modalTitle}
                onClose={onClose}
             />
            <div className="stack-s" />
            <div className="modal__section">
                {!isEditing && (
                    <Select
                        className="stack-l"
                        label={t('room_types')}
                        name="type"
                        value={form.type}
                        error={errors['type']}
                        onChange={handleInput}
                        width="100%"
                        required
                    >
                        {templates.map(({ name }) => {
                            return (
                                <MenuItem key={name} data-value={name}>
                                    {name}
                                </MenuItem>
                            );
                        })}
                        <MenuItem data-value="custom">
                            {t('rooms.custom')}
                        </MenuItem>
                    </Select>
                )}
                <TextField
                    className="stack-l"
                    label={t('room_name')}
                    name="name"
                    value={input.form.name}
                    error={errors['name']}
                    onChange={handleInput}
                    width="100%"
                    required
                />
                <Select
                    label={t('level')}
                    name="floor"
                    value={form.floor}
                    error={errors['floor']}
                    onChange={handleInput}
                    width="100%"
                    required
                >
                    {roomFloors.map((floor) => {
                        return (
                            <MenuItem key={floor} data-value={floor}>
                                {levelToFloor(floor)}
                            </MenuItem>
                        );
                    })}
                </Select>
                <div className='modal__section__saveButton'>
                    <StdButton type="primary"
                        className='modal__section__add_roomBtn'
                        onClick={handleSubmit}>
                            {t('save')}
                    </StdButton>
                </div>
            </div>
        </StdModal>
    );
};

export default memo(
    EditRoomModal,
    (prevProps, nextProps) => prevProps.open === nextProps.open
);
