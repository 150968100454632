export const GET_PROTOCOLS_REQUEST = 'GET_PROTOCOLS_REQUEST';
export const GET_PROTOCOLS_SUCCESS = 'GET_PROTOCOLS_SUCCESS';
export const GET_PROTOCOLS_FAIL = 'GET_PROTOCOLS_FAIL';

export const GET_PROTOCOL_REQUEST = 'GET_PROTOCOL_REQUEST'; 
export const GET_PROTOCOL_SUCCESS = 'GET_PROTOCOL_SUCCESS';
export const GET_PROTOCOL_FAIL = 'GET_PROTOCOL_FAIL';

export const GET_PROTOCOL_FOR_LATEST_DATA_REQUEST = 'GET_PROTOCOL_FOR_LATEST_DATA_REQUEST'; 
export const GET_PROTOCOL_FOR_LATEST_DATA_SUCCESS = 'GET_PROTOCOL_FOR_LATEST_DATA_SUCCESS';
export const GET_PROTOCOL_FOR_LATEST_DATA_FAIL = 'GET_PROTOCOL_FOR_LATEST_DATA_FAIL';

export const POST_PROTOCOL_REQUEST = 'POST_PROTOCOL_REQUEST';
export const POST_PROTOCOL_SUCCESS = 'POST_PROTOCOL_SUCCESS';
export const POST_PROTOCOL_FAIL = 'POST_PROTOCOL_FAIL';

export const PATCH_PROTOCOL_REQUEST = 'PATCH_PROTOCOL_REQUEST';
export const PATCH_PROTOCOL_SUCCESS = 'PATCH_PROTOCOL_SUCCESS';
export const PATCH_PROTOCOL_FAIL = 'PATCH_PROTOCOL_FAIL';

export const DELETE_PROTOCOL_REQUEST = 'DELETE_PROTOCOL_REQUEST';
export const DELETE_PROTOCOL_SUCCESS = 'DELETE_PROTOCOL_SUCCESS';
export const DELETE_PROTOCOL_FAIL = 'DELETE_PROTOCOL_FAIL';

export const GET_PROTOCOL_ROOMS_REQUEST = 'GET_PROTOCOL_ROOMS_REQUEST';
export const GET_PROTOCOL_ROOMS_SUCCESS = 'GET_PROTOCOL_ROOMS_SUCCESS';
export const GET_PROTOCOL_ROOMS_FAIL = 'GET_PROTOCOL_ROOMS_FAIL';

export const POST_PROTOCOL_ROOM_REQUEST = 'POST_PROTOCOL_ROOM_REQUEST';
export const POST_PROTOCOL_ROOM_SUCCESS = 'POST_PROTOCOL_ROOM_SUCCESS';
export const POST_PROTOCOL_ROOM_FAIL = 'POST_PROTOCOL_ROOM_FAIL';

export const PATCH_PROTOCOL_ROOM_REQUEST = 'PATCH_PROTOCOL_ROOM_REQUEST';
export const PATCH_PROTOCOL_ROOM_SUCCESS = 'PATCH_PROTOCOL_ROOM_SUCCESS';
export const PATCH_PROTOCOL_ROOM_FAIL = 'PATCH_PROTOCOL_ROOM_FAIL';

export const DELETE_PROTOCOL_ROOM_REQUEST = 'DELETE_PROTOCOL_ROOM_REQUEST';
export const DELETE_PROTOCOL_ROOM_SUCCESS = 'DELETE_PROTOCOL_ROOM_SUCCESS';
export const DELETE_PROTOCOL_ROOM_FAIL = 'DELETE_PROTOCOL_ROOM_FAIL';

export const GET_PROTOCOL_ELEMENTS_REQUEST = 'GET_PROTOCOL_ELEMENTS_REQUEST';
export const GET_PROTOCOL_ELEMENTS_SUCCESS = 'GET_PROTOCOL_ELEMENTS_SUCCESS';
export const GET_PROTOCOL_ELEMENTS_FAIL = 'GET_PROTOCOL_ELEMENTS_FAIL';

export const POST_PROTOCOL_ELEMENT_REQUEST = 'POST_PROTOCOL_ELEMENT_REQUEST';
export const POST_PROTOCOL_ELEMENT_SUCCESS = 'POST_PROTOCOL_ELEMENT_SUCCESS';
export const POST_PROTOCOL_ELEMENT_FAIL = 'POST_PROTOCOL_ELEMENT_FAIL';

export const PATCH_PROTOCOL_ELEMENT_REQUEST = 'PATCH_PROTOCOL_ELEMENT_REQUEST';
export const PATCH_PROTOCOL_ELEMENT_SUCCESS = 'PATCH_PROTOCOL_ELEMENT_SUCCESS';
export const PATCH_PROTOCOL_ELEMENT_FAIL = 'PATCH_PROTOCOL_ELEMENT_FAIL';

export const DELETE_PROTOCOL_ELEMENT_REQUEST =
    'DELETE_PROTOCOL_ELEMENT_REQUEST';
export const DELETE_PROTOCOL_ELEMENT_SUCCESS =
    'DELETE_PROTOCOL_ELEMENT_SUCCESS';
export const DELETE_PROTOCOL_ELEMENT_FAIL = 'DELETE_PROTOCOL_ELEMENT_FAIL';

export const POST_PROTOCOL_ELEMENT_ATTRIBUTES_REQUEST =
    'POST_PROTOCOL_ELEMENT_ATTRIBUTES_REQUEST';
export const POST_PROTOCOL_ELEMENT_ATTRIBUTES_SUCCESS =
    'POST_PROTOCOL_ELEMENT_ATTRIBUTES_SUCCESS';
export const POST_PROTOCOL_ELEMENT_ATTRIBUTES_FAIL =
    'POST_PROTOCOL_ELEMENT_ATTRIBUTES_FAIL';

// --- Unused
export const GET_ITEMS_REQUEST = 'GET_ITEMS_REQUEST';
export const GET_ITEMS_SUCCESS = 'GET_ITEMS_SUCCESS';
export const GET_ITEMS_FAIL = 'GET_ITEMS_FAIL';

export const POST_ITEM_REQUEST = 'POST_ITEM_REQUEST';
export const POST_ITEM_SUCCESS = 'POST_ITEM_SUCCESS';
export const POST_ITEM_FAIL = 'POST_ITEM_FAIL';

export const PATCH_ITEM_REQUEST = 'PATCH_ITEM_REQUEST';
export const PATCH_ITEM_SUCCESS = 'PATCH_ITEM_SUCCESS';
export const PATCH_ITEM_FAIL = 'PATCH_ITEM_FAIL';

export const DEL_ITEM_REQUEST = 'DEL_ITEM_REQUEST';
export const DEL_ITEM_SUCCESS = 'DEL_ITEM_SUCCESS';
export const DEL_ITEM_FAIL = 'DEL_ITEM_FAIL';

export const ITEM_ATTR_CRUD_REQUEST = 'ITEM_ATTR_CRUD_REQUEST';
export const ITEM_ATTR_CURD_SUCCESS = 'ITEM_ATTR_CRUD_SUCCESS';
export const ITEM_ATTR_CRUD_FAIL = 'ITEM_ATTR_CRUD_FAIL';
// ---

export const GET_PROTOCOL_ITEMS_REQUEST = 'GET_PROTOCOL_ITEMS_REQUEST';
export const GET_PROTOCOL_ITEMS_SUCCESS = 'GET_PROTOCOL_ITEMS_SUCCESS';
export const GET_PROTOCOL_ITEMS_FAIL = 'GET_PROTOCOL_ITEMS_FAIL';

export const POST_PROTOCOL_ITEM_REQUEST = 'POST_PROTOCOL_ITEM_REQUEST';
export const POST_PROTOCOL_ITEM_SUCCESS = 'POST_PROTOCOL_ITEM_SUCCESS';
export const POST_PROTOCOL_ITEM_FAIL = 'POST_PROTOCOL_ITEM_FAIL';

export const PATCH_PROTOCOL_ITEM_REQUEST = 'PATCH_PROTOCOL_ITEM_REQUEST';
export const PATCH_PROTOCOL_ITEM_SUCCESS = 'PATCH_PROTOCOL_ITEM_SUCCESS';
export const PATCH_PROTOCOL_ITEM_FAIL = 'PATCH_PROTOCOL_ITEM_FAIL';

export const DELETE_PROTOCOL_ITEM_REQUEST = 'DELETE_PROTOCOL_ITEM_REQUEST';
export const DELETE_PROTOCOL_ITEM_SUCCESS = 'DELETE_PROTOCOL_ITEM_SUCCESS';
export const DELETE_PROTOCOL_ITEM_FAIL = 'DELETE_PROTOCOL_ITEM_FAIL';

export const POST_PROTOCOL_ITEM_ATTRIBUTES_REQUEST =
    'POST_PROTOCOL_ITEM_ATTRIBUTES_REQUEST';
export const POST_PROTOCOL_ITEM_ATTRIBUTES_SUCCESS =
    'POST_PROTOCOL_ITEM_ATTRIBUTES_SUCCESS';
export const POST_PROTOCOL_ITEM_ATTRIBUTES_FAIL =
    'POST_PROTOCOL_ITEM_ATTRIBUTES_FAIL';

export const GET_ATTENDEES_REQUEST = 'GET_ATTENDEES_REQUEST';
export const GET_ATTENDEES_SUCCESS = 'GET_ATTENDEES_SUCCESS';
export const GET_ATTENDEES_FAIL = 'GET_ATTENDEES_FAIL';

export const POST_ATTENDEE_REQUEST = 'POST_ATTENDEE_REQUEST';
export const POST_ATTENDEE_SUCCESS = 'POST_ATTENDEE_SUCCESS';
export const POST_ATTENDEE_FAIL = 'POST_ATTENDEE_FAIL';

export const PATCH_ATTENDEE_REQUEST = 'PATCH_ATTENDEE_REQUEST';
export const PATCH_ATTENDEE_SUCCESS = 'PATCH_ATTENDEE_SUCCESS';
export const PATCH_ATTENDEE_FAIL = 'PATCH_ATTENDEE_FAIL';

export const DEL_ATTENDEE_REQUEST = 'DEL_ATTENDEE_REQUEST';
export const DEL_ATTENDEE_SUCCESS = 'DEL_ATTENDEE_SUCCESS';
export const DEL_ATTENDEE_FAIL = 'DEL_ATTENDEE_FAIL';

export const POST_SIGNATURE_REQUEST = 'POST_SIGNATURE_REQUEST';
export const POST_SIGNATURE_SUCCESS = 'POST_SIGNATURE_SUCCESS';
export const POST_SIGNATURE_FAIL = 'POST_SIGNATURE_FAIL';

export const DELETE_SIGNATURE_REQUEST = 'DELETE_SIGNATURE_REQUEST';
export const DELETE_SIGNATURE_SUCCESS = 'DELETE_SIGNATURE_SUCCESS';
export const DELETE_SIGNATURE_FAIL = 'DELETE_SIGNATURE_FAIL';

export const FINISH_PROTOCOL_REQUEST = 'FINISH_PROTOCOL_REQUEST';
export const FINISH_PROTOCOL_SUCCESS = 'FINISH_PROTOCOL_SUCCESS';
export const FINISH_PROTOCOL_FAIL = 'FINISH_PROTOCOL_FAIL';

export const CHECK_PDF_READY_REQUEST = 'CHECK_PDF_READY_REQUEST';
export const CHECK_PDF_READY_SUCCESS = 'CHECK_PDF_READY_SUCCESS';
export const CHECK_PDF_READY_FAIL = 'CHECK_PDF_READY_FAIL';

export const PREVIEW_PDF_READY_REQUEST = 'PREVIEW_PDF_READY_REQUEST';
export const PREVIEW_PDF_READY_SUCCESS = 'PREVIEW_PDF_READY_SUCCESS';
export const PREVIEW_PDF_READY_FAIL = 'PREVIEW_PDF_READY_FAIL';

export const GENERATE_PDF_REQUEST = 'GENERATE_PDF_REQUEST';
export const GENERATE_PDF_SUCCESS = 'GENERATE_PDF_SUCCESS';
export const GENERATE_PDF_FAIL = 'GENERATE_PDF_FAIL';

export const GET_ITEM_TEMPLATES_REQUEST = 'GET_ITEM_TEMPLATES_REQUEST';
export const GET_ITEM_TEMPLATES_SUCCESS = 'GET_ITEM_TEMPLATES_SUCCESS';
export const GET_ITEM_TEMPLATES_FAIL = 'GET_ITEM_TEMPLATES_FAIL';

export const AUTHORIZE_PARTNER_PROTOCOL_REQUEST =
    'AUTHORIZE_PARTNER_PROTOCOL_REQUEST';
export const AUTHORIZE_PARTNER_PROTOCOL_SUCCESS =
    'AUTHORIZE_PARTNER_PROTOCOL_SUCCESS';
export const AUTHORIZE_PARTNER_PROTOCOL_FAIL =
    'AUTHORIZE_PARTNER_PROTOCOL_FAIL';

export const PATCH_PARTNER_PROTOCOL_REQUEST = 'PATCH_PARTNER_PROTOCOL_REQUEST';
export const PATCH_PARTNER_PROTOCOL_SUCCESS = 'PATCH_PARTNER_PROTOCOL_SUCCESS';
export const PATCH_PARTNER_PROTOCOL_FAIL = 'PATCH_PARTNER_PROTOCOL_FAIL';

export const GET_PROTOCOL_ROOM_REQUEST = 'GET_PROTOCOL_ROOM_REQUEST';
export const GET_PROTOCOL_ROOM_SUCCESS = 'GET_PROTOCOL_ROOM_SUCCESS';
export const GET_PROTOCOL_ROOM_FAIL = 'GET_PROTOCOL_ROOM_FAIL';

export const GET_PROTOCOL_ELEMENT_REQUEST = 'GET_PROTOCOL_ELEMENT_REQUEST';
export const GET_PROTOCOL_ELEMENT_SUCCESS = 'GET_PROTOCOL_ELEMENT_SUCCESS';
export const GET_PROTOCOL_ELEMENT_FAIL = 'GET_PROTOCOL_ELEMENT_FAIL';

export const GET_PROTOCOL_ITEM_REQUEST = 'GET_PROTOCOL_ITEM_REQUEST';
export const GET_PROTOCOL_ITEM_SUCCESS = 'GET_PROTOCOL_ITEM_SUCCESS';
export const GET_PROTOCOL_ITEM_FAIL = 'GET_PROTOCOL_ITEM_FAIL';

export const GET_ATTENDEE_REQUEST = 'GET_ATTENDEE_REQUEST';
export const GET_ATTENDEE_SUCCESS = 'GET_ATTENDEE_SUCCESS';
export const GET_ATTENDEE_FAIL = 'GET_ATTENDEE_FAIL';

export const CREATE_LOCAL_PROTOCOL_ELEMENT = 'CREATE_LOCAL_PROTOCOL_ELEMENT';
export const PREDICT_PROTOCOL_ITEM_ATTRIBUTES =
    'PREDICT_PROTOCOL_ITEM_ATTRIBUTES';

export const PREDICT_ATTENDEE = 'PREDICT_ATTENDEE';
export const PREDICT_PROTO_ROOM = 'PREDICT_PROTO_ROOM';
export const PREDICT_PROTO_ELE = 'PREDICT_PROTO_ELE';
export const PREDICT_PROTOCOL_ELEMENTS = 'PREDICT_PROTOCOL_ELEMENTS';
export const PREDICT_PROTOCOL_ROOMS = 'PREDICT_PROTOCOL_ROOMS';
export const PREDICT_PROTO_ITEM = 'PREDICT_PROTO_ITEM';
export const PREDICT_CURRNET_PROTOCOL = 'PREDICT_CURRNET_PROTOCOL';
export const PREDICT_CURRENT_PROTOCOL_DPD = 'PREDICT_CURRENT_PROTOCOL_DPD';

export const SET_HEADER_PROTOCOL_PREVIEW = 'SET_HEADER_PROTOCOL_PREVIEW';
export const PREDICT_PROTOCOL_ELEMENTS_ORDER =
    'PREDICT_PROTOCOL_ELEMENTS_ORDER';

export const PREDICT_PROTOCOL_ROOMS_ORDER = 'PREDICT_PROTOCOL_ROOMS_ORDER';
export const UPDATE_PROTOCOL_ROOM_ELEMENTS = 'UPDATE_PROTOCOL_ROOM_ELEMENTS';

export const POST_PROTOCOL_ISSUE_REQUEST = 'POST_PROTOCOL_ISSUE_REQUEST';

export const PATCH_PROTOCOL_ISSUE_REQUEST = 'PATCH_PROTOCOL_ISSUE_REQUEST';

export const DELETE_PROTOCOL_ISSUE_REQUEST = 'DELETE_PROTOCOL_ISSUE_REQUEST';


export const POST_PROTOCOL_ISSUE_RESPONSE = 'POST_PROTOCOL_ISSUE_RESPONSE';

export const PATCH_PROTOCOL_ISSUE_RESPONSE = 'PATCH_PROTOCOL_ISSUE_RESPONSE';

export const DELETE_PROTOCOL_ISSUE_SUCCESS = 'DELETE_PROTOCOL_ISSUE_SUCCESS';

export const DELETE_PROTOCOL_ISSUE_FAIL = 'DELETE_PROTOCOL_ISSUE_FAIL';

export const SAVE_PROTOCOL_ADDITIONAL_NOTES_REQUEST = "SAVE_PROTOCOL_ADDITIONAL_NOTES_REQUEST";
export const SAVE_PROTOCOL_ADDITIONAL_NOTES_SUCCESS = "SAVE_PROTOCOL_ADDITIONAL_NOTES_SUCCESS";
export const SAVE_PROTOCOL_ADDITIONAL_NOTES_FAIL = "SAVE_PROTOCOL_ADDITIONAL_NOTES_FAIL";

export const PROTOCOL_TRACKER_VIEW_PDF_REQUEST = "PROTOCOL_TRACKER_VIEW_PDF_REQUEST";
export const PROTOCOL_TRACKER_VIEW_PDF_SUCCESS = "PROTOCOL_TRACKER_VIEW_PDF_SUCCESS";
export const PROTOCOL_TRACKER_VIEW_PDF_FAIL = "PROTOCOL_TRACKER_VIEW_PDF_FAIL";

export const RESEND_ATTENDEE_EMAIL = "RESEND_ATTENDEE_EMAIL";
export const RESEND_ATTENDEE_EMAIL_SUCCESS = "RESEND_ATTENDEE_EMAIL_SUCCESS";
export const RESEND_ATTENDEE_EMAIL_FAIL = "RESEND_ATTENDEE_EMAIL_FAIL";
