import { takeEvery } from 'redux-saga/effects';

import { POST_TENANT_REQUEST } from '../../actions';
import {
    postTenantSuccess,
    postTenantFail,
    postTenant
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeEvery(
    POST_TENANT_REQUEST,
    simpleRequest<RT<typeof postTenant>>({
        success: postTenantSuccess,
        failure: postTenantFail,
        method: 'POST',
        getUrl: (_) => 'tenant/',
        getBody: (action) => action.tenant
    })
);
