import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
    TENANT_TYPE_MOVED_OUT,
    TENANT_TYPE_MOVING_IN
} from '../../configs/costants';
import { getTenant } from '../../store/action-creators';

import { getPaginationParams } from '../../modules/pagination';
import { getFullname } from '../../modules/account';
import { partition } from '../../modules/array';
import { backDateToFrontAlt } from '../../utils/formatter';

import StdListItem from '../atoms/StdListItem';
import EditTenantModal from './EditTenantModal';
import StdIcon from '../atoms/StdIcon';
import StdButton from '../atoms/StdButton';

interface IProps extends IClass {
    selectedTenant?: ITenant;
    dpdId: number;
    tenants: ITenant[];
    onTenantSelect: (tenant: ITenant) => void;
}

const TenantTab: React.FC<IProps> = ({
    className,
    dpdId,
    selectedTenant,
    tenants,
    onTenantSelect
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [addOpen, setAddOpen] = useState(false);

    const [oldTenants, otherTenants] = partition(
        tenants,
        (tenant) => tenant.type === TENANT_TYPE_MOVED_OUT
    );
    const [movingInTenants, livingTenants] = partition(
        otherTenants,
        (tenant) => tenant.type === TENANT_TYPE_MOVING_IN
    );

    const tenantsRes = useSelector((state) => state.inventory.tenants);
    const { hasNext, hasPrevious } = tenantsRes.paginate;

    const fetchMore = useCallback(
        (direction: IPaginateDirection) => {
            const params = getPaginationParams(tenantsRes.paginate, direction);

            dispatch(getTenant(dpdId, params, direction));
        },
        [dispatch, dpdId, tenantsRes.paginate]
    );

    const handleRefetch = () => {
        dispatch(getTenant(dpdId));
    };

    return (
        <div className={className}>
            {hasPrevious && (
                <StdButton width="100%" onClick={() => fetchMore('backwards')}>
                    {t('load_previous')}
                </StdButton>
            )}
            <StdButton
                className="stack-m"
                leadingIcon={{ name: 'plus' }}
                onClick={() => setAddOpen(true)}
            >
                {t('tenant')}
            </StdButton>
            {Boolean(movingInTenants.length) && (
                <div className="stack-l">
                    <div className="stack-s">
                        {t('ready_to_move_in').toUpperCase()}
                    </div>
                    {movingInTenants.map((tenant) => {
                        const { id, contact_details } = tenant;
                        const { first_name, last_name } = contact_details || {};

                        const isActive = selectedTenant?.id === tenant.id;
                        return (
                            <StdListItem
                                key={id}
                                icon="user"
                                textData={{
                                    main: getFullname(first_name, last_name),
                                    sub: null
                                }}
                                onClick={() => onTenantSelect(tenant)}
                                active={isActive}
                            />
                        );
                    })}
                </div>
            )}
            {Boolean(livingTenants.length) && (
                <div className="stack-l">
                    <div className="stack-s">
                        {t('living_tenant').toUpperCase()}
                    </div>
                    {livingTenants.map((tenant) => {
                        const { id, contact_details, from_date } = tenant;
                        const { first_name, last_name } = contact_details || {};

                        const isActive = selectedTenant?.id === tenant.id;
                        return (
                            <StdListItem
                                key={id}
                                icon="user"
                                textData={{
                                    main: getFullname(first_name, last_name),
                                    sub: from_date && (
                                        <span>
                                            <StdIcon
                                                className="inline-s"
                                                name="sign-in"
                                            />
                                            <span>
                                                {backDateToFrontAlt(from_date)}
                                            </span>
                                        </span>
                                    )
                                }}
                                onClick={() => onTenantSelect(tenant)}
                                active={isActive}
                            />
                        );
                    })}
                </div>
            )}
            {Boolean(oldTenants.length) && (
                <>
                    <div className="stack-s">{t('archive').toUpperCase()}</div>
                    {oldTenants.map((tenant) => {
                        const { id, contact_details, to_date } = tenant;
                        const { first_name, last_name } = contact_details || {};

                        const isActive = selectedTenant?.id === tenant.id;
                        return (
                            <StdListItem
                                key={id}
                                icon="user"
                                textData={{
                                    main: getFullname(first_name, last_name),
                                    sub: to_date && (
                                        <span>
                                            <StdIcon
                                                className="inline-s"
                                                name="sign-out"
                                            />
                                            <span>
                                                {backDateToFrontAlt(to_date)}
                                            </span>
                                        </span>
                                    )
                                }}
                                onClick={() => onTenantSelect(tenant)}
                                active={isActive}
                            />
                        );
                    })}
                </>
            )}
            {hasNext && (
                <StdButton width="100%" onClick={() => fetchMore('forwards')}>
                    {t('load_more')}
                </StdButton>
            )}
            <EditTenantModal
                dpdId={dpdId}
                open={addOpen}
                onClose={() => setAddOpen(false)}
                onSubmit={handleRefetch}
            />
        </div>
    );
};

export default TenantTab;
