import { takeEvery } from 'redux-saga/effects';

import { DELETE_MEDIA_REQUEST } from '../../actions';
import {
    deleteMedia,
    deleteMediaSuccess,
    deleteMediaFail
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeEvery(
    DELETE_MEDIA_REQUEST,
    simpleRequest<ReturnType<typeof deleteMedia>>({
        success: deleteMediaSuccess,
        failure: deleteMediaFail,
        getUrl: (action) => `media/${action.id}/media_delete/`,
        method: 'DELETE',
        enableOffline: true
    })
);
