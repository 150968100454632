import React, { Fragment } from 'react';
import { TFunction } from 'i18next';

import { useTranslation } from 'react-i18next';

import {
    parseFeature,
    parseFeaturePartial,
    isLimited
} from '../../../modules/plans';
import { findLongestArray, flatMap } from '../../../modules/array';

import useMatchMedia from '../../../hooks/useMatchMedia';

import IconBullet from '../../atoms/IconBullet';
import StdButton from '../../atoms/StdButton';
import StdDivider from '../../atoms/StdDivider';
import TrialPlan, { IFeatureCell } from '../TrialPlan';
import TrialCell from '../TrialCell';

import './trial-plan-select.scss';

function featureToTitle(t: TFunction, planFeature: IFeature): IFeatureCell {
    const { feature } = planFeature;
    const { unitTitle } = parseFeaturePartial(planFeature);

    return {
        title: t(`package.backend.feature.included.${feature}`, {
            unit: unitTitle
        }),
        subtitle: isLimited(planFeature)
            ? t(`package.backend.feature.extra.${feature}`, { unit: unitTitle })
            : undefined
    };
}

function featureToAmount(
    t: TFunction,
    planFeature: IFeature,
    currency: string
): IFeatureCell {
    const { feature, unlimited, per_extra } = planFeature;

    const { includedTitle, priceTitle } = parseFeature(planFeature, currency);

    const allowedTitle = unlimited
        ? t('plan.unlimited.feature.included')
        : includedTitle;
    const title = allowedTitle;
    const subtitle = isLimited(planFeature)
        ? !!!parseFloat(per_extra)
            ? per_extra
            : t(`plan.backend.feature.pricing.${feature}`, {
                  price: priceTitle
              })
        : undefined;

    return {
        title,
        subtitle
    };
}

function featuresToNames(features: IFeature[]) {
    return features.map(({ feature }) => feature);
}

function featureByName(name: string, features: IFeature[]) {
    return features.filter(({ feature }) => feature === name);
}

function plansToFeatures(plans: IPackage[]): IFeature[] {
    return flatMap(plans, (plan) => plan.features);
}

function parsePlanFeatures(plans: IPackage[]) {
    const titleFeatures = findLongestArray(plans.map((plan) => plan.features));
    const featureNames = featuresToNames(titleFeatures);

    const allFeatures = plansToFeatures(plans);

    return featureNames.map((name) => {
        return {
            name,
            features: featureByName(name, allFeatures)
        };
    });
}

interface IProps {
    plans: IPackage[];
    currentPackage?: IPackage | null;
    onBack?: ICallback;
    onPlanSelect?: (plan: IPackage) => void;
}

const TrialPlanSelect: React.FC<IProps> = ({
    plans,
    currentPackage,
    onBack,
    onPlanSelect
}) => {
    const { t } = useTranslation();
    const isPhone = useMatchMedia('mobile');

    const featureRows = parsePlanFeatures(plans);

    return (
        <div className="plan-select__page">
            <div className="plan-select__heading">
                <IconBullet className="inline-m" onClick={onBack}>
                    {t('back')}
                </IconBullet>
                <div className="plan-select__header-text inline-m">
                    {t('which_payment_option')}
                </div>
                <IconBullet className="util__hidden">{t('back')}</IconBullet>
            </div>
            <div className="plan-select__plans">
                <div className="plan-select__options-table stack-l">
                    {!isPhone && <div className="trial-plan__wrapper" />}
                    {plans.map((plan: any, index: any) => {
                        const { identifier, base_price, currency } = plan;

                        const parsedPrice = `${currency} ${parseFloat(
                            base_price
                        )}`;
                        return (
                            <Fragment key={index}>
                                {isPhone && (
                                    <div className="trial-plan__wrapper" />
                                )}
                                <TrialPlan
                                    name={t(`plan.backend.name.${identifier}`)}
                                    price={parsedPrice}
                                    isCurrent={Boolean(
                                        currentPackage &&
                                            currentPackage.identifier ===
                                                identifier
                                    )}
                                    onSelect={() => onPlanSelect?.(plan)}
                                    highlighted={false}
                                />
                                {isPhone &&
                                    plan.features.map(
                                        (feature: any, fIndex: number) => {
                                            const amountCell = featureToAmount(
                                                t,
                                                feature,
                                                plans[0].currency
                                            ); //fixme
                                            const titleCell =
                                                isPhone || index === 0
                                                    ? featureToTitle(t, feature)
                                                    : undefined;
                                            const isLastItem =
                                                fIndex ===
                                                plan.features.length - 1;
                                            return (
                                                <Fragment key={index}>
                                                    {titleCell && (
                                                        <TrialCell
                                                            title={
                                                                titleCell.title
                                                            }
                                                            subtitle={
                                                                titleCell.subtitle
                                                            }
                                                        />
                                                    )}
                                                    <TrialCell
                                                        title={amountCell.title}
                                                        subtitle={
                                                            amountCell.subtitle
                                                        }
                                                        highlighted={false}
                                                        last={
                                                            false && isLastItem
                                                        }
                                                    />
                                                </Fragment>
                                            );
                                        }
                                    )}
                            </Fragment>
                        );
                    })}
                    {!isPhone &&
                        featureRows.map((row, rowIndex) => {
                            const isLastRow =
                                rowIndex === featureRows.length - 1;
                            return (
                                <Fragment key={rowIndex}>
                                    {row.features.map((feature, index) => {
                                        const amountCell = featureToAmount(
                                            t,
                                            feature,
                                            plans[0].currency
                                        );
                                        const titleCell =
                                            index === 0
                                                ? featureToTitle(t, feature)
                                                : undefined;

                                        return (
                                            <>
                                                <Fragment key={index}>
                                                    {titleCell && (
                                                        <TrialCell
                                                            title={
                                                                titleCell.title
                                                            }
                                                            subtitle={
                                                                isLastRow
                                                                    ? ''
                                                                    : titleCell.subtitle
                                                            }
                                                            last={isLastRow}
                                                        />
                                                    )}
                                                    <TrialCell
                                                        title={amountCell.title}
                                                        subtitle={
                                                            isLastRow
                                                                ? ''
                                                                : amountCell.subtitle
                                                        }
                                                        highlighted={false}
                                                        last={isLastRow}
                                                        currency={plans[0].currency}
                                                        suffixAmount={
                                                            feature.feature ===
                                                            'MIOP'
                                                        }
                                                    />
                                                </Fragment>
                                            </>
                                        );
                                    })}
                                </Fragment>
                            );
                        })}
                </div>
            </div>
            <StdDivider className="stack-l" width="100%" thick />
            <div className="custom-plan__container stack-ll">
                {/* <div className="custom-plan__portraits-container inline-xl">
                    <div className="custom-plan__portrait-container">
                        <img
                            className="custom-plan__portrait"
                            src="/static/images/Marc_Portrait.jpeg"
                            alt=""
                        />
                        <div className="custom-plan__portrait_subtitle">
                            {t('plan_select.sales_contact_1')}
                        </div>
                    </div>
                    <div className="custom-plan__portrait-container">
                        <img
                            className="custom-plan__portrait"
                            src="/static/images/Nicoletta_Portrait.jpeg"
                            alt=""
                        />
                        <div className="custom-plan__portrait_subtitle">
                            {t('plan_select.sales_contact_2')}
                        </div>
                    </div>
                </div> */}
                <div>
                    <div className="custom-plan__title stack-s">
                        {t('plan_select.custom_plan_title')}
                    </div>
                    <div className="custom-plan__subtitle stack-m">
                        {t('plan_select.custom_plan_subtitle')}
                    </div>
                    <StdButton
                        to="https://aimmo.io/demo-termin-vereinbaren/"
                        size="small"
                        externalUrl
                        openTab
                    >
                        {t('plan_select.contact_sales')}
                    </StdButton>
                </div>
            </div>
            <div>
                <ul className="plan-select__note">
                    <li>{t('trial_sub_note_1')}</li>
                    <li>{t('trial_sub_note_2')}</li>
                </ul>
            </div>
        </div>
    );
};

export default TrialPlanSelect;
