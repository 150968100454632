import React from 'react';
import ReactDOM from 'react-dom';
import smoothscroll from 'smoothscroll-polyfill';

import { Router } from 'react-router';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import DateMomentUtils from '@date-io/moment';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { store, persistor } from './store';
import history from './modules/history';

import { hostEnv, isLocalhost } from './utils';
import env from './configs/environment';

import 'allsettled-polyfill';

import './i18next';
import './configs/initIcons';
import './initFont';

import 'swiper/css/swiper.css';
import './styles/main.scss';

import * as serviceWorker from './initWorker';

// Note: in order to ensure correct scss imports App
// should be imported after global styles
import App from './App';
import ErrorBoundary from './wrappers/ErrorBoundary';

// Note: fixes smooth scrolling on unsupporting browsers
smoothscroll.polyfill();

if (!isLocalhost) {
    Sentry.init({
        dsn: env.getProperty('sentryUrl'),
        integrations: [
            new Integrations.BrowserTracing({
                routingInstrumentation:
                    Sentry.reactRouterV5Instrumentation(history)
            })
        ],
        tracesSampleRate: 1.0,
        environment: hostEnv
    });
}


ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <MuiPickersUtilsProvider utils={DateMomentUtils}>
                <Router history={history}>
                    <ErrorBoundary>
                        <App />
                    </ErrorBoundary>
                </Router>
            </MuiPickersUtilsProvider>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);

serviceWorker.register();
