import { takeLatest } from 'redux-saga/effects';

import { GET_ITEM_TEMPLATES_REQUEST } from '../../actions';
import {
    getItemTemplatesSuccess,
    getItemTemplatesFail,
    getItemTemplates
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeLatest(
    GET_ITEM_TEMPLATES_REQUEST,
    simpleRequest<RT<typeof getItemTemplates>>({
        success: getItemTemplatesSuccess,
        failure: getItemTemplatesFail,
        getUrl: (action) =>
            `protocol_item/item_template/?type=${
                action.itemType
            }&lang=${action.lang.substring(0, 2)}`,
        noBlock: true
    })
);
