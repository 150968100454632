import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { validate } from '../../../utils/validation';
import useInput from '../../../hooks/useInput';
import useValidation from '../../../hooks/useValidation';

import StdTextField from '../../atoms/StdTextField';
import ConfirmModal from '../ConfirmModal';

interface IForm {
    form: {
        verification: string;
    };
    errors: IErrorHash;
}

const initForm: IForm = {
    form: {
        verification: ''
    },
    errors: {}
};

interface IProps extends IModalBase, IClass {
    message: string;
    verifyNote?: string;
    verifyMessage?: string;
    onConfirm?: ICallback;
}

const ConfirmDeletionModal: React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    const [input, setInput] = useState(initForm);
    const { form, errors } = input;

    const { message, verifyMessage, verifyNote, onConfirm } = props;

    const handleInput = useInput(setInput);

    const isConfirmed = form.verification === verifyMessage;

    const validateFields = useValidation(input, setInput, (hash) => {
        const { verification } = input.form;
        return [
            !verifyMessage || validate({ verification }, isConfirmed, hash)
        ].every(Boolean);
    });

    const onAccept = () => {
        if (validateFields()) onConfirm?.();
    };

    const isDisabled = Boolean(verifyMessage && !isConfirmed);

    return (
        <ConfirmModal
            {...props}
            confirmLabel={t('delete').toUpperCase()}
            cancelLabel={t('cancel').toUpperCase()}
            onConfirm={onAccept}
            disabled={isDisabled}
            isDestructive
        >
            <div className="stack-xl">{message}</div>
            {verifyNote && verifyNote && (
                <>
                    <div className="stack-xs typo-grey-s">{verifyNote}</div>
                    <div className="stack-s typo-grey-s">
                        {t('verify_deletion_message', {
                            verification_message: verifyMessage
                        })}
                    </div>
                    <StdTextField
                        className="stack-m"
                        value={form.verification}
                        error={errors['verification']}
                        name="verification"
                        onChange={handleInput}
                        width="100%"
                        outlined={false}
                    />
                </>
            )}
        </ConfirmModal>
    );
};

export default ConfirmDeletionModal;
