export const CONNECTIVITY_CHANGE_ONLINE = 'CONNECTIVITY_CHANGE_ONLINE';
export const CONNECTIVITY_CHANGE_OFFLINE = 'CONNECTIVITY_CHANGE_OFFLINE';
export const BETA_CONNECTIVITY_CHANGE_ONLINE =
    'BETA_CONNECTIVITY_CHANGE_ONLINE';
export const BETA_CONNECTIVITY_CHANGE_OFFLINE =
    'BETA_CONNECTIVITY_CHANGE_OFFLINE';
export const CONNECT_BETA_COMPRESSOR = 'CONNECT_BETA_COMPRESSOR';
export const DISCONNECT_BETA_COMPRESSOR = 'DISCONNECT_BETA_COMPRESSOR';

export const SET_NETWORK_RTT = 'SET_NETWORK_RTT';

export const BACKGROUND_SYNC_START = 'BACKGROUND_SYNC_START';
export const BACKGROUND_SYNC_SUCCESS = 'BACKGROUND_SYNC_SUCCESS';
export const BACKGROUND_SYNC_FAIL = 'BACKGROUND_SYNC_FAIL';

export const BACKGROUND_SYNC_DISCARD = 'BACKGROUND_SYNC_DISCARD';

export const CONNECTION_SPEED_UPDATE = 'CONNECTION_SPEED_UPDATE';

export const ASYNC_ACTION_SEQUENCE = 'ASYNC_ACTION_SEQUENCE';

export const HIDE_USER_OFFLINE_NOTIFICATION_MODAL =
    'HIDE_USER_OFFLINE_NOTIFICATION_MODAL';

export const ASSIGN_PROTOCOL_SESSION_REQUEST =
    'ASSIGN_PROTOCOL_SESSION_REQUEST';
export const ASSIGN_PROTOCOL_SESSION_SUCCESS =
    'ASSIGN_PROTOCOL_SESSION_SUCCESS';
export const ASSIGN_PROTOCOL_SESSION_FAIL = 'ASSIGN_PROTOCOL_SESSION_FAIL';
