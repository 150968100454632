import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import StdButton from '../../atoms/StdButton';

import './trial-note.scss';

interface IProps extends IClass {
    daysLeft: number;
    warn?: boolean;
    onUpgrade?: ICallback;
}

const TrialNote: React.FC<IProps> = ({ daysLeft, warn, onUpgrade }) => {
    const { t } = useTranslation();
    return (
        <div className={clsx('trial-note', warn && 'trial-note--warn')}>
            <div>
                <span className="inline-m">
                    {t('you_have')}{' '}
                    <span className="font--strong">
                        {daysLeft} {t('days_left')}
                    </span>{' '}
                    {t('on_your_trial')}
                </span>
                <StdButton
                    type={warn ? 'primary' : 'secondary'}
                    onClick={onUpgrade}
                >
                    {t('upgrade_now')}
                </StdButton>
            </div>
        </div>
    );
};

export default TrialNote;
