import React from 'react';
import { useTranslation } from 'react-i18next';

import { match } from '../../../utils';

import StdButton from '../../atoms/StdButton';
import StdBullet from '../../atoms/StdBullet';

import './tutorial-card.scss';

const propertyTutorial = {
    title: 'tutorial_property_title',
    text: 'tutorial_property_text',
    point_1_start: 'tutorial_property_point_1_start',
    point_1_end: 'tutorial_property_point_1_end',
    point_2_start: 'tutorial_property_point_2_start',
    point_2_end: 'tutorial_property_point_2_end',
    point_3_start: 'tutorial_property_point_3_start',
    point_3_end: 'tutorial_property_point_3_end',
    action_text: 'tutorial_property_action',
    illustration_src: 'static/images/aimmo_property_illustration.png'
};

const issueTutorial = {
    title: 'tutorial_issue_title',
    text: 'tutorial_issue_text',
    point_1_start: 'tutorial_issue_point_1_start',
    point_1_end: 'tutorial_issue_point_1_end',
    point_2_start: 'tutorial_issue_point_2_start',
    point_2_end: 'tutorial_issue_point_2_end',
    point_3_start: 'tutorial_issue_point_3_start',
    point_3_end: 'tutorial_issue_point_3_end',
    action_text: 'tutorial_issue_action',
    illustration_src: 'static/images/aimmo_issue_illustration.png'
};

const protocolTutorial = {
    title: 'tutorial_protocol_title',
    text: 'tutorial_protocol_text',
    point_1_start: 'tutorial_protocol_point_1_start',
    point_1_end: 'tutorial_protocol_point_1_end',
    point_2_start: 'tutorial_protocol_point_2_start',
    point_2_end: 'tutorial_protocol_point_2_end',
    point_3_start: 'tutorial_protocol_point_3_start',
    point_3_end: 'tutorial_protocol_point_3_end',
    action_text: 'tutorial_protocol_action',
    illustration_src: 'static/images/aimmo_protocol_illustration.png'
};

interface IProps {
    property?: boolean;
    issue?: boolean;
    protocol?: boolean;
    onAction?: ICallback;
}

const TutorialCard: React.FC<IProps> = (props) => {
    const { t } = useTranslation();

    const { onAction } = props;
    const dataLiteral = match<IProps, typeof propertyTutorial>(props)
        .on(
            (x) => Boolean(x.issue),
            (_) => issueTutorial
        )
        .on(
            (x) => Boolean(x.protocol),
            (_) => protocolTutorial
        )
        .otherwise((_) => propertyTutorial);

    return (
        <div className="tutorial-container">
            <div className="tutorial__text-wrapper">
                <div className="font__header stack-m">
                    {t(dataLiteral.title)}
                </div>
                <div className="tutorial__contents">
                    <div className="font--secondary stack-m">
                        {t(dataLiteral.text)}
                    </div>
                    <div className="stack-l">
                        <div className="util__flex-align stack-s">
                            <StdBullet className="inline-s" />
                            <span className="font--secondary inline-xs">
                                {t(dataLiteral.point_1_start)}
                            </span>
                            <span>{t(dataLiteral.point_1_end)}</span>
                        </div>
                        <div className="util__flex-align stack-s">
                            <StdBullet className="inline-s" />
                            <span className="font--secondary inline-xs">
                                {t(dataLiteral.point_2_start)}
                            </span>
                            <span>{t(dataLiteral.point_2_end)}</span>
                        </div>
                        <div className="util__flex-align stack-s">
                            <StdBullet className="inline-s" />
                            <span className="font--secondary inline-xs">
                                {t(dataLiteral.point_3_start)}
                            </span>
                            <span>{t(dataLiteral.point_3_end)}</span>
                        </div>
                    </div>
                    <StdButton type="primary" onClick={onAction}>
                        {t(dataLiteral.action_text)}
                    </StdButton>
                </div>
            </div>
            <img
                className="tutorial__illustration"
                src={dataLiteral.illustration_src}
                alt="tutorial illustration"
            />
        </div>
    );
};

export default TutorialCard;
