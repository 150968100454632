import React from 'react';
import { useTranslation } from 'react-i18next';
import { DASHBOARD_ROUTE } from '../../../configs/routes';

import HeaderLogo from '../../atoms/HeaderLogo';
import StdButton from '../../atoms/StdButton';

const ErrorPage404: React.FC = () => {
    const { t } = useTranslation();
    return (
        <div className="error__page">
            <div className="header">
                <HeaderLogo />
            </div>
            <div className="error__message">
                <div>
                    <img
                        className="error__illustration"
                        src="/static/images/error_page_illustration.png"
                        alt="error illustration"
                    />
                </div>
                <div className="font__title stack-m">{t('404_title')}</div>
                <div>{t('404_description')}</div>
                <div className="stack-xl">{t('report_a_problem_note')}</div>
                <div>
                    <StdButton className="inline-l" to={DASHBOARD_ROUTE}>
                        {t('error_to_dashboard')}
                    </StdButton>
                    <StdButton attr-intercom="report-problem">
                        {t('report_a_problem')}
                    </StdButton>
                </div>
            </div>
        </div>
    );
};

export default ErrorPage404;
