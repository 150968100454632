import { takeEvery, put, all } from 'redux-saga/effects';

import {
    DELETE_PROTOCOL_ROOM_REQUEST,
    DELETE_PROTOCOL_ROOM_FAIL
} from '../../actions';
import {
    protoDelRoomSuccess,
    protoDelRoomFail,
    protoDelRoom,
    getProtocolRoom
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

type IDeleteRoomAction = RT<typeof protoDelRoom>;
type IDeleteRoomFailAction = RT<typeof protoDelRoomFail>;

const requestEffect = simpleRequest<IDeleteRoomAction>({
    success: protoDelRoomSuccess,
    failure: protoDelRoomFail,
    getUrl: (action) => `protocol_room/${action.id}/`,
    getEntityId: (action) => action.id,
    method: 'DELETE',
    enableOffline: true
});

export default all([
    takeEvery(DELETE_PROTOCOL_ROOM_REQUEST, requestEffect),
    takeEvery(
        DELETE_PROTOCOL_ROOM_FAIL,
        function* (action: IDeleteRoomFailAction) {
            if (action.itemId == null) return;
            yield put(getProtocolRoom(action.itemId));
        }
    )
]);
