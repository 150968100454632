import React, { useState, useMemo } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import useMatchMedia from '../../../hooks/useMatchMedia';

import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { MenuItem, MenuSurfaceAnchor } from '@rmwc/menu';

import StdStep from '../../atoms/StdStep';
import StdDropdown from '../../atoms/StdDropdown';
import StdIcon from '../../atoms/StdIcon';

interface IStep {
    name: IProtocolStep;
    title: string;
    isDone: boolean;
    icon: {
        prefix: IconPrefix;
        name: IconName;
    };
}

function protocolToStep(stages: IProtocolStages, isFinished: boolean): IStep[] {
    const { attendees_done, rooms_done, items_done, meters_done } = stages;

    const attendeesDone = isFinished || Boolean(attendees_done);
    const roomsDone = isFinished || Boolean(rooms_done);
    const itemsDone = isFinished || Boolean(items_done);
    const metersDone = isFinished || Boolean(meters_done);

    return [
        {
            name: 'locate',
            title: 'presence',
            isDone: attendeesDone,
            icon: {
                name: 'users',
                prefix: 'fal'
            }
        },
        {
            name: 'rooms',
            title: 'rooms',
            isDone: roomsDone,
            icon: {
                name: 'building',
                prefix: 'fal'
            }
        },
        {
            name: 'other',
            title: 'other',
            isDone: itemsDone,
            icon: {
                name: 'key',
                prefix: 'fal'
            }
        },
        {
            name: 'meters',
            title: 'meters',
            isDone: metersDone,
            icon: {
                name: 'tachometer-alt',
                prefix: 'fal'
            }
        },
        {
            name: isFinished ? 'done' : 'confirm',
            title: isFinished ? 'confirmed_and_closed' : 'confirm',
            isDone: isFinished,
            icon: {
                name: 'file-invoice',
                prefix: 'fal'
            }
        }
    ];
}

interface IProps {
    currentStep: IProtocolStep;
    stages: IProtocolStages;
    isFinished: boolean;
    simplified?: boolean;
    onChangeStep?: (step: IProtocolStep) => void;
    visitedSteps?: { [key: number]: boolean };
}

const ProtocolStepper: React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    const [stepsOpen, setStepsOpen] = useState(false);

    const {
        currentStep,
        stages,
        isFinished,
        simplified,
        onChangeStep,
        visitedSteps
    } = props;

    const steps = useMemo(
        () => protocolToStep(stages, isFinished),
        [stages, isFinished]
    );

    const isPhone = useMatchMedia('mobile');
    const isCollapsed = !simplified && isPhone;

    const currentStepIndex = steps.findIndex(
        (step) => step.name === currentStep
    );
    const lastCompletedIndex = steps
        .slice()
        .map((step) => step.isDone)
        .lastIndexOf(true);

    const stepNodes = steps.map((step, index) => {
        const stepTitle = !simplified ? t(step.title) : undefined;

        const isLast = index === steps.length - 1;
        const userVisitedConfirm = Boolean(visitedSteps?.[steps.length - 1]);
        const isMetersTab = index === steps.length - 2;

        const isHighLighted = Boolean(
            lastCompletedIndex !== -1 &&
                // if step is not last > check (step is visited, and not done) else, check isDone of all steps
                (!userVisitedConfirm
                    ? Boolean(visitedSteps?.[index] && !step.isDone)
                    : Boolean(!step.isDone)) &&
                !isLast &&
                !isMetersTab
        );

        const isCurrent = index === currentStepIndex;

        const onStepClick = () => {
            if (isCollapsed || isCurrent) return;

            onChangeStep?.(step.name);
        };

        return (
            <StdStep
                key={step.title}
                className={clsx(!isFinished && 'util__pointer')}
                title={stepTitle}
                iconName={step.icon.name}
                iconPrefix={step.icon.prefix}
                onClick={onStepClick}
                completed={step.isDone}
                highlighted={isHighLighted}
                selected={isCurrent}
                last={isLast || isCollapsed}
                active
            />
        );
    });

    return (
        <div className={clsx(!simplified && !isCollapsed && 'util__center')}>
            {isCollapsed ? (
                <>
                    <MenuSurfaceAnchor className="inline-s util__inline">
                        <StdDropdown
                            open={stepsOpen}
                            onClose={() => setStepsOpen(false)}
                        >
                            {stepNodes.map((node, index) => {
                                const stepName = steps[index].name;
                                return (
                                    <MenuItem
                                        key={stepName}
                                        onClick={() => onChangeStep?.(stepName)}
                                        disabled={index === currentStepIndex}
                                    >
                                        {node}
                                    </MenuItem>
                                );
                            })}
                        </StdDropdown>
                        <StdIcon
                            prefix="far"
                            name="ellipsis-v"
                            onClick={() => setStepsOpen(true)}
                            clickable
                        />
                    </MenuSurfaceAnchor>
                    <div className="mdc-list-item-step">
                        {stepNodes[currentStepIndex]}
                    </div>
                </>
            ) : (
                stepNodes
            )}
        </div>
    );
};
export default ProtocolStepper;
