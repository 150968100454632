import * as actions from '../actions/room';
import { Action } from 'redux';
import { Assign } from 'utility-types';
import { createAction, createCustomAction } from 'typesafe-actions';
import { actionFail, actionPayload } from '../storeModule';

export const getRoom = createCustomAction(actions.GET_ROOM_REQUEST, (type) => {
    return (dpdId: number, lang: ILang) => ({ type, dpdId, lang });
});
export const getRoomSuccess = createCustomAction(
    actions.GET_ROOM_SUCCESS,
    actionPayload
);
export const getRoomFail = createCustomAction(
    actions.GET_ROOM_FAIL,
    actionFail
);

type IModifibleRoom = Assign<
    Omit<IRoom, 'rom_id' | 'uploads'>,
    { rom_dpd_id: number; media_med_id?: number }
>;

export const postRoom = createCustomAction(
    actions.POST_ROOM_REQUEST,
    (type) => {
        return (room: IModifibleRoom) => ({ type, room });
    }
);
export const postRoomSuccess = createCustomAction(
    actions.POST_ROOM_SUCCESS,
    actionPayload
);
export const postRoomFail = createCustomAction(
    actions.POST_ROOM_FAIL,
    actionFail
);

export const patchRoom = createCustomAction(
    actions.PATCH_ROOM_REQUEST,
    (type) => {
        return (roomId: number, payload: any) => ({ type, roomId, payload });
    }
);
export type IPatchRoom = ReturnType<typeof patchRoom>;

export const patchRoomSuccess = createCustomAction(
    actions.PATCH_ROOM_SUCCESS,
    actionPayload
);
export const patchRoomFail = createCustomAction(
    actions.PATCH_ROOM_FAIL,
    actionFail
);

export interface IDeleteRoom
    extends Action<typeof actions.DELETE_ROOM_REQUEST> {
    roomId: number;
}

export const deleteRoom = createCustomAction(
    actions.DELETE_ROOM_REQUEST,
    (type) => {
        return (roomId: number) => ({ type, roomId });
    }
);
export const deleteRoomSuccess = createAction(actions.DELETE_ROOM_SUCCESS);
export const deleteRoomFail = createCustomAction(
    actions.DELETE_ROOM_FAIL,
    actionFail
);

export const roomAttrCrud = createCustomAction(
    actions.ROOM_ATTR_CRUD_REQUEST,
    (type) => {
        return (payload: {
            roa_rom_id: number;
            roa_state: Partial<IRoomAttr>[];
        }) => ({ type, payload });
    }
);
export const roomAttrCrudSuccess = createCustomAction(
    actions.ROOM_ATTR_CRUD_SUCCESS
);
export const roomAttrCrudFail = createCustomAction(
    actions.ROOM_ATTR_CRUD_FAIL,
    actionFail
);

export const copyRoom = createCustomAction(
    actions.COPY_ROOM_REQUEST,
    (type) => {
        return (roomId: number) => ({ type, roomId });
    }
);
export const copyRoomSuccess = createCustomAction(
    actions.COPY_ROOM_SUCCESS,
    actionPayload
);
export const copyRoomFail = createCustomAction(
    actions.COPY_ROOM_FAIL,
    actionFail
);

export const predictRoom = createCustomAction(actions.PREDICT_ROOM, (type) => {
    return (roomId: number, payload: Omit<IModifibleRoom, 'rom_dpd_id'>) => ({
        type,
        roomId,
        payload
    });
});

export const predictRooms = createCustomAction(
    actions.PREDICT_ROOMS,
    (type) => {
        return (rooms: IRoom[]) => ({ type, rooms });
    }
);
