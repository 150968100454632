import React from 'react';
import Modal from '../../atoms/StdModal';
import clsx from 'clsx';
import StdButton from '../../atoms/StdButton';
import { useTranslation } from 'react-i18next';

import './ProtocolCheckoutWarningModal.scss';
import { parseUserAgent } from '../../../utils/uaParser';
import { backDateToFrontWithTime } from '../../../utils/formatter';

interface ProtocolCheckoutWarningModalProps {
    data: IProtocolCheckoutData | null;
    open: boolean;
    onAccept: (e?: any) => void | undefined;
    onCancel: (e?: any) => void | undefined;
}

const ProtocolCheckoutWarningModal: React.FC<
    ProtocolCheckoutWarningModalProps
> = ({ data, open, onAccept, onCancel }) => {
    const { t } = useTranslation();
    const { user_email = '', device_data = '', created_at = '' } = data || {};
    const isInsideProtocol = window.location.pathname.includes('protocol');

    const updatedDate = backDateToFrontWithTime(created_at);

    const parsedUserData = (parseUserAgent(device_data) as any) || {};

    const alertTextWhenInsideProtocol = t('inside_protocol_checkout_warning', {
        device: parsedUserData?.device?.name || '---',
        user: user_email || '---'
    });

    const alertTextWhenOutsideProtocol = t(
        'outside_protocol_checkout_warning',
        {
            device: parsedUserData?.device?.name || '---',
            user: user_email || '---'
        }
    );

    return (
        <Modal
            open={open}
            onClose={() => {}}
            white
            contentClassName="protocol-checkout-warning-modal-content"
        >
            <div className="modal__section--square protocol-checkout-warning-modal">
                <div className={clsx('stack-xl', 'beta-selector')}>
                    <div className="modal-header">
                        {isInsideProtocol
                            ? alertTextWhenInsideProtocol
                            : alertTextWhenOutsideProtocol}
                    </div>
                    <div className="modal-info__3">
                        {t('protocol_checkout_warning_subtext')}
                    </div>
                </div>
                <div className={clsx('modal__actions', 'beta-modal-actions')}>
                    <StdButton
                        className="beta-cancel"
                        onClick={(e) => {
                            onCancel(e);
                        }}
                    >
                        {'Cancel'}
                    </StdButton>
                    <StdButton
                        className="beta-accept beta-selector-accept-button"
                        onClick={(e) => {
                            onAccept(e);
                        }}
                    >
                        {isInsideProtocol
                            ? t('inside_protocol_checkout_warning.proceed')
                            : t('outside_protocol_checkout_warning.proceed')}
                    </StdButton>
                </div>
            </div>
        </Modal>
    );
};

export default ProtocolCheckoutWarningModal;
