import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getFeatureDetails } from '../../../modules/plans';
import InvoiceCard from '../InvoiceCard';

interface IProps extends IClass {
    used: number;
    subtotal: number;
    featureName: IFeatureName;
    height?: string;
}

const PlanInvoiceCard: React.FC<IProps> = ({
    className,
    featureName,
    subtotal,
    used,
    height
}) => {
    const account = useSelector((state) => state.user.user.response);
    const currentPlan = account?.subscription?.plan;

    const { t } = useTranslation();

    if (!currentPlan) return null;

    const { included, isLimited } = getFeatureDetails(featureName, currentPlan);
    const parsedSubtotal = `${subtotal}${currentPlan.currency}`;

    const subtitle =
        featureName === 'ADVERTISEMENT'
            ? t('invoice.subtitle_published')
            : undefined;
    return (
        <InvoiceCard
            className={className}
            current={used}
            included={isLimited ? included : undefined}
            price={parsedSubtotal}
            subtitle={subtitle}
            height={height}
        />
    );
};

export default PlanInvoiceCard;
