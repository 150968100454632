import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { matchSimple } from '../../../utils';

import './std-chip.scss';

interface IProps {
    to?: string;
    className?: string;
    children?: React.ReactNode;
    type?: 'neutral' | 'danger' | 'info' | 'warn' | 'dark' | 'grey' | 'fill-danger' | 'fill-info' | 'fill-warn' | 'fill-success' ;
    small?: boolean;
    nowrap?: boolean;
    disabled?: boolean;
    onClick?: ICallback;
}

const StdChip: React.FC<IProps> = ({
    className,
    to,
    type = 'neutral',
    nowrap,
    small,
    disabled,
    children,
    onClick
}) => {
    const chipColor = matchSimple<string | null>()
        .on(type === 'danger', 'std-chip--danger')
        .on(type === 'fill-danger', 'std-chip--fill-danger')
        .on(type === 'info', 'std-chip--blue')
        .on(type === 'fill-info', 'std-chip--fill-blue')
        .on(type === 'warn', 'std-chip--warn')
        .on(type === 'fill-warn', 'std-chip--fill-warn')
        .on(type === 'dark', 'std-chip--black')
        .on(type === 'grey', 'std-chip--grey')
        .on(type === 'fill-success', 'std-chip--fill-success')
        .otherwise(null);

    const linkClasses = clsx(
        'std-chip',
        chipColor,
        nowrap && 'std-chip--nowrap',
        small && 'std-chip--small',
        disabled && 'std-chip--disabled',
        className
    );

    return to ? (
        <Link className={linkClasses} to={to} onClick={onClick}>
            {children}
        </Link>
    ) : (
        <span className={linkClasses} onClick={onClick}>
            {children}
        </span>
    );
};

export default StdChip;
