import { takeEvery } from 'redux-saga/effects';
import queryString from 'query-string';

import { getManagerSet } from '../../action-creators/team';
import { simpleRequest } from '../modules/request';

export default takeEvery(
    getManagerSet.type,
    simpleRequest<RT<typeof getManagerSet.request>>({
        success: getManagerSet.success,
        failure: getManagerSet.fail,
        getUrl: (action) =>
            `team_membership/?${queryString.stringify(action.params)}`,
        isV2Api: true
    })
);
