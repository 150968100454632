import { put, all, takeLatest } from 'redux-saga/effects';

import {
    PATCH_ISSUE_REQUEST,
    PATCH_ISSUE_SUCCESS,
    PATCH_ISSUE_FAIL
} from '../../actions';
import {
    patchIssueSuccess,
    patchIssueFail,
    patchIssue,
    getIssue
} from '../../action-creators';

import { simpleRequest } from '../modules/request';
import { normalizeIssue } from '../modules/normalization';
import { responseHelper } from '../modules/promise';
import i18n from '../../../i18next';

function onSuccessCallback(data: any) {
    return normalizeIssue(data);
}

type IPatchIssueAction = RT<typeof patchIssue>;
type IPatchIssueFailAction = RT<typeof patchIssueFail>;

const requstEffect = simpleRequest<IPatchIssueAction>({
    success: patchIssueSuccess,
    failure: patchIssueFail,
    getUrl: (action) => `issue/${action.issueId}/?lang=${action.lang}`,
    getBody: (action) => action.payload,
    getEntityId: (action) => action.issueId,
    method: 'PATCH',
    enableOffline: true
});

const responseEffect = responseHelper({
    actionName: PATCH_ISSUE_SUCCESS,
    processFn: onSuccessCallback
});

const lang = i18n.language as ILang;
export default all([
    takeLatest(PATCH_ISSUE_REQUEST, requstEffect),
    takeLatest(PATCH_ISSUE_FAIL, function* (action: IPatchIssueFailAction) {
        if (action.itemId == null) return;
        yield put(getIssue(action.itemId, lang));
    }),
    responseEffect
]);
