import * as actions from '../actions/dpd';
import { Action } from 'redux';
import { Assign } from 'utility-types';
import { createCustomAction, createAction } from 'typesafe-actions';
import { actionFail, actionPayload } from '../storeModule';

import { IGetAllDpdsResult } from '../sagas/dpd/getAll';

interface IModifyPropertyOverride {
    context?: 'create_object_dpd' | 'create_child_dpd';
    dpd_dpd_id?: number;
    dpd_egid?: number | null; // Note: handle optional fields with null
    dpd_ewid?: number | null;
    media_med_id?: number[];
    template_type?: string;
}

type IModifyPropertyPayload = Assign<
    Omit<IDpd, 'dpd_id' | 'dpd_acc_user_id' | 'uploads'>,
    IModifyPropertyOverride
>;

export type IDpdFetchType =
    | 'all'
    | 'dashboard'
    | 'issue_select'
    | 'protocol_select'
    | 'building_objects';

interface IPropertyParams extends IPaginationParams {
    building_id?: string | number;
    lang?: ILang;
}

export const getDpds = createCustomAction(actions.GET_DPD_REQUEST, (type) => {
    return (
        fetchType: IDpdFetchType,
        params: IPropertyParams = {},
        paginated?: IPaginateDirection
    ) => ({ type, fetchType, params, paginated });
});

export const getDpdsSuccess = createCustomAction(
    actions.GET_DPD_SUCCESS,
    (type) => {
        return (payload: IGetAllDpdsResult) => ({ type, payload });
    }
);
export const getDpdsFail = createCustomAction(actions.GET_DPD_FAIL, actionFail);

export interface IGetOneDpd extends Action<typeof actions.GET_ONE_DPD_REQUEST> {
    id: number;
}

export const getOneDpd = (id: number): IGetOneDpd => ({
    type: actions.GET_ONE_DPD_REQUEST,
    id
});
export const getOneDpdSucces = createCustomAction(
    actions.GET_ONE_DPD_SUCCESS,
    actionPayload
);
export const getOneDpdFail = createCustomAction(
    actions.GET_ONE_DPD_FAIL,
    actionFail
);

export const postDpd = createCustomAction(actions.POST_DPD_REQUEST, (type) => {
    return (dpd: IModifyPropertyPayload) => ({ type, dpd });
});
export const postDpdSuccess = createCustomAction(
    actions.POST_DPD_SUCCESS,
    actionPayload
);
export const postDpdFail = createCustomAction(
    actions.POST_DPD_FAIL,
    actionFail
);

export const patchDpd = createCustomAction(
    actions.PATCH_DPD_REQUEST,
    (type) => {
        return (dpdId: number, payload: IModifyPropertyPayload) => ({
            type,
            dpdId,
            payload
        });
    }
);

export const patchDpdSuccess = createAction(actions.PATCH_DPD_SUCCESS);
export const patchDpdFail = createCustomAction(
    actions.PATCH_DPD_FAIL,
    actionFail
);

export interface IDpdDelete extends Action<typeof actions.DELETE_DPD_REQUEST> {
    dpdId: number;
}

export const deleteDpd = (dpdId: number): IDpdDelete => ({
    type: actions.DELETE_DPD_REQUEST,
    dpdId
});
export const deleteDpdSuccess = createAction(actions.DELETE_DPD_SUCCESS);
export const deleteDpdFail = createCustomAction(
    actions.DELETE_DPD_FAIL,
    actionFail
);

interface IGeoAdminParams {
    searchText: string;
}

export const getLocationCH = createCustomAction(
    actions.GET_LOCATION_CH_REQUEST,
    (type) => {
        return (params: IGeoAdminParams) => ({ type, params });
    }
);
export const getLocationCHSuccess = createCustomAction(
    actions.GET_LOCATION_CH_SUCCESS,
    actionPayload
);
export const getLocationCHFail = createCustomAction(
    actions.GET_LOCATION_CH_FAIL,
    actionFail
);

export const dpdAttrCrud = createCustomAction(
    actions.DPD_ATTR_CRUD_REQUEST,
    (type) => {
        return (payload: {
            dpa_dpd_id: number;
            dpa_state: Partial<IDpdAttr>[];
        }) => ({ type, payload });
    }
);
export const dpdAttrCrudSuccess = createCustomAction(
    actions.DPD_ATTR_CRUD_SUCCESS
);
export const dpdAttrCrudFail = createCustomAction(
    actions.DPD_ATTR_CRUD_FAIL,
    actionFail
);

export const copyDpd = createCustomAction(actions.COPY_DPD_REQUEST, (type) => {
    return (dpdId: number) => ({ type, dpdId });
});
export const copyDpdSuccess = createCustomAction(
    actions.COPY_DPD_SUCCESS,
    actionPayload
);
export const copyDpdFail = createCustomAction(
    actions.COPY_DPD_FAIL,
    actionFail
);

export const predictObjects = createCustomAction(
    actions.PREDICT_OBJECTS,
    (type) => {
        return (id: number, objects: IDpd[]) => ({ type, id, objects });
    }
);
