import { SetStateAction, Dispatch, ChangeEvent, useCallback } from 'react';

export interface IInputState {
    form: object;
    errors: IErrorHash;
}

type IHandleInput = (event: ChangeEvent) => void;
type IInputCallback<T> = (name: string, value: string, state: T) => void | T;

// TODO: make the last param is more intuitive
const useInput = <T extends IInputState>(
    setState: Dispatch<SetStateAction<T>>,
    callback?: IInputCallback<T>
): IHandleInput => {
    return useCallback(
        (event: ChangeEvent): void => {
            const { name, value } = event.target as HTMLInputElement;

            setState((s) => {
                const nextState = {
                    ...s,
                    form: {
                        ...s.form,
                        [name]: value
                    },
                    errors: {
                        ...s.errors,
                        [name]: null
                    }
                };

                // Provide input state to the callback in case the callback wants to override some of it
                // In case it's null, just using the input state
                const modifiedState = callback?.(name, value, nextState);
                const newState = modifiedState || nextState;

                return newState;
            });
        },
        [setState, callback]
    );
};

export default useInput;
