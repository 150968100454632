import React, { useEffect } from 'react';
import queryString from 'query-string';
import { Redirect, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';

import { ROOT_ROUTE } from '../../configs/routes';
import { magicLogin } from '../../store/action-creators';

import useDelayedPending from '../../hooks/useDelayedPending';

import Loading from '../../components/atoms/Loading';

const MagicLoginPage: React.FC = () => {
    const dispatch = useDispatch();

    const { search } = useLocation();
    const { token, redirect } = queryString.parse(search);

    const authLoading = useDelayedPending([magicLogin]);

    const redirectRoute = typeof redirect === 'string' ? redirect : ROOT_ROUTE;

    useEffect(() => {
        if (typeof token === 'string') {
            dispatch(magicLogin(token));
        }
    }, [dispatch, token]);

    if (!authLoading) {
        return <Redirect to={redirectRoute} />;
    }

    return <Loading className="loading__page" size="xlarge" />;
};

export default MagicLoginPage;
