import { matchSimple } from '.';

import passwordSchema from '../configs/password';
import { PASSWORD_MESSAGE_HASH } from '../configs/costants';

export function validate(
    field: object,
    predicate: boolean,
    errors: IErrorHash,
    message: string = 'err'
) {
    const name = Object.keys(field)[0];

    if (predicate) {
        errors[name] = null;
    } else {
        errors[name] = message;
    }
    return predicate;
}

export function validatePassword(password: string): [boolean, string?] {
    const validationResult = passwordSchema.validate(password, { list: true });

    const isPasswordValid =
        Array.isArray(validationResult) && validationResult.length === 0;

    const message = Array.isArray(validationResult)
        ? validationResult[0]
        : undefined;
    const validationMessage = message
        ? PASSWORD_MESSAGE_HASH[message]
        : undefined;

    return [isPasswordValid, validationMessage];
}

// Used to verify backend error codes to be used as translation keys
export const validateErrorCode = (
    error: INetworkError,
    defaultKey: string
): string => {
    const { response } = error;
    const { data } = response?.data?.result || {};

    return matchSimple()
        .on(data === 'same_tenants_in_dpd_error', data)
        .otherwise(defaultKey);
};
