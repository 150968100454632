import React, {
    useState,
    memo,
    Fragment,
    useEffect,
    useCallback,
    useLayoutEffect
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import clsx from 'clsx';

import {
    deleteDpd,
    delProtocol,
    getDpds,
    postDpd,
    predictObjects,
    showSnackbar,
    sortingCrud
} from '../../store/action-creators';

import { ONE_DPD, DASHBOARD_ROUTE } from '../../configs/routes';
import { debounce, matchSimple } from '../../utils';
import { reorder } from '../../modules/array';
import { dispatchAsync } from '../../store/storeModule';
import { getPaginationParams } from '../../modules/pagination';
import { detectScrollEnd } from '../../modules/scroll';

import useToggle from '../../hooks/useToggle';
import useMatchMedia from '../../hooks/useMatchMedia';
import usePending from '../../hooks/usePending';
import useDelayedPending from '../../hooks/useDelayedPending';

import {
    DragDropContext,
    Draggable,
    Droppable,
    DropResult
} from 'react-beautiful-dnd';
import { MenuItem, MenuSurfaceAnchor } from '@rmwc/menu';

import Header from '../../components/inventory/Header';
import ObjectCard from '../../components/inventory/ObjectCard';
import StdButton from '../../components/atoms/StdButton';
import EditDpdModal from '../../components/inventory/EditDpdModal';
import EditObjectModal from '../../components/inventory/EditObjectModal';
import ObjectConfirmDeleteModal from "../../components/molecules/ObjectConfirmDeleteModal/ObjectConfirmDeleteModal";
import PropertyCard from '../../components/inventory/PropertyCard';
import ObjectModal from '../../components/inventory/ObjectModal';
import StdDivider from '../../components/atoms/StdDivider';
import StdDropdown from '../../components/atoms/StdDropdown';
import SearchInput from '../../components/molecules/SearchInput';
import PaginatedList from '../../components/molecules/PaginatedList';
import i18n from '../../i18next';

const lang = i18n.language as ILang;

const searchObject = debounce(
    (dispatch: any, building_id: number, search: string) => {
        dispatch(getDpds('building_objects', { search, building_id, lang }));
    }
);

const fetchLoadingConfig = [{ action: getDpds, fetchType: 'building_objects' }];

interface IProps {
    building: IDpd;
    handleFetch: ICallback;
}

// I.e Building page
const PropertyPage: React.FC<IProps> = ({ building, handleFetch }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();

    const openProtocols = useSelector(state => state.protocol.protocols.response.results);
    const [search, setSearch] = useState('');

    const objectsLoadingInst = usePending(fetchLoadingConfig);
    const objectsLoading = useDelayedPending([postDpd, ...fetchLoadingConfig]);

    const objectRes = useSelector(
        (state: IRootState) => state.dpds.buildings[building.dpd_id]
    );
    const objects = objectRes ? objectRes.response.results : [];
    const objectCount = objectRes ? objectRes.response.count : 0;

    const { hasNext, hasPrevious } = objectRes?.paginate || {};

    const isPhone = useMatchMedia('mobile');

    const [menuOpen, setMenuOpen] = useState(false);
    const [addOpen, setAddOpen] = useState(false);
    const [delOpen, setDelOpen] = useState(false);
    const [viewOpen, setViewOpen] = useState(false);
    const [edit, onEditOpen, onEditClose] = useToggle<IScrollType>({
        cleanupOnChange: true
    });

    const fetchObjects = useCallback(() => {
        dispatch(
            getDpds('building_objects', {
                building_id: building.dpd_id,
                search,
                lang
            })
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, building.dpd_id]);

    useEffect(() => {
        fetchObjects();
    }, [fetchObjects]);

    const getMoreObjects = useCallback(
        (direction?: IPaginateDirection) => {
            if (!objectRes) return;

            const params = getPaginationParams(objectRes.paginate, direction);

            dispatch(
                getDpds(
                    'building_objects',
                    { ...params, building_id: building.dpd_id, search, lang },
                    direction
                )
            );
        },
        [dispatch, objectRes, building.dpd_id, search]
    );

    const handleScroll = useCallback(
        (element: HTMLElement) => {
            if (hasNext && !objectsLoadingInst && detectScrollEnd(element)) {
                getMoreObjects('forwards');
            }
        },
        [objectsLoadingInst, hasNext, getMoreObjects]
    );

    useLayoutEffect(() => {
        const onScroll = (event: Event) => {
            const target = event.target as HTMLElement;
            const element =
                event.target === document ? document.documentElement : target;

            handleScroll(element);
        };

        window.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, [handleScroll]);

    const onObjectDragEnd = (result: DropResult) => {
        const { source, destination } = result;
        if (!destination || source.index === destination.index) return;

        const updatedObjects = reorder(
            objects,
            source.index,
            destination.index
        );

        dispatch(predictObjects(building.dpd_id, updatedObjects));
        dispatch(
            sortingCrud({
                srt_dpd_id: building.dpd_id,
                context: 'srt_dpd_id',
                srt_order: updatedObjects.map(({ dpd_id }) => ({ pk: dpd_id })),
                srt_is_dpd_order: true
            })
        );
    };

    const handlePendingProtocolDelete = (dpdId: number) => { // delete all the protocols of all the child properties when deleting from property page
        openProtocols.forEach((openProtocol) => {
            if (openProtocol.prt_dpd_id?.dpd_assoc_data?.dpd_id === dpdId) {
                dispatchAsync(dispatch, delProtocol(openProtocol.prt_id)).catch(
                    ({ error }) =>
                        dispatch(
                            showSnackbar({
                                message: 'delete_protocol_error',
                                error
                            })
                        )
                );
            }
        });
    }

    const handleDelete = () => {
        handlePendingProtocolDelete(building.dpd_id);
        dispatchAsync(dispatch, deleteDpd(building.dpd_id))
            .then((_) => history.push(DASHBOARD_ROUTE))
            .catch(({ error }) =>
                dispatch(
                    showSnackbar({ message: 'dpd_delete_fail_message', error })
                )
            );
        setDelOpen(false);
    };

    const handleSearch = (value: string) => {
        setSearch(value);
        searchObject(dispatch, building.dpd_id, value);
    };

    const itemSelect = (e: ISelectEvent) => {
        const {
            detail: { item }
        } = e;
        const value = item.dataset['value'];
        switch (value) {
            case 'edit_property':
                onEditOpen();
                break;
            case 'delete_property':
                setDelOpen(true);
                break;
        }
    };

    const actionsNode = matchSimple()
        .on(
            isPhone,
            <MenuSurfaceAnchor>
                <StdDropdown
                    open={menuOpen}
                    onClose={() => setMenuOpen(false)}
                    onSelect={itemSelect}
                >
                    <MenuItem data-value="edit_property">
                        {t('edit_object')}
                    </MenuItem>
                    <MenuItem data-value="delete_property">
                        {t('delete_object')}
                    </MenuItem>
                </StdDropdown>
                <StdButton
                    type="round"
                    onClick={() => setMenuOpen(true)}
                    leadingIcon={{
                        prefix: 'far',
                        name: 'ellipsis-v'
                    }}
                />
            </MenuSurfaceAnchor>
        )
        .otherwise(
            <div>
                <StdButton className="inline-m" onClick={() => onEditOpen()}>
                    {t('edit')}
                </StdButton>
                <StdButton
                    type="round"
                    onClick={() => setDelOpen(true)}
                    leadingIcon={{ name: 'trash' }}
                />
            </div>
        );

    const buildingAddress = `${building.dpd_street || '----'} ${
        building.dpd_street_number || '--'
    }`;
    return (
        <div className="property__page">
            <Header title={buildingAddress} />
            <div className="property__wrapper">
                <div className="property__section">
                    <div className="std-section util__wrap stack-l">
                        <span className="font__title">{t('property')}</span>
                        {actionsNode}
                    </div>
                    <PropertyCard
                        className="stack-m"
                        block={building}
                        onView={() => setViewOpen(true)}
                    />
                </div>
                <StdDivider />
                <div className="property__content">
                    <div className="property__search-section">
                        <span className="property__count-container">
                            <span className="property__subtitle inline-l">
                                {t('objects')}
                            </span>
                            <span className="property__count">
                                {objectCount}
                            </span>
                        </span>
                        <span className="property__search">
                            <SearchInput
                                value={search}
                                onChange={(e) => handleSearch(e.target.value)}
                                width="100%"
                                placeholder={t(
                                    'building_page.search_for_object'
                                )}
                            />
                        </span>
                        <span className="property__add-container">
                            <StdButton
                                type="round"
                                onClick={() => setAddOpen(true)}
                                leadingIcon={{ name: 'plus' }}
                            >
                                {t('object')}
                            </StdButton>
                        </span>
                    </div>
                    <div className="property__items">
                        {!objectsLoading && hasPrevious && (
                            <div className="dashboard__load-more">
                                <StdButton
                                    onClick={() => getMoreObjects('backwards')}
                                >
                                    {t('load_previous')}
                                </StdButton>
                            </div>
                        )}
                        {objectRes && (
                            <PaginatedList
                                pagination={objectRes.paginate}
                                loading={objectsLoading}
                            >
                                <DragDropContext onDragEnd={onObjectDragEnd}>
                                    <Droppable droppableId="droppable-objects">
                                        {(provided, snapshot) => {
                                            return (
                                                <div
                                                    className={clsx(
                                                        snapshot.isDraggingOver &&
                                                        'dragging-list'
                                                    )}
                                                    ref={provided.innerRef}
                                                    {...provided.droppableProps}
                                                >
                                                    {objects.map(
                                                        (object, index) => {
                                                            return (
                                                                <Fragment
                                                                    key={
                                                                        object.dpd_id
                                                                    }
                                                                >
                                                                    <Draggable
                                                                        key={
                                                                            object.dpd_id
                                                                        }
                                                                        draggableId={object.dpd_id.toString()}
                                                                        index={
                                                                            index
                                                                        }
                                                                    >
                                                                        {(
                                                                            provided,
                                                                            snapshot
                                                                        ) => {
                                                                            return (
                                                                                <div
                                                                                    ref={
                                                                                        provided.innerRef
                                                                                    }
                                                                                    {...provided.draggableProps}
                                                                                    // Note: quite clearly a typing bug of some sort
                                                                                    {...(provided.dragHandleProps as any)}
                                                                                >
                                                                                    <ObjectCard
                                                                                        parentId={
                                                                                            object.dpd_id
                                                                                        }
                                                                                        key={
                                                                                            object.dpd_id
                                                                                        }
                                                                                        subDpd={
                                                                                            object
                                                                                        }
                                                                                        link={ONE_DPD(
                                                                                            building.dpd_id,
                                                                                            object.dpd_id
                                                                                        )}
                                                                                        isDragging={
                                                                                            snapshot.isDragging
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            );
                                                                        }}
                                                                    </Draggable>
                                                                    <div className="stack-s" />
                                                                </Fragment>
                                                            );
                                                        }
                                                    )}
                                                    {provided.placeholder}
                                                </div>
                                            );
                                        }}
                                    </Droppable>
                                </DragDropContext>
                            </PaginatedList>
                        )}
                    </div>
                </div>
            </div>
            <EditDpdModal
                dpd={building}
                open={edit.open}
                onClose={onEditClose}
                onSubmit={handleFetch}
                toAttr={edit.item === 'attr'}
                toAttach={edit.item === 'attach'}
            />
            <ObjectModal
                dpd={building}
                open={viewOpen}
                onClose={() => setViewOpen(false)}
                onEdit={onEditOpen}
                mapEnabled
            />
            <EditObjectModal
                parentId={building.dpd_id}
                open={addOpen}
                onClose={() => setAddOpen(false)}
                onSuccess={fetchObjects}
            />
            <ObjectConfirmDeleteModal
                className="stack-m"
                open={delOpen}
                onClose={() => setDelOpen(false)}
                onConfirm={handleDelete}
                message={t('delete_dpd_message', {
                    dpd_address: buildingAddress
                })}
                title={buildingAddress}
                verifyNote={t('dpd_delete_confirm_note')}
                verifyMessage="YES"
            />
        </div>
    );
};

export default memo(PropertyPage);
