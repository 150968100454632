import * as actions from '../actions/appState';
import { createCustomAction, createAction } from 'typesafe-actions';
import { actionFail, actionPayload } from '../storeModule';

interface IUploadParams {
    file: File;
    params: IHash<string | number>;
    onMediaCreated?: (oldId: number, newId: number) => void;
}

export const uploadMedia = createCustomAction(
    actions.UPLOAD_MEDIA_FUNCTION_REQUEST,
    (type) => {
        return (params: IUploadParams) => ({ type, params });
    }
);
export const uploadMediaSuccess = createCustomAction(
    actions.UPLOAD_MEDIA_FUNCTION_SUCCESS,
    actionPayload
);
export const uploadMediaFail = createCustomAction(
    actions.UPLOAD_MEDIA_FUNCTION_FAIL,
    actionFail
);

export const postMedia = createCustomAction(
    actions.CREATE_MEDIA_REQUEST,
    (type) => {
        return (file: File, params: IHash<string | number>) => ({
            type,
            file,
            params
        });
    }
);
export const createMediaObject = createCustomAction(
    'CREATE_MEDIA_OBJECT',
    (type) => {
        return (file: File, params: IHash<string | number>) => ({
            type,
            file,
            params
        });
    }
);
export const postMediaSuccess = createCustomAction(
    actions.CREATE_MEDIA_SUCCESS,
    actionPayload
);
export const postMediaFail = createCustomAction(
    actions.CREATE_MEDIA_FAIL,
    actionFail
);

interface IPatchMediaPayload {
    med_adv_id?: number | null;
    med_prt_id?: number | null;
    med_iss_id?: number | null;
    //...
}

export const patchMedia = createCustomAction(
    actions.PATCH_MEDIA_REQUEST,
    (type) => {
        return (medId: number, payload: IPatchMediaPayload) => ({
            type,
            medId,
            payload
        });
    }
);
export const patchMediaSuccess = createCustomAction(
    actions.PATCH_MEDIA_SUCCESS,
    actionPayload
);
export const patchMediaFail = createCustomAction(
    actions.PATCH_MEDIA_FAIL,
    actionFail
);

export const renameMedia = createCustomAction(
    actions.RENAME_MEDIA_REQUEST,
    (type) => {
        return (id: number, name: string) => ({ type, id, name });
    }
);
export const renameMediaSuccess = createCustomAction(
    actions.RENAME_MEDIA_SUCCESS,
    actionPayload
);
export const renameMediaFail = createCustomAction(
    actions.RENAME_MEDIA_FAIL,
    actionFail
);

export const deleteMedia = createCustomAction(
    actions.DELETE_MEDIA_REQUEST,
    (type) => {
        return (id: number) => ({ type, id });
    }
);
export const deleteMediaSuccess = createCustomAction(
    actions.DELETE_MEDIA_SUCCESS,
    actionPayload
);
export const deleteMediaFail = createCustomAction(
    actions.DELETE_MEDIA_FAIL,
    actionFail
);

export const deleteLocalMedia = createCustomAction(
    actions.DELETE_LOCAL_MEDIA,
    (type) => {
        return (id: number) => ({ type, id });
    }
);

export const showSnackbar = createCustomAction(
    actions.SHOW_SNACKBAR,
    (type) => {
        return (params: ISnackbarParams) => ({ type, params });
    }
);
export const hideSnackbar = createCustomAction(actions.HIDE_SNACKBAR);

export const getVersion = createAction(actions.GET_VERSION_REQUEST);
export const getVersionSuccess = createCustomAction(
    actions.GET_VERSION_SUCCESS,
    actionPayload
);
export const getVersionFail = createCustomAction(
    actions.GET_VERSION_FAIL,
    actionFail
);

interface IEleSort {
    context: 'srt_rom_id';
    srt_rom_id: number;
    srt_order: { pk: number }[];
}
interface IProtocolElementSort {
    protocol_room: number;
    context: 'protocol_room';
    srt_order: { pk: number }[];
}
interface IRoomSort {
    context: 'srt_dpd_id';
    srt_dpd_id: number;
    srt_order: { pk: number }[];
}

interface IProtocolRoomSort {
    protocol: number;
    context: 'protocol';
    srt_order: { pk: number }[];
}

interface IObjectSort {
    context: 'srt_dpd_id';
    srt_dpd_id: number;
    srt_order: { pk: number }[];
    srt_is_dpd_order: true;
}

type ISortPayload =
    | IEleSort
    | IRoomSort
    | IObjectSort
    | IProtocolElementSort
    | IProtocolRoomSort;

export const sortingCrud = createCustomAction(
    actions.SORTING_CRUD_REQUEST,
    (type) => {
        return (payload: ISortPayload) => ({ type, payload });
    }
);
export const sortingCrudSuccess = createCustomAction(
    actions.SORTING_CURD_SUCCESS
);
export const sortingCrudFail = createCustomAction(
    actions.SORTING_CRUD_FAIL,
    actionFail
);

export const setCoreLoading = createCustomAction(
    actions.SET_CORE_LOADING,
    (type) => {
        return (value: boolean) => ({ type, value });
    }
);
export const setAccountLoading = createCustomAction(
    actions.SET_ACCOUNT_LOADING,
    (type) => {
        return (value: boolean) => ({ type, value });
    }
);
export const setCheckoutLoading = createCustomAction(
    actions.SET_CHECKOUT_LOADING,
    (type) => {
        return (value: boolean) => ({ type, value });
    }
);
export const decrementCounter = createCustomAction(actions.DECREMENT_COUNTER);

export const onUploadMediaProgress = createCustomAction(
    actions.ON_MEDIA_UPLOAD_PROGRESS,
    (type) => {
        return (id: number, payload: number) => ({ type, id, payload });
    }
);

export const onUploadMediaRequest = createCustomAction(
    actions.ON_MEDIA_UPLOAD_REQUEST,
    (type) => {
        return (file: File, awsData: ICreateMediaResult) => ({
            type,
            file,
            awsData
        });
    }
);
export const onUploadMediaSuccess = createCustomAction(
    actions.ON_MEDIA_UPLOAD_SUCCESS,
    (type) => {
        return (id: number) => ({ type, id });
    }
);
export const onUploadMediaFail = createCustomAction(
    actions.ON_MEDIA_UPLOAD_FAIL,
    (type) => {
        return (id: number) => ({ type, id });
    }
);
export const onUploadMediaCancel = createCustomAction(
    actions.ON_MEDIA_UPLOAD_CANCEL,
    (type) => {
        return (id: number) => ({ type, id });
    }
);

export const setUploadPreview = createCustomAction(
    actions.SET_MEDIA_UPLOAD_PREVIEW,
    (type) => {
        return (id: number, preview: string) => ({ type, id, preview });
    }
);

export const setUploadThumbnailPreview = createCustomAction(
    actions.SET_MEDIA_UPLOAD_THUMBNAIL_PREVIEW,
    (type) => {
        return (id: number, thumbnail: string, videoBase?: string) => ({
            type,
            id,
            thumbnail,
            videoBase
        });
    }
);

export const cancelUpload = createCustomAction(
    actions.CANCEL_MEDIA_UPLOAD,
    (type) => {
        return (id?: number) => ({ type, id });
    }
);

export const onOfflineModeSwitch = createAction(actions.ON_OFFLINE_MDOE_SWITCH);
export const onOnlineModeSwitch = createAction(actions.ON_ONLINE_MODE_SWITCH);

export const setScreenSize = createCustomAction(
    actions.SET_SCREEN_SIZE,
    (type) => {
        return (size: IScreenSizeType) => ({ type, size });
    }
);
