import React from 'react';
import clsx from 'clsx';
import StdIcon from '../../atoms/StdIcon';

import { Ripple } from '@rmwc/ripple';

import './avatar.scss';

interface IProps extends IClass {
    onClick?: ICallback;
}

const Avatar: React.FC<IProps> = ({ className, onClick }) => {
    return (
        <div className={clsx('avatar__container', className)}>
            <Ripple>
                <div className="avatar__content" onClick={onClick}>
                    <StdIcon name="user" grey />
                </div>
            </Ripple>
        </div>
    );
};

export default Avatar;
