import { takeLatest } from 'redux-saga/effects';

import { POST_OFFLINE_PROTOCOL_CHECK_FINISH_READINESS_REQUEST } from '../../actions';
import {
    checkOfflineProtocolFinishReadiness,
    checkOfflineProtocolFinishReadinessSuccess,
    checkOfflineProtocolFinishReadinessFail
} from '../../action-creators';

import { simpleRequest } from '../modules/request';

export default takeLatest(
    POST_OFFLINE_PROTOCOL_CHECK_FINISH_READINESS_REQUEST,
    simpleRequest<RT<typeof checkOfflineProtocolFinishReadiness>>({
        method: 'GET',
        success: checkOfflineProtocolFinishReadinessSuccess,
        failure: checkOfflineProtocolFinishReadinessFail,
        getUrl: (action) => `protocol/${action.prt_id}/ready/`,
        noBlock: true
    })
);
