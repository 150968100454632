import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { MEDIA_ATTACHMENT_TYPE } from '../../../configs/costants';
import { DPD_TYPE } from '../../../configs/basicData';
import { safeByIds } from '../../../store/storeModule';
import { partition } from '../../../modules/array';

import { attrListToStr } from '../../../utils';
import {
    propertyAddressToForm,
    propertyToIcon
} from '../../../modules/property';

import useGoogleMap from '../../../hooks/useGoogleMap';
import useMatchMedia from '../../../hooks/useMatchMedia';

import StdIcon from '../../atoms/StdIcon';
import Map from '../../misc/Map';
import StdMedia from '../../atoms/StdMedia';
import StdButton from '../../atoms/StdButton';

import './property-card.scss';

const propertyMapOptions = {
    streetViewControl: false,
    mapTypeControl: false,
    zoomControl: false
};

interface IProps extends IClass {
    block: IDpd;
    onView?: ICallback;
}

const PropertyCard: React.FC<IProps> = ({ className, block, onView }) => {
    const { t } = useTranslation();
    const {
        dpd_type,
        dpd_obj_count,
        dpd_iss_count,
        dpd_new_iss_count,
        dpd_street,
        dpd_street_number,
        dpd_city,
        dpd_zip,
        dpd_attributes,
        uploads
    } = block;
    const typeChar = dpd_type || '';

    const isPhone = useMatchMedia('mobile');

    const { mapLoading, handleLoadMap, handleLocate } = useGoogleMap();

    const uploadStore = useSelector((state) => state.uploads.uploads);

    const lookupHash = useSelector((state) => state.basicData.response);
    const dpdType = lookupHash?.[DPD_TYPE]?.[typeChar] || t('dpd');

    const objNum = dpd_obj_count || 0;
    const issNum = dpd_iss_count || 0;
    const newIssNum = dpd_new_iss_count || 0;

    const dpdAttrs = Array.isArray(dpd_attributes) ? dpd_attributes : [];
    const { listVal } = attrListToStr(
        dpdAttrs.map((attr) => attr.dpa_value || ''),
        { charLimit: 50, charPerItem: 15 }
    );

    const storedUploads = safeByIds(uploads as number[], uploadStore.byIds);

    const [, [picture]] = partition(
        storedUploads,
        (upload) => upload.type === MEDIA_ATTACHMENT_TYPE
    );

    useEffect(() => {
        if (!picture && block && !mapLoading)
            handleLocate(propertyAddressToForm(block));
    }, [block, picture, mapLoading, handleLocate]);

    const onMapLoad = handleLoadMap(undefined, true);

    const viewHandler = picture && onView;

    return (
        <div className={clsx('property-card__container', className)}>
            <div
                className={clsx(
                    'property-card__image',
                    isPhone ? 'stack-m' : 'inline-m',
                    viewHandler && 'util__pointer'
                )}
                onClick={viewHandler}
            >
                {picture ? (
                    <StdMedia
                        icon={propertyToIcon(typeChar)}
                        media={picture}
                        width={343}
                        height={160}
                        alt="property image"
                    />
                ) : (
                    <Map options={propertyMapOptions} onMapLoad={onMapLoad} />
                )}
            </div>
            <div className="property-card__content">
                <div
                    className={clsx(
                        'font__header',
                        'stack-m',
                        onView && 'util__pointer'
                    )}
                    onClick={onView}
                >
                    {dpd_street} {dpd_street_number} · {dpd_zip} {dpd_city}
                </div>
                <div className="property-card__info stack-m">
                    <div className="stack-s">
                        <StdIcon
                            className="inline-s"
                            name={propertyToIcon(typeChar)}
                        />
                        <span className="inline-xs">{dpdType}</span>
                        <span className="inline-xs">·</span>
                        <span>
                            {objNum} {t('objects')}
                        </span>
                    </div>
                    <div>
                        <StdIcon
                            className="inline-s"
                            name="exclamation-circle"
                        />
                        <span
                            className={clsx(Boolean(newIssNum) && 'inline-xs')}
                        >
                            {issNum} {issNum > 1 ? t('issues') : t('issue')}
                        </span>
                        {Boolean(newIssNum) && (
                            <>
                                <span className="inline-xs">·</span>
                                <span className="color-danger">
                                    {newIssNum} {t('new')}
                                </span>
                            </>
                        )}
                    </div>
                </div>
                <div>
                    <span>
                        {Boolean(listVal) && (
                            <span
                                className="info__attributes inline-xs"
                                onClick={onView}
                            >
                                {listVal}
                            </span>
                        )}
                        <StdButton
                            className="info__action"
                            leadingIcon={{ name: 'plus' }}
                            onClick={onView}
                            size="small"
                        >
                            {t('info')}
                        </StdButton>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default PropertyCard;
