import * as actions from '../actions/choices';
import { createCustomAction, createAction } from 'typesafe-actions';
import { actionFail, actionPayload } from '../storeModule';

export const getChoices = createCustomAction(
    actions.GET_CHOICES_REQUEST,
    (type) => {
        return (lang: ILang) => ({ type, lang });
    }
);
export type IGetChoices = ReturnType<typeof getChoices>;

export const getChoicesSuccess = createCustomAction(
    actions.GET_CHOICES_SUCCESS,
    actionPayload
);
export const getChoicesFail = createCustomAction(
    actions.GET_CHOICES_FAIL,
    actionFail
);
