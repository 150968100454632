import passwordValidator from 'password-validator';

const schema = new passwordValidator();

schema
    .is()
    .min(7) // Minimum length 7
    .is()
    .max(20) // Maximum length 20
    .has()
    .letters()
    .has()
    .not()
    .spaces(); // Should not have spaces

export default schema;
