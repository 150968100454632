import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useMatchMedia from '../../../hooks/useMatchMedia';

import StdButton from '../../atoms/StdButton';
import StdTextField from '../../atoms/StdTextField';
import useInput from '../../../hooks/useInput';
import useValidation from '../../../hooks/useValidation';
import { validate } from '../../../utils/validation';
import { dispatchAsync } from '../../../store/storeModule';
import { isEmpty } from 'validator';
import { useDispatch, useSelector } from 'react-redux';
import {
    showSnackbar,
    patchAccount,
} from '../../../store/action-creators';
import StdDivider from '../../atoms/StdDivider';
import ProfileLogoUploader from '../ProfileLogoUploader/ProfileLogoUploader';
import StdSelect from '../../atoms/StdSelect';
import clsx from 'clsx';
import { PERSONA_TYPE } from '../../../configs/basicData';

interface IProps {
    name?: string;
    phone?: string;
    email?: string;
    companyLogo?: IUpload;
    onNameEdit?: ICallback;
    onPhoneEdit?: ICallback;
    onPasswordReset?: ICallback;
    editedName?: string;
    editedPhone?: string;
    onChangePassword?: ICallback;
    role?: string;
}
interface IForm {
    form: {
        name: string;
        phone: string;
        email: string | undefined;
        old_password: string | undefined;
    };
    errors: IErrorHash;
}

const initForm: IForm = {
    form: {
        name: '',
        phone: '',
        email: '',
        old_password: ''
    },
    errors: {}
};

const ProfileDetialsSection: React.FC<IProps> = ({
    editedName,
    editedPhone,
    role,
    companyLogo,
    email,
    onChangePassword
}) => {
    const { t, i18n } = useTranslation();
    const languageSelectorOptions: any = {
        en: "English",
        fr: "French",
        de: "German",
        it: "Italian"
    }
    const isPhone = useMatchMedia('mobile');
    const [input, setInput] = useState(initForm);
    const [editButtonState, setEditButtonState] = useState(false);
    const [formDisableState, setFormDisableState] = useState(true);
    const [passwordDisableState, setPasswordDisableState] = useState(true);
    const [lang, setLang] = useState(languageSelectorOptions[i18n.language]);
    const dispatch = useDispatch();
    const lookupHash = useSelector((state) => state.basicData.response);
    const userPersonaKey = useSelector((state) => state.user.user.response?.persona);
    const personaData = lookupHash?.[PERSONA_TYPE];
    const userRole = (personaData && userPersonaKey) ? personaData[userPersonaKey] : '----';

    const languageSelectorArray = ["English", "French", "German", "Italian"];

    useEffect(() => {
        if (editedName || editedPhone || email) {
            setInput((s) => ({
                ...s,
                form: {
                    ...s.form,
                    name: editedName || "",
                    phone: editedPhone || "",
                    email: email || ""
                }
            }));
        }
        setLang(languageSelectorOptions[i18n.language])
    }, [editedName, editedPhone, email, i18n.language]);

    const handleInput = useInput(setInput);
    const validateFields = useValidation(input, setInput, (hash) => {
        const { name, phone, email } = input.form;
        return [validate({ name, phone }, !isEmpty(name), hash)].every(Boolean);
    });

    const changeAppLanguage = (language: string) => {
        if (language === "English") {
            i18n.changeLanguage("en")
        }
        else if (language === "French") {
            i18n.changeLanguage("fr")
        }
        else if (language === "German") {
            i18n.changeLanguage("de")
        }
        else if (language === "Italian") {
            i18n.changeLanguage("it")
        }
    }

    const makeRequest = () => {
        if (validateFields()) {
            if (!formDisableState) {
                const { name, phone } = input.form;
                dispatchAsync(
                    dispatch,
                    patchAccount({
                        fullname: name,
                        ...(phone && {mobile_number: phone})
                    })
                )
                    .then(() => {
                        changeAppLanguage(lang);
                        setFormDisableState(true);
                        setEditButtonState(false);
                    })
                    .catch(({ error }) =>
                        dispatch(
                            showSnackbar({
                                message: 'change_name_error',
                                error
                            })
                        )
                    );
            }
        }
    };

    const { form, errors } = input;
    const profileSettingClassName = `profile__setting profile__space ${formDisableState ? 'disable_property' : ''}`;

    return (
        <div className="profile__section">
            <div className="font--secondary stack-ll">{t('account')}</div>
            <div className='profile__sectionDevision'>
                <div className='profile__form'>
                    <div className={profileSettingClassName}>
                        <StdTextField
                            label={t('usersname')}
                            value={form.name}
                            disabled={formDisableState}
                            name="name"
                            error={errors['name']}
                            width="100%"
                            onChange={handleInput}
                        />
                    </div>
                    <div className={profileSettingClassName}>
                        <StdTextField
                            label={t('phone_no')}
                            value={form.phone}
                            name="phone"
                            disabled={formDisableState}
                            error={errors['phone']}
                            width="100%"
                            onChange={handleInput}
                        />
                    </div>
                    <div className={profileSettingClassName}>
                        <StdTextField
                            label={t('email')}
                            value={form.email}
                            name="email"
                            disabled={formDisableState || editButtonState}
                            error={errors['email']}
                            className={editButtonState ? 'emailDisabled' : ''}
                            width="100%"
                            onChange={(e) => e.preventDefault()}
                        />

                    </div>
                    <div className={profileSettingClassName}>
                        <StdSelect
                            className="stack-m"
                            label={t('language')}
                            name="type"
                            value={lang}
                            disabled={formDisableState}
                            options={languageSelectorArray}
                            error={errors['type']}
                            width="100%"
                            onChange={(e) => setLang(e.target.value)}
                        ></StdSelect>
                    </div>
                    <div
                        className={`stack-m profile__btnWrap ${
                            editButtonState ? 'profile__enableBtn' : ''
                        }`}
                    >
                        {editButtonState ? (
                            <div>
                                <StdButton
                                    size="large"
                                    onClick={() => {
                                        setEditButtonState(false);
                                        setFormDisableState(true);
                                    }}
                                >
                                    {t('cancel')}
                                </StdButton>
                            </div>
                        ) : null}
                        <div>
                            <StdButton
                                className="profile__editOptionBtn"
                                type={editButtonState ? 'primary' : 'secondary'}
                                size="large"
                                onClick={() => {
                                    setEditButtonState(true);
                                    setFormDisableState(false);
                                    makeRequest();
                                }}
                            >
                                {editButtonState
                                    ? t('profile.save_changes')
                                    : t('edit')}
                            </StdButton>
                        </div>
                    </div>
                </div>
                <div className="profile__logoUploader">
                    <div className={clsx({
                        'logoUploader-view': true,
                        'mobile': isPhone
                    })}>
                        <div className="profile__logoUploader__titleWrap">
                            <div className="profile__accountTitle stack-ll">
                                {t('account_role')}
                            </div>
                            <div className="profile__onwerName">
                                {userRole}
                            </div>
                        </div>
                    </div>
                    <div className="profile__form ">
                        <div className="font--secondary profile__section_subTitle stack-ll">
                            {t('password')}
                        </div>
                        <div className={profileSettingClassName}>
                            <StdTextField
                                className={
                                    passwordDisableState
                                        ? 'resetPasswordBtn'
                                        : ''
                                }
                                label={t('****************')}
                                name="old_password"
                                disabled={passwordDisableState}
                                error={errors['old_password']}
                                width="100%"
                                onChange={handleInput}
                                type="password"
                            />
                        </div>
                        <div className="stack-m profile__btnWrap">
                            <StdButton
                                className="profile__changePasswordBtn"
                                type={
                                    !passwordDisableState
                                        ? 'primary'
                                        : 'secondary'
                                }
                                size="large"
                                onClick={onChangePassword}
                            >
                                {passwordDisableState
                                    ? t('change_password_title')
                                    : t('save')}
                            </StdButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileDetialsSection;
