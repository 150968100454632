import { useRef, useCallback } from 'react';
import { isMobileQuery } from '../utils';

export interface IScrollToOptions {
    onlyMobile?: boolean;
    block?: ScrollLogicalPosition;
    inline?: ScrollLogicalPosition;
}

const useScrollToContent = ({
    onlyMobile,
    block = 'nearest',
    inline = 'nearest'
}: IScrollToOptions = {}): [
    React.MutableRefObject<HTMLDivElement | null>,
    ICallback
] => {
    const contentRef = useRef<HTMLDivElement | null>(null);

    const scrollToContent = useCallback(() => {
        const content = contentRef.current;

        // Note: Coupled to scss
        if (
            content?.scrollIntoView &&
            (!onlyMobile || isMobileQuery().matches)
        ) {
            content.scrollIntoView({ block, inline, behavior: 'smooth' });
        }
    }, [onlyMobile, block, inline]);

    return [contentRef, scrollToContent];
};

export default useScrollToContent;
