import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { parseFeaturePartial } from '../../../modules/plans';

import './included-feature.scss';

interface IProps {
    feature: IFeature;
}

const IncludedFeature: React.FC<IProps> = ({ feature: planFeature }) => {
    const { t } = useTranslation();
    const { feature, allowed } = planFeature;

    const { includedTitle } = parseFeaturePartial(planFeature);

    const enabledTitle = Boolean(allowed)
        ? t('plan.unlimited.feature.included')
        : t('plan.unlimited.feature.excluded');
    const featureName = t(`plan.backend.feature.included.${feature}`, {
        amount: includedTitle,
        is_enabled: enabledTitle
    });

    return (
        <div className="included-feature__container">
            <Trans t={t} components={{ bold: <strong /> }}>
                {featureName}
            </Trans>
        </div>
    );
};

export default IncludedFeature;
