export function cancelErrorFactory() {
    return new Error('promise_cancelled');
}

export function isCancelError(error: Error) {
    return error.message === 'promise_cancelled';
}

export const isNetworkDownError = (error: Error) =>
    error.message === 'Network Error';
