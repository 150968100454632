import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'validator';

import { validate } from '../../../utils/validation';

import useInput from '../../../hooks/useInput';
import useValidation from '../../../hooks/useValidation';

import StdTextField from '../../atoms/StdTextField';
import StdButton from '../../atoms/StdButton';

import './element-form.scss';

interface IForm {
    form: {
        name: string;
    };
    errors: IErrorHash;
}

const initForm: IForm = {
    form: {
        name: ''
    },
    errors: {}
};

interface IProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    onElementAdd: (name: string) => void;
}

export const ElementForm: React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    const ref = useRef<HTMLInputElement>(null);

    const [input, setInput] = useState(initForm);
    const { form, errors } = input;

    const handleInput = useInput(setInput);

    useEffect(() => {
        const input = ref.current;
        if (props.open && input && input.focus) {
            input.focus();
        }
    }, [props.open]);

    const onCancel = () => {
        props.setOpen(false);
        setInput(initForm);
    };

    const onSubmit = () => {
        if (validateFields()) {
            props.onElementAdd(form.name);
            onCancel();
        }
    };

    const validateFields = useValidation(input, setInput, (hash) => {
        const { name } = input.form;
        return [validate({ name }, !isEmpty(name), hash)].every(Boolean);
    });

    if (!props.open) {
        return (
            <div className="element-quick-form">
                <StdButton
                    leadingIcon={{ name: 'plus' }}
                    onClick={() => props.setOpen(true)}
                >
                    {t('element')}
                </StdButton>
            </div>
        );
    }

    return (
        <div className="element-quick-form">
            <div className="element-quick-form__content">
                <StdTextField
                    ref={ref}
                    label={t('element_name')}
                    value={form.name}
                    name="name"
                    error={errors['name']}
                    onChange={handleInput}
                    autoFocus
                    width="100%"
                />
                <div className="element-quick-form__buttons">
                    <StdButton
                        className="inline-s"
                        type="round"
                        onClick={onCancel}
                        leadingIcon={{ name: 'times' }}
                    />
                    <StdButton
                        type="round"
                        onClick={onSubmit}
                        leadingIcon={{
                            prefix: 'far',
                            name: 'check'
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

type IContainerProps = Omit<IProps, 'open' | 'setOpen'>;

const ElementFormContainer: React.FC<IContainerProps> = (props) => {
    const [adding, setAdding] = useState(false);
    return (
        <ElementForm
            open={adding}
            setOpen={(value) => setAdding(value)}
            {...props}
        />
    );
};

export default ElementFormContainer;
