import { takeEvery, all, put } from 'redux-saga/effects';

import {
    PATCH_PROTOCOL_ITEM_REQUEST,
    PATCH_PROTOCOL_ITEM_SUCCESS,
    PATCH_PROTOCOL_ITEM_FAIL
} from '../../actions';
import {
    protoPatchItem,
    protoPatchItemSuccess,
    protoPatchItemFail,
    getProtocolItem
} from '../../action-creators';

import { simpleRequest } from '../modules/request';
import { normalizeProtocolItem } from '../modules/normalization';
import { responseHelper } from '../modules/promise';

type IPatchItemAction = RT<typeof protoPatchItem>;
type IPatchItemFailAction = RT<typeof protoPatchItemFail>;

function onSuccessCallback(data: any) {
    return normalizeProtocolItem(data);
}

const requestEffect = simpleRequest<IPatchItemAction>({
    success: protoPatchItemSuccess,
    failure: protoPatchItemFail,
    getBody: (action) => action.payload,
    getUrl: (action) => `protocol_item/${action.itemId}/`,
    getEntityId: (action) => action.itemId,
    method: 'PATCH',
    enableOffline: true
});

const responseEffect = responseHelper({
    actionName: PATCH_PROTOCOL_ITEM_SUCCESS,
    processFn: onSuccessCallback
});

export default all([
    takeEvery(PATCH_PROTOCOL_ITEM_REQUEST, requestEffect),
    takeEvery(
        PATCH_PROTOCOL_ITEM_FAIL,
        function* (action: IPatchItemFailAction) {
            if (action.itemId == null) return;
            yield put(getProtocolItem(action.itemId));
        }
    ),
    responseEffect
]);
