import { takeLatest } from 'redux-saga/effects';

import { ROOM_ATTR_CRUD_REQUEST } from '../../actions';
import {
    roomAttrCrud,
    roomAttrCrudSuccess,
    roomAttrCrudFail
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeLatest(
    ROOM_ATTR_CRUD_REQUEST,
    simpleRequest<ReturnType<typeof roomAttrCrud>>({
        success: roomAttrCrudSuccess,
        failure: roomAttrCrudFail,
        getBody: (action) => action.payload,
        getUrl: (_) => `room_attribute/room_attribute_crud/`,
        method: 'POST'
    })
);
