import { takeEvery } from 'redux-saga/effects';

import { GET_ISSUE_REQUEST } from '../../actions';
import { getIssue, getIssueSuccess, getIssueFail } from '../../action-creators';
import { simpleRequest } from '../modules/request';
import { normalizeIssue } from '../modules/normalization';

type IGetIssueAction = ReturnType<typeof getIssue>;

export default takeEvery(
    GET_ISSUE_REQUEST,
    simpleRequest<IGetIssueAction>({
        success: getIssueSuccess,
        failure: getIssueFail,
        getUrl: (action) => `issue/${action.issueId}/?lang=${action.lang}`,
        onSuccess: function (data) {
            return normalizeIssue(data);
        }
    })
);
