import { takeEvery } from 'redux-saga/effects';

import { DELETE_SIGNATURE_REQUEST } from '../../actions';
import {
    delSignature,
    delSignatureSuccess,
    delSignatureFail
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeEvery(
    DELETE_SIGNATURE_REQUEST,
    simpleRequest<RT<typeof delSignature>>({
        success: delSignatureSuccess,
        failure: delSignatureFail,
        getUrl: (action) => `signature/${action.signId}/`,
        method: 'DELETE',
        enableOffline: true
    })
);
