// import { AxiosRequestConfig } from 'axios';
import FileSaver from 'file-saver';
import JSZip from 'jszip';
import { Dispatch } from 'redux';
import { PROTOCOL_ROUTE_REGEX } from '../configs/costants';

import {
    showSnackbar,
    onOnlineModeSwitch,
    onOfflineModeSwitch,
    // switchModeAsynchronous,
    // switchModeSynchronous,
    backgroundSyncDiscard,
    setOfflineProtocolSyncingState
} from '../store/action-creators';
import { fetchResolvedImageData, isServiceWorkerRunning } from '../utils';
import { dateWithWithTime } from '../utils/formatter';
import { tokenManager } from './tokens';

export function checkConnection() {
    return fetch('/api/v1/ping/');
}

export function notifyWorkerOnlineMode({
    retry = false
}: {
    retry: boolean;
}): Promise<void> {
    return new Promise((resolve, reject) => {
        const { access } = tokenManager.get();

        if (!access) {
            reject(Error('user not logged in error'));
            return;
        }

        if (!isServiceWorkerRunning()) {
            reject(Error('sw.js is not running'));
            return;
        }

        const worker =
            'serviceWorker' in navigator
                ? navigator.serviceWorker.controller
                : null;
        if (worker == null) {
            reject(Error('sw.js is not avilable'));
            return;
        }

        // const typeMessage = useBeta && isAsync ? 'RUN_ASYNC_PROTOCOL_SYNC' : 'RUN_SYNC';

        checkConnection()
            .then((_) => {
                worker.postMessage({ type: 'RUN_SYNC', access, retry });
                resolve();
            })
            .catch((_) => {
                reject(Error('Network not available'));
            });
    });
}

export function handleOnlineSwitch(
    dispatch: Dispatch<AnyAction>,
    useBeta: boolean = false
) {
    console.log('running handleOnlineSwitch');
    return notifyWorkerOnlineMode({ retry: false })
        .then((_) => {
            dispatch(onOnlineModeSwitch());
            useBeta && dispatch(setOfflineProtocolSyncingState('SYNCING'));
        })
        .catch((_) => {
            dispatch(
                showSnackbar({ message: 'offline_mode.online_switch_failed' })
            );
        });
}
export async function handleOnlineSwitchForProtocolCheckin(
    dispatch: Dispatch<AnyAction>
) {
    return notifyWorkerOnlineMode({ retry: false })
        .then((_) => {
            dispatch(onOnlineModeSwitch());
            // useBeta && dispatch(setOfflineProtocolSyncingState('SYNCING'));
        })
        .catch((_) => {
            dispatch(
                showSnackbar({ message: 'offline_mode.online_switch_failed' })
            );
        });
}

export function handleOfflineSwitch(
    dispatch: Dispatch<AnyAction>,
    useBeta?: boolean
) {
    dispatch(onOfflineModeSwitch());
}

export function handleDiscardSync(
    dispatch: Dispatch<AnyAction>,
    useBeta: boolean = false
) {
    const worker =
        'serviceWorker' in navigator
            ? navigator.serviceWorker.controller
            : null;

    const runAsyncDiscard = Boolean(useBeta);

    if (worker != null) {
        worker.postMessage({ type: 'DISCARD_SYNC_DATA', runAsyncDiscard });

        dispatch(backgroundSyncDiscard());
    }
}

// valueType = 'CURRENT' | 'INITIAL';
export function updateSyncProgress(
    valueType: 'CURRENT' | 'INITIAL' = 'CURRENT'
) {
    const worker =
        'serviceWorker' in navigator
            ? navigator.serviceWorker.controller
            : null;

    if (worker != null) {
        worker.postMessage({ type: 'GET_SYNC_DATA', valueType });
    }
}

export function goToProtocolTab(event: any, prtId: number) {
    const worker =
        'serviceWorker' in navigator
            ? navigator.serviceWorker.controller
            : null;

    if (worker != null) {
        worker.postMessage({ type: 'FOCUS_TO_PROTOCOL', prtId });
    }
}

export function handleRetrySync(
    dispatch: Dispatch<AnyAction>,
    useBeta: boolean = false
) {
    // const worker =
    //     'serviceWorker' in navigator
    //         ? navigator.serviceWorker.controller
    //         : null;
    // const { access } = tokenManager.get();

    // if (worker != null && access != null) {
    //     worker.postMessage({ type: 'RUN_SYNC', access, retry: true });
    // }

    return notifyWorkerOnlineMode({ retry: true })
        .then((_) => {
            dispatch(onOnlineModeSwitch());
            useBeta && dispatch(setOfflineProtocolSyncingState('SYNCING'));
        })
        .catch((_) => {
            dispatch(
                showSnackbar({ message: 'offline_mode.online_switch_failed' })
            );
        });
}

// Headers used by service worker
export function workerHeaders(
    clientId: string,
    localId: number,
    successType: string,
    failType: string
) {
    return {
        'offline-mode': 'enabled',
        'worker-client-id': clientId,
        'worker-local-id': localId,
        'sync-success-action': successType,
        'sync-failure-action': failType
    };
}

export function isPathSupported(path: string) {
    return PROTOCOL_ROUTE_REGEX.test(path);
}

// export const handleProtocolDownload = async (
//     protocolData: IOfflineProtocol['protocol'],
//     imageData: [string, { path: string }][]
// ) => {
//     const worker =
//         'serviceWorker' in navigator
//             ? navigator.serviceWorker.controller
//             : null;


//     if (worker != null) {
//         console.log('SENDING WORKER event ---> ')
//         worker.postMessage({
//             type: 'RUN_PROTOCOL_DOWNLOAD',
//             protocolData,
//             imageData
//         });

//     }

// };
