import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { SNACKBAR_TIMEOUT_DURATION } from '../../../configs/costants';
import { matchSimple } from '../../../utils';

import { SnackbarQueue } from '@rmwc/snackbar';
import { queue } from './queue';

// Note: Current implementation partially through redux
// and partially through queue object is not ideal

// Might be better to either stick to one or the other
const GlobalSnackbar: React.FC = () => {
    const snackbar = useSelector((state) => state.appState.snackbar);

    const { duration, snackType = 'warn' } = snackbar;

    const typeClass: string | null = matchSimple()
        .on(snackType === 'info', 'custom-snackbar--success')
        .on(snackType === 'warn', 'custom-snackbar--warning')
        .on(snackType === 'error', 'custom-snackbar--danger')
        .end();

    return (
        <SnackbarQueue
            className={clsx(
                'custom-snackbar',
                'custom-snackbar--top-center',
                typeClass
            )}
            timeout={duration || SNACKBAR_TIMEOUT_DURATION}
            messages={queue.messages}
        />
    );
};

export default memo(GlobalSnackbar);
