import { takeEvery } from 'redux-saga/effects';
import queryString from 'query-string';

import { getInvitationsSet } from '../../action-creators/team';
import { simpleRequest } from '../modules/request';

export default takeEvery(
    getInvitationsSet.type,
    simpleRequest<RT<typeof getInvitationsSet.request>>({
        success: getInvitationsSet.success,
        failure: getInvitationsSet.fail,
        getUrl: (action) =>
            `group_invitation/?${queryString.stringify(action.params)}`,
        isV2Api: true
    })
);
