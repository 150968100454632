import * as actions from '../actions/global';
import { createAction, createCustomAction } from 'typesafe-actions';

export const connectivtiyChangeOnline = createAction(
    actions.CONNECTIVITY_CHANGE_ONLINE
);
export const connectivtiyChangeOffline = createAction(
    actions.CONNECTIVITY_CHANGE_OFFLINE
);

export const betaConnectivityOnline = createAction(
    actions.BETA_CONNECTIVITY_CHANGE_ONLINE
);
export const betaConnectivityOffline = createAction(
    actions.BETA_CONNECTIVITY_CHANGE_OFFLINE
);

export const connectMediaCompressor = createAction(
    actions.CONNECT_BETA_COMPRESSOR
);
export const disconnectMediaCompressor = createAction(
    actions.DISCONNECT_BETA_COMPRESSOR
);

export const setNetworkRtt = createCustomAction(
    actions.SET_NETWORK_RTT,
    (type) => {
        return (rtt: number) => ({ type, rtt });
    }
);

export const backgroundSyncStart = createAction(actions.BACKGROUND_SYNC_START);
export const backgroundSyncSuccess = createAction(
    actions.BACKGROUND_SYNC_SUCCESS
);
export const backgroundSyncFail = createCustomAction(
    actions.BACKGROUND_SYNC_FAIL,
    (type) => {
        return (error: string) => ({ type, error });
    }
);

export const backgroundSyncDiscard = createAction(
    actions.BACKGROUND_SYNC_DISCARD
);

export const connectionSpeedUpdate = createCustomAction(
    actions.CONNECTION_SPEED_UPDATE,
    (type) => {
        return (speed: number) => ({ type, speed });
    }
);

type IAsyncActionGetter = (
    prevResult: AnyAction | undefined
) => AnyAction | undefined;
interface ActionSequenceConfig {
    actions: IAsyncActionGetter[];
    onFail?: (action: AnyAction) => void;
}

export const asyncActionSequence = createCustomAction(
    actions.ASYNC_ACTION_SEQUENCE,
    (type) => {
        return (config: ActionSequenceConfig) => ({ type, payload: config });
    }
);

export const hideUserOfflineNotification = createAction(
    actions.HIDE_USER_OFFLINE_NOTIFICATION_MODAL
);

export const assignOfflineProtocolSessionId = createCustomAction(
    actions.ASSIGN_PROTOCOL_SESSION_REQUEST
);

export const assignOfflineProtocolSessionIdSuccess = createCustomAction(
    actions.ASSIGN_PROTOCOL_SESSION_SUCCESS
);
export const assignOfflineProtocolSessionIdFail = createCustomAction(
    actions.ASSIGN_PROTOCOL_SESSION_FAIL
);
