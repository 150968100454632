import { ChangeEvent, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'validator';
import { EL_ATTRIBUTE_TYPE } from '../configs/basicData';
import {
    handleOfflineProtocolAttributeCrd,
    // handleOfflineProtocolAttributeUpdate,
    protoEleAttrCrud
} from '../store/action-creators';
import { getAttrKey, getAttrHashKeys } from '../utils/attributes';

interface IAttrForm {
    hashForm: IHash<string>;
    hashErrors: IErrorHash;
}

const initAttrForm: IAttrForm = {
    hashForm: {},
    hashErrors: {}
};

interface IAttributeListParams {
    allowBlank?: boolean;
}

const useAttributeList = (params?: IAttributeListParams) => {
    const { allowBlank } = params || {};

    const [idCount, setIdCount] = useState(0);
    const [delIds, setDelIds] = useState<number[]>([]);
    const [attrIds, setAttrIds] = useState<IAttrId[]>([]);

    const [dirtyAttr, setDirtyAttr] = useState(false);

    const [attrForm, setAttrForm] = useState(initAttrForm);

    const handleHashInput = (
        event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        const { name, value } = event.target;
        setAttrForm((s) => ({
            ...s,
            hashForm: {
                ...s.hashForm,
                [name]: value
            },
            hashErrors: {
                ...s.hashErrors,
                [name]: null
            }
        }));
        setDirtyAttr(true);
    };

    const localAdd = () => {
        const hashForm = { ...attrForm.hashForm };

        const newAttrId = { fakeId: idCount };
        const { typeKey, valueKey } = getAttrHashKeys(newAttrId);

        hashForm[typeKey] = '';
        hashForm[valueKey] = '';

        setAttrIds([...attrIds, newAttrId]);
        setIdCount(idCount + 1);

        setAttrForm((s) => ({
            ...s,
            hashForm
        }));
        setDirtyAttr(true);
    };

    const localDelete = (attrId: IAttrId) => {
        setAttrIds((s) =>
            s.filter((attr) => getAttrKey(attr) !== getAttrKey(attrId))
        );
        setDelIds(attrId.id != null ? [...delIds, attrId.id] : delIds);

        setDirtyAttr(true);
    };

    const validateAttrs = () => {
        const hashErrors = { ...attrForm.hashErrors };
        const attrRes = attrIds.map((attr) => {
            const { typeKey, valueKey } = getAttrHashKeys(attr);

            const typeValid = !isEmpty(attrForm.hashForm[typeKey]);
            const valueValid =
                allowBlank || !isEmpty(attrForm.hashForm[valueKey]);

            if (!typeValid) hashErrors[typeKey] = 'err';
            if (!valueValid) hashErrors[valueKey] = 'err';

            return typeValid && valueValid;
        });

        setAttrForm((s) => ({
            ...s,
            hashErrors
        }));
        return attrRes;
    };

    const resetAttrs = useCallback(
        (attrIds: IAttrId[] = [], hashForm: IHash<string> = {}) => {
            setAttrIds(attrIds);
            setAttrForm({
                hashForm,
                hashErrors: {}
            });
            setDelIds([]);
            setIdCount(0);
            setDirtyAttr(false);
        },
        []
    );

    return {
        attrState: {
            ...attrForm,
            idCount,
            attrIds,
            delIds,
            dirtyAttr
        },
        resetAttrs,
        handleHashInput,
        localAdd,
        localDelete,
        validateAttrs
    };
};

export default useAttributeList;

export const useAttributeChip = ({
    pela_pele_id,
    attr,
    selectedAttr,
    setShowAddAttr,
    onCloseEditModal
}: {
    pela_pele_id: number;
    attr: any[];
    selectedAttr: Partial<IProtocolElementAttr>;
    setShowAddAttr?: (v: boolean) => void;
    onCloseEditModal?: ICallback;
}) => {
    const [pelaType, setPelaType] = useState('');
    const [pelaKey, setPelaKey] = useState('');
    const [pelaValue, setPeleValue] = useState('');
    const lookupHash = useSelector((state) => state.basicData.response);
    const elAttrData = lookupHash?.[EL_ATTRIBUTE_TYPE] || {};
    const attrRaw = Object.entries(elAttrData || {}).map(([key, value]) => ({
        key,
        value
    }));
    const [typeError, setTypeError] = useState('');
    const [valueError, setValueError] = useState('');

    const useBeta = useSelector((state) => state.user.user.response?.use_beta);
    // const isAsync = useSelector(
    //     (state) => state.offlineProtocol.protocolState.is_async
    // );

    const [attrOptions, setAttrOptions] = useState<
        { key: string; value: string }[]
    >([]);

    const createAttrLiteral = {
        pela_pele_id,
        pela_state: [
            ...attr.map((a: IProtocolElementAttr) => ({
                pela_type: a.pela_type,
                pela_value: a.pela_value,
                pela_visibleforall: true
            })),
            {
                pela_type: pelaKey || pelaType,
                pela_value: pelaValue,
                pela_visibleforall: true
            }
        ]
    };

    const editAttrLiteral = {
        pela_pele_id,
        pela_state: attr.map((a: IProtocolElementAttr) => {
            return a.pela_id === selectedAttr.pela_id
                ? {
                      pela_type: pelaKey || pelaType,
                      pela_value: pelaValue,
                      pela_visibleforall: true
                  }
                : {
                      pela_type: a.pela_type,
                      pela_value: a.pela_value,
                      pela_visibleforall: true
                  };
        })
    };

    const deleteAttrLiteral = {
        pela_pele_id,
        pela_state: [
            ...attr.filter(
                (a: IProtocolElementAttr) =>
                    selectedAttr.pela_id !== a.pela_id && {
                        pela_type: a.pela_type,
                        pela_value: a.pela_value,
                        pela_visibleforall: true
                    }
            )
        ]
    };

    const handleTypeChange = (e: any) => {
        setPelaKey('');
        setTypeError('');
        setPelaType(
            pelaType.length > e.target.value.length
                ? e.target.value
                : elAttrData[e.target.value as string] ||
                      (e.target.value as string)
        );
        const filtered = attrRaw.filter((a) =>
            a.value.toLowerCase().includes(e.target.value.toLowerCase())
        );
        setAttrOptions(e.target.value ? filtered : []);
    };

    const handleValueChange = (e: any) => {
        setValueError('');
        setPeleValue(e.target.value);
    };

    const dispatch = useDispatch();

    const onEnterSubmit = (e: any, onBlur?: boolean) => {
        if (
            (e.key === 'Enter' || e.key === 'Tab' || onBlur) &&
            pelaValue &&
            (pelaKey || pelaType)
        ) {
            if (useBeta) {
                // if (isAsync) {
                // update only offline reducer
                dispatch(handleOfflineProtocolAttributeCrd(createAttrLiteral));
                // } else {
                //     // update both online and offline reducer
                //     dispatch(handleOfflineProtocolAttributeCrd(createAttrLiteral));
                //     dispatch(protoEleAttrCrud(createAttrLiteral));
                // }
            } else {
                // update only online reducer
                dispatch(protoEleAttrCrud(createAttrLiteral));
            }
            setPelaType('');
            setPeleValue('');
            setPelaKey('');
            setShowAddAttr?.(false);
            setTypeError('');
            setValueError('');
        } else {
            if (!(pelaType || pelaKey)) setTypeError('Type is required');
            if (!pelaValue) setValueError('Value is required');
        }
    };

    const handleDeleteAttr = () => {
        if (useBeta) {
            // if (isAsync) {
            // update only offline reducer
            dispatch(handleOfflineProtocolAttributeCrd(deleteAttrLiteral));
            // } else {
            //     // update both online and offline reducer
            //     dispatch(handleOfflineProtocolAttributeCrd(deleteAttrLiteral));
            //     dispatch(protoEleAttrCrud(deleteAttrLiteral));
            // }
        } else {
            // update only online reducer
            dispatch(protoEleAttrCrud(deleteAttrLiteral));
        }
    };

    const resetError = () => {
        setTypeError('');
        setValueError('');
    };

    const handleUpdateAttr = () => {
        if (!(pelaType || pelaKey)) setTypeError('Type is required');
        if (!pelaValue) setValueError('Value is required');
        if (pelaValue && (pelaKey || pelaType)) {
            if (useBeta) {
                // if (isAsync) {
                // update only offline reducer
                dispatch(handleOfflineProtocolAttributeCrd(editAttrLiteral));
                // } else {
                //     // update both online and offline reducers
                //     dispatch(handleOfflineProtocolAttributeCrd(editAttrLiteral));
                //     dispatch(protoEleAttrCrud(editAttrLiteral));
                // }
            } else {
                // update only online reducer
                dispatch(protoEleAttrCrud(editAttrLiteral));
            }
            setPelaType('');
            setPeleValue('');
            setPelaKey('');
            resetError();
            onCloseEditModal?.();
        }
    };
    return {
        pelaType,
        setPelaType,
        pelaValue,
        setPeleValue,
        handleTypeChange,
        handleValueChange,
        onEnterSubmit,
        elAttrData,
        setAttrOptions,
        attrOptions,
        handleDeleteAttr,
        handleUpdateAttr,
        pelaKey,
        setPelaKey,
        typeError,
        valueError,
        resetError
    };
};
