import { takeEvery, all } from 'redux-saga/effects';

import {
    POST_PROTOCOL_ISSUE_REQUEST,
    POST_PROTOCOL_ISSUE_SUCCESS
} from '../../actions';
import {
    postProtocolIssueSuccess,
    postProtocolIssueFail,
    postProtocolIssue
} from '../../action-creators';

import { simpleRequest } from '../modules/request';
import { normalizeIssue } from '../modules/normalization';
import { responseHelper } from '../modules/promise';

function onSuccessCallback(data: any) {
    return normalizeIssue(data);
}

const requestEffect = takeEvery(
    POST_PROTOCOL_ISSUE_REQUEST,
    simpleRequest<ReturnType<typeof postProtocolIssue>>({
        success: postProtocolIssueSuccess,
        failure: postProtocolIssueFail,
        getUrl: (_) => `protocol/${window.location.pathname.split('/')[2]}/issues/`,
        getBody: (action) => action.payload,
        method: 'POST',
        enableOffline: true
    })
);

const responseEffect = responseHelper({
    actionName: POST_PROTOCOL_ISSUE_SUCCESS,
    processFn: onSuccessCallback
});

export default all([requestEffect, responseEffect]);