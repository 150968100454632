import React, { useState } from 'react';
import Modal from '../../atoms/StdModal';
import clsx from 'clsx';
import StdButton from '../../atoms/StdButton';
import { useTranslation } from 'react-i18next';

import './ProtocolStateVersionWarningModal.scss';
import { parseUserAgent } from '../../../utils/uaParser';
import { backDateToFrontWithTime } from '../../../utils/formatter';
import StdRadio from '../../atoms/StdRadio';

interface ProtocolStateVersionWarningModalProps {
    data: IProtocolCheckoutData | null;
    open: boolean;
    onFetchServer: (e?: any) => void | undefined;
    onFetchLocal: (e?: any) => void | undefined;
    onCancel: (e?: any) => void | undefined;
}

const ProtocolStateVersionWarningModal: React.FC<
    ProtocolStateVersionWarningModalProps
> = ({ data, open, onFetchServer, onFetchLocal, onCancel }) => {
    const { t } = useTranslation();
    const [selectedPrompt, setSelectedPrompt] = useState<
        'SERVER' | 'LOCAL' | string
    >('SERVER');

    const handleFetchProtocol = () => {
        if (selectedPrompt === 'SERVER') {
            onFetchServer();
        } else {
            onFetchLocal();
        }
    };

    return (
        <Modal
            open={open}
            onClose={() => {}}
            white
            contentClassName="protocol-state-version-warning-modal-content"
        >
            <div className="modal__section--square protocol-state-version-warning-modal">
                <div className={clsx('stack-xl', 'beta-selector')}>
                    <div className="modal-header">
                        {`Another version of this protocol is available on the AIMMO servers. How do you want to proceed:`}
                    </div>
                    <div className="modal-info__3">
                        <div className="form__line-action protocol-state-version-warning-modal-content__radioBtn-wrap">
                            <StdRadio
                                className="inline-xs"
                                value="SERVER"
                                name="status"
                                onChange={(event) =>
                                    setSelectedPrompt(event.target.value)
                                }
                                checked={selectedPrompt === 'SERVER'}
                            />
                            <span>{`Load the protocol from the AIMMO servers and overwrite the locally cached protocol data`}</span>
                        </div>
                        <div className="form__line-action protocol-state-version-warning-modal-content__radioBtn-wrap">
                            <StdRadio
                                className="inline-xs"
                                value="LOCAL"
                                name="status"
                                onChange={(event) =>
                                    setSelectedPrompt(event.target.value)
                                }
                                checked={selectedPrompt === 'LOCAL'}
                            />
                            <span>{`Load the locally cached protocol data and overwrite the data saved on AIMMO servers`}</span>
                        </div>
                    </div>
                </div>
                <div className={clsx('modal__actions', 'beta-modal-actions')}>
                    <StdButton
                        className="beta-cancel"
                        onClick={(e) => {
                            onCancel(e);
                        }}
                    >
                        {'Cancel'}
                    </StdButton>
                    <StdButton
                        className="beta-accept beta-selector-accept-button"
                        onClick={handleFetchProtocol}
                    >
                        {`Ok`}
                    </StdButton>
                </div>
            </div>
        </Modal>
    );
};

export default ProtocolStateVersionWarningModal;
