import React, { useLayoutEffect, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

import { dispatchAsync } from '../../../store/storeModule';
import { isoDateTimeToFront } from '../../../utils/formatter';
import {
    getIssueLogs,
    createIssueLink,
    getAllIssue,
    patchMedia
} from '../../../store/action-creators';
import { MEDIA_DOCUMENT_TYPE } from '../../../configs/costants';

import useDelayedPending from '../../../hooks/useDelayedPending';
import useUploader, {
    uploadResultsToIds,
    IUploadConfig
} from '../../../hooks/useUploader';

import StdButton from '../../atoms/StdButton';
import Loading from '../../atoms/Loading';
import useShareLink from '../../../hooks/useShareLink';
import useCopyLink from '../../../hooks/useCopyLink';
import SharedLink from '../../molecules/SharedLink';
import StdChip from '../../atoms/StdChip';
import StdStep from '../../atoms/StdStep';
import StdDivider from '../../atoms/StdDivider';
import AttachmentGallery from '../../molecules/AttachmentGallery';
import ShareIssueSection from '../ShareIssueSection';

import './issue-progress.scss';

interface IProps {
    issue: IIssue;
    documents: IUpload[];
    showMoreLogs?: boolean;
    sharingDisabled?: boolean;
    sharingHidden?: boolean;
    width?: string;
    onShare?: ICallback;
    onFix?: ICallback;
    onShowClick?: ICallback;
    onModifiedDocuments?: ICallback;
}

const SHOWN_ISSUE_LOGS_COUNT = 2;

const logToUi = (log: ILinkLog) => {
    const { sll_id, sll_logmsg, sll_createddate } = log;
    return (
        <div key={sll_id} className="std-section stack-s">
            <span className="inline-xs">{sll_logmsg || '----'}</span>
            <span className="font--secondary">
                {sll_createddate ? isoDateTimeToFront(sll_createddate) : '--'}
            </span>
        </div>
    );
};

const IssueProgress: React.FC<IProps> = (props) => {
    const {
        issue,
        documents,
        width = '100%',
        showMoreLogs,
        sharingDisabled,
        sharingHidden,
        onShare,
        onFix,
        onShowClick,
        onModifiedDocuments
    } = props;

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const attachUploader = useUploader();

    const logsLoading = useDelayedPending([getIssueLogs]);
    const isSharing = useDelayedPending([createIssueLink, getAllIssue]);

    const shareLink = useShareLink();
    const copyLink = useCopyLink();

    const { sharing_link_public, iss_fix, iss_title } = issue;

    const isShared = Boolean(sharing_link_public);
    const isFixed = Boolean(iss_fix);
    const isClosed = Boolean(documents.length);

    const showContentNode = (!isFixed || isShared) && !sharingHidden;

    const issueShareLink = `${document.location.origin}/share/issue/${sharing_link_public}`;

    const logsRes = useSelector((state) => state.inventory.issueLogs);
    const logs = Array.isArray(logsRes.response.result.data)
        ? logsRes.response.result.data
        : [];

    const shownLogs = logs.slice(0, SHOWN_ISSUE_LOGS_COUNT);
    const hiddenLogs = logs.slice(SHOWN_ISSUE_LOGS_COUNT, logs.length);
    const hasMoreLogs = !showMoreLogs && Boolean(hiddenLogs.length);

    useEffect(() => {
        attachUploader.setUploads(documents.map((upload) => upload.id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useLayoutEffect(() => {
        if (!isSharing && sharing_link_public) {
            dispatch(getIssueLogs(sharing_link_public));
        }
    }, [dispatch, isSharing, sharing_link_public]);

    const handleCopy = () => {
        copyLink(issueShareLink);
    };

    const handleShare = () => {
        shareLink({
            title: t('native_share_title'),
            text: `${t('native_share_start')} "${iss_title || '---'}" ${t(
                'native_share_end'
            )}`,
            url: issueShareLink
        });
    };

    const handleUploadFiles = (
        ...args: [File[], IUploadConfig | undefined]
    ) => {
        attachUploader.uploadFilesCore(...args).then((results) => {
            uploadResultsToIds(results).forEach((id) => {
                if (id == null) return;
                dispatchAsync(
                    dispatch,
                    patchMedia(id, { med_iss_id: issue.iss_id })
                ).then(onModifiedDocuments);
            });
        });
    };

    const handleRenameFiles = (...args: [number, string, string]) => {
        attachUploader.renameUploadHard(...args).then(onModifiedDocuments);
    };

    const handleRemoveFiles = (...args: [number]) => {
        attachUploader.removeUploadHard(...args).then(onModifiedDocuments);
    };

    return (
        <div className="issue-progress__container" style={{ width }}>
            <div className="issue-progress__header">
                <StdStep
                    iconName="check-circle"
                    title={t('issue_step.reviewed')}
                    active
                />
                <StdStep iconName="tools" title={t('fixed')} active={isFixed} />
                <StdStep
                    iconName="receipt"
                    title={t('issue_step.closed')}
                    active={isClosed}
                    last
                />
            </div>
            <StdDivider />
            {showContentNode && (
                <>
                    <div className="issue-progress__section">
                        {isShared ? (
                            <div>
                                <div className="stack-m">
                                    <div
                                        className={clsx(
                                            'issue-progress__logs',
                                            hasMoreLogs && 'stack-s'
                                        )}
                                    >
                                        {logsLoading ? (
                                            <Loading className="loading__logs" />
                                        ) : (
                                            <div>
                                                {shownLogs.map((log) =>
                                                    logToUi(log)
                                                )}
                                                {showMoreLogs &&
                                                    hiddenLogs.map((log) =>
                                                        logToUi(log)
                                                    )}
                                            </div>
                                        )}
                                    </div>
                                    {hasMoreLogs && !logsLoading && (
                                        <StdChip onClick={onShowClick}>
                                            {t('see_all')} ({logs.length}){' '}
                                            {t('issue_logs.updates')}
                                        </StdChip>
                                    )}
                                </div>
                                <SharedLink
                                    link={issueShareLink}
                                    handleCopy={handleCopy}
                                    handleShare={handleShare}
                                />
                            </div>
                        ) : (
                            <ShareIssueSection
                                onShare={onShare}
                                isSharing={isSharing}
                                disabled={sharingDisabled}
                            />
                        )}
                    </div>
                    <StdDivider />
                </>
            )}

            <div className="issue-progress__section">
                {isFixed ? (
                    <div>
                        <div className="stack-m">
                            {t('issue_progress.document_upload_note')}
                        </div>
                        <AttachmentGallery
                            type={MEDIA_DOCUMENT_TYPE}
                            previews={attachUploader.previews}
                            uploadFiles={handleUploadFiles}
                            renameUpload={handleRenameFiles}
                            removeUpload={handleRemoveFiles}
                        />
                    </div>
                ) : (
                    <div>
                        <StdButton type="primary" onClick={onFix}>
                            {t('issue.mark_as_fixed')}
                        </StdButton>
                    </div>
                )}
            </div>
        </div>
    );
};

export default IssueProgress;
