import { useEffect, useRef, useState } from 'react';

const useToggleTimeout = (toggle: boolean, delay: number) => {
    const timeoutRef = useRef<number>();
    const [active, setActive] = useState(false);

    useEffect(() => {
        if (toggle) {
            setActive(true);

            timeoutRef.current = window.setTimeout(() => {
                setActive(false);
            }, delay);
        } else {
            setActive(false);
        }

        return () => {
            if (timeoutRef.current != null) {
                window.clearTimeout(timeoutRef.current);
            }
        };
    }, [toggle, delay]);

    return active;
};

export default useToggleTimeout;
