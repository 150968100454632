import * as actions from '../actions/appState';

import {
    BACKGROUND_SYNC_FAIL,
    BACKGROUND_SYNC_SUCCESS,
    BACKGROUND_SYNC_DISCARD,
    ON_OFFLINE_MDOE_SWITCH
} from '../actions';

interface ISnackbarState extends ISnackbarParams {
    toggle: boolean;
}

interface IAppState {
    screenType: IScreenSizeType;
    isManualOffline: boolean;
    localCounter: number;
    syncFailure: {
        // Persist sync failed state
        hasFailed: boolean;
        error: string | null;
    };
    version: {
        response: IGetManyPaginatedSuccess<IVersion>;
        error: Error | null;
    };
    snackbar: ISnackbarState;
}

const syncFailInit = {
    hasFailed: false,
    error: null
};

const appStateInitial: IAppState = {
    screenType: 'desktop',
    isManualOffline: false,
    localCounter: -1,
    syncFailure: syncFailInit,
    version: {
        response: {
            count: 0,
            next: null,
            previous: null,
            results: []
        },
        error: null
    },
    snackbar: {
        toggle: false,
        message: '',
        snackType: undefined,
        duration: undefined,
        error: undefined
    }
};

function appStateReducer(
    state = appStateInitial,
    action: AnyAction
): IAppState {
    switch (action.type) {
        case actions.SHOW_SNACKBAR:
            return {
                ...state,
                snackbar: {
                    ...action.params,
                    error: action.params.error,
                    toggle: !state.snackbar.toggle
                }
            };
        case actions.GET_VERSION_REQUEST:
            return {
                ...state,
                version: {
                    ...state.version,
                    error: null
                }
            };
        case actions.GET_VERSION_SUCCESS:
            return {
                ...state,
                version: {
                    error: null,
                    response: action.payload
                }
            };
        case actions.DECREMENT_COUNTER:
            return {
                ...state,
                localCounter: state.localCounter - 1
            };
        case actions.ON_OFFLINE_MDOE_SWITCH:
            return {
                ...state,
                isManualOffline: true
            };
        case actions.ON_ONLINE_MODE_SWITCH:
            return {
                ...state,
                isManualOffline: false
            };
        case actions.SET_SCREEN_SIZE: {
            return {
                ...state,
                screenType: action.size
            };
        }
        case BACKGROUND_SYNC_FAIL: {
            return {
                ...state,
                syncFailure: {
                    hasFailed: true,
                    error: action.error
                }
            };
        }
        case BACKGROUND_SYNC_SUCCESS: {
            return {
                ...state,
                syncFailure: syncFailInit
            };
        }
        case BACKGROUND_SYNC_DISCARD: {
            return {
                ...state,
                syncFailure: syncFailInit
            };
        }
        case ON_OFFLINE_MDOE_SWITCH: {
            return {
                ...state,
                syncFailure: syncFailInit
            };
        }

        // async mode switch helpers
        // case 'CONNECTIVITY_CHANGE_ONLINE':
        //     return {
        //         ...state,
        //         isManualOffline: false
        //     };

        case 'CONNECTIVITY_CHANGE_OFFLINE':

        case 'GET_ATTENDEES_FAIL':
        case 'GET_PROTOCOL_ROOMS_FAIL':
        case 'GET_PROTOCOL_ITEMS_FAIL':
        case 'GET_PROTOCOL_ISSUES_FAIL':

        case 'POST_ATTENDEE_FAIL':
        case 'POST_PROTOCOL_ELEMENT_FAIL':
        case 'POST_PROTOCOL_ITEM_FAIL':
        case 'POST_PROTOCOL_ROOM_FAIL':
        case 'POST_PROTOCOL_ISSUE_FAIL':
        case 'POST_PROTOCOL_ELEMENT_ATTRIBUTES_FAIL':

        case 'PATCH_ATTENDEE_FAIL':
        case 'PATCH_PROTOCOL_ROOM_FAIL':
        case 'PATCH_PROTOCOL_ELEMENT_FAIL':
        case 'PATCH_PROTOCOL_ITEM_FAIL':
        case 'PATCH_PROTOCOL_ISSUE_FAIL':
        case 'PATCH_PROTOCOL_FAIL':

        case 'DEL_ATTENDEE_FAIL':
        case 'DELETE_PROTOCOL_ROOM_FAIL':
        case 'DELETE_PROTOCOL_ELEMENT_FAIL':
        case 'DELETE_PROTOCOL_ITEM_FAIL':
        case 'DELETE_PROTOCOL_ISSUE_FAIL':
        case 'DELETE_PROTOCOL_FAIL':

        // case "BACKGROUND_SYNC_FAIL":
        case "CREATE_MEDIA_FAIL":
            const useBeta = action?.useBeta || false;
            if (!useBeta) return state;
            return {
                ...state,
                isManualOffline: true,
                syncFailure: syncFailInit
            };
    }
    return state;
}

export default appStateReducer;
