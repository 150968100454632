import React from 'react';
import clsx from 'clsx';

import StdChip from '../../atoms/StdChip';

import './invoice-item.scss';

interface IProps {
    title: string;
    link?: string;
    date?: string;
    price?: string;
}

const InvoiceItem: React.FC<IProps> = ({ title, link, date, price }) => {
    return (
        <div className="invoice-item__container">
            <span className="inline-m">
                <StdChip to={link} className={clsx('inline-s')}>
                    {title}
                </StdChip>
                <span>{date}</span>
            </span>
            <span className="inline-ll">{price}</span>
        </div>
    );
};

export default InvoiceItem;
