import { takeLatest } from 'redux-saga/effects';
import queryString from 'query-string';

import { GET_LOCATION_CH_REQUEST } from '../../actions';
import {
    getLocationCH,
    getLocationCHSuccess,
    getLocationCHFail
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

function getGeoAdminUrl(params: object) {
    return `https://api3.geo.admin.ch/rest/services/api/SearchServer?${queryString.stringify(
        params
    )}&type=locations&returnGeometry=false&lang=en`;
}

export default takeLatest(
    GET_LOCATION_CH_REQUEST,
    simpleRequest<ReturnType<typeof getLocationCH>>({
        success: getLocationCHSuccess,
        failure: getLocationCHFail,
        getUrl: (action) => getGeoAdminUrl(action.params),
        fullUrl: true,
        ignoreError: true,
        withAuth: false
    })
);
