import { takeEvery, all } from 'redux-saga/effects';

import {
    POST_PROTOCOL_ITEM_REQUEST,
    POST_PROTOCOL_ITEM_SUCCESS
} from '../../actions';
import {
    protoPostItem,
    protoPostItemSuccess,
    protoPostItemFail
} from '../../action-creators';

import { simpleRequest } from '../modules/request';
import { normalizeProtocolItem } from '../modules/normalization';
import { responseHelper } from '../modules/promise';

function onSuccessCallback(data: any) {
    return normalizeProtocolItem(data);
}

const requestEffect = takeEvery(
    POST_PROTOCOL_ITEM_REQUEST,
    simpleRequest<RT<typeof protoPostItem>>({
        success: protoPostItemSuccess,
        failure: protoPostItemFail,
        getUrl: (_) => 'protocol_item/item_create/',
        getBody: (action) => action.payload,
        method: 'POST',
        enableOffline: true
    })
);

const responseEffect = responseHelper({
    actionName: POST_PROTOCOL_ITEM_SUCCESS,
    processFn: onSuccessCallback
});

export default all([requestEffect, responseEffect]);
