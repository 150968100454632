import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { logout } from '../../../store/action-creators';
import { PROFILE_ROUTE } from '../../../configs/routes';
import { getFullname } from '../../../modules/account';

import useMatchMedia from '../../../hooks/useMatchMedia';

import { MenuSurfaceAnchor, MenuItem } from '@rmwc/menu';
import { Link } from 'react-router-dom';

import StdDropdown from '../../atoms/StdDropdown';
import Avatar from '../Avatar';
import StdIcon from '../../atoms/StdIcon';
import StdChip from '../../atoms/StdChip';

import './user-actions.scss';
import { PERSONA_TYPE } from '../../../configs/basicData';

function displayRole(role: IUserRole) {
    switch (role) {
        case 'MA':
        case 'MANAGER':
            return 'role.manager';
        case 'TE':
            return 'role.tenant';
        case 'TEAM_MEMBER':
            return 'role.team_member';
        case 'ADMIN':
            return 'role.admin';
        default:
            return 'role.landlord';
    }
}
const UserActions: React.FC = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [optionsOpen, setOptionsOpen] = useState(false);

    const account = useSelector((state) => state.user.user.response);
    const useBeta = useSelector((state) => state.user.user.response?.use_beta);
    const lookupHash = useSelector((state) => state.basicData.response);
    const userPersonaKey = useSelector((state) => state.user.user.response?.persona);
    const personaData = lookupHash?.[PERSONA_TYPE];
    const { firstname, lastname, role } = account || {};
    const name = getFullname(firstname, lastname);

    const isPhone = useMatchMedia('mobile');

    const userRole = (personaData && userPersonaKey) ? personaData[userPersonaKey] : '----';

    const statusNode = (
        <div
            className={clsx(
                'user-actions__content',
                isPhone ? 'stack-s' : 'inline-s'
            )}
        >
            <div className="user-actions__name">{name}</div>
            <span className="user-actions__role">{userRole}</span>
        </div>
    );

    return (
        <MenuSurfaceAnchor>
            <StdDropdown
                open={optionsOpen}
                onClose={() => setOptionsOpen(false)}
                onSelect={(e) => {
                    const {
                        detail: { item }
                    } = e;
                    const value = item.dataset['value'];
                    switch (value) {
                        case 'logout':
                            dispatch(logout());
                            break;
                    }
                }}
            >
                {isPhone && <MenuItem disabled>{statusNode}</MenuItem>}
                <Link className="std-blank-link" to={PROFILE_ROUTE()}>
                    <MenuItem>{t('profile')}</MenuItem>
                </Link>
                <MenuItem data-value="logout">{t('logout')}</MenuItem>
            </StdDropdown>
            <div
                className="user-actions__container"
                onClick={() => setOptionsOpen(true)}
            >
                <Avatar className={clsx({'inline-m': !isPhone, 'beta-active': useBeta})} />
                {!isPhone && (
                    <>
                        {statusNode}
                        <div className="user-actions__chevron">
                            <StdIcon name="caret-down" prefix="fas" />
                        </div>
                    </>
                )}
            </div>
        </MenuSurfaceAnchor>
    );
};

export default UserActions;
