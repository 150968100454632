import { takeLatest } from 'redux-saga/effects';

import { COPY_DPD_REQUEST } from '../../actions';
import { copyDpd, copyDpdSuccess, copyDpdFail } from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeLatest(
    COPY_DPD_REQUEST,
    simpleRequest<RT<typeof copyDpd>>({
        success: copyDpdSuccess,
        failure: copyDpdFail,
        getUrl: (action) => `dpd/${action.dpdId}/copy/`
    })
);
