import { all } from 'redux-saga/effects';
import createInvitationEffect from './createInviation';
import deleteInvitationEffect from './deleteInvitation';
import getInvitationsEffect from './getInvitations';
import getEnterpriseManagerEffect from './getEnterpriseManagers';
import resendInvitationEffect from './resendInvitation';
import getManagersEffect from './getManagers';
import deleteManagerEffect from './deleteManager';
import acceptInviteEffect from './acceptInvite';
import checkInviteEffect from './checkInvite';

export default all([
    createInvitationEffect,
    deleteInvitationEffect,
    getInvitationsEffect,
    getEnterpriseManagerEffect,
    resendInvitationEffect,
    getManagersEffect,
    deleteManagerEffect,
    acceptInviteEffect,
    checkInviteEffect
]);
