import * as actions from '../actions/element';
import { Action } from 'redux';
import { createCustomAction, createAction } from 'typesafe-actions';
import { Assign } from 'utility-types';
import { actionFail, actionPayload } from '../storeModule';

type IModifiableElement = Assign<
    Omit<IElement, 'ele_id' | 'uploads'>,
    { ele_rom_id?: number; media_med_id?: number[] }
>;

export interface IGetElement
    extends Action<typeof actions.GET_ELEMENT_REQUEST> {
    roomId: number;
    lang: ILang;
}

export const getElement = (roomId: number, lang: ILang): IGetElement => ({
    type: actions.GET_ELEMENT_REQUEST,
    roomId,
    lang
});

export const getElementSuccess = createCustomAction(
    actions.GET_ELEMENT_SUCCESS,
    actionPayload
);
export const getElementFail = createCustomAction(
    actions.GET_ELEMENT_FAIL,
    actionFail
);

export interface IPostElement
    extends Action<typeof actions.POST_ELEMENT_REQUEST> {
    element: IModifiableElement;
}

export const postElement = (element: IModifiableElement): IPostElement => ({
    type: actions.POST_ELEMENT_REQUEST,
    element
});
export const postElementSuccess = createAction(actions.POST_ELEMENT_SUCCESS);
export const postElementFail = createCustomAction(
    actions.POST_ELEMENT_FAIL,
    actionFail
);

export const patchElement = createCustomAction(
    actions.PATCH_ELEMENT_REQUEST,
    (type) => {
        return (eleId: number, payload: IModifiableElement) => ({
            type,
            eleId,
            payload
        });
    }
);
export const patchElementSuccess = createAction(actions.PATCH_ELEMENT_SUCCESS);
export const patchElementFail = createCustomAction(
    actions.PATCH_ELEMENT_FAIL,
    actionFail
);

export interface IDeleteElement
    extends Action<typeof actions.DELETE_ELEMENT_REQUEST> {
    elId: number;
}

export const deleteElement = (elId: number): IDeleteElement => ({
    type: actions.DELETE_ELEMENT_REQUEST,
    elId
});
export const deleteElementSuccess = createAction(
    actions.DELETE_ELEMENT_SUCCESS
);
export const deleteElementFail = createCustomAction(
    actions.DELETE_ELEMENT_FAIL,
    actionFail
);

export const predictElements = createCustomAction(
    actions.PREDICT_ELEMENTS,
    (type) => {
        return (elements: IElement[]) => ({ type, elements });
    }
);
