import React, { useState, useEffect, useMemo, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router';
import { forceCheck } from 'react-lazyload';

import { ONE_DPD, DASHBOARD_ROUTE, PROTOCOL_ROUTE } from '../../configs/routes';
import { DPD_TYPE, SUBDPD_TYPE } from '../../configs/basicData';
import {
    isHouseType,
    MEDIA_ATTACHMENT_TYPE,
    ISSUE_TYPE_EXCESSIVE,
    ISSUE_TYPE_NORMAL
} from '../../configs/costants';

import {
    getRoom,
    getIssues,
    deleteDpd,
    getTemplate,
    postRoom,
    patchRoom,
    deleteRoom,
    showSnackbar,
    getTenant,
    roomAttrCrud,
    postTenant,
    deleteTenant,
    postProtocol,
    copyDpd,
    copyRoom,
    delProtocol,
    getOfflineProtocolCheckoutId,
    setCheckoutLoading
} from '../../store/action-creators';

import {
    intOrNothing,
    match,
    truthyOrNothing,
    justArray,
    intOrElse
} from '../../utils';
import { partition } from '../../modules/array';

import { attrListToStr } from '../../utils';
import {
    getPropertyLink,
    getBuildingLink,
    propertyToIcon,
    propertyAddressToForm,
    objectToIcon
} from '../../modules/property';
import { handleStartProtocol } from '../../utils/requests';
import { dispatchAsync, safeByIds } from '../../store/storeModule';

import useDelayedPending from '../../hooks/useDelayedPending';
import useMatchMedia from '../../hooks/useMatchMedia';
import useScrollToContent from '../../hooks/useScrollToContent';
import useToggle from '../../hooks/useToggle';
import useArraySelector from '../../hooks/useArraySelector';
import usePending from '../../hooks/usePending';
import useGoogleMap from '../../hooks/useGoogleMap';

import { MenuItem, MenuSurfaceAnchor } from '@rmwc/menu';
import { ListDivider } from '@rmwc/list';

import Header from '../../components/inventory/Header';
import ObjectBar from '../../components/inventory/ObjectBar';
import StdIcon from '../../components/atoms/StdIcon';
import RoomView from '../../components/inventory/RoomView';
import IssueTab from '../../components/issues/IssueTab';
import IssuesView from '../../components/issues/IssueView';
import EditObjectModal from '../../components/inventory/EditObjectModal';
import StdDropdown from '../../components/atoms/StdDropdown';
import ObjectConfirmDeleteModal from '../../components/molecules/ObjectConfirmDeleteModal/ObjectConfirmDeleteModal';
import EditRoomModal from '../../components/inventory/EditRoomModal';
import TenantTab from '../../components/inventory/TenantTab';
import TenantView from '../../components/inventory/TenantView';
import ObjectModal from '../../components/inventory/ObjectModal';
import StdChip from '../../components/atoms/StdChip';
import StdMedia from '../../components/atoms/StdMedia';
import Map from '../../components/misc/Map';
import RoomTab from '../../components/inventory/RoomTab';
import i18n from '../../i18next';
import EditObjectHouse from '../../components/inventory/EditObjectModal/EditObjectHouse';
import ProtocolBetaWarningModal from '../../components/protocol/ProtocolBetaWarningModal';
import ProtocolCheckoutWarningModal from '../../components/protocol/ProtocolCheckoutWarningModal';

function issueCategoryToFilter(category: IIssueFilter) {
    switch (category) {
        case 'unresolved':
            return {
                iss_fix: false
            };
        case 'resolved':
            return {
                iss_fix: true
            };
        case 'light':
            return {
                iss_grade: ISSUE_TYPE_NORMAL
            };
        case 'excessive':
            return {
                iss_grade: ISSUE_TYPE_EXCESSIVE
            };
    }
}

function isAnyRoomInvisible(rooms: IRoom[], propertyFloor: number) {
    return rooms.some((room) => intOrElse(room.rom_level, 1) > propertyFloor);
}

type IItemType =
    | {
          type: 'room';
          item: IRoom;
      }
    | {
          type: 'tenant';
          item: ITenant;
      }
    | {
          type: 'issue_category';
          item: IIssueFilter;
      }
    | {
          type: 'none';
      };

const isIObjectTab = (value: unknown): value is IObjectTab => {
    const tab = value as IObjectTab;
    return tab === 'rooms' || tab === 'reporting' || tab === 'tenants';
};

const isIIssueFilter = (value: unknown): value is IIssueFilter => {
    const category = value as IIssueFilter;
    return (
        category === 'resolved' ||
        category === 'unresolved' ||
        category === 'light' ||
        category === 'excessive'
    );
};

const proccessQuery = (query: queryString.ParsedQuery<string>) => {
    const { tab, floor, room, category, issue, element, tenant } = query;

    const parsedFloor = intOrNothing(floor);
    const floorNum = intOrNothing(
        parsedFloor != null && Math.max(parsedFloor - 1, 0)
    );
    const roomId = intOrNothing(room);
    const issueId = intOrNothing(issue);
    const eleId = intOrNothing(element);
    const tenId = intOrNothing(tenant);

    return {
        elementParam: truthyOrNothing(
            roomId != null && eleId != null && { roomId, eleId }
        ),
        roomParam: truthyOrNothing(roomId != null && { roomId }),
        floorParam: truthyOrNothing(floorNum != null && { floorNum }),
        issueParam: truthyOrNothing(issueId != null && { issueId }),
        categoryParam: truthyOrNothing(
            isIIssueFilter(category) && { category }
        ),
        tabParam: truthyOrNothing(isIObjectTab(tab) && { tab }),
        tenParam: truthyOrNothing(tenId != null && { tenId })
    };
};

const mapConfig = {
    streetViewControl: false,
    mapTypeControl: false,
    zoomControl: false
};

interface IProps {
    object: IDpd;
    handleFetch: ICallback;
}

const ObjectPage: React.FC<IProps> = ({ object, handleFetch }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const { search } = useLocation();
    const queryParams = useMemo(
        () => proccessQuery(queryString.parse(search)),
        [search]
    );

    const {
        elementParam,
        roomParam,
        floorParam,
        tenParam,
        issueParam,
        categoryParam,
        tabParam
    } = queryParams;

    const initTab = match<null, IObjectTab>(null)
        .on(
            () => Boolean(roomParam || floorParam),
            () => 'rooms'
        )
        .on(
            () => Boolean(categoryParam || issueParam),
            () => 'reporting'
        )
        .on(
            () => Boolean(tenParam),
            () => 'tenants'
        )
        .on(
            () => Boolean(tabParam),
            () => tabParam?.tab || 'rooms'
        )
        .otherwise(() => 'rooms');

    const [fetchHash, setFetchHash] = useState<IHash<boolean>>({});

    const [activeTab, setActiveTab] = useState<IObjectTab>(initTab);

    const [viewOpen, setViewOpen] = useState(false);
    const [edit, onEditOpen, onEditClose] = useToggle<IScrollType>({
        cleanupOnChange: true
    });

    const [delOpen, setDelOpen] = useState(false);
    const [optionsOpen, setOptionsOpen] = useState(false);

    const [template, onTemplateOpen, onTemplateClose] =
        useToggle<Partial<IRoom>>();
    const [viewedItem, setViewedItem] = useState<IItemType>({ type: 'none' });

    const [contentRef, scrollToContent] = useScrollToContent({
        onlyMobile: true
    });

    const [openCheckoutWarningModal, setOpenCheckoutWarningModal] =
        useState(false);

    const [objectProtocol, setObjectProtocol] = useState<IProtocol>(
        {} as IProtocol
    );

    const [showBetaWarning, setShowBetaWarning] = useState<boolean>(false);

    const { mapLoading, handleLoadMap, handleLocate } = useGoogleMap();
    const onMapLoad = handleLoadMap(undefined, true);

    const [rooms] = useArraySelector((state) => state.inventory.rooms);
    const hasBasementRooms = Boolean(
        rooms.find((room) => room.rom_level === 0)
    );

    const [tenants] = useArraySelector((state) => state.inventory.tenants);

    const roomsLoading = useDelayedPending([
        getRoom,
        postRoom,
        copyRoom,
        roomAttrCrud,
        patchRoom,
        deleteRoom
    ]);
    const tenantsLoading = useDelayedPending([
        getTenant,
        postTenant,
        deleteTenant
    ]);

    const protocolLoading = usePending([postProtocol]);
    const copyLoading = usePending([copyDpd]);

    const isPhone = useMatchMedia('mobile');

    const uploadStore = useSelector((state) => state.uploads.uploads);
    const lookupHash = useSelector((state) => state.basicData.response);
    const useBeta = useSelector((state) => state.user.user.response?.use_beta);
    const openProtocols = useSelector(
        (state) => state.protocol.protocols.response.results
    );
    const offlineProtocolSessionId = useSelector(
        (state) => state.global.offlineProtocolSessionId
    );

    const {
        dpd_title,
        dpd_type,
        dpd_prt_id,
        dpd_attributes,
        dpd_description,
        uploads
    } = object;
    const buildingId = object?.dpd_assoc_data?.dpd_id;
    const buildingStreet = object?.dpd_assoc_data?.dpd_street;
    const buildingStreetNumber = object?.dpd_assoc_data?.dpd_street_number;
    const objectId = object.dpd_id;

    const dpdType = dpd_type || '';
    const isHouse = isHouseType(dpdType);
    const typeName =
        lookupHash?.[DPD_TYPE]?.[dpdType] ||
        lookupHash?.[SUBDPD_TYPE]?.[dpdType] ||
        '//';

    const propertyIcon = isHouse
        ? propertyToIcon(dpdType)
        : objectToIcon(dpdType);

    const hasProtocol = dpd_prt_id != null;

    const canStartProtocol = !hasProtocol;

    const currentLink = getPropertyLink(
        objectId,
        object.dpd_assoc_data?.dpd_id
    );

    const storedUploads = safeByIds(uploads as number[], uploadStore.byIds);
    const [attachments, media] = partition(
        storedUploads,
        (upload) => upload.type === MEDIA_ATTACHMENT_TYPE
    );
    const [mainPicture] = media;

    const dpdAttrs = justArray(dpd_attributes);
    const { listVal } = attrListToStr(
        dpdAttrs.map((attr) => attr.dpa_value || ''),
        { itemLimit: 10, charPerItem: 10 }
    );

    const attachCount = attachments.length;

    useEffect(() => {
        if (!initTab) return;
        setActiveTab(initTab);

        if (initTab === 'reporting' && viewedItem.type !== 'issue_category') {
            setViewedItem({ type: 'issue_category', item: 'unresolved' });
        }
    }, [initTab, viewedItem.type]);

    const lang = i18n.language as ILang;
    useEffect(() => {
        let isMount = true;

        if (!category) {
            dispatchAsync(
                dispatch,
                getIssues(objectId, { iss_fix: false, lang })
            ).then((_) => {
                if (isMount) setFetchHash((s) => ({ ...s, unresolved: true }));
            });
        }
        return () => {
            isMount = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, objectId, lang]);

    const category = categoryParam?.category;
    const fetchedCategory = fetchHash[category || ''];

    useEffect(() => {
        let isMount = true;
        if (category) {
            setViewedItem({ type: 'issue_category', item: category });

            dispatchAsync(
                dispatch,
                getIssues(objectId, {
                    ...issueCategoryToFilter(category),
                    lang
                })
            ).then((_) => {
                if (isMount) {
                    setFetchHash((s) => ({ ...s, [category]: true }));
                }
            });
        }
        return () => {
            isMount = false;
        };
    }, [dispatch, category, objectId, fetchedCategory, lang]);

    const tenId = tenParam?.tenId;
    const tenant =
        !tenantsLoading &&
        tenId != null &&
        tenants.find((tenant) => tenant.id === tenId);
    useEffect(() => {
        if (tenant) {
            setViewedItem({ type: 'tenant', item: tenant });
        }
    }, [tenant]);

    useEffect(() => {
        dispatch(getTemplate(dpd_type || 'H', lang));
    }, [dispatch, dpd_type, lang]);

    useEffect(() => {
        dispatch(getRoom(objectId, lang));
        dispatch(getTenant(objectId));
    }, [dispatch, objectId]);

    const shouldLocate = !mainPicture && !mapLoading;
    useEffect(() => {
        if (shouldLocate) handleLocate(propertyAddressToForm(object));
    }, [shouldLocate, object, handleLocate]);

    useEffect(() => {
        if (viewedItem.type === 'issue_category') {
            forceCheck();
        }
    }, [viewedItem.type]);
    useEffect(() => {
        const foundProtocol =
            openProtocols.find(
                (openProtocol) => openProtocol.prt_dpd_id?.dpd_id === objectId
            ) || ({} as IProtocol);
        if (foundProtocol) {
            setObjectProtocol(foundProtocol);
        }
    }, []);

    const handlePendingProtocolDelete = (dpdId: number) => {
        // delete protocol of the property when deleting from object page
        let dpdProtocolId;
        openProtocols.forEach((openProtocol) => {
            if (openProtocol.prt_dpd_id?.dpd_id === dpdId) {
                dpdProtocolId = openProtocol.prt_id;
            }
        });

        if (dpdProtocolId) {
            dispatchAsync(dispatch, delProtocol(dpdProtocolId)).catch(
                ({ error }) =>
                    dispatch(
                        showSnackbar({
                            message: 'delete_protocol_error',
                            error
                        })
                    )
            );
        }
    };

    const handleDelete = useCallback(() => {
        const backPath = getBuildingLink(buildingId) || DASHBOARD_ROUTE;

        handlePendingProtocolDelete(objectId);
        dispatchAsync(dispatch, deleteDpd(objectId))
            .then((_) => history.push(backPath))
            .catch(({ error }) =>
                dispatch(
                    showSnackbar({
                        message: 'object_delete_fail_message',
                        error
                    })
                )
            );

        setDelOpen(false);
    }, [dispatch, history, objectId, buildingId]);

    const fetchRooms = useCallback(() => {
        dispatch(getRoom(objectId, lang));
    }, [dispatch, objectId]);

    const onRoomChange = useCallback(() => {
        setViewedItem({ type: 'none' });
        fetchRooms();
    }, [fetchRooms]);

    const onObjectItemSelect = (e: ISelectEvent) => {
        const {
            detail: { item }
        } = e;
        const value = item.dataset['value'];
        switch (value) {
            case 'edit_object':
                onEditOpen(undefined);
                break;
            case 'start_protocol':
                if (!protocolLoading && canStartProtocol)
                    useBeta
                        ? setShowBetaWarning(true)
                        : handleStartProtocol({
                              dispatch,
                              history,
                              dpdId: objectId,
                              offlineProtocolSessionId
                          });
                break;
            case 'delete_object':
                setDelOpen(true);
                break;
            case 'add_room':
                onTemplateOpen();
                break;
            case 'copy_object':
                !copyLoading && handleObjectCopy();
                break;
        }
    };

    const scrollToViewEnd = useCallback(() => {
        // NOTE: this depends on the fact that scroll container for mobile is document
        const content = isPhone ? document.documentElement : contentRef.current;
        if (content) {
            content.scrollTo({
                top: content.scrollHeight - content.clientHeight,
                behavior: 'smooth'
            });
        }
    }, [isPhone, contentRef]);

    const handleObjectCopy = useCallback(() => {
        const backPath = getBuildingLink(buildingId) || DASHBOARD_ROUTE;

        dispatchAsync(dispatch, copyDpd(objectId))
            .then((_) => {
                dispatch(
                    showSnackbar({
                        message: 'copy_object_success',
                        snackType: 'info'
                    })
                );
                history.push(backPath);
            })
            .catch(({ error }) =>
                dispatch(showSnackbar({ message: 'copy_object_fail', error }))
            );
    }, [dispatch, history, objectId, buildingId]);

    const viewedRoom = viewedItem.type === 'room' ? viewedItem.item : undefined;
    const urlElementId =
        viewedRoom != null && !roomsLoading ? elementParam?.eleId : undefined;

    const viewedTenant =
        viewedItem.type === 'tenant' ? viewedItem.item : undefined;
    const viewedCategory =
        viewedItem.type === 'issue_category' ? viewedItem.item : undefined;

    const stackType = Boolean(
        hasProtocol || attachCount || listVal || dpd_description
    );
    const stackDescription = Boolean(hasProtocol || attachCount || listVal);
    const stackAttributes = Boolean(hasProtocol || attachCount);
    const stackAttachments = Boolean(hasProtocol);

    const paths =
        buildingId != null
            ? [
                  {
                      link: ONE_DPD(buildingId),
                      title: `${buildingStreet || '----'} ${
                          buildingStreetNumber || '--'
                      }`
                  }
              ]
            : undefined;

    const handleProtocolAccess = () => {
        const { checkout_data = {}, prt_id } = objectProtocol;
        if (
            useBeta &&
            checkout_data &&
            Object.keys(checkout_data as any).length
        ) {
            setOpenCheckoutWarningModal(true);
        } else {
            dpd_prt_id
                ? dispatchAsync(
                      dispatch,
                      getOfflineProtocolCheckoutId(dpd_prt_id, {
                          override: true,
                          session_id: offlineProtocolSessionId,
                          check_validity: true
                      })
                  )
                      .then(() => {
                          dispatch(setCheckoutLoading(false));
                          history.push(PROTOCOL_ROUTE(prt_id));
                      })
                      .catch((e) => {
                          const errorResponseDataCode =
                              e.error.response.data.code;
                          if (JSON.parse(errorResponseDataCode) === 101) {
                              dispatchAsync(
                                  dispatch,
                                  showSnackbar({
                                      snackType: 'error',
                                      message: 'offline_proto_closed'
                                  })
                              ).then(() => {
                                  window.location.reload();
                              });
                          }
                          dispatch(setCheckoutLoading(false));
                      })
                : history.push(PROTOCOL_ROUTE(prt_id));
        }
    };

    return (
        <div className="object__page">
            <Header title={dpd_title || t('no_title')} paths={paths} />
            <div className="object__content">
                <div className="object__sidebar">
                    <div className="object__info">
                        <div className="object__picture stack-m">
                            {mainPicture ? (
                                <StdMedia
                                    icon={propertyIcon}
                                    media={mainPicture}
                                    width={254}
                                    height={201}
                                    alt="object picture"
                                />
                            ) : (
                                <Map
                                    options={mapConfig}
                                    onMapLoad={onMapLoad}
                                />
                            )}
                        </div>
                        <div>
                            <div className="std-section stack-m">
                                <div
                                    className="font__header util__pointer"
                                    onClick={() => setViewOpen(true)}
                                >
                                    {dpd_title || t('no_title')}
                                </div>
                                <div>
                                    <MenuSurfaceAnchor>
                                        <StdDropdown
                                            open={optionsOpen}
                                            onClose={() =>
                                                setOptionsOpen(false)
                                            }
                                            onSelect={onObjectItemSelect}
                                        >
                                            <MenuItem data-value="edit_object">
                                                {t('edit_object')}
                                            </MenuItem>
                                            <MenuItem data-value="delete_object">
                                                {t('delete_object')}
                                            </MenuItem>
                                            <MenuItem
                                                data-value="copy_object"
                                                disabled={copyLoading}
                                            >
                                                {t('copy_object')}
                                            </MenuItem>
                                            <ListDivider />
                                            {canStartProtocol && (
                                                <MenuItem
                                                    data-value="start_protocol"
                                                    disabled={protocolLoading}
                                                >
                                                    {t('start_protocol')}
                                                </MenuItem>
                                            )}
                                            <MenuItem data-value="add_room">
                                                {t('add_room')}
                                            </MenuItem>
                                        </StdDropdown>
                                        <StdIcon
                                            name="ellipsis-v"
                                            prefix="far"
                                            onClick={() => setOptionsOpen(true)}
                                            clickable
                                            small
                                        />
                                    </MenuSurfaceAnchor>
                                </div>
                            </div>
                            <div
                                className={clsx(
                                    'object__type',
                                    stackType && 'stack-m'
                                )}
                                onClick={() => setViewOpen(true)}
                            >
                                <StdIcon
                                    className="inline-m"
                                    name={propertyIcon}
                                    grey
                                />
                                <span>{typeName}</span>
                            </div>
                            {Boolean(dpd_description) && (
                                <div
                                    className={clsx(
                                        'object__description',
                                        stackDescription && 'stack-m'
                                    )}
                                    onClick={() => setViewOpen(true)}
                                >
                                    <StdIcon
                                        className="inline-m"
                                        name="sticky-note"
                                        grey
                                    />
                                    <span>{dpd_description}</span>
                                </div>
                            )}
                            {listVal && (
                                <div
                                    className={clsx(
                                        'object__attribute-list',
                                        stackAttributes && 'stack-m'
                                    )}
                                    onClick={() => setViewOpen(true)}
                                >
                                    <StdIcon
                                        className="inline-m"
                                        name="list"
                                        grey
                                    />
                                    <span>{listVal}</span>
                                </div>
                            )}
                            {Boolean(attachCount) && (
                                <div
                                    className={clsx(
                                        'object__attachment-list',
                                        stackAttachments && 'stack-m'
                                    )}
                                    onClick={() => setViewOpen(true)}
                                >
                                    <StdIcon
                                        className="inline-m"
                                        name="paperclip"
                                        grey
                                    />
                                    <span>
                                        {attachCount} {t('attachments')}
                                    </span>
                                </div>
                            )}
                            <div>
                                {dpd_prt_id != null && (
                                    <div>
                                        <StdChip
                                            // to={PROTOCOL_ROUTE(dpd_prt_id)}
                                            onClick={handleProtocolAccess}
                                        >
                                            {t('open_protocol')}
                                        </StdChip>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <ObjectBar
                        className="stack-s"
                        selectedTab={activeTab}
                        tabs={{
                            roomTab: (
                                <RoomTab
                                    object={object}
                                    rooms={rooms}
                                    viewedRoomId={viewedRoom?.rom_id}
                                    urlFloorNumber={floorParam?.floorNum}
                                    urlRoomId={roomParam?.roomId}
                                    onTemplateSelect={(payload) =>
                                        onTemplateOpen(payload)
                                    }
                                    onRoomView={(room) =>
                                        setViewedItem({
                                            type: 'room',
                                            item: room
                                        })
                                    }
                                    roomsLoading={roomsLoading}
                                    isHouse={isHouse}
                                />
                            ),
                            tenantTab: (
                                <TenantTab
                                    className="stack-l"
                                    selectedTenant={viewedTenant}
                                    dpdId={objectId}
                                    tenants={tenants}
                                    onTenantSelect={(tenant) =>
                                        history.push({
                                            search: `?tenant=${tenant.id}`
                                        })
                                    }
                                />
                            ),
                            reportTab: (
                                <IssueTab
                                    className="stack-l"
                                    resolvedCount={
                                        object.closed_issue_count || 0
                                    }
                                    unresolvedCount={
                                        object.open_issue_count || 0
                                    }
                                    excessiveCount={
                                        object.excessive_issue_count || 0
                                    }
                                    lightCount={object.light_issue_count || 0}
                                    filter={viewedCategory}
                                    onFilterChange={(filter) =>
                                        history.push({
                                            search: `?category=${filter}`
                                        })
                                    }
                                />
                            )
                        }}
                        onTabChange={(tab) =>
                            history.push({ search: `?tab=${tab}` })
                        }
                        isRoomLoading={roomsLoading}
                        isTenantLoading={tenantsLoading}
                    />
                </div>
                <div className="object__main" ref={contentRef}>
                    <div hidden={viewedItem.type !== 'room'}>
                        <RoomView
                            room={viewedRoom}
                            dpd={object}
                            link={currentLink}
                            initEleId={urlElementId}
                            onDelete={onRoomChange}
                            onEdit={onRoomChange}
                            onCopy={onRoomChange}
                            onElesLoaded={scrollToContent}
                            scrollToEnd={scrollToViewEnd}
                        />
                    </div>
                    <div hidden={viewedItem.type !== 'tenant'}>
                        <TenantView
                            dpdId={objectId}
                            tenant={viewedTenant}
                            onTenantsLoaded={scrollToContent}
                            canStartProtocol={canStartProtocol}
                            onDelete={() => setViewedItem({ type: 'none' })}
                        />
                    </div>
                    <div hidden={viewedItem.type !== 'issue_category'}>
                        <IssuesView
                            filter={viewedCategory}
                            initIssueId={issueParam?.issueId}
                            dpdLink={currentLink}
                            onIssuesLoaded={scrollToContent}
                        />
                    </div>
                </div>
            </div>
            <ObjectModal
                dpd={object}
                open={viewOpen}
                onClose={() => setViewOpen(false)}
                onEdit={(param) => onEditOpen(param)}
            />

            {isHouse ? (
                <EditObjectHouse
                    parentId={buildingId}
                    dpd={object}
                    open={edit.open}
                    onClose={onEditClose}
                    onSuccess={handleFetch}
                    toAttr={edit.item === 'attr'}
                    toAttach={edit.item === 'attach'}
                    hasBasementRooms={hasBasementRooms}
                    isHidingRooms={(newFloor) =>
                        isAnyRoomInvisible(rooms, newFloor)
                    }
                />
            ) : (
                <EditObjectModal
                    parentId={buildingId}
                    dpd={object}
                    open={edit.open}
                    onClose={onEditClose}
                    onSuccess={handleFetch}
                    toAttr={edit.item === 'attr'}
                    toAttach={edit.item === 'attach'}
                    hasBasementRooms={hasBasementRooms}
                    isHidingRooms={(newFloor) =>
                        isAnyRoomInvisible(rooms, newFloor)
                    }
                />
            )}
            <EditRoomModal
                object={object}
                open={template.open}
                room={template.item}
                onClose={onTemplateClose}
                onSuccess={fetchRooms}
            />
            <ObjectConfirmDeleteModal
                className="stack-m"
                open={delOpen}
                onClose={() => setDelOpen(false)}
                onConfirm={handleDelete}
                message={t('delete_object_message', {
                    object_name: dpd_title || t('no_title')
                })}
                title={dpd_title}
                verifyNote={t('object_delete_confirm_note')}
                verifyMessage="YES"
            />
            <ProtocolBetaWarningModal
                open={showBetaWarning}
                onAccept={() => {
                    setShowBetaWarning(false);
                    handleStartProtocol({
                        dispatch,
                        history,
                        dpdId: objectId,
                        useBeta,
                        offlineProtocolSessionId
                    });
                }}
                onCancel={() => {
                    setShowBetaWarning(false);
                }}
            />
            {dpd_prt_id && (
                <ProtocolCheckoutWarningModal
                    data={
                        objectProtocol?.checkout_data as IProtocolCheckoutData
                    }
                    open={openCheckoutWarningModal}
                    onAccept={() => {
                        // run checkout API with {override: true}
                        dispatchAsync(
                            dispatch,
                            getOfflineProtocolCheckoutId(dpd_prt_id, {
                                override: true,
                                session_id: offlineProtocolSessionId,
                                check_validity: true
                            })
                        )
                            .then(() => {
                                dispatch(setCheckoutLoading(false));
                                history.push(PROTOCOL_ROUTE(dpd_prt_id));
                            })
                            .catch((e) => {
                                const errorResponseDataCode =
                                    e.error.response.data.code;
                                if (JSON.parse(errorResponseDataCode) === 101) {
                                    dispatchAsync(
                                        dispatch,
                                        showSnackbar({
                                            snackType: 'error',
                                            message: 'offline_proto_closed'
                                        })
                                    ).then(() => {
                                        window.location.reload();
                                    });
                                }
                                dispatch(setCheckoutLoading(false));
                            });
                    }}
                    onCancel={() => {
                        setOpenCheckoutWarningModal(false);
                    }}
                />
            )}
        </div>
    );
};

export default memo(
    ObjectPage,
    (prevProps, nextProps) => prevProps.object === nextProps.object
);
