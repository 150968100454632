import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'validator';
import { getLocationCH } from '../store/action-creators';

interface IAdressData {
    country: string;
    street_name: string;
    city: string;
    zip: string;
    street_number: string;
}

const useGeoAdmin = (onFinally: (egid?: string, ewid?: string) => void) => {
    const dispatch = useDispatch();

    const geoAdminRes = useSelector((state) => state.geoAdmin);
    const geoAdminResult: IGeoAdminItem = geoAdminRes.response.results[0];

    useEffect(() => {
        if (geoAdminResult) {
            const { featureId } = geoAdminResult.attrs;
            const [egid, ewid] = featureId.split('_');

            onFinally?.(egid, ewid);
        } else {
            onFinally?.();
        }
    }, [geoAdminResult, onFinally]);

    const requestInfo = useCallback(
        (data: IAdressData) => {
            const { country, street_name, street_number, zip, city } = data;
            const isSearchableAddress = Boolean(
                country === 'CH' &&
                    !isEmpty(city) &&
                    !isEmpty(street_number) &&
                    !isEmpty(zip)
            );

            if (isSearchableAddress)
                dispatch(
                    getLocationCH({
                        searchText: `${street_name} ${street_number}, ${zip} ${city}`
                    })
                );
        },
        [dispatch]
    );

    return requestInfo;
};

export default useGeoAdmin;
