import { takeEvery, all } from 'redux-saga/effects';
import { RESEND_ATTENDEE_EMAIL, RESEND_ATTENDEE_EMAIL_SUCCESS } from '../../actions';
import {
    resendAttendeeEmail,
    resendAttendeeEmailSuccess,
    resendAttendeeEmailFail
} from '../../action-creators';

import { simpleRequest } from '../modules/request';
import { responseHelper } from '../modules/promise';

function onSuccessCallback(data: any) {
    return data;
}

const requestEffect = takeEvery(
    RESEND_ATTENDEE_EMAIL,
    simpleRequest<RT<typeof resendAttendeeEmail>>({
        success: resendAttendeeEmailSuccess,
        failure: resendAttendeeEmailFail,
        getUrl: (action) => `attendee/${action.attendee_id}/resend_email/`,
        getBody: (_) => {},
        method: 'POST',
        enableOffline: true
    })
);

const responseEffect = responseHelper({
    actionName: RESEND_ATTENDEE_EMAIL_SUCCESS,
    processFn: onSuccessCallback
});

export default all([requestEffect, responseEffect]);
