import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { createPortal } from 'react-dom';
import { AnimatePresence, motion } from 'framer-motion';

import './std-overlay.scss';

interface IProps {
    open: boolean;
    animateInital?: boolean;
    transparent?: boolean;
    children?: React.ReactNode;
    onExited?: ICallback;
}

const root = document.getElementById('root');

const StdOverlay: React.FC<IProps> = ({
    open,
    transparent,
    animateInital = true,
    children,
    onExited
}) => {
    const everOpen = useRef(false);
    const beforeOpen = useRef<string>();

    useEffect(() => {
        return () => {
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        if (!everOpen.current && open) {
            beforeOpen.current = document.body.style.overflow;
            everOpen.current = true;
            document.body.style.overflow = 'hidden';
        } else if (everOpen.current) {
            document.body.style.overflow = beforeOpen.current || '';
        }
    }, [open]);

    return createPortal(
        <AnimatePresence onExitComplete={onExited}>
            {open && (
                <motion.div
                    className={clsx(
                        'overlay',
                        transparent && 'overlay--transparent'
                    )}
                    initial={animateInital && { opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                >
                    {children}
                </motion.div>
            )}
        </AnimatePresence>,
        root || document.body
    );
};

export default StdOverlay;
