import { takeEvery } from 'redux-saga/effects';

import { createInvitationSet } from '../../action-creators/team';
import { simpleRequest } from '../modules/request';

export default takeEvery(
    createInvitationSet.type,
    simpleRequest<RT<typeof createInvitationSet.request>>({
        success: createInvitationSet.success,
        failure: createInvitationSet.fail,
        getUrl: (action) => `group_invitation/?lang=${action.lang}`,
        getBody: (action) => action.payload,
        method: 'POST',
        isV2Api: true
    })
);
