import React from 'react';
import clsx from 'clsx';
import { Ripple } from '@rmwc/ripple';

import './select-list-item.scss';

interface IProps extends IClass {
    title: React.ReactNode;
    subtitle: React.ReactNode;
    disabled?: boolean;
    onClick?: ICallback;
}

const SelectListItem: React.FC<IProps> = ({
    className,
    title,
    subtitle,
    disabled,
    onClick
}) => {
    return (
        <Ripple disabled={disabled}>
            <div
                className={clsx('select-list-item', className)}
                onClick={!disabled ? onClick : undefined}
            >
                <div className="stack-s">{title}</div>
                <div className="select-list-item__subtitle">{subtitle}</div>
            </div>
        </Ripple>
    );
};

export default SelectListItem;
