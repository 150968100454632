import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { MEDIA_ATTACHMENT_TYPE } from '../../../configs/costants';
import IssueModule from '../../../modules/issue';

import { safeByIds } from '../../../store/storeModule';
import { setImageDimentions } from '../../../modules/upload';
import { attrListToStr } from '../../../utils';
import { justArray } from '../../../utils';

import { MenuSurfaceAnchor, MenuItem } from '@rmwc/menu';

import StdImage from '../../atoms/StdImage';
import StdIcon from '../../atoms/StdIcon';
import StdDropdown from '../../atoms/StdDropdown';
import ChipIssueList from '../../issues/ChipIssueList';
import StdButton from '../../atoms/StdButton';

import './element-card.scss';

interface IProps extends IClass {
    element: IElement;
    onView?: ICallback;
    onEdit?: ICallback;
    onDel?: ICallback;
    onAddIssue?: ICallback;
}

const ElementCard: React.FC<IProps> = ({
    className,
    element,
    onView,
    onEdit,
    onDel,
    onAddIssue
}) => {
    const { t } = useTranslation();
    const { ele_name, ele_ela_id, ele_iss, uploads } = element;

    const elementIssues = justArray(ele_iss);
    const issues = elementIssues.filter((issue) => !issue.iss_fix);
    const hasIssues = Boolean(issues.length);

    const hasUrgentIssue = issues.some(
        (issue) => IssueModule.isOpen(issue) && IssueModule.isExcessive(issue)
    );

    const uploadStore = useSelector((state) => state.uploads.uploads);
    const storedUploads = safeByIds(uploads as number[], uploadStore.byIds);

    const [optionsOpen, setOptionsOpen] = useState(false);

    const media = storedUploads.find(
        (upload) => upload.type !== MEDIA_ATTACHMENT_TYPE
    );

    const elAttrs = Array.isArray(ele_ela_id) ? ele_ela_id : [];
    const { listVal } = attrListToStr(
        elAttrs.map((attr) => attr.ela_value || ''),
        { charLimit: 28 }
    );

    return (
        <div className={clsx('element-card', className)}>
            <div
                className={clsx(
                    'element-card__block',
                    'inline-m',
                    'util__pointer'
                )}
                onClick={onView}
            >
                <StdImage
                    icon="image"
                    src={setImageDimentions(media?.path || '', 64, 64)}
                />
            </div>
            <div className="element-card__info inline-l">
                <div
                    className={clsx(
                        'font--strong',
                        'stack-s',
                        'util__pointer',
                        'element-card__title'
                    )}
                    onClick={onView}
                >
                    {hasUrgentIssue && <div className="std-badge inline-xs" />}
                    <span>{ele_name || '--'}</span>
                    <div className="element-card__info-icon" onClick={onView}>
                        <StdIcon clickable name="info-circle" prefix="fal" />
                    </div>
                </div>
                {hasIssues ? (
                    <div className="element-card__issue-group">
                        <ChipIssueList issues={issues} onIssueClick={onView} />
                    </div>
                ) : (
                    <>
                        {Boolean(listVal) && (
                            <span
                                className="info__attributes inline-xs"
                                onClick={onView}
                            >
                                {listVal}
                            </span>
                        )}
                    </>
                )}
            </div>
            <div className="element-card__icon">
                <MenuSurfaceAnchor>
                    <StdDropdown
                        open={optionsOpen}
                        onClose={() => setOptionsOpen(false)}
                        onSelect={(e) => {
                            const {
                                detail: { item }
                            } = e;
                            const value = item.dataset['value'];
                            switch (value) {
                                case 'add_issue':
                                    onAddIssue?.();
                                    break;
                                case 'edit_element':
                                    onEdit?.();
                                    break;
                                case 'delete_element':
                                    onDel?.();
                                    break;
                            }
                        }}
                    >
                        <MenuItem data-value="add_issue">
                            {t('add_issue')}
                        </MenuItem>
                        <MenuItem data-value="edit_element">
                            {t('edit_element')}
                        </MenuItem>
                        <div className="divider" />
                        <MenuItem data-value="delete_element">
                            {t('delete_element')}
                        </MenuItem>
                    </StdDropdown>
                    <StdIcon
                        name="ellipsis-v"
                        prefix="far"
                        clickable
                        onClick={() => setOptionsOpen(true)}
                    />
                </MenuSurfaceAnchor>
            </div>
        </div>
    );
};

export default ElementCard;
