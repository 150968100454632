const env = process.env.NODE_ENV || 'development';

// Look up env variables in either config (if set), or in process.env
// Will reject injected envs if they contain themselves
// i.e REACT_APP_HOST: "PREFIX_REACT_APP_HOST_POSTFIX" will not work
function envLookup(key: string) {
    const settingsHash = window.ENV_SETTINGS || {};

    const settingValue =
        typeof settingsHash[key] === 'string' ? settingsHash[key] : undefined;
    const processValue = process.env[key] || '';

    const isSet = settingValue != null && settingValue.indexOf(key) === -1;

    return isSet ? settingValue : processValue;
}

const properties = {
    env,
    host: envLookup('REACT_APP_HOST'),
    apiUrl: envLookup('REACT_APP_API_URL'),
    imagesHost: envLookup('REACT_APP_PICTURE_HOST'),
    googleApi: envLookup('REACT_APP_GOOGLE_API_KEY'),
    version: envLookup('REACT_APP_VERSION'),
    intercomAppId: envLookup('REACT_APP_INTERCOM_APP_ID'),
    sentryUrl: envLookup('REACT_APP_SENTRY_API_URL')
};

class Environment {
    getProperty(key: keyof typeof properties) {
        return properties[key] || '';
    }
}

export default new Environment();
