import { useCallback, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Optional } from 'utility-types';

import {
    AWS_UPLOAD_LIMIT,
    MEDIA_TYPE_IMAGE,
    MEDIA_TYPE_VIDEO,
    VIDEO_LENGTH_LIMIT
} from '../configs/costants';

import {
    uploadMedia,
    deleteMedia,
    renameMedia,
    showSnackbar,
    cancelUpload,
    deleteLocalMedia
} from '../store/action-creators';

import { dispatchAsync, getActionId, safeByIds } from '../store/storeModule';

import {
    bytesToMB,
    getExtension,
    renameUploadKey,
    isVideoFile
} from '../modules/file';
import {
    removeAtIndex,
    replacePrimitve,
    iterateOverHash
} from '../modules/array';
// import useCompressUploader from './useMediaCompressor';

// Public utils

export function uploadResultsToIds(
    results: PromiseSettledResult<number | void>[]
) {
    return results.map((result) => {
        if (result.status === 'rejected' || result.value == null) return null;
        return result.value;
    });
}

// Utils

const getVideoDuration = (file: File) => {
    const videoUrl = URL.createObjectURL(file);
    const video = document.createElement('video');

    let listenerRef: ICallback, errorRef: ICallback;
    return new Promise<number>((resolve, reject) => {
        video.preload = 'metadata';
        video.setAttribute('playsinline', 'true');
        video.setAttribute('muted', 'true');

        listenerRef = () => {
            const duration = video.duration;
            const isValid =
                typeof duration === 'number' &&
                !Number.isNaN(duration) &&
                Number.isFinite(duration);

            if (isValid) {
                resolve(video.duration);
            }
        };
        errorRef = () => reject(video.error);

        //video.addEventListener('durationchange', listenerRef);
        video.addEventListener('loadedmetadata', listenerRef);
        video.addEventListener('error', errorRef);

        video.src = videoUrl;

        // Neither listen "loadedmetadata" or "durationchange", video.duration is unreliable.
        // Seek to a big number time and listen to the event
        video.currentTime = 7 * 24 * 60 * 1000;

        video.addEventListener('seeked', listenerRef);
    }).finally(() => {
        video.pause();

        //video.removeEventListener('durationchange', listenerRef);
        video.removeEventListener('loadedmetadata', listenerRef);
        video.removeEventListener('seeked', listenerRef);

        video.removeEventListener('error', errorRef);

        // NOTE: because of an issue where the same file will be "NOT_FOUND" if
        // the URL has been previosly revoked, the url will stay in memory until we don't need the video altogether anymore

        //URL.revokeObjectURL(videoUrl);
        video.remove();
    });
};

const formatLengthLimit = (limit: number) => {
    return limit >= 60 ? `${limit / 60.0}min` : `${limit}s`;
};

const isSupportedType = (type: string, filename: string, filetypes: string) => {
    const [typeName, ext] = type.split('/');
    const systemExt = getExtension(filename);

    const types = filetypes.split(',');

    return types.some((type) => {
        // it's either .ext, or image/*, this split should handle both just fine
        const [accName] = type.split('/');
        return (
            typeName === accName ||
            `.${ext}` === accName ||
            `.${systemExt}` === accName
        );
    });
};

//

type IValidationError = [string, object?] | undefined;

interface INumberValidatorConfig {
    // Current values
    totalFiles: number;
    totalVideoFiles: number;

    // Limits
    totalLimit: number;
    imageLimit?: number;
    videoLimit?: number;
}

function validateFileNumber(
    files: File[],
    config: INumberValidatorConfig
): IValidationError {
    const newVideoCount = files.reduce(
        (acc, val) => (isVideoFile(val) ? acc + 1 : acc),
        0
    );
    const newImageCount = files.length - newVideoCount;

    // Images that are currently in the uploader
    const totalImages = config.totalFiles - config.totalVideoFiles;

    if (files.length + config.totalFiles > config.totalLimit) {
        // return ['too_many_files_error', { limit: config.totalLimit }];
        return ['too_many_files_error', { limit: config.totalLimit }];
    }

    if (
        config.imageLimit != null &&
        newImageCount + totalImages > config.imageLimit
    ) {
        return ['too_many_images_error', { limit: config.imageLimit }];
    }

    if (
        config.videoLimit != null &&
        newVideoCount + config.totalVideoFiles > config.videoLimit
    ) {
        return ['too_many_videos_error', { limit: config.videoLimit }];
    }
}

interface IFileValidatorConfig {
    sizeLimit: number;
    allowedFiletypes: string;
    videoLengthLimit: number;
}

function validateFile(file: File, config: IFileValidatorConfig): Promise<void> {
    return new Promise((resolve, reject) => {
        if (file.size > config.sizeLimit) {
            reject([
                'file_too_big_error',
                { limit: bytesToMB(config.sizeLimit) }
            ]);
        }

        if (!isSupportedType(file.type, file.name, config.allowedFiletypes)) {
            reject(['usupported_type_error']);
        }

        if (isVideoFile(file)) {
            getVideoDuration(file)
                .then((duration) => {
                    if (duration > config.videoLengthLimit) {
                        reject([
                            'video_too_long_error',
                            {
                                limit: formatLengthLimit(
                                    config.videoLengthLimit
                                )
                            }
                        ]);
                    }
                    resolve();
                })
                .catch((_) => reject(['get_video_duration_error']));
        } else {
            resolve();
        }
    });
}

interface IPrivateUploadConfig
    extends INumberValidatorConfig,
        IFileValidatorConfig {
    type: string;
}

export type IUploadConfig = Optional<
    Omit<IPrivateUploadConfig, 'totalFiles' | 'totalVideoFiles'>,
    | 'type'
    | 'totalLimit'
    | 'sizeLimit'
    | 'allowedFiletypes'
    | 'videoLengthLimit'
>;

const defaultConfig = {
    totalLimit: 3,
    type: MEDIA_TYPE_IMAGE,
    sizeLimit: AWS_UPLOAD_LIMIT,
    allowedFiletypes: 'image/*',
    videoLengthLimit: VIDEO_LENGTH_LIMIT
};

interface IEdit {
    id: number;
    newName: string;
    newKey?: string;
}

function applyEdits(uploads: IUpload[], localEdits: IHash<IEdit>): IUpload[] {
    return uploads.map((upload) => {
        const edits = localEdits[upload.id];

        if (edits) {
            return {
                ...upload,
                name: edits.newName
            };
        }
        return upload;
    });
}

interface IUploaderState {
    edits: IHash<IEdit>;
    uploads: number[];
    deletions: number[];
    isUploading: boolean;
}

const initState: IUploaderState = {
    edits: {},
    uploads: [],
    deletions: [],
    isUploading: false
};

function uploaderReducer(
    state: IUploaderState,
    action: AnyAction
): IUploaderState {
    switch (action.type) {
        case 'set_uploads': {
            return {
                ...state,
                uploads: action.payload
            };
        }
        case 'remove_upload': {
            const deletedId = action.payload;
            const index = state.uploads.indexOf(deletedId);
            if (index === -1) return state;

            return {
                ...state,
                uploads: removeAtIndex(state.uploads, index)
            };
        }
        case 'upload_file': {
            return {
                ...state,
                uploads: state.uploads.concat(action.payload)
            };
        }
        case 'create_success': {
            const { oldId, newId } = action.payload;
            return {
                ...state,
                uploads: replacePrimitve(state.uploads, oldId, newId)
            };
        }
        case 'mark_deleted': {
            return {
                ...state,
                deletions: state.deletions.concat(action.payload)
            };
        }
        case 'reset_state': {
            return {
                edits: {},
                deletions: [],
                uploads: [],
                isUploading: false
            };
        }
        case 'rename_upload': {
            const { id, newName, newKey } = action.payload;
            return {
                ...state,
                edits: {
                    ...state.edits,
                    [id]: { id, newName, newKey }
                }
            };
        }
        case 'is_uploading': {
            const { isUploading } = action.payload;
            return {
                ...state,
                isUploading
            };
        }
    }
    return state;
}

const useUploader = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    // const compressUploader = useCompressUploader();

    const [uploaderState, dispatchLocal] = useReducer(
        uploaderReducer,
        initState
    );

    const localCounter = useSelector((state) => state.appState.localCounter);
    // const useBeta = useSelector((state) => state.user.user.response?.use_beta);

    const uploadStore = useSelector((state) => state.uploads.uploads);
    const storedUploads = safeByIds(uploaderState.uploads, uploadStore.byIds); // Note: safeByIds could technically case issues

    const totalFiles = storedUploads.length;
    const totalVideoFiles = storedUploads.reduce(
        (acc, val) => (val.useType === 'video' ? acc + 1 : acc),
        0
    );

    // -- Set functions
    const setUploads = useCallback((uploads: number[]) => {
        dispatchLocal({ type: 'set_uploads', payload: uploads });
    }, []);

    const setPartialUploads = useCallback(
        (uploadIds: number[], filter: (item: IUpload) => boolean) => {
            const setUploads = safeByIds(uploadIds, uploadStore.byIds);
            const uploads = setUploads
                .filter(filter)
                .map((upload) => upload.id);

            dispatchLocal({ type: 'set_uploads', payload: uploads });
        },
        [uploadStore.byIds]
    );

    // -- Rename functions
    const renameUploadHard = (
        id: number,
        oldKey: string,
        newFilename: string
    ) => {
        const { newKey, newName } = renameUploadKey(oldKey, newFilename);

        dispatchLocal({ type: 'rename_upload', payload: { id, newName } });
        return dispatchAsync(dispatch, renameMedia(id, newKey));
    };

    const renameUpload = (id: number, oldKey: string, newFilename: string) => {
        const { newKey, newName } = renameUploadKey(oldKey, newFilename);

        dispatchLocal({
            type: 'rename_upload',
            payload: { id, newKey, newName }
        });
    };

    // -- Remove functions
    // Remove media unconditionally
    const removeUploadHard = (id: number) => {
        dispatchLocal({ type: 'remove_upload', payload: id });

        const upload = uploadStore.byIds[id];

        if (upload.status === 'in-progress') dispatch(cancelUpload(id));
        return dispatchAsync(dispatch, deleteMedia(id));
    };

    // Avoid removing media until the submission
    const removeUpload = useCallback(
        (id: number) => {
            const upload = uploadStore.byIds[id];

            if (upload.status === 'in-progress') dispatch(cancelUpload(id));
            if (!upload.hasAssociations) {
                dispatch(id < 0 ? deleteLocalMedia(id) : deleteMedia(id));
            } else {
                dispatchLocal({ type: 'mark_deleted', payload: id });
            }

            dispatchLocal({ type: 'remove_upload', payload: id });
        },
        [dispatch, uploadStore.byIds]
    );

    const uploadFilesCore = (
        files: File[],
        userConfig?: IUploadConfig
    ): Promise<PromiseSettledResult<number | void>[]> => {
        const config: IPrivateUploadConfig = {
            ...defaultConfig,
            ...userConfig,
            totalFiles,
            totalVideoFiles
        };

        const error = validateFileNumber(files, config);
        console.log('error from useUploader ----> ', { error });
        if (error) {
            alert(t(...error));
            return Promise.reject();
        }

        dispatchLocal({ type: 'is_uploading', payload: { isUploading: true } });
        let localId = localCounter;
        const results = files.map(async (file) => {
            const compressedFile = file;
            return validateFile(compressedFile as File, config)
                .then((_) => {
                    dispatchLocal({ type: 'upload_file', payload: localId });

                    const med_type = isVideoFile(compressedFile as File)
                        ? MEDIA_TYPE_VIDEO
                        : config.type;

                    const uploadParams = {
                        file: compressedFile as File,
                        params: { med_type },
                        onMediaCreated: (oldId: number, newId: number) => {
                            dispatchLocal({
                                type: 'is_uploading',
                                payload: { isUploading: false }
                            });
                            dispatchLocal({
                                type: 'create_success',
                                payload: { oldId, newId }
                            });
                        }
                    };

                    const uploadResult = dispatchAsync(
                        dispatch,
                        uploadMedia(uploadParams)
                    )
                        .then((id) => {
                            if (typeof id === 'number') return id;
                        })
                        .catch((action) => {
                            const { error } = action;
                            if (error?.message === 'promise_cancelled') {
                                const id = getActionId(action);
                                if (id != null) return id;
                            }
                            dispatch(
                                showSnackbar({
                                    message: 'upload_failed_message',
                                    error
                                })
                            );
                        });
                    --localId;
                    return uploadResult;
                })
                .catch((reason: IValidationError) => {
                    if (reason) {
                        alert(t(...reason));
                    }
                });
        });

        return Promise.allSettled(results);
    };

    const uploadFiles = (files: File[], userConfig?: IUploadConfig) => {
        uploadFilesCore(files, userConfig);
    };

    // -- Flow handlng functions
    const submitChanges = () => {
        const deletions = uploaderState.deletions.map((id) =>
            dispatchAsync(dispatch, deleteMedia(id))
        );

        const edits = iterateOverHash(uploaderState.edits)
            .filter((edit) => edit.newKey)
            .map((edit) =>
                dispatchAsync(
                    dispatch,
                    renameMedia(edit.id, edit.newKey as string)
                )
            );

        dispatchLocal({ type: 'reset_state' });
        return Promise.allSettled(deletions.concat(edits));
    };

    const cancelChanges = () => {
        dispatch(cancelUpload());

        const uncommittedUploads = storedUploads.filter(
            (upload) => !upload.hasAssociations
        );
        const deletions = uncommittedUploads.map((upload) =>
            dispatchAsync(dispatch, deleteMedia(upload.id), {
                disableLoading: true
            })
        );

        dispatchLocal({ type: 'reset_state' });
        return Promise.allSettled(deletions);
    };

    const previews = applyEdits(storedUploads, uploaderState.edits);
    return {
        ids: uploaderState.uploads,
        isUploading: uploaderState.isUploading,
        previews,
        setUploads,
        setPartialUploads,
        removeUpload,
        renameUpload,
        uploadFiles,
        uploadFilesCore,
        submitChanges,
        cancelChanges,
        removeUploadHard,
        renameUploadHard
    };
};

export type IUploaderResult = RT<typeof useUploader>;

export default useUploader;
