import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ConfirmModal from '../../molecules/ConfirmModal';

interface IProps extends IModalBase {
    amount: string;
    price: string;
}

const StorageModal: React.FC<IProps> = ({ open, amount, price, onClose }) => {
    const { t } = useTranslation();
    return (
        <ConfirmModal
            className="stack-l"
            open={open}
            onClose={onClose}
            cancelLabel={t('okay')}
            isNotification
            alignRight
        >
            <div className="font__header stack-m">
                {t('plan.storage.manage_title')}
            </div>
            <div className="stack-m">{t('plan.storage.manage_text_1')}</div>
            <div>
                <Trans t={t} components={{ bold: <strong /> }}>
                    {t('plan.storage.manage_text_2', {
                        storage_amount: amount,
                        price_amount: price
                    })}
                </Trans>
            </div>
        </ConfirmModal>
    );
};

export default StorageModal;
