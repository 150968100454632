import React from 'react';
import clsx from 'clsx';
import StdButton from '../../atoms/StdButton';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '../../atoms/StdModal';
import { logout } from '../../../store/action-creators';

interface AccountExpiredWarningProps {}

const AccountExpiredWarning: React.FC<AccountExpiredWarningProps> = ({}) => {
    const dispatch = useDispatch();
    const mainAccountExpired = useSelector((state) => state.user.user.response?.trial.main_account_expired)
    const mainAccountEmail = useSelector((state) => state.user.user.response?.trial.main_account_email)


    return (
        <>
            {mainAccountExpired && (
                <Modal
                    open={true}
                    onClose={() => {}}
                    white
                    contentClassName="accExp-Warning-modal-content"
                >
                    <div className="modal__section--square accExp-Warning-modal">
                        <div
                            className={clsx(
                                'stack-xl',
                                'accExp-Warning-content-wrapper'
                            )}
                        >
                            <div className="modal-header">
                                Your Aimmo subscription has expired!
                            </div>
                            <div className="modal-info__2">
                                To regain access to the Aimmo, renew you license
                                or contact manager{' '}
                                <span className="modal-info__highlight">
                                    {mainAccountEmail}
                                </span>
                            </div>
                        </div>
                        <div className={clsx("modal__actions", "accExp-Warning-action-button")}>
                            <StdButton
                                className="accExp-Warning-action-back"
                                onClick={() => {
                                    dispatch(logout());
                                }}
                            >
                                {'Go Back'}
                            </StdButton>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default AccountExpiredWarning;
