import { takeLatest } from 'redux-saga/effects';

import {
    PROTOCOL_TRACKER_VIEW_PDF_REQUEST,
} from '../../actions';
import {
    protocolTrackerViewPdfSuccess,
    protocolTrackerViewPdfFail,
    protocolTrackerViewPdf
} from '../../action-creators';

import { simpleRequest } from '../modules/request';

export default takeLatest(
    PROTOCOL_TRACKER_VIEW_PDF_REQUEST,
    simpleRequest<ReturnType<typeof protocolTrackerViewPdf>>({
        success: protocolTrackerViewPdfSuccess,
        failure: protocolTrackerViewPdfFail,
        getUrl: (_) => `protocol_tracker/view_pdf/`,
        getBody: (action) => action.payload,
        method: 'POST',
    })
);
