import moment from 'moment';
import * as actions from '../actions';

import { getActionId } from '../storeModule';
import { mediaToUpload } from '../../modules/upload';

import {
    storeAdd,
    storeDelete,
    storeMergeEntities,
    storePostFail,
    storeReplaceId,
    storeUpdate
} from './reducerModule';

// Disable fallthrough rule to allow for better spacing
/* eslint-disable no-fallthrough */

function updateUploadAssociations(
    store: INormalizedState<IUpload>,
    ids: number[]
): INormalizedState<IUpload> {
    const changesHash: IHash<IUpload> = {};

    ids.map((id) => store.byIds[id])
        .filter((upload) => upload)
        .map((upload) => {
            if (upload.hasAssociations) return upload;
            return {
                ...upload,
                hasAssociations: true
            };
        })
        .forEach((upload) => {
            changesHash[upload.id] = upload;
        });

    return {
        ...store,
        byIds: {
            ...store.byIds,
            ...changesHash
        }
    };
}

function requestToUpload(media: IMedia, size: number): IUpload {
    return {
        ...mediaToUpload(media),
        timestamp: moment().unix(),
        hasAssociations: false,
        status: 'in-progress',
        progress: 0,
        entryCreated: false,
        isViewable: false,
        path: '',
        size
    };
}

function mergeCreatedToUpload(upload: IUpload, media: IMedia): IUpload {
    const newUpload = mediaToUpload(media);
    if (upload.status === 'uploaded') return upload;

    // Note: No nedd to revoke base64
    return {
        ...newUpload,
        hasAssociations: upload.hasAssociations,
        status: 'in-progress',
        progress: 0,
        entryCreated: true,
        isViewable: false,
        size: upload.size
    };
}

interface IUploadReducer {
    uploads: INormalizedState<IUpload>;
    uploadState: {
        isUploading: boolean;
    };
}

const initState: IUploadReducer = {
    uploads: {
        allIds: [], // Note: not needed?
        byIds: {},
        errors: {}
    },
    uploadState: {
        isUploading: false
    }
};

function uploadReducer(state = initState, action: AnyAction): IUploadReducer {
    switch (action.type) {
        case actions.CREATE_MEDIA_REQUEST: {
            // need to handle the case for betaMode, where the local previews are generated before the CREATE_MEDIA_REQUEST action
            const localId = getActionId(action);
            const {
                med_type,
                useBeta = 0,
                localId: betaLocalId = localId
            } = action.params;

            const media = {
                med_id: betaLocalId, // use the id of the local preview (beta feture), defaults to localId from action
                med_path: '', // TODO: handle empty paths better
                med_type: med_type || 'BI'
            };

            const newUpload = requestToUpload(media, action.file.size);

            // if (Boolean(useBeta)) { // donot do anything here if it is run during betaMode
            //     return {
            //         ...state
            //     }
            // }
            return {
                ...state,
                uploads: {
                    ...state.uploads,
                    ...storeAdd(state.uploads, betaLocalId, newUpload) // use the id of the local preview (beta feture), defaults to localId from action
                },
                uploadState: {
                    isUploading: true
                }
            };
        }
        case actions.CREATE_MEDIA_OBJECT: {
            // this should make a new empty object of media with localId
            const localId = getActionId(action);
            const { med_type } = action.params;

            const media = {
                med_id: localId,
                med_path: '', // TODO: handle empty paths better
                med_type: med_type || 'BI'
            };

            const newUpload = requestToUpload(media, action.file.size);
            return {
                ...state,
                uploads: {
                    ...state.uploads,
                    ...storeAdd(state.uploads, localId, newUpload)
                },
                uploadState: {
                    isUploading: true
                }
            };
        }
        case actions.CREATE_MEDIA_SUCCESS: {
            const localId = getActionId(action);
            const { media } = action.payload;

            const subjectMedia = state.uploads.byIds[localId];
            if (!subjectMedia) return state;

            const updatedUpload = mergeCreatedToUpload(subjectMedia, media);
            return {
                ...state,
                uploads: {
                    ...state.uploads,
                    ...storeReplaceId(
                        state.uploads,
                        localId,
                        updatedUpload.id,
                        updatedUpload
                    )
                },
                uploadState: {
                    isUploading: false
                }
            };
        }
        case actions.CREATE_MEDIA_FAIL: {
            return {
                ...state,
                uploads: {
                    ...state.uploads,
                    ...storePostFail(state.uploads, action)
                },
                uploadState: {
                    isUploading: false
                }
            };
        }
        // Note: due to issues with media handling,
        // second deletion on "success" is often needed to adjust unanajusted (at that point) backend payload
        case actions.DELETE_MEDIA_SUCCESS:
        case actions.DELETE_LOCAL_MEDIA:
        case actions.DELETE_MEDIA_REQUEST: {
            // Note: would be good to recover on error
            const id =
                action.id != null ? action.id : action.payload?.result?.pk;
            return {
                ...state,
                uploads: {
                    ...state.uploads,
                    ...storeDelete(state.uploads, id)
                }
            };
        }
        case actions.ON_MEDIA_UPLOAD_PROGRESS: {
            return {
                ...state,
                uploads: {
                    ...state.uploads,
                    ...storeUpdate(state.uploads, action.id, {
                        progress: action.payload
                    })
                },
                uploadState: {
                    isUploading: true
                }
            };
        }
        case actions.ON_MEDIA_UPLOAD_SUCCESS: {
            return {
                ...state,
                uploads: {
                    ...state.uploads,
                    ...storeUpdate(state.uploads, action.id, {
                        status: 'uploaded'
                    })
                },
                uploadState: {
                    isUploading: false
                }
            };
        }
        // Note: would good to add error handling
        case actions.RENAME_MEDIA_REQUEST: {
            const id = action.id;
            const oldUpload = state.uploads.byIds[id];

            if (!oldUpload) return state;

            const oldMedia = oldUpload.media;
            const updatedMedia: IMedia = {
                ...oldMedia,
                med_path: action.name
            };

            const updatedUpload = mediaToUpload(updatedMedia);
            return {
                ...state,
                uploads: {
                    ...state.uploads,
                    ...storeUpdate(state.uploads, id, updatedUpload)
                }
            };
        }
        case actions.SET_MEDIA_UPLOAD_PREVIEW: {
            return {
                ...state,
                uploads: {
                    ...state.uploads,
                    ...storeUpdate(state.uploads, action.id, {
                        path: action.preview,
                        isViewable: true
                    })
                }
            };
        }
        case actions.SET_MEDIA_UPLOAD_THUMBNAIL_PREVIEW: {
            return {
                ...state,
                uploads: {
                    ...state.uploads,
                    ...storeUpdate(state.uploads, action.id, {
                        thumbnail: action.thumbnail,
                        isViewable: true
                    })
                }
            };
        }
        case actions.POST_ATTENDEE_REQUEST:
        case actions.PATCH_ATTENDEE_REQUEST: {
            const { att_picture } = action.attendee;
            if (att_picture == null) return state;
            return {
                ...state,
                uploads: {
                    ...state.uploads,
                    ...updateUploadAssociations(state.uploads, [att_picture])
                }
            };
        }
        case actions.POST_PROTOCOL_ITEM_REQUEST:
        case actions.PATCH_PROTOCOL_ITEM_REQUEST:
        case actions.POST_ISSUE_REQUEST:
        case actions.POST_PROTOCOL_ISSUE_REQUEST: // protocol issue post
        case actions.PATCH_ISSUE_REQUEST:
        case actions.PATCH_PROTOCOL_ISSUE_REQUEST: // protocol issue patch
        case actions.POST_PROTOCOL_ELEMENT_REQUEST:
        case actions.PATCH_PROTOCOL_ELEMENT_REQUEST: {
            // TODO: consistent media action structure
            // Requires backned to updated payloads
            const ids =
                action.payload.media_med_id || action.payload.media_med_path;
            if (!ids) return state;

            return {
                ...state,
                uploads: {
                    ...state.uploads,
                    ...updateUploadAssociations(state.uploads, ids)
                }
            };
        }

        // update upload state when protoocl save
        case 'POST_PROTOCOL_ISSUE_REQUEST':
        case 'PATCH_PROTOCOL_ISSUE_REQUEST':
        case 'CREATE_MEDIA_SKIP':
        case 'UPLOAD_MEDIA_FUNCTION_SKIP':
            return {
                ...state,
                uploadState: {
                    isUploading: false
                }
            };

        // ---- Load in uploads from all over the place
        // dpds
        case actions.GET_DPD_SUCCESS:
        case actions.GET_ONE_DPD_SUCCESS:

        // misc
        case actions.GET_CURRENT_ACC_SUCCESS:
        case actions.GET_ROOM_SUCCESS:
        case actions.GET_ELEMENT_SUCCESS:
        case actions.GET_TENANT_SUCCESS:

        // issues
        case actions.GET_SHARED_ISSUE_SUCCESS:
        case actions.GET_ALL_ISSUE_SUCCESS:
        case actions.GET_ISSUES_SUCCESS:
        case actions.GET_PROTOCOL_ISSUES_SUCCESS:
        case actions.GET_STORED_PROTO_ISSUES_SUCCESS:
        case actions.GET_ISSUE_SUCCESS:
        case 'PATCH_ISSUE_RESPONSE':
        case 'PATCH_PROTOCOL_ISSUE_RESPONSE':
        case 'POST_ISSUE_RESPONSE':
        case 'POST_PROTOCOL_ISSUE_RESPONSE':

        // attendees
        case actions.GET_ATTENDEES_SUCCESS:
        case actions.GET_ATTENDEE_SUCCESS:
        case 'POST_ATTENDEE_RESPONSE':
        case 'PATCH_ATTENDEE_RESPONSE':

        // protocol rooms
        case actions.GET_PROTOCOL_ROOMS_SUCCESS:
        case actions.GET_PROTOCOL_ROOM_SUCCESS:
        case 'PATCH_PROTOCOL_ROOM_RESPONSE':
        case 'POST_PROTOCOL_ROOM_RESPONSE':

        // protocol elements
        case actions.GET_PROTOCOL_ELEMENT_SUCCESS:
        case 'PATCH_PROTOCOL_ELEMENT_RESPONSE':
        case 'POST_PROTOCOL_ELEMENT_RESPONSE':

        // protocol items
        case actions.GET_PROTOCOL_ITEMS_SUCCESS:
        case actions.GET_PROTOCOL_ITEM_SUCCESS:
        case 'POST_PROTOCOL_ITEM_RESPONSE':
        case 'PATCH_PROTOCOL_ITEM_RESPONSE':

        //protocol
        case actions.GET_PROTOCOL_SUCCESS:
        case actions.CHECK_PDF_READY_SUCCESS:

        // checkout data uploads saving
        case actions.GET_OFFLINE_PROTOCOL_CHECKOUT_ID_SUCCESS:

        // siganture
        // case actions.GET_SIGNATURES_SUCCESS:
        case 'POST_SIGNATURE_RESPONSE':
            return {
                ...state,
                uploads: {
                    ...state.uploads,
                    ...storeMergeEntities(state.uploads, action.payload.uploads)
                }
            };
    }
    return state;
}

export default uploadReducer;
