import {
    isHouseType,
    SUPPORTED_OBJECT_TEMPLATES,
    OBJECT_ATTRIBUTE_TYPE_HASH
} from '../configs/costants';

import { ONE_DPD } from '../configs/routes';

import { isInRange, justString, matchSimple } from '../utils';
import { iterateTimes } from './array';
import { backDateToYear } from '../utils/formatter';

export function isPropertyObject(property: IDpd) {
    // House type is both an objecta and a building at the same time
    // 'dpd_assoc_data' is used to check if an object belongs to a particular building
    return property.dpd_assoc_data || isHouseType(property.dpd_type || '');
}

export function objectHasTemplate(type?: string): boolean {
    return Boolean(type && SUPPORTED_OBJECT_TEMPLATES.includes(type));
}

// All of the icons are prefixed with 'fal' here
export const propertyToIcon = (type: string) => {
    return matchSimple()
        .on(type === 'H', 'house')
        .on(type === 'A', 'building')
        .on(type === 'W', 'building')
        .on(type === 'WGR', 'city')
        .on(type === 'GSH', 'warehouse-alt')
        .on(type === 'SBA', 'garage')
        .on(type === 'FH', 'garage')
        .otherwise('building');
};

export const objectToIcon = (type: string) => {
    return matchSimple()
        .on(type === 'Wo', 'house')
        .on(type === 'Ge', 'warehouse-alt')
        .on(type === 'SA', 'warehouse-alt')
        .on(type === 'All', 'th-large')
        .on(type === 'Ga', 'garage')
        .otherwise('house');
};

// Find object in an array of properties
export function findObject(propertyList: IDpd[], objectId: number) {
    const objectList: IDpd[] = [];

    for (const property of propertyList) {
        if (isPropertyObject(property)) {
            objectList.push(property);
        } else if (Array.isArray(property.dpd_dpd_id)) {
            objectList.push(...property.dpd_dpd_id);
        }
    }
    return objectList.find((object) => object.dpd_id === objectId);
}

export const parseStreetAddress = (streetData: string) => {
    const streetArr = streetData.split(' ');
    const street_num = streetArr.pop() || '';
    const street_name = streetArr.join(' ');

    return { street_name, street_num };
};

export function addressFormToString(address: IAddressForm) {
    const { street, zip, city, country } = address;

    return `${street}, ${zip} ${city}, ${country}`;
}

export function getPropertyLink(id: number, parentId?: number) {
    const buildingId = parentId != null ? parentId : id;
    const objectId = parentId != null ? id : undefined;

    return ONE_DPD(buildingId, objectId);
}

export function getBuildingLink(buildingId?: number) {
    return buildingId != null ? ONE_DPD(buildingId) : undefined;
}

function getPropertyAttribute(
    attrs: IDpdAttr[],
    key: string
): IDpdAttr | undefined {
    return attrs.find((attr) => attr.dpa_type === key);
}

export function getObjectAdAttribute(
    attributes: IDpdAttr[],
    name: IObjectAdAttribute
) {
    const key = OBJECT_ATTRIBUTE_TYPE_HASH[name];
    if (!key) return;

    return getPropertyAttribute(attributes, key);
}

function getFloorBase(hasBasement?: boolean): [number, number] {
    const baseLevel = +!hasBasement; // start counting floors from 0 if user specified to include basement
    const groundFloorIndex = +!!hasBasement; // index of the first floor

    return [baseLevel, groundFloorIndex];
}

export function getFloorParams(object: Partial<IDpd>) {
    const { dpd_basement_included, dpd_floor } = object;
    const floor = dpd_floor || 1;

    const [baseLevel, groundFloorIndex] = getFloorBase(dpd_basement_included);
    const count = floor + groundFloorIndex;

    return {
        baseLevel,
        count
    };
}

function roomFloors(count: number, base: number) {
    return iterateTimes(count).map((_, index) => base + index);
}

export function getRoomFloors(object: Partial<IDpd>) {
    const params = getFloorParams(object);

    return roomFloors(params.count, params.baseLevel);
}

// ground floor level
const GROUND_LEVEL = 1;

export function getFloorState(
    count: number,
    baseLevel: number,
    openLevel?: number
) {
    const floors = roomFloors(count, baseLevel);

    const openFloor =
        openLevel != null && isInRange(openLevel, baseLevel, count)
            ? openLevel
            : GROUND_LEVEL;

    const floorHash: IHash<boolean> = {};
    floors.forEach((floor) => {
        const open = floor === openFloor;

        floorHash[floor] = open;
    });
    return floorHash;
}

export function getObjectFloorState(object: Partial<IDpd>, openLevel?: number) {
    const params = getFloorParams(object);

    return getFloorState(params.count, params.baseLevel, openLevel);
}

function stringifyAddress(
    street: string,
    street_number: string,
    zip: string,
    city: string,
    country: string
) {
    return `${street} ${street_number}, ${zip} ${city}, ${country}`;
}

// Note: very similar. Dpd adresses should be first noved to IAddress table on the backend to merge these
export function propertyAddressToForm(building: IDpd): IAddressForm {
    const {
        dpd_street,
        dpd_street_number,
        dpd_zip,
        dpd_country,
        dpd_commune,
        dpd_region,
        dpd_city,
        dpd_geo_lat,
        dpd_geo_lng
    } = building;

    const address = stringifyAddress(
        dpd_street || '',
        dpd_street_number || '',
        dpd_zip || '',
        dpd_city || '',
        dpd_country || ''
    );
    return {
        address,
        street: dpd_street || '',
        street_number: dpd_street_number || '',
        city: dpd_city || '',
        zip: dpd_zip || '',
        country: dpd_country || '',
        address_extra: {
            commune: dpd_commune || '',
            region: dpd_region || '',
            country: dpd_country || '',
            lat: dpd_geo_lat ? parseFloat(dpd_geo_lat) : undefined,
            lng: dpd_geo_lng ? parseFloat(dpd_geo_lng) : undefined
        }
    };
}

export function addressEntryToForm(address: IAddress): IAddressForm {
    const { city, country, zip, street, street_number } = address;
    return {
        address: stringifyAddress(
            street || '',
            street_number || '',
            zip || '',
            city || '',
            country || ''
        ),
        street: street || '',
        street_number: street_number || '',
        city: city || '',
        zip: zip || '',
        country: country || '',
        address_extra: {
            commune: '',
            region: '',
            country: country || ''
        }
    };
}

export function propertyToForm(property: IDpd) {
    const { dpd_type, dpd_floor, dpd_egid, dpd_ewid, dpd_basement_included } =
        property;
    return {
        dpd_type: justString(dpd_type),
        type: justString(dpd_type), // FIMXE: form types
        floor: justString(dpd_floor, '1'),
        egid: justString(dpd_egid),
        ewid: justString(dpd_ewid),
        include_basement: Boolean(dpd_basement_included),
        use_template: false
    };
}

export function propertyToObjectForm(property: IDpd) {
    const propertyForm = propertyToForm(property);
    return {
        ...propertyForm,
        title: justString(property.dpd_title),
        description: justString(property.dpd_description)
    };
}
