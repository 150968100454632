import { intOrElse, matchSimple } from './';

import {
    MEDIA_DOCUMENT_TYPE,
    MEDIA_ATTACHMENT_TYPE
} from '../configs/costants';
import { safeByIds } from '../store/storeModule';
import { levelToFloor } from '../modules/rooms';
import { isoDateTimeToDateAlt } from './formatter';

import IssueModule from '../modules/issue';

export interface IIssueData {
    id: number;
    title: string;
    location: string;
    date: string;
    desc: string;
    media: IUpload[];
    mediaCount?: number;
    shared?: boolean;
    fixed?: boolean;
    excessive?: boolean;
}

export function trasnslateIssue(
    issue: IIssue,
    uploadState: INormalizedState<IUpload>
): IIssueData {
    const {
        piss_id,
        title,
        date_occurred,
        fix,
        description,
        sharing_link_public,
        uploads,
        media_med_path
    } = issue || {};
    const medias = uploads?.length ? uploads : media_med_path?.map(media => media.med_id);
    const room = issue?.piss_rom_id || issue?.iss_rom_id;
    const element = issue?.piss_ele_id || issue?.iss_ele_id;
    const item = issue?.piss_itm_id || issue?.iss_itm_id;

    const roomLevel = intOrElse(room?.rom_level, 1);
    const roomName = room?.rom_name ? `${room.rom_name} /` : '';

    const itemLocation = item?.itm_name || '';
    const elementLocation = `${levelToFloor(roomLevel)} / ${roomName} ${
        element?.ele_name || ''
    }`;

    const location = matchSimple<string>()
        .on(Boolean(item), itemLocation)
        .on(Boolean(element), elementLocation)
        .otherwise('---');

    const storedUploads = safeByIds(medias as number[], uploadState.byIds);
    const media =
        storedUploads?.filter((upload) => upload.type !== MEDIA_DOCUMENT_TYPE) ||
        issue?.media_med_path || [];

    const mediaCount = media?.length;
    return {
        id: Number(piss_id || issue?.iss_id),
        title: title || issue?.iss_title || '---',
        date: date_occurred || issue?.iss_date_occured ? isoDateTimeToDateAlt(String(date_occurred || issue?.iss_date_occured)) : '---',
        desc: description || issue?.iss_description || '---',
        shared: Boolean(sharing_link_public || issue?.sharing_link_public),
        fixed: Boolean(fix || issue?.iss_fix),
        excessive: IssueModule.isExcessive(issue),
        media,
        mediaCount,
        location
    };
}

export interface IDpdData {
    id: number;
    street: string;
    streetNum: string;
    zip: string;
    city: string;
    type?: string;
    objNum?: number;
    roomNum?: number;
    issNum?: number;
    newIssNum?: number;
    media?: IUpload;
}

export const dpdToData = (
    property: IDpd,
    uploadState: INormalizedState<IUpload>
): IDpdData => {
    const {
        dpd_id,
        dpd_street,
        dpd_street_number,
        dpd_zip,
        dpd_type,
        dpd_city,
        dpd_iss_count,
        dpd_new_iss_count,
        dpd_obj_count,
        dpd_rom_count,
        uploads
    } = property;

    const storedUploads = safeByIds(uploads as number[], uploadState.byIds);
    const picture = storedUploads.find(
        (upload) => upload.type !== MEDIA_ATTACHMENT_TYPE
    );

    return {
        id: dpd_id,
        street: dpd_street || '----',
        streetNum: dpd_street_number || '--',
        zip: dpd_zip || '--',
        city: dpd_city || '----',
        media: picture,
        issNum: dpd_iss_count,
        newIssNum: dpd_new_iss_count,
        objNum: dpd_obj_count,
        roomNum: dpd_rom_count,
        type: dpd_type
    };
};
