import React, {
    useState,
    useCallback,
    useEffect,
    Fragment,
    useMemo,
    memo
} from 'react';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';
import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'validator';
import { useHistory } from 'react-router';
import { Optional } from 'utility-types';

import { validate } from '../../../utils/validation'; 
import { standardiseIssueObject } from '../../../utils/standardiseIssueObject'; 

import {
    isHouseType,
    ISSUE_UPLOAD_CONFIG,
    ISSUE_TYPE_EXCESSIVE,
    ISSUE_TYPE_NORMAL,
    PER_PAGE,
    MEDIA_DOCUMENT_TYPE
} from '../../../configs/costants';

import { getPropertyLink, findObject } from '../../../modules/property';

import { dispatchAsync } from '../../../store/storeModule';
import {
    postIssue,
    getElement,
    patchIssue,
    showSnackbar,
    getRoom,
    getDpds
} from '../../../store/action-creators';

import { numericToString, justArray, intOrElse } from '../../../utils';

import { nativeBySet } from '../../../modules/array';
import { levelToFloor } from '../../../modules/rooms';

import usePending from '../../../hooks/usePending';
import useValidation from '../../../hooks/useValidation';
import useUploader from '../../../hooks/useUploader';
import useScrollToContent from '../../../hooks/useScrollToContent';

import { MenuItem } from '@rmwc/menu';
import { ListGroupSubheader, ListGroup, ListDivider } from '@rmwc/list';

import StdModal from '../../atoms/StdModal';
import StdButton from '../../atoms/StdButton';
import StdTextField from '../../atoms/StdTextField';
import useInput from '../../../hooks/useInput';
import StdSelect from '../../atoms/StdSelect';
import StdGallery from '../../molecules/StdGallery';
import StdRadio from '../../atoms/StdRadio';
import StdDatePicker from '../../atoms/StdDatePicker';
import StdDivider from '../../atoms/StdDivider';
import EditModalHeader from '../../atoms/EditModalHeader';
import i18n from '../../../i18next';
import ElementIssueHistory from '../../inventory/ElemenntIssueHistory';
import ObjectEditModal from '../../atoms/ObjectEditModal/ObjectEditModal';

function insertAtStart(
    propertyArray: IDpd[],
    issueObject: Optional<IDpd, 'uploads'>
): IDpd[] {
    return [
        { ...issueObject, uploads: [] },
        ...propertyArray.filter(
            (object) => object.dpd_id !== issueObject.dpd_id
        )
    ];
}

function findPropertyLink(properties: IDpd[], id: string) {
    const object = findObject(properties, parseInt(id));
    return object
        ? getPropertyLink(object.dpd_id, object.dpd_assoc_data?.dpd_id)
        : undefined;
}

// FIMXE edit for item issues
interface IProps extends IModalBase {
    defaultCategory?: 'light' | 'excessive';
    issue?: Partial<IIssue>;
    dpdLink?: string;
    selectObject?: boolean;
    omitSelection?: boolean;
    onSubmit?: ICallback;
}

interface IForm {
    temp: {
        element: string;
        room: string;
        room_level: string;
    };
    form: {
        dpd: string;
        title: string;
        floor: string;
        room: string;
        element: string;
        date: string; //ISO format
        description: string;
        status: 'light' | 'excessive';
    };
    errors: IErrorHash;
}

const initForm: IForm = {
    temp: {
        element: '',
        room: '',
        room_level: ''
    },
    form: {
        dpd: '',
        title: '',
        floor: '',
        room: '',
        element: '',
        date: moment().toISOString(),
        description: '',
        status: 'excessive'
    },
    errors: {}
};

const EditIssueModal: React.FC<IProps> = ({
    dpdLink,
    issue,
    open,
    selectObject,
    defaultCategory,
    omitSelection,
    onClose,
    onSubmit
}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const lang = i18n.language as ILang;

    const [input, setInput] = useState(initForm);

    const [titleRef, scrollToTitle] = useScrollToContent();
    const [locationRef, scrollToLocation] = useScrollToContent();

    const galleryUploader = useUploader();

    const roomsRes = useSelector((state) => state.inventory.rooms);
    const elementsRes = useSelector((state) => state.inventory.elements);

    // Dpd related results
    const dpdRes = useSelector((state) => state.dpds.issueModal);
    const dpdsNext = dpdRes.response.next;

    const dpdsArr = justArray(dpdRes.response.results);

    const issueObject = selectObject && issue?.iss_dpd_id;

    // Make sure that a currently selected dpd is shown first, and is ignored later, to avoid issues with pagination
    const selectProperties: IDpd[] = issueObject
        ? insertAtStart(dpdsArr, issueObject)
        : dpdsArr;

    const isObjectSelected = selectObject && Boolean(input.form.dpd);
    const messageLink = isObjectSelected
        ? findPropertyLink(dpdsArr, input.form.dpd)
        : dpdLink;

    const isRoomSelectable = !selectObject || isObjectSelected;
    const roomsArr = justArray(roomsRes.response.results);
    const rooms = useMemo(
        () => (isRoomSelectable ? roomsArr : []),
        [roomsArr, isRoomSelectable]
    );

    const elements = justArray(elementsRes.response.results);

    const dpdsLoading = usePending([
        { action: getDpds, fetchType: 'issue_select' },
        { action: getDpds, fetchType: 'all' }
    ]);
    const roomLoading = usePending([getRoom]);
    const eleLoading = usePending([getElement]);

    const hasNoElement = issue && !issue.iss_ele_id;
    const noSelection = omitSelection || hasNoElement;

    const showPropertySelect = selectObject && !noSelection;
    const showElementSelect = !selectObject && !noSelection;

    const onDpdSelect = (dpdId: number) => {
        dispatch(getRoom(dpdId, lang));
    };

    const onRoomSelect = (roomId: number) => {
        dispatch(getElement(roomId, lang as ILang));
    };

    const onLoadOptions = () => {
        if (dpdsNext && !dpdsLoading) {
            dispatch(
                getDpds(
                    'issue_select',
                    {
                        limit: PER_PAGE,
                        offset: PER_PAGE * dpdRes.paginate.page,
                        lang
                    },
                    'forwards'
                )
            );
        }
    };

    const handleInput = useInput(setInput, (name, value, inputState) => {
        if (noSelection) return;

        if (name === 'room') {
            onRoomSelect(parseInt(value));
            return {
                ...inputState,
                form: {
                    ...inputState.form,
                    element: ''
                }
            };
        }

        if (name === 'floor') {
            return {
                ...inputState,
                form: {
                    ...inputState.form,
                    room: '',
                    element: ''
                }
            };
        }

        if (name === 'dpd') {
            selectObject && onDpdSelect(parseInt(value));
            return {
                ...inputState,
                form: {
                    ...inputState.form,
                    room: '',
                    element: ''
                }
            };
        }
    });

    const resetInput = useCallback(() => { 
        if (!open) return;

        // TODO: Currently this component tries to accomodate way too many use casess.
        // needs a refactoring by separating out the UI and data fetching functionality
        if (issue) {
            const {
                iss_title = '',
                iss_description = '',
                iss_grade = '',
                piss_rom_id,
                iss_rom_id,
                piss_ele_id,
                iss_ele_id,
                iss_date_occured,
                iss_dpd_id,
                uploads: issueUploads = [],
                media_med_path = []
            } = issue;

            const issueMedias = media_med_path?.map((media) => media.med_id) || [];
            const uploads = issueUploads.length > 0 ? issueUploads : issueMedias;
            const { rom_id, rom_level } = piss_rom_id as IRoom || iss_rom_id as IRoom || {};
            const { ele_id } = piss_ele_id || iss_ele_id || {};
            const { dpd_id } = iss_dpd_id || {};

            if (!noSelection) {
                selectObject &&
                    dpd_id != null &&
                    dispatch(getRoom(dpd_id, lang));
                rom_id != null && dispatch(getElement(rom_id, lang as ILang));
            }

            setInput((s) => ({
                ...s,
                temp: {
                    element: numericToString(ele_id),
                    room: numericToString(rom_id),
                    room_level: numericToString(rom_level)
                },
                form: {
                    dpd: numericToString(dpd_id),
                    title: iss_title,
                    description: iss_description,
                    floor: numericToString(!selectObject && rom_level),
                    room: numericToString(!selectObject && rom_id),
                    element: numericToString(noSelection && ele_id),
                    status:
                        iss_grade === ISSUE_TYPE_EXCESSIVE
                            ? 'excessive'
                            : 'light',
                    date: iss_date_occured
                        ? iss_date_occured
                        : moment().toISOString()
                }
            }));

            galleryUploader.setPartialUploads(
                uploads as number[],
                (upload) => upload.type !== MEDIA_DOCUMENT_TYPE
            );
        } else {
            setInput((s) => ({
                ...s,
                form: {
                    ...s.form,
                    status: defaultCategory || 'light'
                }
            }));
        }
        // TODO: simplify dependencies list, as we only care about 'open' variable
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        issue,
        open,
        dispatch,
        selectObject,
        noSelection,
        defaultCategory,
        lang
    ]);

    useEffect(() => {
        resetInput();
    }, [resetInput]);

    // Wait until the elements and rooms are actually loaded before trying to display them
    const hasRooms = Boolean(rooms.length);
    useEffect(() => {
        if (!selectObject || !hasRooms) return;

        setInput((s) => ({
            ...s,
            form: {
                ...s.form,
                room: s.temp.room,
                floor: s.temp.room_level
            }
        }));
    }, [hasRooms, selectObject]);

    useEffect(() => {
        if (elements.length) {
            setInput((s) => ({
                ...s,
                form: {
                    ...s.form,
                    element: s.temp.element
                }
            }));
        }
    }, [elements]);

    const validateFields = useValidation(input, setInput, (hash) => {
        const { title, floor, room, date, element, dpd } = input.form;

        const isTitleValid = validate({ title }, !isEmpty(title), hash);

        const isSelectValid =
            noSelection ||
            [
                !selectObject || validate({ dpd }, !isEmpty(dpd), hash),
                validate({ floor }, !isEmpty(floor), hash),
                validate({ room }, !isEmpty(room), hash),
                validate({ element }, !isEmpty(element), hash)
            ].every(Boolean);

        const isValid = [
            isTitleValid,
            isSelectValid,
            validate({ date }, !isEmpty(date), hash)
        ].every(Boolean);

        if (!isTitleValid) {
            scrollToTitle();
        } else if (!isSelectValid) {
            scrollToLocation();
        }
        return isValid;
    });

    const makeRequest = () => {
        const { title, description, room, element, status, date } = input.form;
        const issueLiteral = {
            iss_title: title,
            iss_description: description,
            iss_rom_id: room ? parseInt(room) : undefined,
            iss_ele_id: room ? parseInt(element) : undefined,
            iss_grade:
                status === 'light' ? ISSUE_TYPE_NORMAL : ISSUE_TYPE_EXCESSIVE,
            iss_fix: false,
            iss_date_occured: date,
            media_med_path: galleryUploader.ids
        };
        const issId = issue?.iss_id;

        const modAction =
            issId != null
                ? patchIssue(issId, issueLiteral, lang)
                : postIssue(issueLiteral);

        dispatchAsync(dispatch, modAction)
            .then(galleryUploader.submitChanges)
            .then(onSubmit)
            .catch(({ error }) =>
                dispatch(showSnackbar({ message: 'create_issue_fail', error }))
            );
        onClose();
    };

    const cleanup = () => {
        setInput(initForm);
    };

    const handleSubmit = () => {
        validateFields() && makeRequest();
    };

    const onModalClose = () => {
        galleryUploader.cancelChanges();
        onClose();
    };

    const { form, errors } = input;

    const byFloor = useMemo(
        () =>
            nativeBySet(rooms, ({ rom_level }) => {
                if (rom_level == null) return { rom_level: 1 };
                return { rom_level };
            }),
        [rooms]
    );

    const roomOpts = form.floor
        ? rooms.filter(
              (room) => intOrElse(room.rom_level, 1) === parseInt(form.floor)
          )
        : [];
    const elementOpts = form.room ? elements : [];

    const roomsDisabled = roomLoading || !rooms.length;
    const eleDisabled = eleLoading || !form.room || !elementOpts.length;

    const elesEmpty = !eleLoading && Boolean(form.room) && !elementOpts.length;
    const roomsEmpty = !roomLoading && !rooms.length;

    const showLink = messageLink && (roomsEmpty || elesEmpty);

    const helpLink = `${messageLink}${
        roomsDisabled ? '?tab=rooms' : `?room=${form.room}`
    }`;

    const onLinkClick = () => {
        history.push(helpLink);
        onClose();
    };

    // Depending on 'selectObject' this component could be in two different places
    const elementSelectNode = (
        <div>
            <div className={clsx('form__line', 'stack-m')}>
                <StdSelect
                    className="inline-s"
                    label={t('floor')}
                    name="floor"
                    value={form.floor}
                    error={errors['floor']}
                    onChange={handleInput}
                    width="30%"
                    disabled={roomsDisabled}
                    dense
                    required
                >
                    {byFloor
                        .map((item) => {
                            const {
                                key: { rom_level }
                            } = item;
                            return (
                                <MenuItem
                                    key={rom_level}
                                    data-value={rom_level}
                                >
                                    {levelToFloor(rom_level)}
                                </MenuItem>
                            );
                        })
                        .reverse()}
                </StdSelect>
                <StdSelect
                    label={t('room')}
                    name="room"
                    value={form.room}
                    error={errors['room']}
                    onChange={handleInput}
                    width="70%"
                    disabled={roomsDisabled || !form.floor}
                    dense
                    required
                >
                    {roomOpts.map(({ rom_id, rom_name }) => {
                        return (
                            <MenuItem key={rom_id} data-value={rom_id}>
                                {rom_name || '---'}
                            </MenuItem>
                        );
                    })}
                </StdSelect>
            </div>
            <div className={clsx(showLink && 'stack-m')}>
                <StdSelect
                    label={t('element')}
                    name="element"
                    value={form.element}
                    error={errors['element']}
                    onChange={handleInput}
                    width="100%"
                    disabled={eleDisabled}
                    dense
                    required
                >
                    {elementOpts.map(({ ele_id, ele_name }) => {
                        return (
                            <MenuItem key={ele_id} data-value={ele_id}>
                                {ele_name || '---'}
                            </MenuItem>
                        );
                    })}
                </StdSelect>
            </div>
            {showLink && (
                <div className="form__start">
                    <StdButton
                        className="inline-m util__inflexible"
                        to={selectObject ? helpLink : undefined}
                        onClick={!selectObject ? onLinkClick : undefined}
                    >
                        {roomsDisabled ? t('add_room') : t('add_element')}
                    </StdButton>
                    <div className="font--small">
                        {roomsDisabled ? t('no_room_yet') : t('no_element_yet')}
                        , {t('issue_requirements')}
                    </div>
                </div>
            )}
        </div>
    );

    const propertySelectNode = showPropertySelect && (
        <>
            <div className="modal__section">
                <div className="modal__heading stack-m" ref={locationRef}>
                    {t('location').toUpperCase()}
                </div>
                <div className="form__line stack-m">
                    <StdSelect
                        label={t('property')}
                        name="dpd"
                        value={form.dpd}
                        error={errors['dpd']}
                        onChange={handleInput}
                        onScrollEnd={onLoadOptions}
                        width="100%"
                        disabled={Boolean(issue)}
                        dense
                        required
                    >
                        {selectProperties.map(
                            ({
                                dpd_id,
                                dpd_dpd_id,
                                dpd_street,
                                dpd_street_number,
                                dpd_type = ''
                            }) => {
                                const label = `${dpd_street || '---'} ${
                                    dpd_street_number || '--'
                                }`;
                                const hasSubdpds =
                                    !isHouseType(dpd_type) &&
                                    Array.isArray(dpd_dpd_id);

                                const options =
                                    hasSubdpds &&
                                    dpd_dpd_id?.map(
                                        ({ dpd_id, dpd_title }) => ({
                                            label: dpd_title || t('no_title'),
                                            value: dpd_id
                                        })
                                    );
                                return (
                                    <Fragment key={dpd_id}>
                                        {options ? (
                                            <ListGroup>
                                                <ListDivider />
                                                <ListGroupSubheader>
                                                    <span className="font--secondary">
                                                        {label}
                                                    </span>
                                                </ListGroupSubheader>
                                                {options.map(
                                                    ({ label, value }) => {
                                                        return (
                                                            <MenuItem
                                                                key={value}
                                                                data-value={
                                                                    value
                                                                }
                                                            >
                                                                {label}
                                                            </MenuItem>
                                                        );
                                                    }
                                                )}
                                                <ListDivider />
                                            </ListGroup>
                                        ) : (
                                            <MenuItem data-value={dpd_id}>
                                                {label}
                                            </MenuItem>
                                        )}
                                    </Fragment>
                                );
                            }
                        )}
                    </StdSelect>
                </div>
                <div>{elementSelectNode}</div>
            </div>
            <StdDivider />
        </>
    );

    const modalTitle = issue ? t('edit_issue') : t('add_issue');
    const eleId = Number(input.form.element);
    return (
        <StdModal open={open} onClose={onClose} onExited={cleanup}>
            <ObjectEditModal
                title={modalTitle}
                onClose={onModalClose}
            />
            <div className="modal__section" ref={titleRef}>
                <div className="modal__heading stack-m">
                    {t('title').toUpperCase()}
                </div>
                <StdTextField
                    className="stack-s modal__section_inputHeight"
                    name="title"
                    value={form.title}
                    error={errors['title']}
                    onChange={handleInput}
                    width="100%"
                    placeholder={t('enter_issue_title')}
                    required
                />
            </div>
            <StdDivider />
            {propertySelectNode}
            <div className="modal__section">
                <div className="modal__heading stack-l">
                    {t('photos_and_videos').toUpperCase()}
                </div>
                <StdGallery
                    {...galleryUploader}
                    uploadConfig={ISSUE_UPLOAD_CONFIG}
                    className="stack-s"
                    alt="Issue description"
                    icon="photo-video"
                    noTitle
                    isMedia
                />
            </div>
            <StdDivider />
            <div className="modal__section">
                <div className="modal__heading stack-l">
                    {t('details').toUpperCase()}
                </div>
                <div className="stack-l">
                    <div className="font--small font--secondary stack-s">
                        {t('status')}
                    </div>
                    <div className='modal__section--ButtonGrp'>
                        <div className="form__line-action">
                            <StdRadio
                                className="inline-xs"
                                value="excessive"
                                name="status"
                                onChange={handleInput}
                                checked={form.status === 'excessive'}
                            />
                            <span>{t('excessive_wear')}</span>
                        </div>
                        <div className="form__line-action">
                            <StdRadio
                                className="inline-xs"
                                value="light"
                                name="status"
                                onChange={handleInput}
                                checked={form.status === 'light'}
                            />
                            <span>{t('light_wear')}</span>
                        </div>
                    </div>
                </div>
                <div className="stack-l">
                    <StdDatePicker
                        variant="inline"
                        label={t('reported_on')}
                        name="date"
                        value={form.date}
                        error={errors['date']}
                        onChange={(date) =>
                            setInput((s) => ({
                                ...s,
                                form: {
                                    ...s.form,
                                    date: date ? date.toISOString() : ''
                                }
                            }))
                        }
                        fullWidth
                        disableFuture
                        inputVariant="outlined"
                        required
                    />
                </div>
                {showElementSelect && (
                    <div className="stack-l" ref={locationRef}>
                        {elementSelectNode}
                    </div>
                )}
                <StdTextField
                    label={t('description')}
                    name="description"
                    value={form.description}
                    error={errors['description']}
                    onChange={handleInput}
                    multiline
                    rows={2}
                    width="100%"
                />
            </div>
            <div className="modal__section--ButtonGrp modal__section">
                <div>
                    <StdButton
                        className='modal__section--ButtonGrp--issueBtn'
                        onClick={onClose}
                    >
                        {t("cancel")}
                    </StdButton>
                </div>
                <div>
                    <StdButton
                        className='modal__section--ButtonGrp--issueBtn'
                        type="primary"
                        onClick={handleSubmit}
                    >
                        {t('save')}
                    </StdButton>

                </div>
            </div>
            {Boolean(eleId) && (
                <ElementIssueHistory eleId={eleId} />
            )}
        </StdModal>
    );
};

export default memo(
    EditIssueModal,
    (prevProps, nextProps) => prevProps.open === nextProps.open
);
