import { takeLatest, call } from 'redux-saga/effects';
import { normalize } from 'normalizr';

import { protocolRoomSchema } from '../../reducers/schema';
import { GET_PROTOCOL_ROOMS_REQUEST } from '../../actions';
import {
    protoGetRooms,
    protoGetRoomsSuccess,
    protoGetRoomsFail
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

import { fetchFailError } from '../../../modules/errorHandlers';
import { justArray } from '../../../utils';

export interface IGetProtocolRoomsResult {
    roomsHash: IHash<IProtocolRoom>;
    roomsIds: number[];
    elementsHash: IHash<IProtocolElement>;
    uploads: IHash<IUpload>;
}

export default takeLatest(
    GET_PROTOCOL_ROOMS_REQUEST,
    simpleRequest<RT<typeof protoGetRooms>>({
        success: protoGetRoomsSuccess,
        failure: protoGetRoomsFail,
        getUrl: (action) =>
            `protocol/${action.protoId}/protocol_rooms/?lang=${action.lang}`,
        onSuccess: function (data): IGetProtocolRoomsResult {
            const normalizedResult = normalize(justArray(data), [
                protocolRoomSchema
            ]);
            const { result, entities } = normalizedResult;
            const {
                protocol_rooms = {},
                protocol_elements = {},
                uploads = {}
            } = entities;

            return {
                elementsHash: protocol_elements,
                roomsHash: protocol_rooms,
                roomsIds: result,
                uploads
            };
        },
        onFail: function* (error: INetworkError) {
            yield call(fetchFailError, 'fetch_rooms_error', error);
        }
    })
);
