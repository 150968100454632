import { useSelector } from 'react-redux';

import { getCurrentAcc } from '../store/action-creators';
import usePending from './usePending';

const useTrialInfo = () => {
    const account: IUser | null = useSelector(
        (state) => state.user.user.response
    );
    const accLoading = usePending([getCurrentAcc]);

    const { is_trial, days_left } = account?.trial || {};

    //  days_left == null => no need for subscription
    //  days_left === 0 => subscription expired
    //  is_trial => is trial subscription active?
    const isCancelled = !accLoading && Boolean(account?.is_canceled);
    const inTrial = !accLoading && Boolean(is_trial);
    const isExpired = !accLoading && Boolean(days_left === 0);

    const isPartner = !accLoading && Boolean(account?.from_partner);

    const daysLeft = days_left;
    const hasSubscription = Boolean(account?.subscription?.active);

    return {
        hasSubscription,
        daysLeft,
        isCancelled,
        inTrial,
        isExpired,
        isPartner
    };
};

export default useTrialInfo;
