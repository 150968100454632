// Helper to handle basicData table usage to lookup type names
export function lookupType(
    category: string,
    type?: string,
    hash?: ILookupHash | null,
    placeholder = '//'
) {
    if (type == null) return placeholder;
    if (hash == null || hash[category] == null) return type;

    return hash[category][type] || type || placeholder;
}

export function subtypeToGeneric<T>(
    attributes: T[],
    id: keyof T,
    type: keyof T,
    value: keyof T
): IAttribute[] {
    // Unfortunetly it does not seem to be possible to express type "key of T that has value of type string/number"
    // so casting to any is used as a workaround
    return attributes.map((attr: any) => {
        return {
            id: attr[id],
            type: attr[type],
            value: attr[value]
        };
    });
}
