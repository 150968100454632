import React, {useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import Loading from '../../components/atoms/Loading';
import { DASHBOARD_ROUTE } from '../../configs/routes';
import { protocolTrackerViewPdf } from '../../store/action-creators';
import { dispatchAsync } from '../../store/storeModule';

const ProtocolTrackerPdfPage = () => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const token = location.search.split('token=').pop();

    const handleTrackingAndRedirect = (userToken: string) => {
        dispatchAsync(
            dispatch,
            protocolTrackerViewPdf({token: userToken})
        )
        .then((res: any) => {
            const {link} = res.payload;
            if(link) {
                window.location.assign(link)
            } else {
                history.push(DASHBOARD_ROUTE)
            }
        })
        .catch((error) => {
            console.error(error);
            history.push(DASHBOARD_ROUTE)
        })
    }

    useEffect(() => {
        if(!token) {
            history.push(DASHBOARD_ROUTE)
        } else {
            handleTrackingAndRedirect(token)
        }
    }, [])

    return (
        <>
            <Loading className="loading__page" size="xlarge" />;
        </>
    );
};

export default ProtocolTrackerPdfPage;
