import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { PROTOCOL_ROUTE } from '../../../configs/routes';

import StdButton from '../../atoms/StdButton';
import MessageOverlay from '../../molecules/MessageOverlay';

import './open-protocol-warning-overlay.scss';

const OpenProtocolWarningOverlay: React.FC = () => {
    const { t } = useTranslation();
    const useBeta = useSelector((state) => state.user.user.response?.use_beta);
    // const isAsync = useSelector(state => state.offlineProtocol.protocolState.is_async);
    const isManualOffline = useSelector(
        (state) => state.appState.isManualOffline
    );
    const protocolId = useSelector(
        (state) => state.offlineProtocol.protocol.pk
    );
    const protocolCheckoutStatus = useSelector(
        (state) => state.offlineProtocol.protocol?.checkout_data
    );
    const protocolIsCompleted = useSelector(
        (state) => state.offlineProtocol.protocolState.is_completed
    );
    const protocolIsBeingEdited = useSelector(
        (state) => state.offlineProtocol.protocolState.is_user_editing
    );
    const isOffline = useSelector((state) => state.global.isOffline);

    const isOutsideProtocol = !window.location.pathname.includes('protocol');

    // const protocolModeIsOffline = isOffline || isManualOffline;
    const protocolShouldNotExit =
        isOutsideProtocol &&
        protocolId &&
        Boolean(
            !protocolIsCompleted &&
                protocolIsBeingEdited &&
                protocolCheckoutStatus &&
                protocolCheckoutStatus?.status === 'ACTIVE'
        );
    const openOverlay = Boolean(useBeta && protocolShouldNotExit);

    return (
        <MessageOverlay
            open={false} // not showing warning overlay temporarily
            message={t('async.offline_protocol_text')}
            action={
                <div className="openProtocol-overlay__action">
                    <StdButton
                        attr-intercom="offline-btn"
                        type="primary"
                        to={PROTOCOL_ROUTE(protocolId)}
                    >
                        {t('async.offline_protocol_button_text')}
                    </StdButton>
                </div>
            }
        />
    );
};

export default OpenProtocolWarningOverlay;
