import React, { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { DASHBOARD_ROUTE } from '../../../configs/routes';
import { matchSimple } from '../../../utils';
import useMatchMedia from '../../../hooks/useMatchMedia';

import { MenuItem, MenuSurfaceAnchor } from '@rmwc/menu';
import { Link } from 'react-router-dom';

import UserActions from '../../profile/UserActions';
import StdDropdown from '../../atoms/StdDropdown';
import StdIcon from '../../atoms/StdIcon';
import StdLink from '../../atoms/StdLink';

interface IProps {
    paths?: {
        link: string;
        title: string;
    }[];
    title: string;
}

const Header: React.FC<IProps> = ({ title, paths }) => {
    const { t } = useTranslation();
    const [pathsOpen, setPathsOpen] = useState(false);

    const isPhone = useMatchMedia('mobile');

    const pathNode: React.ReactNode = matchSimple()
        .on(
            !isPhone,
            <Fragment>
                <StdLink to={DASHBOARD_ROUTE}>{t('dashboard.title')}</StdLink>
                <span className="header__slash">/</span>
                {paths?.map(({ link, title }) => {
                    return (
                        <Fragment key={link}>
                            <StdLink to={link}>{title}</StdLink>
                            <span className="header__slash">/</span>
                        </Fragment>
                    );
                })}
            </Fragment>
        )
        .otherwise(
            <Fragment>
                <MenuSurfaceAnchor>
                    <StdDropdown
                        open={pathsOpen}
                        onClose={() => setPathsOpen(false)}
                    >
                        <Link className="std-blank-link" to={DASHBOARD_ROUTE}>
                            <MenuItem>{t('dashboard.title')}</MenuItem>
                        </Link>
                        {paths?.map(({ link, title }) => {
                            return (
                                <Link
                                    key={link}
                                    className="std-blank-link"
                                    to={link}
                                >
                                    <MenuItem>{title}</MenuItem>
                                </Link>
                            );
                        })}
                    </StdDropdown>
                    <StdIcon
                        prefix="far"
                        name="ellipsis-v"
                        onClick={() => setPathsOpen(true)}
                        clickable
                    />
                </MenuSurfaceAnchor>
                <span className="header__slash">/</span>
            </Fragment>
        );

    return (
        <div className="header">
            <div className="header__location inline-m">
                {pathNode}
                <span className="header__title">{title}</span>
            </div>
            <UserActions />
        </div>
    );
};

export default Header;
