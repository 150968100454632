import { takeEvery, call } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import queryString from 'query-string';

import { GET_TENANT_REQUEST } from '../../actions';
import {
    getTenantSuccess,
    getTenantFail,
    getTenant
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

import { fetchFailError } from '../../../modules/errorHandlers';
import { justArray } from '../../../utils';
import { tenantSchema } from '../../reducers/schema';

export default takeEvery(
    GET_TENANT_REQUEST,
    simpleRequest<RT<typeof getTenant>>({
        success: getTenantSuccess,
        failure: getTenantFail,
        getUrl: (action) =>
            `dpd/${action.dpdId}/tenants/?${queryString.stringify(
                action.params
            )}`,
        onFail: function* (error: INetworkError) {
            yield call(fetchFailError, 'fetch_tenants_error', error);
        },
        onSuccess: function (data) {
            const { result, entities } = normalize(justArray(data.results), [
                tenantSchema
            ]);
            const { uploads = {}, tenants = {} } = entities;

            return {
                response: {
                    ...data,
                    results: result.map((id: any) => tenants[id])
                },
                uploads
            };
        }
    })
);
