// Unused for now
interface IReducer {
    status: IHash<boolean>;
}

const initState: IReducer = {
    status: {}
};

function fetchStatusReducer(state = initState, _: AnyAction) {
    return state;
}

export default fetchStatusReducer;
