import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { Radio } from '@rmwc/radio';

interface IProps extends IClass {
    name?: string;
    value?: string;
    checked?: boolean;
    trim?: boolean;
    children?: ReactNode;
    isLabelBold?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
const StdRadio: React.FC<IProps> = ({
    name = '',
    value = '',
    checked,
    className,
    trim,
    children,
    isLabelBold,
    onChange
}) => {
    return (
        <Radio
            className={clsx(className, trim && 'mdc-radio--trim')}
            value={value}
            checked={checked}
            // NOTE: Simulate input having a name and a value
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const { target, currentTarget } = e;
                onChange?.({
                    ...e,
                    target: { ...target, name, value },
                    currentTarget: { ...currentTarget, name, value }
                });
            }}
        >
            <span className={`font__body ${isLabelBold ? 'boldTitleText' : null}`}>{children}</span>
        </Radio>
    );
};

export default StdRadio;
