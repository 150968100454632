import React, { ChangeEvent, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { getAttrHashKeys, getAttrKey } from '../../utils/attributes';

import { MenuItem } from '@rmwc/menu';
import { ListDivider } from '@rmwc/list';

import AttributeSelector from '../molecules/AttributeSelector';
import StdIcon from '../atoms/StdIcon';
import StdButton from '../atoms/StdButton';

interface IProps {
    attrData?: IHash<string> | null;
    attrState: {
        attrIds: IAttrId[];
        hashForm: IHash<string>;
        hashErrors: IErrorHash;
    };
    localAdd: ICallback;
    localDelete: (attrId: IAttrId) => void;
    handleHashInput: (
        event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => void;
}

const EditAttributeList: React.FC<IProps> = ({
    attrData,
    attrState,
    localAdd,
    handleHashInput,
    localDelete
}) => {
    const { t } = useTranslation();
    const { attrIds, hashForm, hashErrors } = attrState;

    const keys = Object.keys(attrData || {});

    const attrTypesNode = (
        <Fragment>
            {keys.map((key, index) => {
                return (
                    <MenuItem key={index} data-value={key}>
                        {attrData?.[key]}
                    </MenuItem>
                );
            })}
            {Boolean(keys.length) && <ListDivider />}
            <MenuItem onClick={(e) => e.stopPropagation()}>
                <StdButton attr-intercom="attribute-add">
                    {t('attributes.request_attribute')}
                </StdButton>
            </MenuItem>
        </Fragment>
    );

    return (
        <Fragment>
            {Boolean(attrIds.length) && (
                <div>
                    {attrIds.map((attrId) => {
                        const { typeKey, valueKey } = getAttrHashKeys(attrId);

                        const attrType = hashForm[typeKey];
                        const attrValue = hashForm[valueKey];

                        return (
                            <div
                                key={getAttrKey(attrId)}
                                className={clsx('form__line-action', 'stack-m')}
                            >
                                <AttributeSelector
                                    className="inline-xs"
                                    selectProps={{
                                        label: t('attribute_type'),
                                        name: typeKey,
                                        value: attrType,
                                        error: hashErrors[typeKey],
                                        children: attrTypesNode,
                                        onChange: handleHashInput
                                    }}
                                    textfieldProps={{
                                        label: t('attribute_value'),
                                        name: valueKey,
                                        value: attrValue,
                                        error: hashErrors[valueKey],
                                        onChange: handleHashInput
                                    }}
                                    width="100%"
                                />
                                <StdIcon
                                    name="trash"
                                    onClick={() => localDelete(attrId)}
                                    clickable
                                />
                            </div>
                        );
                    })}
                </div>
            )}
            <div>
                <StdButton leadingIcon={{ name: 'plus' }} onClick={localAdd}>
                    {t('new_attribute_label')}
                </StdButton>
            </div>
        </Fragment>
    );
};

export default EditAttributeList;
