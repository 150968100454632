import React from 'react';
import {
    CircularProgress,
    CircularProgressProps
} from '@rmwc/circular-progress';

interface IProps extends IClass {
    size?: CircularProgressProps['size'];
}

const Loading: React.FC<IProps> = ({ className, size }) => {
    return (
        <div className={className}>
            <CircularProgress size={size} />
        </div>
    );
};

export default Loading;
