import { useState, useEffect } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
interface Size {
    width: number | undefined;
    height: number | undefined;
}

interface ReturnValues extends Size {
    isMobile: boolean;
    isTablet: boolean;
}

export default function useWindowSize(): ReturnValues {
    const [windowSize, setWindowSize] = useState<Size>({
        width: undefined,
        height: undefined
    });

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
        // eslint-disable-next-line
    }, []);
    return { ...windowSize, isMobile, isTablet };
}
