import { takeEvery } from 'redux-saga/effects';
import { GET_BILLING_REQUEST } from '../../actions';
import {
    getBilling,
    getBillingSuccess,
    getBillingFail
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeEvery(
    GET_BILLING_REQUEST,
    simpleRequest<ReturnType<typeof getBilling>>({
        success: getBillingSuccess,
        failure: getBillingFail,
        getUrl: (_) => 'user/me/billing_address/',
        isV2Api: true
    })
);
