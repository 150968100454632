import React from 'react';
import clsx from 'clsx';

import CustomIcon from '../../atoms/CustomIcon';
import './search-input.scss';

export interface IProps extends IClass {
    value: string;
    onChange: IInputEventHandler;
    placeholder?: string;
    width?: string;
    mobile?: boolean;
}

const SearchInput: React.FC<IProps> = ({
    className,
    value,
    onChange,
    placeholder,
    width,
    mobile
}) => {
    const containerClass = clsx(
        'search-input__container',
        mobile && 'search-input__container--mobile',
        className
    );

    const inputClass = clsx(
        'search-input__input',
        mobile && 'search-input__input--mobile'
    );

    const iconClass = clsx(
        'search-input__icon',
        mobile && 'search-input__icon--mobile'
    );

    return (
        <div style={{ width }} className={containerClass}>
            <CustomIcon className={iconClass} name="search" />
            <input
                className={inputClass}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
            />
        </div>
    );
};

export default SearchInput;
