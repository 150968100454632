import React, { ReactNode } from 'react';
import StdIcon from '../StdIcon';
import clsx from 'clsx';
import { motion, AnimatePresence } from 'framer-motion';
import { ANIMATE_HEIGHT_PROPS } from '../../../configs/costants';

import './std-note.scss';

interface IProps extends IClass {
    open: boolean;
    children?: ReactNode;
    width?: string;
    centered?: boolean;
    success?: boolean;
    info?: boolean;
    slim?: boolean;
    danger?: boolean;
    onClose?: ICallback;
}

const StdNote: React.FC<IProps> = ({
    className,
    open,
    children,
    width,
    centered,
    success,
    slim,
    info,
    danger,
    onClose
}) => {
    const noteClasses = clsx(
        'std-note',
        centered && 'std-note--centered',
        success && 'std-note--success',
        info && 'std-note--info',
        slim && 'std-note--slim',
        danger && 'std-note--danger',
        className
    );

    return (
        <AnimatePresence initial={false}>
            {open && (
                <motion.div
                    className="util__inflexible"
                    {...ANIMATE_HEIGHT_PROPS}
                >
                    <div className={noteClasses} style={{ width }}>
                        <span className="inline-l">{children}</span>
                        {Boolean(onClose) && (
                            <StdIcon
                                name="times-circle"
                                prefix="fas"
                                clickable
                                onClick={onClose}
                            />
                        )}
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default StdNote;
