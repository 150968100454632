import { takeLatest } from 'redux-saga/effects';
import { normalize } from 'normalizr';

import { GET_PROTOCOL_ISSUES_REQUEST } from '../../actions';
import {
    getProtocolIssues,
    getProtocolIssuesSuccess,
    getProtocolIssueFail
} from '../../action-creators';
import { simpleRequest, ISuccessCallback } from '../modules/request';

import { issueSchema } from '../../reducers/schema';

type IGetProtocolIssuesAction = RT<typeof getProtocolIssues>;

const onSuccess: ISuccessCallback<IGetProtocolIssuesAction> = function (data) {
    const { entities, result } = normalize(data, [issueSchema]);
    const { issues = {}, uploads } = entities;

    return {
        store: {
            allIds: result,
            byIds: issues
        },
        uploads
    };
};

export default takeLatest(
    GET_PROTOCOL_ISSUES_REQUEST,
    simpleRequest<IGetProtocolIssuesAction>({
        success: getProtocolIssuesSuccess,
        failure: getProtocolIssueFail,
        getUrl: (action) => `protocol/${action.protoId}/protocol_issues/`,
        onSuccess
    })
);
