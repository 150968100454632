import { takeEvery, put, all } from 'redux-saga/effects';

import {
    DELETE_PROTOCOL_ELEMENT_REQUEST,
    DELETE_PROTOCOL_ELEMENT_FAIL
} from '../../actions';
import {
    protoDelEle,
    protoDelEleSuccess,
    protoDelEleFail,
    getProtocolElement
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

type IDeleteElementAction = RT<typeof protoDelEle>;
type IDeelteElementActionFail = RT<typeof protoDelEleFail>;

const requestEffect = simpleRequest<IDeleteElementAction>({
    success: protoDelEleSuccess,
    failure: protoDelEleFail,
    getUrl: (action) => `protocol_element/${action.id}/`,
    getEntityId: (action) => action.id,
    method: 'DELETE',
    enableOffline: true
});

export default all([
    takeEvery(DELETE_PROTOCOL_ELEMENT_REQUEST, requestEffect),
    takeEvery(
        DELETE_PROTOCOL_ELEMENT_FAIL,
        function* (action: IDeelteElementActionFail) {
            if (action.itemId == null) return;
            yield put(getProtocolElement(action.itemId));
        }
    )
]);
