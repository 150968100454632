import { takeEvery } from 'redux-saga/effects';
import { SHOW_SNACKBAR } from '../../actions';
import { showSnackbar } from '../../action-creators';

import { ERROR_SNACKBAR_TIMEOUT_DURATION } from '../../../configs/costants';
import { isCancelError } from '../../../modules/error';
import { composeErrorMessage } from '../../../modules/errorHandlers';

import { queue } from '../../../components/misc/GlobalSnackbar/queue';
import { t } from '../../../i18next';

// TODO: Remove error handling logic from snackbar. Intead intermediary .catcb
// should decide what to show in what configuration depending on error given
export default takeEvery(
    SHOW_SNACKBAR,
    function (action: RT<typeof showSnackbar>) {
        const { message, error } = action.params;
        if (!message) return;

        if (error && isCancelError(error)) return;

        const { status } = error?.response || {};
        const isImportantError =
            status === 400 || status === 500 || status === 503;

        const errorMessage =
            typeof message === 'string' && error != null
                ? composeErrorMessage(message, error)
                : undefined;

        const translatedMessage =
            typeof message === 'string' ? t(message) : message;
        const body = errorMessage || translatedMessage;

        const timeout = isImportantError
            ? ERROR_SNACKBAR_TIMEOUT_DURATION
            : undefined;
        queue.notify({
            body,
            dismissesOnAction: true,
            actions: [{ title: t('dismiss') }],
            timeout
        });
    }
);
