import { takeEvery } from 'redux-saga/effects';

import { PATCH_DPD_REQUEST } from '../../actions';
import { patchDpdSuccess, patchDpdFail, patchDpd } from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeEvery(
    PATCH_DPD_REQUEST,
    simpleRequest<ReturnType<typeof patchDpd>>({
        success: patchDpdSuccess,
        failure: patchDpdFail,
        getUrl: (action) => `dpd/${action.dpdId}/`,
        getBody: (action) => action.payload,
        method: 'PATCH'
    })
);
