import { takeEvery, all } from 'redux-saga/effects';

import {
    POST_PROTOCOL_ELEMENT_REQUEST,
    POST_PROTOCOL_ELEMENT_SUCCESS
} from '../../actions';
import {
    protoPostEle,
    protoPostEleSuccess,
    protoPostEleFail
} from '../../action-creators';

import { simpleRequest } from '../modules/request';
import { normalizeProtocolElement } from '../modules/normalization';
import { responseHelper } from '../modules/promise';

function onSuccessCallback(data: any) {
    return normalizeProtocolElement(data);
}

const requestEffect = takeEvery(
    POST_PROTOCOL_ELEMENT_REQUEST,
    simpleRequest<RT<typeof protoPostEle>>({
        success: protoPostEleSuccess,
        failure: protoPostEleFail,
        getUrl: (_) => 'protocol_element/element_create/',
        getBody: (action) => action.payload,
        method: 'POST',
        enableOffline: true
    })
);

const responseEffect = responseHelper({
    actionName: POST_PROTOCOL_ELEMENT_SUCCESS,
    processFn: onSuccessCallback
});

export default all([requestEffect, responseEffect]);
