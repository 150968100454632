import { takeEvery, all } from 'redux-saga/effects';

import { POST_ISSUE_REQUEST, POST_ISSUE_SUCCESS } from '../../actions';
import {
    postIssueSuccess,
    postIssueFail,
    postIssue
} from '../../action-creators';

import { simpleRequest } from '../modules/request';
import { normalizeIssue } from '../modules/normalization';
import { responseHelper } from '../modules/promise';

function onSuccessCallback(data: any) {
    return normalizeIssue(data);
}

const requestEffect = takeEvery(
    POST_ISSUE_REQUEST,
    simpleRequest<ReturnType<typeof postIssue>>({
        success: postIssueSuccess,
        failure: postIssueFail,
        getUrl: (_) => `issue/issue_create/`,
        getBody: (action) => action.payload,
        method: 'POST',
        enableOffline: true
    })
);

const responseEffect = responseHelper({
    actionName: POST_ISSUE_SUCCESS,
    processFn: onSuccessCallback
});

export default all([requestEffect, responseEffect]);
