import React, { useState, useCallback, useLayoutEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useLocation, useHistory } from 'react-router';

import {
    getDpds,
    getAllIssue,
    getProtocols,
    predictIssue,
    getUsageLogs
} from '../../store/action-creators';

import { PROTOCOL_ROUTE } from '../../configs/routes';
import { PER_PAGE } from '../../configs/costants';

import {
    handleShareIssue,
    handleFixIssue,
    handleDeleteIssue,
    handleStartProtocol
} from '../../utils/requests';

import { debounce } from '../../utils';

import useToggle from '../../hooks/useToggle';
import useMatchMedia from '../../hooks/useMatchMedia';

import DashboardHeader from '../../components/dashboard/DashboardHeader';
import EditDpdModal from '../../components/inventory/EditDpdModal';

import EditIssueModal from '../../components/issues/EditIssueModal';
import IssueModal from '../../components/issues/IssueModal';
import ConfirmDeletionModal from '../../components/molecules/ConfirmDeletionModal';
import SelectPropertyModal from '../../components/inventory/SelectPropertyModal';
import Note from '../../components/atoms/StdNote';

import DpdSection from '../../components/dashboard/DpdSection';
import IssueSection from '../../components/dashboard/IssueSection';
import ProtocolSection from '../../components/dashboard/ProtocolSection';
import StdDivider from '../../components/atoms/StdDivider';
import IssueSharingDisabledFlow from '../../components/issues/IssueSharingDisabledFlow';
import StdChip from '../../components/atoms/StdChip';
import i18n from '../../i18next';
import ProtocolBetaWarningModal from '../../components/protocol/ProtocolBetaWarningModal';

const lang = i18n.language as ILang;
const searchProperty = debounce((dispatch: any, search: string) =>
    dispatch(getDpds('dashboard', { search, lang }))
);

interface IProps {
    user: IUser | null;
    issuesRes: any;
    dpdsRes: IPaginatedResult<IDpd>;
    protocolRes: IPaginatedResult<IProtocol>;
}

export const DashboardComponent: React.FC<IProps> = (props) => {
    const { user, dpdsRes, issuesRes, protocolRes } = props;

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const history = useHistory();
    const { state } = useLocation<{ fromVerification: boolean }>();
    const { fromVerification } = state || {};

    const [search, setSearch] = useState('');

    const [modalOpen, setModalOpen] = useState(false);

    const [issAdd, onAddIssOpen, onAddIssClose] = useToggle<
        'light' | 'excessive'
    >();

    const [editedIss, setEditedIss] = useState<IIssue>();
    const [viewedIssue, onViewIssOpen, onViewIssClose] = useToggle<IIssue>();

    const [protocolAdd, setProtocolAdd] = useState(false);

    const [delIss, onDelIssOpen, onDelIssClose] = useToggle<IIssue>();

    const [welcomeOpen, setWelcomeOpen] = useState(false);
    // const [createAdOpen, setCreateAdOpen] = useState(false);

    const [sharingPlanOpen, setSharingPlanOpen] = useState(false);

    const [showBetaWarning, setShowBetaWarning] = useState<boolean>(false);

    const account = useSelector((state) => state.user.user.response);
    const canShareIssues = Boolean(account?.permissions.issues.share);
    const isManualOffline = useSelector((state) => state.appState.isManualOffline)
    const offlineProtocolSessionId = useSelector(
        (state) => state.global.offlineProtocolSessionId
    );

    const isPhone = useMatchMedia('mobile');

    const fetchProtos = useCallback(() => {
        dispatch(
            getProtocols({
                limit: PER_PAGE,
                offset: 0,
                ordering: '-prt_createddate'
            })
        );
    }, [dispatch]);

    const fetchDpds = useCallback(() => {
        dispatch(getDpds('all', { limit: PER_PAGE, offset: 0, search, lang }));
    }, [dispatch, search]);
    const lang = i18n.language as ILang;
    const getIssues = useCallback(() => {
        dispatch(getAllIssue(lang, { limit: PER_PAGE, offset: 0 }));
    }, [dispatch, lang]);


    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;

        setSearch(value);
        searchProperty(dispatch, value);
    };

    const onIssueFix = () => {
        const { item } = viewedIssue;
        if (!item) return;

        handleFixIssue({
            id: item.iss_id,
            dispatch,
            onSuccess: function () {
                dispatch(predictIssue(Number(item.iss_id), { iss_fix: true }));
            },
        });
    };

    const onIssueShare = () => {
        if (!canShareIssues) {
            setSharingPlanOpen(true);
            return;
        }

        const { item } = viewedIssue;
        if (item) {
            handleShareIssue({
                id: item.piss_id,
                user,
                t,
                dispatch,
                onSuccess: getIssues
            });
        }
    };

    const onIssueDelete = () => {
        const { item } = delIss;
        if (!item) return;

        handleDeleteIssue({
            dispatch,
            id: item.iss_id,
            isOffline: isManualOffline,
            onSuccess: getIssues
        });
        onDelIssClose();
    };

    // -------

    useLayoutEffect(() => {
        if (fromVerification) setWelcomeOpen(true);
        return () => {
            setWelcomeOpen(false);
        };
    }, [fromVerification]);

    useLayoutEffect(() => {
        fetchDpds();
        getIssues();
        fetchProtos();
        dispatch(getUsageLogs(true));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useLayoutEffect(() => {
        getIssues();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, lang]);

    return (
        <div className="dashboard__page">
            <DashboardHeader value={search} onChange={handleSearch} />
            <div className="dashboard__wrapper">
                <Note
                    className="stack-ll"
                    open={welcomeOpen}
                    onClose={() => history.push({ state: null })}
                    success
                >
                    <div className="font--strong stack-xs">
                        {t('welcome_message_title')}
                    </div>
                    <div>{t('welcome_message_text')}</div>
                </Note>
                <div className="dashboard__section">
                    <span
                        className={clsx(
                            'font__title',
                            isPhone && 'util__inline stack-m'
                        )}
                    >
                        {t('dashboard.title')}
                    </span>
                </div>
                <ProtocolSection
                    protocolRes={protocolRes}
                    onModalOpen={() => setProtocolAdd(true)}
                    onAdd={() => {
                        account?.use_beta ? setShowBetaWarning(true) : setProtocolAdd(true)
                    }}
                />
                <StdDivider className="stack-m" />
                <IssueSection
                    issuesRes={issuesRes}
                    viewedIssue={viewedIssue}
                    onViewIssOpen={onViewIssOpen}
                    onViewIssClose={onViewIssClose}
                    onModalOpen={() => {
                        setEditedIss(undefined);
                        onAddIssOpen('excessive');
                    }}
                    onAdd={() => {
                        setEditedIss(undefined);
                        onAddIssOpen();
                    }}
                />
                <StdDivider className="stack-m" />
                <DpdSection
                    search={search}
                    dpdsRes={dpdsRes}
                    onModalOpen={() => setModalOpen(true)}
                    onAdd={() => setModalOpen(true)}
                />
            </div>
            <EditDpdModal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                onSubmit={fetchDpds}
            />
            <EditIssueModal
                defaultCategory={issAdd.item}
                issue={editedIss}
                open={issAdd.open}
                onClose={onAddIssClose}
                onSubmit={getIssues}
                selectObject
            />
            <IssueModal
                open={viewedIssue.open}
                issue={viewedIssue.item}
                onClose={onViewIssClose}
                onShare={onIssueShare}
                onFix={onIssueFix}
                onDelete={() => {
                    if (viewedIssue.item) onDelIssOpen(viewedIssue.item);
                    onViewIssClose();
                }}
                onEdit={() => {
                    setEditedIss(viewedIssue.item);
                    onViewIssClose();

                    onAddIssOpen();
                }}
                onFetch={getIssues}
                sharingDisabled={!canShareIssues}
                editable
            />
            <ConfirmDeletionModal
                open={delIss.open}
                onClose={onDelIssClose}
                message={t('delete_issue_message', {
                    name: delIss.item?.iss_title || '--'
                })}
                onConfirm={onIssueDelete}
            />
            <SelectPropertyModal
                open={protocolAdd}
                onClose={() => setProtocolAdd(false)}
                onObjectSelect={(dpdId) => {
                    handleStartProtocol({ dispatch, dpdId, history, useBeta: account?.use_beta, offlineProtocolSessionId }); // send session Id here
                    setProtocolAdd(false);
                }}
                renderDisabledBadge={(object) =>
                    object.dpd_prt_id != null && (
                        <StdChip
                            to={PROTOCOL_ROUTE(object.dpd_prt_id)}
                            type="dark"
                        >
                            {t('open_protocol')}
                        </StdChip>
                    )
                }
            />
            <IssueSharingDisabledFlow
                open={sharingPlanOpen}
                onClose={() => setSharingPlanOpen(false)}
            />
             <ProtocolBetaWarningModal
                open={showBetaWarning}
                onAccept={() => {
                    setShowBetaWarning(false);
                    setProtocolAdd(true);
                }}
                onCancel={() => {
                    setShowBetaWarning(false);
                }}
            />
        </div>
    );
};

export const Dashboard = () => {
    const user = useSelector((state) => state.user.user.response);

    const dpdsRes = useSelector((state) => state.dpds.dashboard);
    const issuesRes = useSelector((state) => state.inventory.issues.dashboard);
    const protocolRes = useSelector((state) => state.protocol.protocols);

    const props = {
        user,
        dpdsRes,
        issuesRes,
        protocolRes
    };
    return <DashboardComponent {...props} />;
};

export default Dashboard;
