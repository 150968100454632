import { takeEvery } from 'redux-saga/effects';
import { UPDATE_BILLING_REQUEST } from '../../actions';
import {
    updateBilling,
    updateBillingSuccess,
    updateBillingFail
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeEvery(
    UPDATE_BILLING_REQUEST,
    simpleRequest<ReturnType<typeof updateBilling>>({
        success: updateBillingSuccess,
        failure: updateBillingFail,
        getUrl: (_) => 'user/me/create_billing_address/',
        getBody: (action) => action.payload,
        method: 'POST',
        isV2Api: true
    })
);
