import React, {
    ChangeEvent,
    useEffect,
    useState,
    useCallback,
    memo
} from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { AnyAction } from 'redux';
import { isEmpty } from 'validator';

import { MEDIA_ATTACHMENT_TYPE } from '../../../configs/costants';
import { EL_ATTRIBUTE_TYPE } from '../../../configs/basicData';
import { validate } from '../../../utils/validation';
import { backDateToYear, backDateFormat } from '../../../utils/formatter';
import {
    patchElement,
    showSnackbar,
    elAttrCrud
} from '../../../store/action-creators';
import { dispatchAll } from '../../../store/storeModule';
import { getAttrHashKeys } from '../../../utils/attributes';

import useAttributeList from '../../../hooks/useAttributeList';
import useReactScrollTo from '../../../hooks/useReactScrollTo';
import useUploader from '../../../hooks/useUploader';

import StdModal from '../../atoms/StdModal';
import StdButton from '../../atoms/StdButton';
import StdTextField from '../../atoms/StdTextField';
import StdGallery from '../../molecules/StdGallery';
import EditAttributeList from '../EditAttributeList';
import StdDivider from '../../atoms/StdDivider';
import AttachmentGallery from '../../molecules/AttachmentGallery';
import EditModalHeader from '../../atoms/EditModalHeader';
import ObjectEditModal from '../../atoms/ObjectEditModal/ObjectEditModal';

interface IProps extends IModalBase {
    element?: IElement;
    toAttr?: boolean;
    toAttach?: boolean;
    onSuccess?: ICallback;
    contentClassName?: string;
}

interface IForm {
    form: {
        name: string;
        year_of_install: string;
    };
    errors: IErrorHash;
}

const initForm: IForm = {
    form: {
        name: '',
        year_of_install: ''
    },
    errors: {}
};

const EditElementModal: React.FC<IProps> = ({
    open,
    element,
    toAttr,
    toAttach,
    contentClassName,
    onClose,
    onSuccess
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [input, setInput] = useState(initForm);
    const lookupHash = useSelector((state) => state.basicData.response);
    const elAttrData = lookupHash?.[EL_ATTRIBUTE_TYPE];

    const attrListProps = useAttributeList();
    const { attrState, resetAttrs, validateAttrs } = attrListProps;

    const galleryUploader = useUploader();
    const attachUploader = useUploader();

    const attrRef = useReactScrollTo(toAttr);
    const attachRef = useReactScrollTo(toAttach);

    const handleInput = (
        event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        const { name, value } = event.target;
        if (name === 'year_of_install' && !/^\d{0,4}$/.test(value)) return;
        setInput((s) => ({
            ...s,
            form: {
                ...s.form,
                [name]: value
            },
            errors: {
                ...s.errors,
                [name]: null
            }
        }));
    };

    const resetInput = useCallback(() => {
        if (element && open) {
            const {
                ele_name,
                ele_ela_id,
                ele_date_installation,
                uploads = []
            } = element;
            const elAttrs = Array.isArray(ele_ela_id) ? ele_ela_id : [];

            const hashForm: IHash<string> = {};
            const attrIds = elAttrs.map((elAttr) => {
                const { ela_id, ela_value, ela_type } = elAttr;
                const idLiteral = { id: ela_id };

                const { typeKey, valueKey } = getAttrHashKeys(idLiteral);

                hashForm[typeKey] = ela_type || '';
                hashForm[valueKey] = ela_value || '';

                return idLiteral;
            });

            setInput({
                ...initForm,
                form: {
                    name: ele_name || '',
                    year_of_install: ele_date_installation
                        ? backDateToYear(ele_date_installation)
                        : ''
                }
            });
            resetAttrs(attrIds, hashForm);

            galleryUploader.setPartialUploads(
                uploads,
                (upload) => upload.type !== MEDIA_ATTACHMENT_TYPE
            );
            attachUploader.setPartialUploads(
                uploads,
                (upload) => upload.type === MEDIA_ATTACHMENT_TYPE
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [element, open, resetAttrs]);

    useEffect(() => {
        resetInput();
    }, [resetInput]);

    const mediaIds = galleryUploader.ids.concat(attachUploader.ids);

    const makeRequests = () => {
        const { name, year_of_install } = input.form;
        const { attrIds, hashForm, dirtyAttr } = attrState;

        if (element) {
            const eleLiteral = {
                ele_name: name,
                ele_date_installation: year_of_install !== '' ? moment(year_of_install, 'YYYY').format(
                    backDateFormat
                ) : "",
                media_med_id: mediaIds
            };

            const actions: AnyAction[] = [];
            actions.push(patchElement(element.ele_id, eleLiteral));

            const context = attrIds.map((attrId) => {
                const { typeKey, valueKey } = getAttrHashKeys(attrId);
                return {
                    ela_type: hashForm[typeKey],
                    ela_value: hashForm[valueKey],
                    ela_visibleforall: true
                };
            });

            dirtyAttr &&
                actions.push(
                    elAttrCrud({
                        ela_ele_id: element.ele_id,
                        ela_state: context
                    })
                );
            dispatchAll(dispatch, actions)
                .then((_) =>
                    Promise.all([
                        galleryUploader.submitChanges(),
                        attachUploader.submitChanges()
                    ])
                )
                .then(onSuccess)
                .catch(({ error }) =>
                    dispatch(
                        showSnackbar({
                            message: 'save_changes_fail_message',
                            error
                        })
                    )
                );
            onClose();
        }
    };

    const submitValidate = () => {
        const errors = { ...input.errors };
        const { name, year_of_install } = input.form;

        const attrRes = validateAttrs();
        const isValid = [
            validate({ name }, !isEmpty(name), errors),
            // validate({ year_of_install }, !isEmpty(year_of_install), errors) &&
            //     validate(
            //         { year_of_install },
            //         moment(year_of_install, 'YYYY').isValid(),
            //         errors
            //     ),
            ...attrRes
        ].every(Boolean);

        setInput((s) => ({
            ...s,
            errors
        }));
        return isValid;
    };

    const handleSubmit = () => {
        submitValidate() && makeRequests();
    };

    const cleanup = () => {
        setInput(initForm);
    };

    const onModalClose = () => {
        galleryUploader.cancelChanges();
        onClose();
    };

    const { form, errors } = input;
    return (
        <StdModal open={open} onClose={onClose} onExited={cleanup} contentClassName={'element_modalBorder'}>
            <ObjectEditModal
                title={t('element_detail')}
                onClose={onClose}
            />
            <div className="modal__heading modal__section">
                {t('Photos_and_Videos').toUpperCase()}
            </div>
            <div className="modal__section modal__section__mediaPosition">
                <StdGallery
                    {...galleryUploader}
                    noTitle
                    noMargin
                    uploadConfig={{
                        totalLimit: 2
                    }}
                    className="stack-s"
                    icon="image"
                    centered
                />
            </div>
            <StdDivider />
            <div className="modal__section">
                <div className="modal__heading stack-l">
                    {t('element_name').toUpperCase()}
                </div>
                <StdTextField
                    name="name"
                    value={form.name}
                    error={errors['name']}
                    onChange={handleInput}
                    width="100%"
                />
            </div>
            <div className="modal__section stack-m" ref={attrRef}>
                <div className="modal__heading stack-l">
                    {t('description').toUpperCase()}
                </div>
                <div>
                    {/* <StdTextField
                        label={t('year_of_installation')}
                        className="inline-xs"
                        name="year_of_install"
                        error={errors['year_of_install']}
                        value={form.year_of_install}
                        onChange={handleInput}
                        width="100%"
                    /> */}
                    {/* <StdButton
                        className="util__hidden"
                        type="round"
                        leadingIcon={{ name: 'times' }}
                    /> */}
                </div>
                <EditAttributeList
                    {...attrListProps}
                    {...attrState}
                    attrData={elAttrData}
                />
            </div>
            <StdDivider />
            <div className="modal__section" ref={attachRef}>
                <AttachmentGallery {...attachUploader} showTitle />
            </div>
            <div className="modal__section---buttonGrp modal__section--addProperty">
                <div>
                    <StdButton
                        className='modal__section__optionBtns'
                        onClick={onModalClose}
                    >
                        {t("cancel")}

                    </StdButton>
                </div>
                <div>
                    <StdButton
                        className='modal__section__optionBtns'
                        type="primary"
                        onClick={handleSubmit}
                    >
                        {t("save")}
                    </StdButton>
                </div>
            </div>
        </StdModal>
    );
};

export default memo(
    EditElementModal,
    (prevProps, nextProps) => prevProps.open === nextProps.open
);
