import { takeLatest } from 'redux-saga/effects';
import { PREVIEW_PDF_READY_REQUEST } from '../../actions';
import {
    previewPdfReady,
    previewPdfReadySuccess,
    previewPdfReadyFail
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeLatest(
    PREVIEW_PDF_READY_REQUEST,
    simpleRequest<RT<typeof previewPdfReady>>({
        success: previewPdfReadySuccess,
        failure: previewPdfReadyFail,
        getUrl: (action) =>
            `protocol/${action.protoId}/preview/?lang=${action.lang}`
    })
);
