import React from 'react';

import { useTranslation } from 'react-i18next';
import { roomToIcon } from '../../modules/rooms';

import {
    ListItem,
    ListItemGraphic,
    ListItemText,
    ListDivider
} from '@rmwc/list';
import StdIcon from '../atoms/StdIcon';

interface IProps {
    templates: ITemplate[];
    omitIcon?: boolean;
}

const TemplateList: React.FC<IProps> = ({ templates, omitIcon }) => {
    const { t } = useTranslation();
    const widthStyle = { minWidth: 220 };
    return (
        <>
            {templates.map((template) => {
                const { name, type_for_icon } = template;
                return (
                    <ListItem key={name} style={widthStyle} data-value={name}>
                        {!omitIcon && (
                            <ListItemGraphic
                                icon={
                                    <StdIcon
                                        name={roomToIcon(type_for_icon || '')}
                                    />
                                }
                            />
                        )}
                        <ListItemText>{name || '---'}</ListItemText>
                    </ListItem>
                );
            })}
            {Boolean(templates.length) && <ListDivider style={widthStyle} />}
            <ListItem data-value="__custom" style={widthStyle}>
                <ListItemText>{t('rooms.choose_custom_name')}</ListItemText>
            </ListItem>
        </>
    );
};

export default TemplateList;
