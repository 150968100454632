import React, { useEffect, ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';

import { validateLogo } from '../../../modules/image';

import { LOGO_TYPES, PDF_LOGO_TYPE } from '../../../configs/costants';
import { dispatchAsync } from '../../../store/storeModule';
import { getCurrentAcc, showSnackbar, updateUserMedia } from '../../../store/action-creators';
import { setImageDimentions } from '../../../modules/upload';

import useMatchMedia from '../../../hooks/useMatchMedia';
import useUploader from '../../../hooks/useUploader';

import StdButton from '../../atoms/StdButton';
import StdImage from '../../atoms/StdImage';
import VerticalProgress from '../../atoms/VerticalProgress';

import './Profile-logo-uploader.scss';

interface IProps {
    logo?: IUpload;
    verticalButton?: boolean;
    editPermission?: boolean
}

interface IForm {
    errors: IErrorHash;
}

const initForm: IForm = {
    errors: {}
};

const ProfileLogoUploader: React.FC<IProps> = ({ logo, verticalButton, editPermission = false }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const galleryUploader = useUploader();
    const [preview] = galleryUploader.previews;

    const [imageLoading, setImageLoading] = useState<boolean>(false);

    const config = {
        totalLimit: 2,
        type: PDF_LOGO_TYPE,
        allowedFiletypes: LOGO_TYPES
    };

    const isPhone = useMatchMedia('mobile');

    const [input, setInput] = useState(initForm);

    useEffect(() => {
        if (logo) {
            galleryUploader.setUploads([logo.id]);

            setInput({
                errors: {}
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logo]);

    const errKeys = Object.keys(input.errors);
    const hasError = errKeys.length;

    const handleValidate = (file: File) => {
        return validateLogo(file).then((result) => {
            if (typeof result === 'string') {
                setInput((s) => ({
                    ...s,
                    errors: {
                        ...s.errors,
                        aspect: result
                    }
                }));

                return false;
            }
            return true;
        });
    };

    const validateUpload = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.item(0);

        setInput({
            errors: {}
        });

        if (!file) return;

        handleValidate(file)
            .then(async (isValid) => {
                if (!isValid) {
                    throw new Error('Invalid Image Format')
                };
                setImageLoading(true);

                if (preview) {
                    await galleryUploader.removeUploadHard(preview.id);
                }
                return galleryUploader.uploadFilesCore([file], config);
            })
            .then((resultArray) => {
                if (!resultArray) return;
                const [result] = resultArray;

                if (result.status !== 'fulfilled') return;

                return result.value;
            })
            .then((id) => {
                if (typeof id === 'number') {
                    dispatchAsync(
                        dispatch,
                        updateUserMedia({ media_ids: [id] }),
                        {
                            disableLoading: true
                        }
                    ).then((_) =>
                        dispatchAsync(dispatch, getCurrentAcc(), {
                            disableLoading: true
                        })
                    );
                }
            })
            .then((_) => {
                setImageLoading(false);
                dispatch(
                    showSnackbar({snackType: 'info', message: 'partner.save_changes_success' })
                )
            })
            .catch((error) => {
                console.error(error);
                dispatch(
                    showSnackbar({ message: 'ad_edit_media_error', error })
                )
            });
    };

    const actionNode = (
        <StdButton className="logo-uploader__action">
            {preview ? t('change_logo') : t('add_logo')}
                <input
                    className="absolute-input"
                    type="file"
                    accept={LOGO_TYPES}
                    onChange={validateUpload}
                />
        </StdButton>
    );

    const imageNode = preview?.status === 'uploaded' && (
        <StdImage src={setImageDimentions(preview.path, 96, 0)} fullWidth />
    );

    const progressNode = preview?.status === 'in-progress' && (
        <VerticalProgress progress={preview.progress} />
    );

    return (
        <div className="logo-uploader__container">
            <div
                className={clsx(
                    'logo-uploader__content',
                    isPhone ? 'stack-m' : '',
                    hasError && 'logo-uploader__content--error'
                )}
            >
                <div
                    className={clsx(
                        'logo-uploader__logo',
                        'logo-uploader__logo--empty',
                        'inline-m',
                        hasError && 'logo-uploader__logo--error'
                    )}
                >
                    {preview ? (
                        <>
                            {imageNode}
                            {progressNode}
                        </>
                    ) : (
                        <>
                        <img src="/static/images/user.svg" alt="user" />
                        {hasError ?
                            <input
                                className="absolute-input"
                                type="file"
                                accept={LOGO_TYPES}
                                onChange={validateUpload}
                            />
                            :
                            null
                        }
                        </>
                    )}
                </div>
                <div className="logo-uploader__text">
                    <div className={clsx(verticalButton && 'stack-m')}>
                        {hasError ? (
                            <>
                            <div className="stack-m">
                                {errKeys.map((key, index) => {
                                    const isLast = index === errKeys.length - 1;
                                    return (
                                        <div
                                            key={index}
                                            className={clsx(
                                                !isLast && 'stack-xs'
                                            )}
                                        >
                                            {t(input.errors[key])}
                                        </div>
                                    );
                                })}
                            </div>
                            {(!imageLoading && editPermission) && (
                                <div>{actionNode}</div>
                            )}
                            </>
                        ) : (
                            <div className='logo-uploader_requiredSection'>
                                <div className="font--secondary">
                                    <div className="stack-xs">
                                        {t('logo_format_validation')}
                                    </div>
                                </div>
                                {(!imageLoading && editPermission) && (
                                    <div>{actionNode}</div>
                                )}
                            </div>
                        )}
                    </div>
                    <div>{verticalButton && actionNode}</div>
                </div>
            </div>
        </div>
    );
};

export default ProfileLogoUploader;
