import { takeLatest } from 'redux-saga/effects';

import { GET_PROTOCOL_REQUEST } from '../../actions';
import {
    getProtocol, 
    getProtocolSuccess,
    getProtocolFail
} from '../../action-creators';

import { simpleRequest } from '../modules/request';
import { normalizeProtocol } from '../modules/normalization';

export default takeLatest(
    GET_PROTOCOL_REQUEST,
    simpleRequest<RT<typeof getProtocol>>({
        success: getProtocolSuccess,
        failure: getProtocolFail,
        getUrl: (action) => `protocol/${action.protoId}/`,
        onSuccess: function (data) {
            return normalizeProtocol(data);
        }
    })
);
