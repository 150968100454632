import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { isProduction } from './utils';

import translationEN from './locales/en/translation.json';
import translationDE from './locales/de/translation.json';
import translationFR from './locales/fr/translation.json';
import translationIT from './locales/it/translation.json';

// the translations
const resources = {
    en: {
        translation: translationEN
    },
    de: {
        translation: translationDE
    },
    fr: {
        translation: translationFR
    },
    it: {
        translation: translationIT
    }
};

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources,
        fallbackLng: isProduction && 'en', // use english if detected language is not available
        debug: !isProduction,
        saveMissing: true,
        returnEmptyString: false,
        keySeparator: false, // we do not use nested translations
        interpolation: {
            escapeValue: false // react already escapes input
        }
    });

export const t = i18n.t.bind(i18n);
export default i18n;
