import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { isProduction } from '../../utils';
import { geoResultToAddress } from '../../utils/geo';

import useGoogleMap from '../../hooks/useGoogleMap';
import { IUseAddressEditProps, initForm } from '../../hooks/useAddressEdit';

import TextField from '../atoms/StdTextField';
import Map from '../misc/Map';

interface IProps extends IUseAddressEditProps {
    address?: IAddressForm;
    omitPadding?: boolean;
    omitTitle?: boolean;
    onGeoLocateSuccess?: (data: RT<typeof geoResultToAddress>) => void;
    onDirtyConfirm?: ICallback;
    isHouse?: boolean;
}

const AddressEditForm: React.FC<IProps> = ({
    address,
    input,
    omitTitle,
    omitPadding,
    isHouse,
    setInput,
    handleInput,
    onGeoLocateSuccess
}) => {
    const { t } = useTranslation();
    const isOffline = useSelector((state) => state.global.isOffline);

    useEffect(() => {
        if (address) {
            setInput((s) => ({
                ...s,
                form: address
            }));
        }
        // else {
        //     setInput(initForm);
        // }
    }, [address, setInput]);

    const resultToForm = useCallback(
        (
            place: google.maps.places.PlaceResult | google.maps.GeocoderResult
        ) => {
            const { address_components, formatted_address, geometry } = place;
            const addressData = geoResultToAddress(address_components);

            const {
                country,
                street_name,
                region,
                commune,
                city,
                zip,
                street_number,
                country_full
            } = addressData;

            const fullAddress = `${street_name} ${street_number}, ${
                zip || '??'
            } ${city || '????'}`;

            setInput((s) => ({
                ...s,
                form: {
                    ...s.form,
                    city,
                    zip,
                    street: street_name,
                    street_number: street_number,
                    address: formatted_address || '',
                    description: fullAddress,
                    title: `${street_name} ${street_number}`,
                    country: country_full,
                    address_extra: {
                        ...s.form.address_extra,
                        lat: geometry?.location?.lat(),
                        lng: geometry?.location?.lng(),
                        country,
                        commune,
                        region
                    }
                }
            }));

            onGeoLocateSuccess?.(addressData);
        },
        [setInput, onGeoLocateSuccess]
    );

    const { inputRef, handleLoadMap, handleLocate, handleGeocode } =
        useGoogleMap(resultToForm);

    const onMapLoad = handleLoadMap(() => {
        if (isOffline) return;

        if (address) {
            handleLocate(address);
        } else if (!isProduction && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const { latitude, longitude } = position.coords;
                handleGeocode({ lat: latitude, lng: longitude }).then(
                    (result) => resultToForm(result)
                );
            });
        }
    });

    const { form, errors } = input;
    return (
        <div
            className={clsx(
                !omitPadding && 'modal__section',
                !omitPadding && 'modal__section--slim'
            )}
        >
            {!omitTitle && (
                <div className="modal__heading stack-l">
                    {t('address').toUpperCase()}
                </div>
            )}
            <div className="stack-m">
                <TextField
                    inputRef={inputRef}
                    name="address"
                    value={form.address}
                    error={errors['address']}
                    onChange={handleInput}
                    placeholder={`${isHouse ?  t('enter_address') :t('enter_location')}`}
                    width="100%"
                />
            </div>
            <div className="maps__modal stack-m">
                <Map onMapLoad={onMapLoad} />
            </div>
            <div className="form__line stack-m">
                <TextField
                    className="inline-m"
                    label={t('street')}
                    name="street"
                    value={form.street}
                    error={errors['street']}
                    onChange={handleInput}
                    width="60%"
                    required
                />
                <TextField
                    label={t('street_number')}
                    name="street_number"
                    value={form.street_number}
                    error={errors['street_number']}
                    onChange={handleInput}
                    width="40%"
                    required
                />
            </div>
            <div className="form__line stack-m">
                <TextField
                    className="inline-m"
                    label={t('city_short')}
                    name="city"
                    value={form.city}
                    error={errors['city']}
                    onChange={handleInput}
                    width="60%"
                    required
                />
                <TextField
                    label={t('zip')}
                    name="zip"
                    value={form.zip}
                    error={errors['zip']}
                    onChange={handleInput}
                    width="40%"
                    required
                />
            </div>
            <TextField
                className="stack-s"
                label={t('country')}
                name="country"
                value={form.country}
                error={errors['country']}
                onChange={handleInput}
                width="100%"
                required
            />
        </div>
    );
};

export default AddressEditForm;
