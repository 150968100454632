import { takeLatest, call } from 'redux-saga/effects';
import { normalize } from 'normalizr';

import { GET_ROOM_REQUEST } from '../../actions';
import { getRoomSuccess, getRoomFail, getRoom } from '../../action-creators';

import { simpleRequest, ISuccessCallback } from '../modules/request';
import { fetchFailError } from '../../../modules/errorHandlers';
import { roomSchema } from '../../reducers/schema';
import { justArray } from '../../../utils';

type IGetPropertyRooms = RT<typeof getRoom>;

const onSuccess: ISuccessCallback<IGetPropertyRooms> = function (data) {
    const { result, entities } = normalize(justArray(data.results), [
        roomSchema
    ]);
    const { rooms = {}, uploads = {} } = entities;

    return {
        response: {
            ...data,
            results: result.map((key: any) => rooms[key])
        },
        uploads
    };
};

export default takeLatest(
    GET_ROOM_REQUEST,
    simpleRequest<IGetPropertyRooms>({
        success: getRoomSuccess,
        failure: getRoomFail,
        getUrl: (action) => `dpd/${action.dpdId}/rooms/?lang=${action.lang}`,
        onFail: function* (error: INetworkError) {
            yield call(fetchFailError, 'fetch_rooms_error', error);
        },
        onSuccess
    })
);
