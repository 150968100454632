import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { justArray } from '../../../utils';
import { getDpds } from '../../../store/action-creators';
import { PER_PAGE } from '../../../configs/costants';
import { detectScrollEnd } from '../../../modules/scroll';

import useToggle from '../../../hooks/useToggle';
import usePending from '../../../hooks/usePending';

import StdModal from '../../atoms/StdModal';
import StdButton from '../../atoms/StdButton';
import StdDivider from '../../atoms/StdDivider';
import PropertyAccordion from '../PropertyAccordion';
import Loading from '../../atoms/Loading';
import EditObjectModal from '../EditObjectModal';
import EditDpdModal from '../EditDpdModal';
import ViewModalHeader from '../../atoms/ViewModalHeader';

import './select-object-modal.scss';
import i18n from '../../../i18next';
import ObjectEditModal from '../../atoms/ObjectEditModal/ObjectEditModal';

interface IProps extends IModalBase {
    onObjectSelect: (id: number) => void;
    renderDisabledBadge: (object: IDpd) => React.ReactNode;
}

const SelectPropertyModal: React.FC<IProps> = ({
    open,
    onClose,
    onObjectSelect,
    renderDisabledBadge
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const lang = i18n.language as ILang;
    const [addOpen, setAddOpen] = useState(false);
    const [editOpen, onEditOpen, onEditClose] = useToggle<number>();
    const dpdsLoading = usePending([
        { action: getDpds, fetchType: 'protocol_select' },
        { action: getDpds, fetchType: 'all' }
    ]);

    // Note dpds.select is fetched with the dashboard (i.e it is copied from dpds.dashboad)
    // so if this component is placed anywhere else on the page dpds.select would have to be
    // filled in by the parent
    const dpdsRes = useSelector((state) => state.dpds.select);
    const properties = justArray(dpdsRes.response.results);

    const { paginate } = dpdsRes;

    const fetchDpds = useCallback(() => {
        dispatch(
            getDpds('protocol_select', { limit: PER_PAGE, offset: 0, lang })
        );
    }, [dispatch]);

    const onScroll = (e: React.UIEvent<HTMLElement>) => {
        const element = e.target as HTMLElement;
        if (paginate.hasNext && !dpdsLoading && detectScrollEnd(element)) {
            dispatch(
                getDpds(
                    'protocol_select',
                    { limit: PER_PAGE, offset: PER_PAGE * paginate.page, lang },
                    'forwards'
                )
            );
        }
    };

    return (
        <StdModal open={open} onClose={onClose} white>
            <ObjectEditModal
                title={t('select_property')}
                onClose={onClose}
            />
            <div className="stack-s" />
            <StdDivider />
            <div className="select-object-modal__content" onScroll={onScroll}>
                {dpdsLoading && paginate.page < 1 ? (
                    <Loading className="loading__select" size="large" />
                ) : (
                    properties.map((property, index) => {
                        const isLast = index === properties.length - 1;

                        return (
                            <PropertyAccordion
                                key={property.dpd_id}
                                className={clsx(!isLast && 'stack-m')}
                                property={property}
                                onObjectSelect={onObjectSelect}
                                onObjectAdd={() => onEditOpen(property.dpd_id)}
                                renderDisabledBadge={renderDisabledBadge}
                            />
                        );
                    })
                )}
                {dpdsLoading && paginate.page >= 1 && (
                    <Loading className="loading__select" size="large" />
                )}
            </div>
            <div className="modal__section modal__section--postionRight">
                <StdButton className="stack-s" type="primary" onClick={() => setAddOpen(true)}>
                    {t('add_property')}
                </StdButton>
            </div>
            <EditObjectModal
                parentId={editOpen.item}
                open={editOpen.open}
                onClose={onEditClose}
                onSuccess={fetchDpds}
            />
            <EditDpdModal
                open={addOpen}
                onClose={() => setAddOpen(false)}
                onSubmit={fetchDpds}
            />
        </StdModal>
    );
};

export default SelectPropertyModal;
