import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { IIssueData } from '../../../utils/componentInterfaces';

import { MenuSurfaceAnchor, MenuItem } from '@rmwc/menu';
import StdIcon from '../../atoms/StdIcon';
import MediaCount from '../../atoms/MediaCount';
import StdDropdown from '../../atoms/StdDropdown';
import StdMedia from '../../atoms/StdMedia';
import StdBullet from '../../atoms/StdBullet';

import './issue-card.scss';

interface IProps {
    className?: string;
    issueData: IIssueData;
    outlined?: boolean;
    width?: string;
    editable?: boolean;
    closed?: boolean;
    highlight?: boolean;
    sharingDisabled?: boolean;
    onShare?: ICallback;
    onView?: ICallback;
    onEdit?: ICallback;
    onDelete?: ICallback;
}

const IssueCard: React.FC<IProps> = (props) => {
    const { t } = useTranslation();

    const {
        className,
        outlined,
        width,
        editable,
        closed,
        highlight,
        issueData,
        sharingDisabled,
        onShare,
        onDelete,
        onEdit,
        onView
    } = props;
    const {
        title,
        location,
        date,
        desc,
        media,
        mediaCount,
        shared,
        fixed,
        excessive
    } = issueData;

    const [picture] = media;

    const [optionsOpen, setOptionsOpen] = useState(false);

    const issueCardClass = clsx(
        'issue-card',
        outlined && 'issue-card--outlined',
        closed && 'issue-card--closed',
        className
    );

    const titleClasses = clsx(
        'issue-card__title',
        excessive && 'issue-card__title--danger',
        closed && 'issue-card__title--closed',
        onView && 'util__pointer'
    );

    const onOptionSelect = (e: ISelectEvent) => {
        const {
            detail: { item }
        } = e;
        const value = item.dataset['value'];

        switch (value) {
            case 'share_issue':
                if (!shared) onShare?.();
                break;
            case 'view_issue':
                onView?.();
                break;
            case 'edit_issue':
                onEdit?.();
                break;
            case 'delete_issue':
                onDelete?.();
                break;
        }
    };

    return (
        <div style={{ width }} className={clsx(issueCardClass)}>
            <div className="std-section stack-m">
                <div className={titleClasses} onClick={onView}>
                    {highlight && (
                        <StdBullet
                            className="inline-s"
                            type={excessive ? 'danger' : 'warn'}
                        />
                    )}
                    <span className="font--strong">
                        {title} {closed && `(${t('fixed')})`}
                    </span>
                </div>
                <div className="util__flex-align">
                    {shared && !fixed && <StdIcon name="share-alt" />}
                    {editable && (
                        <div className="util__inline">
                            <MenuSurfaceAnchor>
                                <StdDropdown
                                    open={optionsOpen}
                                    onClose={() => setOptionsOpen(false)}
                                    onSelect={onOptionSelect}
                                >
                                    {!shared && !fixed && (
                                        <MenuItem data-value="share_issue">
                                            {sharingDisabled && (
                                                <StdIcon
                                                    className="inline-m"
                                                    name="lock-alt"
                                                    prefix="far"
                                                    small
                                                />
                                            )}
                                            {t('share_issue')}
                                        </MenuItem>
                                    )}
                                    <MenuItem data-value="view_issue">
                                        {t('view_issue')}
                                    </MenuItem>
                                    {!fixed && (
                                        <MenuItem data-value="edit_issue">
                                            {t('edit_issue')}
                                        </MenuItem>
                                    )}
                                    <MenuItem data-value="delete_issue">
                                        {t('delete_issue')}
                                    </MenuItem>
                                </StdDropdown>
                                <StdIcon
                                    prefix="far"
                                    name="ellipsis-v"
                                    clickable
                                    onClick={() => setOptionsOpen(true)}
                                />
                            </MenuSurfaceAnchor>
                        </div>
                    )}
                </div>
            </div>
            <div className="issue-card__main">
                <div
                    className={clsx(
                        'issue-card__image',
                        'inline-m',
                        onView && 'util__pointer'
                    )}
                    onClick={onView}
                >
                    <StdMedia
                        icon="image"
                        media={picture}
                        width={96}
                        height={96}
                    />
                    {Boolean(mediaCount && mediaCount > 1) && (
                        <MediaCount count={mediaCount} />
                    )}
                </div>
                <div
                    className={clsx(
                        'issue-card__content',
                        onView && 'util__pointer'
                    )}
                    onClick={onView}
                >
                    <div className="util__ellipsis stack-s">
                        <StdIcon className="inline-s" name="location-circle" />
                        <span>{location}</span>
                    </div>
                    <div className="util__ellipsis stack-s">
                        <StdIcon className="inline-s" name="calendar-week" />
                        <span>{date}</span>
                    </div>
                    <div className="util__ellipsis">
                        <StdIcon className="inline-s" name="info-square" />
                        <span>{desc}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IssueCard;
