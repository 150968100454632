import { takeEvery, all, put } from 'redux-saga/effects';

import { DEL_ATTENDEE_FAIL, DEL_ATTENDEE_REQUEST } from '../../actions';
import {
    delAttendee,
    delAttendeeSuccess,
    delAttendeeFail,
    getAttendee
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

type IDeleteAttendeAction = RT<typeof delAttendee>;
type IDeleteAttendeFailAction = RT<typeof delAttendeeFail>;

const requestEffect = simpleRequest<IDeleteAttendeAction>({
    success: delAttendeeSuccess,
    failure: delAttendeeFail,
    getUrl: (action) => `attendee/${action.id}/attendee_delete/`,
    getEntityId: (action) => action.id,
    method: 'DELETE',
    enableOffline: true
});

export default all([
    takeEvery(DEL_ATTENDEE_REQUEST, requestEffect),
    takeEvery(DEL_ATTENDEE_FAIL, function* (action: IDeleteAttendeFailAction) {
        if (action.itemId == null) return;
        yield put(getAttendee(action.itemId));
    })
]);
