import { takeLatest } from 'redux-saga/effects';

import { EL_ATTR_CRUD_REQUEST } from '../../actions';
import {
    elAttrCrud,
    elAttrCrudSuccess,
    elAttrCrudFail
} from '../../action-creators';
import { simpleRequest } from '../modules/request';

export default takeLatest(
    EL_ATTR_CRUD_REQUEST,
    simpleRequest<ReturnType<typeof elAttrCrud>>({
        success: elAttrCrudSuccess,
        failure: elAttrCrudFail,
        getBody: (action) => action.payload,
        getUrl: (_) => `element_attribute/element_attribute_crud/`,
        method: 'POST'
    })
);
