import moment from 'moment';

export const backDateFormat = 'YYYY-MM-DD';
export const frontDateFormat = 'DD-MM-YYYY';
export const frontDateFormatAlt = 'DD.MM.YYYY';
export const frontDateFormatWithTime = 'DD.MM.YYYY (hh:mm a)';
export const dateFormatWithTime = 'DD.MM.YYYY (HH:mm:ss)';
export const yearFormat = 'YYYY';
export const monthYearFormat = 'MM-YYYY';

export const backDateToFront = (date: string) => {
    return moment(date, backDateFormat).format(frontDateFormat);
};

export const backDateToFrontAlt = (date: string) => {
    return moment(date, backDateFormat).format(frontDateFormatAlt);
};
export const backDateToFrontWithTime = (date: string) => {
    return moment(date).format(frontDateFormatWithTime);
};
export const dateWithWithTime = () => {
    return moment().format('DD.MM.YYYY(hh.mm A)');
};

export const dateRangeWithTime = (fromDate: string, toDate: string) => {
    return `${moment(fromDate)
        .utc()
        .format(`${dateFormatWithTime}`)} - ${moment(toDate)
        .utc()
        .format(`${dateFormatWithTime}`)}`;
};

export function backDateToYear(date: string) {
    return moment(date, backDateFormat).format(yearFormat);
}

export function yearToBackDate(year: string) {
    return moment(year, 'YYYY').format(backDateFormat);
}

export const isoDateTimeToDateAlt = (dateTime: string) => {
    return moment(dateTime, moment.ISO_8601).format(frontDateFormatAlt);
};

export const isoDateTimeToDate = (dateTime: string) => {
    return moment(dateTime, moment.ISO_8601).format(frontDateFormat);
};

export const isoDateTimeToFront = (dateTime: string) => {
    return moment(dateTime, moment.ISO_8601).format('DD.MM.YY - HH:mm');
};

export const isoDateTimetoFrontAlt = (dateTime: string) => {
    return moment(dateTime, moment.ISO_8601).format('MMM Do [at] HH:mm');
};

export const backDateToISO = (date: string) => {
    return moment(date, backDateFormat).toISOString();
};

export const ISOToBackDate = (dateTime: string) => {
    return moment(dateTime, moment.ISO_8601).format(backDateFormat);
};

export const nextMonth = () =>
    moment().add(1, 'M').startOf('month').format(frontDateFormatAlt);

const INVOICE_DATE = 25;
export const nextInoviceDate = () => {
    const currentMoment = moment();

    // Note: careful add mutates the original
    if (currentMoment.date() < INVOICE_DATE) {
        return currentMoment
            .startOf('month')
            .add(INVOICE_DATE - 1, 'days')
            .format(frontDateFormatAlt);
    }
    return currentMoment
        .startOf('month')
        .add(1, 'M')
        .add(INVOICE_DATE - 1, 'days')
        .format(frontDateFormatAlt);
};
