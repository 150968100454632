import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isLimited } from '../../../modules/plans';

import useMatchMedia from '../../../hooks/useMatchMedia';

import StdButton from '../../atoms/StdButton';
import StdDivider from '../../atoms/StdDivider';
import InvoiceHistory from '../InvoiceHistory';

import IncludedFeature from '../../trial/IncludedFeature';
import ExtraFeature from '../../trial/ExtraFeature';

import StorageProgress from '../../molecules/StorageProgress';
import ExpandableTitle from '../../atoms/ExpandableTitle';
import StorageModal from '../StorageModal';

import './plan-section.scss';
import clsx from 'clsx';

interface IProps {
    account: IUser | null;
    currentPackage?: IPackage | null;
    currentInvoice?: IInvoiceHistory | null;
    invoiceHistory?: IHash<IInvoiceSummary>;

    billingAddress: IBilling | null;
    onBillingEdit?: ICallback;
    onPlanChange?: ICallback;
    onPlanCancel?: ICallback;
}

const PlanSection: React.FC<IProps> = ({
    account,
    currentPackage,
    currentInvoice,
    billingAddress,
    onBillingEdit,
    onPlanChange,
    onPlanCancel
}) => {
    const { t } = useTranslation();
    const [planDetailsOpen, setPlanDetailsOpen] = useState(false);
    const [storageOpen, setStorageOpen] = useState(false);

    const allowDetailComponentView = () => {
        if (currentPackage?.identifier.includes('ENTERPRISE')) {
            return true;
        }
        return false;
    };
    const showDetail = allowDetailComponentView();
    const allowPlanEdit = Boolean(!currentPackage?.identifier.includes('ENTERPRISE') && account?.role !== 'ADMIN' && account?.permissions?.company?.manage);

    const isPhone = useMatchMedia('mobile');

    const currency = currentPackage?.currency;
    const currentUsage = currentInvoice?.current_period.usage;

    const storageIncluded = currentUsage?.DOCUMENT.included;
    const storageUsed = currentUsage?.DOCUMENT.count;
    const unit = currentUsage?.DOCUMENT.unit;
    const price = currentUsage?.DOCUMENT.per_extra;
    const isUnlimited = Boolean(currentUsage?.DOCUMENT.unlimited);

    const storageTitle = `1${unit || ''}`.trim();
    const stroagePrice = `${price || 0} ${currency || 0}`.trim();

    const getPackageTitle = () => {
        let title = '';
        if(currentPackage?.identifier.includes('ENTERPRISE')) {
            title = `${currentPackage?.name}`
        } else {
            title = t(`plan.backend.name.${currentPackage?.identifier}`)
        }
        return title;
    }

    const packageTitle = getPackageTitle();

    return (
        <div>
            <div className="profile__section">
                <ExpandableTitle
                    className={clsx({
                        'detail-hidden': !showDetail
                    })}
                    open={planDetailsOpen}
                    title={t('plan_details')}
                    subtitle={
                        <div className="std-section">
                            <div className="plan-section__package-title inline-m">
                                {currentPackage
                                    ? packageTitle
                                    : t('no_active_plan_message')}
                            </div>
                            {allowPlanEdit && (
                                <div className="plan-section__package-actions">
                                <StdButton
                                    className={isPhone ? 'stack-s' : 'inline-m'}
                                    onClick={onPlanChange}
                                >
                                    {t('change_plan')}
                                </StdButton>
                                {currentPackage && (
                                    <span
                                        onClick={onPlanCancel}
                                        className="plan-section__canceal-plan"
                                    >
                                        {t('cancel_plan')}
                                    </span>
                                )}
                            </div>
                            )}
                        </div>
                    }
                    onToggle={() => setPlanDetailsOpen((s) => !s)}
                >
                    {currentPackage && (
                        <>
                            <div className="plan-section__package-table">
                                <div className="plan-section__package-row">
                                    <div className="plan-section__cell">
                                        <div className="plan-section__subtitle">
                                            {t('plan.included')}
                                        </div>
                                    </div>
                                    <div className="plan-section__cell">
                                        <div className="plan-section__subtitle">
                                            {t('plan.extra_costs')}
                                        </div>
                                    </div>
                                </div>
                                {currentPackage.features.map(
                                    (feature, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className="plan-section__package-row"
                                            >
                                                <div className="plan-section__cell">
                                                    <IncludedFeature
                                                        feature={feature}
                                                    />
                                                </div>
                                                <div className="plan-section__cell">
                                                    {isLimited(feature) && (
                                                        <ExtraFeature
                                                            feature={feature}
                                                            currency={
                                                                currentPackage.currency
                                                            }
                                                            inline={!isPhone}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    }
                                )}
                            </div>
                        </>
                    )}
                </ExpandableTitle>
            </div>
            {currentPackage && !isUnlimited && (
                <>
                    <StdDivider />
                    <div className="profile__section">
                        <div className="font--secondary stack-l">
                            {t('plan.storage')}
                        </div>
                        <div className="profile__setting">
                            <StorageProgress
                                className={clsx(
                                    isPhone ? 'stack-s' : 'inline-s'
                                )}
                                included={storageIncluded || 0}
                                used={storageUsed || 0}
                                unit={unit || ''}
                            />
                            <StdButton
                                className="plan-section__storage-action"
                                onClick={() => setStorageOpen(true)}
                            >
                                {t('plan.manage_storage')}
                            </StdButton>
                        </div>
                    </div>
                </>
            )}
            {(account?.role === 'PO' || account?.role === 'ADMIN') && (
                <>
                <StdDivider />
                <div className="profile__section">
                    <div className="font--secondary stack-l">
                        {t('billing_details')}
                    </div>
                    <div className="profile__setting">
                        <span
                            className={clsx(isPhone ? 'stack-s' : 'inline-s')}
                        >
                            {billingAddress ? (
                                <>
                                    <div>{billingAddress.full_name}</div>
                                    <div>
                                        {billingAddress.street}{' '}
                                        {billingAddress.street_number}
                                    </div>
                                    <div>
                                        {billingAddress.city}{' '}
                                        {billingAddress.zip}
                                    </div>
                                    <div>
                                        {billingAddress.region}{' '}
                                        {t(`${billingAddress.country}`)}
                                    </div>
                                </>
                            ) : (
                                t('no_default_billing_message')
                            )}
                        </span>
                        <StdButton onClick={onBillingEdit}>
                            {t('update_billing')}
                        </StdButton>
                    </div>
                </div>
            </>
            )}
            {currentInvoice && currentPackage && (
                <>
                    <StdDivider />
                    <div
                        className={clsx({
                            'plan-section__invoice': true
                        })}
                    >
                        <InvoiceHistory
                            invoice={currentInvoice.current_period}
                            openByDefault
                        />
                    </div>
                </>
            )}
            <StorageModal
                amount={storageTitle}
                price={stroagePrice}
                open={storageOpen}
                onClose={() => setStorageOpen(false)}
            />
        </div>
    );
};

export default PlanSection;
