import React from 'react';
import clsx from 'clsx';

import './divider.scss';

interface IProps extends IClass {
    width?: string;
    thick?: boolean;
    black?: boolean;
}

const StdDivider: React.FC<IProps> = ({ className, width, thick, black }) => {
    const dividerClass = clsx(
        'divider',
        thick && 'divider--thick',
        black && 'divider--black',
        className
    );

    return <div style={{ width }} className={dividerClass} />;
};

export default StdDivider;
