export interface IStorageEngine {
    remove(key: string): void;
    get(key: string): unknown;
    set(key: string, data: unknown): void;
    entries(): string[];
}

class LocalStorage implements IStorageEngine {
    private storage: Storage;

    constructor(storage: Storage = localStorage) {
        this.storage = storage;
    }

    remove(key: string): void {
        this.storage.removeItem(key);
    }

    get(key: string): unknown {
        const item = this.storage.getItem(key);
        return item ? JSON.parse(item) : '';
    }

    set(key: string, data: unknown): void {
        this.storage.setItem(key, JSON.stringify(data));
    }

    entries() {
        const entries: string[] = [];
        for (let i = 0; i < this.storage.length; ++i) {
            const key = this.storage.key(i);
            if (key) entries.push();
        }
        return entries;
    }
}

class StorageManager implements IStorageEngine {
    private engine: IStorageEngine;
    private prefix: string;

    constructor(engine: IStorageEngine, prefix: string) {
        this.engine = engine;
        this.prefix = prefix;
    }

    private augmentKey(key: string) {
        return `${this.prefix}_${key}`;
    }

    remove(key: string): void {
        this.engine.remove(this.augmentKey(key));
    }

    get(key: string): unknown {
        return this.engine.get(this.augmentKey(key));
    }

    set(key: string, data: unknown): void {
        this.engine.set(this.augmentKey(key), data);
    }

    entries() {
        return this.engine.entries();
    }

    clear() {
        const entries = this.engine.entries();
        for (let entry of entries) {
            if (entry.startsWith(this.prefix)) {
                this.engine.remove(entry);
            }
        }
    }
}

export const localStorageEngine = new LocalStorage();
export const sessionStorageEngine = new LocalStorage(sessionStorage);

export const storageManager = new StorageManager(
    localStorageEngine,
    'aimmo-storage'
);
export const sessionStorageManager = new StorageManager(
    sessionStorageEngine,
    'aimmo-storage'
);
