export const geoResultToAddress = (
    components: google.maps.GeocoderAddressComponent[] | undefined
) => {
    let country = '';
    let street_name = '';
    let region = '';
    let commune = '';
    let city = '';
    let zip = '';
    let street_number = '';
    let country_full = '';

    components &&
        components.forEach((component) => {
            switch (component.types[0]) {
                case 'street_number':
                    street_number = component.long_name;
                    break;
                case 'route':
                    street_name = component.long_name;
                    break;
                case 'locality':
                    city = component.long_name;
                    break;
                case 'administrative_area_level_2':
                    commune = component.long_name;
                    break;
                case 'administrative_area_level_1':
                    region = component.long_name;
                    break;
                case 'country':
                    country = component.short_name;
                    country_full = component.long_name;
                    break;
                case 'postal_code':
                    zip = component.long_name;
                    break;
            }
        });

    return {
        country,
        street_name,
        region,
        commune,
        city,
        zip,
        street_number,
        country_full
    };
};
